import { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../../../../../../components/ModalV2'
import { debounce } from 'lodash'
import PermissionList, { TouchedPermission } from '../../../components/PermissionList'
import { useToast } from '../../../../../../hooks/useToast'
import { ListRolePermissions, permissionsService } from '../../../../../../services/permissions'
import Loading from '../../../../../../components/Loading'

interface Props {
  roleId: number
  onClose: () => void
}

export default function RoleModal(props: Props) {
  const { roleId, onClose } = props

  const { toast } = useToast()

  const [permissions, setPermissions] = useState<ListRolePermissions['result']>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const listRolePermissions = async () => {
    try {
      setLoading(true)
      const rolesResponse = await permissionsService.listRolePermissions({
        roleId,
      })
      setPermissions(rolesResponse)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar grupos',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const updateRolePermissions = useCallback(async (touchedPermissions: TouchedPermission[]) => {
    try {
      await permissionsService.updateRolePermissions({
        roleId,
        permissions: touchedPermissions.map((touchedPermission) => ({
          id: touchedPermission.permissionId,
          remove: !touchedPermission.hasPermission,
        })),
      })
      toast({
        message: 'Permissões atualizadas com sucesso',
        type: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao atualizar permissões',
        type: 'error',
      })
    }
  }, [])

  const debounceOnChange = useMemo(
    () =>
      debounce(
        (touchedPermission: TouchedPermission[]) => updateRolePermissions(touchedPermission),
        1000,
      ),
    [],
  )

  useEffect(() => {
    listRolePermissions()
  }, [])

  return (
    <Modal.Provider
      style={{ overflowY: 'auto', width: '100%', height: '100%' }}
      isOpen
      maxWidth={650}
      onClose={onClose}
    >
      <Modal.Title>
        {`Grupo`}
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        {isLoading && (<Loading />)}
        {!isLoading && permissions && (
          <PermissionList permissions={permissions as any} onChange={debounceOnChange} />
        )}
      </Modal.Body>
    </Modal.Provider>
  )
}
