import { Ticket } from '../../../../interfaces/tickets'
import AssignTicketSelect from './components/AssignTicketSelect'
import TicketStatusSelect from './components/TicketStatusSelect'
import ModalV2 from '../../../../components/ModalV2'
import { ButtonsArea, CancelButton, Container, ContinueButton, TicketSettings } from './styles'
import { Dispatch, SetStateAction, useState } from 'react'
import CloseTicket from './components/CloseTicket'

interface Props {
  ticket?: Ticket
  isOpen?: boolean
  assignedTo?: string
  setAssignedTo: Dispatch<SetStateAction<string>>
  onCancel: () => void
  onTicketClose: () => void
}

export default function TicketManageModal(props: Props) {
  const { ticket, isOpen, assignedTo, setAssignedTo, onCancel, onTicketClose } = props

  const [isClosingTicket, setClosingTicket] = useState<boolean>(false)

  if (!ticket) {
    return null
  }

  return (
    <ModalV2.Provider
      modalStyle={{ width: '100%', ...(!isOpen ? { visibility: 'hidden' } : {}) }}
      style={{ width: '100%' }}
      isOpen
      hideBackdrop={!isOpen}
      onClose={onCancel}
    >
      <ModalV2.Title style={{ display: 'flex' }}>
        {`Ticket #${ticket.id} - Gerenciar`}
        {!isClosingTicket && (
          <ContinueButton
            containerStyle={{ width: 'fit-content', marginLeft: 'auto', whiteSpace: 'nowrap' }}
            text='Encerrar atendimento'
            onClick={() => setClosingTicket(true)}
          />
        )}
        <ModalV2.CloseButton alignLeft={isClosingTicket} />
      </ModalV2.Title>

      <Container style={isClosingTicket ? { display: 'none' } : {}}>
        <TicketSettings>
          <AssignTicketSelect ticketId={ticket.id} assignedTo={assignedTo} setAssignedTo={setAssignedTo} />
          {assignedTo && <TicketStatusSelect ticketId={ticket.id} ticketStatus={ticket.status} />}
        </TicketSettings>

        <ButtonsArea>
          <CancelButton
            containerStyle={{ width: 'fit-content' }}
            text='Voltar'
            onClick={onCancel}
          />
        </ButtonsArea>
      </Container>

      <CloseTicket
        style={!isClosingTicket ? { display: 'none' } : {}}
        ticketId={ticket.id}
        onClose={onTicketClose}
        onBack={() => setClosingTicket(false)}
      />
    </ModalV2.Provider>
  )
}
