import { useEffect, useState } from 'react'
import Modal from '../../../../../components/Modal'
import { InvoiceParser } from '../../../../../interfaces/invoices'
import { StickyFooter } from './styles'
import { Formik } from 'formik'
import { Button } from '../../../../../components/Button'
import { invoiceParserService } from '../../../../../services/invoice-parser'
import Loading from '../../../../../components/Loading'
import InvoiceClosing from '../InvoiceClosing'
import InvoiceForm from '../InvoiceForm'

interface Props {
  invoiceParserId: string
  onReanalyze: (invoice: InvoiceParser) => void
  onCreate: (invoiceParserId: string) => void
  onClose: () => void
}

export default function InvoiceModal(props: Props) {
  const { invoiceParserId, onReanalyze, onCreate, onClose } = props

  const [invoiceParser, setInvoiceParser] = useState<InvoiceParser>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const [closingModalOpen, setClosingModalOpen] = useState<boolean>(false)

  const [currentAction, setCurrentAction] = useState<'update' | 'reanalyze'>()

  const updateInvoiceData = async (data: Record<string, unknown>) => {
    try {
      setCurrentAction('update')
      const invoiceParserUpdated = await invoiceParserService.updateInvoice(invoiceParserId, data)
      setInvoiceParser(invoiceParserUpdated)
    } catch (error) {
      console.log(`Error updating invoice data: ${error}`)
    } finally {
      setCurrentAction(undefined)
    }
  }

  const fetchInvoiceParser = async () => {
    try {
      setLoading(true)
      const invoice = await invoiceParserService.getInvoice(invoiceParserId)
      setInvoiceParser(invoice)
    } catch (error) {
      console.log(`Error fetching invoice parser: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const reanalyzeInvoice = async () => {
    try {
      setCurrentAction('reanalyze')
      const invoiceParser = await invoiceParserService.reanalyzeInvoice(invoiceParserId)
      onReanalyze(invoiceParser)
      onClose()
    } catch (error) {
      console.log(`Error reanalyzing invoice: ${error}`)
    } finally {
      setCurrentAction(undefined)
    }
  }

  useEffect(() => {
    fetchInvoiceParser()
  }, [invoiceParserId])

  return (
    <Modal
      width='720px'
      title='Dados da Fatura'
      setModalOpen={onClose}
      defaultButtons={false}
      openModal
    >
      {isLoading && <Loading />}

      {closingModalOpen && (
        <InvoiceClosing
          invoiceParserId={invoiceParserId}
          onCreate={() => onCreate(invoiceParserId)}
          onClose={() => setClosingModalOpen(false)}
        />
      )}

      {!isLoading && invoiceParser && !closingModalOpen && (
        <Formik
          initialValues={invoiceParser.raw_data ?? {}}
          enableReinitialize
          onSubmit={updateInvoiceData}
        >
          <InvoiceForm invoiceParser={invoiceParser}>
            <StickyFooter>
              <Button text='Fechar' onClick={onClose} disabled={!!currentAction} />
              <Button
                type='submit'
                text='Atualizar dados'
                isLoading={currentAction === 'update'}
                disabled={!!currentAction}
              />
              <Button
                text='Releitura de dados'
                onClick={reanalyzeInvoice}
                isLoading={currentAction === 'reanalyze'}
                disabled={!!currentAction}
              ></Button>
              <Button
                text='Calcular fechamento'
                onClick={() => setClosingModalOpen(true)}
                disabled={!!currentAction}
              ></Button>
            </StickyFooter>
          </InvoiceForm>
        </Formik>
      )}
    </Modal>
  )
}
