import { Ticket, TicketPagination, TicketReply, TicketStatus, TicketWidgets } from '../interfaces/tickets'
import { sanitizeFilename } from '../utils/string-utils'
import { api } from './api'

type TicketBase = Omit<Ticket, 'replies' | 'files'>

interface CreateTicket {
  params: {
    title: string
    category: string
    subCategory?: string
    description: string
    files: File[]
  }
  result: TicketBase
}
export interface ListTickets {
  params: {
    page: number
    itemsPerPage?: number
    field?: string
    order?: 'asc' | 'desc'
    filter?: Record<string, unknown>
  }
  result: TicketPagination
}
interface GetTicket {
  params: {
    ticketId: number
  }
  result: Ticket
}
interface ReplyTicket {
  params: {
    ticketId: number
    message: string
  }
  result: TicketReply
}
interface CloseTicket {
  params: {
    ticketId: number
  }
  result: void
}
interface DownloadFile {
  params: {
    fileId: string
  }
  result: Blob
}
interface AssignTicket {
  params: {
    ticketId: number
    assignTo: string
  }
  result: void
}
interface ChangeTicketStatus {
  params: {
    ticketId: number
    ticketStatus: TicketStatus
  }
  result: void
}

export class TicketsService {
  async createTicket(params: CreateTicket['params']): Promise<CreateTicket['result']> {
    const { files, ...data } = params

    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file, sanitizeFilename(file.name))
    }

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }

    const ticket = await api.post('/tickets', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return ticket.data
  }
  async getTicket(params: GetTicket['params']): Promise<GetTicket['result']> {
    const { ticketId } = params
    const ticket = await api.get(`/tickets/${ticketId}`)
    return ticket.data
  }
  async listTickets(params: ListTickets['params']): Promise<ListTickets['result']> {
    const { filter, ...data } = params

    const filters = filter ? Object.entries(filter) : []

    const ticket = await api.get('/tickets', {
      params: {
        ...(filters.length > 0
          ? {
              filter: filters
                .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
                .join(','),
            }
          : {}),
        ...data,
      },
    })
    return ticket.data
  }
  async getWidgets(): Promise<TicketWidgets> {
    const ticket = await api.get('/tickets/widgets')
    return ticket.data
  }
  async replyTicket(params: ReplyTicket['params']): Promise<ReplyTicket['result']> {
    const { ticketId, message } = params
    const ticketReply = await api.post(`/tickets/${ticketId}/reply`, {
      message,
    })
    return ticketReply.data
  }
  async closeTicket(params: CloseTicket['params']): Promise<CloseTicket['result']> {
    const { ticketId } = params
    await api.patch(`/tickets/${ticketId}/close`)
  }
  async downloadFile(params: DownloadFile['params']): Promise<DownloadFile['result']> {
    const { fileId } = params
    const response = await api.get(`/tickets/files/${fileId}`, {
      responseType: 'blob'
    })
    return response.data
  }
  async assignTicket(params: AssignTicket['params']): Promise<AssignTicket['result']> {
    const { ticketId, assignTo } = params;
    await api.patch(`/tickets/${ticketId}/assign`, {
      assignTo,
    });
  }
  async changeTicketStatus(params: ChangeTicketStatus['params']): Promise<ChangeTicketStatus['result']> {
    const { ticketId, ticketStatus } = params;
    await api.patch(`/tickets/${ticketId}/status`, {
      ticketStatus,
    });
  }
}

export const ticketsService = new TicketsService()
