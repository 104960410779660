import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import {
  statusAggregatedPaymentStyle,
  StatusEmailEnum,
} from '../../../Invoices/utils/selectedObservationFieldColor'
import { RowText, StatusText } from '../../styles'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { Edit, Visibility } from '@mui/icons-material'
import DownloadSheetButton from '../DownloadSheetButton'
import SendIcon from '../../../../assets/send-gray.svg'
import DeleteIcon from '../../../../assets/delete.svg'
import DownloadRentalInvoiceButton from '../DownloadRentalInvoiceButton'

const getEmailStatus = (row: any): StatusEmailEnum => {
  if (row.sentAt) {
    return StatusEmailEnum.Confirmado
  }
  return StatusEmailEnum['Não enviado']
}

interface TableBuilderProps {
  referenceDate: Date;
  onSend: (id: number) => void
  onEdit: (id: number) => void
  onDelete: (id: number) => void
}

export const aggregatedPaymentsTableColumns = (props: TableBuilderProps): GridColDef[] => {
  const { referenceDate, onSend, onEdit, onDelete } = props
  const columns: GridColDef[] = [
    {
      field: 'paymentStatus',
      headerName: 'Status',
      description: 'Status',
      minWidth: 90,
      maxWidth: 125,
      hideable: true,
      sortable: false,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        const status = statusAggregatedPaymentStyle(params.value)
        return <StatusText className={status.class}>{status.text}</StatusText>
      },
    },
    {
      field: 'email_status',
      headerName: 'Status Email',
      description: 'Status Email',
      minWidth: 90,
      maxWidth: 125,
      hideable: true,
      sortable: false,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        const emailStatus = getEmailStatus(params.row)
        const emailStatusLabel = Object.entries(StatusEmailEnum).find(
          (item) => item[1] === emailStatus,
        )
        return (
          <StatusText className={emailStatus}>
            {emailStatusLabel ? emailStatusLabel[0] : ''}
          </StatusText>
        )
      },
    },
    {
      field: 'clientName',
      headerName: 'Cliente',
      description: 'Cliente',
      minWidth: 240,
      hideable: true,
      sortable: true,
    },
    {
      field: 'dueDateFormatted',
      headerName: 'Vencimento',
      description: 'Vencimento',
      minWidth: 80,
      maxWidth: 120,
      hideable: true,
      sortable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        try {
          return <RowText>{params.value}</RowText>
        } catch (error) {
          return '-'
        }
      },
    },
    {
      field: 'paymentValue',
      headerName: 'Valor devido (R$)',
      description: 'Valor devido (R$)',
      minWidth: 120,
      maxWidth: 160,
      hideable: true,
      sortable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        const formattedCurrency = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return <RowText>{formattedCurrency.format(params.value).replace('R$', '')}</RowText>
      },
    },
    {
      field: 'actions',
      headerName: 'Opções',
      renderHeader: () => '',
      sortable: false,
      hideable: false,
      width: 240,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction='row' alignItems='center' gap={0.5}>
            <Tooltip title={<p style={{ fontSize: 15 }}>Editar</p>} placement='bottom'>
              <IconButton
                onClick={
                  () => onEdit(params.row.id)
                }
              >
                <Edit style={{ color: '#ababab' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={<p style={{ fontSize: 15 }}>Enviar cobrança</p>} placement='bottom'>
              <IconButton onClick={() => onSend(params.row.id)}>
                <img src={SendIcon.toString()} style={{ cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={<p style={{ fontSize: 15 }}>Baixar relatório</p>} placement='bottom'>
              <span>
                <DownloadSheetButton
                  variant='secondary'
                  aggregatedPaymentId={params.row.id}
                  referenceMonth={referenceDate}
                />
              </span>
            </Tooltip>
            <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar cobrança</p>} placement='bottom'>
                <span>
                  <DownloadRentalInvoiceButton
                    aggregatedPaymentId={params.row.id}
                    fechamentosIds={params.row.fechamentoIds}
                  />
                </span>
            </Tooltip>

            <Tooltip title={<p style={{ fontSize: 15 }}>Deletar</p>} placement='bottom'>
              <IconButton onClick={() => onDelete(params.row.id)}>
                <img src={DeleteIcon.toString()} style={{ cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        )
      },
    },
  ]
  return columns.filter((col) => !col.hide)
}
