import Widget from '../../Widget'
import Chart from 'react-apexcharts'
import {
  ButtonsDiv,
  CalendarDiv,
  CalendarText,
  ChartBody,
  DownloadDiv,
  LegendCircle,
  LegendDiv,
  LegendSubDiv,
  LegendText,
  OptionItem,
  OptionList,
  TitleDiv,
  TitleText,
} from './styles'
import CalendarIcon from '../../../assets/icons/calendar-icon.svg'
import DownloadIcon from '../../../assets/icons/download-icon.svg'
import React, { useEffect, useState } from 'react'
import './style.css'
import { convertDateToMonthName } from '../../../utils/dateToName'
import { BarChartPayload } from '../../../services/requests/types'
import writeXlsxFile from 'write-excel-file'
import { GlobalContext } from '../../../context/global/global'
import {
  ButtonsContainer,
  CancelButton,
} from '../../../components/Modal/Payments/EditPaymentModal/styles'
import Modal from '../../../components/Modal/index'

interface BarProps {
  data: BarChartPayload[]
  isConsumer?: boolean
  dateSelected: { month: number; year: number }
  userEmail?: string
  onChangePeriod?: (value: number) => void
}

export default function ConsumerMainChart({
  data,
  dateSelected,
  userEmail,
  onChangePeriod,
}: BarProps) {
  const { Theme } = React.useContext(GlobalContext)
  const generatedEnergy = data.filter((item) => item.campo === 'consumida')
  const consumedEnergy = data.filter((item) => item.campo === 'compensada')
  const reimbursedEnergy = data.filter((item) => item.campo === 'saldo')

  const withGdCost = data.filter((item) => item.campo === 'consumida')
  const withoutGdCost = data.filter((item) => item.campo === 'compensada')
  const gainValue = data.filter((item) => item.campo === 'saldo')
  const energia_injetada = data.filter((item) => item.campo === 'injetada')
  const add_field_energia_injetada = userEmail && ['cesup.projetos@bb.com.br'].includes(userEmail)

  const maxInterval = 13

  const [interval, setInterval] = useState({
    id: maxInterval,
    name: 'Últimos 12 meses',
  })

  const calendarOption = [
    {
      id: maxInterval,
      name: 'Últimos 12 meses',
    },
    {
      id: 6,
      name: 'Últimos 6 meses',
    },
    {
      id: 3,
      name: 'Últimos 3 meses',
    },
  ]

  const get_unique_months = data
    .map((item) => item.mes_referencia)
    .filter((item, pos, self) => self.indexOf(item) == pos)

  const sorted_months = get_unique_months.sort()
  const getMonths = sorted_months.map((value) => convertDateToMonthName(value))

  const ConsumerStartingValueArray = [
    {
      name: 'Energia Consumida',
      data: withGdCost.map((item) => item.valor),
    },
    {
      name: 'Energia Compensada',
      data: withoutGdCost.map((item) => item.valor),
    },
    {
      name: 'Saldo',
      data: gainValue.map((item) => item.valor),
    },
  ]
  const ConsumerStartingValue = [
    ...ConsumerStartingValueArray,
    ...(add_field_energia_injetada
      ? [
          {
            name: 'Energia Injetada',
            data: energia_injetada.map((item) => Number(item.valor.toFixed(0))),
          },
        ]
      : []),
  ]

  const handleSeriesChange = (id: number) => {
    removeFromIds.has(id) ? removeFromIds.delete(id) : removeFromIds.add(id)
    const currentStartingValue = ConsumerStartingValue
    setApexSeries(
      removeFromIds.size >= 3
        ? currentStartingValue
        : currentStartingValue.filter((_, index: number) => !removeFromIds.has(index)),
    )
    setCurrentColor(
      removeFromIds.size >= 3
        ? colors
        : colors.filter((_, index: number) => !removeFromIds.has(index)),
    )
    if (removeFromIds.size >= 3) removeFromIds.clear()
  }

  const [showDropDown, setShowDropDown] = useState(false)
  const [removeFromIds] = useState(new Set())
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [apexSeries, setApexSeries] = useState(ConsumerStartingValue)

  useEffect(() => {
    if (data) {
      setApexSeries(ConsumerStartingValue)
    }
  }, [data])

  const colors = [
    Theme.colors.lightGreen,
    Theme.colors.orange,
    Theme.colors.darkishGreen,
    Theme.colors.appleGreen,
  ]

  const [currentColor, setCurrentColor] = useState(colors)

  const chartOptions = {
    options: {
      fill: {
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: false,
        markers: {
          radius: 100,
        },
        itemMargin: {
          horizontal: 24,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: true,
        style: {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontweight: '400',
          fontSize: '12px',
        },
        x: {
          show: false,
        },
        y: {
          formatter: function (value: number) {
            return `${Number(value.toFixed(2)).toLocaleString('pt-BR')} kWh`
          },
        },
        marker: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '65',
          borderRadius: interval.id === 31 ? 1 : 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: currentColor,
      chart: {
        id: 'bar-chart',
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      xaxis: {
        categories: getMonths,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          formatter: (value: number) => {
            return Number(value.toFixed(2)).toLocaleString('pt-BR')
          },
        },
        forceNiceScale: true,
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#808080',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
    series: apexSeries.map((item) => ({
      ...item,
      data: item.data.slice(maxInterval - interval.id, maxInterval),
    })),
  }

  useEffect(() => {
    if (data) {
      setApexSeries(ConsumerStartingValue)
    }
  }, [data])

  const handleDownloadButton = async () => {
    const schema = [
      {
        value: 'Mes referência',
        fontWeight: 'bold',
        fontSize: 14,
        align: 'center',
      },
      {
        value: 'Campo',
        fontWeight: 'bold',
        fontSize: 14,
        align: 'center',
      },
      {
        value: 'Valor',
        fontWeight: 'bold',
        fontSize: 14,
        align: 'center',
      },
    ]

    const documentTitle = [
      {
        value: `Desempenho Financeiro - ${interval.id === 13 ? 12 : interval.id} meses`,
        fontWeight: 'bold',
        fontSize: 16,
      },
    ]

    const columns = [{ width: 25 }, { width: 25 }, { width: 20 }]

    const generatedData = generatedEnergy as any
    const consumedData = consumedEnergy as any
    const reimbursedData = reimbursedEnergy as any

    const formattedDownloadArray = [
      ...generatedData.slice(maxInterval - interval.id, maxInterval).map((item: any) => [
        { type: Date, value: new Date(item.mes_referencia), format: 'dd/mm/yyyy', align: 'left' },
        { type: String, value: item.campo === 'geradora' ? 'gerada' : item.campo, align: 'left' },
        { type: Number, value: item.valor, format: '#,##0.00', align: 'right' },
      ]),
      ...consumedData.slice(maxInterval - interval.id, maxInterval).map((item: any) => [
        { type: Date, value: new Date(item.mes_referencia), format: 'dd/mm/yyyy', align: 'left' },
        {
          type: String,
          value: item.campo === 'consumido' ? 'consumida' : item.campo,
          align: 'left',
        },
        { type: Number, value: item.valor, format: '#,##0.00', align: 'right' },
      ]),
      ...reimbursedData.slice(maxInterval - interval.id, maxInterval).map((item: any) => [
        { type: Date, value: new Date(item.mes_referencia), format: 'dd/mm/yyyy', align: 'left' },
        {
          type: String,
          value: item.campo === 'compensado' ? 'compensada' : item.campo,
          align: 'left',
        },
        { type: Number, value: item.valor, format: '#,##0.00', align: 'right' },
      ]),
    ]

    await writeXlsxFile([documentTitle, schema, ...formattedDownloadArray], {
      columns,
      fileName: `historico_${
        interval.name.toLowerCase().replaceAll(' ', '-') +
        '-' +
        dateSelected.month +
        '-' +
        dateSelected.year
      }.xlsx`,
    })
  }

  return (
    <Widget style={{ minWidth: 540, height: 337, marginLeft: 16, zIndex: 10 }}>
      <Modal
        title='Histórico de energia'
        openModal={openModal}
        setModalOpen={setOpenModal}
        defaultButtons={false}
      >
        <ButtonsContainer>
          <CancelButton
            disabled={false}
            type='button'
            onClick={() => {
              setOpenModal(false)
            }}
          >
            Cancelar
          </CancelButton>
        </ButtonsContainer>
      </Modal>
      <ChartBody>
        <TitleDiv>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <TitleText>Histórico de energia</TitleText>
            {/* <GenericTooltip content='Histórico de energia significa...' direction='right'>
              <InfoIcon style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }} />
            </GenericTooltip> */}
          </div>
          <ButtonsDiv>
            <CalendarDiv onClick={() => setShowDropDown(!showDropDown)} showDropDown={showDropDown}>
              <img src={`${CalendarIcon}`} alt='Calendar Button' />
              <CalendarText>{interval.name}</CalendarText>
              <OptionList showDropDown={showDropDown}>
                {calendarOption.map((item) => (
                  <OptionItem
                    key={item.id}
                    onClick={() => {
                      setInterval({ id: item.id, name: item.name })
                      onChangePeriod && onChangePeriod(item.id)
                      setShowDropDown(false)
                    }}
                    active={interval.id === item.id}
                  >
                    {item.name}
                  </OptionItem>
                ))}
              </OptionList>
            </CalendarDiv>
            <DownloadDiv>
              <img
                src={`${DownloadIcon}`}
                alt='Download Button'
                onClick={() => handleDownloadButton()}
              />
            </DownloadDiv>
          </ButtonsDiv>
        </TitleDiv>
        <div>
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type='bar'
            width='100%'
            height={245}
          />
          <LegendDiv>
            <LegendSubDiv active={!removeFromIds.has(0)}>
              <LegendCircle color={Theme.colors.lightGreen} />
              <LegendText onClick={() => handleSeriesChange(0)}>Energia consumida</LegendText>
            </LegendSubDiv>
            <LegendSubDiv active={!removeFromIds.has(1)}>
              <LegendCircle color={Theme.colors.orange} />
              <LegendText onClick={() => handleSeriesChange(1)}>Energia compensada</LegendText>
            </LegendSubDiv>
            <LegendSubDiv active={!removeFromIds.has(2)}>
              <LegendCircle color={Theme.colors.darkishGreen} />
              <LegendText onClick={() => handleSeriesChange(2)}>Saldo</LegendText>
            </LegendSubDiv>
            <LegendSubDiv active={!removeFromIds.has(3)}>
              <LegendCircle color={Theme.colors.appleGreen} />
              <LegendText onClick={() => handleSeriesChange(3)}>Energia Injetada</LegendText>
            </LegendSubDiv>
          </LegendDiv>
        </div>
      </ChartBody>
    </Widget>
  )
}
