import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../../context/global/global'

type ApexSerie = {
  name: string
  data: number | number[]
}

interface Props<T extends ApexSerie> {
  chartData: Array<T>
  colors?: string[]
}

export function useChartInteraction<T extends ApexSerie>(props: Props<T>) {
  const { Theme } = useContext(GlobalContext)

  const {
    chartData,
    colors = [
      Theme.colors.lightGreen,
      Theme.colors.orange,
      Theme.colors.darkishGreen,
      Theme.colors.cream,
      Theme.colors.quartzGrey,
    ],
  } = props

  const [selectedSeries, setSelecetedSeries] = useState<string[]>([])

  const [colorSchema, setColorSchema] = useState<string[]>(colors)
  const [apexSeries, setApexSeries] = useState<T[]>(chartData)

  const handleSeriesChange = (serie: string) => {
    const updatedSelectedSeries = [...selectedSeries]

    const existingIndex = updatedSelectedSeries.findIndex((idx) => idx === serie)
    if (existingIndex === -1) {
      updatedSelectedSeries.push(serie)
    } else {
      updatedSelectedSeries.splice(existingIndex, 1)
    }

    setApexSeries(() => [
      ...chartData.filter(
        (apexSerie) => updatedSelectedSeries.findIndex((serie) => serie === apexSerie.name) !== -1,
      ),
    ])
    setColorSchema(() => [
      ...colors.filter(
        (apexSerie) => updatedSelectedSeries.findIndex((serie) => serie === apexSerie) !== -1,
      ),
    ])
    setSelecetedSeries(updatedSelectedSeries)
  }

  useEffect(() => {
    if (chartData) {
      setApexSeries(chartData)
      setColorSchema(colors)
      setSelecetedSeries(chartData.map((serie) => serie.name))
    }
  }, [chartData])

  return {
    colors,
    colorSchema,
    selectedSeries,
    handleSeriesChange,
    apexSeries,
  }
}
