import { Pagination, PaginationQuery } from '../interfaces/pagination'
import { PaymentMethodPlatform } from '../interfaces/payment-method'
import { convertObjectToQueryParams } from '../utils/pagination'
import { sanitizeFilename } from '../utils/string-utils'
import { api } from './api'

export interface ListPaymentMethods {
  result: {
    id: number
    name: string
  }[]
}
export interface ListPaymentMethodsSuper {
  params: PaginationQuery
  result: Pagination<{
    id: number
    name: string
    documentNumber: string
    platform: PaymentMethodPlatform
    manager: {
      name: string
    }
  }>
}
export interface FindPaymentMethod {
  params: {
    id: number
  }
  result: {
    bb_nome_consorcio: string
    documento_identificador: string
    cliente_gestor_id: number
    plataforma?: PaymentMethodPlatform
    asaas_api_key?: string
    bb_basic_token?: string
    gerar_pix?: boolean
    bb_boleto_tipo?: number
    bb_codigo_modalidade?: number
    bb_numero_carteira?: number
    bb_numero_variacao_carteira?: number
    conta_corrente?: number
    certificado_senha?: string
    numero_contrato?: number
    bb_app_key?: string
    bb_numero_convenio?: number
  }
}
export interface CreatePaymentMethod {
  params: {
    bb_nome_consorcio: string
    documento_identificador: string
    cliente_gestor_id: number
    plataforma?: PaymentMethodPlatform
    asaas_api_key?: string
    bb_basic_token?: string
    gerar_pix?: boolean
    bb_boleto_tipo?: number
    bb_codigo_modalidade?: number
    bb_numero_carteira?: number
    bb_numero_variacao_carteira?: number
    conta_corrente?: number
    certificado_senha?: string
    numero_contrato?: number
    certificado?: File
    bb_app_key?: string
    bb_numero_convenio?: number
  }
  result: void
}
export interface UpdatePaymentMethod {
  params: {
    paymentMethodId: number
    bb_nome_consorcio?: string
    documento_identificador?: string
    cliente_gestor_id?: number
    plataforma?: PaymentMethodPlatform
    asaas_api_key?: string
    bb_basic_token?: string
    gerar_pix?: boolean
    bb_boleto_tipo?: number
    bb_codigo_modalidade?: number
    bb_numero_carteira?: number
    bb_numero_variacao_carteira?: number
    conta_corrente?: number
    certificado_senha?: string
    numero_contrato?: number
    certificado?: File
    bb_app_key?: string
    bb_numero_convenio?: number
  }
  result: void
}

export class PaymentMethodsService {
  async createPaymentMethod(params: CreatePaymentMethod['params']): Promise<CreatePaymentMethod['result']> {
    const { certificado, ...data } = params
    const formData = new FormData()
    if(certificado) {
      formData.append('certificado', certificado, sanitizeFilename(certificado.name))
    }
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, String(value))
    }
    await api.post('/payment-methods', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  async updatePaymentMethod(params: UpdatePaymentMethod['params']): Promise<UpdatePaymentMethod['result']> {
    const { paymentMethodId, certificado, ...data } = params
    const formData = new FormData()
    if(certificado) {
      formData.append('certificado', certificado, sanitizeFilename(certificado.name))
    }
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, String(value))
    }
    await api.put(`/payment-methods/${paymentMethodId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  async listPaymentMethods(): Promise<ListPaymentMethods['result']> {
    const result = await api.get('/payment-methods')
    return result.data
  }
  async findPaymentMethod(params: FindPaymentMethod['params']): Promise<FindPaymentMethod['result']> {
    const { id } = params
    const result = await api.get(`/payment-methods/${id}`)
    return result.data
  }
  async listPaymentMethodsSuper(
    params: ListPaymentMethodsSuper['params'],
  ): Promise<ListPaymentMethodsSuper['result']> {
    const { filter, itemsPerPage, page, sort } = params
    const result = await api.get('/payment-methods/super', {
      params: {
        itemsPerPage,
        page,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
        ...(sort
          ? {
              field: sort.field,
              order: sort.order,
            }
          : {}),
      },
    })
    return result.data
  }
}

export const paymentMethodsService = new PaymentMethodsService()
