import { useState, useContext } from 'react'
import Chart, { Props as ApexProps } from 'react-apexcharts'
import { GlobalContext } from '../../../context/global/global'
import { InvoiceStatus, InvoiceStatusColor } from '../../../interfaces/invoices'
import Loading from '../../Loading'
import { ChartContainer } from './styles'

interface Props {
  data: {
    value: number
    label: InvoiceStatus
    color: InvoiceStatusColor
  }[]
  legendPosition?: 'bottom' | 'right'
  styles?: React.CSSProperties
  isConsumer?: boolean
  isLoading?: boolean
}

export const InvoicesDonut: React.FC<Props> = ({
  data,
  legendPosition,
  styles,
  isConsumer,
  isLoading,
}) => {
  const { Theme } = useContext(GlobalContext)

  const chartProps: ApexProps = {
    series: data.map(({ value }) => value),
    type: 'donut',
    options: {
      labels: data.map(({ label }) => label),
      colors: data.map(
        ({ label }) =>
          Theme.colors[
            InvoiceStatusColor[label] as unknown as
              | 'darkishGreen'
              | 'lightGreen'
              | 'orange'
              | 'cream'
          ],
      ),
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        fillSeriesColor: false,
      },

      chart:
        legendPosition === 'right'
          ? {
              offsetX: -40,
              offsetY: -8,
            }
          : {},
      legend: {
        position: legendPosition ?? 'bottom',
        horizontalAlign: 'left',
        height: 120,
        itemMargin: {
          vertical: 0,
          horizontal: 0,
        },
        offsetY: legendPosition === 'right' ? -15 : 0,
        offsetX: legendPosition === 'right' ? 30 : -30,
        fontSize: '14',
        labels: {
          colors: Theme.colors.htmlGrey,
        },
      },
    },
  }

  return (
    <ChartContainer
      style={styles}
      horizontal={legendPosition === 'right'}
      isConsumer={isConsumer ?? false}
    >
      <h3>Visão geral das faturas</h3>
      {isLoading ? (
        <Loading />
      ) : isConsumer ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Chart
            {...chartProps}
            width={legendPosition === 'right' ? 330 : 200}
            height={legendPosition === 'right' ? 120 : 350}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', margin: '10px' }}>
          <Chart
            {...chartProps}
            width={legendPosition === 'right' ? 330 : 200}
            height={legendPosition === 'right' ? 120 : 350}
          />
        </div>
      )}
    </ChartContainer>
  )
}
