import { useState } from "react"
import { invoiceErrorsService } from "../../../../../services/invoice-errors"
import { useToast } from "../../../../../hooks/useToast"
import { Button } from "../../../../../components/Button"

interface Props {
  readerErrorId: number
}

export default function DownloadReaderErrorFilesButton(props: Props) {
  const { readerErrorId } = props

  const [isDownloading, setDownloading] = useState<boolean>(false)

  const { toast } = useToast()

  const downloadInvoices = async () => {
    if (isDownloading) {
      return
    }
    try {
      setDownloading(true)
      const fileBuffer = await invoiceErrorsService.downloadErrorFiles({
        readerErrorId,
      })
      const blob = new Blob([fileBuffer])
      const element = document.createElement('a')
      element.href = URL.createObjectURL(blob)
      element.download = 'faturas.zip'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      URL.revokeObjectURL(element.href)
      toast({
        message: 'Faturas baixadas com sucesso',
        type: 'success',
      })
    } catch (error: any) {
      toast({
        message: error.message ?? 'Erro ao baixar faturas',
        type: 'error',
      })
    } finally {
      setDownloading(false)
    }
  }

  return (
    <Button
      containerStyle={{ margin: 'auto 0 0 0' }}
      text='Baixar Faturas'
      onClick={downloadInvoices}
      isLoading={isDownloading}
    />
  )
}
