import { SettingsOutlined } from '@mui/icons-material'
import SolarPowerOutlinedIcon from '@mui/icons-material/SolarPowerOutlined'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useContext, useEffect, useState } from 'react'

import { ReactComponent as boltIcon } from '../../../assets/bolt.svg'
import { ReactComponent as Dollar } from '../../../assets/dollar-green.svg'
import { ReactComponent as powerPlugIcon } from '../../../assets/power-plug.svg'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import Loading from '../../../components/Loading'
import { SearchInput } from '../../../components/SearchInput'
import {
  CreditsBalanceTableRes,
  CreditsBalance as CreditsBalanceType,
} from '../../../services/requests/types'
import {
  consumerListGet,
  creditsBalanceDownload,
  creditsBalanceInfos,
  creditsBalanceTable,
  powerPlantListGet,
} from '../../../services/requests/user-requests'

import { DateFilter } from '../../../components/Filter/DateFilter'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import { GlobalContext } from '../../../context/global/global'
import { CheckboxType } from '../../../interfaces/checkbox'
import { CustomersType } from '../../MyUnits/MyUnitsTables/ConsumerUnit/types'
import { CreditsBalanceTableColumns } from '../utils/TableData'
import { AllocationContainer, Header, TableHeader } from './styles'
import CompensationModalityFilter from './components/CompensationModalityFilter'
import WidgetInfoV2 from '../../../components/WidgetInfoV2'
import DownloadSheet from './components/DownloadSheet'
import { useToast } from '../../../hooks/useToast'
import PaymentMethodFilter from './components/PaymentMethodFilter'
import { TableV2 } from '../../../components/TableV2'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'
import { Sort } from '../../../interfaces/conference'

export default function CreditsBalance() {
  const { state } = useContext(GlobalContext)
  const { toast } = useToast()

  const [tableCols, setTableCols] = useState<GridColDef[]>(CreditsBalanceTableColumns)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [search, setSearch] = useState('')
  const [balanceInfos, setBalanceInfos] = useState<Omit<CreditsBalanceType, 'economia_ucs'>>({
    geracao_total: {
      percentual: 0,
      valor: 0,
    },
    compensacao_total: {
      percentual: 0,
      valor: 0,
    },
    consumo_total: {
      percentual: 0,
      valor: 0,
    },
    remuneracao_usina: {
      percentual: 0,
      valor: 0,
    },
  })
  const [balancesTotal, setBalancesTotal] = useState(1)
  const [balancesTable, setBalancesTable] = useState<CreditsBalanceTableRes['data']>([])
  const [powerPlants, setPowerPlants] = useState<CheckboxType[]>([])
  const [customerFilter, setCustomerFilter] = useState<CheckboxType<CustomersType>[]>([])
  const [page, setPage] = useState(1)
  const [compensationModalityFilter, setCompensationModalityFilter] = useState<string>()
  const [paymentMethodFilter, setPaymentMethodFilter] = useState<number[]>([])
  const [sortValues, setSortValues] = useState<Sort>()
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const { type: authType, id: authId } = state.auth?.customer || {}
  const { email } = state.auth || {}

  useEffect(() => {
    const isGestor = authType === 'GESTOR'
    if (!powerPlants.length && !customerFilter.length) {
      if (!isGestor) {
        powerPlantListGet()
          .then((res) => {
            setPowerPlants(
              res.data.map((item) => ({
                id: item.id,
                label: item.nome,
                checked: false,
              })),
            )
          })
          .catch((err) => console.log(err))
      } else {
        Promise.all([powerPlantListGet(), consumerListGet()])
          .then(async ([powerPlantsData, consumerData]) => {
            setPowerPlants(
              powerPlantsData.data.map((item) => ({
                id: item.id,
                label: item.nome,
                checked: false,
              })),
            )

            setCustomerFilter(
              consumerData.data.map((item) => ({
                id: item.id,
                label: item.cliente_nome,
                checked: false,
              })),
            )
          })
          .catch((error) => {
            setErrorMessage(error.message ?? 'Erro ao atualizar dados dos gráficos.')
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [])

  // useEffect(() => {
  //   if (powerPlants.length > 0) {
  //     const powerPlantIds = powerPlants.filter((item) => item.checked).map((item) => item.id)

  //     Promise.all([consumerListGet(powerPlantIds as number[])])
  //       .then(async ([consumerListGet]) => {
  //         setCustomerFilter(
  //           consumerListGet.data.map((item) => ({
  //             id: item.id,
  //             label: item.cliente_nome,
  //             checked: true,
  //           })),
  //         )
  //       })
  //       .catch((error) => {
  //         setErrorMessage(error?.response?.data.message ?? 'Erro ao buscar os consumidores.')
  //       })
  //       .finally(() => {
  //         setLoading(false)
  //       })
  //   }
  // }, [powerPlants])

  function updatePage(month: number, year: number, itemsPerPage: number) {
    setLoading(true)

    const powerPlantIds = powerPlants.filter((item) => item.checked).map((item) => item.id)
    const consumersId =
      authType === 'GESTOR'
        ? customerFilter.filter((item) => item.checked).map((item) => item.id)
        : [authId as number]

    const haveAllPowerPlantsBeenSelected = powerPlants?.every(item => !item.checked)
    const filters = {
      ...(search ? { search } : {}),
      ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      ...(paymentMethodFilter.length > 0 ? { paymentMethod: paymentMethodFilter } : {}),
      haveAllPowerPlantsBeenSelected
    }

    Promise.all([
      creditsBalanceInfos({
        powerPlantIds,
        filter: filters,
        consumersId,
        year,
        month 
      }),
      creditsBalanceTable({
        powerPlantIds,
        sort: sortValues?.field,
        order: sortValues?.order,
        consumersId,
        page,
        month,
        year,
        filter: filters,
        itemsPerPage,
      }),
    ])
      .then(async ([creditsInfoData, creditsTableData]) => {
        const creditsBalanceRes = creditsInfoData?.data

        setBalanceInfos({
          geracao_total: creditsBalanceRes?.generatedEnergy,
          compensacao_total: creditsBalanceRes?.compensatedEnergy,
          consumo_total: creditsBalanceRes?.consumedEnergy,
          remuneracao_usina: creditsBalanceRes?.revenue,
        })

        const creditsBalancesTableRes = creditsTableData

        setBalancesTotal(creditsBalancesTableRes.data.meta.total ?? 0)

        setBalancesTable(
          creditsBalancesTableRes.data.data
            ? creditsBalancesTableRes.data.data.map((item) => ({
              ...item,
              id: `${item.uc_numero}${item.id}`,
            }))
            : [],
        )
      })
      .catch((error) => {
        setErrorMessage(error.message ?? 'Erro ao buscar os dados de alocação de créditos.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (powerPlants.length > 0) {
      updatePage(dateSelected.month, dateSelected.year, pageItemsAmount)
    }
  }, [
    page,
    sortValues,
    powerPlants,
    ready,
    customerFilter,
    dateSelected,
    pageItemsAmount,
    compensationModalityFilter,
    paymentMethodFilter,
  ])

  useEffect(() => {
    if (search !== undefined) {
      const delayDebounce = setTimeout(() => {
        if (search.length >= 3 || search.length === 0) {
          setPage(1)
          setReady(!ready)
        }
      }, 1000)

      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  useEffect(() => {
    if (
      email &&
      [
        'cesup.projetos@bb.com.br',
        'alves-marcelo97@hotmail.com',
        'matheus.izidro@ilumisolenergiasolar.com.br',
      ].includes(email)
    ) {
      setTableCols((cols) =>
        cols.map((col) => {
          if (col.field === 'remuneracao_gerador' || col.field === 'economia') {
            return {
              ...col,
              hide: true,
            }
          }
          if (col.field === 'saldo_de_creditos') {
            return {
              ...col,
              hide: false,
            }
          }
          return col
        }),
      )
    }
  }, [])

  const handlePageChange = (nextPage: number) => {
    if (nextPage !== page) {
      setPage(nextPage)
    }
  }

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  const handleDownloadButton = async (referenceMonths: string[]) => {
    const powerPlantIds = powerPlants.filter((item) => item.checked).map((item) => item.id)
    const consumerId = customerFilter.filter((item) => item.checked).map((item) => item.id)
    const columns = tableCols.map((col) => ({ [col.field]: !col.hide }))
    const haveAllPowerPlantsBeenSelected = powerPlants?.every(item => item.checked)


    const filters = {
      ...(search ? { search } : {}),
      ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      ...(paymentMethodFilter.length > 0 ? { paymentMethod: paymentMethodFilter } : {}),
      haveAllPowerPlantsBeenSelected
    }

    try {
      const response = await creditsBalanceDownload({
        monthsRefs: referenceMonths,
        powerPlantIds,
        consumerId,
        sort: sortValues?.field,
        order: sortValues?.order,
        page,
        filter: filters,
        columns,
      })

      const blob = new Blob([response.data])
      const element = document.createElement('a')
      element.href = URL.createObjectURL(blob)
      element.download = `balanço_mensal_${dateSelected.month + '_' + dateSelected.year}.xlsx`

      document.body.appendChild(element)
      element.click()
    } catch (error) {
      toast({
        message: 'Erro ao baixar planilha',
        type: 'error'
      })
      throw error
    }
  }

  const filterPowerPlants = (values: CheckboxType[]) => {
    const powerPlantIds = values.filter((item) => item.checked).map((item) => item.id)

    Promise.all([consumerListGet(powerPlantIds as number[])])
      .then(async ([consumerListGet]) => {
        setCustomerFilter(
          consumerListGet.data.map((item) => ({
            id: item.id,
            label: item.cliente_nome,
            checked: true,
          })),
        )
      })
      .catch((error) => {
        setErrorMessage(error.message ?? 'Erro ao buscar os consumidores.')
      })

    setPowerPlants(values)
    setPage(1)
  }

  const handleFilterConsumer = async (checkboxes: CheckboxType<CustomersType>[]) => {
    setCustomerFilter(checkboxes)
    setPage(1)
  }

  if (loading) return <Loading />

  return (
    <AllocationContainer>
      {balanceInfos && (
        <>
          <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
          <Header>
            <WidgetInfoV2
              icon={SolarPowerOutlinedIcon}
              title='Energia Gerada'
              value={Math.trunc(balanceInfos.geracao_total.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={balanceInfos.geracao_total.percentual ?? 0}
            />
            <WidgetInfoV2
              icon={boltIcon}
              title='Energia Consumida'
              value={Math.trunc(balanceInfos.consumo_total.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={balanceInfos.consumo_total.percentual ?? 0}
            />
            <WidgetInfoV2
              icon={powerPlugIcon}
              title='Energia Compensada'
              value={Math.trunc(balanceInfos.compensacao_total.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={balanceInfos.compensacao_total.percentual ?? 0}
            />
            <WidgetInfoV2
              icon={Dollar}
              title='Faturamento'
              value={balanceInfos.remuneracao_usina.valor}
              inRelationToText='em relação ao mês anterior'
              variation={balanceInfos.remuneracao_usina.percentual ?? 0}
              measurement
            />
          </Header>
          <TableHeader>
            <div>
              <FloatingCheckboxes
                isFilterActive={powerPlants.some((powerplant) => powerplant.checked)}
                label='Usinas'
                styles={{ marginRight: 16, marginLeft: 16 }}
                searchable
                selectableAll
                options={powerPlants || []}
                submitAction={filterPowerPlants}
              />
              {authType === 'GESTOR' && (
                <FloatingCheckboxes
                  isFilterActive={customerFilter.some((customer) => customer.checked)}
                  key={customerFilter.toString()}
                  label='Consumidores'
                  options={customerFilter}
                  selectableAll
                  searchable
                  submitAction={handleFilterConsumer}
                />
              )}
              <CompensationModalityFilter
                style={{ marginLeft: '16px' }}
                compensationModalityFilter={compensationModalityFilter}
                setCompensationModalityFilter={setCompensationModalityFilter}
              />

              {/* TODO: temporarily removed until we have all the clients with the necessary data to make this query without breaking */}
              {/* {state.auth && state.auth.customer && state.auth.customer.type === 'GESTOR' &&  */}
              {/*   <PaymentMethodFilter  */}
              {/*     style={{ marginLeft: '16px' }} */}
              {/*     paymentMethodFilter={paymentMethodFilter} */}
              {/*     setPaymentMethodFilter={setPaymentMethodFilter} */}
              {/*   /> */}
              {/* } */}
            </div>
            <div>
              <SearchInput
                value={search}
                setValue={setSearch}
                style={{ width: 180, marginRight: 16 }}
              />
              <DownloadSheet dateSelected={dateSelected} onDownload={handleDownloadButton} />
              <FloatingCheckboxes
                pagination={{
                  selected: pageItemsAmount,
                  setSelected: setPageItemsAmount,
                  options: [15, 20, 30, 50, 100],
                }}
                isFilterActive={false}
                title='Configurar Tabela'
                options={tableCols.map((col) => ({
                  label: col.headerName || '',
                  checked: !col.hide,
                  id: Math.random(),
                }))}
                customActionComponent={
                  <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
                }
                submitBtnText='Salvar'
                submitAction={handleSelectTableColumns}
              />
            </div>
          </TableHeader>
          <TableV2
            columns={tableCols}
            rows={balancesTable}
            alignText='center'
            messageNoRow='Não há tickets cadastrados.'
            sort={sortValues}
            onSort={(sort) => {
              setSortValues(sort)
              setPage(1)
            }}
            serverSorting
          >
            <TablePagination
              pageInfo={{
                totalCount: balancesTotal,
                currentPage: page,
                totalPages: Math.ceil(balancesTotal / pageItemsAmount),
                limit: pageItemsAmount
              }}
              currentPageItemCount={balancesTable?.length ?? 0}
              onChange={handlePageChange}
            />
          </TableV2>
        </>
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <AlertTitle>{errorMessage}</AlertTitle>
        </Alert>
      </Snackbar>
    </AllocationContainer>
  )
}
