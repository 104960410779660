import styled from 'styled-components'

export const ChartContainer = styled.div`
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChartBody = styled.div`
  padding: 16px 16px 20px 0px;
  width: 100%;
`

export const TitleDiv = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TitleText = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;

  color: ${({ theme }) => theme.colors.htmlGrey};
`