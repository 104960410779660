import { useEffect, useState } from 'react'
import Modal from '../../../ModalV2'
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { CheckCircle, Error } from '@mui/icons-material'
import { Button } from '../../../Button'
import { useSocket } from '../../../../hooks/useSocket'

type PaymentsUpdatesResponse = Array<{
  invoiceClosingId: number
  success?: boolean
  error?: string
  uc?: {
    name: string
    code: string
  }
}>

const getIcon = (paymentInfo: PaymentsUpdatesResponse[number]) => {
  if (!paymentInfo.success && !paymentInfo.error) {
    return <CircularProgress style={{ width: '20px', height: '20px', marginRight: '16px' }} />
  }
  if (paymentInfo.error) {
    return <Error color='error' style={{ marginRight: '16px' }} />
  }
  return <CheckCircle color='success' style={{ marginRight: '16px' }} />
}


interface Props {
  invoicesToListen: PaymentsUpdatesResponse
  isOpen?: boolean
  onClose: () => void
}

export default function PaymentStatusModal(props: Props) {
  const { isOpen, invoicesToListen, onClose } = props

  const { socket } = useSocket()

  const [paymentResponse, setPaymentResponse] = useState<PaymentsUpdatesResponse>([])

  useEffect(() => {
    setPaymentResponse(
      invoicesToListen.map((invoice) => ({
        invoiceClosingId: invoice.invoiceClosingId,
        uc: invoice.uc,
      })),
    )
  }, [invoicesToListen])

  const receivePaymentUpdate = (response: PaymentsUpdatesResponse) => {
    setPaymentResponse((previousResponseList) => {
      return [...previousResponseList].map((previousResponse) => {
        const responseFound = response.find(
          (currentResponse) =>
            currentResponse.invoiceClosingId === previousResponse.invoiceClosingId,
        )
        return {
          ...previousResponse,
          ...(responseFound ?? {}),
        }
      })
    })
  }

  useEffect(() => {
    if(socket) {
      socket.on('payments_sent_update', receivePaymentUpdate)
    }
    return () => {
      if(socket) {
        socket.off('payments_sent_update', receivePaymentUpdate)
      }
    }
  }, [socket])

  return (
    <Modal.Provider
      style={{
        display: !isOpen ? 'none' : 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '550px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Envio de faturamento
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body
        style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}
      >
        <List>
          {paymentResponse.map((paymentInfo) => (
            <ListItem
              key={`payment-info-${paymentInfo.invoiceClosingId}`}
              style={{
                backgroundColor: 'rgb(241, 241, 241)',
                marginBottom: '8px',
                borderRadius: '8px',
              }}
            >
              <Box display='flex' alignItems='center' width='100%'>
                {getIcon(paymentInfo)}
                <ListItemText
                  primary={
                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                      {paymentInfo.uc ? `${paymentInfo.uc.name} (${paymentInfo.uc.code})` : ``}
                    </Typography>
                  }
                  secondary={
                    <>
                      {paymentInfo.error && (
                        <Typography variant='body2' color='error' style={{ display: 'block' }}>
                          {paymentInfo.error}
                        </Typography>
                      )}
                    </>
                  }
                />
              </Box>
              <Divider />
            </ListItem>
          ))}
        </List>

        <Button containerStyle={{ margin: 'auto 0 0 auto', width: '50%' }} text='Fechar' onClick={onClose} />
      </Modal.Body>
    </Modal.Provider>
  )
}
