import { useFormik } from 'formik'
import { Button, Form, Input, Loading } from './styles'
import { RefObject, useState } from 'react'
import { ticketsService } from '../../../../../../services/tickets'
import { Send } from '@mui/icons-material'

interface FormData {
  message: string
}

interface Props {
  ticketId: number
  textAreaRef: RefObject<HTMLTextAreaElement>
  onChange: () => void
  onReply: () => void
}

export default function ReplyForm(props: Props) {
  const { textAreaRef, ticketId, onChange, onReply } = props

  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const replyTicket = async (values: FormData) => {
    if (!values.message) {
      return
    }
    try {
      setSubmitting(true)
      await ticketsService.replyTicket({
        message: values.message,
        ticketId,
      })

      formik.resetForm()
      onReply()
    } catch (error) {
      console.log(`Error replying ticket: ${error}`)
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: { message: '' },
    onSubmit: replyTicket,
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Input
        ref={textAreaRef}
        name='message'
        value={formik.values.message}
        onChange={e => {
          formik.handleChange(e)
          onChange()
        }}
        onBlur={formik.handleBlur}
      />

      <Button type='submit' disabled={isSubmitting}>
        {!isSubmitting ? <Send /> : <Loading />}
      </Button>
    </Form>
  )
}
