import styled from 'styled-components'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export const Label = styled.h4<{ mode?: boolean | number; required?: boolean }>`
  color: ${({ mode, theme }) => (mode ? theme.colors.quartzGrey : theme.colors.white)};
  margin-bottom: 4px;
  padding-left: ${({ mode }) => (mode ? `0px` : `15px`)};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  ${({ required, theme }) =>
    required &&
    `::after {
    content: '*';
    color:${theme.colors.orange};`}
`
export const InputField = styled.input<{
  mode?: boolean | number
  halfSize?: boolean
  type?: string
  sizeForm?: boolean
  heightInput?: number | string
}>`
  background: ${({ mode }) => (mode ? `#F1F1F1` : `rgba(0, 0, 0, 0.21)`)};
  border-radius: 8px;
  border: none;
  padding-left: ${({ type }) => (type === 'search' ? '36px' : '15px')};
  padding-right: ${({ type }) => (type === 'search' ? '15px' : '36px')};
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${({ mode }) => (mode ? `black` : `white`)};
  height: ${(props) => props.heightInput ?? '45px'};

  ${({ mode, halfSize, sizeForm }) =>
    sizeForm
      ? mode && halfSize
        ? `width: 518px`
        : `width: 1052px;`
      : mode && halfSize
      ? `width: 205px`
      : `width: 420px;`}

  ${({ type }) =>
    type === 'search' &&
    `
  ::placeholder, ::-webkit-input-placeholder {
    font-family: var(--roboto);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;

    color: #adadad;
  }
  
  `}
`

export const Icon = styled(VisibilityIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 8px;
`

export const IconHiden = styled(VisibilityOffIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 8px;
`
export const InputDiv = styled.div<{ mode?: boolean | number }>`
  position: relative;
  ${({ mode }) => mode && `margin-bottom: 16px;`}
`

export const SearchIcon = styled.img`
  position: absolute;
  left: 12px;
  top: 10px;
  height: 16px;
  width: 16px;
`
