import { Alert, Snackbar } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as DollarIcon } from '../../assets/dollar.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon-white.svg'
import { ReactComponent as NonPaymentIcon } from '../../assets/non-payment.svg'
import { ReactComponent as ReceiptX } from '../../assets/receipt-x.svg'
import { ReactComponent as ReceivedAmountIcon } from '../../assets/received-amount.svg'
import { ReactComponent as SendIcon } from '../../assets/send.svg'
import { FloatingCheckboxes } from '../../components/FloatingCheckboxes'
import { FloatingSelects } from '../../components/FloatingSelects'
import Loading from '../../components/Loading'
import Modal from '../../components/Modal'
import { EditPaymentModal } from '../../components/Modal/Payments/EditPaymentModal'
import { SendButton } from '../../components/Modal/Payments/EditPaymentModal/styles'
import { SearchInput } from '../../components/SearchInput'
import { ActionTableButton } from '../../components/Table/ActionTableButton'
import { WidgetPayments } from '../../components/WidgetPayments'
import { CheckboxType } from '../../interfaces/checkbox'
import {
  deletePayment,
  distributorListGet,
  downloadCsvGet,
  downloadCsvGetAllgreen,
  getInvoiceData,
  paymentsInfoGet,
  paymentsInfoGetAllgreen,
  powerPlantListGet,
} from '../../services/requests/user-requests'
import {
  ConfigButtonsColumn,
  ContainerPreviewInvoice,
  FiltersContainer,
  OptionItem,
  OptionList,
  PaymentsContainer,
  SettingsContainer,
  WidgetsContainer,
} from './styles'
import { PaymentsInfoData } from './types'
import { getPaymentColumns } from './utils'

import { SettingsOutlined } from '@mui/icons-material'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { DateFilter } from '../../components/Filter/DateFilter'
import { FloatingFilterWrapper } from '../../components/FloatingFilterWrapper'
import ModalDelete from '../../components/Modal/ModalDelete'
import { PaymentExcel } from '../../components/Modal/PaymentExcel'
import { PaymentsBalanceSheet } from '../../interfaces/payments'
import { paymentsService } from '../../services/payments'
import CompensationModalityFilter from '../Credits/Balance/components/CompensationModalityFilter'
import './style.css'
import { usePermissions } from '../../hooks/usePermissions'
import { PermissionType } from '../../interfaces/permissions'
import { Sort } from '../../interfaces/conference'
import { TableV2 } from '../../components/TableV2'
import { TablePagination } from '../../components/TableV2/components/TablePagination'
import PaymentMethodFilter from '../Credits/Balance/components/PaymentMethodFilter'
import { GlobalContext } from '../../context/global/global'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface invoicePreviewData {
  type: 'html' | 'pdf'
  toRender: string | { type: string; data: Buffer }
  toDownload: {
    filename: string
    content: { type: string; data: Buffer }
  }
}

interface IPreviewInvoiceProps {
  invoicePreviewData: invoicePreviewData
  downloadingInvoice: boolean
  setDownloadingInvoice: React.Dispatch<React.SetStateAction<boolean>>
}

// Temporary code, remove after refactoring this page
const paymentStatusMap: Record<string, string> = {
  '1': 'Cobrado',
  '2': 'Não cobrado',
  '3': 'Recebido',
  '4': 'Vencido',
}

const PreviewHtmlInvoice = ({ toRender }: Pick<invoicePreviewData, 'toRender'>) => {
  const ContainerPreviewInvoiceRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ContainerPreviewInvoiceRef.current) {
      ContainerPreviewInvoiceRef.current.innerHTML = toRender as string
    }
  }, [ContainerPreviewInvoiceRef])

  return <div style={{ maxWidth: '780px' }} ref={ContainerPreviewInvoiceRef} />
}

const PreviewInvoice = ({
  invoicePreviewData,
  downloadingInvoice,
  setDownloadingInvoice,
}: IPreviewInvoiceProps) => {
  const downloadPreview = async (pdf: Buffer, fileName: string) => {
    setDownloadingInvoice(true)
    if (invoicePreviewData) {
      // example: https://thelearning.dev/how-to-download-files-on-button-click-reactjs

      // file object
      const file = new Blob([new Uint8Array(pdf).buffer])

      // anchor link
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)

      element.download = fileName

      // simulate link click
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
    }
    setDownloadingInvoice(false)
  }

  const PreviewType = ({ type, toRender }: Omit<invoicePreviewData, 'toDownload'>) => {
    switch (type) {
      case 'html':
        return <PreviewHtmlInvoice toRender={toRender as string} />

      case 'pdf':
        return (() => {
          const myBuffer = (toRender as { data: Buffer }).data
          return (
            <Document
              file={{ data: new Uint8Array(myBuffer) }}
              onSourceError={(error) => console.log('source error', error)}
              loading={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <span>Carregando pré-visualização...</span>
                </div>
              }
              onLoadError={(error) => console.log('load error', error)}
            >
              <Page pageNumber={1} width={720} />
            </Document>
          )
        })()
    }
  }

  return (
    <ContainerPreviewInvoice>
      {invoicePreviewData?.toDownload && (
        <SendButton
          onClick={() =>
            downloadPreview(
              invoicePreviewData.toDownload.content.data,
              invoicePreviewData.toDownload.filename,
            )
          }
          disabled={downloadingInvoice}
        >
          {downloadingInvoice ? 'Carregando..' : 'Baixar PDF'}
        </SendButton>
      )}
      {invoicePreviewData?.type && invoicePreviewData?.toRender && (
        <PreviewType type={invoicePreviewData.type} toRender={invoicePreviewData.toRender} />
      )}
      {!invoicePreviewData && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span>Carregando informações...</span>
        </div>
      )}
    </ContainerPreviewInvoice>
  )
}

const legacyClients = [2657,1540] // Allgreen, Atua

export function Payments() {
  const { checkPermission } = usePermissions()
  const { state } = useContext(GlobalContext)
  const isALegacyClient = state.auth?.customer?.id ? legacyClients.includes(state.auth?.customer?.id) : false

  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [ready, setReady] = useState(false)
  const [modalExcel, setModalExcel] = useState(false)
  const [totalCountPayments, setTotalCountPayments] = useState(0)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [compensationModalityFilter, setCompensationModalityFilter] = useState<string>()
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [distributors, setDistributors] = useState<
    {
      id: number | string
      label: string
      checked?: boolean
    }[]
  >([])
  const [companies, setCompanies] = useState<number[]>([])
  const [powerPlants, setPowerPlants] = useState<
    {
      id: number | string
      label: string
      checked?: boolean
    }[]
  >([])

  const [payments, setPayments] = useState<PaymentsBalanceSheet>()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isCancelPaymentOpen, setIsCancelPaymentOpen] = useState(false)
  const [isSendOpen, setIsSendOpen] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState<any>()
  const [paymentsInfo, setPaymentsInfo] = useState<PaymentsInfoData>()
  const [status, setStatus] = useState<number>()
  const [update, setUpdate] = useState(false)
  const [day, setDay] = useState(0)
  const [dayFilterArray, setDayFilterArray] = useState<string[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [showDropDown, setShowDropDown] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [sortValues, setSortValues] = useState<Sort>()
  const [showInvoicePreview, setShowInvoicePreview] = useState<boolean>(false)
  const [invoicePreviewData, setInvoicePreviewData] = useState<any>(null)
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false)
  const [tableCols, setTableCols] = useState<GridColDef[]>([])
  const rows = useMemo(
    () => (payments?.data ?? []).map((item) => ({ ...item, id: item.fechamento_id })) || [],
    [JSON.stringify(payments?.data, null, 3)]
  )
  const [isSendAllOpen, setIsSendAllOpen] = useState(false)

  function handleChangePage(page: number) {
    setPage(page)
  }

  function handleSendCharge(value: any) {
    setSelectedPayment(value)
    setIsEditOpen(true)
  }

  function handleDrive(id: string) {
    const url = `${process.env.REACT_APP_BASE_URL}/faturas/download/${id}`
    window.open(url, '_blank')
  }

  async function handleSendAllCharges() {
    if (!selectedIds.length) {
      setErrorMessage('Selecione pelo menos uma cobrança.')
      return
    }

    setIsSendAllOpen(true)
  }

  async function getPaymentsInfoData() {
    try {
      const filters = {
        ...(search ? { search } : {}),
        ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      }

      let data;

      if(isALegacyClient) {
        const { data: result } = await paymentsInfoGetAllgreen({
          page,
          filter: filters,
          limit: pageItemsAmount,
          sort: sortValues?.field,
          order: sortValues?.order,
          distributorsIds:
            distributors.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          powerPlantsIds:
            powerPlants.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          status: status ? paymentStatusMap[String(status)] : '',
          year: dateSelected.year,
          month: dateSelected.month,
          day,
          companies,
        })

        data = result
      } else {
        const { data: result } = await paymentsInfoGet({
          page,
          filter: filters,
          distributorsId:
            distributors.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          powerPlantsId:
            powerPlants.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          status: status ? paymentStatusMap[String(status)] : '',
          monthReference: `${dateSelected.year}-${String(dateSelected.month).padStart(2, '0')}-01`,
          day: day > 0 ? day : undefined,
        })

        data = result
      }

      if (data) {
        setPaymentsInfo(data)
      }
    } catch (error) {
      setErrorMessage('Erro ao carregar os dados de pagamentos.')
    }
  }

  async function getData() {
    try {
      const filters = {
        ...(search ? { search } : {}),
        ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      }
      let data;
      if(isALegacyClient) {
        data = await paymentsService.getBalanceSheetAllgreen({
          page,
          itemsPerPage: pageItemsAmount,
          sort:
            sortValues && sortValues.field && sortValues.order
              ? {
                field: sortValues.field,
                order: sortValues.order as any,
              }
              : undefined,
          distributorsId:
            distributors.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          powerPlantsId:
            powerPlants.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          monthsRefs: [`${dateSelected.year}-${String(dateSelected.month).padStart(2, '0')}-01`],
          day: day > 0 ? day : undefined,
          status,
          filter: filters,
          companies,
        })
      } else {
        data = await paymentsService.getBalanceSheet({
          page,
          itemsPerPage: pageItemsAmount,
          sort:
            sortValues && sortValues.field && sortValues.order
              ? {
                field: sortValues.field,
                order: sortValues.order as any,
              }
              : undefined,
          distributorsId:
            distributors.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          powerPlantsId:
            powerPlants.filter((item) => item.checked).map((item) => item.id as number) ?? [],
          monthReference: `${dateSelected.year}-${String(dateSelected.month).padStart(2, '0')}-01`,
          dueDayReference: day > 0 ? day : undefined,
          status,
          filter: filters,
          companies,
        })
      }
      
      setPayments(data)
      setTotalCountPayments(data.pageInfo.totalCount)
    } catch (error) {
      console.log({ error })
      setErrorMessage('Erro ao carregar os dados de pagamentos.')
    }
  }

  useEffect(() => {
    if (payments != null) {
      const delayDebounce = setTimeout(() => {
        setPage(1)
        setReady(!ready)
      }, 2000)
      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  useEffect(() => {
    ; (async () => {
      await Promise.all([getData(), getPaymentsInfoData()])
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    const daysInMonth = new Date(dateSelected.year, dateSelected.month, 0).getDate()
    setDayFilterArray([...new Array(daysInMonth)].map((_, index) => `${index + 1}`))
    setDay(0)
  }, [dateSelected])

  useEffect(() => {
    Promise.all([distributorListGet(), powerPlantListGet()])
      .then(async ([distributorData, powerPlantData]) => {
        const distribuitorPayload = distributorData.data.map(
          (distributor: { id: number; nome: string }) => {
            return {
              id: distributor.id,
              label: distributor.nome,
              checked: false,
            }
          },
        )
        setDistributors(distribuitorPayload)

        const powerPlantPayload = powerPlantData.data.map(
          (powerPlant: { id: number; nome: string }) => {
            return {
              id: powerPlant.id,
              label: powerPlant.nome,
              checked: false,
            }
          },
        )

        setPowerPlants(powerPlantPayload)
      })
      .catch((error) => {
        setErrorMessage(error.message ?? 'Erro ao atualizar dados dos gráficos.')
      })
  }, [])

  function filterPowerPlants(values: CheckboxType[]) {
    if (!values.length) return
    setPage(1)
    setPowerPlants(values)
  }

  function filterDistributors(values: CheckboxType[]) {
    if (!values.length) return
    setPage(1)
    setDistributors(values)
  }

  useEffect(() => {
    if (!showInvoicePreview) {
      setInvoicePreviewData(null)
    }
  }, [showInvoicePreview])

  async function previewInvoice(value: number) {
    try {
      setShowInvoicePreview(true)
      const {
        data: { data },
      } = await getInvoiceData({ ...dateSelected, fechamento_id: value })

      if (data) {
        setInvoicePreviewData(data)
      } else {
        setShowInvoicePreview(false)
        setErrorMessage(
          'Algo deu errado, tente novamente e caso o erro persista contate o suporte.',
        )
      }
    } catch (err) {
      console.error('invoice preview data', err)
      setShowInvoicePreview(false)
      setErrorMessage('Não foi possivel localizar os dados da cobrança.')
    }
  }

  async function handleDownload(values: any) {
    const filters = {
      ...(search ? { search } : {}),
      ...(compensationModalityFilter ? { compensationModality: compensationModalityFilter } : {}),
      ...(tableCols ? { fields: tableCols.filter((col) => !col.hide).map((col) => col.field) } : {}),
    }

    if(isALegacyClient) {
      return await downloadCsvGetAllgreen({
        filter: filters,
        order: 'ASC',
        distributorsIds:
          distributors.filter((item) => item.checked).map((item) => item.id as number) ?? [],
        powerPlantsIds:
          powerPlants.filter((item) => item.checked).map((item) => item.id as number) ?? [],
        monthsRefs: values,
        status: status ? paymentStatusMap[String(status)] : '',
        day,
        companies,
      })
        .then((response) => {
          const blob = new Blob([response.data])
  
          const element = document.createElement('a')
          element.href = URL.createObjectURL(blob)
          element.download = `pagamentos_${dateSelected.month + '_' + dateSelected.year}.xlsx`
          document.body.appendChild(element)
          element.click()
        })
        .catch(() => {
          setErrorMessage('Erro ao baixar o arquivo.')
        })
        .finally(() => 'success')
    } 

    return await downloadCsvGet({
      sort:
        sortValues && sortValues.field && sortValues.order
          ? {
            field: sortValues.field,
            order: sortValues.order as any,
          }
          : undefined,
      distributorsId:
        distributors.filter((item) => item.checked).map((item) => item.id as number) ?? [],
      powerPlantsId:
        powerPlants.filter((item) => item.checked).map((item) => item.id as number) ?? [],
      monthReference: values,
      dueDayReference: day > 0 ? day : undefined,
      status,
      filter: filters,
    })
      .then((response) => {
        const blob = new Blob([response.data])

        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `pagamentos_${dateSelected.month + '_' + dateSelected.year}.xlsx`
        document.body.appendChild(element)
        element.click()
      })
      .catch(() => {
        setErrorMessage('Erro ao baixar o arquivo.')
      })
      .finally(() => 'success')
  }

  const handleEdit = (value: number) => {
    setSelectedPayment(value)
    setIsSendOpen(true)
  }

  const hasSendInvoicePermission = checkPermission(PermissionType.PAYMENTS_SEND_INVOICE)
  const hasEditingPermission = checkPermission(PermissionType.PAYMENTS_UPDATE)

  useEffect(() => {
    if (!rows?.length) return

    const columns = getPaymentColumns({
      previewHandler: previewInvoice,
      sendHandler: handleSendCharge,
      editHandler: handleEdit,
      printHandler: (paymentId: number) => {
        const payment = (payments?.data ?? []).find((item) => item.fechamento_id === paymentId)
        if (payment && payment.drive_id) {
          handleDrive(payment.drive_id)
        }
      },
      permissions: {
        hasSendInvoicePermission,
        hasEditingPermission
      },
      isALegacyClient
    })

    setTableCols((state) => {
      const getFromState = (name?: string) => state.find(item => item.headerName === name)

      const newColumns = columns.reduce((acc, item) => {
        const name = item.headerName
        const oldItem = getFromState(name)

        item.hide = oldItem?.hide ?? item?.hide
        acc.push(item)
        return acc
      }, [] as GridColDef[])

      return newColumns
    })
  }, [rows])

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  useEffect(() => {
    if (!loading) {
      ; (async () => {
        setIsSearchLoading(true)

        await Promise.all([getData(), getPaymentsInfoData()])
        setIsSearchLoading(false)
      })()
    }
  }, [
    page,
    distributors,
    powerPlants,
    update,
    status,
    ready,
    sortValues,
    dateSelected,
    pageItemsAmount,
    tableCols,
    compensationModalityFilter,
    companies
  ])

  const deleteFunction = async () => {
    try {
      setLoading(true)
      await deletePayment(selectedPayment)
      setIsCancelPaymentOpen(false)
      await getData()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <PaymentsContainer>
      <PaymentExcel
        selectedDate={dateSelected}
        openModal={modalExcel}
        setModalOpen={setModalExcel}
        handleDownload={handleDownload}
      />
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      <Modal
        title='Pré-visualização da cobrança'
        openModal={showInvoicePreview}
        setModalOpen={setShowInvoicePreview}
        defaultButtons={false}
        opacity={1}
        width='780px'
      >
        <PreviewInvoice
          invoicePreviewData={invoicePreviewData}
          downloadingInvoice={downloadingInvoice}
          setDownloadingInvoice={setDownloadingInvoice}
        />
      </Modal>
      <WidgetsContainer>
        <WidgetPayments
          Icon={DollarIcon}
          title='Faturamento'
          value={paymentsInfo?.faturamentoTotal}
        />
        <WidgetPayments
          Icon={ReceivedAmountIcon}
          title='Valores Recebidos'
          value={paymentsInfo?.recebidos}
        />
        <WidgetPayments Icon={ReceiptX} title='Valores Vencidos' value={paymentsInfo?.vencidos} />
        <WidgetPayments
          Icon={NonPaymentIcon}
          title='Inadimplência'
          value={paymentsInfo?.inadimplencia}
          isPercentage
        />
      </WidgetsContainer>
      <SettingsContainer>
        <FiltersContainer>
          <FloatingCheckboxes
            isFilterActive={distributors.some(({ checked }) => checked === true)}
            label='Distribuidoras'
            options={distributors}
            selectableAll
            searchable
            submitAction={filterDistributors}
          />
          <FloatingCheckboxes
            isFilterActive={powerPlants.some(({ checked }) => checked === true)}
            label='Usinas'
            options={powerPlants}
            selectableAll
            searchable
            submitAction={filterPowerPlants}
          />
          {/* <PaymentMethodFilter
            paymentMethodFilter={companies}
            setPaymentMethodFilter={setCompanies}
          /> */}
          <FloatingSelects
            isFilterActive={dayFilterArray
              .filter((day) => day !== 'Nenhum')
              .some((day) => day === String(day))}
            label='Dia de Vencimento'
            submitAction={() => {
              setUpdate(!update)
            }}
            selects={[
              {
                value: day.toString(),
                setValue: (value) => setDay(Number(value)),
                datas: [
                  {
                    label: 'Nenhum',
                    value: '0',
                  },
                  ...dayFilterArray.map((day) => ({
                    label: day,
                    value: day,
                  })),
                ],
              },
            ]}
          />
          <CompensationModalityFilter
            compensationModalityFilter={compensationModalityFilter}
            setCompensationModalityFilter={setCompensationModalityFilter}
          />
          <FloatingFilterWrapper
            checkbox
            isFilterActive={typeof status !== 'undefined'}
            label='Status'
            modalStyles={{ padding: 4 }}
            hideSubmitButton
          >
            <OptionList showDropDown={showDropDown}>
              {[
                { value: 2, label: 'Não cobrado' },
                { value: 1, label: 'Cobrado' },
                { value: 3, label: 'Recebido' },
                { value: 4, label: 'Vencido' },
              ].map((item) => (
                <OptionItem
                  active={status === item.value}
                  key={item.value}
                  onClick={() => {
                    setShowDropDown(!showDropDown)
                    status === item.value ? setStatus(undefined) : setStatus(item.value)
                    setPage(1)
                  }}
                >
                  {item.label}
                </OptionItem>
              ))}
            </OptionList>
          </FloatingFilterWrapper>
        </FiltersContainer>
        <ConfigButtonsColumn>
          <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />

          <ActionTableButton onClick={() => setModalExcel(true)} icon={<DownloadIcon />} />
          <FloatingCheckboxes
            pagination={{
              selected: pageItemsAmount,
              setSelected: setPageItemsAmount,
              options: [15, 20, 30, 50, 100],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableCols
              .map((col) => ({
                label: col.headerName || '',
                checked: !col.hide,
                id: Math.random(),
              }))
            }
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={handleSelectTableColumns}
          />

          {hasSendInvoicePermission && <ActionTableButton onClick={handleSendAllCharges} icon={<SendIcon />} />}
        </ConfigButtonsColumn>
      </SettingsContainer>
      {isSearchLoading ? (
        <Loading />
      ) : (
        <TableV2
          columns={tableCols}
          rows={(payments?.data ?? []).map((item) => ({ ...item, id: item.fechamento_id })) || []}
          alignText='center'
          sort={sortValues}
          onSort={(sort) => {
            setSortValues(sort)
            setPage(1)
          }}
          serverSorting
          messageNoRow='Não há pagamentos.'
          isSelectable={true}
          selectedItems={selectedIds}
          setSelectedItems={items => {
            const ids = (payments?.data ?? []).filter(payment => payment.status_ativacao_uc !== 'DISABLED').filter(payment => items.includes(payment.fechamento_id)).map(payment => payment.fechamento_id)
            setSelectedIds(ids)
          }}
        // headerModel={modelPayments}
        // setHeaderModel={setModelPayments}
        >
          <TablePagination
            pageInfo={{
              totalCount: payments?.pageInfo?.totalCount ?? 0,
              currentPage: page,
              totalPages: Math.ceil((payments?.pageInfo?.totalCount ?? 0) / pageItemsAmount),
              limit: pageItemsAmount
            }}
            currentPageItemCount={payments?.data?.length ?? 0}
            onChange={handleChangePage}
          />
        </TableV2>
      )}

      {isEditOpen && (
        <EditPaymentModal
          setErrorMessage={setErrorMessage}
          openModal={isEditOpen}
          setModalOpen={setIsEditOpen}
          confirmCharge
          receiveChargeId={selectedPayment}
          receivePaymentData={payments?.data || []}
          postAction={getData}
          emailsList={
            (payments?.data ?? []).find((payment) => payment.fechamento_id === selectedPayment)
              ?.emails || ['']
          }
          selectedDate={dateSelected}
        />
      )}
      {isCancelPaymentOpen && (
        <ModalDelete
          openModal={isCancelPaymentOpen}
          setModalOpen={setIsCancelPaymentOpen}
          confirmText
          buttonColor='darkGreen'
          text='Tem certeza que deseja cancelar a cobrança?'
          action={deleteFunction}
        />
      )}
      {isSendOpen && (
        <EditPaymentModal
          openModal={isSendOpen}
          setModalOpen={setIsSendOpen}
          receiveChargeId={selectedPayment}
          receivePaymentData={payments?.data ?? []}
          postAction={getData}
          setErrorMessage={setErrorMessage}
          selectedDate={dateSelected}
        />
      )}
      {isSendAllOpen && (
        <EditPaymentModal
          setErrorMessage={setErrorMessage}
          openModal={isSendAllOpen}
          setModalOpen={setIsSendAllOpen}
          sendAll={isSendAllOpen}
          selectedIds={selectedIds}
          postAction={getData}
          selectedDate={dateSelected}
        />
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </PaymentsContainer>
  )
}
