import { Form, FormikProvider, useFormik } from 'formik'
import Modal from '../../../../ModalV2'
import { myUnitsPowerPlantsType } from '../../../../../pages/MyUnits/MyUnitsTables/PowerPlant/types'
import * as Yup from 'yup'
import { InputV2 } from '../../../../InputV2'
import { RadioButtonFormik } from '../../../../FormikComponents/RadioButtonInput'
import { CancelButton, SendButton } from './styles'
import { useState } from 'react'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { CircularProgress, Stack } from '@mui/material'
import Tabs from '../ModalRegisterConsumerUnit/components/Tabs'
import { Stage, StickyFooter } from '../ModalRegisterConsumerUnit/styles'
import DistributorFields from '../ModalRegisterConsumerUnit/components/DistributorFields'
import RemunerationRulesFields from './components/RemunerationRulesFields'
import ConsumptionModalitySelect from './components/ConsumptionModalitySelect'
import {
  editPowerPlant,
  registerPowerPlantPost,
} from '../../../../../services/requests/user-requests'
import { useToast } from '../../../../../hooks/useToast'
import ClassificationSelect from './components/ClassificationSelect'
import CompensationModalitySelect from './components/CompensationModalitySelect'
import ConsumerSelect from './components/ConsumerSelect'
import InverterFields from './components/InverterFields'

const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const cnpjMask = [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

const getInitialValues = (editValues?: myUnitsPowerPlantsType) => {
  return {
    nome: editValues?.nome || '',
    fonte: editValues?.fonte || '',
    potencia_instalada: editValues?.potencia_instalada || 0,
    distribuidora_id: editValues?.distribuidora_id || 0,
    codigo: editValues?.codigo || '',
    senha_dist: editValues?.senha_dist || '',
    cep: editValues?.cep || '',
    modalidade_de_compensacao: editValues?.modalidade_de_compensacao || '',
    cliente_id_consumidor: editValues?.cliente_id_consumidor,
    planta_ids: editValues?.planta_ids || 0,
    grupo: editValues?.grupo || 'B',
    login: editValues?.login || '',
    inversor_id: editValues?.inversor_id || 0,
    documento_numero: editValues?.documento_numero || '',
    expectativa_geracao: editValues?.expectativa_geracao || 0,
    classificacao: editValues?.classificacao || '',
    remuneracao_regra: editValues?.remuneracao_regra || '',
    desconto_fixo_valor: editValues?.desconto_fixo_valor
      ? editValues?.desconto_fixo_valor * 100
      : 0,
    compensado_usina_valor: editValues?.compensado_usina_valor
      ? editValues?.compensado_usina_valor * 100
      : 0,
    valor_fixo: editValues?.valor_fixo ? editValues?.valor_fixo : 0,
    valor_arrendamento: editValues?.valor_arrendamento ? editValues?.valor_arrendamento * 100 : 0,
    piso_disponibilidade: editValues?.piso_disponibilidade
      ? editValues?.piso_disponibilidade * 100
      : 0,
    mes_operacao: editValues?.mes_operacao ? editValues?.mes_operacao : '',
    isl_sengi: editValues?.isl_sengi ? editValues?.isl_sengi * 100 : 0,
    tarifa_fixa_valor: editValues?.tarifa_fixa_valor ? editValues?.tarifa_fixa_valor : 0,
    percentual_sobre_compensado_valor: editValues?.percentual_sobre_compensado_valor
      ? editValues?.percentual_sobre_compensado_valor * 100
      : 0,
    percentual_sobre_compensado_devolucao: editValues?.percentual_sobre_compensado_devolucao
      ? editValues?.percentual_sobre_compensado_devolucao * 100
      : 0,
    percentual_sobre_bandeira_valor: editValues?.percentual_sobre_bandeira_valor
      ? editValues?.percentual_sobre_bandeira_valor * 100
      : 0,
    percentual_por_bandeira_verde: editValues?.percentual_por_bandeira_verde
      ? editValues?.percentual_por_bandeira_verde * 100
      : 0,
    percentual_por_bandeira_amar: editValues?.percentual_por_bandeira_amar
      ? editValues?.percentual_por_bandeira_amar * 100
      : 0,
    percentual_por_bandeira_verm_p1: editValues?.percentual_por_bandeira_verm_p1
      ? editValues?.percentual_por_bandeira_verm_p1 * 100
      : 0,
    percentual_por_bandeira_verm_p2: editValues?.percentual_por_bandeira_verm_p2
      ? editValues?.percentual_por_bandeira_verm_p2 * 100
      : 0,
  }
}

interface FormData {
  nome: string
  fonte: string
  potencia_instalada: number
  distribuidora_id: number
  codigo: string
  senha_dist: string
  cep: string
  modalidade_de_compensacao: string
  cliente_id_consumidor?: number
  planta_ids: number
  grupo: string
  login: string
  inversor_id?: number
  documento_numero: string
  remuneracao_regra: string
  desconto_fixo_valor: number
  compensado_usina_valor: number
  tarifa_fixa_valor: number
  piso_disponibilidade: number
  valor_arrendamento: number
  valor_fixo: number
  isl_sengi: number
  percentual_sobre_compensado_valor: number
  percentual_sobre_bandeira_valor: number
  percentual_por_bandeira_verde: number
  percentual_sobre_compensado_devolucao?: number
  percentual_por_bandeira_amar: number
  percentual_por_bandeira_verm_p1: number
  percentual_por_bandeira_verm_p2: number
  expectativa_geracao?: number
  classificacao?: string
}

export const validationSchema = Yup.object({
  nome: Yup
    .string()
    .min(4, "O nome deve ter pelo menos 4 letras")
    .max(120, "O nome tem um limite de 120 letras")
    /* eslint-disable-next-line */
    .matches(/^[0-9A-Za-z\u00C0-\u00F6\u00F9-\u00FF\s\-\(\)]*$/i, "Não pode ser usado caracteres especiais")
    .required('Digite o nome da usina.'),
  fonte: Yup.string().required('Selecione uma fonte de geração.'),
  potencia_instalada: Yup.number()
    .typeError('Digite um valor válido')
    .required('Digite a potência instalada.'),
  distribuidora_id: Yup.number()
    .required('Selecione uma distribuidora.')
    .moreThan(0, 'Selecione uma distribuidora.'),
  codigo: Yup
    .string()
    .min(4, "Minimo de 4 caracteres")
    .max(30, "Maximo de 30 caracteres")
    .required('Digite o número da UC.'),
  cep: Yup.string()
    .required('Digite o CEP da usina.')
    .length(8, 'Digite apenas 8 caracteres do CEP.')
    .transform((value) => value.replace(/[^\d]/g, '')),
  modalidade_de_compensacao: Yup.string().required('Selecione uma modalidade de compensação.'),
  grupo: Yup.string().required('Marque um dos grupos.'),
  login: Yup.string(),
  documento_numero: Yup.string().required('CPF/CNPJ é obrigatório.'),
})

interface Props {
  editValues?: myUnitsPowerPlantsType
  postAction?: () => void
  onClose: () => void
}

export default function ModalRegisterPowerPlant(props: Props) {
  const { editValues, postAction, onClose } = props

  const [currentStage, setCurrentStage] = useState<number>(0)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const { toast } = useToast()

  async function handleSubmit(values: FormData) {
    try {
      setSubmitting(true)
      const payload = {
        nome: values.nome,
        fonte: values.fonte,
        potencia_instalada: values.potencia_instalada,
        distribuidora_id: values.distribuidora_id,
        codigo: values.codigo,
        cep: values.cep.replace(/[^\d]/g, ''),
        cliente_id_consumidor: values.cliente_id_consumidor,
        grupo: values.grupo,
        login: values.login,
        modalidade_de_compensacao: values.modalidade_de_compensacao,
        planta_ids: [values.planta_ids],
        senha_dist: values.senha_dist,
        documento_numero: values.documento_numero.replace(/\D+/g, ''),
        remuneracao_regra: values.remuneracao_regra,
        desconto_fixo_valor:
          typeof values.desconto_fixo_valor === 'string' || values.desconto_fixo_valor === 0
            ? null
            : values.desconto_fixo_valor / 100,
        // mes_operacao: dateMask,
        compensado_usina_valor:
          typeof values.compensado_usina_valor === 'string' || values.compensado_usina_valor === 0
            ? null
            : values.compensado_usina_valor / 100,
        valor_fixo:
          typeof values.valor_fixo === 'string' || values.valor_fixo === 0
            ? null
            : values.valor_fixo,
        valor_arrendamento:
          typeof values.valor_arrendamento === 'string' || values.valor_arrendamento === 0
            ? null
            : values.valor_arrendamento,
        piso_disponibilidade:
          typeof values.piso_disponibilidade === 'string' || values.piso_disponibilidade === 0
            ? null
            : values.piso_disponibilidade,
        isl_sengi:
          typeof values.isl_sengi === 'string' || values.isl_sengi === 0
            ? null
            : values.isl_sengi / 100,
        tarifa_fixa_valor:
          typeof values.tarifa_fixa_valor === 'string' || values.tarifa_fixa_valor === 0
            ? null
            : values.tarifa_fixa_valor,
        percentual_sobre_compensado_valor:
          typeof values.percentual_sobre_compensado_valor === 'string' ||
          values.percentual_sobre_compensado_valor === 0
            ? null
            : values.percentual_sobre_compensado_valor / 100,
        percentual_sobre_bandeira_valor:
          typeof values.percentual_sobre_bandeira_valor === 'string' ||
          values.percentual_sobre_bandeira_valor === 0
            ? null
            : values.percentual_sobre_bandeira_valor / 100,
        percentual_por_bandeira_verde:
          typeof values.percentual_por_bandeira_verde === 'string' ||
          values.percentual_por_bandeira_verde === 0
            ? null
            : values.percentual_por_bandeira_verde / 100,
        percentual_por_bandeira_amar:
          typeof values.percentual_por_bandeira_amar === 'string' ||
          values.percentual_por_bandeira_amar === 0
            ? null
            : values.percentual_por_bandeira_amar / 100,
        percentual_por_bandeira_verm_p1:
          typeof values.percentual_por_bandeira_verm_p1 === 'string' ||
          values.percentual_por_bandeira_verm_p1 === 0
            ? null
            : values.percentual_por_bandeira_verm_p1 / 100,
        percentual_por_bandeira_verm_p2:
          typeof values.percentual_por_bandeira_verm_p2 === 'string' ||
          values.percentual_por_bandeira_verm_p2 === 0
            ? null
            : values.percentual_por_bandeira_verm_p2 / 100,
        expectativa_geracao: values.expectativa_geracao,
        classificacao: values.classificacao,
      }

      if (editValues) {
        await editPowerPlant({ ...payload, id: editValues.id })
      } else {
        await registerPowerPlantPost(payload)
      }

      toast({
        message: `Usina geradora ${editValues ? 'atualizada' : 'cadastrada'} com sucesso`,
        type: 'success',
      })

      onClose()
      if (postAction) {
        postAction()
      }
    } catch (error: any) {
      toast({
        message: error?.message ?? `Erro ao ${editValues ? 'atualizar' : 'criar'} usina geradora.`,
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: getInitialValues(editValues),
    initialTouched: editValues
      ? Object.keys(editValues).reduce((agg: Record<string, boolean>, key) => {
          agg[key] = true
          return agg
        }, {})
      : {},
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  })

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '750px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {editValues ? 'Edição' : 'Cadastro'} de Usina Geradora
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Tabs
        value={currentStage}
        onChange={(stage) => setCurrentStage(stage)}
        tabs={['Dados da Usina', 'Distribuidora', 'Financeiro', 'Inversores']}
      />

      <FormikProvider value={formik}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            height: '100%',
          }}
        >
          <Modal.Body>
            <Stage active={currentStage === 1}>
              <DistributorFields formik={formik} />
            </Stage>
            <Stage active={currentStage === 2}>
              <RemunerationRulesFields formik={formik} />
            </Stage>
            <Stage active={currentStage === 3}>
              <InverterFields formik={formik} />
            </Stage>
            <Stage active={currentStage === 0}>
              <InputV2 label='Nome da Usina' required id='nome' />
              <Stack gap={2} direction='row'>
                <InputV2 label='Número da UC' disabled={!!editValues} required id='codigo' />
                <InputV2 id='documento_numero' label='Documento da Usina' required mask={{
                  mask: (value) => {
                    const numbers = value.replace(/\D/g, '');
                    return numbers.length <= 11 ? cpfMask : cnpjMask
                  }
                }} />
              </Stack>
              <ConsumerSelect formik={formik} />
              <ConsumptionModalitySelect formik={formik} />
              <Stack gap={2} direction='row'>
                <InputV2 label='Potência Instalada(kW)' required id='potencia_instalada' />
                <InputV2 id='expectativa_geracao' label='Expectativa de geração (kWh)' />
              </Stack>
              <InputV2
                label='CEP'
                required
                id='cep'
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              />
              <CompensationModalitySelect formik={formik} />
              <ClassificationSelect formik={formik} />
              <RadioButtonFormik
                style={{ margin: 0 }}
                name='grupo'
                label='Grupo'
                values={[
                  { field: 'A', value: 'A' },
                  { field: 'B', value: 'B' },
                ]}
                mode
                required
              />
            </Stage>
          </Modal.Body>
          <StickyFooter>
            {currentStage > 0 && (
              <CancelButton
                type='button'
                onClick={() => setCurrentStage((previousStage) => previousStage - 1)}
              >
                Voltar
              </CancelButton>
            )}
            <SendButton
              style={{ marginLeft: 'auto' }}
              key={`next-stage-button-${currentStage}`}
              type={currentStage < 3 ? 'button' : 'submit'}
              disabled={isSubmitting}
              onClick={() => {
                if (currentStage < 3) {
                  setCurrentStage((previousStage) => previousStage + 1)
                }
              }}
            >
              {isSubmitting && <CircularProgress size={20} />}
              {!isSubmitting && (currentStage < 3 ? 'Avançar' : 'Salvar')}
            </SendButton>
          </StickyFooter>
        </Form>
      </FormikProvider>
    </Modal.Provider>
  )
}
