import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Sidebar } from '../components/Sidebar'
import { Header } from '../components/UI/Header'
import { GlobalContext } from '../context/global/global'
import { Content, ContentContainer, DashboardLayoutContainer } from './styles'
import { PermissionsProvider } from '../hooks/usePermissions'
import { RoutesProvider } from '../context/global/sidebar-routes'

export function DashboardLayout() {
  const { state } = useContext(GlobalContext)

  return (
    <>
      {!state.auth ? (
        <Navigate to='/' />
      ) : (
        <PermissionsProvider>
          <RoutesProvider>
            <DashboardLayoutContainer>
              <Sidebar />
              <ContentContainer>
                <Header />
                <Content>
                  <Outlet />
                </Content>
              </ContentContainer>
            </DashboardLayoutContainer>
          </RoutesProvider>
        </PermissionsProvider>
      )}
    </>
  )
}
