import { useEffect, useRef, useState } from 'react'
import { useToast } from '../../../../hooks/useToast'
import { aggregatedPaymentsService } from '../../../../services/aggregated-payments'
import {  Visibility } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Modal from '../../../../components/Modal'
import { Document, Page } from 'react-pdf'
import { ContainerPreviewInvoice } from '../../../Payments/styles'
import { SendButton } from '../../../../components/Modal/Payments/EditPaymentModal/styles'
import { fi } from 'date-fns/locale'

interface Props {
  aggregatedPaymentId: number
  fechamentosIds?: number[]
}


const PreviewHtmlInvoice = ({ toRender }: any) => {
  const ContainerPreviewInvoiceRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ContainerPreviewInvoiceRef.current) {
      ContainerPreviewInvoiceRef.current.innerHTML = toRender as string
    }
  }, [ContainerPreviewInvoiceRef])

  return <div style={{ maxWidth: '780px' }} ref={ContainerPreviewInvoiceRef} />
}

const PreviewInvoice = ({
  aggregatedPaymentId,
  fechamentosIds,
  invoicePreviewData,
  downloadingInvoice,
  setDownloadingInvoice,
}: any) => {
  const { toast } = useToast()

  const downloadRentalInvoice = async () => {
    try {
      setDownloadingInvoice(true)
      if(invoicePreviewData) {
        const invoice = (await aggregatedPaymentsService.getRentalInvoice({
          aggregatedPaymentId,
          fechamentosIds
        })) as any

        const blob = new Blob([new Uint8Array(invoice.data.toRender.data)])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `faturamento_agrupado_locacao_${aggregatedPaymentId}.pdf`
        document.body.appendChild(element)
        element.click()
      }
    } catch(error) {
      console.error(error)
      toast({
        message: 'Erro ao visualizar fatura de locação',
        type: 'error'
      })
    } finally {
      setDownloadingInvoice(false)
    }
  }

  const PreviewType = ({ type, toRender }: any) => {
    switch (type) {
      case 'html':
        return <PreviewHtmlInvoice toRender={toRender as string} />

      case 'pdf':
        return (() => {
          const myBuffer = (toRender as { data: Buffer }).data
          return (
            <Document
              file={{ data: new Uint8Array(myBuffer) }}
              onSourceError={(error) => console.log('source error', error)}
              loading={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <span>Carregando pré-visualização...</span>
                </div>
              }
              onLoadError={(error) => console.log('load error', error)}
            >
              <Page pageNumber={1} width={720} />
            </Document>
          )
        })()
        
      default:
        return null
    }
  }

  return (
    <ContainerPreviewInvoice>
      {invoicePreviewData?.toRender && (
        <SendButton
          onClick={downloadRentalInvoice}
          disabled={downloadingInvoice}
        >
          {downloadingInvoice ? 'Carregando..' : 'Baixar PDF'}
        </SendButton>
      )}
      {invoicePreviewData?.type && invoicePreviewData?.toRender && (
        <PreviewType type={invoicePreviewData.type} toRender={invoicePreviewData.toRender} />
      )}
      {!invoicePreviewData && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span>Carregando informações...</span>
        </div>
      )}
    </ContainerPreviewInvoice>
  )
}

export default function DownloadRentalInvoiceButton(props: Props) {
  const [showInvoicePreview, setShowInvoicePreview] = useState<boolean>(false);
  const [invoicePreviewData, setInvoicePreviewData] = useState<any | null>(null);
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false);
  const { aggregatedPaymentId, fechamentosIds } = props

  const { toast } = useToast()

  const downloadRentalInvoice = async () => {
    try {
      setShowInvoicePreview(true);
      const { data } = await aggregatedPaymentsService.getRentalInvoice({
        aggregatedPaymentId,
        fechamentosIds
      })

      if (data) {
        setInvoicePreviewData(data);
      } else {
        setShowInvoicePreview(false)
        throw new Error("No invoice data available.");
      }
    } catch(error) {
      setShowInvoicePreview(false)
      toast({
        message: 'Erro ao visualizar fatura de locação',
        type: 'error'
      })
    }
  }

  useEffect(() => {
    if (!showInvoicePreview) {
      setInvoicePreviewData(null)
    }
  }, [showInvoicePreview])

  return (
    <>
      {showInvoicePreview && (
        <Modal
          title='Pré-visualização da cobrança'
          openModal={showInvoicePreview}
          setModalOpen={setShowInvoicePreview}
          defaultButtons={false}
          opacity={1}
          width='780px'
        >
          <PreviewInvoice
            aggregatedPaymentId={aggregatedPaymentId}
            fechamentosIds={fechamentosIds}
            invoicePreviewData={invoicePreviewData}
            downloadingInvoice={downloadingInvoice}
            setDownloadingInvoice={setDownloadingInvoice}
          />
        </Modal>
      )}
      <IconButton onClick={downloadRentalInvoice}>
        <Visibility style={{ color: '#ababab' }} />
      </IconButton>
    </>
  )
}
