import { useState, useContext, useEffect, HTMLAttributes } from 'react'
import Filter from '..'
import { GlobalContext } from '../../../context/global/global'
import { ButtonsDiv } from '../../charts/ConsumerMainChart/styles'
import { monthsList, yearList } from '../../../utils/selectDates'

interface iValue {
  month: number
  year: number
}
interface iFiltroProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  onChange: (Props: iValue) => void
  currentValue?: iValue
}

export const DateFilter = ({ onChange, currentValue, ...rest }: iFiltroProps) => {
  const { Theme } = useContext(GlobalContext)
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  useEffect(() => {
    if (currentValue) setDateSelected(currentValue)
  }, [currentValue])

  const changeDate = (type: 'add' | 'subtract') => {
    const years = [...yearList()]
    const isFirstYear = dateSelected.year === Number(years[years.length - 1].value)
    const isCurrentYear = dateSelected.year === Number(years[0].value)
    const isFirstMonth = dateSelected.month === 1
    const isLastMonth = dateSelected.month === 12
    const isCurrentMonth = dateSelected.month === new Date().getMonth() + 1 && isCurrentYear

    if (
      (isFirstYear && isFirstMonth && type == 'subtract') ||
      (isCurrentYear && isLastMonth && type == 'add') ||
      (isCurrentMonth && type == 'add')
    ) {
      return
    }
    if (isFirstMonth && type == 'subtract') {
      setDateSelected((prev) => {
        onChange({ month: 12, year: prev.year - 1 })
        return { year: prev.year - 1, month: 12 }
      })
    } else if (isLastMonth && type == 'add') {
      setDateSelected((prev) => {
        onChange({ month: 1, year: prev.year + 1 })
        return { year: prev.year + 1, month: 1 }
      })
    } else {
      setDateSelected((prev) => {
        onChange({ month: type == 'add' ? prev.month + 1 : prev.month - 1, year: prev.year })
        return { ...prev, month: type == 'add' ? prev.month + 1 : prev.month - 1 }
      })
    }
  }

  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px',
        gap: '0.5rem',
        ...(rest.style ? rest.style : {}),
      }}
    >
      <ButtonsDiv
        onClick={() => changeDate('subtract')}
        style={{ fontWeight: '700', color: Theme.colors.grey, cursor: 'pointer' }}
      >
        {'<'}
      </ButtonsDiv>
      <Filter
        submitAction={() => onChange(dateSelected)}
        selectedDate={dateSelected}
        selects={[
          {
            value: dateSelected.year.toString(),
            setValue: (val) => {
              const monthArray =
                Number(val) === Number(yearList()[0].value)
                  ? monthsList.filter((item) => Number(item.value) <= new Date().getMonth() + 1)
                  : monthsList
              setDateSelected((prev) => {
                const findMonth = monthArray.find(
                  ({ value }) => Number(value) === prev.month,
                )?.value
                return {
                  month: findMonth
                    ? Number(findMonth)
                    : Number(monthArray[monthArray.length - 1].value),
                  year: Number(val),
                }
              })
            },
            datas: yearList(),
          },
          {
            value: dateSelected.month.toString(),
            setValue: (val) => {
              setDateSelected((prev) => ({
                ...prev,
                month: Number(val),
              }))
            },
            datas:
              dateSelected.year === Number(yearList()[0].value)
                ? monthsList.filter((item) => Number(item.value) <= new Date().getMonth() + 1)
                : monthsList,
          },
        ]}
      />
      <ButtonsDiv
        onClick={() => changeDate('add')}
        style={{ fontWeight: '700', color: Theme.colors.grey, cursor: 'pointer' }}
      >
        {'>'}
      </ButtonsDiv>
    </div>
  )
}
