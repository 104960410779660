import { InvoiceValidation } from '../interfaces/invoices'
import { api } from './api'

interface UploadInvoiceFiles {
  params: {
    files: File[]
    managerId: number
    hasMuc: boolean
    distributor: string
  }
  result: {
    invoices: Array<{
      id: string
      fileName: string
    }>
  }
}

export class InvoiceParserV2 {
  async listInvoicesToValidate(invoicesIdsToList?: string[]) {
    const response = await api.get('/invoices/validation', {
      params: invoicesIdsToList && invoicesIdsToList.length > 0 ? { invoicesIds: invoicesIdsToList.join(',') } : undefined,
    })
    return response.data
  }
  async getInvoiceToValidateById(id: string): Promise<InvoiceValidation> {
    const response = await api.get(`/invoices/validation/${id}`)
    return response.data
  }
  async getInvoiceClosing(id: string) {
    const response = await api.get(`/invoices/validation/${id}/closing`)
    return response.data
  }
  async updateInvoiceValidationData(
    id: string,
    data: Record<string, unknown>,
  ): Promise<InvoiceValidation> {
    const response = await api.patch(`/invoices/validation/${id}`, data)
    return response.data
  }
  async validateInvoice(id: string) {
    const response = await api.post(`/invoices/validation/${id}/validate`)
    return response.data
  }
  async reanalyzeInvoice(id: string) {
    const response = await api.post(`/invoices/validation/${id}/reanalyze`)
    return response.data
  }
  async uploadInvoiceFiles(
    params: UploadInvoiceFiles['params'],
  ): Promise<UploadInvoiceFiles['result']> {
    const { distributor, files, hasMuc, managerId } = params

    const formData = new FormData()
    formData.append('distributor', distributor)
    formData.append('hasMuc', String(hasMuc))
    formData.append('managerId', String(managerId))
    for (const file of files) {
      formData.append('files', file)
    }
    const response = await api.post(`/invoices/aws-new`, formData)
    return response.data
  }
  async deleteInvoiceValidation(id: string): Promise<void> {
    await api.delete(`/invoices/validation/${id}`)
  }
}

export const invoiceParserV2 = new InvoiceParserV2()
