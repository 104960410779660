import styled from 'styled-components'
import Autocomplete from '@mui/material/Autocomplete'

export const InputSearchContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 16px;
`

export const InputSearch = styled(Autocomplete)`
  background-color: rgba(241, 241, 241, 1);
  border-radius: 8px;
  width: 100%;

  & fieldset {
    border: none !important;
  }

  & input {
    width: 100%;
  }

  & .MuiAutocomplete-popupIndicator {
    display: none;
  }

  & .MuiAutocomplete-listbox {
    background-color: red !important;
    border: 1px solid #000;
  }
`
