import styled from 'styled-components'

export const RadioButtonInputContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

export const LabelRadioInput = styled.label<{ mode?: boolean; required?: boolean }>`
  color: ${({ mode, theme }) => (mode ? theme.colors.quartzGrey : theme.colors.white)};
  margin-bottom: 8px;
  padding-left: ${({ mode }) => (mode ? `0px` : `15px`)};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  ${({ required, theme }) =>
    required &&
    `::after {
    content: '*';
    color: ${theme.colors.orange};`}
`

export const RadioButtonField = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-size: 0.9rem;
  }
`
