import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { FormikInput } from '../../../components/FormikComponents/FormikInput'
import Loading from '../../../components/Loading'
import Widget from '../../../components/Widget'
import { consumerSettingsGet, consumerSettingsPut } from '../../../services/requests/user-requests'
import { ButtonArea, FormDiv, SaveButton } from './styles'
import { cellphoneReg, ConsumerData, phoneReg } from './types'

export function EditCustomer() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const [isRequesting, setIsRequesting] = useState(false)
  const [documentMask, setDocumentMask] = useState('99.999.999/9999-99')
  const [phoneMask, setPhoneMask] = useState(phoneReg)
  const [consumerData, setConsumerData] = useState<ConsumerData>()
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)

  const isKeyValid = (key: number) => {
    if ((key >= 96 && key <= 105) || (key >= 48 && key <= 57)) {
      return true
    }

    return false
  }

  const formatNumber = (value: string) => {
    let formattedNumber = ''
    if (value.length === 11) {
      formattedNumber = `(${value.substring(0, 2)}) ${value.substring(2, 3)} ${value.substring(
        3,
        7,
      )}-${value.substring(7, 11)}`
    }
    if (value.length === 10) {
      formattedNumber = `(${value.substring(0, 2)}) ${value.substring(2, 6)}-${value.substring(
        6,
        10,
      )}`
    }
    return formattedNumber
  }

  const yupSchema = Yup.object({
    email: Yup.string().email('Formato de e-mail incorreto.').required('E-mail é obrigatório.'),
    nome_razao_social: Yup.string(),
    documento_numero: Yup.string()
      .matches(
        /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/,
        'Formato incorreto.',
      )
      .test('Valid', 'CPF/CNPJ válido é obrigatório.', (value: any) => {
        value = value.replace(/\D/g, '')

        if (value.length === 11) {
          value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
        }

        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')

        return value?.length <= 14 ? cpf.isValid(value) : cnpj.isValid(value)
      })
      .required('CPF/CNPJ é obrigatório.'),
    fone: Yup.string().transform(formatNumber),
  })

  const handleDynamicMask = (value: any) => {
    if (value.length < 14) {
      setDocumentMask('999.999.999-99')
    } else {
      setDocumentMask('99.999.999/9999-99')
    }
  }

  useEffect(() => {
    const getConsumerData = async () => {
      try {
        setLoading(true)
        const response = await consumerSettingsGet()
        setConsumerData(response.data)
        handleDynamicMask(response.data.documento_numero)
        if (response.data?.fone && response.data?.fone.length === 11) {
          setPhoneMask(cellphoneReg)
        }
        setReady(true)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        setErrorMessage(error.message)
      }
    }

    getConsumerData()
  }, [])

  const handleConsumerPut = async (values: any, setSubmitting: any) => {
    try {
      setSubmitting(true)
      setIsRequesting(true)
      await consumerSettingsPut({
        ...values,
        documento_numero: values.documento_numero.replace(/\D+/g, ''),
        fone: values.fone.replace(/\D+/g, ''),
      })
      setSubmitting(false)
      setSuccessMessage('success')
      setIsRequesting(false)
    } catch (error: any) {
      setSubmitting(false)
      setErrorMessage(error.message)
      setIsRequesting(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Widget>
        {ready && (
          <Formik
            initialValues={{
              email: consumerData?.email || '',
              nome_razao_social: consumerData?.nome_razao_social || '',
              documento_numero: consumerData?.documento_numero || '',
              fone: consumerData?.fone ? consumerData.fone : '',
            }}
            validateOnChange={false}
            validateOnBlur
            validationSchema={yupSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleConsumerPut(values, setSubmitting)
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <FormDiv>
                  <FormikInput
                    label='Email de login'
                    disabled
                    mode
                    sizeForm
                    type='email'
                    name='email'
                  />
                  <FormikInput label='Nome da empresa' mode sizeForm name='nome_razao_social' />
                  <FormikInput
                    label='CNPJ/CPF'
                    mode
                    sizeForm
                    name='documento_numero'
                    mask={documentMask}
                    onKeyDown={(e: any) =>
                      isKeyValid(e.keyCode)
                        ? values.documento_numero.length === 14 ||
                          values.documento_numero.length === 13
                          ? handleDynamicMask(values.documento_numero)
                          : null
                        : null
                    }
                    onPaste={(e: any) => {
                      const clipboardData = e.clipboardData
                      const pastedData = clipboardData.getData('Text').toString().replace(/\D/, '')
                      handleDynamicMask(pastedData)
                    }}
                  />
                  <FormikInput
                    label='Número de telefone'
                    mode
                    sizeForm
                    name='fone'
                    textMask={phoneMask}
                    onKeyDown={(e: any) => {
                      isKeyValid(e.keyCode)
                        ? values.fone && values.fone?.replace(/\D+/g, '').length > 9
                          ? setPhoneMask(cellphoneReg)
                          : setPhoneMask(phoneReg)
                        : null
                    }}
                  />
                  <ButtonArea>
                    <SaveButton
                      text='Salvar'
                      mode='darkGreen'
                      type='submit'
                      containerStyle={{ width: 213 }}
                      disabled={isSubmitting}
                      isLoading={isRequesting}
                    />
                  </ButtonArea>
                </FormDiv>
              </Form>
            )}
          </Formik>
        )}
      </Widget>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <AlertTitle>Erro</AlertTitle>
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='success'
          onClose={() => setSuccessMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <AlertTitle>Sucesso</AlertTitle>
          <p>Dados alterados com sucesso</p>
        </Alert>
      </Snackbar>
    </>
  )
}
