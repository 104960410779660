import styled from 'styled-components'
import { Button } from '../../Button'

export const CheckMarkDiv = styled.div`
  background: #ffffff;
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SuccessText = styled.h2`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.darkishGreen};

  padding-top: 32px;
  padding-bottom: 56px;
`

export const ModalSuccessDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ConfirmButton = styled(Button)`
  width: 100%;
  height: 40px;

  border-radius: 8px;
`
