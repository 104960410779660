import ModalDelete from "../../../../../components/Modal/ModalDelete"
import { useToast } from "../../../../../hooks/useToast"
import { invoiceErrorsService } from "../../../../../services/invoice-errors"

interface Props {
  readerErrorId: number
  errorFileId: string
  onSuccess: () => void
  onClose: () => void
}

export default function DeleteInvoiceFileModal(props: Props) {
  const { readerErrorId, errorFileId, onSuccess, onClose } = props

  const { toast } = useToast()

  const deleteInvoiceFile = async () => {
    if (!errorFileId || !readerErrorId) {
      return
    }
    try {
      await invoiceErrorsService.deleteErrorFile({
        errorFileId,
        readerErrorId,
      })
      onSuccess()
    } catch (error: any) {
      toast({
        message: error?.message ?? 'Erro ao deletar',
        type: 'error',
      })
    }
  }

  return (
    <ModalDelete
      openModal
      setModalOpen={onClose}
      buttonColor='darkGreen'
      text='Tem certeza que deseja excluir esta fatura?'
      textStyle={{ fontWeight: 'bold', paddingBottom: 12 }}
      action={deleteInvoiceFile}
    />
  )
}