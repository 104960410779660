import { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../../../../../../components/ModalV2'
import { useToast } from '../../../../../../hooks/useToast'
import { ListUserRoles, permissionsService } from '../../../../../../services/permissions'
import { Checkbox, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { debounce } from 'lodash'
import Loading from '../../../../../../components/Loading'

interface TouchedRole {
  roleId: number
  hasRole: boolean
}

interface Props {
  userId: number
  onClose: () => void
}

export default function UserRolesModal(props: Props) {
  const { userId, onClose } = props

  const [roles, setRoles] = useState<ListUserRoles['result']>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const [touchedRoles, setTouchedRoles] = useState<TouchedRole[]>([])

  useEffect(() => {
    setTouchedRoles(
      roles
        .filter((roleInfo) => roleInfo.hasRole)
        .map((roleInfo) => ({
          roleId: roleInfo.role.id,
          hasRole: true,
        })),
    )
  }, [roles])

  const { toast } = useToast()

  const fetchUserRoles = async () => {
    try {
      setLoading(true)
      const rolesResponse = await permissionsService.listUserRoles({
        userId,
      })
      setRoles(rolesResponse)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar grupos',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUserRoles()
  }, [userId])

  const updateUserRoles = useCallback(async (touchedRoles: TouchedRole[]) => {
    try {
      await permissionsService.updateUserRoles({
        userId,
        roles: touchedRoles.map((touchedRoles) => ({
          id: touchedRoles.roleId,
          remove: !touchedRoles.hasRole,
        })),
      })
      toast({
        message: 'Grupos atualizados com sucesso',
        type: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao atualizar Grupos',
        type: 'error',
      })
    }
  }, [])

  const debounceOnChange = useMemo(
    () => debounce((touchedRole: TouchedRole[]) => updateUserRoles(touchedRole), 1000),
    [],
  )

  const checkRoles = (roleId: number) => {
    setTouchedRoles((previousTouchedRoles) => {
      const updatedTouchedRoles = [...previousTouchedRoles]
      const existsIndex = updatedTouchedRoles.findIndex(
        (permission) => permission.roleId === roleId,
      )
      const obj = {
        roleId,
        hasRole: existsIndex !== -1 ? !updatedTouchedRoles[existsIndex].hasRole : true,
      }
      if (existsIndex === -1) {
        updatedTouchedRoles.push(obj)
      } else {
        updatedTouchedRoles[existsIndex] = obj
      }
      debounceOnChange(updatedTouchedRoles)
      return updatedTouchedRoles
    })
  }

  return (
    <Modal.Provider
      style={{ overflowY: 'auto', width: '100%', height: '100%' }}
      isOpen
      maxWidth={650}
      onClose={onClose}
    >
      <Modal.Title>
        Grupos do Usuário
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        {isLoading && <Loading />}
        {!isLoading && roles.length > 0 && <List sx={{ width: '100%', padding: 0 }}>
          {roles.map((roleInfo) => (
            <div key={`permission-role-${roleInfo.role.id}`}>
              <ListItemButton
                sx={{
                  padding: '2px 8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  '&:hover': { backgroundColor: 'action.hover' },
                  cursor: 'pointer',
                }}
                divider
                onClick={() => checkRoles(roleInfo.role.id)}
              >
                <Checkbox
                  checked={
                    touchedRoles.find((touchedRole) => touchedRole.roleId === roleInfo.role.id)
                      ?.hasRole ?? roleInfo.hasRole
                  }
                  edge='start'
                  disableRipple
                  sx={{ marginRight: '8px' }}
                />
                <ListItemText
                  primary={roleInfo.role.name}
                  primaryTypographyProps={{ fontWeight: 'bold' }}
                />
              </ListItemButton>
            </div>
          ))}
        </List>}
      </Modal.Body>
    </Modal.Provider>
  )
}
