import { Checkbox, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material'
import { ListUserPermissions } from '../../../../../../services/permissions'

interface Props {
  permission: ListUserPermissions['result'][number]['permission']
  disabled?: boolean
  tooltipTitle?: string
  hasPermission: boolean
  onClick: () => void
}

export default function PermissionItem(props: Props) {
  const { permission, tooltipTitle, disabled, hasPermission, onClick } = props

  return (
    <Tooltip
      title={<Typography variant='body2'>{tooltipTitle}</Typography>}
      placement='bottom'
      disableHoverListener={!disabled}
    >
      <ListItemButton
        sx={{
          padding: '2px 8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': disabled ? {} : { backgroundColor: 'action.hover' },
          cursor: disabled ? 'default' : 'pointer',
        }}
        divider
        onClick={() => {
          if(!disabled) {
            onClick()
          }
        }}
      >
        <Checkbox
          checked={hasPermission}
          edge='start'
          disableRipple
          disabled={disabled}
          sx={{ marginRight: '8px' }}
        />
        <ListItemText
          primary={permission.displayName}
          secondary={permission.description}
          primaryTypographyProps={{ fontWeight: 'bold' }}
        />
      </ListItemButton>
    </Tooltip>
  )
}
