import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { Theme } from '../../../../../../styles/theme'

export const HeaderTitle = styled(Typography)`
  font-weight: bold;
  color: ${Theme.colors.darkishGreen};
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 16px;
  color: ${Theme.colors.darkishGreen};
`

export const FileName = styled(Typography)`
  overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
`