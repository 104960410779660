import styled from 'styled-components'

export const WidgetTitle = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;
  text-align: center;

  color: ${({ theme }) => theme.colors.htmlGrey};
  padding: 16px 4px;
  margin: auto;
`

export const WidgetTitleColumn = styled(WidgetTitle)`
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.05em;
  padding: 11px 28px 11px 28px;
`

export const WidgetInfo = styled.h5`
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 700;
  font-size: 14.7115px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  margin: auto;

  color: ${({ theme }) => theme.colors.white};
`

export const DivWithBackground = styled.div`
  background: ${({ theme }) => theme.colors.darkishGreen};
  padding: 4px;
  max-width: 116px;
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  border-radius: 0px 8px 8px 0px;
`

export const WidgetContainer = styled.div<{ orientation: boolean }>`
  display: flex;
  flex-direction: ${({ orientation }) => (orientation ? 'row' : 'column')};
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const DivWithBackgroundColumn = styled.div`
  background: ${({ theme }) => theme.colors.darkishGreen};
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  align-self: center;
  justify-content: center;
  display: flex;
  border-radius: 8px 8px 0px 0px;
  padding: 14px 8px;
`
