import { GridColDef } from '@mui/x-data-grid'
import { useContext, useEffect, useState } from 'react'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import { SearchInput } from '../../../components/SearchInput'
import { Table } from '../../../components/Table'
import { ConsumerHistory, PowerPlantsInvoice } from '../../../services/requests/types'
import {
  consumerListGet,
  distributorListGet,
  getConsumerDashboardInvoiceStatus,
  invoicesHistory,
  invoicesOverviewGet,
  powerPlantListGet,
} from '../../../services/requests/user-requests'

import { Alert, Snackbar, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../../../components/Loading'
import { GlobalContext } from '../../../context/global/global'
import { InvoiceStatusColor } from '../../../interfaces/invoices'
import {
  ColorCaption,
  CompletePayment,
  FilterContainer,
  InvoiceCard,
  InvoiceCardBody,
  InvoiceCardCaption,
  InvoiceCardCaptionContainer,
  InvoiceHeaderCard,
  InvoiceHeaderTitleCard,
  InvoicePercentageBar,
  InvoicesContainer,
  NotLoading,
  OptionItem,
  OptionList,
  PropTitle,
  SearchContainer,
  TableFilters,
  WaitIssuance,
  YearButton,
  YearText,
} from '../styles'
import { colorsCaption } from '../utils/selectedObservationFieldColor'
import { tableInvoiceColumnsHistory } from '../utils/TableData'
import { CheckboxType } from '../../../interfaces/checkbox'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import { SettingsOutlined } from '@mui/icons-material'
import { FloatingFilterWrapper } from '../../../components/FloatingFilterWrapper'

interface ListProps {
  id: number
  label: string
  checked: boolean
}

interface PercentagesProps {
  aguardando_emissao: number
  nao_capturadas: number
  completas: number
  // nao_processadas: number
}

export default function InvoiceHistory() {
  const { state, Theme } = useContext(GlobalContext)
  const { type: authType, id: authId } = state.auth?.customer || {}

  const [tableCols, setTableCols] = useState<GridColDef[]>(tableInvoiceColumnsHistory)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [search, setSearch] = useState('')
  const [powerPlants, setPowerPlants] = useState<ListProps[] | undefined>(
    authType === 'CONSUMIDOR' ? [] : undefined,
  )
  const [consumers, setConsumers] = useState<ListProps[] | undefined>(
    authType === 'CONSUMIDOR' ? [] : undefined,
  )

  const [invoicePercentages, setInvoicePercentages] = useState<PercentagesProps>()
  const [eachPercentage, setEachPercentage] = useState<PercentagesProps>()
  const [consumerInvoices, setConsumerInvoices] = useState<PowerPlantsInvoice[]>()
  const [percentageTotal, setPercentageTotal] = useState(1)
  const [page, setPage] = useState(1)
  const [year, setYear] = useState(new Date().getFullYear())

  const [powerPlantsTotal, setPowerPlantsTotal] = useState(0)
  const [sortValues, setSortValues] = useState<{ field: string; sort: string }>()
  const [loading, setLoading] = useState(authType === 'GESTOR')
  const [ready, setReady] = useState(false)
  const [currentColor, setCurrentColor] = useState('')
  const [showDropDown, setShowDropDown] = useState(false)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)

  const [distributors, setDistributors] = useState<ListProps[]>()

  const filterDistributor = (values: any) => {
    setPage(1)
    setDistributors(values)
  }

  const [modelStateHistory, setModelStateHistory] = useState([
    {
      field: 'uc_nome',
      sort: undefined,
    },
    {
      field: 'uc_numero',
      sort: undefined,
    },
  ])

  const location = useLocation()
  const navigate = useNavigate()

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: `${currentColor}`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${currentColor}`,
      color: '#FFFFFF',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      letterSpacing: 0,
      fontSize: 14,
      padding: 8,
      maxWidth: 280,
      border: '1px solid white',
    },
  }))
  // uc_nome = nome
  // uc_numero = documento_numero

  useEffect(() => {
    if (!location.state && authType === 'GESTOR') navigate('/invoices')
  }, [])

  useEffect(() => {
    Promise.all([powerPlantListGet(), consumerListGet(), distributorListGet()])
      .then(async ([powerPlantData, consumerData, distributorData]) => {
        const powerPlantResponse = powerPlantData
        const consumerResponse = consumerData
        const distribuitorList = distributorData

        setLoading(!powerPlantData.data.length || !consumerData.data.length ? false : true)
        setPowerPlants(
          powerPlantResponse.data.map((item) => ({
            id: item.id,
            label: item.nome,
            checked: false,
          })),
        )

        setConsumers(
          consumerResponse.data.map((item) => ({
            id: item.id,
            label: item.cliente_nome,
            checked: false,
          })),
        )

        setDistributors(
          distribuitorList.data.map((item: { id: number; nome: string }) => ({
            id: item.id,
            label: item.nome,
            checked: true,
          })),
        )
      })
      .catch((error) => {
        setLoading(false)
        setErrorMessage(
          error.message ??
            'Erro ao buscar os dados iniciais de usina e distribuidora.',
        )
      })
  }, [])

  useEffect(() => {
    const fetchInvoices = async () => {
      if (consumers) {
        await invoicesHistory({
          consumersId: consumers.map((consumer) => consumer.id),
          limit: pageItemsAmount,
        })
      }
    }
    fetchInvoices()
  }, [consumers])

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    let month = 12

    if (year === currentYear) {
      month = currentMonth
    } else {
      month = 12
    }
    const invoicesOverview = ({
      request,
      consumersId,
      distributorsId = [],
    }: {
      request?: any
      consumersId: number[]
      distributorsId?: number[]
    }) => {
      Promise.all(
        authType === 'CONSUMIDOR'
          ? [
              getConsumerDashboardInvoiceStatus({
                year,
                month: currentMonth,
                onlyYear: true,
              }),
              invoicesHistory({
                consumersId,
                distributorsId,
                powerPlantsId:
                  powerPlants?.filter((item) => item.checked).map((item) => item.id as number) ??
                  [],
                page: page,
                filter: search,
                year,
                sort: sortValues?.field === 'uc_nome' ? sortValues?.field.split('_')[1] : 'codigo',
                order: sortValues?.sort,
                limit: pageItemsAmount,
              }),
            ]
          : [
              invoicesOverviewGet({
                ...request,
                isPowerPlant: 'false',
                year,
                isDashboard: true,
                month: currentMonth,
                consumersId,
              }),
              invoicesHistory({
                consumersId,
                distributorsId,
                powerPlantsId:
                  powerPlants?.filter((item) => item.checked).map((item) => item.id as number) ??
                  [],
                page: page,
                filter: search,
                year,
                sort: sortValues?.field === 'uc_nome' ? sortValues?.field.split('_')[1] : 'codigo',
                order: sortValues?.sort,
                limit: pageItemsAmount,
              }),
            ],
      )
        .then(async ([overViewData, historyData]) => {
          const responseOverview = overViewData
          const consumersResponse = historyData

          consumersResponse.data.meta.total &&
            setPowerPlantsTotal(consumersResponse.data.meta.total)

          setConsumerInvoices(
            consumersResponse.data.ucs
              ? consumersResponse.data.ucs.map((item: ConsumerHistory) => ({
                  uc_nome: item.uc_nome,
                  uc_numero: item.uc_numero,
                  consumidor_nome: item.consumidor_nome,
                  distribuidora_nome: item.distribuidora_nome,
                  ...item.faturas,
                  id: item.uc_id,
                }))
              : null,
          )

          setPercentageTotal(
            Number(Object.values(responseOverview.data).reduce((r: any, c: any) => r + c)),
          )
          setInvoicePercentages(responseOverview.data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
          setErrorMessage(
            error.message ?? 'Erro ao buscar os dados de fatura das usinas.',
          )
        })
    }

    if (powerPlants?.length && consumers?.length && distributors?.length) {
      setLoading(true)
      let allIds = location.state as {
        distributorsId: Array<number>
        powerPlantsIds: Array<number>
      }
      allIds = { ...allIds }

      const request = {
        ...allIds,
        page,
        year,
      }

      const consumersId = consumers.filter((item) => item.checked).map((item) => item.id)

      const distributorsId = distributors.filter((item) => item.checked).map((item) => item.id)

      invoicesOverview({ request, consumersId, distributorsId })
    } else if (authType === 'CONSUMIDOR') {
      setLoading(true)
      const request = {
        page,
        distributorsId: [],
        powerPlantsIds: [],
        consumersId: authId ? [authId] : [],
      }
      const consumersId = authId ? [authId] : []
      invoicesOverview({ consumersId, request })
    }
  }, [
    powerPlants,
    consumers,
    page,
    ready,
    year,
    sortValues,
    pageItemsAmount,
    tableCols,
    distributors,
  ])

  useEffect(() => {
    if (invoicePercentages) {
      setEachPercentage({
        completas: Math.round((invoicePercentages.completas / percentageTotal) * 100),
        aguardando_emissao: Math.round(
          (invoicePercentages.aguardando_emissao / percentageTotal) * 100,
        ),
        // nao_processadas: Math.round((invoicePercentages.nao_processadas / percentageTotal) * 100),
        nao_capturadas: Math.round((invoicePercentages.nao_capturadas / percentageTotal) * 100),
      })
    }
  }, [invoicePercentages])

  const handlePageChange = (nextPage: number) => {
    if (nextPage !== page) {
      setPage(nextPage)
    }
  }

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  const filterConsumers = (values: any) => {
    setPage(1)
    setConsumers(values)
  }

  const generateYear = () => {
    const year = []

    for (let i = 2018; i <= new Date().getFullYear(); i++) {
      year.push(i)
    }

    return year
  }

  const filterPowerPlants = (values: any) => {
    setPage(1)
    setPowerPlants(values)
  }

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (search.length >= 3 || search.length === 0) {
        setPage(1)
        setReady(!ready)
      }
    }, 1000)

    return () => clearTimeout(delayDebounce)
  }, [search])

  return powerPlants && consumers && !loading ? (
    <InvoicesContainer>
      <InvoiceCard>
        <InvoiceHeaderCard>
          <InvoiceHeaderTitleCard>Visão geral das faturas</InvoiceHeaderTitleCard>
        </InvoiceHeaderCard>
        <InvoiceCardBody>
          {invoicePercentages && eachPercentage ? (
            <InvoicePercentageBar>
              {!!eachPercentage.completas && (
                <StyledTooltip
                  title={`Faturas carregadas - ${invoicePercentages.completas}`}
                  arrow
                  placement='top'
                >
                  <CompletePayment
                    percentage={eachPercentage.completas}
                    onMouseEnter={() =>
                      setCurrentColor(
                        Theme.colors[InvoiceStatusColor['Completas'] as keyof typeof Theme.colors],
                      )
                    }
                  >
                    {`${eachPercentage.completas}%`}
                  </CompletePayment>
                </StyledTooltip>
              )}
              {!!eachPercentage.aguardando_emissao && (
                <StyledTooltip
                  title={`Faturas aguardando emissão - ${invoicePercentages.aguardando_emissao}`}
                  arrow
                  placement='top'
                >
                  <WaitIssuance
                    percentage={eachPercentage.aguardando_emissao}
                    onMouseEnter={() =>
                      setCurrentColor(
                        Theme.colors[
                          InvoiceStatusColor['Aguardando emissão'] as keyof typeof Theme.colors
                        ],
                      )
                    }
                  >
                    {`${eachPercentage.aguardando_emissao}%`}
                  </WaitIssuance>
                </StyledTooltip>
              )}
              {/* {!!eachPercentage.nao_processadas && (
                <StyledTooltip
                  title={`Faturas não obtidas - ${invoicePercentages.nao_processadas}`}
                  arrow
                  placement='top'
                >
                  <NotDrawn
                    percentage={eachPercentage.nao_processadas}
                    onMouseEnter={() =>
                      setCurrentColor(
                        Theme.colors[
                          InvoiceStatusColor['Não obtidas'] as keyof typeof Theme.colors
                        ],
                      )
                    }
                  >
                    {`${eachPercentage.nao_processadas}%`}
                  </NotDrawn>
                </StyledTooltip>
              )} */}
              {!!eachPercentage.nao_capturadas && (
                <StyledTooltip
                  title={`Faturas não capturadas - ${invoicePercentages.nao_capturadas}`}
                  arrow
                  placement='top'
                >
                  <NotLoading
                    percentage={eachPercentage.nao_capturadas}
                    onMouseEnter={() =>
                      setCurrentColor(
                        Theme.colors[
                          InvoiceStatusColor['Não capturadas'] as keyof typeof Theme.colors
                        ],
                      )
                    }
                  >
                    {`${eachPercentage.nao_capturadas}%`}
                  </NotLoading>
                </StyledTooltip>
              )}
            </InvoicePercentageBar>
          ) : (
            <InvoicePercentageBar>
              <h4>Não foi possível obter os dados necessários.</h4>
            </InvoicePercentageBar>
          )}
          <InvoiceCardCaptionContainer>
            {colorsCaption.map((props) => (
              <InvoiceCardCaption key={props.title}>
                <ColorCaption color={Theme.colors[props.color]} />
                <PropTitle>{props.title}</PropTitle>
              </InvoiceCardCaption>
            ))}
          </InvoiceCardCaptionContainer>
        </InvoiceCardBody>
      </InvoiceCard>
      <FilterContainer>
        <TableFilters>
          {authType === 'GESTOR' && (
            <>
              <FloatingCheckboxes
                checkbox
                isFilterActive={consumers.every((consumer) => consumer.checked)}
                label='Consumidores'
                options={consumers ?? []}
                selectableAll
                searchable
                submitAction={filterConsumers}
              />
              {distributors && (
                <FloatingCheckboxes
                  isFilterActive={distributors.some((distributor) => distributor.checked)}
                  label='Distribuidoras'
                  options={distributors}
                  selectableAll
                  searchable
                  submitAction={filterDistributor}
                />
              )}
            </>
          )}
          {generateYear().map((item) => (
            <YearButton
              key={item}
              active={item === year}
              onClick={() => {
                setPage(1)
                item !== year ? setYear(item) : null
              }}
            >
              <YearText>{item}</YearText>
            </YearButton>
          ))}
        </TableFilters>
        <SearchContainer>
          <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />
          <FloatingCheckboxes
            pagination={{
              selected: pageItemsAmount,
              setSelected: setPageItemsAmount,
              options: [15, 20, 30, 50, 100],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableCols.map((col) => ({
              label: col.headerName || '',
              checked: !col.hide,
              id: Math.random(),
            }))}
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={handleSelectTableColumns}
          />
        </SearchContainer>
      </FilterContainer>
      <Table
        columns={tableCols}
        rows={consumerInvoices ?? []}
        sortEvent={setSortValues}
        messageNoRow='Não há faturas cadastradas.'
        alignText='center'
        serverSorting
        headerModel={modelStateHistory}
        setHeaderModel={setModelStateHistory}
        pagination={{
          page,
          total: powerPlantsTotal,
          limit: pageItemsAmount,
          totalRegistersPerPage: consumerInvoices?.length ?? 0,
          onChangePage: handlePageChange,
        }}
      />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </InvoicesContainer>
  ) : (
    <Loading />
  )
}
