import {
  ConfigButtonsColumn,
  ContainerPreviewInvoice,
  FiltersContainer,
  PaymentsContainer,
  SettingsContainer,
} from './styles'

import { Alert, Snackbar } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as SendIcon } from '../../assets/send.svg'
import { FloatingCheckboxes } from '../../components/FloatingCheckboxes'
import Loading from '../../components/Loading'
import Modal from '../../components/Modal'
import { SendButton } from '../../components/Modal/Payments/EditPaymentModal/styles'
import { SearchInput } from '../../components/SearchInput'
import { Table } from '../../components/Table'
import { ActionTableButton } from '../../components/Table/ActionTableButton'
import { CheckboxType } from '../../interfaces/checkbox'
import { distributorListGet, getAllClients, getDemonstratives } from '../../services/requests/user-requests'
import { IFloatingCheckboxFilterItem, PaymentsTypeData } from './types'
import { tableColumns } from './utils'

import { SettingsOutlined } from '@mui/icons-material'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { DateFilter } from '../../components/Filter/DateFilter'
import { DemonstrativeModal } from '../../components/Modal/Demonstrative'
import StatusFilter from './components/StatusFilter'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface invoicePreviewData {
  type: 'html' | 'pdf'
  toRender: string | { type: string; data: Buffer }
  toDownload: {
    filename: string
    content: { type: string; data: Buffer }
  }
}

interface IPreviewInvoiceProps {
  invoicePreviewData: invoicePreviewData
  downloadingInvoice: boolean
  setDownloadingInvoice: React.Dispatch<React.SetStateAction<boolean>>
}

const PreviewHtmlInvoice = ({ toRender }: Pick<invoicePreviewData, 'toRender'>) => {
  const ContainerPreviewInvoiceRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ContainerPreviewInvoiceRef.current) {
      ContainerPreviewInvoiceRef.current.innerHTML = toRender as string
    }
  }, [ContainerPreviewInvoiceRef])

  return <div style={{ maxWidth: '780px' }} ref={ContainerPreviewInvoiceRef} />
}

const PreviewInvoice = ({
  invoicePreviewData,
  downloadingInvoice,
  setDownloadingInvoice,
}: IPreviewInvoiceProps) => {
  const downloadPreview = async (pdf: Buffer, fileName: string) => {
    setDownloadingInvoice(true)
    if (invoicePreviewData) {
      // example: https://thelearning.dev/how-to-download-files-on-button-click-reactjs

      // file object
      const file = new Blob([new Uint8Array(pdf).buffer])

      // anchor link
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)

      element.download = fileName

      // simulate link click
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
    }
    setDownloadingInvoice(false)
  }

  const PreviewType = ({ type, toRender }: Omit<invoicePreviewData, 'toDownload'>) => {
    switch (type) {
      case 'html':
        return <PreviewHtmlInvoice toRender={toRender as string} />

      case 'pdf':
        return (() => {
          const myBuffer = (toRender as { data: Buffer }).data
          return (
            <Document
              file={{ data: new Uint8Array(myBuffer) }}
              onSourceError={(error) => console.log('source error', error)}
              loading={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <span>Carregando pré-visualização...</span>
                </div>
              }
              onLoadError={(error) => console.log('load error', error)}
            >
              <Page pageNumber={1} width={720} />
            </Document>
          )
        })()
    }
  }

  return (
    <ContainerPreviewInvoice>
      {invoicePreviewData?.toDownload && (
        <SendButton
          onClick={() =>
            downloadPreview(
              invoicePreviewData.toDownload.content.data,
              invoicePreviewData.toDownload.filename,
            )
          }
          disabled={downloadingInvoice}
        >
          {downloadingInvoice ? 'Carregando..' : 'Baixar PDF'}
        </SendButton>
      )}
      {invoicePreviewData?.type && invoicePreviewData?.toRender && (
        <PreviewType type={invoicePreviewData.type} toRender={invoicePreviewData.toRender} />
      )}
      {!invoicePreviewData && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span>Carregando informações...</span>
        </div>
      )}
    </ContainerPreviewInvoice>
  )
}

export function DemonstrativeToInvoice() {
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [ready, setReady] = useState(false)
  const [total, setTotal] = useState(0)
  const [tableCols, setTableCols] = useState<GridColDef[]>(tableColumns)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [clients, setClients] = useState<IFloatingCheckboxFilterItem[]>([])
  const [suppliers, setSuppliers] = useState<IFloatingCheckboxFilterItem[]>([])
  const [powerPlants, setPowerPlants] = useState<IFloatingCheckboxFilterItem[]>([])

  const [modelPayments, setModelPayments] = useState([
    { field: 'codigo', sort: undefined },
    { field: 'energia_compensada_total', sort: undefined },
    { field: 'consumo_total', sort: undefined },
    { field: 'remuneracao_geracao', sort: undefined },
  ])
  const [payments, setPayments] = useState<PaymentsTypeData[] | null>(null)
  const [update, setUpdate] = useState(false)
  const [day, setDay] = useState(0)
  const [dayFilterArray, setDayFilterArray] = useState<string[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [loading, setLoading] = useState(true)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [sortValues, setSortValues] = useState<{ field: string; sort: string }>()
  const [showInvoicePreview, setShowInvoicePreview] = useState<boolean>(false)
  const [invoicePreviewData, setInvoicePreviewData] = useState<any>(null)
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<string>()

  const [isSendAllOpen, setIsSendAllOpen] = useState(false)

  function handleChangePage(page: number) {
    setPage(page)
  }

  async function handleSendAllCharges() {
    if (!selectedIds.length) {
      setErrorMessage('Selecione pelo menos um demonstrativo.')
      return
    }

    setIsSendAllOpen(true)
  }

  async function getData() {
    try {
      const allClients = clients
        .filter((val) => val.checked)
        .map((val) => val.id)
        .join(',')

      const selectedSuppliers = suppliers
        .filter((supplier) => supplier.checked)
        .map((dist) => dist.id)
        .join(',')

      const { data } = await getDemonstratives({
        page,
        filter: search,
        limit: pageItemsAmount,
        sort: sortValues?.field ?? 'codigo',
        order: sortValues?.sort ?? 'ASC',
        year: dateSelected.year,
        month: dateSelected.month,
        clients: allClients,
        status: statusFilter,
        suppliers: selectedSuppliers
      })

      setTotal(data.maxPages * pageItemsAmount)
      setPayments(data.data)
    } catch (error) {
      console.log({ error })
      setErrorMessage('Erro ao carregar os dados de demonstrativo.')
    }
  }

  useEffect(() => {
    if (payments != null) {
      const delayDebounce = setTimeout(() => {
        setPage(1)

        setReady(!ready)
      }, 2000)
      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  useEffect(() => {
    ;(async () => {
      await getData()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    const daysInMonth = new Date(dateSelected.year, dateSelected.month, 0).getDate()
    setDayFilterArray([...new Array(daysInMonth)].map((_, index) => `${index + 1}`))
    setDay(0)
  }, [dateSelected])

  useEffect(() => {
    if (!loading) {
      ;(async () => {
        setIsSearchLoading(true)
        await getData()
        setIsSearchLoading(false)
      })()
    }
  }, [
    page,
    clients,
    suppliers,
    powerPlants,
    update,
    statusFilter,
    ready,
    sortValues,
    dateSelected,
    pageItemsAmount,
    tableCols,
  ])

  useEffect(() => {
    ;(async () => {
      const clientsPayload = await getAllClients()

      setClients(
        clientsPayload.data.map((val: any) => ({ ...val, label: val.cliente_nome, checked: true })),
      )
    })()
  }, [])

  useEffect(() => {
    const getSuppliers = async () => {
      const payload = await distributorListGet()
      const data = payload.data ?? []

      if (!data?.length) return

      const formattedPayload = data?.map(
        (supplier: { id: number, nome: string }) => ({ 
          ...supplier,
          label: supplier.nome, 
          checked: true 
        })
      )

      setSuppliers(formattedPayload)
    }

    getSuppliers()
  }, [])

  function filterClients(values: CheckboxType[]) {
    if (!values.length) return

    setClients(values)
  }

  function filterSuppliers(values: CheckboxType[]) {
    if (!values.length) return

    setSuppliers(values)
  }

  useEffect(() => {
    if (!showInvoicePreview) {
      setInvoicePreviewData(null)
    }
  }, [showInvoicePreview])

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  if (loading) {
    return <Loading />
  }

  return (
    <PaymentsContainer>
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      <Modal
        title='Pré-visualização da cobrança'
        openModal={showInvoicePreview}
        setModalOpen={setShowInvoicePreview}
        defaultButtons={false}
        opacity={1}
        width='780px'
      >
        <PreviewInvoice
          invoicePreviewData={invoicePreviewData}
          downloadingInvoice={downloadingInvoice}
          setDownloadingInvoice={setDownloadingInvoice}
        />
      </Modal>
      <SettingsContainer>
        <FiltersContainer>
          <FloatingCheckboxes
            isFilterActive={suppliers.some(({ checked }) => checked === true)}
            label='Distribuidora'
            options={suppliers}
            selectableAll
            searchable
            submitAction={filterSuppliers}
          />

          <FloatingCheckboxes
            isFilterActive={clients.some(({ checked }) => checked === true)}
            label='Clientes'
            options={clients}
            selectableAll
            searchable
            submitAction={filterClients}
          />

          <StatusFilter statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
        </FiltersContainer>
        <ConfigButtonsColumn>
          <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />
          <FloatingCheckboxes
            pagination={{
              selected: pageItemsAmount,
              setSelected: setPageItemsAmount,
              options: [15, 20, 30, 50, 100],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableCols.map((col) => ({
              label: col.headerName || '',
              checked: !col.hide,
              id: Math.random(),
            }))}
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={handleSelectTableColumns}
          />

          <ActionTableButton onClick={handleSendAllCharges} icon={<SendIcon />} />
        </ConfigButtonsColumn>
      </SettingsContainer>
      {isSearchLoading ? (
        <Loading />
      ) : (
        <Table
          pagination={{
            page,
            total,
            limit: pageItemsAmount,
            totalRegistersPerPage: payments?.length || 0,
            onChangePage: handleChangePage,
          }}
          columns={tableCols}
          rows={payments?.map((item) => ({ ...item })) || []}
          alignText='center'
          sortEvent={setSortValues}
          serverSorting
          messageNoRow='Não há demonstrativos.'
          isSelectable={true}
          setSelectedItems={setSelectedIds}
          headerModel={modelPayments}
          setHeaderModel={setModelPayments}
        />
      )}

      {isSendAllOpen && (
        <DemonstrativeModal
          setErrorMessage={setErrorMessage}
          openModal={isSendAllOpen}
          setModalOpen={setIsSendAllOpen}
          sendAll={isSendAllOpen}
          allData={payments!}
          selectedIds={selectedIds}
          postAction={getData}
          selectedDate={dateSelected}
        />
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </PaymentsContainer>
  )
}
