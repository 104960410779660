import { GridRenderCellParams } from '@mui/x-data-grid'
import { StatusText } from './styles'

export const tableColumns = [
  {
    field: 'fatura_id',
    headerName: 'Status',
    sortable: false,
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return (
        <StatusText className={params.value ? 'charged' : 'notCharged'}>
          {params.value ? 'Fatura existente' : 'Fatura Inexistente'}
        </StatusText>
      )
    },
  },
  {
    field: 'distribuidora_nome',
    headerName: 'Distribuidora',
  },
  {
    field: 'codigo',
    headerName: 'Número da UC',
  },
  {
    field: 'nome',
    headerName: 'Nome da UC',
  },
  {
    field: 'subgrupo',
    headerName: 'Sub-grupo',
  },
  {
    field: 'energia_compensada_total',
    headerName: 'Energia Compensada',
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return <>{params.value.toFixed(2)}</>
    },
  },
  {
    field: 'consumo_total',
    headerName: 'Energia Consumida',
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return <>{params.value.toFixed(2)}</>
    },
  },
  {
    field: 'remuneracao_geracao',
    headerName: 'Valor Fechamento',
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return <>{params.value ? params.value.toFixed(2) : 0}</>
    },
  },
  {
    field: 'saldo_acumulado',
    headerName: 'Saldo Acumulado',
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return <>{params.value ? Number(params.value) : 0}</>
    },
  },
  // {
  //   field: 'status_ativacao_uc',
  //   headerName: 'Status de contrato',
  //   hide: true,
  //   renderCell(params: GridRenderCellParams<any, any, any>) {
  //     if (params.value === false) {
  //       return <DisabledAcess>Desativada</DisabledAcess>
  //     }
  //     return <PositiveAcess>Ativa</PositiveAcess>
  //   },
  // },
  // {
  //   field: 'data_vencimento',
  //   headerName: 'Vencimento',
  //   renderCell(params: GridRenderCellParams<any, any, any>) {
  //     const date = new Date(params.value)
  //     const formattedDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' })
  //     return <RowText>{formattedDate.format(date)}</RowText>
  //   },
  // },
  // {
  //   field: 'remuneracao_geracao',
  //   headerName: 'Valor devido (R$)',
  //   renderCell(params: GridRenderCellParams<any, any, any>) {
  //     const formattedCurrency = new Intl.NumberFormat('pt-BR', {
  //       style: 'currency',
  //       currency: 'BRL',
  //     })

  //     return <RowText>{formattedCurrency.format(params.value).replace('R$', '')}</RowText>
  //   },
  // },
]
