import { AxiosResponse } from 'axios'

export interface PowerPlantProps {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editValues?: ModalEditPowerPlantData
  postAction?: () => void
}

export interface ModalRegisterPowerPlantData {
  nome: string
  fonte: string
  potencia_instalada: number
  distribuidora_id: number
  codigo: string
  senha_dist: string
  cep: string
  modalidade: string
  cliente_id_consumidor: number
  planta_ids: number
  grupo: string
  login: string
  inversor_id?: number
  documento_numero: string
  cliente_consumidor_nome?: string
}

export interface ModalEditPowerPlantData extends ModalRegisterPowerPlantData {
  id: number
}

export interface ModalAddQuotaProps {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editValues?: {
    consumerId: number
    usinaId: number
    ucId: number
    powerPlantId: number
    year: string
    month: string
    quota: number
    ucName: string
    ucNumber: string
    id: number
  }
  postAction?: () => void
}

export enum FontsEnum {
  'solar' = 'Solar',
  'hídrica' = 'Hídrica',
  'eólica' = 'Eólica',
  'biogás/biomassa' = 'BIOGAS/BIOMASSA',
}

export const consumptionModalityArr = [
  { 'Autoconsumo Remoto': 'AUTOCONSUMO_REMOTO' },
  { 'Autoconsumo Local': 'AUTOCONSUMO_LOCAL' },
  { EMUC: 'EMUC' },
  { 'Geração Compartilhada': 'GERACAO_COMPARTILHADA' },
  { 'Associação': 'ASSOCIACAO' },
]

export const fontsArr = [
  { Solar: 'SOLAR' },
  { Hídrica: 'HIDRICA' },
  { Eólica: 'EOLICA' },
  { 'Biogás/Biomassa': 'BIOGAS_BIOMASSA' },
]

export type fontsType = 'SOLAR' | 'HIDRICA' | 'EOLICA' | 'BIOGAS_BIOMASSA'

export const fonts = {
  SOLAR: 'Solar',
  HIDRICA: 'Hídrica',
  EOLICA: 'Eólica',
  BIOGAS_BIOMASSA: 'Biogás/Biomassa',
}

export const Fonts = FontsEnum as unknown as string

export interface GetResponseData {
  responseGet: () => Promise<AxiosResponse<any, any>>
  responseData: React.Dispatch<
    React.SetStateAction<
      {
        value: number
        label: string
      }[]
    >
  >
}
