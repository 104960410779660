import styled from 'styled-components'

export const RegisterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  @media screen and (min-width: 1498px) {
    grid-template-columns: repeat(4, 0.3fr);
  }

  @media screen and (max-width: 1125px) {
    justify-content: center;
    gap: 1rem;
  }
`
