import { useEffect, useState } from 'react'
import Modal from '../../../../../components/Modal'
import { InvoiceValidation } from '../../../../../interfaces/invoices'
import { StickyFooter } from './styles'
import { Formik } from 'formik'
import { Button } from '../../../../../components/Button'
import Loading from '../../../../../components/Loading'
import InvoiceClosing from '../InvoiceClosing'
import InvoiceForm from '../InvoiceForm'
import { invoiceParserV2 } from '../../../../../services/invoice-parser-v2'
import { IconButton, Stack, Typography } from '@mui/material'
import { useToast } from '../../../../../hooks/useToast'
import AddInvoiceField from '../AddInvoiceField'
import { parseNumbers, removeEmptyFields } from '../../../../../utils/obj-utils'
import VisibilityIcon from '@mui/icons-material/Visibility'

interface Props {
  invoiceValidationId: string
  onValidate: () => void
  onClose: () => void
}

export default function InvoiceModal(props: Props) {
  const { invoiceValidationId, onValidate, onClose } = props

  const [invoiceValidation, setInvoiceValidation] = useState<InvoiceValidation>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const [closingModalOpen, setClosingModalOpen] = useState<boolean>(false)

  const [showGeneralData, setShowGeneralData] = useState<boolean>(false)

  const { toast } = useToast()

  const updateInvoiceData = async (data: Record<string, unknown>) => {
    try {
      setLoading(true)
      const invoiceValidationUpdated = await invoiceParserV2.updateInvoiceValidationData(
        invoiceValidationId,
        parseNumbers(data),
      )
      setInvoiceValidation({
        ...invoiceValidationUpdated,
        invoiceData: invoiceValidationUpdated.invoiceData
          ? (removeEmptyFields(
              invoiceValidationUpdated.invoiceData,
            ) as InvoiceValidation['invoiceData'])
          : undefined,
      })
    } catch (error) {
      toast({
        message: `Erro ao atualizar fatura: ${error}`,
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const fetchInvoiceValidation = async () => {
    try {
      setLoading(true)
      const invoice = await invoiceParserV2.getInvoiceToValidateById(invoiceValidationId)
      setInvoiceValidation({
        ...invoice,
        invoiceData: invoice.invoiceData
          ? (removeEmptyFields(invoice.invoiceData) as InvoiceValidation['invoiceData'])
          : undefined,
      })
    } catch (error) {
      toast({
        message: `Erro ao procurar fatura: ${error}`,
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const addFieldsToInvoiceData = (fields: Record<string, unknown>) => {
    setInvoiceValidation((previousInvoiceValidation) =>
      previousInvoiceValidation
        ? {
            ...previousInvoiceValidation,
            invoiceData: previousInvoiceValidation.invoiceData
              ? {
                  ...fields,
                  ...previousInvoiceValidation.invoiceData,
                }
              : undefined,
          }
        : undefined,
    )
  }

  useEffect(() => {
    fetchInvoiceValidation()
  }, [invoiceValidationId])

  return (
    <Modal
      width='720px'
      title='Dados da Fatura'
      setModalOpen={onClose}
      defaultButtons={false}
      openModal
    >
      {(isLoading || !invoiceValidation) && <Loading />}

      {closingModalOpen && (
        <InvoiceClosing
          invoiceValidationId={invoiceValidationId}
          onCreate={() => {
            onValidate()
            onClose()
          }}
          onClose={() => setClosingModalOpen(false)}
        />
      )}

      {!isLoading && invoiceValidation && !closingModalOpen && (
        <>
          {invoiceValidation.error && (
            <div style={{ marginBottom: '20px' }}>
              <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                Erros na leitura:
              </Typography>
              <div style={{ margin: '10px 0' }}>
                {invoiceValidation.error.split('|').map((error, index) => (
                  <Typography
                    key={`invoice-validation-error-${invoiceValidation.id}-${index}`}
                    variant='body2'
                    color='red'
                  >
                    {error}
                  </Typography>
                ))}
              </div>
            </div>
          )}

          <AddInvoiceField onAddField={(key, value) => addFieldsToInvoiceData({ [key]: value })} />

          <Stack direction="row" alignItems="center" marginBottom={2}>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              Dados da Fatura
            </Typography>

            <IconButton style={{ marginLeft: 'auto' }} onClick={() => setShowGeneralData(previous => !previous)}>
              <VisibilityIcon />
            </IconButton>
          </Stack>

          <Formik
            initialValues={invoiceValidation.invoiceData ?? {}}
            enableReinitialize
            onSubmit={updateInvoiceData}
          >
            <InvoiceForm invoiceValidation={invoiceValidation} showGeneral={showGeneralData}>
              <StickyFooter>
                <Button text='Fechar' onClick={onClose} />
                <Button type='submit' text='Atualizar' disabled={!!isLoading} />
                <Button
                  text='Fechamento'
                  onClick={() => setClosingModalOpen(true)}
                  disabled={!!isLoading}
                ></Button>
              </StickyFooter>
            </InvoiceForm>
          </Formik>
        </>
      )}
    </Modal>
  )
}
