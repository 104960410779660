import { Typography } from '@mui/material'
import { Buttons, CancelButton, Container } from './styles'
import CloseTicketButton from '../CloseTicketButton'
import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  ticketId: number
  onClose: () => void
  onBack: () => void
}

export default function CloseTicket(props: Props) {
  const { ticketId, onClose, onBack, ...rest } = props

  return (
    <Container {...rest}>
      <Typography variant='h5' fontWeight='bold'>
        Você deseja encerrar este ticket?
      </Typography>

      <Buttons>
        <CancelButton text='Voltar' mode='white' onClick={onBack} />
        <CloseTicketButton ticketId={ticketId} onClose={onClose} />
      </Buttons>
    </Container>
  )
}
