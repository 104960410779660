import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'
import { InvoiceParser } from '../../../../interfaces/invoices'
import { invoiceParserService } from '../../../../services/invoice-parser'

interface InvoiceListContextData {
  invoiceList: InvoiceParser[]
  isLoading: boolean
  addInvoicesToList: (invoices: InvoiceParser[]) => void
  updateInvoiceFromList: (invoiceParser: InvoiceParser) => void
  removeInvoiceFromList: (invoiceParserId: string) => void
}

const InvoiceListContext = createContext({} as InvoiceListContextData)

export function InvoiceListProvider(props: PropsWithChildren) {
  const { children } = props

  const [invoiceList, setInvoiceList] = useState<InvoiceParser[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const pollingTimeoutRef = useRef<NodeJS.Timeout>()

  const updateInvoices = (invoices: InvoiceParser[]) => {
    const sortedInvoices = invoices.sort((i1, i2) => {
      if (i1.raw_data && !i2.raw_data) {
        return -1
      } else if (!i1.raw_data && i2.raw_data) {
        return 1
      } else {
        return 0
      }
    })
    setInvoiceList(sortedInvoices)
  }

  const canStartPolling = (invoices: InvoiceParser[]) => {
    for (const invoice of invoices) {
      if (invoice.status === 'PROCESSING') {
        return true
      }
    }
    return false
  }

  const startPolling = () => {
    const timeout = setTimeout(async () => {
      const invoices = await invoiceParserService.getInvoices()
      updateInvoices(invoices)

      if (canStartPolling(invoices)) {
        startPolling()
      }
    }, 15000)
    pollingTimeoutRef.current = timeout
  }

  const stopPolling = () => {
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current)
      pollingTimeoutRef.current = undefined
    }
  }

  const fetchInvoiceList = async () => {
    const invoices = await invoiceParserService.getInvoices()
    updateInvoices(invoices)

    if (canStartPolling(invoices)) {
      startPolling()
    }
  }

  const addInvoicesToList = (invoices: InvoiceParser[]) => {
    setInvoiceList((previousList) => [...previousList, ...invoices])
  }

  const updateInvoiceFromList = (invoiceParser: InvoiceParser) => {
    const indexFound = invoiceList.findIndex(
      (invoiceParserFromList) => invoiceParserFromList.id === invoiceParser.id,
    )

    if (indexFound !== -1) {
      const updatedInvoiceList = [...invoiceList]
      updatedInvoiceList[indexFound] = invoiceParser
      setInvoiceList(updatedInvoiceList)
    }
  }

  const removeInvoiceFromList = (invoiceParserId: string) => {
    setInvoiceList((previous) => [...previous].filter((invoice) => invoice.id !== invoiceParserId))
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        await fetchInvoiceList()
      } catch (error) {
        console.log(`Erro ao listar faturas em processamento: ${error}`)
      } finally {
        setLoading(false)
      }
    }

    fetch()
    return () => {
      stopPolling()
    }
  }, [])

  return (
    <InvoiceListContext.Provider
      value={{ invoiceList, isLoading, removeInvoiceFromList, updateInvoiceFromList, addInvoicesToList }}
    >
      {children}
    </InvoiceListContext.Provider>
  )
}

export function useInvoiceList() {
  const context = useContext(InvoiceListContext)
  if (!context) {
    throw new Error('Provider not found')
  }
  return context
}
