import { Form, FormikProvider, useFormik } from 'formik'
import Modal from '../../../../components/ModalV2'
import * as Yup from 'yup'
import { useToast } from '../../../../hooks/useToast'
import ClientSelect from './components/ClientSelect'
import { InputV2 } from '../../../../components/InputV2'
import { format, isAfter, isToday, parse } from 'date-fns'
import { aggregatedPaymentsService } from '../../../../services/aggregated-payments'
import { Button } from '../../../../components/Button'
import { useState } from 'react'
import { Stack } from '@mui/material'

interface FormData {
  clientId: number
  dueDate: string
}

const validationSchema = Yup.object({
  clientId: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
  dueDate: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório')
    .matches(/^(\d{2})\/(\d{2})\/(\d{4})$/, 'Formato inválido, o correto é: DD/MM/YYYY'),
  // .test('dueDate reference', 'Data de vencimento inválida', (value) => {
  //   if (!value) {
  //     return false
  //   }
  //   const currentDate = new Date()
  //   const date = parse(value, 'dd/MM/yyyy', new Date())
  //   return isToday(date) || isAfter(date, currentDate)
  // }),
})

interface Props {
  aggregatedPaymentId?: number
  initialValues?: {
    clientId: number
    dueDate: string
  }
  referenceMonth: Date
  onSuccess: () => void
  onClose: () => void
}

export default function AggregatedPaymentModal(props: Props) {
  const { aggregatedPaymentId, initialValues, referenceMonth, onSuccess, onClose } = props

  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const { toast } = useToast()

  const handleSubmit = async (formData: FormData) => {
    try {
      setSubmitting(true)

      const dueDate = format(parse(formData.dueDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')

      if (!aggregatedPaymentId) {
        await aggregatedPaymentsService.createAggregatedPayment({
          ...formData,
          dueDate,
          referenceMonth: format(referenceMonth, 'yyyy-MM-dd'),
        })
      } else {
        await aggregatedPaymentsService.updateAggregatedPayment({
          aggregatedPaymentId,
          dueDate,
        })
      }
      toast({
        message: `Faturamento agrupado ${!initialValues ? 'criado' : 'atualizado'} com sucesso`,
        type: 'success',
      })
      onSuccess()
      onClose()
    } catch (error) {
      console.log(error)
      toast({
        message: `Erro ao ${!initialValues ? 'criar' : 'atualizar'} faturamento agrupado`,
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik<FormData>({
    initialTouched: initialValues
      ? Object.keys(initialValues).reduce((agg: Record<string, boolean>, key) => {
          agg[key] = true
          return agg
        }, {})
      : {},
    initialValues: initialValues ?? {
      clientId: undefined as unknown as number,
      dueDate: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: true,
  })

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {!initialValues ? 'Novo' : 'Editar'} faturamento agrupado
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <FormikProvider value={formik}>
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
            }}
          >
            <ClientSelect
              selectedClientId={initialValues?.clientId}
              onChange={(clientId) => formik.setFieldValue('clientId', clientId, true)}
              required
              disabled={!!initialValues}
            />
            <Stack direction='row' gap={4}>
              <InputV2
                id='dueDate'
                label='Data de Vencimento'
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                required
                name='dueDate'
                value={formik.values.dueDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik
              />

              <InputV2
                label='Mês de Referencia'
                value={format(referenceMonth, 'MM/yyyy')}
                disabled
                formik={false}
              />
            </Stack>

            <Button
              type='submit'
              isLoading={isSubmitting}
              text={!initialValues ? 'Cadastrar' : 'Atualizar'}
              containerStyle={{ marginLeft: 'auto', width: 'fit-content' }}
            />
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal.Provider>
  )
}
