import React from 'react'
import Widget from '../Widget'

import {
  WidgetTitle,
  WidgetContainer,
  WidgetInfo,
  DivWithBackground,
  DivWithBackgroundColumn,
  WidgetTitleColumn,
} from './styles'

interface WidgetProps {
  title: string
  value: string
  orientation: 'row' | 'column'
  style?: React.CSSProperties
}

export function WidgetDetails({ title, value, orientation, style }: WidgetProps) {
  const isOrientationRow = orientation === 'row'
  return (
    <Widget style={style}>
      {isOrientationRow ? (
        <WidgetContainer orientation={isOrientationRow}>
          <WidgetTitle>{title}</WidgetTitle>
          <DivWithBackground>
            <WidgetInfo>{value}</WidgetInfo>
          </DivWithBackground>
        </WidgetContainer>
      ) : (
        <WidgetContainer orientation={isOrientationRow}>
          <DivWithBackgroundColumn>
            <WidgetInfo>{value}</WidgetInfo>
          </DivWithBackgroundColumn>
          <WidgetTitleColumn>{title}</WidgetTitleColumn>
        </WidgetContainer>
      )}
    </Widget>
  )
}

export default WidgetDetails
