import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Modal from '../../../../components/Modal'
import {
  ButtonsContainer,
  CancelButton,
  SendButton,
} from '../../../../components/Modal/Payments/EditPaymentModal/styles'
import { GlobalContext } from '../../../../context/global/global'
import { uploadInvoice } from '../../../../services/requests/user-requests'
import { Button, Container, FileStatusMessage, FilesList, UploadContainer, Wrapper } from './styles'

interface UploadInvoicesModalProps {
  openModal: boolean
  setOpenModal: (open: boolean) => void
}

interface StatusFile {
  code: number
  message: string
}

export function UploadInvoicesModal(props: UploadInvoicesModalProps) {
  const { openModal, setOpenModal } = props
  const { Theme } = useContext(GlobalContext)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [statusFiles, setStatusFiles] = useState<StatusFile[]>([])
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState<boolean>(false)
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/html': ['.pdf'],
    },
    onDrop: (files) => {
      setAcceptedFiles((prevFiles: any) => [...prevFiles, ...files])
    },
  })

  const files = acceptedFiles.map((file: any, index: number) => (
    <Wrapper key={index}>
      <Container key={file.name}>
        <DescriptionRoundedIcon />
        {file.name}
      </Container>
      {(() => {
        switch (statusFiles[index]?.code) {
          case 1:
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.grayDark}>
                {statusFiles[index].message}
              </FileStatusMessage>
            )
          case 2:
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.darkishGreen}>
                {statusFiles[index].message}
              </FileStatusMessage>
            )
          case 3: {
            const message = statusFiles[index].message;
            const isPending = message === 'Fatura em analise'
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={isPending ? Theme.colors.green : Theme.colors.suportError}>
                {statusFiles[index].message}
              </FileStatusMessage>
            )
          }
          default:
            return acceptedFiles.filter((file2) => file2.name === file.name).length > 1 ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                  Arquivo duplicado
                </FileStatusMessage>
                <Button onClick={() => handleDelete(index)}>
                  <DeleteRoundedIcon />
                </Button>
              </div>
            ) : (
              <Button onClick={() => handleDelete(index)}>
                <DeleteRoundedIcon />
              </Button>
            )
        }
      })()}
    </Wrapper>
  ))

  const handleDelete = (index: number) => {
    const newFiles = [...acceptedFiles]
    newFiles.splice(index, 1)

    const newStatusFiles = { ...statusFiles }
    delete newStatusFiles[index]

    setAcceptedFiles(newFiles)
    setStatusFiles(newStatusFiles)
  }

  const deleteAll = () => {
    const newFiles: File[] = []
    const newStatusFiles: StatusFile[] = []
    setAcceptedFiles(newFiles)
    setStatusFiles(newStatusFiles)
  }

  const handleUploadInvoices = async () => {
    setSendInvoiceLoading(true)

    const filteredFiles: File[] = []

    acceptedFiles.forEach((file) => {
      if (!filteredFiles.some((file2) => file2.name === file.name)) {
        filteredFiles.push(file)
      }
    })

    setStatusFiles(filteredFiles.map(() => ({ code: 1, message: 'Enviando..' })))
    filteredFiles.forEach((file, index) => {
      uploadInvoice({
        invoice: file,
      })
        .then(() => {
          setStatusFiles((prevStatusFiles) => {
            return {
              ...prevStatusFiles,
              [index]: { code: 2, message: 'Sucesso' },
            }
          })
        })
        .catch((err) => {
          setStatusFiles((prevStatusFiles) => {
            return {
              ...prevStatusFiles,
              [index]: { code: 3, message: err.response?.data?.message || err.message },
            }
          })
        })
    })

    setSendInvoiceLoading(false)
  }

  return (
    <Modal
      title='Upload de faturas'
      openModal={openModal}
      handleOnClose={deleteAll}
      setModalOpen={setOpenModal}
      defaultButtons={false}
    >
      <div>
        <UploadContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>Arraste o arquivo ou clique para fazer o upload</p>
        </UploadContainer>
        <FilesList>{files}</FilesList>
        <ButtonsContainer>
          <CancelButton onClick={deleteAll} style={{ gap: '5px' }}>
            Limpar arquivos
            <DeleteRoundedIcon />
          </CancelButton>
        </ButtonsContainer>
      </div>
      <ButtonsContainer>
        <CancelButton
          disabled={sendInvoiceLoading}
          type='button'
          onClick={() => {
            setOpenModal(false)
            deleteAll()
          }}
        >
          Fechar
        </CancelButton>
        <SendButton disabled={sendInvoiceLoading} onClick={handleUploadInvoices}>
          {sendInvoiceLoading ? <CircularProgress size={20} /> : 'Enviar'}
        </SendButton>
      </ButtonsContainer>
    </Modal>
  )
}
