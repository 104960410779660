import styled from 'styled-components'
import AutorenewIcon from '@mui/icons-material/Autorenew'

type ConfigButtonsProps = {
  gap?: string | number
}

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const PositiveAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
`

export const ErrorAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.suportError};
`

export const DisabledAcess = styled.span`
padding: 4px 8px;
font-size: 12px;
line-height: 14px;
border-radius: 4px;
color: ${({ theme }) => theme.colors.white};
background-color: ${({ theme }) => theme.colors.htmlGrey};
`

export const CancellingAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightOrange};
`

export const AgreementAccess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.orange};
`

export const ButtonsPowerPlantContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const ConfigButtonsColumn = styled.div<ConfigButtonsProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  gap: ${(props) => props.gap ?? 'none'};
`
export const StatusIcon = styled(AutorenewIcon)`
  color: ${({ theme }) => theme.colors.grayDark};
`
