import { PropsWithChildren, createContext, useContext } from "react";
import { TableStateDataContext } from "./useTableState";


const TableStateContext = createContext({} as TableStateDataContext)

export const TableStateProvider = (props: PropsWithChildren<TableStateDataContext>) => {
  const { children, ...tableState } = props
  return (
    <TableStateContext.Provider value={tableState}>
      {children}
    </TableStateContext.Provider>
  )
}

export const useTableStateContext = (): TableStateDataContext => {
  const context = useContext(TableStateContext)
  if(!context) {
    throw new Error('Context must be used within a provider')
  }
  return context
}