import { InputLabel, MenuItem, Select } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { ticketsService } from '../../../../../../services/tickets'
import { Container, Loading } from './styles'
import { ASSIGNEES } from '../../../AssigneeSelect/assignees'

interface Props {
  ticketId: number
  assignedTo?: string
  setAssignedTo: Dispatch<SetStateAction<string>>
}

export default function AssignTicketSelect(props: Props) {
  const { ticketId, assignedTo, setAssignedTo } = props

  const [isLoading, setLoading] = useState<boolean>(false)

  const assignToLabel = assignedTo ? 'Responsável' : 'Atribuir responsável'

  const assignTicket = async (assignTo: string) => {
    try {
      setLoading(true)
      await ticketsService.assignTicket({
        assignTo,
        ticketId,
      })
    } catch (error) {
      console.log(`Error assigning ticket: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container fullWidth>
      {isLoading && <Loading />}
      <InputLabel id='ticket-assign-label'>{assignToLabel}</InputLabel>
      <Select
        labelId='ticket-assign-label'
        id='ticket-assign'
        value={assignedTo}
        label={assignToLabel}
        onChange={(e) => {
          setAssignedTo(e.target.value)
          assignTicket(e.target.value)
        }}
      >
        {ASSIGNEES.map((status) => (
          <MenuItem key={`ticket-assign-${status}`} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </Container>
  )
}
