import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import Chart from 'react-apexcharts'
import './style.css'
import { ChartBody, ChartContainer, HeaderContainer, TitleDiv, TitleText } from './styles'
import Widget from '../../../../components/Widget'
import { PlantInvoicing } from '../../../../interfaces/plants'
import { handleMoneyFormat } from '../../../../utils/formatMoney'
import { useChartInteraction } from '../../hooks/useChartInteraction'
import ChartLegend from '../ChartLegend'

function getDatesSorted(plants: PlantInvoicing[]): string[] {
  const availableDates = plants.reduce((agg = [], item) => {
    for (const date of item.months) {
      const monthYear = `${date.month}/${date.year}`
      const existingEntryIndex = agg.findIndex((entry) => entry === monthYear)
      if (existingEntryIndex === -1) {
        agg.push(monthYear)
      }
    }
    return agg
  }, [] as string[])

  const availableDatesSorted = availableDates.sort((d1, d2) => {
    const d1Date = d1.split('/')
    const [d1Month, d1Year] = [parseInt(d1Date[0], 10), parseInt(d1Date[1]), 10]
    const d2Date = d2.split('/')
    const [d2Month, d2Year] = [parseInt(d2Date[0], 10), parseInt(d2Date[1]), 10]

    if (d1Year === d2Year) {
      return d1Month - d2Month
    }
    return d1Year - d2Year
  })
  return availableDatesSorted
}

interface ChartData {
  data: {
    name: string
    date: string
    data: number[]
  }[]
  categories: string[]
  availableDates: string[]
}

function getChartData(inputData: PlantInvoicing[]): ChartData {
  const formattedData: { name: string; data: number[]; date: string }[] = []

  const availableDates = getDatesSorted(inputData)

  for (const date of availableDates) {
    formattedData.push({
      name: date,
      data: [],
      date,
    })
  }

  for (const item of inputData) {
    for (
      let dateFormattedIndex = 0;
      dateFormattedIndex < formattedData.length;
      dateFormattedIndex++
    ) {
      const dateFormatted = formattedData[dateFormattedIndex]
      const monthFound = item.months.find(
        (date) => `${date.month}/${date.year}` === dateFormatted.name,
      )
      dateFormatted.data.push(monthFound?.invoicing ?? 0)
    }
  }

  return {
    data: formattedData,
    categories: inputData.map((plantInvoice) => plantInvoice.plant.name),
    availableDates,
  }
}

interface Props {
  title: string
  chartData: PlantInvoicing[]
}

export default function PlantsInvoicingChart(props: PropsWithChildren<Props>) {
  const { title, chartData: defaultChartData } = props

  const [chartData, setChartData] = useState<PlantInvoicing[]>([])

  const chartDataFormatted = useMemo(() => getChartData(chartData), [chartData])


  const { selectedSeries, colors, apexSeries, handleSeriesChange } = useChartInteraction({
    chartData: chartDataFormatted.data,
    colors: ['#023b21', '#0f6a3a', '#299c5c', '#5ac784', '#9bfab3'],
  })

  useEffect(() => {
    const firstTen = defaultChartData.slice(0, 10)
    setChartData(firstTen)
  }, [defaultChartData])

  return (
    <Widget style={{ minWidth: 540 }}>
      <ChartBody id='plants-invoicing-chart'>
        <HeaderContainer>
          <TitleDiv>
            <TitleText>{title}</TitleText>
          </TitleDiv>
        </HeaderContainer>
        <ChartContainer>
          <Chart
            options={{
              legend: {
                show: false,
              },
              tooltip: {
                shared: true,
                intersect: false,
                fillSeriesColor: false,
                y: {
                  title: {
                    formatter(seriesName) {
                      return `${seriesName}: `
                    },
                  },
                  formatter: (val) => {
                    return `R$ ${handleMoneyFormat(val)}`
                  },
                },
              },
              plotOptions: {
                bar: {
                  columnWidth: '84',
                  borderRadius: 5,
                },
              },
              dataLabels: {
                enabled: false,
              },
              colors: colors,
              chart: {
                id: 'plants-invoicing-chart',
                type: 'bar',
                stacked: true,
                toolbar: {
                  tools: {
                    download: false,
                  },
                },
              },
              xaxis: {
                categories: chartDataFormatted.categories,
              },
              yaxis: {
                labels: {
                  formatter: (value: number) => {
                    return Number(value?.toFixed(2) || 0).toLocaleString('pt-BR')
                  },
                },
              },
            }}
            series={apexSeries}
            type='bar'
            height={450}
          />

          <ChartLegend
            colors={colors}
            legend={chartDataFormatted.availableDates}
            selectedLegends={selectedSeries}
            onClick={handleSeriesChange}
          />
        </ChartContainer>
      </ChartBody>
    </Widget>
  )
}
