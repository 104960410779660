import { useEffect, useState } from 'react'
import { Modal } from '../..'
import { ButtonsContainer, CancelButton, SendButton } from './styles'
import { Form, Formik } from 'formik'
import { ReplicationProps } from './types'
import FormikSelect from '../../../FormikComponents/FormikSelect'
import { Alert, CircularProgress, Snackbar } from '@mui/material'
import { consumerListGet, replicateQuotas } from '../../../../services/requests/user-requests'
import ModalSuccess from '../../ModalSuccess'
import { monthsList, yearList } from '../../../../utils/selectDates'
import { yupSchema } from './utils'

type dropDownConsumers = { id: number; cliente_nome: string; documento_numero: string }

export default function ModalReplicateQuota({
  openModal,
  setModalOpen,
  powerPlants,
}: ReplicationProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [consumers, setConsumers] = useState<dropDownConsumers[]>([] as dropDownConsumers[])
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    // reqs
  }, [])

  useEffect(() => {
    consumerListGet().then((res) => {
      setConsumers(res?.data)
    })
  }, [])

  function handleCloseModal(value: boolean) {
    setModalSuccess(value)
    setOpacity(1)
    setModalOpen(value)
  }

  async function handleSubmit(values: { powerPlantId: number; year: number; month: number }) {
    try {
      setIsLoading(true)
      const { powerPlantId, year, month } = values
      await replicateQuotas({ powerPlantId, year, month })
      setModalSuccess(true)
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      setErrorMessage(err?.response?.data?.message ?? 'Erro ao cadastrar usina geradora.')
      setOpacity(1)
      setModalOpen(false)
    }
  }

  return (
    <>
      <Modal
        title='Replicar cotas'
        openModal={openModal}
        setModalOpen={setModalOpen}
        defaultButtons={false}
        opacity={opacity}
      >
        {modalSuccess && (
          <ModalSuccess
            openModal={modalSuccess}
            setModalOpen={handleCloseModal as any}
            buttonColor='darkGreen'
            buttonText='Ok, obrigado'
            text={`Cotas replicadas com sucesso!`}
          />
        )}
        <Formik
          initialValues={{
            powerPlantId: 0,
            month: 1,
            year: new Date().getFullYear(),
          }}
          onSubmit={handleSubmit}
          validationSchema={yupSchema}
          validateOnBlur
          validateOnChange={false}
        >
          {({ errors, setFieldValue }) => {
            return (
              <Form>
                <FormikSelect
                  label='Usina'
                  name='powerPlantId'
                  required
                  responseData={powerPlants}
                  setFieldValue={setFieldValue}
                  keyName=''
                  messageError='Selecione uma usina.'
                />
                <FormikSelect
                  label='Ano'
                  name='year'
                  required
                  responseData={yearList()}
                  setFieldValue={setFieldValue}
                  keyName=''
                  error={errors.year}
                  messageError='Selecione um ano.'
                />
                <FormikSelect
                  label='Mês'
                  name='month'
                  required
                  responseData={monthsList}
                  setFieldValue={setFieldValue}
                  keyName=''
                  error={errors.month}
                  messageError='Selecione um mês.'
                />
                <ButtonsContainer>
                  <CancelButton
                    onClick={() => {
                      setModalOpen(false)
                    }}
                  >
                    Cancelar
                  </CancelButton>
                  <SendButton type='submit'>
                    {isLoading ? <CircularProgress size={20} /> : 'Enviar'}
                  </SendButton>
                </ButtonsContainer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </>
  )
}
