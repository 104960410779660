import { ListOrchestratorStatus } from '../../../../../services/orchestrator'
import Widget from '../../../../../components/Widget'
import { Typography } from '@mui/material'
import { Theme } from '../../../../../styles/theme'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

interface Props {
  chartData: ListOrchestratorStatus['result']
}

export default function StatusPieChart(props: Props) {
  const { chartData } = props

  return (
    <Widget
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '350px',
        width: '100%',
        height: 'unset',
        padding: '16px',
      }}
    >
      <Typography textAlign='center' marginBottom={2} variant='h6' fontWeight='bold'>
        Status dos Acessos
      </Typography>

      {chartData.length === 0 ? (
        <Typography style={{ textAlign: 'center', marginTop: '32px' }}>Não há dados</Typography>
      ) : (
        <Pie
          options={{
            plugins: {
              legend: {
                position: 'bottom',
              },
            },
          }}
          data={{
            labels: chartData.map((data) => data.attemptResult), 
            datasets: [
              {
                data: chartData.map((data) => data.totalCount), 
                backgroundColor: chartData.map(
                  (data) =>
                    data.attemptResult === 'SUCCESS' ? Theme.colors.darkishGreen : '#FF6347',
                ),
                borderColor: 'rgba(0, 0, 0, 0.87)',
                borderWidth: 1
              },
            ],
          }}
        />
      )}
    </Widget>
  )
}
