import styled from 'styled-components'
import { Button } from '../../Button'

export const CheckMarkDiv = styled.div`
  background: #ffffff;
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DeleteText = styled.h2`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.darkishGreen};

  padding-top: 32px;
  padding-bottom: 20px;
`

export const ConfirmDeleteText = styled.h2`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.darkishGreen};

  padding-top: 32px;
  padding-bottom: 56px;
`

export const ModalDeleteDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`

export const ConfirmButton = styled(Button)`
  width: 152px;
  height: 40px;

  border-radius: 8px;
`
export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;
  margin-right: 16px;
`
