import { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../../../../../../components/ModalV2'
import { useToast } from '../../../../../../hooks/useToast'
import { ListUserPermissions, permissionsService } from '../../../../../../services/permissions'
import { debounce } from 'lodash'
import PermissionList, { TouchedPermission } from '../../../components/PermissionList'
import Loading from '../../../../../../components/Loading'

interface Props {
  userId: number
  onClose: () => void
}

export default function UserPermissionsModal(props: Props) {
  const { userId, onClose } = props

  const [permissions, setPermissions] = useState<ListUserPermissions['result']>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const fetchUserPermissions = async () => {
    try {
      setLoading(true)
      const permissions = await permissionsService.listUserPermissions({
        userId,
      })
      setPermissions(permissions)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar permissões',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUserPermissions()
  }, [userId])

  const updateUserPermissions = useCallback(async (touchedPermissions: TouchedPermission[]) => {
    try {
      await permissionsService.updateUserPermissions({
        userId,
        permissions: touchedPermissions.map((touchedPermission) => ({
          id: touchedPermission.permissionId,
          remove: !touchedPermission.hasPermission,
        })),
      })
      toast({
        message: 'Permissões atualizadas com sucesso',
        type: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao atualizar permissões',
        type: 'error',
      })
    }
  }, [])

  const debounceOnChange = useMemo(
    () =>
      debounce(
        (touchedPermission: TouchedPermission[]) => updateUserPermissions(touchedPermission),
        1000,
      ),
    [],
  )

  return (
    <Modal.Provider
      style={{ overflowY: 'auto', width: '100%', height: '100%' }}
      isOpen
      maxWidth={650}
      onClose={onClose}
    >
      <Modal.Title>
        Permissões de Acesso
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        {isLoading && <Loading />}
        {!isLoading && permissions.length > 0 && (
          <PermissionList permissions={permissions} onChange={debounceOnChange} />
        )}
      </Modal.Body>
    </Modal.Provider>
  )
}
