import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const ToggleSidebarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.8125rem;
  height: 2.6875rem;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.green};

  color: ${({ theme }) => theme.colors.white};
  border: 0;
`

export const ToggleSidebarButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 3rem;
  margin-bottom: 3rem;

  // ajusta o botão em alturas menores que 719px
  @media only screen and (max-height: 719px) {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
`

// =====================================================
// =====================================================
// =====================================================

const transition = '500ms'

interface IProps {
  isOpen?: boolean
  isactive?: boolean
}

interface ILogoProps extends IProps {
  width?: string
}

export const Wrapper = styled.aside<IProps>`
  height: 100%;
  width: ${({ isOpen }) => (isOpen ? '222px' : '80px')};
  background: ${({ theme }) => theme.colors.darkGreen};
  border-radius: 0px 2rem 2rem 0px;
  overflow: hidden;
  transition: ${transition};
`

export const Container = styled.div`
  height: 100%;
  width: 222px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const LogoContainer = styled.header<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 170px;
  width: ${({ isOpen }) => (isOpen ? '100%' : '50px')};
  transition: ${transition};
`

export const Logo = styled.img<ILogoProps>`
  height: auto;
  width: ${({ isOpen, width }) => (isOpen ? width ?? '90%' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${transition};
`

export const Nav = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.brightGreen};
    border-radius: 10px;
  }
`

export const Link = styled(NavLink)<IProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 4px 24px 1fr;
  grid-template-rows: 44px;
  gap: 15px;
  text-decoration: none;
  color: ${({ isactive, theme }) =>
    isactive ? theme.colors.brightGreen : theme.colors.sideBarText};
  border-radius: 0 5px 5px 0;
  transition: 300ms;

  ::before {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 10px 0 0 10px;
    background-color: ${({ isactive, theme }) =>
      isactive ? theme.colors.brightGreen : 'transparent'};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.darkishGreen};
  }
`

interface ILinkDropDownListProps extends Pick<IProps, 'isOpen'> {
  linksAmount: number
}

export const LinkIconContainer = styled.div<IProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & path {
    height: 24px;
    width: auto;
    fill: ${({ isactive, theme }) =>
      isactive ? theme.colors.brightGreen : theme.colors.sideBarText};
  }
`

export const LinkText = styled.span<IProps>`
  max-width: 100%;
  align-self: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.02rem;

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${transition};
`

export const LinkDropDownListButton = styled.button<IProps>`
  width: 100%;
  display: grid;
  place-items: center stretch;
  grid-template-columns: 4px 24px 1fr;
  grid-template-rows: 44px;
  gap: 15px;
  color: ${({ isactive, theme }) =>
    isactive ? theme.colors.brightGreen : theme.colors.sideBarText};
  border-radius: 0 5px 5px 0;
  background-color: transparent;
  text-align: left;
  transition: 300ms;

  ::before {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 10px 0 0 10px;
    background-color: ${({ isactive, theme }) =>
      isactive ? theme.colors.brightGreen : 'transparent'};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const LinkDropDownList = styled.div<ILinkDropDownListProps>`
  width: 100%;
  height: ${({ isOpen, linksAmount }) => (isOpen ? `${linksAmount * 24}px` : 0)};
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  transition: ${transition};
`

export const LinkDropDownItem = styled(NavLink)<IProps>`
  width: 100%;
  padding: 5px 0 5px 19px;
  font-size: 14px;
  text-decoration: none;
  color: ${({ isactive, theme }) =>
    isactive ? theme.colors.brightGreen : theme.colors.sideBarText};
  border-radius: 0 5px 5px 0;

  max-width: 100%;
  align-self: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: 300ms;

  :hover {
    background-color: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const Footer = styled.aside<IProps>`
  width: ${({ isOpen }) => (isOpen ? '100%' : '50px')};
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  transition: ${transition};
`

export const PartnersContainer = styled.aside<IProps>`
  width: 100%;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  transition: ${transition};
`

export const TextContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
`
