export const phoneReg = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const cellphoneReg = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export interface PowerPlantProps {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editValues?: HandleRegisterUpdateFormData
  postAction?: () => void
  paymentMethods?: { value: number, label: string }[]
}

export interface HandleRegisterFormData {
  name: string
  email: string
  emails_pagamentos: Array<{ id: number; email: string }>
  cpf_cnpj: string
  fone: string
  has_aggregate_payments?: boolean
  aggr_pay_metodo_id?: number | null
}

export interface HandleRegisterUpdateFormData {
  id: number
  cliente_nome: string
  email: string
  emails_pagamentos: Array<{ id: number; email: string }> | [] | null
  documento_numero: string
  fone: string
  has_aggregate_payments?: boolean
  aggr_pay_metodo_id?: number | null
}

export const formatNumber = (value: string) => {
  let formattedNumber = ''
  if (value.length === 11) {
    formattedNumber = `(${value.substring(0, 2)}) ${value.substring(2, 3)} ${value.substring(
      3,
      7,
    )}-${value.substring(7, 11)}`
  }
  if (value.length === 10) {
    formattedNumber = `(${value.substring(0, 2)}) ${value.substring(2, 6)}-${value.substring(
      6,
      10,
    )}`
  }
  return formattedNumber
}
