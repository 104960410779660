import { SettingsOutlined } from '@mui/icons-material'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import { useTableStateContext } from '../../../../hooks/useTableStateContext'
import { useState } from 'react'
import { CheckboxType } from '../../../../interfaces/checkbox'

export default function TableConfig() {
  const { tableColumns, tableState, setItemsPerPage, setTableColumns } = useTableStateContext()

  const [localItemsPerPage, setLocalItemsPerPage] = useState<number>(tableState.itemsPerPage)

  const submitAction = (checkboxes: CheckboxType[]) => {
    setItemsPerPage(localItemsPerPage)
    setTableColumns(
      tableColumns.map((col) => ({
        ...col,
        hide: col.hideable
          ? !checkboxes.find((box) => box.label === col.headerName)?.checked
          : false,
      })),
    )
  }

  return (
    <FloatingCheckboxes
      pagination={{
        selected: localItemsPerPage,
        setSelected: setLocalItemsPerPage,
        options: [15, 20, 30, 50, 100],
      }}
      isFilterActive={false}
      title='Configurar Tabela'
      options={tableColumns
        .filter((col) => col.hideable)
        .map((col) => ({
          label: col.headerName || '',
          checked: !col.hide,
          id: Math.random(),
        }))}
      customActionComponent={<ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>}
      submitBtnText='Salvar'
      submitAction={submitAction}
    />
  )
}
