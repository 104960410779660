import { useRef } from 'react'
import { SearchInput } from '../../../../../components/SearchInput'

interface Props {
  value: string
  onChange: (value: string) => void
  onSearch: (value: string) => void
}

export default function ConferenceSearchInput(props: Props) {
  const { value, onChange, onSearch } = props

  const searchDebounceRef = useRef<NodeJS.Timeout>()

  const searchDebounce = () => {
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current)
    }
    const delayDebounce = setTimeout(() => {
      if (value.length >= 3 || value.length === 0) {
        onSearch(value)
      }
    }, 1000)
    searchDebounceRef.current = delayDebounce
  }

  return (
    <SearchInput
      value={value}
      setValue={(searchParams) => {
        onChange(searchParams)

        if (searchParams) {
          searchDebounce()
        }
      }}
      style={{ width: 180 }}
    />
  )
}
