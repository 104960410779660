import { Pagination } from './conference'

export enum TicketStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export interface TicketFile {
  id: string
  originalFileName: string
}

export interface TicketReply {
  id: number
  message: string
  createdAt: string
  replier: {
    id: number
    tipo: string
    cliente_nome: string
  }
}

export interface Ticket {
  id: number
  client_id: number
  title: string
  category: string
  status: TicketStatus
  assignedTo?: string
  createdAt: string
  updatedAt: string
  files: TicketFile[]
  replies: TicketReply[]
}

export type TicketPagination = Pagination<{
  id: number
  category: string
  closedAt: Date
  createdAt: Date
  status: string
  title: string
  assigned_to: string
  client: {
    id: number
    cliente_nome: string
  }
}>

export interface TicketWidgets {
  ticketsCompletedCount: number
  ticketsInProgressCount: number
  ticketsOpenCount: number
}
