import { darken } from 'polished'
import styled from 'styled-components'

export const ActionButton = styled.button`
  border: 0;
  background: transparent;
`
interface IDefaultActionContent {
  isModalOpened: boolean
  backgroundColor?: string
  textColor?: string
  checkbox?: boolean
}

export const DefaultActionContent = styled.div<IDefaultActionContent>`
  background: ${({ isModalOpened, backgroundColor }) =>
    isModalOpened ? '#F1F1F1' : backgroundColor ? backgroundColor : '#FFFFFF'};
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px;
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.colors.htmlGrey)};
  font-size: 12px;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  filter: drop-shadow(-1px -1px 3px #ffffff) drop-shadow(1.5px 1.5px 3px rgba(174, 174, 192, 0.4));

  > img {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${(props) =>
      props.checkbox
        ? darken(0.1, props.theme.colors.white)
        : darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`

export const FloatingModal = styled.div<{ isModalOpened: boolean; sideToRender: 'left' | 'right' }>`
  display: ${({ isModalOpened }) => (isModalOpened ? 'unset' : 'none')};
  position: absolute;
  top: 35px;
  ${({ sideToRender }) => (sideToRender === 'right' ? `left: 0;` : `right: 0;`)}
  padding: 16px;
  background: #ffffff;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  z-index: 100;
`

export const Content = styled.div<{ height: number }>`
  max-height: ${({ height }) => height}px;
`

export const SubmitButton = styled.button`
  padding: 10px;
  min-width: 50%;
  margin: 16px auto 0;
  background-color: ${({ theme }) => theme.colors.darkishGreen};
  color: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`
