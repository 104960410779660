import styled from '@emotion/styled'
import { Theme } from '../../../../../../styles/theme'
import { CircularProgress, TextareaAutosize } from '@mui/material'

export const Form = styled.form`
  display: flex;
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
`

export const Input = styled(TextareaAutosize)`
  background-color: transparent;
  outline: none;
  border: none;
  padding: 16px;
  width: 100%;
  height: 120px;
  resize: none;
  font-size: 16px;
  `

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 51px;
  margin-top: auto;
  justify-content: center;
  color: ${Theme.colors.white};
  padding: 8px 16px;
  background-color: ${Theme.colors.darkishGreen};
`

export const Loading = styled(CircularProgress)`
  width: 24px !important;
  height: 24px !important;
`