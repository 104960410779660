import * as Yup from 'yup'
import { GetResponseData } from './types'

export const stringToNumber = (value: any) => +value.toString().replace(',', '.')

export const yupSchema = Yup.object({
  consumerId: Yup.number().required('Selecione um consumidor.'),
  ucId: Yup.string().required('Selecione uma Unidade Consumidora.'),
  powerPlantId: Yup.string().required('Selecione uma Usina Geradora.'),
  year: Yup.string().required('Selecione um ano.'),
  month: Yup.string().required('Selecione um mês.'),
  quota: Yup.number()
    .transform((_, value) => stringToNumber(value))
    .typeError('Cota é um número')
    .required('Cota é um campo obrigatório')
    .max(100, 'Máximo de 100')
    .min(0, 'Mínimo de 0'),
})

export const initialValues = {
  nome: '',
  fonte: '',
  potencia_instalada: 0,
  distribuidora_id: 0,
  codigo: '',
  senha_dist: '',
  cep: '',
  modalidade: '',
  titular_da_usina: '',
  UCOwner: '',
  planta_ids: 0,
  grupo: 'B',
  login: '',
  inversor_id: 0,
  documento_numero: '',
}

export async function getResponseData({ responseGet, responseData }: GetResponseData) {
  try {
    const response = await responseGet()

    const payload = response.data.map((item: { id: number; nome: string }) => {
      return {
        label: item.nome,
        value: item.id,
      }
    })

    responseData(payload)
  } catch (error: any) {
    console.log(error.message)
  }
}
