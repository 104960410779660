import { TextField } from '@mui/material'
import styled from 'styled-components'

export const CustomInput = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
    padding: '0 65px 0 0 !important',
  },
  '& .MuiAutocomplete-input': {
    padding: '0 !important',
  },
  '& .MuiOutlinedInput-root': {
    '& input': {
      height: '100%',
      background: 'transparent !important',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      WebkitAppearance: 'none',
      margin: 0,
      padding: 0,
      width: '100%',
      '&::placeholder': {
        color: '#adadad',
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
})

export const InputDiv = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 14px;
  height: 45px;
  background: #f1f1f1;
  color: black;
  
  border: 2px solid ${({ hasError }) => !hasError ? '#f1f1f1' : '#d32f2f' };
  
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.darkishGreen};
  }
`

/* color: ${({ theme }) => theme.colors.quartzGrey : theme.colors.white)}; */
export const Label = styled.label<{ required?: boolean }>`
  color: ${({ theme }) => theme.colors.quartzGrey};
  margin-bottom: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  ${({ required, theme }) =>
    required &&
    `::after {
    content: '*';
    color: ${theme.colors.orange};`}
`
