import { PeopleOutlineOutlined, PowerOutlined, StorefrontOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { myUnitsGet } from '../../../services/requests/user-requests'
import {
  ContainerBottom,
  PowerPlantContainer,
  WidgetPowerPlant,
  WidgetPowerPlantHeader,
  WidgetsContainer,
  PercentageText,
  InRelationToText,
} from './styles'
import { WidgetStateType } from './types'
import { ReactComponent as Increase } from '../../../assets/increase.svg'
import { ReactComponent as Decrease } from '../../../assets//decrease.svg'
import { DateFilter } from '../../../components/Filter/DateFilter'
import Loading from '../../../components/Loading'

export function MyUnitsTable() {
  const [widgets, setWidgets] = useState<WidgetStateType[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  useEffect(() => {
    setIsLoading(true)
    myUnitsGet(dateSelected)
      .then((response) => {
        const stateWidgets: WidgetStateType[] = [
          {
            title: 'Usinas',
            total_count: response.data.total_usinas.valor,
            total_variable: response.data.total_usinas.percentual,
            icon: <PowerOutlined />,
            path: '/my-units/power-plant',
            month: dateSelected.month,
            year: dateSelected.year,
          },
          {
            title: response.data.total_consumidores.valor === 1 ? 'Consumidor' : 'Consumidores',
            total_count: response.data.total_consumidores.valor,
            total_variable: response.data.total_consumidores.percentual,
            icon: <PeopleOutlineOutlined />,
            path: '/my-units/consumer',
            month: dateSelected.month,
            year: dateSelected.year,
          },
          {
            title: 'Unidades Consumidoras',
            total_count: response.data.total_ucs.valor,
            total_variable: response.data.total_ucs.percentual,
            icon: <StorefrontOutlined />,
            path: '/my-units/consumer-units',
            month: dateSelected.month,
            year: dateSelected.year,
          },
        ]
        setWidgets(stateWidgets)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [dateSelected])

  function handleChangeTable(
    title: 'Usinas' | 'Consumidor' | 'Consumidores' | 'Unidades Consumidoras',
  ) {
    switch (title) {
      case 'Usinas': {
        navigate('/my-units/power-plant')
        break
      }
      case 'Consumidores':
      case 'Consumidor': {
        navigate('/my-units/consumer')
        break
      }
      case 'Unidades Consumidoras': {
        navigate('/my-units/consumer-units')
        break
      }
    }
  }

  return (
    <>
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      <PowerPlantContainer>
        <WidgetsContainer>
          {widgets.map((widget) => (
            <WidgetPowerPlant
              key={widget.title}
              isSelectedButton={widget.path === pathname}
              onClick={() => handleChangeTable(widget.title)}
            >
              <WidgetPowerPlantHeader isSelectedButton={widget.path === pathname}>
                <div className='icon-container-widget-plant'>{widget.icon}</div>
                <p>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <strong>{widget.total_count}</strong> <span>{widget.title}</span>
                    </>
                  )}
                </p>
              </WidgetPowerPlantHeader>

              <ContainerBottom isSelectedButton={widget.path === pathname}>
                {isLoading ? (
                  <PercentageText isSelectedButton={widget.path === pathname}>
                    Buscando dados...
                  </PercentageText>
                ) : (
                  <>
                    {widget.total_variable > 0 ? (
                      <Increase width={28} height={14.5} />
                    ) : (
                      <Decrease width={28} height={14.5} />
                    )}

                    <PercentageText isSelectedButton={widget.path === pathname}>
                      {widget.total_variable > 0
                        ? `+${widget.total_variable ?? 0}%`
                        : `${widget.total_variable ?? 0}%`}
                    </PercentageText>
                    <InRelationToText
                      isSelectedButton={widget.path === pathname}
                      title={'em relação ao mês anterior'}
                    >
                      em relação ao mês anterior
                    </InRelationToText>
                  </>
                )}
              </ContainerBottom>
            </WidgetPowerPlant>
          ))}
        </WidgetsContainer>
        <Outlet
          context={{
            mes_ref: `${dateSelected.year}-${
              dateSelected.month >= 10 ? dateSelected.month : `0${dateSelected.month}`
            }-01`,
          }}
        />
      </PowerPlantContainer>
    </>
  )
}
