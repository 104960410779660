import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { TicketPagination } from '../../../interfaces/tickets'
import { ListTickets, ticketsService } from '../../../services/tickets'

interface TicketsContextData {
  tickets: TicketPagination
  isLoading: boolean
  fetchTickets: (params: ListTickets['params']) => Promise<void>
}

const TicketsContext = createContext({} as TicketsContextData)

export function TicketsProvider(props: PropsWithChildren) {
  const { children } = props

  const [tickets, setTickets] = useState<TicketPagination>({
    pageInfo: {
      currentPage: 1,
      limit: 2,
      totalCount: 0,
      totalPages: 1,
    },
    data: [],
  })
  const [isLoading, setLoading] = useState<boolean>(false)

  const fetchTickets = async (params: ListTickets['params']) => {
    try {
      setLoading(true)
      const tickets = await ticketsService.listTickets(params)
      setTickets(tickets)
    } catch (error) {
      console.log(`Erro ao listar tickets: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <TicketsContext.Provider value={{ tickets, isLoading, fetchTickets }}>
      {children}
    </TicketsContext.Provider>
  )
}

export function useTickets() {
  const context = useContext(TicketsContext)
  if (!context) {
    throw new Error('Provider not found')
  }
  return context
}
