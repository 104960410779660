import { TicketsWrapper } from './styles'
import TicketsWidgets from './components/TicketsWidgets'
import { TicketsProvider } from './hooks/useTickets'
import TicketsTable from './components/TicketsTable'

export function Tickets() {
  return (
    <TicketsWrapper>
      <TicketsWidgets />
      <TicketsProvider>
        <TicketsTable />
      </TicketsProvider>
    </TicketsWrapper>
  )
}
