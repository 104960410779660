import { TypographyProps } from '@mui/material'
import { PropsWithChildren, useContext } from 'react'
import { Title } from './styles'
import { GlobalContext } from '../../../context/global/global'

type Props = TypographyProps

export default function ModalTitle(props: PropsWithChildren<Props>) {
  const { children, ...rest } = props

  const { Theme } = useContext(GlobalContext)

  return (
    <Title color={Theme.colors.darkishGreen} variant='h5' {...rest}>
      {children}
    </Title>
  )
}
