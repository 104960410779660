import { ListItem, ListItemText, Typography } from '@mui/material'
import Modal from '../../../../../components/ModalV2'
import { format, parseISO, startOfMonth } from 'date-fns'
import { useToast } from '../../../../../hooks/useToast'
import { ListOrchestratorUcLogs, orchestratorService } from '../../../../../services/orchestrator'
import { useEffect, useState } from 'react'
import Loading from '../../../../../components/Loading'

interface Props {
  ucId: number
  referenceMonth: Date
  onClose: () => void
}

export default function UcScrapingLogsModal(props: Props) {
  const { ucId, referenceMonth, onClose } = props

  const [isLoading, setLoading] = useState<boolean>(false)
  const [scrapingLogs, setScrapingLogs] = useState<ListOrchestratorUcLogs['result']>([])

  const { toast } = useToast()

  const fetchUcLogs = async () => {
    try {
      setLoading(true)
      const scrapingLogsResponse = await orchestratorService.listUcLogs({
        ucId,
        referenceMonth: format(startOfMonth(referenceMonth), 'yyyy-MM-dd'),
      })
      setLoading(false)
      setScrapingLogs(scrapingLogsResponse)
    } catch (error) {
      console.log(error)
      toast({
        type: 'error',
        message: 'Erro ao listar logs da UC',
      })
    }
  }

  useEffect(() => {
    fetchUcLogs()
  }, [ucId])

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '750px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Logs de Scraping
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body style={{ overflowY: 'auto' }}>
        {isLoading && <Loading />}

        {!isLoading &&
          scrapingLogs.map((attempt) => (
            <ListItem key={`attempt-${attempt.createdAt}`} disablePadding divider>
              <ListItemText
                primary={`Scrape realizado em ${format(parseISO(attempt.createdAt), 'dd/MM/yyyy HH:mm')}`}
                primaryTypographyProps={{
                  fontWeight: 'bold',
                }}
                secondary={
                  <>
                    <Typography
                      variant='body2'
                      fontWeight='bold'
                      component='span'
                      color={attempt.attemptResult !== 'SUCCESS' ? 'error' : 'primary'}
                    >
                      {attempt.attemptResult}:
                    </Typography>{' '}
                    {attempt.attemptMessage || 'Nenhuma mensagem adicional.'}
                  </>
                }
              />
            </ListItem>
          ))}
      </Modal.Body>
    </Modal.Provider>
  )
}
