import { useEffect, useState } from 'react'
import { FloatingSelects } from '../../../../components/FloatingSelects'

interface Props {
  referenceMonth: Date
  selectedDay?: number
  onChange: (selectedDay?: number) => void
}

export default function ExpiringDayFilter(props: Props) {
  const { selectedDay: defaultSelectedDay, referenceMonth, onChange } = props

  const daysFilter = [...new Array(referenceMonth.getDate())].map((_, index) => `${index + 1}`)
  const [selectedDay, setSelectedDay] = useState<number | undefined>(defaultSelectedDay)

  useEffect(() => {
    setSelectedDay(defaultSelectedDay)
  }, [defaultSelectedDay])

  return (
    <FloatingSelects
      isFilterActive={daysFilter
        .filter((day) => day !== 'Nenhum')
        .some((day) => day === String(day))}
      label='Dia de Vencimento'
      submitAction={() => onChange(selectedDay)}
      selects={[
        {
          value: selectedDay ? String(selectedDay) : '0',
          setValue: (value) => setSelectedDay(Number(value)),
          datas: [
            {
              label: 'Nenhum',
              value: '0',
            },
            ...daysFilter.map((day) => ({
              label: day,
              value: day,
            })),
          ],
        },
      ]}
    />
  )
}
