export const trauncateFractionAndFormat = (parts: any, digits: any) => {
  return parts
    .map(({ type, value }: { type: any; value: any }) => {
      if (type !== 'fraction' || !value || value.length < digits) {
        return value
      }

      let retVal = ''
      for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
        if (value[idx] !== '0') {
          counter++
        }
        retVal += value[idx]
      }
      return retVal
    })
    .reduce((string: any, part: any) => string + part)
}
