import React, { useState } from 'react'
import { Icon, IconHiden, InputDiv, InputField, Label, SearchIcon } from './styles'
import Search from '../../assets/icons/searchIcon.svg'
import Tooltip from '../Tooltip'

interface Props {
  value?: string
  label?: string
  type?: 'email' | 'password' | 'search' | 'url'
  mode?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  required?: boolean
  halfSize?: boolean
  tooltip?: boolean
  sizeForm?: boolean
  heightInput?: string | number
}

export const Input: React.FC<Props> = ({
  value,
  label,
  type,
  onChange,
  placeholder,
  mode,
  required,
  halfSize,
  tooltip,
  sizeForm,
  heightInput,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(true)

  return (
    <div>
      {!!label && (
        <div style={{ display: 'flex', gap: 8 }}>
          <Label mode={+Boolean(mode)} required={required}>
            {label}
          </Label>
          {tooltip && <Tooltip title='Random message!' />}
        </div>
      )}
      <InputDiv mode={+Boolean(mode)}>
        <InputField
          halfSize={halfSize}
          mode={+Boolean(mode)}
          type={isPasswordVisible ? type : ''}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          sizeForm={sizeForm}
          heightInput={heightInput}
        />
        {type === 'password' && (
          <>
            {isPasswordVisible ? (
              <Icon
                fontSize='small'
                style={{ color: '#808080' }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            ) : (
              <IconHiden
                fontSize='small'
                style={{ color: '#808080' }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            )}
          </>
        )}
        {type === 'search' && <SearchIcon src={`${Search}`} alt='Search Icon' />}
      </InputDiv>
    </div>
  )
}
