import { useState, useContext, createContext, PropsWithChildren, useEffect, useCallback } from 'react'
import { PermissionType } from '../interfaces/permissions'
import { ListPermissionsFromUser, permissionsService } from '../services/permissions'
import { GlobalContext } from '../context/global/global'

interface PermissionsContextData {
  checkPermission: (permissionType: PermissionType) => boolean
  permissions: ListPermissionsFromUser['result']
}

const PermissionsContext = createContext({} as PermissionsContextData)

export function PermissionsProvider({ children }: PropsWithChildren) {
  const [permissions, setPermissions] = useState<ListPermissionsFromUser['result']>([])
  const { state } = useContext(GlobalContext)

  const listPermissions = async () => {
    if (!state.auth) {
      return
    }

    try {
      const permissionsResponse = await permissionsService.listPermissionsFromUser(state.auth.id)

      setPermissions(permissionsResponse)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!state?.auth) return

    listPermissions()
  }, [state.auth])

  const checkPermission = useCallback(
    (permissionType: PermissionType) => {
      return permissions.includes(permissionType)
    },
    [permissions]
  )

  return (
    <PermissionsContext.Provider value={{ checkPermission, permissions }}>
      {children}
    </PermissionsContext.Provider>
  )
}

export const usePermissions = () => {
  const context = useContext(PermissionsContext)

  if (!context) {
    throw new Error('Provider not found')
  }

  return context
}
