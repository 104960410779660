import { Form, FormikProvider, useFormik } from 'formik'
import Modal from '../../../../../components/ModalV2'
import { ChangeEvent, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useToast } from '../../../../../hooks/useToast'
import { StickyFooter } from '../../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumerUnit/styles'
import { Button } from '../../../../../components/Button'
import { InputV2 } from '../../../../../components/InputV2'
import PlatformSelect from '../PlatformSelect'
import { PaymentMethodPlatform } from '../../../../../interfaces/payment-method'
import { Stack } from '@mui/material'
import ManagerSelect from '../../../../../components/ManagerSelect'
import { Search } from '@mui/icons-material'
import { paymentMethodsService } from '../../../../../services/payment-method'
import Loading from '../../../../../components/Loading'
import PaymentDocumentTypeSelect from '../PaymentDocumentTypeSelect'
import PaymentModalitySelect from '../PaymentModalitySelect'
import { FormikCheckbox } from '../../../../../components/FormikComponents/FormikCheckbox'
import { removeEmptyFields } from '../../../../../utils/obj-utils'

interface FormData {
  id?: number
  bb_nome_consorcio: string
  documento_identificador: string
  cliente_gestor_id?: number
  plataforma?: PaymentMethodPlatform
  asaas_api_key?: string
  bb_basic_token?: string
  gerar_pix?: boolean
  bb_boleto_tipo?: number
  bb_codigo_modalidade?: number
  bb_numero_carteira?: number
  bb_numero_variacao_carteira?: number
  conta_corrente?: number
  certificado_senha?: string
  numero_contrato?: number
  certificado?: File
  bb_app_key?: string
  bb_numero_convenio?: number
  porcentagem_juros?: number | null
  porcentagem_multa?: number | null
}

const ValidationSchema: Yup.SchemaOf<Omit<FormData, 'id'>> = Yup.object().shape({
  bb_nome_consorcio: Yup.string().required('Campo obrigatório'),
  documento_identificador: Yup.string().required('Campo obrigatório'),
  cliente_gestor_id: Yup.number().required('Campo obrigatório'),
  porcentagem_juros: Yup.number().nullable().typeError('Valor inválido').min(0).max(100),
  porcentagem_multa: Yup.number().nullable().typeError('Valor inválido').min(0).max(100),
  asaas_api_key: Yup.string().when('plataforma', {
    is: PaymentMethodPlatform.ASAAS,
    then: Yup.string().when('id', {
      is: (id: number | undefined) => !id,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
  }),
  bb_basic_token: Yup.string().when('plataforma', {
    is: PaymentMethodPlatform.BB,
    then: Yup.string().when('id', {
      is: (id: number | undefined) => !id,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
  }),
  gerar_pix: Yup.boolean(),
  plataforma: Yup.mixed()
    .oneOf(Object.values(PaymentMethodPlatform), 'Campo obrigatório')
    .required('Campo obrigatório'),
  bb_boleto_tipo: Yup.number().when('plataforma', {
    is: PaymentMethodPlatform.SICOOB,
    then: Yup.number().required('Campo obrigatório'),
    otherwise: Yup.number().nullable(),
  }),
  bb_codigo_modalidade: Yup.number().when('plataforma', {
    is: (value: string) =>
      value === PaymentMethodPlatform.SICOOB || value === PaymentMethodPlatform.BB,
    then: Yup.number().required('Campo obrigatório'),
    otherwise: Yup.number().nullable(),
  }),
  bb_numero_carteira: Yup.number().when('plataforma', {
    is: (value: string) =>
      value === PaymentMethodPlatform.SICOOB || value === PaymentMethodPlatform.BB,
    then: Yup.number().required('Campo obrigatório'),
    otherwise: Yup.number().nullable(),
  }),
  bb_numero_variacao_carteira: Yup.number().when('plataforma', {
    is: (value: string) =>
      value === PaymentMethodPlatform.SICOOB || value === PaymentMethodPlatform.BB,
    then: Yup.number().required('Campo obrigatório'),
    otherwise: Yup.number().nullable(),
  }),
  conta_corrente: Yup.number().when('plataforma', {
    is: PaymentMethodPlatform.SICOOB,
    then: Yup.number().required('Campo obrigatório'),
    otherwise: Yup.number().nullable(),
  }),
  certificado_senha: Yup.string().when('plataforma', {
    is: PaymentMethodPlatform.SICOOB,
    then: Yup.string().when('id', {
      is: (id: number | undefined) => !id,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    otherwise: Yup.string().nullable(),
  }),
  numero_contrato: Yup.number().when('plataforma', {
    is: PaymentMethodPlatform.SICOOB,
    then: Yup.number().required('Campo obrigatório'),
    otherwise: Yup.number().nullable(),
  }),
  certificado: Yup.mixed().when('plataforma', {
    is: PaymentMethodPlatform.SICOOB,
    then: Yup.mixed().when('id', {
      is: (id: number | undefined) => !id,
      then: Yup.mixed().required('Campo obrigatório'),
      otherwise: Yup.mixed().nullable(),
    }),
    otherwise: Yup.mixed().nullable(),
  }),
  bb_app_key: Yup.string().when('plataforma', {
    is: PaymentMethodPlatform.BB,
    then: Yup.string().when('id', {
      is: (id: number | undefined) => !id,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    otherwise: Yup.string().nullable(),
  }),
  bb_numero_convenio: Yup.number().when('plataforma', {
    is: PaymentMethodPlatform.BB,
    then: Yup.number().required('Campo obrigatório'),
    otherwise: Yup.number().nullable(),
  }),
})

interface Props {
  paymentMethodId?: number
  onSuccess?: () => void
  onClose: () => void
}

export default function PaymentMethodModal(props: Props) {
  const { paymentMethodId, onSuccess, onClose } = props

  const { toast } = useToast()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const handleSubmit = async (data: FormData) => {
    try {
      setSubmitting(true)
      if (!paymentMethodId) {
        await paymentMethodsService.createPaymentMethod(removeEmptyFields(data as any) as any)
      } else {
        await paymentMethodsService.updatePaymentMethod({
          paymentMethodId,
          ...(removeEmptyFields(data as any) as any),
        })
      }
      toast({
        message: `Método de pagamento ${!paymentMethodId ? 'criado' : 'atualizado'} com sucesso`,
        type: 'success',
      })
      if (onSuccess) {
        onSuccess()
      }
      onClose()
    } catch (error) {
      console.log(error)
      toast({
        message: `Erro ao ${!paymentMethodId ? 'criar' : 'atualizar'} método de pagamento`,
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const fetchPaymentMethod = async () => {
    if (!paymentMethodId) {
      return
    }
    try {
      setLoading(true)
      const paymentMethodResult = await paymentMethodsService.findPaymentMethod({
        id: paymentMethodId,
      })
      formik.setValues(paymentMethodResult)
      setLoading(false)
    } catch (error) {
      onClose()
      toast({
        message: 'Erro ao buscar método de pagamento',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchPaymentMethod()
  }, [paymentMethodId])

  const formik = useFormik<FormData>({
    initialValues: {
      bb_nome_consorcio: '',
      documento_identificador: '',
      gerar_pix: false,
      porcentagem_juros: 0,
      porcentagem_multa: 0,
    },
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: true,
    validate: async (values) => {
      try {
        await ValidationSchema.validate(values, { abortEarly: false })
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const validationErrors = error.inner.reduce((acc: any, err) => {
            if (err.path) {
              acc[err.path] = err.message
            }
            return acc
          }, {} as any)
          console.log('Validation Errors:', validationErrors)
          return validationErrors
        }
      }
    },
  })

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        maxHeight: '750px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Método de Pagamento
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      {isLoading ? (
        <Loading />
      ) : (
        <FormikProvider value={formik}>
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              height: '100%',
            }}
          >
            <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <ManagerSelect
                id='cliente_gestor_id'
                onChange={(_, val) => formik.setFieldValue('cliente_gestor_id', val ?? undefined)}
                sx={{ marginBottom: 0 }}
                value={formik.values.cliente_gestor_id}
                renderInput={(params) => (
                  <InputV2
                    {...params}
                    required
                    rightIcon={<Search />}
                    id='cliente_gestor_id'
                    label='Gestor'
                  />
                )}
              />
              <InputV2 id='bb_nome_consorcio' label='Nome do Consórcio' required />
              <InputV2
                id='documento_identificador'
                label='Documento'
                mask={[
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                ]}
                required
              />
              <Stack direction='row' gap={2}>
                <InputV2 id='porcentagem_juros' label='Porcentagem Juros' />
                <InputV2 id='porcentagem_multa' label='Porcentagem Multa' />
              </Stack>
              <PlatformSelect
                value={formik.values.plataforma}
                error={!!formik.errors.plataforma}
                onChange={(value) => formik.setFieldValue('plataforma', value)}
              />
              {formik.values.plataforma && (
                <>
                  <FormikCheckbox
                    defaultChecked={!!formik.values.gerar_pix}
                    name='gerar_pix'
                    label='Gerar Pix'
                    mode
                  />

                  <Stack>
                    {formik.values.plataforma === PaymentMethodPlatform.ASAAS && (
                      <InputV2 id='asaas_api_key' label='Chave API' required={!paymentMethodId} />
                    )}
                    {formik.values.plataforma === PaymentMethodPlatform.SICOOB && (
                      <Stack gap={2}>
                        <PaymentDocumentTypeSelect
                          value={formik.values.bb_boleto_tipo}
                          error={!!formik.errors.bb_boleto_tipo}
                          onChange={(value) => formik.setFieldValue('bb_boleto_tipo', value)}
                        />
                        <PaymentModalitySelect
                          value={formik.values.bb_codigo_modalidade}
                          error={!!formik.errors.bb_codigo_modalidade}
                          onChange={(value) => formik.setFieldValue('bb_codigo_modalidade', value)}
                        />
                        <Stack direction='row' gap={2}>
                          <InputV2 id='bb_numero_carteira' label='Número da Carteira' required />
                          <InputV2
                            id='bb_numero_variacao_carteira'
                            label='Número Variação da Carteira'
                            required
                          />
                        </Stack>
                        <Stack direction='row' gap={2}>
                          <InputV2 id='conta_corrente' label='Conta Corrente' required />
                          <InputV2 id='numero_contrato' label='Numero do Contrato' required />
                        </Stack>
                        <InputV2
                          type='file'
                          label='Certificado'
                          required={!paymentMethodId}
                          formik={false}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue(
                              'certificado',
                              e.currentTarget.files ? e.currentTarget.files[0] : undefined,
                            )
                          }
                        />
                        <InputV2
                          id='certificado_senha'
                          label='Senha do Certificado'
                          required={!paymentMethodId}
                        />
                      </Stack>
                    )}
                    {formik.values.plataforma === PaymentMethodPlatform.BB && (
                      <Stack gap={2}>
                        <PaymentDocumentTypeSelect
                          value={formik.values.bb_boleto_tipo}
                          error={!!formik.errors.bb_boleto_tipo}
                          onChange={(value) => formik.setFieldValue('bb_boleto_tipo', value)}
                        />
                        <PaymentModalitySelect
                          value={formik.values.bb_codigo_modalidade}
                          error={!!formik.errors.bb_codigo_modalidade}
                          onChange={(value) => formik.setFieldValue('bb_codigo_modalidade', value)}
                        />
                        <Stack direction='row' gap={2}>
                          <InputV2 id='bb_numero_carteira' label='Número da Carteira' required />
                          <InputV2
                            id='bb_numero_variacao_carteira'
                            label='Número Variação da Carteira'
                            required
                          />
                        </Stack>
                        <InputV2 id='bb_numero_convenio' label='Número do Convenio' required />
                        <InputV2 id='bb_basic_token' label='Token' required={!paymentMethodId} />
                        <InputV2 id='bb_app_key' label='Chave de APP' required={!paymentMethodId} />
                      </Stack>
                    )}
                  </Stack>
                </>
              )}
            </Modal.Body>
            <StickyFooter>
              <Button
                containerStyle={{ marginLeft: 'auto', width: 'fit-content', minWidth: '152px' }}
                isLoading={isSubmitting}
                disabled={isSubmitting}
                text={paymentMethodId ? 'Atualizar' : 'Criar'}
              />
            </StickyFooter>
          </Form>
        </FormikProvider>
      )}
    </Modal.Provider>
  )
}
