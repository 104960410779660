import styled from "styled-components";
import { Theme } from '../../../../styles/theme'

export const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const RightSideContainer = styled.div`
  display: flex;
  gap: 20px;
`

export const TicketsCount = styled.p`
  font-size: 16px;
  margin-right: 30px;
  color: ${Theme.colors.white};
  background-color: ${Theme.colors.darkishGreen};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: default;
  letter-spacing: 0.001px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-left: auto;
`
