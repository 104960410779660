import { CSSProperties, HTMLAttributes, PropsWithChildren } from 'react'
import { Container, Modal } from './styles'
import { ModalProvider } from '../hooks/useModal'

type Props = HTMLAttributes<HTMLDivElement> & {
  modalStyle?: CSSProperties;
  isOpen?: boolean
  maxWidth?: number
  hideBackdrop?: boolean
  onClose: () => void
}

export default function ModalContainer(props: PropsWithChildren<Props>) {
  const { children, modalStyle, maxWidth = 800, hideBackdrop, isOpen = false, onClose, ...rest } = props

  return (
    <Modal style={modalStyle} open={isOpen} hideBackdrop={hideBackdrop} onClose={onClose}>
      <Container maxWidth={maxWidth} {...rest}>
        <ModalProvider onClose={onClose}>{children}</ModalProvider>
      </Container>
    </Modal>
  )
}
