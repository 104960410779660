import { useState } from 'react'
import { FloatingFilterWrapper } from '../../../../components/FloatingFilterWrapper'
import { OptionItem, OptionList } from '../../styles'
import { TicketStatus } from '../../../../interfaces/tickets'

interface Props {
  statusFilter: string
  setStatusFilter: (value: string) => void
}

export default function StatusFilter(props: Props) {
  const { statusFilter, setStatusFilter } = props

  const [isStatusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive
      label='Status'
      modalStyles={{ padding: 4 }}
      hideSubmitButton
    >
      <OptionList showDropDown={isStatusDropdownOpen}>
        {[
          { value: TicketStatus.OPEN, label: 'Aberto' },
          { value: TicketStatus.IN_PROGRESS, label: 'Em progresso' },
          { value: TicketStatus.CLOSED, label: 'Concluído' },
        ].map((item) => (
          <OptionItem
            active={statusFilter === item.value}
            key={item.value}
            onClick={() => {
              setStatusDropdownOpen((previousStatusDropdownOpen) => !previousStatusDropdownOpen)
              const updatedValue = statusFilter === item.value ? '' : item.value;
              setStatusFilter(updatedValue)
            }}
          >
            {item.label}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
