import styled from 'styled-components'
import AutorenewIcon from '@mui/icons-material/Autorenew'

type ConfigButtonsProps = {
  gap?: string | number
}

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const TotalPower = styled.p`
  font-size: 16px;
  margin-right: 30px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
  padding: 4px 8px;
  border-radius: 4px;
  letter-spacing: 0.001px;
  cursor: default;

  span {
    font-weight: 600;
  }
`

export const PositiveAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
`

export const ErrorAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.suportError};
`

export const ButtonsPowerPlantContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const ConfigButtonsColumn = styled.div<ConfigButtonsProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: ${(props) => props.gap ?? 'none'};
`

export const DisabledAccess = styled.span`
padding: 4px 8px;
font-size: 12px;
line-height: 14px;
border-radius: 4px;
color: ${({ theme }) => theme.colors.white};
background-color: ${({ theme }) => theme.colors.htmlGrey};
`

export const CancellingAccess = styled.span`
padding: 4px 8px;
font-size: 12px;
line-height: 14px;
border-radius: 4px;
color: ${({ theme }) => theme.colors.white};
background-color: ${({ theme }) => theme.colors.lightOrange};
`

export const PositiveAccess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
`

export const StatusIcon = styled(AutorenewIcon)`
  color: ${({ theme }) => theme.colors.grayDark};
`
