import { Card, Grid, Typography } from '@mui/material'
import Modal from '../../../../../components/ModalV2'
import { Find, invoiceErrorsService } from '../../../../../services/invoice-errors'
import { Check, Close, InsertDriveFile } from '@mui/icons-material'
import { StickyFooter } from '../../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumerUnit/styles'
import { useEffect, useState } from 'react'
import { useToast } from '../../../../../hooks/useToast'
import DeleteInvoiceFileModal from '../DeleteInvoiceFileModal'
import Loading from '../../../../../components/Loading'
import DownloadReaderErrorFilesButton from '../DownloadReaderErrorFilesButton'

interface Props {
  readerErrorId: number
  onClose: () => void
}

export default function ReaderErrorModal(props: Props) {
  const { readerErrorId, onClose } = props

  const [isLoading, setLoading] = useState<boolean>(false)
  const [fileIdBeingRemoved, setFileIdBeingRemoved] = useState<string>()

  const [readerError, setReaderError] = useState<Find['result']>()

  const { toast } = useToast()

  const fetchReaderError = async () => {
    if (isLoading) {
      return
    }
    try {
      setLoading(true)
      const fileBuffer = await invoiceErrorsService.find({
        readerErrorId,
      })
      setReaderError(fileBuffer)
    } catch (error: any) {
      toast({
        message: error.message ?? 'Erro ao buscar erro de leitor',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReaderError()
  }, [])

  if (fileIdBeingRemoved) {
    return (
      <DeleteInvoiceFileModal
        readerErrorId={readerErrorId}
        errorFileId={fileIdBeingRemoved}
        onSuccess={fetchReaderError}
        onClose={() => setFileIdBeingRemoved(undefined)}
      />
    )
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '750px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Faturas com Erro
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body
        style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%' }}
      >
        {isLoading && <Loading />}

        {!isLoading && typeof readerError !== 'undefined' && (
          <Grid container spacing={1}>
            {readerError.files
              .sort((f1, f2) => {
                if (!f1.deleted_at && f2.deleted_at) {
                  return -1
                }
                if (f1.deleted_at && !f2.deleted_at) {
                  return 1
                }
                return 0
              })
              .map((file, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Card
                    variant='outlined'
                    title={file.name}
                    style={{
                      backgroundColor: '#f5f5f5',
                      position: 'relative',
                      textAlign: 'center',
                      padding: '10px',
                      ...(file.deleted_at ? { opacity: '0.6' } : {})
                    }}
                  >
                    {!file.deleted_at ? (
                      <Close
                        color='error'
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          top: 4,
                          right: 4,
                          fontSize: '22px',
                        }}
                        onClick={() => setFileIdBeingRemoved(file.id)}
                      />
                    ) : (
                      <Check
                        color='success'
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          top: 4,
                          right: 4,
                          fontSize: '22px',
                        }}
                      />
                    )}
                    <InsertDriveFile
                      fontSize='large'
                      color='action'
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => window.open(file.url, '_blank')}
                    />
                    <Typography
                      variant='body2'
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {file.name}
                    </Typography>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
      </Modal.Body>
      {!isLoading && typeof readerError !== 'undefined' && (
        <StickyFooter>
          <DownloadReaderErrorFilesButton readerErrorId={readerErrorId} />
        </StickyFooter>
      )}
    </Modal.Provider>
  )
}
