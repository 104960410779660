import { darken } from 'polished'
import styled, { DefaultTheme } from 'styled-components'

type ModeType = 'lightGreen' | 'darkGreen' | 'white' | 'blue'

const getColorsFont = (theme: DefaultTheme) => ({
  lightGreen: theme.colors.darkishGreen,
  darkGreen: '#fff',
  blue: '#fff',
  white: theme.colors.htmlGrey,
})

const getColorsBg = (theme: DefaultTheme) => ({
  lightGreen: theme.colors.brightGreen,
  darkGreen: theme.colors.darkishGreen,
  blue: '#2D7BBA',
})

const getColor = (mode: ModeType, theme: DefaultTheme, colors: (theme: DefaultTheme) => any) => {
  return colors(theme)[mode] || '#fff'
}

export const Container = styled.button<{ mode: ModeType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ mode, theme }) => getColor(mode, theme, getColorsBg)};
  cursor: pointer;
  margin: 5px;
  border-radius: 8px;
  width: 100%;

  :hover {
    background-color: ${({ mode, theme }) => darken(0.01, getColor(mode, theme, getColorsBg))};
  }
`

export const ButtonField = styled.button<{ mode: ModeType }>`
  color: ${({ mode, theme }) => getColor(mode, theme, getColorsFont)};
  background: transparent;
  border: 0;
  padding: 10px;
  font-weight: 700;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${({ mode, theme }) => darken(0.1, getColor(mode, theme, getColorsFont))};
  }
`
