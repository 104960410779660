import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { GetConsumerColumnsParams } from './types'
import { Box, Tooltip } from '@mui/material'
import DeleteIcon from '../../../../assets/delete.svg'
import EditIcon from '../../../../assets/edit.svg'

const formatDocument = (value: string) => {
  const cnpjCpf = value.replace(/\D/g, '')

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  }

  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

const formatNumber = (value: string) => {
  let formattedNumber = ''
  if (value.length === 11) {
    formattedNumber = `(${value.substring(0, 2)}) ${value.substring(2, 3)} ${value.substring(
      3,
      7,
    )}-${value.substring(7, 11)}`
  }
  if (value.length === 10) {
    formattedNumber = `(${value.substring(0, 2)}) ${value.substring(2, 6)}-${value.substring(
      6,
      10,
    )}`
  }
  return formattedNumber
}

export const getConsumerColumns = ({
  data,
  deleteHandler,
  editHandler,
  permissions
}: GetConsumerColumnsParams): GridColDef[] => {
  const rows: GridColDef[] = [
    {
      field: 'cliente_nome',
      headerName: 'Nome',
      description: 'Nome',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const value = params.value ?? '-'

        return (
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={value}
          >
            {value}
          </span>
        )
      },
      minWidth: 320,
      sortable: true,
      hideable: true
    },
    {
      field: 'documento_numero',
      headerName: 'CPF/CNPJ',
      description: 'CPF/CNPJ',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? formatDocument(params.value) : '-'
      },
      minWidth: 160,
      maxWidth: 180,
      sortable: true,
      hideable: true
    },
    {
      field: 'email',
      headerName: 'Login',
      description: 'Login',
      hide: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return (
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.value ?? '-'}
          </span>
        )
      },
      minWidth: 220,
      sortable: true,
      hideable: true
    },
    {
      field: 'fone',
      headerName: 'Telefone',
      description: 'Telefone',
      hide: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? formatNumber(params.value) : '-'
      },
      minWidth: 140,
      maxWidth: 160,
      sortable: false,
      hideable: true
    },
    {
      field: 'createdAt',
      headerName: 'Data de Cadastro',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.value && params.value.length) {
          return new Date(`${params.value.substring(0, 10)} 12:00:00`)
            .toLocaleString('pt-BR')
            .substring(0, 10)
        } else {
          return '-'
        }
      },
      minWidth: 100,
      maxWidth: 120,
      hide: true,
      sortable: true,
      hideable: true
    },
    {
      field: 'emails_pagamentos',
      headerName: 'Emails de recebimento',
      description: 'Emails de recebimento',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const value = params?.value?.map((item: { email: string }) => item?.email).join('; ')
        return (
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={value}
          >
            {value && value.length ?
              value : '-'}
          </span>
        )
      },
      minWidth: 220,
      sortable: false,
      hideable: true
    },
    {
      field: 'quantidade_ucs',
      headerName: 'Quantidade de UCs',
      description: 'Quantidade de UCs',
      maxWidth: 160,
      sortable: true,
      hideable: true
    },
  ]

  if (permissions && (permissions?.hasEditingPermission || permissions?.hasDeletePermission)) {
    const actionsRow: GridColDef = {
      field: 'actions',
      headerName: 'Opções',
      renderHeader: () => '',
      sortable: false,
      hideable: false,
      width: 120,
      renderCell(params) {
        const fontSize = 15;

        return (
          <Box display={'flex'} gap={2}>
            {permissions?.hasEditingPermission && (
              <Tooltip title={<p style={{ fontSize }}>Editar</p>} placement='bottom'>
                <img
                  src={EditIcon.toString()}
                  onClick={() => editHandler(params.row?.id as number, data)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}

            {permissions?.hasDeletePermission && (
              <Tooltip title={<p style={{ fontSize }}>Deletar</p>} placement='bottom'>
                <img
                  src={DeleteIcon.toString()}
                  onClick={() => deleteHandler(params.row?.id as number)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}
          </Box>
        )
      }
    }

    rows.push(actionsRow)
  }

  return rows
}
