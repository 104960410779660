import styled from '@emotion/styled'
import { Button } from '../../../../components/Button'
import { Theme } from '../../../../styles/theme'
import { darken } from 'polished'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  justify-content: center;
`

export const ButtonsArea = styled.div`
  padding: 32px 0 16px 0;
  display: flex;
  margin-right: auto;
  gap: 16px;
`

export const TicketSettings = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
`

export const CancelButton = styled(Button)`
  width: 152px;
  height: 40px;

  background: ${Theme.colors.darkishGreen};
  border-radius: 8px;

  &:hover {
    background-color: ${darken(0.1, Theme.colors.darkishGreen)};
    cursor: pointer;
  }
`

export const ContinueButton = styled(CancelButton)`
  background: ${Theme.colors.darkishGreen};
  margin-left: auto;

  &:hover {
    background-color: ${darken(0.1, Theme.colors.darkishGreen)};
    cursor: pointer;
  }
`