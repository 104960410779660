import React, { useState } from 'react'
import { Icon, IconHiden, InputDiv, InputField, Label } from './styles'
interface Props {
  label?: string
  type?: 'email' | 'password' | 'search' | 'url'
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  value?: any
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const Input: React.FC<Props> = ({ label, type, onChange, placeholder, value, onBlur }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(true)

  return (
    <div>
      {!!label && <Label>{label}</Label>}
      <InputDiv>
        <InputField
          type={isPasswordVisible ? type : ''}
          onChange={onChange}
          placeholder={placeholder || label}
          value={value}
          onBlur={onBlur}
        />
        {type === 'password' && (
          <>
            {isPasswordVisible ? (
              <Icon
                fontSize='small'
                style={{ color: 'white' }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            ) : (
              <IconHiden
                fontSize='small'
                style={{ color: 'white' }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            )}
          </>
        )}
      </InputDiv>
    </div>
  )
}
