import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { InvoiceParser } from '../../../../../interfaces/invoices'
import { FieldContainer, SectionContainer, SectionGrid, SectionTitle } from '../InvoiceModal/styles'
import { Form } from 'formik'
import { FormikInput } from '../../../../../components/FormikComponents'

const groupsMapping = {
  consumo_p: 'Consumo Ponta',
  consumo_fp: 'Consumo Fora Ponta',
  consumo: 'Consumo',
  energia_comp: 'Energia Compensada',
  med_ener: 'Medidor Energia',
  tot_trib: 'Tributos',
  med_cons: 'Medidor Consumo',
  med_dem: 'Medidor Demanda',
  ener_reat: 'Energia Reativa',
  data: 'Datas',
  mes: 'Datas',
}

interface Props {
  invoiceParser: InvoiceParser
}

export default function InvoiceForm(props: PropsWithChildren<Props>) {
  const { invoiceParser, children } = props

  const formFields = useMemo(() => {
    const fields: ReactNode[] = []
    const groups: Record<string, ReactNode[]> = {}

    if (!invoiceParser || !invoiceParser.raw_data) {
      return {
        fields,
        groups,
      }
    }

    const entries = Object.entries(invoiceParser.raw_data)

    for (const [key] of entries) {
      const [groupKey, groupValue] =
        Object.entries(groupsMapping).find(([mapKey]) => key.startsWith(mapKey)) ?? []
      if (groupKey && groupValue) {
        if (!groups[groupValue]) {
          groups[groupValue] = []
        }
        groups[groupValue].push(
          <FieldContainer key={`invoice-field-${key}`}>
            <FormikInput showTitle label={key} mode required halfSize name={key} />
          </FieldContainer>,
        )
        continue
      }

      fields.push(
        <FieldContainer key={`invoice-field-${key}`}>
          <FormikInput showTitle label={key} mode required halfSize name={key} />
        </FieldContainer>,
      )
    }

    return {
      fields,
      groups,
    }
  }, [invoiceParser])

  return (
    <Form style={{ position: 'relative' }}>
      <SectionContainer>
        <SectionGrid>{formFields.fields.map((node) => node)}</SectionGrid>
      </SectionContainer>
      {Object.entries(formFields.groups).map(([key, value]) => (
        <SectionContainer key={`invoice-group-${key}`}>
          <SectionTitle>{key}</SectionTitle>

          <SectionGrid>{value}</SectionGrid>
        </SectionContainer>
      ))}

      {children}
    </Form>
  )
}
