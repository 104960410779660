import React from 'react'
import { SearchContainer } from './styles'
import SearchIcon from '../../assets/search-light.svg'

interface Props {
  value: string
  setValue: (...params: any[]) => void
  style?: React.CSSProperties
}

export const SearchInput: React.FC<Props> = ({ value, setValue, style }) => {
  return (
    <SearchContainer style={style}>
      <img src={SearchIcon.toString()} />
      <input value={value} onChange={(e) => setValue(e.target.value)} placeholder='Buscar' 
        onKeyDown={(e) => {
        if (e.key === "Enter") {
          setValue(value)
        }
      }}/>
    </SearchContainer>
  )
}