import { Pagination, PaginationQuery } from '../interfaces/pagination'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

export interface AggregatedPayment {
  id: number
  clientId: number
  clientName: string
  paymentValue: number
  paymentStatus: string | null
  dueDate: string
  referenceDate: string
  sentAt: string | null
  goingToPay: number
  paymentEmails: string[]
  fechamentoIds: number[]
}

export interface ListAggregatedPayments {
  params: {
    referenceMonth: string
  } & PaginationQuery
  result: Pagination<AggregatedPayment>
}

export interface CreateAggregatedPayment {
  params: {
    clientId: number
    dueDate: string
    referenceMonth: string
  }
  result: void
}

export interface ListConsumersWithAggregatedPayment {
  result: {
    id: number
    cliente_nome: string
  }[]
}

export interface GetAggregatedPaymentsMetrics {
  params: {
    referenceMonth: string
  } & Pick<PaginationQuery, 'filter' | 'sort'>
  result: {
    total: number
    paid: number
    overdue: number
    defaultRate: number
  }
}
export interface DownloadAggregatedPaymentsSheet {
  params: {
    aggregatedPaymentId?: number
    referenceMonth: string
  } & Pick<PaginationQuery, 'filter' | 'sort'>
  result: Buffer
}
export interface SendAggregatedPayment {
  params: {
    aggregatedPaymentId: number
    fechamentosIds: number[]
  }
  result: void
}
export interface GetRentalInvoiceParams {
  aggregatedPaymentId: number
  fechamentosIds?: number[]
}
export interface UpdateAggregatedPayment {
  params: {
    aggregatedPaymentId: number
    dueDate: string
  }
  result: void
}

class AggregatedPaymentsService {
  async listAggregatedPayments(
    params: ListAggregatedPayments['params'],
  ): Promise<ListAggregatedPayments['result']> {
    const { referenceMonth, filter, itemsPerPage, page, sort } = params
    const response = await api.get('/aggregate-payments', {
      params: {
        monthReference: referenceMonth,
        itemsPerPage,
        page,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
        ...(sort
          ? {
              field: sort.field,
              order: sort.order,
            }
          : {}),
      },
    })
    return response.data
  }
  async createAggregatedPayment(
    params: CreateAggregatedPayment['params'],
  ): Promise<CreateAggregatedPayment['result']> {
    const { clientId, dueDate, referenceMonth } = params
    await api.post('/aggregate-payments', {
      clientId,
      dueDate,
      referenceDate: referenceMonth,
    })
  }
  async listConsumersWithAggregatedPayment(): Promise<
    ListConsumersWithAggregatedPayment['result']
  > {
    const response = await api.get('/consumidores/with-aggregate-payments')
    return response.data
  }
  async getMetrics(
    params: GetAggregatedPaymentsMetrics['params'],
  ): Promise<GetAggregatedPaymentsMetrics['result']> {
    const { referenceMonth, filter, sort } = params
    const response = await api.get('/aggregate-payments/metrics', {
      params: {
        monthReference: referenceMonth,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
        ...(sort
          ? {
              field: sort.field,
              order: sort.order,
            }
          : {}),
      },
    })
    return response.data
  }
  async downloadSheet(
    params: DownloadAggregatedPaymentsSheet['params'],
  ): Promise<DownloadAggregatedPaymentsSheet['result']> {
    const { aggregatedPaymentId, referenceMonth, filter, sort } = params
    const url = aggregatedPaymentId ? `aggregate-payments/${aggregatedPaymentId}/spreadsheet` : 'aggregate-payments/spreadsheets'
    const response = await api.get(
      url,
      {
        params: {
          monthReference: referenceMonth,
          ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
          ...(sort
            ? {
                field: sort.field,
                order: sort.order,
              }
            : {}),
        },
        responseType: 'blob',
      },
    )

    return response.data
  }
  async sendAggregatedPayment(
    params: SendAggregatedPayment['params'],
  ): Promise<SendAggregatedPayment['result']> {
    const { aggregatedPaymentId, fechamentosIds } = params
    const response = await api.post(`aggregate-payments/${aggregatedPaymentId}/send`, {
      fechamentosIds
    })
    return response.data
  }
  async updateAggregatedPayment(
    params: UpdateAggregatedPayment['params'],
  ): Promise<UpdateAggregatedPayment['result']> {
    const { aggregatedPaymentId, dueDate } = params
    const response = await api.put(`aggregate-payments/${aggregatedPaymentId}/due-date`, {
      newDate: dueDate,
    })
    return response.data
  }
  async deleteAggregatePayment(id: number): Promise<void> {
    const response = await api.delete(`aggregate-payments/${id}`)
    return response.data
  }
  async getRentalInvoice({
    aggregatedPaymentId,
    fechamentosIds
  }: GetRentalInvoiceParams): Promise<{
    data: {
      filename: string;
      content: Buffer;
      svgInString: string;
    }
  }> {
    const response = await api.post(`aggregate-payments/${aggregatedPaymentId}/rental-invoice/preview`, {
      fechamentosIds
    })
    return response.data
  }
}

export const aggregatedPaymentsService = new AggregatedPaymentsService()
