import { FormikProvider, useFormik } from 'formik'
import Modal from '../../../../components/ModalV2'
import { useContext, useState } from 'react'
import { FormikInput } from '../../../../components/FormikComponents'
import ImageSelector from '../ImageSelector'
import { ButtonsModalContainer, CancelButton, CustomField, SendButton } from '../../styles'
import { GlobalContext } from '../../../../context/global/global'
import CategorySelect from '../CategorySelect'
import { ticketsService } from '../../../../services/tickets'
import * as Yup from 'yup'

interface FormData {
  title: string
  description: string
  category: string
  subCategory?: string
}

interface Props {
  onCreate: () => void
  onClose: () => void
}

const validationSchema: Yup.SchemaOf<FormData> = Yup.object().shape({
  title: Yup.string().required('Insira um título'),
  description: Yup.string().required(),
  category: Yup.string().required(),
  subCategory: Yup.string(),
})

export default function CreateTicketModal(props: Props) {
  const { onCreate, onClose } = props

  const { Theme } = useContext(GlobalContext)

  const [images, setImages] = useState<File[]>([])
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const createTicket = async (values: FormData) => {
    try {
      setSubmitting(true)
      await ticketsService.createTicket({
        ...values,
        files: images,
      })
      onCreate()
      onClose()
    } catch (error) {
      console.log(`Error creating ticket: ${error}`)
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      subCategory: '',
      category: '',
    } as FormData,
    validationSchema: validationSchema,
    onSubmit: createTicket,
  })

  return (
    <Modal.Provider style={{ overflowY: 'auto' }} isOpen maxWidth={450} onClose={onClose}>
      <Modal.Title>
        Cadastrar novo ticket
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <FormikInput name='title' label='Título' mode required />
            <label style={{ color: '#4B4B4B', fontSize: '16px' }}>
              Descrição<span style={{ color: Theme.colors.suportError }}>*</span>
            </label>
            <CustomField
              style={{ marginBottom: '16px' }}
              name='description'
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <CategorySelect onChange={(category, subCategory) => {
              formik.setFieldValue('category', category)
              formik.setFieldValue('subCategory', subCategory ?? '')
            }} />
            <ImageSelector images={images} setImages={setImages} />
            <ButtonsModalContainer>
              <CancelButton onClick={onClose}>Cancelar</CancelButton>
              <SendButton type='submit' disabled={isSubmitting}>
                Enviar
              </SendButton>
            </ButtonsModalContainer>
          </form>
        </FormikProvider>
      </Modal.Body>
    </Modal.Provider>
  )
}
