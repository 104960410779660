import InvoiceList from './components/InvoiceList'
import { InvoiceListProvider } from './hooks/useInvoiceList'

export default function InvoiceValidationPage() {
  return (
    <InvoiceListProvider>
      <InvoiceList />
    </InvoiceListProvider>
  )
}
