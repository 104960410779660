import { IconButton, Stack, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import InvoiceModal from '../InvoiceModal'
import { useInvoiceList } from '../../hooks/useInvoiceList'
import Loading from '../../../../../components/Loading'
import { TableV2 } from '../../../../../components/TableV2'
import { GridColDef } from '@mui/x-data-grid'
import ReloadIcon from '../../../../../assets/reload.svg'
import EditIcon from '../../../../../assets/edit.svg'
import DeleteIcon from '../../../../../assets/delete.svg'
import PaperIcon from '../../../../../assets/paper.svg'
import { invoiceParserV2 } from '../../../../../services/invoice-parser-v2'
import { useToast } from '../../../../../hooks/useToast'
import { InvoiceValidation } from '../../../../../interfaces/invoices'
import { usePagination } from '../../../../PlantsInvoicing/hooks/usePagination'
import { TablePagination } from '../../../../../components/TableV2/components/TablePagination'
import {
  Configuration,
  ConfigurationActions,
  TotalInvoicing,
} from '../../../../PlantsInvoicing/components/PlantsInvoicing/styles'
import SearchInput from '../../../../PlantsInvoicing/components/SearchInput'

const primitiveTableColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'managerName',
    headerName: 'Gestor',
  },
  {
    field: 'distributorName',
    headerName: 'Distribuidora',
  },
  {
    field: 'ucName',
    headerName: 'Unidade Consumidora',
  },
  {
    field: 'originalFileName',
    headerName: 'Nome do Arquivo',
  },
]

function getInvoiceValidationStatus(invoice: InvoiceValidation) {
  if (invoice.error) {
    return 'Erro'
  }
  if (invoice.invoiceData) {
    return 'Pronta'
  }
  return 'Processando'
}

interface Props {
  showStatus?: boolean
}

export default function InvoiceList(props: Props) {
  const { showStatus } = props

  const { invoiceList, fetchInvoiceList, isLoading } = useInvoiceList()

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>()

  const [tableColumns, setTableColumns] = useState<GridColDef[]>([])

  const { pagination, getPagination, searchFilter, setSearchFilter } = usePagination<{
    id: string
    distributorName: string
    managerName: string
    originalFileName: string
    ucName: string
    fileId: string
    status?: string
  }>({
    rawData: invoiceList.map((invoice) => ({
      id: invoice.id,
      managerName: invoice.manager ? invoice.manager.name : '-',
      originalFileName: invoice.originalFileName ? invoice.originalFileName : '-',
      distributorName: invoice.distributor ? invoice.distributor.name : '-',
      ucName: invoice.uc ? invoice.uc.name : '-',
      fileId: invoice.fileId,
      ...(showStatus ? { status: getInvoiceValidationStatus(invoice) } : {}),
    })),
    filterBySearch: (invoices, searchFilter) => {
      return invoices.filter(
        (info) =>
          (info.ucName && info.ucName.toLowerCase().includes(searchFilter.toLowerCase())) ||
          info.id === searchFilter,
      )
    },
    filterDataByIds: (invoices, ids) => {
      return invoices.filter((info) => ids.includes(info.id))
    },
    filterBySort: (invoices) => {
      return invoices.sort(() => {
        return 1
      })
    },
  })

  useEffect(() => {
    getPagination({ page: 1 })
  }, [invoiceList])

  useEffect(() => {
    fetchInvoiceList()
  }, [])

  const { toast } = useToast()

  const openDownloadInvoice = (fileId: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/faturas/download/${fileId}`
    window.open(url, '_blank')
  }

  const reanalyzeInvoice = async (invoiceValidationId: string) => {
    try {
      await invoiceParserV2.reanalyzeInvoice(invoiceValidationId)
      toast({
        message: 'Releitura realizada. A fatura pode demorar até 2 minutos para ser atualizada',
        type: 'success',
      })
    } catch (error) {
      toast({
        message: `Erro na releitura da fatura: ${error}`,
        type: 'error',
      })
    }
  }

  const deleteInvoiceValidation = async (invoiceValidationId: string) => {
    try {
      await invoiceParserV2.deleteInvoiceValidation(invoiceValidationId)
      await fetchInvoiceList()
      toast({
        message: 'Fatura deletada com sucesso',
        type: 'success',
      })
    } catch (error) {
      toast({
        message: `Erro ao deletar fatura: ${error}`,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    const primitiveColumns = [...primitiveTableColumns]
    if (showStatus) {
      primitiveColumns.push({
        field: 'status',
        headerName: 'Status',
      })
    }

    setTableColumns([
      ...primitiveColumns,
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        renderCell(params) {
          return (
            <Stack gap={2} direction='row'>
              <Tooltip title={<p style={{ fontSize: 15 }}>Editar</p>} placement='bottom'>
                <IconButton onClick={() => setSelectedInvoiceId(params.row.id)}>
                  <img src={EditIcon.toString()} />
                </IconButton>
              </Tooltip>
              <Tooltip title={<p style={{ fontSize: 15 }}>Releitura</p>} placement='bottom'>
                <IconButton onClick={() => reanalyzeInvoice(params.row.id)}>
                  <img
                    src={ReloadIcon.toString()}
                    style={{ cursor: 'pointer', maxWidth: '24px' }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar fatura</p>} placement='bottom'>
                <IconButton onClick={() => openDownloadInvoice(params.row.fileId)}>
                  <img src={PaperIcon.toString()} />
                </IconButton>
              </Tooltip>
              <Tooltip title={<p style={{ fontSize: 15 }}>Deletar</p>} placement='bottom'>
                <IconButton onClick={() => deleteInvoiceValidation(params.row.id)}>
                  <img src={DeleteIcon.toString()} />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        },
      },
    ])
  }, [invoiceList])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {selectedInvoiceId && (
        <InvoiceModal
          invoiceValidationId={selectedInvoiceId}
          onValidate={() => fetchInvoiceList()}
          onClose={() => setSelectedInvoiceId(undefined)}
        />
      )}

      <Configuration>
        <ConfigurationActions>
          <TotalInvoicing>
            <span>Total de faturas:</span> {invoiceList.length}
          </TotalInvoicing>

          <SearchInput
            value={searchFilter}
            onSearch={(filter) => {
              getPagination({
                searchFilter: filter,
              })
            }}
            onChange={(filter) => setSearchFilter(filter)}
          />
        </ConfigurationActions>
      </Configuration>

      <TableV2 alignText='center' columns={tableColumns} rows={pagination.data}>
        <TablePagination
          currentPageItemCount={pagination.data.length}
          pageInfo={pagination.pageInfo}
          onChange={(page) => getPagination({ page })}
        />
      </TableV2>
    </>
  )
}
