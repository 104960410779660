import { useState, useContext } from 'react'
import Chart, { Props as ApexProps } from 'react-apexcharts'
import { GlobalContext } from '../../../context/global/global'
import { ChartContainer, ChartContext, ChartWrapper, Indicators } from './styles'

interface Props {
  value: number
  styles?: React.CSSProperties
}

export const HalfCircle: React.FC<Props> = ({ value, styles }) => {
  const { Theme } = useContext(GlobalContext)

  const handleColor = () => {
    if (value >= 0 && value <= 49) {
      return [Theme.colors.orange]
    } else if (value >= 50 && value <= 89) {
      return [Theme.colors.lightGreen]
    } else {
      return [Theme.colors.darkGreen]
    }
  }

  const [chartProps] = useState<ApexProps>({
    type: 'radialBar',
    series: [value],
    options: {
      chart: {
        type: 'radialBar',
      },
      series: [67],
      colors: handleColor(),
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: Theme.colors.background,
            startAngle: -90,
            endAngle: 90,
            dropShadow: {
              enabled: true,
              top: -1,
              left: -2,
              blur: 1,
              opacity: 0.15,
            },
            strokeWidth: '130%',
            margin: 7,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: '30',
              fontFamily: Theme.fonts.family.primary,
              show: true,
              fontWeight: 500,
              color: Theme.colors.htmlGrey,
              offsetY: -5,
              formatter: function (val) {
                return `${Math.trunc(val).toLocaleString('pt-BR')}%`
              },
            },
          },
        },
      },
      fill: {
        type: 'solid',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          gradientToColors: undefined,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
        width: 0,
      },
      labels: ['Progress'],
    },
  })

  return (
    <ChartContainer style={styles}>
      <h1>Compensado/Consumido</h1>
      <ChartWrapper>
        <ChartContext>
          <Chart {...chartProps} width='75%' height='75%' />
        </ChartContext>
      </ChartWrapper>
      <Indicators>
        <span>0</span>
        <span>100</span>
      </Indicators>
    </ChartContainer>
  )
}
