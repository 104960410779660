import { useState, useEffect } from 'react'

import { DateFilter } from '../../../components/Filter/DateFilter'

import { BillingControlContainer, TableUtils } from './styles'

import { SettingsOutlined } from '@mui/icons-material'
import { getFaturamento, getFaturamentoSugestions } from '../../../services/requests/user-requests'
import Loading from '../../../components/Loading'
import { Alert, Snackbar, TextField } from '@mui/material'
import { IFaturamentoData } from '../../../services/requests/types'
import { InputSearch } from '../../../components/FormikComponents/SearchInput/styles'
import Search from '../../../assets/icons/searchIcon.svg'
import { Table } from '../../../components/Table'
import { tableColumns } from './utils'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'

interface IFaturamentoSugestions {
  label: string
  value: string
}

export function BillingControl() {
  // const { Theme } = useContext(GlobalContext)
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [search, setSearch] = useState('')
  const [inputSugestions, setInputSugestions] = useState<Array<IFaturamentoSugestions>>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IFaturamentoData['data']>([])
  const [page, setPage] = useState(1)
  const [totalCountPayments, setTotalCountPayments] = useState(0)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [sortValues, setSortValues] = useState<{ field: string; sort: string }>()
  const [modelPayments, setModelPayments] = useState([
    { field: 'cliente_nome', sort: undefined },
    { field: 'potencia_instalada', sort: undefined },
    { field: 'count_ucs', sort: undefined },
    { field: 'boletos', sort: undefined },
  ])

  const fetchData = async (name?: string) => {
    setIsLoading(true)
    try {
      const { data } = await getFaturamento({
        ...dateSelected,
        name,
        page,
        limit: pageItemsAmount,
        sort: sortValues?.field,
        order: sortValues?.sort,
      })

      console.log(data)
      setData(data.data ?? [])
      setTotalCountPayments(data.count ?? 0)
    } catch (error) {
      setErrorMessage('Ocorreu um erro ao buscar as informações.')
      console.log('fetch data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchSugestions = async () => {
    setIsLoading(true)
    try {
      const { data: names } = await getFaturamentoSugestions()

      setInputSugestions(
        names.map((name) => ({
          label: name,
          value: name,
        })),
      )
    } catch (error) {
      setErrorMessage('Ocorreu um erro ao buscar as sugestões de e-mail.')
      console.log('fetch sugestions:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const callFetchData = () => (search != '' ? fetchData(search) : fetchData())

  useEffect(() => {
    callFetchData()
    fetchSugestions()
  }, [dateSelected, sortValues])

  const handleSearch = (name: string) => {
    setSearch(name)
    fetchData(name)
  }

  function handleChangePage(page: number) {
    setPage(page)
  }

  return (
    <BillingControlContainer>
      <Snackbar
        open={errorMessage !== ''}
        autoHideDuration={5000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage('')}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      <TableUtils>
        <InputSearch
          disablePortal
          id='combo-box-demo'
          options={inputSugestions}
          noOptionsText='Nenhuma opção encontrada'
          sx={{ width: 300 }}
          renderInput={(params) => (
            <div style={{ display: 'flex', position: 'relative' }}>
              <img src={`${Search}`} style={{ position: 'absolute', top: '20px', left: '20px' }} />
              <TextField placeholder='Buscar e-mail de cliente' {...params} />
            </div>
          )}
          value={search}
          // onChange={(_, val) => handleSearch((val as IFaturamentoSugestions).value)}
          onInputChange={(e, value, reason) => {
            if (['reset', 'clear'].includes(reason)) {
              handleSearch('')
            } else {
              handleSearch(value)
            }
          }}
          renderOption={(props, option: any) => {
            return (
              <li style={{ paddingTop: 8, paddingBottom: 8, fontSize: 16 }} {...props}>
                {option.label}
              </li>
            )
          }}
        />
        <FloatingCheckboxes
          pagination={{
            selected: pageItemsAmount,
            setSelected: setPageItemsAmount,
            options: [15, 20, 30, 50, 100],
          }}
          isFilterActive={false}
          title='Configurar Tabela'
          submitBtnText='Salvar'
          customActionComponent={
            <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
          }
          submitAction={() => callFetchData()}
        />
      </TableUtils>
      {isLoading ? (
        <Loading />
      ) : (
        <Table
          // pagination={{
          //   page,
          //   total: totalCountPayments,
          //   limit: pageItemsAmount,
          //   totalRegistersPerPage: data?.length || 0,
          //   onChangePage: handleChangePage,
          // }}
          columns={tableColumns}
          rows={data.map((item, index) => ({ ...item, id: index }))}
          alignText='center'
          sortEvent={setSortValues}
          serverSorting
          messageNoRow='Não há pagamentos.'
          // isSelectable={true}
          headerModel={modelPayments}
          setHeaderModel={setModelPayments}
        />
      )}
    </BillingControlContainer>
  )
}
