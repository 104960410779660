import React, { useEffect, useState } from 'react'
import { Modal } from '../..'
import { ButtonsContainer, CancelButton, SendButton } from './styles'
import { FormikInput } from '../../../FormikComponents/FormikInput'
import { Form, Formik } from 'formik'
import { ModalAddQuotaProps } from './types'
import FormikSelect from '../../../FormikComponents/FormikSelect'
import { Alert, CircularProgress, Snackbar } from '@mui/material'
import {
  consumerListGet,
  createQuota,
  editQuota,
  powerPlantListGet,
  ucsListConsumer,
} from '../../../../services/requests/user-requests'
import ModalSuccess from '../../ModalSuccess'
import { stringToNumber, yupSchema } from './utils'
import { monthsList, yearList } from '../../../../pages/Invoices/utils/selectData'
import { SearchInputFormik } from '../../../FormikComponents/SearchInput'

type dropDownConsumers = { id: number; cliente_nome: string; documento_numero: string }
type dropDownUCs = { id: number; nome: string; numero_uc: string }
type dropDownPowerPlants = { id: number; nome: string }

export default function ModalAddQuota({
  openModal,
  setModalOpen,
  editValues,
  postAction,
}: ModalAddQuotaProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [consumers, setConsumers] = useState<dropDownConsumers[]>([] as dropDownConsumers[])
  const [powerPlants, setPowerPlants] = useState<dropDownPowerPlants[]>([] as dropDownPowerPlants[])
  const [ucs, setUcs] = useState<dropDownUCs[]>([] as dropDownUCs[])
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    // reqs
  }, [])

  useEffect(() => {
    consumerListGet().then((res) => {
      if (res?.data) {
        const data = res.data.sort((a, b) => {
          if (a.cliente_nome < b.cliente_nome) {
            return -1
          }
          if (b.cliente_nome < a.cliente_nome) {
            return 1
          }
          return 0
        })
        setConsumers(data)
      }
    })

    powerPlantListGet().then((res) => {
      setPowerPlants(res?.data)
    })
  }, [])

  function handleCloseModal(value: boolean) {
    setModalSuccess(value)
    setOpacity(1)
    setModalOpen(value)
    postAction && postAction()
  }

  async function handleSubmit(values: {
    ucId: number
    powerPlantId: number
    year: string
    month: string
    quota: number
    consumerId: number
  }) {
    try {
      setIsLoading(true)

      const payload = {
        ucId: values.ucId,
        powerPlantId: values.powerPlantId,
        year: +values.year,
        month: +values.month,
        quota: stringToNumber(values.quota) / 100,
      }

      if (editValues) {
        await editQuota({ ...payload, id: editValues.id })
      } else {
        await createQuota(payload)
      }
      setModalOpen(false)
      setIsLoading(false)
      setOpacity(0)
      setModalOpen(true)
      setModalSuccess(true)
    } catch (err: any) {
      setIsLoading(false)
      setErrorMessage(err?.response?.data?.message ?? 'Erro ao cadastrar usina geradora.')
      setOpacity(1)
      setModalOpen(false)
      postAction && postAction()
    }
  }

  return (
    <>
      <Modal
        title={
          editValues
            ? `Editar Dados - UC ${editValues.ucNumber} ${editValues.ucName}`
            : 'Cadastrar Cota'
        }
        openModal={openModal}
        setModalOpen={setModalOpen}
        defaultButtons={false}
        opacity={opacity}
      >
        {modalSuccess && (
          <ModalSuccess
            openModal={modalSuccess}
            setModalOpen={handleCloseModal as any}
            buttonColor='darkGreen'
            buttonText='Ok, obrigado'
            text={`Cota ${editValues ? 'alterada' : 'cadastrada'} com sucesso!`}
          />
        )}
        <Formik
          initialValues={{
            consumerId: editValues?.consumerId || 0,
            ucId: editValues?.ucId || 0,
            powerPlantId: editValues?.usinaId || 0,
            year: editValues?.year || new Date().getFullYear().toString(),
            month: editValues?.month ?? '1',
            quota: editValues?.quota ? editValues.quota * 100 : 0,
          }}
          onSubmit={handleSubmit}
          validationSchema={yupSchema}
          validateOnBlur
          validateOnChange={false}
        >
          {({ errors, setFieldValue }) => {
            const fetchUcs = (value: number | null) => {
              if (value) {
                ucsListConsumer(value).then((res) => {
                  setUcs(res?.data)
                })
              } else {
                setUcs([])
              }
            }

            return (
              <Form>
                <SearchInputFormik
                  name='consumerId'
                  mode
                  required
                  label='Consumidor'
                  type='search'
                  placeholder='Buscar Consumidor'
                  data={consumers.map((item) => ({ label: item.cliente_nome, value: item.id }))}
                  setFieldValue={setFieldValue}
                  keyName=''
                  error={errors.consumerId}
                  messageError='Selecione um consumidor.'
                  onChange={(selectedItem) => {
                    setFieldValue('ucId', null)
                    fetchUcs(selectedItem?.value as number)
                  }}
                />
                <SearchInputFormik
                  name='ucId'
                  mode
                  required
                  label='Unidade Consumidora'
                  type='search'
                  placeholder='Buscar Unidade Consumidora'
                  data={ucs.map((item) => ({
                    label: item.numero_uc + ' - ' + item.nome,
                    value: item.id,
                  }))}
                  setFieldValue={setFieldValue}
                  keyName=''
                  error={errors.ucId}
                  messageError='Selecione uma Unidade Consumidora.'
                />
                <SearchInputFormik
                  name='powerPlantId'
                  mode
                  required
                  label='Usina Geradora'
                  type='search'
                  placeholder='Buscar Usina Geradora'
                  data={powerPlants.map((item) => ({ label: item.nome, value: item.id }))}
                  setFieldValue={setFieldValue}
                  keyName=''
                  error={errors.powerPlantId}
                  messageError='Selecione uma Usina Geradora.'
                />
                <SearchInputFormik
                  name='year'
                  mode
                  required
                  label='Ano'
                  type='search'
                  data={yearList()}
                  setFieldValue={setFieldValue}
                  keyName=''
                  error={errors.year}
                  messageError='Selecione um ano.'
                />
                <SearchInputFormik
                  name='month'
                  mode
                  required
                  label='Mês'
                  type='search'
                  data={monthsList}
                  setFieldValue={setFieldValue}
                  keyName=''
                  error={errors.month}
                  messageError='Selecione um mês.'
                />
                <FormikInput label='Cota (%)' mode required name='quota' />
                <ButtonsContainer>
                  <CancelButton
                    onClick={() => {
                      setModalOpen(false)
                    }}
                  >
                    Cancelar
                  </CancelButton>
                  <SendButton type='submit'>
                    {isLoading ? <CircularProgress size={20} /> : 'Enviar'}
                  </SendButton>
                </ButtonsContainer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </>
  )
}
