import { FormikProps } from 'formik'
import { consumerListGet } from '../../../../../../../services/requests/user-requests'
import { useEffect, useState } from 'react'
import { ConsumerGetListRequestType } from '../../../ModalRegisterConsumerUnit/types'
import { useToast } from '../../../../../../../hooks/useToast'
import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../../InputV2'
import { Search } from '@mui/icons-material'

interface Props {
  formik: FormikProps<any>
}

export default function ConsumerSelect(props: Props) {
  const { formik } = props

  const { errors, values, setFieldValue } = formik

  const [consumers, setConsumers] = useState<ConsumerGetListRequestType>([])
  const [selectedConsumerId, setSelectedConsumerId] = useState<number | null>(
    values.cliente_id_consumidor,
  )

  const { toast } = useToast()

  const fetchConsumers = async () => {
    try {
      const consumersData = await consumerListGet()
      setConsumers(consumersData.data)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar consumidores',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchConsumers()
  }, [])

  return (
    <Autocomplete
      id='cliente_id_consumidor'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          label='Titular da UC'
          error={!!errors[params.id]}
          {...params}
        />
      )}
      options={consumers.map((consumer) => consumer.id)}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? consumers.find((consumer) => consumer.id === option)?.cliente_nome ?? ''
          : String(option)
      }}
      isOptionEqualToValue={(option) => option === selectedConsumerId}
      value={selectedConsumerId}
      onChange={async (_, value) => {
        setSelectedConsumerId(value)
        setFieldValue('cliente_id_consumidor', value, true)
      }}
    />
  )
}
