export interface TicketCategory {
  label: string
  value?: string
  options?: {
    label: string
    value: string
  }[]
}

export const ticketCategories: TicketCategory[] = [
  {
    label: 'Pagamentos',
    options: [
      { label: 'Cancelamento de boleto', value: 'Cancelamento de boleto' },
      { label: 'Alteração da Data de Vencimento', value: 'Alteração da Data de Vencimento' },
      { label: 'Alteração Status Pagamento', value: 'Alteração Status Pagamento' },
      { label: 'Valore(s) Incorreto(s)', value: 'Valores Incorretos' },
      { label: 'Alteração de Valores', value: 'Alteração de Valores' },
    ],
  },
  {
    label: 'Fatura de Distribuição',
    options: [
      { label: 'Upload de fatura', value: 'Upload de fatura' },
      { label: 'Fatura com dados incorretos', value: 'Fatura com dados incorretos' },
      { label: 'Alterar anexo', value: 'Alterar anexo' },
      { label: 'Deletar fatura', value: 'Deletar fatura' },
      { label: 'Atraso na emissão de fatura', value: 'Atraso na emissao de fatura' },
    ],
  },
  {
    label: 'Balanço Mensal',
    options: [
      { label: 'Incluir campos', value: 'Incluir campos' },
      { label: 'Planilha com erro', value: 'Planilha com erro' },
    ],
  },
  {
    label: 'Saldo de Créditos',
    options: [
      { label: 'Corrigir saldo', value: 'Corrigir saldo' },
      { label: 'Incluir saldo', value: 'Incluir saldo' },
    ],
  },
  {
    label: 'Base Cadastral/Minhas Unidades',
    options: [
      { label: 'Cadastro de Usinas', value: 'Cadastro de Usinas' },
      { label: 'Cadastro de Consumidor/UCs', value: 'Cadastro de Consumidor/UCs' },
      { label: 'Atualizar Cadastro', value: 'Atualizar Cadastro' },
    ],
  },
  {
    label: 'Alocação de Créditos/Rateios',
    options: [
      { label: 'Ajuste de rateio', value: 'Ajuste de rateio' },
      {
        label: 'Inclusão/Exclusão de UCs da lista de rateio',
        value: 'Inclusao/Exclusão de UCs da lista de rateio',
      },
      { label: 'Cadastrar lista na plataforma', value: 'Cadastrar lista na plataforma' },
      { label: 'Protocolar lista de rateio', value: 'Protocolar lista de rateio' },
    ],
  },
  {
    label: 'Outros',
    value: 'Outros',
  },
]