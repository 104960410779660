import { useMemo } from 'react'
import { Conference } from '../../../../interfaces/conference'
import ConferenceChart from '../ConferenceChart'

const getChartData = (conferences: Conference[]) => {
  const data = [...conferences].sort(
    (r1, r2) => r2.invoicesTotalCount - r1.invoicesTotalCount
  )

  const categories = data.map((conference) => conference.entity.name)

  const chartData = [
    {
      name: 'Total de Faturas',
      data: data.map((conference) => conference.invoicesTotalCount),
    },
    {
      name: 'Não Baixadas',
      data: data.map((conference) => conference.invoicesNotDownloadedCount),
    },
    {
      name: 'Faturas Completas',
      data: data.map((conference) => conference.invoicesCompletedCount),
    },
  ]

  return {
    categories,
    chartData,
  }
}

interface Props {
  conferences: Conference[]
}

export default function InvoicesCountChart(props: Props) {
  const { conferences } = props

  const { categories, chartData } = useMemo(() => getChartData(conferences), [conferences])

  return (
    <ConferenceChart
      title='Total de Faturas e Não Baixadas'
      data={chartData}
      categories={categories}
    />
  )
}
