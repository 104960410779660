import styled from 'styled-components'
import { screenSizes } from '../../../styles/media'

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 350px;
  padding: 16px;
  background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  background-blend-mode: soft-light, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  max-width: 330px;

  @media (max-width: ${screenSizes.large}) {
      width: 100%;
      max-width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  z-index: 20;

  & > h3 {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.htmlGrey};
  }
`

export const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 10;
`

export const MarkerTolltip = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80%;
  right: 50%;
  transform: translateX(50%);
  width: max-content;
  background-color: ${({ theme }) => theme.colors.darkishGreen};
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;

  transition: 200ms;
  transition-delay: 500ms;
  transition-property: all;

  ::before {
    content: '';
    width: 10px;
    height: 10px;

    position: absolute;
    left: 50%;
    bottom: calc(100% - 5px);
    transform: translateX(-50%) rotate(135deg);

    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
    clip-path: polygon(0 0, 0% 100%, 100% 100%);

    background-color: inherit;
  }
`

interface IMarkerWrapperProps {
  lat: number
  lng: number
}

export const MarkerWrapper = styled.div<IMarkerWrapperProps>`
  width: fit-content;
  height: fit-content;
  transform: translate(-50%, -100%);
  position: relative;

  :hover {
    ${MarkerTolltip} {
      opacity: 1;
      top: calc(100% + 5px);
    }
  }
`

export const MapMarkerIcon = styled.img`
  width: 28px;
  height: auto;
`
