import styled from 'styled-components'
import { useContext } from 'react'
import { GlobalContext } from '../../../context/global/global'
import { Theme } from '../../../styles/theme'

const getColor = (props: any) => {
  const { Theme } = useContext(GlobalContext)

  if (props.isDragAccept) {
    return Theme.colors.darkishGreen
  }
  if (props.isDragReject) {
    return Theme.colors.darkishGreen
  }
  if (props.isFocused) {
    return Theme.colors.darkishGreen
  }
  return '#FFF'
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  hr {
    border: 1px dashed ${Theme.colors.darkGreen};
    opacity: 15%;
    
    width: 95%;
    margin: 12px auto;
  }
`

export const InputContainer = styled.div`
  margin-right: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-size: 1.25rem;
  }

  & > div > button {
    margin-right: auto;
    min-width: 280px;

    & > div:hover {
      background-color: ${Theme.colors.darkishGreen};
      color: ${Theme.colors.white}
    }
  }
`

export const FilesWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  gap: 10px;
`

export const Button = styled.button`
  background-color: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UploadContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 48px 20px;
  border-width: 2px;
  height: 680px;
  width: 100%;
  font-size: 20px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const FilesList = styled.div`
  padding-top: 20px;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`

export const FileContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  flex: 1;
  gap: 4px; 
`

interface IFileStatusMessageProps {
  bgColor: string;
  color: string;
}

export const FileStatusMessage = styled.div<IFileStatusMessageProps>`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const Return = styled.div`
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
`

export const Status = styled.div`
  font-size: 16px;
  color: red;
`

export const Infos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 45px;
`

export const HeaderButton = styled.button`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${Theme.colors.darkGreen};
  color: ${Theme.colors.white};
  font-size: 16px;

  &.orange {
    background-color: ${Theme.colors.orange};
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`
