const InvoiceStatusValues = ['Completas', 'Aguardando emissão', 'Não capturadas', 'Não obtidas']
type InvoiceStatusType = ['Completas', 'Aguardando emissão', 'Não capturadas', 'Não obtidas']
const InvoiceStatusConst = InvoiceStatusValues as InvoiceStatusType

export type InvoiceStatus = (typeof InvoiceStatusConst)[number]

export enum InvoiceStatusColor {
  'Completas' = 'darkishGreen',
  'Aguardando emissão' = 'lightGreen',
  'Não capturadas' = 'orange',
  'Não obtidas' = 'cream',
}

export interface InvoiceParser {
  id: string
  status: string
  originalFileName: string
  raw_data?: Record<string, unknown>;
  error?: string;
}

export interface InvoiceData {
  id: number
  chave: string
  versao_leitor: string
  drive_id: string
  uc_id: number
  cliente_id: number
  distribuidora_id: number
  mes_referencia: Date
  data_leitura_atual: Date
  data_leitura_anterior: Date
  data_emissao: Date
  data_apresentacao: Date
  data_proxima_leitura: Date
  nf_serie_num: string
  nf_serie_tipo: string
  codigo_barras: string
  data_vencimento: Date
  codigo_fisco: string
  periodo_fiscal: Date
  numero_ref_fat: string
  valor_total_fatura: number
  uc: string
  doc: string
  ie: string
  uf: string
  cidade: string
  endereco: string
  cep: string
  nome: string
  equipamento: string
  createdAt: Date
  updatedAt: Date
  origem_leitura: string
  dias_faturados: number
  fator_potencia: number
  perdas_transform: number
  med_cons_spt_ant: number
  med_cons_spt_atual: number
  med_cons_spt_const: number
  med_cons_spt_quant: number
  med_cons_p_atual: number
  med_cons_p_ant: number
  med_cons_p_const: number
  med_cons_p_quant: number
  med_cons_fp_atual: number
  med_cons_fp_ant: number
  med_cons_fp_const: number
  med_cons_fp_quant: number
  med_dem_spt_atual: number
  med_dem_spt_ant: number
  med_dem_spt_const: number
  med_dem_spt_quant: number
  med_dem_p_atual: number
  med_dem_p_ant: number
  med_dem_p_const: number
  med_dem_p_quant: number
  med_dem_fp_atual: number
  med_dem_fp_ant: number
  med_dem_fp_const: number
  med_dem_fp_quant: number
  med_ener_reat_exc_spt_atual: number
  med_ener_reat_exc_spt_ant: number
  med_ener_reat_exc_spt_const: number
  med_ener_reat_exc_spt_quant: number
  med_ener_reat_exc_p_atual: number
  med_ener_reat_exc_p_ant: number
  med_ener_reat_exc_p_const: number
  med_ener_reat_exc_p_quant: number
  med_ener_reat_exc_fp_atual: number
  med_ener_reat_exc_fp_ant: number
  med_ener_reat_exc_fp_const: number
  med_ener_reat_exc_fp_quant: number
  med_dem_reat_exc_p_atual: number
  med_dem_reat_exc_p_ant: number
  med_dem_reat_exc_p_const: number
  med_dem_reat_exc_p_quant: number
  med_dem_reat_exc_fp_atual: number
  med_dem_reat_exc_fp_ant: number
  med_dem_reat_exc_fp_const: number
  med_dem_reat_exc_fp_quant: number
  med_ger_spt_atual: number
  med_ger_spt_ant: number
  med_ger_spt_const: number
  med_ger_spt_quant: number
  med_ger_p_atual: number
  med_ger_p_ant: number
  med_ger_p_const: number
  med_ger_p_quant: number
  med_ger_fp_atual: number
  med_ger_fp_ant: number
  med_ger_fp_const: number
  med_ger_fp_quant: number
  med_ger_tot_atual: number
  med_ger_tot_ant: number
  med_ger_tot_const: number
  med_ger_tot_quant: number
  tensao_nominal_ou_contratada: string
  limites_adequados_tensao: string
  grupo: string
  subgrupo: string
  fases: string
  modalidade: string
  classe: string
  hist_cons_spt_ref_1: number
  hist_cons_spt_ref_2: number
  hist_cons_spt_ref_3: number
  hist_cons_spt_ref_4: number
  hist_cons_spt_ref_5: number
  hist_cons_spt_ref_6: number
  hist_cons_spt_ref_7: number
  hist_cons_spt_ref_8: number
  hist_cons_spt_ref_9: number
  hist_cons_spt_ref_10: number
  hist_cons_spt_ref_11: number
  hist_cons_spt_ref_12: number
  hist_cons_p_ref_1: number
  hist_cons_p_ref_2: number
  hist_cons_p_ref_3: number
  hist_cons_p_ref_4: number
  hist_cons_p_ref_5: number
  hist_cons_p_ref_6: number
  hist_cons_p_ref_7: number
  hist_cons_p_ref_8: number
  hist_cons_p_ref_9: number
  hist_cons_p_ref_10: number
  hist_cons_p_ref_11: number
  hist_cons_p_ref_12: number
  hist_cons_fp_ref_1: number
  hist_cons_fp_ref_2: number
  hist_cons_fp_ref_3: number
  hist_cons_fp_ref_4: number
  hist_cons_fp_ref_5: number
  hist_cons_fp_ref_6: number
  hist_cons_fp_ref_7: number
  hist_cons_fp_ref_8: number
  hist_cons_fp_ref_9: number
  hist_cons_fp_ref_10: number
  hist_cons_fp_ref_11: number
  hist_cons_fp_ref_12: number
  hist_cons_tot_ref_1: number
  hist_cons_tot_ref_2: number
  hist_cons_tot_ref_3: number
  hist_cons_tot_ref_4: number
  hist_cons_tot_ref_5: number
  hist_cons_tot_ref_6: number
  hist_cons_tot_ref_7: number
  hist_cons_tot_ref_8: number
  hist_cons_tot_ref_9: number
  hist_cons_tot_ref_10: number
  hist_cons_tot_ref_11: number
  hist_cons_tot_ref_12: number
  hist_dem_spt_ref_1: number
  hist_dem_spt_ref_2: number
  hist_dem_spt_ref_3: number
  hist_dem_spt_ref_4: number
  hist_dem_spt_ref_5: number
  hist_dem_spt_ref_6: number
  hist_dem_spt_ref_7: number
  hist_dem_spt_ref_8: number
  hist_dem_spt_ref_9: number
  hist_dem_spt_ref_10: number
  hist_dem_spt_ref_11: number
  hist_dem_spt_ref_12: number
  hist_dem_p_ref_1: number
  hist_dem_p_ref_2: number
  hist_dem_p_ref_3: number
  hist_dem_p_ref_4: number
  hist_dem_p_ref_5: number
  hist_dem_p_ref_6: number
  hist_dem_p_ref_7: number
  hist_dem_p_ref_8: number
  hist_dem_p_ref_9: number
  hist_dem_p_ref_10: number
  hist_dem_p_ref_11: number
  hist_dem_p_ref_12: number
  hist_dem_fp_ref_1: number
  hist_dem_fp_ref_2: number
  hist_dem_fp_ref_3: number
  hist_dem_fp_ref_4: number
  hist_dem_fp_ref_5: number
  hist_dem_fp_ref_6: number
  hist_dem_fp_ref_7: number
  hist_dem_fp_ref_8: number
  hist_dem_fp_ref_9: number
  hist_dem_fp_ref_10: number
  hist_dem_fp_ref_11: number
  hist_dem_fp_ref_12: number
  hist_dem_tot_ref_1: number
  hist_dem_tot_ref_2: number
  hist_dem_tot_ref_3: number
  hist_dem_tot_ref_4: number
  hist_dem_tot_ref_5: number
  hist_dem_tot_ref_6: number
  hist_dem_tot_ref_7: number
  hist_dem_tot_ref_8: number
  hist_dem_tot_ref_9: number
  hist_dem_tot_ref_10: number
  hist_dem_tot_ref_11: number
  hist_dem_tot_ref_12: number
  ener_reat_exc_spt_qt: number
  ener_reat_exc_spt_tar: number
  ener_reat_exc_spt_val: number
  ener_reat_exc_p_qt: number
  ener_reat_exc_p_tar: number
  ener_reat_exc_p_val: number
  ener_reat_exc_fp_qt: number
  ener_reat_exc_fp_tar: number
  ener_reat_exc_fp_val: number
  consumo_total_faturado_qt: number
  consumo_total_faturado_tar: number
  consumo_total_faturado_val: number
  consumo_spt_qt: number
  consumo_spt_tar: number
  consumo_spt_val: number
  consumo_p_qt: number
  consumo_p_tar: number
  consumo_p_val: number
  consumo_fp_qt: number
  consumo_fp_tar: number
  consumo_fp_val: number
  energia_comp_total_qt: number
  energia_comp_total_tar: number
  energia_comp_total_val: number
  energia_comp_spt_qt: number
  energia_comp_spt_tar: number
  energia_comp_spt_val: number
  energia_comp_p_qt: number
  energia_comp_p_tar: number
  energia_comp_p_val: number
  energia_comp_fp_qt: number
  energia_comp_fp_tar: number
  energia_comp_fp_val: number
  band_amar_val: number
  band_verm_p1_val: number
  band_verm_p2_val: number
  tot_band_val: number
  dev_band_amar_val: number
  dev_band_verm_p1_val: number
  dev_band_verm_p2_val: number
  tot_dev_band_val: number
  demanda_faturada_p_qt: number
  demanda_faturada_p_tar: number
  demanda_faturada_p_val: number
  demanda_faturada_fp_qt: number
  demanda_faturada_fp_tar: number
  demanda_faturada_fp_val: number
  demanda_faturada_spt_qt: number
  demanda_faturada_spt_tar: number
  demanda_faturada_spt_val: number
  demanda_fat_ultrap_p_qt: number
  demanda_fat_ultrap_p_tar: number
  demanda_fat_ultrap_p_val: number
  demanda_fat_ultrap_fp_qt: number
  demanda_fat_ultrap_fp_tar: number
  demanda_fat_ultrap_fp_val: number
  demanda_fat_ultrap_spt_qt: number
  demanda_fat_ultrap_spt_tar: number
  demanda_fat_ultrap_spt_val: number
  demanda_fat_ociosa_p_qt: number
  demanda_fat_ociosa_p_tar: number
  demanda_fat_ociosa_p_val: number
  demanda_fat_ociosa_fp_qt: number
  demanda_fat_ociosa_fp_tar: number
  demanda_fat_ociosa_fp_val: number
  demanda_fat_ociosa_spt_qt: number
  demanda_fat_ociosa_spt_tar: number
  demanda_fat_ociosa_spt_val: number
  demanda_fat_util_p_qt: number
  demanda_fat_util_p_tar: number
  demanda_fat_util_p_val: number
  demanda_fat_util_fp_qt: number
  demanda_fat_util_fp_tar: number
  demanda_fat_util_fp_val: number
  demanda_fat_util_spt_qt: number
  demanda_fat_util_spt_tar: number
  demanda_fat_util_spt_val: number
  demanda_contr_p_qt: number
  demanda_contr_fp_qt: number
  demanda_contr_spt_qt: number
  demanda_reat_exced_fat_p_qt: number
  demanda_reat_exced_fat_p_tar: number
  demanda_reat_exced_fat_p_val: number
  demanda_reat_exced_fat_fp_qt: number
  demanda_reat_exced_fat_fp_tar: number
  demanda_reat_exced_fat_fp_val: number
  demanda_reat_exced_fat_spt_qt: number
  demanda_reat_exced_fat_spt_tar: number
  demanda_reat_exced_fat_spt_val: number
  demanda_reserva_cap_p: number
  demanda_reserva_cap_fp: number
  cosip: number
  indevidos: number
  cp_distribuicao: number
  cp_setoriais: number
  cp_energia: number
  cp_transmissao: number
  cp_tributos: number
  cp_perdas: number
  tot_trib_icms_base: number
  tot_trib_icms_aliq: number
  tot_trib_icms_val: number
  tot_trib_pis_base: number
  tot_trib_pis_aliq: number
  tot_trib_pis_val: number
  tot_trib_cofins_base: number
  tot_trib_cofins_aliq: number
  tot_trib_cofins_val: number
  ic_local: string
  ic_mes_apuracao: Date
  ic_dic_padrao_mensal: number
  ic_dic_padrao_trimestral: number
  ic_dic_padrao_anual: number
  ic_dic_padrao_apurado: number
  ic_fic_padrao_mensal: number
  ic_fic_padrao_trimestral: number
  ic_fic_padrao_anual: number
  ic_fic_padrao_apurado: number
  ic_dmic_padrao_mensal: number
  ic_dmic_padrao_trimestral: number
  ic_dmic_padrao_anual: number
  ic_dmic_padrao_apurado: number
  ic_dicri_padrao_mensal: number
  ic_dicri_padrao_trimestral: number
  ic_dicri_padrao_anual: number
  ic_dicri_padrao_apurado: number
  ic_eusd_val: number
  ml_desconto_livre: number
  ml_subvencao_icms: number
  ml_taxa_medicao: number
  ajuste_faturamento: number
  ml_icms_devec: number
  ml_base_calc_icms_devec: number
  ml_base_calc_pis_devec: number
  creditos_estoque_p: number
  creditos_estoque_fp: number
  creditos_estoque_spt: number
  creditos_estoque_tot: number
  creditos_saldo_ger_p: number
  creditos_saldo_ger_fp: number
  creditos_saldo_ger_spt: number
  creditos_saldo_ger_tot: number
  consumo_spt_tar_crua: number
  consumo_spt_te_qt: number
  consumo_spt_te_tar: number
  consumo_spt_te_val: number
  consumo_spt_te_tar_crua: number
  consumo_spt_tusd_qt: number
  consumo_spt_tusd_tar: number
  consumo_spt_tusd_val: number
  consumo_spt_tusd_tar_crua: number
  consumo_p_tar_crua: number
  consumo_p_te_qt: number
  consumo_p_te_tar: number
  consumo_p_te_val: number
  consumo_p_te_tar_crua: number
  consumo_p_tusd_qt: number
  consumo_p_tusd_tar: number
  consumo_p_tusd_val: number
  consumo_p_tusd_tar_crua: number
  consumo_fp_tar_crua: number
  consumo_fp_te_qt: number
  consumo_fp_te_tar: number
  consumo_fp_te_val: number
  consumo_fp_te_tar_crua: number
  consumo_fp_tusd_qt: number
  consumo_fp_tusd_tar: number
  consumo_fp_tusd_val: number
  consumo_fp_tusd_tar_crua: number
  energia_comp_spt_tar_crua: number
  energia_comp_spt_te_qt: number
  energia_comp_spt_te_tar: number
  energia_comp_spt_te_val: number
  energia_comp_spt_te_tar_crua: number
  energia_comp_spt_tusd_qt: number
  energia_comp_spt_tusd_tar: number
  energia_comp_spt_tusd_val: number
  energia_comp_spt_tusd_tar_crua: number
  energia_comp_p_tar_crua: number
  energia_comp_p_te_qt: number
  energia_comp_p_te_tar: number
  energia_comp_p_te_val: number
  energia_comp_p_te_tar_crua: number
  energia_comp_p_tusd_qt: number
  energia_comp_p_tusd_tar: number
  energia_comp_p_tusd_val: number
  energia_comp_p_tusd_tar_crua: number
  energia_comp_fp_tar_crua: number
  energia_comp_fp_te_qt: number
  energia_comp_fp_te_tar: number
  energia_comp_fp_te_val: number
  energia_comp_fp_te_tar_crua: number
  energia_comp_fp_tusd_qt: number
  energia_comp_fp_tusd_tar: number
  energia_comp_fp_tusd_val: number
  energia_comp_fp_tusd_tar_crua: number
  devolucao_pis: number
  devolucao_cofins: number
  devolucao_cont_social: number
  devolucao_imposto_renda: number
  devolucao_cosip: number
  juros: number
  multa: number
  med_cons_int_ant: number
  med_cons_int_atual: number
  med_cons_int_const: number
  med_cons_int_quant: number
  med_ener_reat_exc_int_atual: number
  med_ener_reat_exc_int_ant: number
  med_ener_reat_exc_int_const: number
  med_ener_reat_exc_int_quant: number
  med_ger_int_atual: number
  med_ger_int_ant: number
  med_ger_int_const: number
  med_ger_int_quant: number
  ener_reat_exc_int_qt: number
  ener_reat_exc_int_tar: number
  ener_reat_exc_int_val: number
  consumo_int_qt: number
  consumo_int_tar: number
  consumo_int_val: number
  consumo_int_tar_crua: number
  energia_comp_int_qt: number
  energia_comp_int_tar: number
  energia_comp_int_val: number
  energia_comp_int_tar_crua: number
  hist_ger_spt_ref_1: number
  hist_ger_spt_ref_2: number
  hist_ger_spt_ref_3: number
  hist_ger_spt_ref_4: number
  hist_ger_spt_ref_5: number
  hist_ger_spt_ref_6: number
  hist_ger_spt_ref_7: number
  hist_ger_spt_ref_8: number
  hist_ger_spt_ref_9: number
  hist_ger_spt_ref_10: number
  hist_ger_spt_ref_11: number
  hist_ger_spt_ref_12: number
  hist_ger_tot_ref_1: number
  hist_ger_tot_ref_2: number
  hist_ger_tot_ref_3: number
  hist_ger_tot_ref_4: number
  hist_ger_tot_ref_5: number
  hist_ger_tot_ref_6: number
  hist_ger_tot_ref_7: number
  hist_ger_tot_ref_8: number
  hist_ger_tot_ref_9: number
  hist_ger_tot_ref_10: number
  hist_ger_tot_ref_11: number
  hist_ger_tot_ref_12: number
  demanda_geracao_fp_qt: number
  demanda_geracao_spt_qt: number
  consumo_s_icms_tar: number
  demanda_complementar_fp_val: number
  diversos_val: number
  consumo_fp_s_icms_tar: number
  consumo_p_s_icms_tar: number
  is_xml: boolean
  scraper_version: string
  devolucao_creditos: number
}

export interface InvoiceValidation {
  id: string
  fileId: string
  originalFileName: string | null
  invoiceKey?: string
  invoiceData?: Omit<
    InvoiceData,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'cliente_id'
    | 'distribuidora_id'
    | 'uc_id'
  >
  error?: string
}