import styled from 'styled-components'
import { screenSizes } from '../../styles/media'

export const WidgetContainer = styled.div`
  background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  background-blend-mode: soft-light, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: ${screenSizes.desktop}) {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

export const WidgetBody = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;

  @media (max-width: ${screenSizes.desktop}) {
    padding: 12px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`

export const IcontContainer = styled.div<{ color: string }>`
  background: #f9f9fd;
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @media (max-width: ${screenSizes.desktop}) {
    width: 44px;
    height: 44px;
  }

  svg {
    path {
      fill: ${({ color}) => color};
    }
  }
`

export const InfoTitle = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.htmlGrey};

  @media (max-width: ${screenSizes.large}) {
    font-size: 14px;
  }
`

export const InfoValue = styled.h1`
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.05em;

  @media (max-width: ${screenSizes.desktop}) {
    font-size: 26px;
  }
`

export const MeasurementText = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.05em;
  align-self: flex-end;
  padding-bottom: 10px;
  padding-left: 4px;
  color: #808080;
`

export const PercentageText = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: -0.05em;
  color: #4b4b4b;
  margin-left: 7px;
  margin-right: 7px;

  @media (max-width: ${screenSizes.desktop}) {
    font-size: 14px;
  }
`

export const InRelationToText = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.05em;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #808080;

  @media (max-width: ${screenSizes.desktop}) {
    font-size: 14px;
  }
  @media (max-width: ${screenSizes.large}) {
    font-size: 12px;
  }
`
