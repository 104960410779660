import styled from 'styled-components'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export const Label = styled.h4`
  color: white;
  margin-bottom: 8px;
  padding-left: 15px;
`

export const InputField = styled.input`
  background: rgba(0, 0, 0, 0.21);
  border-radius: 8px;
  border: none;
  padding-left: 15px;
  padding-right: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  height: 45px;
  width: 436px;

::-ms-reveal {
    display: none !important;
}
`

export const Icon = styled(VisibilityIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`

export const IconHiden = styled(VisibilityOffIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`
export const InputDiv = styled.div`
  position: relative;
`
