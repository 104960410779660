import { useEffect, useState } from 'react'
import { distributorRegisterGet } from '../../../../../../../services/requests/user-requests'
import { useToast } from '../../../../../../../hooks/useToast'
import { DistribuitorsListRequestType } from '../../types'
import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../../InputV2'
import { Search } from '@mui/icons-material'
import { FormikProps } from 'formik'

interface Props {
  formik: FormikProps<any>
}

export default function DistributorFields(props: Props) {
  const { formik } = props

  const { errors, values, setFieldValue } = formik

  const [distributors, setDistributors] = useState<DistribuitorsListRequestType>([])

  const [selectedDistributorId, setSelectedDistributorId] = useState<number | null>(
    values.distribuidora_id ?? null,
  )

  const { toast } = useToast()

  const fetchDistributors = async () => {
    try {
      const distributorsData = await distributorRegisterGet()
      const payload = distributorsData.data.map((distributor: { id: number; nome: string }) => {
        return {
          label: distributor.nome,
          value: distributor.id,
        }
      })
      setDistributors(payload)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar distribuidoras',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  useEffect(() => {
    setSelectedDistributorId(values.distribuidora_id)
  }, [values.distribuidora_id])

  return (
    <>
      <Autocomplete
        id='distribuidora_id'
        ListboxProps={{
          style: {
            maxHeight: '250px',
          },
        }}
        renderInput={(params) => (
          <InputV2
            rightIcon={<Search />}
            label='Distribuidora'
            required
            error={!!errors[params.id]}
            {...params}
          />
        )}
        options={distributors.map((distributor) => distributor.value)}
        getOptionLabel={(option) => {
          const optionNumber = Number(option)
          return !isNaN(optionNumber)
            ? distributors.find((distributor) => distributor.value === option)?.label ?? ''
            : String(option)
        }}
        isOptionEqualToValue={(option) => option === selectedDistributorId}
        value={selectedDistributorId}
        onChange={(_, value) => {
          setFieldValue('distribuidora_id', value)
          setSelectedDistributorId(value)
        }}
      />
      <InputV2 id='login' label='Login da distribuidora' />
      <InputV2 id='senha_dist' label='Senha da Distribuidora' />
    </>
  )
}
