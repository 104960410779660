import logoBB from '../../assets/bb-logo.svg'
import mapIconBB from '../../assets/map-icon-bb.svg'
import mapSecondaryIconBB from '../../assets/map-icon-bb-secondary.svg'
import logoVE from '../../assets/logo-via-energy.svg'
import iconVE from '../../assets/logo-via-energy-icon.svg'
import mapIconVE from '../../assets/via-energy-map-icon.svg'
import logoLight from '../../assets/light-logo.svg'
import iconLight from '../../assets/light-icon.svg'
import iconAvant from '../../assets/icons/avant.svg'
import logoAvant from '../../assets/logos/avant.svg'
import logoKelfor from '../../assets/logos/kelfor.svg'
import iconKelfor from '../../assets/icons/kelfor.svg'
import mapIconLight from '../../assets/light-map-icon.svg'
import mapSecondaryIconLight from '../../assets/light-map-secondary-icon.svg'
import logoAtuaEnergia from '../../assets/atua-energia-logo.svg'
import logoAtuaIcon from '../../assets/atua-energia-icon.svg'
import logoIpper from '../../assets/ipper.svg'
import logoIpperIcon from '../../assets/ipperIcon.svg'
import logoGdSolar from '../../assets/gd-solar-logo.svg'
import iconGdSolar from '../../assets/gd-solar-icon.svg'
import logoSerra from '../../assets/serra.svg'
import iconSerra from '../../assets/serra-icon.svg'
import mapIconGdSolar from '../../assets/gd-solar-map-icon.svg'
import mapIconSecondaryGdSolar from '../../assets/gd-solar-map-icon-secondary.svg'
import iconVoitz from '../../assets/voitz-icon.svg'
import mapIconVoitz from '../../assets/voitz-map-icon.svg'
import mapIconSecondaryVoitz from '../../assets/voitz-map-icon-secondary.svg'
import mapIconSecondary from '../../assets/google-default-marker.svg'

const colorsGradient = [
  '#A2B6AD',
  '#A6E0C6',
  '#90C3AC',
  '#A2B6AD',
  '#6E549D',
  '#7649C8',
  '#7649C8',
  '#7430F2',
  '#5C5073',
]

function getGradientString(colors: string[]): string {
  return `linear-gradient(to bottom, ${colors
    .map((color, index) => `${color} ${(index * 100) / (colors.length - 1)}%`)
    .join(',')})`
}

const bancoDoBrasil = {
  for: [215],
  theme: {
    colors: {
      darkGreen: '#101936',
      darkishGreen: '#273d6a',
      green: '#5d7aeb',
      DarkSpringGreen: '#3c57c3',
      SeaGreen: '#5173fe',
      ShinyShamrock: '#0c39ec',
      appleGreen: '#375EFC',
      lightGreen: '#37aec0',
      brightGreen: '#04DCFC',
      orange: '#F8F809',
      sideBarText: '#F8F809',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: logoBB.toString(),
      tabTitle: 'Banco do Brasil',
      icon: logoBB.toString(),
      size: '45%',
      mapIconDefault: mapIconBB.toString(),
      mapIconSecondary: mapSecondaryIconBB.toString(),
    },
  },
}

const ViaEnergy = {
  for: [],
  theme: {
    colors: {
      darkGreen: '#14203d',
      darkishGreen: '#273d6a',
      green: '#2259d0',
      DarkSpringGreen: '#3c57c3',
      SeaGreen: '#5173fe',
      ShinyShamrock: '#0c39ec',
      appleGreen: '#375EFC',
      lightGreen: '#F5CC5A',
      brightGreen: '#2583ef',
      orange: '#1fde68',
      sideBarText: '#fff',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: logoVE.toString(),
      tabTitle: 'Via energy - gestão',
      icon: iconVE.toString(),
      size: '80%',
      mapIconDefault: mapIconVE.toString(),
    },
  },
}

const Light = {
  for: [],
  theme: {
    colors: {
      darkGreen: '#106550',
      darkishGreen: '#23846C',
      green: '#0DB9B1',
      DarkSpringGreen: '#50cad5',
      SeaGreen: '#2f949d',
      ShinyShamrock: '#006f93',
      appleGreen: '#20e0f1',
      lightGreen: '#F59F04',
      brightGreen: '#FFC334',
      orange: '#DA5222',
      sideBarText: '#fff',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: logoLight.toString(),
      tabTitle: 'Light - gestão',
      icon: iconLight.toString(),
      size: '90%',
      mapIconDefault: mapIconLight.toString(),
      mapIconSecondary: mapSecondaryIconLight.toString(),
    },
  },
}

const Avant = {
  for: [3325],
  theme: {
    colors: {
      darkGreen: '#166C9B',
      darkishGreen: '#166C9B',
      ShinyShamrock: '#F28B0C',
      appleGreen: '#F2620C',
      SeaGreen: '#F2AF5D',
      orange: '#F2B35A',
      DarkSpringGreen: '#73543E',
      green: '#F28B0C',
      lightGreen: '#F28B0C',
      brightGreen: '#F28B0C',
      sideBarText: '#fff',
      cream: '#B88845',
    },
    logos: {
      fullLogo: logoAvant.toString(),
      tabTitle: 'Avant',
      icon: iconAvant.toString(),
      size: '90%',
      mapIconDefault: null,
      mapIconSecondary: mapIconSecondary.toString(),
    },
  },
}

const SerraAmolar = {
  for: [5577],
  theme: {
    colors: {
      darkGreen: '#0E4366',
      darkishGreen: '#71A7BF',
      ShinyShamrock: '#3266BF',
      appleGreen: '#112633',
      SeaGreen: '#3239BF',
      orange: '#0E4366',
      DarkSpringGreen: '#304040',
      green: '#303640',
      lightGreen: '#C2E7FF',
      brightGreen: '#DBF1FF',
      sideBarText: '#fff',
      cream: '#406373',
    },
    logos: {
      fullLogo: logoSerra.toString(),
      tabTitle: 'Serra do Amolar',
      icon: iconSerra.toString(),
      size: '120%',
      mapIconDefault: null,
      mapIconSecondary: mapIconSecondary.toString(),
    },
  },
}

const OesteEnergia = {
  for: [3680],
  theme: {
    colors: {
      darkGreen: getGradientString(colorsGradient),
      darkishGreen: '#5925B9',
      ShinyShamrock: '#A6E0C6',
      appleGreen: '#A6E0C6',
      SeaGreen: '#7430F2',
      orange: '#A6E0C6',
      DarkSpringGreen: '#7053A6',
      green: '#90C3AC',
      lightGreen: '#709484',
      brightGreen: '#A6E0C6',
      sideBarText: '#FFFF',
      cream: '#5C5073',
    },

    logos: {
      fullLogo: logoKelfor.toString(),
      tabTitle: 'Kelfor',
      icon: iconKelfor.toString(),
      size: '200%',
      mapIconDefault: null,
      mapIconSecondary: mapIconSecondary.toString(),
    },
  },
}

const AtuaEnergia = {
  for: [1540],
  theme: {
    colors: {
      darkGreen: '#3D4543',
      darkishGreen: '#5b6865',
      green: '#ffb95e',
      DarkSpringGreen: '#ffd865',
      SeaGreen: '#d9994b',
      ShinyShamrock: '#8b4300',
      appleGreen: '#e98514',
      lightGreen: '#ff8d31',
      brightGreen: '#F9A232',
      orange: '#E94E1B',
      sideBarText: '#fff',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: logoAtuaEnergia.toString(),
      tabTitle: 'Atua Energia - gestão',
      icon: logoAtuaIcon.toString(),
      size: '90%',
    },
  },
}

const Ipper = {
  for: [5546],
  theme: {
    colors: {
      darkGreen: '#FF4D01',
      darkishGreen: '#FFA755',
      green: '#C65A2C',
      DarkSpringGreen: '#AA5B39',
      SeaGreen: '#8E573F',
      ShinyShamrock: '#714E3F',
      appleGreen: '#e98514',
      lightGreen: '#E38A64',
      brightGreen: '#FFB570',
      orange: '#FF7900',
      sideBarText: '#fff',
      cream: '#FF4C01',
    },
    logos: {
      fullLogo: logoIpper.toString(),
      tabTitle: 'Ipper - gestão',
      icon: logoIpperIcon.toString(),
      mapIconDefault: null,
      mapIconSecondary: mapIconSecondary.toString(),
      size: '90%',
    },
  },
}

const AtuaEnergiaConsumers = {
  for: [1619, 3137],
  theme: {
    colors: {
      darkGreen: '#3D4543',
      darkishGreen: '#5b6865',
      green: '#ffb95e',
      DarkSpringGreen: '#ffd865',
      SeaGreen: '#d9994b',
      ShinyShamrock: '#8b4300',
      appleGreen: '#e98514',
      lightGreen: '#ff8d31',
      brightGreen: '#F9A232',
      orange: '#E94E1B',
      sideBarText: '#fff',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: logoAtuaEnergia.toString(),
      tabTitle: 'Atua Energia',
      icon: logoAtuaIcon.toString(),
      size: '90%',
    },
  },
}

const GDSolar = {
  for: [],
  theme: {
    colors: {
      darkGreen: '#1b2936',
      darkishGreen: '#35475a',
      ShinyShamrock: '#143634',
      appleGreen: '#0a7574',
      SeaGreen: '#369B95',
      orange: '#009c9a',
      DarkSpringGreen: '#52c8c2',
      green: '#68c1c8',
      lightGreen: '#51b3d4',
      brightGreen: '#49c4ef',
      sideBarText: '#fff',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: logoGdSolar.toString(),
      tabTitle: 'GD Solar - gestão',
      icon: iconGdSolar.toString(),
      size: '100%',
      mapIconDefault: mapIconGdSolar.toString(),
      mapIconSecondary: mapIconSecondaryGdSolar.toString(),
    },
  },
}

const Voitz = {
  for: [1252],
  theme: {
    colors: {
      darkGreen: '#5D614F',
      darkishGreen: '#737865',
      ShinyShamrock: '#045848',
      appleGreen: '#2BAC94',
      SeaGreen: '#5DBCA7',
      orange: '#A3D0B8',
      DarkSpringGreen: '#E0EDCE',
      green: '#e4d8a3',
      lightGreen: '#c1c588',
      brightGreen: '#D4DB79',
      sideBarText: '#fff',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: iconVoitz.toString(),
      tabTitle: 'Voitz - gestão',
      icon: iconVoitz.toString(),
      size: '50%',
      mapIconDefault: mapIconVoitz.toString(),
      mapIconSecondary: mapIconSecondaryVoitz.toString(),
    },
  },
}

const VoitzConsumer = {
  for: [3627],
  theme: {
    colors: {
      darkGreen: '#5D614F',
      darkishGreen: '#737865',
      ShinyShamrock: '#045848',
      appleGreen: '#2BAC94',
      SeaGreen: '#5DBCA7',
      orange: '#A3D0B8',
      DarkSpringGreen: '#E0EDCE',
      green: '#e4d8a3',
      lightGreen: '#c1c588',
      brightGreen: '#D4DB79',
      sideBarText: '#fff',
      cream: '#FC7A6C',
    },
    logos: {
      fullLogo: iconVoitz.toString(),
      tabTitle: 'Voitz - gestão',
      icon: iconVoitz.toString(),
      size: '50%',
      mapIconDefault: mapIconVoitz.toString(),
      mapIconSecondary: mapIconSecondaryVoitz.toString(),
    },
  },
}

export const themes = [
  bancoDoBrasil,
  ViaEnergy,
  Light,
  AtuaEnergia,
  AtuaEnergiaConsumers,
  GDSolar,
  Voitz,
  Avant,
  VoitzConsumer,
  OesteEnergia,
  Ipper,
  SerraAmolar
]
