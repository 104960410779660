/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldAttributes, useField } from 'formik'
import Tooltip from '../../Tooltip'
import Search from '../../../assets/icons/searchIcon.svg'
import { ErrorMessage, SelectInputFormikContainer, InputSearch } from './styles'

import TextField from '@mui/material/TextField'
import { Label } from '../../Formik/FormikInput/styles'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'

const useStyles = makeStyles({
  paper: {
    boxShadow:
      '-1.66667px -1.66667px 5px #FFFFFF, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2)',
  },
})

type InputData = { label: string; value: number | string }

interface SearchInputFormik extends FieldAttributes<any> {
  data: InputData[]
  name: string
  label?: string
  type?: 'email' | 'password' | 'search' | 'url'
  mode?: boolean
  tooltip?: boolean
  required?: boolean
  placeholder?: string
  hasError?: string
  messageError?: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  maxWidth?: string
  margin?: string
  onChange?: (value: InputData | null) => void
}

export function SearchInputFormik({
  data,
  hasError,
  required,
  label,
  mode,
  tooltip,
  placeholder,
  maxWidth,
  margin,
  onChange,
  ...rest
}: SearchInputFormik) {
  const [field] = useField(rest)
  const [valueInput, setValueInput] = useState('')

  const [dataResult, setDataResult] = useState<string[]>(data.map((item) => item.label))

  function handleSelectItem(selectedItem: InputData | null) {
    if (selectedItem) {
      rest.setFieldValue(field.name, selectedItem?.value ?? null)
      onChange && onChange(selectedItem)
    }
  }

  useEffect(() => {
    console.log(1)
    setDataResult(data.map((item) => item.label))
  }, [data])

  useEffect(() => {
    console.log(2)
    const noRepeatData: string[] = []
    for (const item of data.map((item) => item.label)) {
      if (!noRepeatData.includes(item)) {
        noRepeatData.push(item)
      }
    }

    if (!valueInput) {
      setDataResult(data.map((item) => item.label))
    } else {
      setDataResult(
        noRepeatData.filter((value) =>
          value
            .toLowerCase()
            .replaceAll(' ', '')
            .includes(valueInput.toLowerCase().replaceAll(' ', '')),
        ),
      )
    }
  }, [valueInput])

  const classes = useStyles()

  return (
    <SelectInputFormikContainer maxWidth={maxWidth} margin={margin}>
      {!!label && (
        <div style={{ display: 'flex', gap: 8 }}>
          <Label mode={Boolean(mode)} required={required} htmlFor={rest.id || rest.name}>
            {label}
          </Label>
          {tooltip && <Tooltip title='Random message!' />}
        </div>
      )}
      <InputSearch
        disablePortal
        options={dataResult}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <div style={{ display: 'flex' }}>
            <img src={`${Search}`} style={{ position: 'absolute', top: 45, left: 16 }} />
            <TextField placeholder={placeholder} {...params} />
          </div>
        )}
        renderOption={(props, option) => (
          <li style={{ paddingTop: 8, paddingBottom: 8, fontSize: 16 }} {...props}>
            {option as string}
          </li>
        )}
        onInputChange={(_, value, reason) => {
          if (['clear'].includes(reason)) {
            handleSelectItem(null)
          } else {
            setValueInput(value)
            const selectedValue = data?.find((item) => item.label === value) ?? null
            handleSelectItem(selectedValue)
          }
        }}
        value={data.find((item) => item.value === field.value)?.label || null}
        noOptionsText='Nenhuma opção selecionada'
        classes={{ paper: classes.paper }}
      />
      {hasError && <ErrorMessage>{hasError}</ErrorMessage>}
    </SelectInputFormikContainer>
  )
}
