import styled from 'styled-components'
import { darken } from 'polished'

type ActionTableButtonContainerProps = {
  hasTitle?: boolean
}

export const ActionTableButtonContainer = styled.button<ActionTableButtonContainerProps>`
  padding: 0 7px;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5625rem;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.white};
  font-size: 0.75rem;
  border: none;

  background: ${(props) => props.theme.colors.darkishGreen};

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    background: ${(props) => props.theme.colors.darkishGreen};
  }

  > svg {
    width: 0.97375rem;
    height: 1rem;
  }
`
