export function convertObjectToQueryParams(obj: Record<string, unknown>, separator = ','): string | undefined {
  const entries = Object.entries(obj)

  return entries.length > 0
    ? entries.map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`).join(separator)
    : undefined
}

export function getPageInfo<T = unknown>(currentPage: number, itemsPerPage: number, data: T[]) {
  const totalCount = data.length
  const totalPages = Math.ceil(totalCount / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const slicedData = data.slice(startIndex, startIndex + itemsPerPage)
  return {
    totalCount,
    totalPages,
    itemsPerPage,
    data: slicedData,
  }
}