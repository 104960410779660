import ModalTitle from './Title'
import ModalCloseButton from './CloseButton'
import ModalBody from './Body'
import ModalContainer from './Container'

const Modal = {
  Provider: ModalContainer,
  Title: ModalTitle,
  Body: ModalBody,
  CloseButton: ModalCloseButton,
}

export default Modal
