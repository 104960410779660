import { useEffect, useState } from 'react'
import { invoiceErrorsService } from '../../../../../services/invoice-errors'
import { useToast } from '../../../../../hooks/useToast'
import Modal from '../../../../../components/ModalV2'
import {
  CancelButton,
  FileContainer,
  FilesList,
  FileStatusMessage,
  SendButton,
  Wrapper,
} from './styles'
import { DescriptionRounded } from '@mui/icons-material'
import { Theme } from '../../../../../styles/theme'
import { StickyFooter } from '../../../InvoiceValidation/components/InvoiceModal/styles'
import Loading from '../../../../../components/Loading'
import ModalSuccess from '../../../../../components/Modal/ModalSuccess'

interface StatusFile {
  fileId: string
  fileName: string
  code: number
  message: string
}

interface Props {
  readerErrorId: number
  onSuccess: () => void
  onClose: () => void
}

export default function ReanalyzeReaderErrorFilesModal(props: Props) {
  const { readerErrorId, onSuccess, onClose } = props

  const [isLoading, setLoading] = useState<boolean>(false)
  const [statusFiles, setStatusFiles] = useState<StatusFile[]>([])
  const [hasCompletedAllFiles, setHasCompletedAllFiles] = useState<boolean>(false)

  const { toast } = useToast()

  const fetchReaderError = async () => {
    if (isLoading) {
      return
    }
    try {
      setLoading(true)
      const readerErrorResponse = await invoiceErrorsService.find({
        readerErrorId,
      })
      setStatusFiles(
        readerErrorResponse.files
          .filter((file) => !file.deleted_at)
          .map((file) => ({
            fileId: file.id,
            code: 0,
            message: '',
            fileName: file.name,
          })),
      )
    } catch (error: any) {
      toast({
        message: error.message ?? 'Erro ao buscar erro de leitor',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const reanalyzeReaderError = async () => {
    try {
      setLoading(true)
      const response = await invoiceErrorsService.reanalyzeReaderError({
        readerErrorId,
      })
      if (response.filter((file) => file.status !== 'SUCCESS').length === 0) {
        setHasCompletedAllFiles(true)
      }
      setStatusFiles(
        response.map((data: any) => {
          if (data.status === 'SUCCESS') {
            return { fileId: data.fileId, fileName: data.fileName, code: 3, message: 'Sucesso' }
          }
          if (data.status === 'INFO') {
            return { fileId: data.fileId, fileName: data.fileName, code: 2, message: data.message }
          }
          return { fileId: data.fileId, fileName: data.fileName, code: 4, message: data.message }
        }),
      )
    } catch (error: any) {
      toast({
        message: error?.message ?? 'Erro ao listar faturas com erro',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReaderError()
  }, [])

  const files = statusFiles.map((fileInfo) => (
    <Wrapper key={`status-file-${fileInfo.fileId}`}>
      <FileContainer>
        <DescriptionRounded />
        <span>{fileInfo.fileName}</span>
      </FileContainer>
      {(() => {
        const statusFileFound = statusFiles.find(
          (statusFile) => statusFile.fileId === fileInfo.fileId,
        )
        switch (statusFileFound?.code) {
          case 2:
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.orange}>
                {statusFileFound.message}
              </FileStatusMessage>
            )
          case 3:
            return (
              <FileStatusMessage color={Theme.colors.white} bgColor={Theme.colors.darkishGreen}>
                {statusFileFound.message}
              </FileStatusMessage>
            )
          case 4: {
            const message = statusFileFound.message
            const isPending = message === 'Fatura em analise'
            return (
              <FileStatusMessage
                color={Theme.colors.white}
                bgColor={isPending ? Theme.colors.green : Theme.colors.suportError}
              >
                {statusFileFound.message}
              </FileStatusMessage>
            )
          }
          default:
            return null
        }
      })()}
    </Wrapper>
  ))

  if (hasCompletedAllFiles) {
    return (
      <ModalSuccess
        text='Todas as faturas foram lidas com sucesso'
        buttonText='Ok, obrigado'
        buttonColor='darkGreen'
        openModal
        setModalOpen={() => {
          onSuccess()
          onClose()
        }}
      />
    )
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '450px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Releitura de Faturas
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <Modal.Body
            style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%' }}
          >
            <FilesList>{files}</FilesList>
          </Modal.Body>
          <StickyFooter style={{ gap: '16px', padding: '16px' }}>
            <CancelButton style={{ width: '100%' }} onClick={onClose}>
              Fechar
            </CancelButton>
            <SendButton style={{ width: '100%' }} onClick={reanalyzeReaderError}>
              Releitura
            </SendButton>
          </StickyFooter>
        </>
      )}
    </Modal.Provider>
  )
}
