import styled from "@emotion/styled";
import { CancelButton } from "../../styles";
import { Theme } from "../../../../../../styles/theme";
import { darken } from "polished";

export const ContinueButton = styled(CancelButton)`
  width: 152px;
  background: ${Theme.colors.darkishGreen};
  margin-left: auto;

  &:hover {
    background-color: ${darken(0.1, Theme.colors.darkishGreen)};
    cursor: pointer;
  }
`