import { ButtonsPowerPlantContainer, ConfigButtonsColumn, TableContainer } from './style'

import { AddOutlined, SettingsOutlined } from '@mui/icons-material'
import { Alert, Snackbar } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import DownloadIcon from '../../../../assets/download-icon-white.svg'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import Loading from '../../../../components/Loading'
import ModalStatus from '../../../../components/Modal/ModalStatus'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
import ModalRegisterConsumerUnit from '../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumerUnit'
import { SearchInput } from '../../../../components/SearchInput'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import { CheckboxType } from '../../../../interfaces/checkbox'
import {
  consumerListGet,
  distributorListGet,
  myUnitsUCs,
  powerPlantListGet,
  ucsDownload,
  updateStatusUC,
} from '../../../../services/requests/user-requests'
import dateFormatForRequest from '../../../../utils/dateFormatForRequest'
import { DownloadDiv } from '../../../Credits/Allocation/styles'
import { IMyUnitsPageProps } from '../types'
import { usePermissions } from '../../../../hooks/usePermissions'
import { PermissionType } from '../../../../interfaces/permissions'
import { getConsumerUnitsColumns } from './utils'
import { TableV2 } from '../../../../components/TableV2'
import { TablePagination } from '../../../../components/TableV2/components/TablePagination'
import { Sort } from '../../../../interfaces/conference'
import { CustomersType, DistributorsType, myUnitsUCsType, PowerPlantsType } from './types'

export function ConsumerUnit() {
  const { checkPermission } = usePermissions()
  const { mes_ref } = useOutletContext<IMyUnitsPageProps>()

  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [totalCountUCs, setTotalCountUCs] = useState(0)
  const [ucs, setUcs] = useState<myUnitsUCsType[]>([])
  const [isSuccessOpen, setIsSuccessOpen] = useState(false)
  const [isStatusOpen, setIsStatusOpen] = useState(false)
  const [successMessage] = useState('Unidade consumidora excluída com sucesso!')
  const [statusMessage] = useState('Status de contrato alterado com sucesso!')
  const [selectedIds, setSelectedIds] = useState<number[]>([0])
  const [loading, setLoading] = useState(false)
  const [selectedUCs, setSelectedUCs] = useState<myUnitsUCsType>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [ready, setReady] = useState(false)
  const [sortValues, setSortValues] = useState<Sort>()
  const [customerFilter, setCustomerFilter] = useState<CheckboxType<CustomersType>[]>([])
  const [powerPlantFilter, setPowerPlantFilter] = useState<CheckboxType<PowerPlantsType>[]>([])
  const [distributorsFilter, setDistributorsFilter] = useState<CheckboxType<DistributorsType>[]>([])
  const [firstRender, setFirstRender] = useState(true)
  const [clickFilter, setClickFilter] = useState(false)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)

  const [selectedUcId, setSelectedUcId] = useState<number>()

  useEffect(() => {
    if (search !== undefined) {
      const delayDebounce = setTimeout(() => {
        if (search.length >= 3 || search.length === 0) {
          setPage(1)
          setReady(!ready)
        }
      }, 1000)

      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  function handleChangePage(page: number) {
    setPage(page)
  }

  const getData = async () => {
    setLoading(true)

    const myUnitsResponse = await myUnitsUCs({
      page,
      filter: search,
      limit: pageItemsAmount,
      order: sortValues?.order?.toUpperCase() ?? 'ASC',
      sort: sortValues?.field ?? 'nome',
      customersId: customerFilter
        .filter((item) => item.checked)
        .map((item) => item.payload?.id as number),
      powerPlantId: powerPlantFilter
        .filter((item) => item.checked)
        .map((item) => item.payload?.id as number),
      distributorsId: distributorsFilter
        ?.filter((item) => item.checked)
        ?.map(item => item?.id as number),
      mes_ref: mes_ref || dateFormatForRequest(new Date()),
    })

    myUnitsResponse.data?.meta?.total && setTotalCountUCs(myUnitsResponse.data.meta.total)

    myUnitsResponse.data &&
      setUcs(
        myUnitsResponse.data.ucs.map((uc) => ({
          ...uc,
          usina_geradora_nome: uc.usinas.map((usina) => usina.nome).join(', '),
        })),
      )
    setLoading(false)
    setFirstRender(false)
  }

  useEffect(() => {
    getData()
  }, [page, ready, sortValues, customerFilter, powerPlantFilter, distributorsFilter, pageItemsAmount, mes_ref])

  useEffect(() => {
    Promise.all([consumerListGet(), powerPlantListGet(), distributorListGet()])
      .then(async ([consumerData, powerPlantData, distributorData]) => {
        if (firstRender) {
          const consumerResponse = consumerData
          const powerPlantsResponse = powerPlantData
          const distributorResponse = distributorData

          setCustomerFilter(
            consumerResponse.data.map((item) => ({
              id: Math.random(),
              label: item.cliente_nome,
              payload: item,
              checked: false,
            })),
          )

          setPowerPlantFilter(
            powerPlantsResponse.data.map((item: { id: any; nome: any }) => ({
              id: Math.random(),
              label: item.nome,
              payload: item,
              checked: false,
            })),
          )

          setDistributorsFilter(
            distributorResponse.data.map((item: { id: number; nome: string }) => ({
              id: item.id,
              label: item.nome,
              checked: true,
            })),
          )
        } else if (!firstRender && clickFilter) {
          getData()
          setClickFilter(false)
        }
      })
      .catch((error) => {
        setErrorMessage(error.message ?? 'Erro ao atualizar dados de Unidades Consumidoras.')
      })
  }, [])

  const handleDownloadButton = async () => {
    await ucsDownload({
      filter: search,
      sort: sortValues?.field,
      order: sortValues?.order?.toUpperCase(),
      customersId: customerFilter
        .filter((item) => item.checked)
        .map((item) => item.payload?.id as number),
      powerPlantId: powerPlantFilter
        .filter((item) => item.checked)
        .map((item) => item.payload?.id as number),
      mesRef: mes_ref || dateFormatForRequest(new Date()),
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `ucs_${moment().format('DD_MM_YYYY')}.xlsx`

        document.body.appendChild(element)
        element.click()
      })
      .catch((err) => console.log(err))
  }

  const handleStatus = async (ucId: number) => {
    const uc = ucs.find((item) => item.id === ucId)

    if (!uc) return

    setSelectedUCs(uc)

    try {
      const response = await updateStatusUC(uc.id)

      const contractStatus = response.data.contractStatus

      setUcs((prevUcs) => {
        return prevUcs.map((prevUc) => {
          if (prevUc.id === uc.id) {
            return {
              ...prevUc,
              status_ativacao_uc: contractStatus,
            }
          }
          return prevUc
        })
      })

      setIsStatusOpen(true)
    } catch (error) {
      console.error('Erro ao atualizar o status da UC:', error)
    }
  }

  const [tableCols, setTableCols] = useState<GridColDef[]>([])

  useEffect(() => {
    if (!ucs?.length) return

      const hasEditPermission = checkPermission(PermissionType.MY_UNITS_CONSUMER_UNITS_UPDATE)

    const columns = getConsumerUnitsColumns({
      data: ucs,
      editHandler: setSelectedUcId,
      statusHandler: handleStatus,
      permissions: {
        hasEditPermission
      }
    })

    setTableCols((state) => {
      const getFromState = (name?: string) => state.find(item => item.headerName === name)

      const newColumns = columns.reduce((acc, item) => {
        const name = item.headerName
        const oldItem = getFromState(name)

        item.hide = oldItem?.hide ?? item?.hide
        acc.push(item)
        return acc
      }, [] as GridColDef[])

      return newColumns
    })
  }, [ucs])

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  const handleFilterConsumer = async (checkboxes: CheckboxType<CustomersType>[]) => {
    setClickFilter(true)
    setCustomerFilter(checkboxes)
  }

  const handleFilterPowerPlant = (checkboxes: CheckboxType<PowerPlantsType>[]) => {
    setClickFilter(true)
    setPowerPlantFilter(checkboxes)
  }

  const hasCreatePermission = checkPermission(PermissionType.MY_UNITS_CONSUMER_UNITS_CREATE)

  const handleFilterDistributor = (values: CheckboxType<DistributorsType>[]) => {
    setClickFilter(true)
    setDistributorsFilter(values)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <TableContainer>
        <ButtonsPowerPlantContainer>
          <ConfigButtonsColumn gap={'1rem'}>
            <FloatingCheckboxes
              isFilterActive={distributorsFilter.some((distributor) => distributor.checked)}
              label='Distribuidoras'
              options={distributorsFilter}
              selectableAll
              searchable
              submitAction={handleFilterDistributor}
            />

            <FloatingCheckboxes
              checkbox
              isFilterActive={powerPlantFilter.some((powerplant) => powerplant.checked)}
              key={powerPlantFilter.toString()}
              label='Usinas'
              options={powerPlantFilter}
              selectableAll
              searchable
              submitAction={handleFilterPowerPlant}
            />

            <FloatingCheckboxes
              isFilterActive={customerFilter.some((customer) => customer.checked)}
              key={customerFilter.toString()}
              label='Consumidores'
              options={customerFilter}
              selectableAll
              searchable
              submitAction={handleFilterConsumer}
            />
          </ConfigButtonsColumn>
          <ConfigButtonsColumn gap='1rem'>
            <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />
            <ConfigButtonsColumn gap='0.5rem'>
              <DownloadDiv>
                <img src={`${DownloadIcon}`} alt='Download Button' onClick={handleDownloadButton} />
              </DownloadDiv>
              <FloatingCheckboxes
                pagination={{
                  selected: pageItemsAmount,
                  setSelected: setPageItemsAmount,
                  options: [15, 20, 30, 50, 100],
                }}
                isFilterActive={false}
                title='Configurar Tabela'
                options={tableCols
                  .map((col) => ({
                    label: col.headerName || '',
                    checked: !col.hide,
                    id: Math.random(),
                  }))
                }
                customActionComponent={
                  <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
                }
                submitBtnText='Salvar'
                submitAction={handleSelectTableColumns}
              />
              {hasCreatePermission && (
                <ActionTableButton onClick={() => setSelectedUcId(0)} icon={<AddOutlined />}>
                  Adicionar
                </ActionTableButton>
              )}
            </ConfigButtonsColumn>
          </ConfigButtonsColumn>
        </ButtonsPowerPlantContainer>
        <TableV2
          columns={tableCols}
          rows={ucs}
          alignText='center'
          isSelectable={true}
          messageNoRow='Não há unidades consumidoras cadastradas.'
          setSelectedItems={setSelectedIds}
          serverSorting
          sort={sortValues}
          onSort={(sort) => {
            setSortValues(sort)
            setPage(1)
          }}
        >
          <TablePagination
            pageInfo={{
              totalCount: totalCountUCs,
              currentPage: page,
              totalPages: Math.ceil(totalCountUCs / pageItemsAmount),
              limit: pageItemsAmount
            }}
            currentPageItemCount={ucs?.length}
            onChange={handleChangePage}
          />
        </TableV2>
      </TableContainer>

      {typeof selectedUcId !== 'undefined' && (
        <ModalRegisterConsumerUnit
          ucId={selectedUcId > 0 ? selectedUcId : undefined}
          onClose={() => setSelectedUcId(undefined)}
        />
      )}
      {isStatusOpen && (
        <ModalStatus
          buttonText='Ok, obrigado'
          buttonColor='darkGreen'
          text={statusMessage}
          openModal={isStatusOpen}
          setModalOpen={setIsStatusOpen}
        />
      )}
      {isSuccessOpen && (
        <ModalSuccess
          buttonText='Ok, obrigado'
          buttonColor='darkGreen'
          text={successMessage}
          openModal={isSuccessOpen}
          setModalOpen={setIsSuccessOpen}
        />
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </>
  )
}
