import { CircularProgress } from '@mui/material'
import { HTMLAttributes } from 'react'
import { LoadingContainer } from './style'

interface Props extends HTMLAttributes<HTMLDivElement> {
  fatura?: boolean
  message?: string
}

export default function Loading(props: Props) {
  const { fatura, message, ...rest } = props;

  return (
    <LoadingContainer {...rest}>
      <h2>{!fatura ? (message ? message : 'Buscando dados') : 'Baixando fatura'}</h2>
      <CircularProgress />
    </LoadingContainer>
  )
}
