import { styled } from '@mui/material/styles'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { Theme } from '../../styles/theme'

export const Root = styled('div')`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
`

export const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`

export const InputWrapper = styled('div')`
  width: 300px;
  border: 1px solid ${Theme.colors.lightGrey};
  background-color: ${Theme.colors.white};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${Theme.colors.darkishGreen};
  }

  &.focused {
    border-color: ${Theme.colors.darkishGreen};
  }

  & input {
    background-color: ${Theme.colors.white};
    color: rgba(0,0,0,.85);
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`

export const StyledTag = styled('div')`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.lightGrey};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${Theme.colors.darkishGreen};
    background-color: ${Theme.colors.grayLight};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`

export const Listbox = styled('ul')`
   width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${Theme.colors.white};
    font-weight: 600;

    & svg {
      color: ${Theme.colors.darkishGreen};
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${Theme.colors.grayLight};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`