import styled from 'styled-components'

export const DashboardLayoutContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export const ContentContainer = styled.div`
  flex: 1 1 0%;
  display: grid;
  grid-template-rows: min-content 1fr;
  padding-bottom: 40px;
  overflow-x: hidden;
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 20px 0 20px;
  overflow-x: auto;
`
