import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../components/InputV2'
import { Search } from '@mui/icons-material'
import { useState } from 'react'
import { paymentMethodDocumentTypes } from '../../../../../interfaces/payment-method'

interface Props {
  onChange: (value?: number) => void
  value?: number
  error?: boolean
}

export default function PaymentDocumentTypeSelect(props: Props) {
  const { value, onChange, error } = props

  const [selectedDocumentType, setSelectedDocumentType] = useState<number | undefined>(value)

  return (
    <Autocomplete
      id='bb_boleto_tipo'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2 rightIcon={<Search />} label='Tipo de Boleto' required error={error} {...params} />
      )}
      options={Object.keys(paymentMethodDocumentTypes).map((key) => Number(key))}
      isOptionEqualToValue={(option) => option === value}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? paymentMethodDocumentTypes[optionNumber as keyof typeof paymentMethodDocumentTypes] ??
              ''
          : String(option)
      }}
      value={selectedDocumentType}
      onChange={(_, value) => {
        onChange(value ?? undefined)
        setSelectedDocumentType(value ?? undefined)
      }}
    />
  )
}
