import styled from 'styled-components'

export const ContainerLimits = styled.div`
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 5px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
`

export const ContainerBottom = styled(Container)`
  padding: 14px 24px 14px 24px;
  align-items: baseline;
  justify-content: flex-start;
`
export const IcontContainer = styled.div`
  background: #f9f9fd;
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  min-width: 56px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoContainer = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const LegendArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
`

export const InfoTitle = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-size: 28px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.htmlGrey};
  padding-bottom: 4px;
`

export const InfoValue = styled.h1`
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.05em;
  padding-right: 30px;
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`
