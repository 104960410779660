import { useEffect, useState } from 'react'
import Loading from '../../../components/Loading'
import { Conference } from '../../../interfaces/conference'
import { conferenceService } from '../../../services/conference'
import InvoicesOverviewChart from './InvoicesOverviewChart'
import InvoicesCountChart from './InvoicesCountChart'
import { ChartsContainer } from './styles'

interface Conferences {
  distributors: Conference[]
  clients: Conference[]
}

export default function ConferenceChartsPage() {
  const [isLoading, setLoading] = useState<boolean>(false)

  const [conferences, setConferences] = useState<Conferences>()

  useEffect(() => {
    const fetchConferences = async () => {
      setLoading(true)
      const conferencesResult = await conferenceService.getConfereces()
      setConferences(conferencesResult)
      setLoading(false)
    }

    fetchConferences()
  }, [])

  return (
    <>
      {isLoading || !conferences ? (
        <Loading />
      ) : (
        <ChartsContainer>
          <InvoicesOverviewChart conferences={conferences.clients ? conferences.clients : []} />
          <InvoicesCountChart
            conferences={conferences.distributors ? conferences.distributors : []}
          />
        </ChartsContainer>
      )}
    </>
  )
}
