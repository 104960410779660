import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../../components/InputV2'
import { Search } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { aggregatedPaymentsService } from '../../../../../../services/aggregated-payments'

interface Props {
  selectedClientId?: number
  onChange?: (clientId: number | null) => void
  formik?: boolean
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
}

export default function ClientSelect(props: Props) {
  const { selectedClientId, formik = true, disabled, required, readOnly = false, onChange } = props

  const [consumers, setConsumers] = useState<{ id: number; cliente_nome: string }[]>([])

  const [selectedConsumerId, setSelectedConsumerId] = useState<number | undefined>(selectedClientId)

  const fetchConsumers = async () => {
    try {
      const consumersResponse = await aggregatedPaymentsService.listConsumersWithAggregatedPayment()
      setConsumers(consumersResponse)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchConsumers()
  }, [])

  return (
    <Autocomplete
      id='clientId'
      disabled={disabled}
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      readOnly={readOnly}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          label='Consumidor'
          formik={formik}
          {...params}
          required={required}
          disabled={disabled}
        />
      )}
      options={consumers.map((consumer) => consumer.id)}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? consumers.find((consumer) => consumer.id === option)?.cliente_nome ?? ''
          : String(option)
      }}
      isOptionEqualToValue={(option) => option === selectedConsumerId}
      value={selectedConsumerId}
      onChange={async (_, value) => {
        setSelectedConsumerId(value ?? undefined)
        if (onChange) {
          onChange(value)
        }
      }}
    />
  )
}
