import { useEffect, useMemo, useState } from 'react'
import Loading from '../../components/Loading'
import { SearchInput } from '../../components/SearchInput'
import { ActionTableButton } from '../../components/Table/ActionTableButton'
import {
  ConfigButtonsColumn,
  PaymentsContainer,
  SettingsContainer,
  WidgetsContainer,
} from './styles'

import { Add } from '@mui/icons-material'
import { DateFilter } from '../../components/Filter/DateFilter'
import { useToast } from '../../hooks/useToast'
import {
  AggregatedPayment,
  GetAggregatedPaymentsMetrics,
  ListAggregatedPayments,
  aggregatedPaymentsService,
} from '../../services/aggregated-payments'
import { format, parseISO } from 'date-fns'
import AggregatedPaymentModal from './components/AggregatedPaymentModal'
import DownloadSheetButton from './components/DownloadSheetButton'
import { useTableState } from '../../hooks/useTableState'
import AggregatedPaymentsTable from './components/AggregatedPaymentsTable'
import { TableStateProvider } from '../../hooks/useTableStateContext'
import { aggregatedPaymentsTableColumns } from './components/AggregatedPaymentsTableColumns'
import AggregatedPaymentsTableFilters from './components/AggregatedPaymentsTableFilters'
import WidgetPayments from '../../components/WidgetPayments'
import { ReactComponent as DollarIcon } from '../../assets/dollar.svg'
import { ReactComponent as NonPaymentIcon } from '../../assets/non-payment.svg'
import { ReactComponent as ReceiptX } from '../../assets/receipt-x.svg'
import { ReactComponent as ReceivedAmountIcon } from '../../assets/received-amount.svg'
import TableConfig from '../../components/TableV2/components/TableConfig'
import SendAggregatedPaymentModal from './components/SendAggregatedPaymentModal'
import DeleteAggregatedPaymentModal from './components/DeleteAggregatePaymentModal'

export function AggregatedPaymentsPage() {
  const { toast } = useToast()

  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [loading, setLoading] = useState(true)
  const [payments, setPayments] = useState<ListAggregatedPayments['result']>()
  const [selectedAggregatedPaymentId, setSelectedAggregatedPaymentId] = useState<number>()
  const [sendingAggregatedPaymentId, setSendingAggregatedPaymentId] = useState<number>()
  const [deletingAggregatedPaymentId, setDeletingAggregatedPaymentId] = useState<number>()

  const tableColumns = useMemo(
    () =>
      aggregatedPaymentsTableColumns({
        referenceDate: new Date(dateSelected.year, dateSelected.month - 1, 1),
        onEdit: (id) => setSelectedAggregatedPaymentId(id),
        onSend: (id) => setSendingAggregatedPaymentId(id),
        onDelete: (id) => setDeletingAggregatedPaymentId(id),
      }),
    [dateSelected],
  )

  const tableStateHook = useTableState({
    tableColumns,
  })

  const { tableState, setFilter } = tableStateHook

  const [widgets, setWidgets] = useState<GetAggregatedPaymentsMetrics['result']>()

  const fetchWidgets = async () => {
    try {
      const widgetsResult = await aggregatedPaymentsService.getMetrics({
        referenceMonth: format(
          new Date(dateSelected.year, dateSelected.month - 1, 1),
          'yyyy-MM-dd',
        ),
        filter: tableState.filters,
        sort: tableState.sort,
      })
      setWidgets(widgetsResult)
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao carregar widgets',
        type: 'error',
      })
    }
  }

  const fetchPayments = async () => {
    try {
      const { currentPage, itemsPerPage, sort } = tableState

      const data = await aggregatedPaymentsService.listAggregatedPayments({
        referenceMonth: format(
          new Date(dateSelected.year, dateSelected.month - 1, 1),
          'yyyy-MM-dd',
        ),
        filter: tableState.filters as any,
        itemsPerPage,
        page: currentPage,
        sort,
      })
      setPayments(data)
    } catch (error) {
      toast({
        message: 'Erro ao carregar dados de pagamento',
        type: 'error',
      })
    }
  }

  const fetchData = async () => {
    setLoading(true)
    await Promise.all([fetchPayments(), fetchWidgets()])
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [dateSelected, tableState])

  if (loading) {
    return <Loading />
  }

  return (
    <TableStateProvider {...tableStateHook}>
      {payments && sendingAggregatedPaymentId && (
        <SendAggregatedPaymentModal
          aggregatedPayment={
            payments.data.find(
              (payment) => payment.id === sendingAggregatedPaymentId,
            ) as AggregatedPayment
          }
          onSuccess={fetchData}
          onClose={() => setSendingAggregatedPaymentId(undefined)}
        />
      )}
      {payments && deletingAggregatedPaymentId && (
        <DeleteAggregatedPaymentModal
          aggregatedPayment={
            payments.data.find(
              (payment) => payment.id === deletingAggregatedPaymentId,
            ) as AggregatedPayment
          }
          onSuccess={fetchData}
          onClose={() => setDeletingAggregatedPaymentId(undefined)}
        />
      )}
      {typeof selectedAggregatedPaymentId === 'number' && (
        <AggregatedPaymentModal
          aggregatedPaymentId={
            selectedAggregatedPaymentId && selectedAggregatedPaymentId > 0
              ? selectedAggregatedPaymentId
              : undefined
          }
          onSuccess={fetchData}
          referenceMonth={new Date(dateSelected.year, dateSelected.month - 1, 1)}
          initialValues={(() => {
            const payment = payments?.data?.find(
              (payment) => payment.id === selectedAggregatedPaymentId,
            )
            return payment
              ? {
                  ...payment,
                  dueDate: format(parseISO(payment.dueDate), 'dd/MM/yyyy'),
                }
              : undefined
          })()}
          onClose={() => setSelectedAggregatedPaymentId(undefined)}
        />
      )}
      <PaymentsContainer>
        <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
        <WidgetsContainer>
          <WidgetPayments Icon={DollarIcon} title='Faturamento' value={widgets?.total} />
          <WidgetPayments
            Icon={ReceivedAmountIcon}
            title='Valores Recebidos'
            value={widgets?.paid}
          />
          <WidgetPayments Icon={ReceiptX} title='Valores Vencidos' value={widgets?.overdue} />
          <WidgetPayments
            Icon={NonPaymentIcon}
            title='Inadimplência'
            value={widgets?.defaultRate}
            isPercentage
          />
        </WidgetsContainer>
        <SettingsContainer>
          <AggregatedPaymentsTableFilters
            referenceDate={new Date(dateSelected.year, dateSelected.month - 1, 0)}
          />
          <ConfigButtonsColumn>
            <ActionTableButton
              icon={<Add />}
              onClick={() => setSelectedAggregatedPaymentId(0)}
              styles={{ fontSize: '15px' }}
            >
              Novo faturamento agrupado
            </ActionTableButton>

            <SearchInput
              value={(tableState.filters.search as any) ?? ''}
              setValue={(search) => setFilter('search', search)}
              style={{ width: 180 }}
            />

            <DownloadSheetButton
              referenceMonth={new Date(dateSelected.year, dateSelected.month - 1, 1)}
            />

            <TableConfig />
          </ConfigButtonsColumn>
        </SettingsContainer>

        {payments && <AggregatedPaymentsTable payments={payments} />}
      </PaymentsContainer>
    </TableStateProvider>
  )
}
