import styled from 'styled-components'
import { ReactComponent as DollarIcon } from '../../../assets/dollar.svg'
import { darken } from 'polished'

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-left: 42px;
  padding-right: 42px;
  gap: 16px;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`

export const BillingControlContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 20px;
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 16px;
`

export const SectionTitle = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 7px;
  border-radius: 4px;
  font-weight: 100;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkishGreen};
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
`

export const DollarIconColored = styled(DollarIcon)`
  fill: ${({ theme }) => theme.colors.darkishGreen};

  path {
    fill: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const WidgetContainer = styled.div`
  flex: 1;
  flex-wrap: wrap;
  min-width: fit-content;
  max-width: 600px;
`

export const TableUtils = styled.div`
  display: flex;
`
