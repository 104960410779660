import { Form, Formik } from 'formik'
import { FieldContainer, SectionGrid, StickyFooter } from '../InvoiceModal/styles'
import { FormikInput } from '../../../../../components/FormikComponents'
import { Button } from '../../../../../components/Button'
import { useEffect, useState } from 'react'
import { invoiceParserService } from '../../../../../services/invoice-parser'
import Loading from '../../../../../components/Loading'

interface Props {
  invoiceParserId: string
  onCreate: () => void;
  onClose: () => void
}

export default function InvoiceClosing(props: Props) {
  const { invoiceParserId, onCreate, onClose } = props

  const [invoicingClosingData, setInvoicingClosingData] = useState<Record<string, unknown>>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const calculateInvoiceClosing = async () => {
    try {
      setLoading(true)
      const closing = await invoiceParserService.calculateClosing(invoiceParserId)
      setInvoicingClosingData(closing)
    } catch (error) {
      console.log(`Error calculating closing: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const createInvoice = async () => {
    try {
      await invoiceParserService.createInvoice(invoiceParserId)
      onCreate();
      onClose()
    } catch (error) {
      console.log(`Error reanalyzing invoice: ${error}`)
    }
  }

  useEffect(() => {
    calculateInvoiceClosing()
  }, [invoiceParserId])

  if (isLoading || !invoicingClosingData) {
    return <Loading />
  }

  return (
    <Formik initialValues={invoicingClosingData} onSubmit={() => console.log('submit')}>
      <Form>
        <SectionGrid>
          {Object.entries(invoicingClosingData)
            .filter(([, value]) => !Array.isArray(value))
            .map(([key]) => (
              <FieldContainer key={`invoice-closing-field-${key}`}>
                <FormikInput showTitle label={key} mode required halfSize name={key} />
              </FieldContainer>
            ))}
        </SectionGrid>

        <StickyFooter>
          <Button text='Voltar' onClick={onClose}></Button>
          <Button text='Validar fatura' onClick={createInvoice}></Button>
        </StickyFooter>
      </Form>
    </Formik>
  )
}
