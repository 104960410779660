import React from 'react'
import Widget from '../Widget'
import increase from '../../assets/increase.svg'
import decrease from '../../assets/decrease.svg'
import {
  Divider,
  InfoContainer,
  InfoTitle,
  ValueContainer,
  InfoValue,
  ContainerBottom,
  PercentageText,
  InRelationToText,
  Container,
} from './styles'
import { formatNumberWithDot } from '../../utils/formatNumber'

interface WidgetProps {
  title: string
  value: number
  variation: number
  inRelationToText: string
}

export function WidgetInfoSmall({ title, value, variation, inRelationToText }: WidgetProps) {
  return (
    <Widget>
      <Container>
        <InfoContainer>
          <ValueContainer>
            <InfoValue>{formatNumberWithDot(value)}</InfoValue>
          </ValueContainer>
          <InfoTitle>{title}</InfoTitle>
        </InfoContainer>
        <Divider />
        <ContainerBottom>
          <img src={variation > 0 ? `${increase}` : `${decrease}`} width={12} height={11.27} />
          <PercentageText>{variation > 0 ? `+${variation}%` : `${variation}%`}</PercentageText>
          <InRelationToText title={inRelationToText}>{inRelationToText}</InRelationToText>
        </ContainerBottom>
      </Container>
    </Widget>
  )
}

export default WidgetInfoSmall
