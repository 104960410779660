import { Typography } from '@mui/material'
import { Ticket, TicketReply } from '../../../../../../interfaces/tickets'
import {
  MasterContainer,
  MessageCard,
  MessageCardHeader,
  MessageDate,
  MessageText,
} from '../../styles'
import { SupportAgent } from '@mui/icons-material'
import { format } from 'date-fns'
import { PropsWithChildren, useContext } from 'react'
import { GlobalContext } from '../../../../../../context/global/global'

interface Props {
  assignedTo?: string
  reply: TicketReply
  isMaster?: boolean
}

export default function Reply(props: PropsWithChildren<Props>) {
  const { assignedTo, reply, children } = props

  const { state } = useContext(GlobalContext)

  const isMaster = reply.replier.tipo === 'MASTER'

  const isSender = state.auth?.customer?.id === reply.replier.id

  return (
    <MessageCard isSender={isSender}>
      <MessageCardHeader>
        <Typography variant='subtitle2'>
          {isMaster ? (
            <MasterContainer>
              <SupportAgent />
              {assignedTo ? assignedTo : `Suporte`}
            </MasterContainer>
          ) : (
            reply.replier.cliente_nome
          )}
        </Typography>
        <MessageDate variant='subtitle2'>
          {format(new Date(reply.createdAt), 'HH:mm - dd/MM/yyyy')}
        </MessageDate>
      </MessageCardHeader>
      <MessageText variant='body1'>{reply.message}</MessageText>

      {children}
    </MessageCard>
  )
}
