import { GridRenderCellParams } from '@mui/x-data-grid'
import moment from 'moment'
import { StatusText } from '../../Payments/styles'
import { statusPaymentStyle } from '../../Invoices/utils/selectedObservationFieldColor'

export const tableColumns = [
  {
    field: 'uc_nome',
    headerName: 'Nome UC',
  },
  {
    field: 'codigo',
    headerName: 'Número UC',
  },
  {
    field: 'payment_status',
    headerName: 'Status do Pagamento',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <StatusText className={statusPaymentStyle[params.value]}>{params.value}</StatusText>
    },
  },
  {
    field: 'versao_leitor',
    headerName: 'Tipo Leitor',
  },
  {
    field: 'mes_referencia',
    headerName: 'Mês de Referência',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return moment(params.value).format('MM/YYYY')
    },
  },
  {
    field: 'nome',
    headerName: 'Distribuidora',
  },
  {
    field: 'createdAt',
    headerName: 'Data de criação',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return moment(params.value).format('DD/MM/YYYY - HH:mm')
    },
  },
]
