import InvoiceList from './components/InvoiceList'
import InvoiceUpload from './components/InvoiceUpload'
import { InvoiceListProvider } from './hooks/useInvoiceList'

export default function InvoiceParserPage() {
  return (
    <InvoiceListProvider>
      <InvoiceUpload />
      <InvoiceList />
    </InvoiceListProvider>
  )
}
