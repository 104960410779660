import { Form, Formik } from 'formik'
import { FieldContainer, StickyFooter } from '../InvoiceModal/styles'
import { FormikInput } from '../../../../../components/FormikComponents'
import { Button } from '../../../../../components/Button'
import { useEffect, useState } from 'react'
import Loading from '../../../../../components/Loading'
import { invoiceParserV2 } from '../../../../../services/invoice-parser-v2'
import { useToast } from '../../../../../hooks/useToast'

interface Props {
  invoiceValidationId: string
  onCreate: () => void
  onClose: () => void
}

export default function InvoiceClosing(props: Props) {
  const { invoiceValidationId, onCreate, onClose } = props

  const [invoicingClosingData, setInvoicingClosingData] = useState<Record<string, unknown>>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const calculateInvoiceClosing = async () => {
    try {
      setLoading(true)
      const closing = await invoiceParserV2.getInvoiceClosing(invoiceValidationId)
      setInvoicingClosingData(closing)
    } catch (error) {
      toast({
        message: `Erro ao calcular fechamento: ${error}`,
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const createInvoice = async () => {
    try {
      await invoiceParserV2.validateInvoice(invoiceValidationId)
      onCreate()
      onClose()
    } catch (error) {
      toast({
        message: (error as Error).message,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    calculateInvoiceClosing()
  }, [invoiceValidationId])

  if (isLoading || !invoicingClosingData) {
    return <Loading />
  }

  return (
    <Formik initialValues={invoicingClosingData} onSubmit={() => console.log('submit')}>
      <Form>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: '16px',
          }}
        >
          {Object.entries(invoicingClosingData)
            .filter(([, value]) => !Array.isArray(value))
            .map(([key]) => (
              <FieldContainer key={`invoice-closing-field-${key}`}>
                <FormikInput readOnly showTitle label={key} mode required halfSize name={key} />
              </FieldContainer>
            ))}
        </div>

        <StickyFooter>
          <Button text='Voltar' onClick={onClose}></Button>
          <Button text='Validar fatura' onClick={createInvoice}></Button>
        </StickyFooter>
      </Form>
    </Formik>
  )
}
