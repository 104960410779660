import { TableV2 } from '../../../../../components/TableV2'
import { ListUcsScraped } from '../../../../../services/orchestrator'
import { Visibility } from '@mui/icons-material'
import { TablePagination } from '../../../../../components/TableV2/components/TablePagination'
import { Theme } from '../../../../../styles/theme'
import { Chip, Tooltip } from '@mui/material'
import { useState } from 'react'
import UcScrapingLogsModal from '../UcScrapingLogsModal'
import { Sort } from '../../../../../interfaces/pagination'

interface Props {
  referenceMonth: Date
  rows: ListUcsScraped['result']
  sort?: Sort
  onSort: (sort?: Sort) => void
  onPageChange: (page: number) => void
}

export default function UcsScrapedTable(props: Props) {
  const { referenceMonth, rows, onPageChange, sort, onSort } = props

  const [selectedUcId, setSelectedUcId] = useState<number>()

  return (
    <>
      {selectedUcId && (
        <UcScrapingLogsModal
          referenceMonth={referenceMonth}
          ucId={selectedUcId}
          onClose={() => setSelectedUcId(undefined)}
        />
      )}
      <TableV2
        alignText='center'
        columns={[
          {
            field: 'managerName',
            headerName: 'Gestor',
            sortable: true,
          },
          {
            field: 'ucCode',
            headerName: 'Codigo',
            sortable: true,
          },
          {
            field: 'ucName',
            headerName: 'Nome',
            sortable: true,
          },
          {
            field: 'distributorName',
            headerName: 'Distribuidora',
            sortable: true,
          },
          {
            field: 'attemptCount',
            headerName: 'Tentativas',
            sortable: true,
          },
          {
            field: 'latestAttemptResult',
            headerName: 'Ultimo Status',
            sortable: false,
            renderCell(params) {
              return (
                <Chip style={{ maxWidth: '120px', width: '100%' }} label={params.value} color={params.value === 'SUCCESS' ? 'success' : 'error'} variant="filled" />
              )
            }
          },
          {
            field: 'actions',
            headerName: '',
            sortable: false,
            hideable: false,
            renderCell(params) {
              return (
                <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar logs</p>} placement='bottom'>
                  <Visibility
                    style={{ cursor: 'pointer', color: Theme.colors.grayDark }}
                    fontSize='medium'
                    onClick={() => setSelectedUcId(params.row.id)}
                  />
                </Tooltip>
              )
            },
          },
        ]}
        rows={rows.data.map((scraped) => ({
          id: scraped.uc.id,
          ucCode: scraped.uc.code,
          ucName: scraped.uc.name,
          attemptCount: scraped.attempts,
          distributorName: scraped.distributor.name,
          managerName: scraped.manager.name,
          latestAttemptResult: scraped.latestAttempt?.attemptResult ?? '-'
        }))}
        sort={sort}
        onSort={onSort}
      >
        <TablePagination
          onChange={onPageChange}
          pageInfo={rows.pageInfo}
          currentPageItemCount={rows.data.length}
        />
      </TableV2>
    </>
  )
}
