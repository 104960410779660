import styled from 'styled-components'
import { screenSizes } from '../../styles/media'

export const ContainerLimits = styled.div`
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: space-between;

  @media (max-width: ${screenSizes.desktop}) {
    padding: 1rem;
  }
`

export const ContainerBottom = styled(Container)`
  padding: 0.875rem 1.5rem;
  align-items: baseline;
  justify-content: flex-start;

  @media (max-width: ${screenSizes.desktop}) {
    padding: .675rem 1rem;
  }
`
export const IcontContainer = styled.div`
  background: #f9f9fd;
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 56px;
  min-width: 44px;
  width: 100%;

  max-height: 56px;
  min-height: 44px;
  height: 100%;

  aspect-ratio: 1 / 1;

  @media (max-width: ${screenSizes.desktop}) {
    max-width: 44px;
    min-width: 36px;

    max-height: 44px;
    min-height: 36px;
  }
`

export const InfoContainer = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const InfoTitle = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.htmlGrey};

  @media (max-width: ${screenSizes.desktop}) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const InfoValue = styled.h1`
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.05em;

  @media (max-width: ${screenSizes.desktop}) {
    font-size: 26px;
    line-height: 44px;
  }
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const MeasurementText = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.05em;
  align-self: flex-end;
  padding-bottom: 10px;
  padding-left: 4px;
  color: #808080;
`

export const PercentageText = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: -0.05em;
  color: #4b4b4b;
  margin-left: 7px;
  margin-right: 7px;
`

export const InRelationToText = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #808080;
`
