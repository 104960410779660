import { PermissionType } from '../interfaces/permissions'
import { api } from './api'

export interface ListUserPermissions {
  params: {
    userId: number
  }
  result: {
    hasPermission: boolean
    fromRoles?: string[]
    permission: {
      id: number
      displayName: string
      name: string
      description?: string
      resourceType: string
    }
  }[]
}

export interface ListRoles {
  result: {
    id: number
    name: string
    permissions: {
      id: number
      displayName: string
      name: string
      description?: string
      resourceType: string
    }[]
  }[]
}

export interface ListRolePermissions {
  params: {
    roleId: number
  }
  result: {
    hasPermission: boolean
    permission: {
      id: number
      displayName: string
      name: string
      description?: string
      resourceType: string
    }
  }
}

export interface UpdateRolePermissions {
  params: {
    roleId: number
    permissions: {
      id: number
      remove: boolean
    }[]
  }
  result: void
}
export interface UpdateUserPermissions {
  params: {
    userId: number
    permissions: {
      id: number
      remove: boolean
    }[]
  }
  result: void
}
export interface ListUserRoles {
  params: {
    userId: number
  }
  result: {
    hasRole: boolean
    role: {
      id: number
      name: string
    }
  }[]
}
export interface UpdateUserRoles {
  params: {
    userId: number
    roles: {
      id: number
      remove: boolean
    }[]
  }
  result: void
}

export interface CreateRole {
  params: {
    name: string
    permissionsIds: number[]
  }
  result: void
}
export interface ListPermissions {
  result: {
    id: number
    displayName: string
    name: string
    description?: string
    resourceType: string
  }[]
}
export interface ListPermissionsFromUser {
  result: PermissionType[]
}

export class PermissionsService {
  async listUserPermissions(
    params: ListUserPermissions['params'],
  ): Promise<ListUserPermissions['result']> {
    const { userId } = params
    const permissionsResponse = await api.get(`/permissions/users/${userId}`)
    return permissionsResponse.data
  }
  async listRoles(): Promise<ListRoles['result']> {
    const rolesResponse = await api.get('/roles')
    return rolesResponse.data
  }
  async listRolePermissions(
    params: ListRolePermissions['params'],
  ): Promise<ListRolePermissions['result']> {
    const { roleId } = params
    const rolesResponse = await api.get(`/permissions/roles/${roleId}`)
    return rolesResponse.data
  }
  async updateRolePermissions(
    params: UpdateRolePermissions['params'],
  ): Promise<UpdateRolePermissions['result']> {
    const { roleId, permissions } = params
    await api.patch(`/permissions/roles/${roleId}`, {
      permissions,
    })
  }
  async updateUserPermissions(
    params: UpdateUserPermissions['params'],
  ): Promise<UpdateUserPermissions['result']> {
    const { userId, permissions } = params
    await api.patch(`/permissions/users/${userId}`, {
      permissions,
    })
  }
  async listUserRoles(params: ListUserRoles['params']): Promise<ListUserRoles['result']> {
    const { userId } = params
    const rolesResponse = await api.get(`/permissions/users/${userId}/roles`)
    return rolesResponse.data
  }
  async updateUserRoles(params: UpdateUserRoles['params']): Promise<UpdateUserRoles['result']> {
    const { userId, roles } = params
    await api.patch(`/permissions/users/${userId}/roles`, {
      roles,
    })
  }
  async createRole(params: CreateRole['params']): Promise<CreateRole['result']> {
    const { name, permissionsIds } = params
    await api.post('/roles', {
      name,
      permissionsIds,
    })
  }
  async listPermissions(): Promise<ListPermissions['result']> {
    const permissionsResponse = await api.get('/permissions')
    return permissionsResponse.data
  }
  async listPermissionsFromUser(userId: number): Promise<ListPermissionsFromUser['result']> {
    const permissionsResponse = await api.get(`/permissions/users/${userId}`)
    const data = permissionsResponse.data as ListUserPermissions['result']

    return data
      .filter((permissionInfo) => permissionInfo.hasPermission)
      .map(
        (permissionInfo) =>
          `${permissionInfo.permission.resourceType}:${permissionInfo.permission.name}` as PermissionType,
      )
  }
}

export const permissionsService = new PermissionsService()
