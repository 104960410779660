import { Pagination, PaginationQuery } from '../interfaces/pagination'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

export interface Quantitative {
  clientId: number
  clientName: string
  totalUcsCount: number
  totalPower: number
}

export interface DashboardSuperList {
  params: PaginationQuery & {
    referenceDate: string
  }
  result: Pagination<Quantitative>
}

export interface DashboardSuperCards {
  params: {
    referenceDate: string
    filter?: Record<string, unknown>
  }
  result: {
    totalUcs: {
      count: number
      percentage: number
    }
    totalPowerPlants: {
      count: number
      percentage: number
    }
    totalPower: {
      count: number
      percentage: number
    }
    totalPastValueDue: {
      count: number
      percentage: number
    }
  }
}

export interface DashboardSuperDownloadSheet {
  params: {
    referenceMonths: string[]
    filter?: Record<string, unknown>
  }
  result: Buffer
}

class DashboardSuperService {
  async cards(params: DashboardSuperCards['params']): Promise<DashboardSuperCards['result']> {
    const { referenceDate, filter } = params
    // const response = await api.get('ENDPOINT', {
    //   params: {
    //     referenceDate,
    //     ...(filter ? { filter: convertObjectToQueryParams(filter, ';') } : {}),
    //   },
    // })
    // return response.data
    await new Promise((resolve) => setTimeout(() => resolve({}), 1500))
    return {
      totalPastValueDue: {
        count: 10,
        percentage: 0.1,
      },
      totalPower: {
        count: 5,
        percentage: 0.3,
      },
      totalPowerPlants: {
        count: 13,
        percentage: 0.6,
      },
      totalUcs: {
        count: 124,
        percentage: 0.9,
      },
    }
  }

  async list(params: DashboardSuperList['params']): Promise<DashboardSuperList['result']> {
    const { referenceDate, filter, itemsPerPage, page, sort } = params
    // const response = await api.get('ENDPOINT', {
    //   params: {
    //     referenceDate,
    //     itemsPerPage,
    //     page,
    //     ...(filter ? { filter: convertObjectToQueryParams(filter, ';') } : {}),
    //     ...(sort
    //       ? {
    //           field: sort.field,
    //           order: sort.order,
    //         }
    //       : {}),
    //   },
    // })
    // return response.data
    await new Promise((resolve) => setTimeout(() => resolve({}), 1500))
    return {
      data: [
        {
          clientId: 1,
          clientName: 'Tangisa-ECO',
          totalPower: 75,
          totalUcsCount: 1281,
        },
      ],
      pageInfo: {
        currentPage: 1,
        limit: itemsPerPage ?? 15,
        totalCount: 1,
        totalPages: 1,
      },
    }
  }

  async downloadSheet(
    params: DashboardSuperDownloadSheet['params'],
  ): Promise<DashboardSuperDownloadSheet['result']> {
    const { referenceMonths, filter } = params
    await new Promise((resolve) => setTimeout(() => resolve({}), 1500))
    return {} as any
    // return api.get('endpoint', {
    //   responseType: 'blob',
    //   params: {
    //     ...(filter ? { filter: convertObjectToQueryParams(filter, ';') } : {}),
    //     referenceMonths,
    //   }
    // })
  }
}

export const dashboardSuperService = new DashboardSuperService()
