import styled from 'styled-components'
import { Button } from '../../components/Button'
import { FormDivProps } from './types'

export const SaveButton = styled(Button)`
  width: 213px;
  height: 45px;
  border-radius: 8px;
`

export const FormDiv = styled.div<FormDivProps>`
  max-width: ${(props) => (props.$isToggled ? '1250px' : '1120px')};
  width: ${(props) => (props.$isToggled ? '1280px' : '1120px')};
  padding: 32px;
  @media (min-width: 1441px) {
    width: ${(props) => (props.$isToggled ? '94vw' : '80vw')};
  }
`

export const TwoInputs = styled.div`
  display: flex;
  gap: 16px;
`

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`
export const Title = styled.span`
  font-weight: bold;
  margin-bottom: 32px;
  display: inline-block;
`

export const PasswordFormContainer = styled.div`
  margin: 56px 0 32px;
`
