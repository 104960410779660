import styled from 'styled-components'
import WindTurbine from '../../../../assets/login-image.png'

const windTurbine = WindTurbine as unknown as string

export const LoginPageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background: #f9fafd;
`

export const LoginInfoContainer = styled.div`
  width: 650px;
  height: 774px;
  background: ${({ theme }) => theme.colors.darkishGreen};
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.12);
  border-radius: 0px 16px 16px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  align-self: center;
`

export const InputContainer = styled.div`
  display: flex;
  width: 436px;
  gap: 16px;
  flex-direction: column;
`

export const ForgotPasswordText = styled.h4`
  color: white;
  align-self: flex-end;
  text-decoration: underline;
  margin-top: -8px;
  cursor: pointer;
  margin-bottom: 97px;
`

export const ButtonEnter = styled.button`
  width: 436px;
  height: 52px;
  left: 827px;
  top: 727px;
  background: #01fe88;
  border-radius: 8px;
  border: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  &:disabled {
    color: black;
    cursor: not-allowed;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 610px;
  height: 860px;
  background-color: ${({ theme }) => theme.colors.darkishGreen} !important;
  background: url(${windTurbine});
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  display: flex;
`

export const LoginTextBox = styled.div`
  width: 338px;
  height: 189px;
  display: flex;
  align-items: center;
  text-align: right;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  right: 56px;
  bottom: 56px;
`

export const LoginText = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 47px;
  width: 100%;
  color: #ffffff;
`

export const LoginTextStrong = styled(LoginText)`
  font-weight: bold;
`

export const InvalidInputText = styled.p`
  color: ${(props) => props.theme.colors.suportError};
  font-size: 0.7rem;
  margin-top: -8px;
  margin-left: 2px;
`
