import styled from 'styled-components'
import { ReactComponent as DollarIcon } from '../../../assets/dollar.svg'

export const BillingControlContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 20px;
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 16px;
`

export const SectionTitle = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 7px;
  border-radius: 4px;
  font-weight: 100;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.darkishGreen};
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
`

export const DollarIconColored = styled(DollarIcon)`
  fill: ${({ theme }) => theme.colors.darkishGreen};

  path {
    fill: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const WidgetContainer = styled.div`
  flex: 1;
  flex-wrap: wrap;
  min-width: fit-content;
  max-width: 600px;
`

export const TableUtils = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`
