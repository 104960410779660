import styled from "@emotion/styled"
import { List } from "@mui/material"

export const ButtonsContainer = styled.div`
  display: flex;
`

export const UploadFileContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const UploadedFilesList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const UploadInput = styled.input`
  display: none;
`