import { PasswordForm } from '../../components/PasswordForm'
import { EditCustomer } from './EditCustomer'
import { PasswordFormContainer, Title } from './styles'

export default function Settings() {
  return (
    <>
      <EditCustomer />
      <PasswordFormContainer>
        <Title>Senha</Title>
        <PasswordForm />
      </PasswordFormContainer>
    </>
  )
}
