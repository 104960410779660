import styled from 'styled-components'
import { Theme } from '../../../styles/theme'

export const Container = styled.div``

export const Return = styled.div`
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
`

export const Infos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding-right: 45px;
`
export const Content = styled.div``

export const InputDiv = styled.div`
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 8px;
  width: fit-content;
`

export const SendDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const SendButton = styled.div`
  background-color: ${Theme.colors.darkGreen};
  color: ${Theme.colors.white};
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

export const ResponseDiv = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 20px;

  div {
    p {
      color: ${Theme.colors.quartzGrey};
      font-size: 18px;
    }
  }
`

export const ResponseTitle = styled.div`
  font-size: 24px;
`
