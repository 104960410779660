import { InvoiceParser } from '../interfaces/invoices'
import { api } from './api'

class InvoiceParserService {
  async getInvoices() {
    const response = await api.get('/invoice-parser')
    return response.data
  }
  async uploadFiles(files: File[]): Promise<InvoiceParser[]> {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append(`files`, file)
    })
    const response = await api.post('/invoice-parser/aws', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data.invoices
  }
  async calculateClosing(invoiceParserId: string) {
    const response = await api.get('/invoice-parser/closing', {
      params: {
        id: invoiceParserId,
      },
    })
    return response.data
  }
  async reanalyzeInvoice(invoiceParserId: string) {
    const response = await api.post('/invoice-parser/reanalyze', {
      id: invoiceParserId,
    })
    return response.data
  }
  async createInvoice(invoiceParserId: string) {
    const response = await api.post('/invoice-parser', {
      id: invoiceParserId,
    })
    return response.data
  }
  async updateInvoice(invoiceParserId: string, data: Record<string, unknown>) {
    const response = await api.put(`/invoice-parser/${invoiceParserId}`, data)
    return response.data
  }
  async getInvoice(invoiceParserId: string) {
    const response = await api.get(`/invoice-parser/${invoiceParserId}`)
    return response.data
  }
}

export const invoiceParserService = new InvoiceParserService()
