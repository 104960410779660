import { Button, Buttons, Container, Infos, Return, ShowData, Status } from './styles'
import { useState } from 'react'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { createManualPdf, getAzureData, sendIaData } from '../../../services/requests/user-requests'
import { SendInvoice } from '../../../components/Pdfs/SendInvoice'

export function ReportEnergisaReader({ setPage }: { setPage: (value: '') => void }) {
  const [data, setData] = useState<any>({})
  const [closure, setClosure] = useState<any>({})
  const [exist, setExist] = useState(false)
  const [status, setStatus] = useState(false)
  const [hasMuc, setHasMuc] = useState(false)

  const [file, setFile] = useState<File>()

  function sendPdf() {
    ;(async () => {
      if (file) {
        setStatus(true)
        const formDatas = new FormData()
        formDatas.append('files', file)

        // const result = await createManualPdf(formDatas)
        //   .catch(() => {
        //     alert('Ocorreu um erro ao tentar subir o pdf, por favor contate os desenvolvedores!')
        //   })
        //   .finally(() => setStatus(false))

        // setStatus(true)

        // const url = `https://api.labs-lumi.com.br/faturas/show/${result.id}`

        await getAzureData('', hasMuc, undefined, file)
          .then((val) => {
            setData(val.invoice)
            setClosure(val.closure)
            setExist(val.exists)
          })
          .catch((err) => alert(err.message))
          .finally(() => setStatus(false))
        // await getAzureData(url, hasMuc, undefined, file)
        //   .then((val) => {
        //     setData(val.invoice)
        //     setClosure(val.closure)
        //     setExist(val.exists)
        //   })
        //   .catch((err) => alert(err.response.data.message))
        //   .finally(() => setStatus(false))
      } else {
        alert('Pdf ausente')
      }
    })()
  }

  function sendData() {
    ;(async () => {
      if (Object.keys(data).length) {
        setStatus(true)

        const sendData = await sendIaData(data)
          .then(() => {
            setExist(false)
            setClosure({})
            setData({})
          })
          .catch((err) => alert(err))
          .finally(() => setStatus(false))
        console.log(sendData)
      } else {
        alert('Extraia os dados do pdf primeiro')
      }
    })()
  }

  return (
    <Container>
      <Infos>
        <Return
          onClick={() => {
            setPage('')
          }}
        >
          <ArrowBack fontSize='small' />
          <p>Voltar</p>
        </Return>
        <Buttons>
          <Button className='orange' onClick={() => setHasMuc(!hasMuc)}>
            {hasMuc ? '' : 'Não '}Incluir Muc - Clique para alterar
          </Button>
          {exist ? <Status>Esta fatura já existe</Status> : null}
          <Button onClick={sendPdf}>Dados pdf</Button>
          <Button onClick={sendData}>Enviar</Button>
        </Buttons>
      </Infos>
      <SendInvoice setFile={setFile} />
      {!status ? (
        <ShowData>
          <div>
            <h3>Fatura</h3>
            {Object.keys(data).map((val) => {
              return (
                <p key={val}>
                  <span>{val}</span>: {data[val]}
                </p>
              )
            })}
          </div>
          <div>
            <h3>Fechamento</h3>
            {Object.keys(closure).map((val) => {
              return (
                <p key={val}>
                  <span>{val}</span>: {closure[val]}
                </p>
              )
            })}
          </div>
        </ShowData>
      ) : (
        <p>Caregando dados...</p>
      )}
    </Container>
  )
}
