import React from 'react'
import Widget from '../Widget'
import increase from '../../assets/increase.svg'
import decrease from '../../assets/decrease.svg'
import {
  Container,
  IcontContainer,
  InfoContainer,
  InfoTitle,
  ValueContainer,
  InfoValue,
  MeasurementText,
  ContainerBottom,
  PercentageText,
  InRelationToText,
  ContainerLimits,
} from './styles'
import { GlobalContext } from '../../context/global/global'
import { Divider } from '../Divider'

interface WidgetProps {
  icon: string | React.ReactNode
  title: string
  value: number | string
  measurement?: boolean
  measurementText?: string
  variation?: number
  inRelationToText?: string
}

export function WidgetInfo({
  icon,
  title,
  value,
  measurement,
  measurementText,
  variation,
  inRelationToText,
}: WidgetProps) {
  const { Theme } = React.useContext(GlobalContext)

  return (
    <Widget>
      <ContainerLimits>
        <Container>
          <IcontContainer>
            {typeof icon === 'string' ? (
              <img src={`${icon}`} width={24} height={24} color={Theme.colors.darkishGreen} />
            ) : (
              icon
            )}
          </IcontContainer>
          <InfoContainer>
            <InfoTitle>{title}</InfoTitle>
            <ValueContainer>
              <InfoValue>
                {typeof value === 'string'
                  ? value
                  : measurement
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }).format(value)
                  : `${Number(value?.toFixed(2) || 0).toLocaleString('pt-BR')}`}
              </InfoValue>
              {!measurement && <MeasurementText>{measurementText ?? 'kWh'}</MeasurementText>}
            </ValueContainer>
          </InfoContainer>
        </Container>
        {(variation || inRelationToText) && (
          <>
            <Divider />
            <ContainerBottom>
              {variation != undefined && variation != null && (
                <>
                  <img
                    src={variation > 0 ? `${increase}` : `${decrease}`}
                    width={28}
                    height={14.5}
                  />
                  <PercentageText>
                    {variation > 0 ? `+${variation}%` : `${variation}%`}
                  </PercentageText>
                </>
              )}
              {inRelationToText && (
                <InRelationToText title={inRelationToText}>{inRelationToText}</InRelationToText>
              )}
            </ContainerBottom>
          </>
        )}
      </ContainerLimits>
    </Widget>
  )
}

export default WidgetInfo
