import { FC, SVGProps } from 'react'
import { Link, LinkIconContainer, LinkText } from '../styles'

interface ILinkButtonProps {
  path: string
  isActive: boolean
  Icon: FC<SVGProps<SVGSVGElement>>
  isOpen: boolean
  text: string
}

export const LinkButton = ({ path, isActive, Icon, isOpen, text }: ILinkButtonProps) => {
  return (
    <Link to={path} isactive={isActive} title={text}>
      <LinkIconContainer isactive={isActive}>
        <Icon />
      </LinkIconContainer>
      <LinkText isOpen={isOpen}>{text}</LinkText>
    </Link>
  )
}
