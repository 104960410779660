import React, { useState } from 'react'
import Modal from '..'
import ExclamationMark from '../../../assets/exclamation-mark.svg'
import {
  CheckMarkDiv,
  ConfirmButton,
  ModalDeleteDiv,
  DeleteText,
  Buttons,
  CancelButton,
  ConfirmDeleteText,
} from './styles'
import { Typography } from '@mui/material'

interface ModalDeleteProps {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  text: string
  textStyle?: React.CSSProperties
  descriptionStyle?: React.CSSProperties
  description?: string
  confirmText?: boolean
  buttonColor: 'lightGreen' | 'darkGreen' | 'white'
  hideBackDrop?: boolean
  action?: () => void
}

export default function ModalDelete({
  openModal,
  setModalOpen,
  text,
  description,
  confirmText,
  buttonColor,
  hideBackDrop,
  action,
  textStyle,
  descriptionStyle,
}: ModalDeleteProps) {
  const [isLoading, setLoading] = useState<boolean>(false)

  const handleAction = async () => {
    setLoading(true)
    if (action) {
      await action()
    }
    setLoading(false)
    setModalOpen(!openModal)
  }
  return (
    <Modal
      openModal={openModal}
      setModalOpen={setModalOpen}
      defaultButtons={false}
      hideBackDrop={hideBackDrop}
    >
      <ModalDeleteDiv>
        <CheckMarkDiv>
          <img alt='checkmark' src={`${ExclamationMark}`} />
        </CheckMarkDiv>
        <DeleteText style={textStyle}>{text}</DeleteText>
        {confirmText && (
          <ConfirmDeleteText>
            {`Caso o cliente já tenha efetuado o pagamento do boleto, não efetue o cancelamento!`}
          </ConfirmDeleteText>
        )}
        {description && ( 
          <Typography style={descriptionStyle} sx={{ textAlign: 'center' }}>
            {description}
          </Typography>
        )}
        <Buttons>
          <CancelButton
            onClick={() => {
              setModalOpen(false)
            }}
          >
            Cancelar
          </CancelButton>
          <ConfirmButton mode={buttonColor} text={'Excluir'} isLoading={isLoading} onClick={handleAction} />
        </Buttons>
      </ModalDeleteDiv>
    </Modal>
  )
}
