import { useEffect, useState } from 'react'
import { DateFilter } from '../../components/Filter/DateFilter'
import PlantsInvoicingChart from './components/PlantsInvoicingChart'
import PlantsInvoicing from './components/PlantsInvoicing'
import PlantsInvoicingPieChart from './components/PlantsInvoicingPieChart'
import { ChartsRow } from './styles'
import { PlantInvoicing } from '../../interfaces/plants'
import { plantsService } from '../../services/plants'
import Loading from '../../components/Loading'

interface DateFilter {
  month: number
  year: number
}

export default function PlantsInvoicingPage() {
  const [dateSelected, setDateSelected] = useState<DateFilter>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [plantsInvoicing, setPlantsInvoicing] = useState<PlantInvoicing[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const fetchPlantsInvoicing = async (date: DateFilter): Promise<void> => {
    try {
      setLoading(true)
      const plants = await plantsService.getPlantsInvoicing({
        date: `${date.year}-${date.month}`,
      })
      setPlantsInvoicing(plants)
    } catch (error) {
      console.log(`Error fetching plants invoicing: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const changeSelectedDate = async (date: DateFilter) => {
    await fetchPlantsInvoicing(date)
    setDateSelected(date)
  }

  useEffect(() => {
    fetchPlantsInvoicing(dateSelected)
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <DateFilter onChange={changeSelectedDate} currentValue={dateSelected} />
      <ChartsRow>
        <PlantsInvoicingChart title='Arrecadação por Usina (R$)' chartData={plantsInvoicing} />
        <PlantsInvoicingPieChart chartData={plantsInvoicing} />
      </ChartsRow>
      <PlantsInvoicing
        plantsInvoicing={plantsInvoicing}
        date={`${dateSelected.year}-${dateSelected.month}`}
      />
    </>
  )
}
