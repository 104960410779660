import { useContext, useState } from 'react'
import { Button } from '../../../../components/Button'
import Modal from '../../../../components/ModalV2'
import { useToast } from '../../../../hooks/useToast'
import {
  AggregatedPayment,
  aggregatedPaymentsService,
} from '../../../../services/aggregated-payments'
import { Stack, Typography } from '@mui/material'
import {
  EmailsListContainer,
  EmailsListWrapper,
} from '../../../../components/Modal/PaymentExcel/styles'
import { GenericTooltip } from '../../../../components/Tooltip'
import { Info } from '@mui/icons-material'
import { GlobalContext } from '../../../../context/global/global'
import { InputV2 } from '../../../../components/InputV2'
import { numberToString } from '../../../../utils/formatNumberToString'
import ClientSelect from '../AggregatedPaymentModal/components/ClientSelect'
import { format, parseISO } from 'date-fns'
import { CheckMarkDiv } from '../../../../components/Modal/ModalDelete/styles'
import ExclamationMark from '../../../../assets/exclamation-mark.svg'

interface Props {
  aggregatedPayment: AggregatedPayment
  onSuccess: () => void
  onClose: () => void
}

export default function DeleteAggregatedPaymentModal(props: Props) {
  const { aggregatedPayment, onSuccess, onClose } = props

  const { toast } = useToast()

  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const deletePayment = async () => {
    try {
      setSubmitting(true)
      await aggregatedPaymentsService.deleteAggregatePayment(aggregatedPayment.id)
      toast({
        message: 'Faturamento deletado com sucesso',
        type: 'success',
      })
      onSuccess()
      onClose()
    } catch (error) {
      toast({
        message: 'Erro ao deletar faturamento',
        type: 'error',
      })
      setSubmitting(false)
    }
  }

  const { Theme } = useContext(GlobalContext)

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Deletar Faturamento
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', overflowY: 'auto' }}
      >
        <CheckMarkDiv>
          <img alt='checkmark' src={`${ExclamationMark}`} />
        </CheckMarkDiv>
        <Typography color='black'>
          Você está prestes a deletar o faturamento e baixar o boleto do mesmo. Tem certeza que deseja prosseguir?
        </Typography>

      </Modal.Body>
      <Stack
        direction='row'
        style={{
          display: 'flex',
          width: '100%',
          position: 'sticky',
          padding: '16px',
          bottom: '-16px',
          backgroundColor: 'white',
        }}
      >
        <Button text='Fechar' mode='white' onClick={onClose} disabled={isSubmitting} />
        <Button
          text='Deletar'
          onClick={deletePayment}
          disabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Stack>
    </Modal.Provider>
  )
}
