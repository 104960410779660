import _ from 'lodash'

export function removeEmptyFields(obj: Record<string, unknown>) {
  const clonedObj = _.cloneDeep(obj);
  function clean(item: any) {
      _.forEach(item, (value, key) => {
          if (_.isNull(value) || _.isUndefined(value)) {
              delete item[key];
          } else if (_.isObject(value)) {
              clean(value);
          }
      });
  }
  clean(clonedObj);
  return clonedObj;
}

export function countDecimals(num: number) {
    if (!isNaN(num) && Math.floor(num) !== num) {
      const numParts = num.toString().split(".");
      return numParts.length > 1 ? numParts[1].length : 0;
    }
    return 0;
  }

export function parseNumbers(obj: Record<string, unknown>) {
    const clonedObj = _.cloneDeep(obj);
    function clean(item: any) {
        _.forEach(item, (value, key) => {
            if(typeof value === 'string' && !isNaN(Number(value))) {
                const decimals = countDecimals(parseFloat(value))
                const updatedValue = !isNaN(Number(value)) ? parseFloat(Number(value).toFixed(decimals)) : value
                item[key] = updatedValue
            }

            if (_.isNull(value) || _.isUndefined(value)) {
                delete item[key];
            } else if (_.isObject(value)) {
                clean(value);
            }
        });
    }
    clean(clonedObj);
    return clonedObj;
  }