import styled from "styled-components";
import { Button } from "../../../../../../components/Button";
import { Theme } from "../../../../../../styles/theme";
import { darken } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

export const CancelButton = styled(Button)`
  width: 152px;
  height: 40px;

  background: ${Theme.colors.white};
  border-radius: 8px;

  &:hover {
    background-color: ${darken(0.1, Theme.colors.white)};
    cursor: pointer;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 16px;
`