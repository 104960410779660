import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { CheckCircle, ErrorOutline, HourglassEmpty } from '@mui/icons-material'
import { useState } from 'react'
import InvoiceModal from '../InvoiceModal'
import { useInvoiceList } from '../../hooks/useInvoiceList'
import Loading from '../../../../../components/Loading'

export default function InvoiceList() {
  const { invoiceList, isLoading, updateInvoiceFromList, removeInvoiceFromList } = useInvoiceList()

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {selectedInvoiceId && (
        <InvoiceModal
          invoiceParserId={selectedInvoiceId}
          onReanalyze={updateInvoiceFromList}
          onCreate={removeInvoiceFromList}
          onClose={() => setSelectedInvoiceId(undefined)}
        />
      )}

      {invoiceList.length === 0 ? (
        <p>Nenhuma fatura está sendo processada</p>
      ) : (
        <>
          <h3 style={{ marginTop: '8px', fontWeight: 400 }}>Faturas processadas</h3>
          <List>
            {invoiceList.map((invoice) => (
              <ListItem
                key={`invoice-${invoice.id}`}
                style={{
                  borderRadius: '8px',
                  margin: '8px 0',
                  backgroundColor: '#f0f0f0',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (invoice.status === 'COMPLETED' || invoice.raw_data) {
                    setSelectedInvoiceId(invoice.id)
                  }
                }}
              >
                <ListItemIcon style={{ minWidth: 'unset', marginRight: '16px' }}>
                  {(invoice.status === 'PROCESSING' || invoice.status === 'READING') && (
                    <HourglassEmpty style={{ color: 'orange' }} />
                  )}
                  {invoice.status === 'COMPLETED' && <CheckCircle style={{ color: 'green' }} />}
                  {invoice.status === 'CANCELED' && <ErrorOutline style={{ color: 'red' }} />}
                </ListItemIcon>
                <ListItemText>
                  <p>
                    <strong>Fatura:</strong> {invoice.id}{' '}
                    <strong>({invoice.originalFileName})</strong>
                  </p>
                  {invoice.status === 'READING' && 'Efetuando releitura de dados...'}
                  {invoice.status === 'PROCESSING' && 'Aguardando processamento...'}
                  {invoice.status === 'COMPLETED' && 'Clique para conferir dados'}
                  {invoice.status === 'CANCELED' && invoice.error && (
                    <>
                      <strong>Ocorreu um erro:</strong> {invoice.error}
                    </>
                  )}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  )
}
