import styled from "@emotion/styled";

export const LegendDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 41px;
  justify-content: center;
`

export const LegendText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: #808080;
`

export const LegendSubDiv = styled(LegendDiv)<{ active: boolean }>`
  gap: 4px;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`

export const LegendCircle = styled.div<{ color: string }>`
  border-radius: 10px;
  background: ${({ color }) => `${color}`};
  width: 10px;
  height: 10px;
  flex-shrink: 0;
`