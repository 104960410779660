import { useEffect } from 'react'
import {
  Container,
  Content,
  ContentDouble,
  Double,
  EnergiaInjetada,
  InputDiv,
  Table,
  Text,
  Title,
} from './styles'
import { calculateTar, calculateVal, formatDateRef, maxValue, transformDate } from '../utils/format'
import { ITemplate } from '../utils/interface'
import axios from 'axios'
import moment from 'moment'
import { v4 } from 'uuid'

export function EdpTemplate({
  data,
  changeInfo,
  changeInfoPrice,
  changeInfoDate,
  changeInfoNegative,
  setData,
  extra,
  setExtra,
}: {
  data: ITemplate
  setData: (value: ITemplate) => void
  changeInfo: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeInfoPrice: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeInfoDate: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeInfoNegative: (event: React.ChangeEvent<HTMLInputElement>) => void
  ucs: string[]
  extra: any
  setExtra: (value: any) => void
}) {
  useEffect(() => {
    if (data.data_leitura_anterior.length === 10 && data.data_leitura_atual.length === 10) {
      const data1 = moment(transformDate(data.data_leitura_atual))
      const data2 = moment(transformDate(data.data_leitura_anterior))

      setData({
        ...data,
        dias_faturados: String(-data2.diff(data1, 'days')),
      })
    }
  }, [data.data_leitura_atual, data.data_leitura_anterior])

  useEffect(() => {
    ;(async () => {
      if (data.cep && data.cep.length === 9) {
        const cep = (await axios
          .get(`https://viacep.com.br/ws/${data.cep.replace('-', '')}/json`)
          .then((res) => res.data)) as any

        if (cep) {
          setData({
            ...data,
            cidade: cep.localidade,
            uf: cep.uf,
          })
        }
      }
    })()
  }, [data.cep])

  function extraChange(id: any, change: any) {
    const exist = extra.filter((val: any) => val.id !== id)
    const diff = extra.filter((val: any) => val.id === id)[0]

    setExtra([...exist, { ...diff, ...change }].sort((a: any, b: any) => a.sort - b.sort))
  }

  return (
    <Container>
      <Content>
        <ContentDouble>
          <Double>
            <Content>
              <Title>Cliente / Endereço de Entrega</Title>
              <Text>
                <Text>{data.nome}</Text>
                <InputDiv>
                  <div>
                    <label>GRUPO/SUBGRUPO: {data.grupo} - </label>
                    <input
                      value={data.subgrupo}
                      id='subgrupo'
                      onChange={(e) => setData({ ...data, subgrupo: maxValue(e.target.value, 3) })}
                      style={{ width: '40px', textAlign: 'center' }}
                    />
                  </div>
                  <div>
                    <label>CLASSE/SUBCLASSE: </label>
                    <input value={data.classe} id='classe' onChange={changeInfo} />
                  </div>
                </InputDiv>
                <InputDiv>
                  <div>
                    <label>TP Fornecimento: </label>
                    <input value={data.fases} id='fases' onChange={changeInfo} />
                  </div>
                  <div>
                    <label>Modalidade: </label>
                    <input value={data.modalidade} id='modalidade' onChange={changeInfo} />
                  </div>
                </InputDiv>
              </Text>
              <Title>Local de Consumo</Title>
              <Text>
                <InputDiv>
                  <Text>CNPJ/CPF/CI: {data.doc}</Text>
                </InputDiv>
                <InputDiv>
                  <div style={{ width: '100%' }}>
                    <label>ENDEREÇO: </label>
                    <input value={data.endereco} id='endereco' onChange={changeInfo} />
                  </div>
                </InputDiv>
                <InputDiv>
                  <div style={{ width: '100%' }}>
                    <label>CEP: </label>
                    <input value={data.cep} id='cep' onChange={changeInfo} />
                    <p>
                      {data.cidade} - {data.uf}
                    </p>
                  </div>
                </InputDiv>
              </Text>
            </Content>
          </Double>
          <Double>
            <Content>
              <ContentDouble>
                <Double>
                  <Title>Valor total a pagar</Title>
                  <InputDiv>
                    <div className='bigInput'>
                      <input
                        id='valor_total_fatura'
                        value={data.valor_total_fatura}
                        onChange={changeInfoPrice}
                      />
                    </div>
                  </InputDiv>
                </Double>
                <Double>
                  <Title>Número da instalação *</Title>
                  <InputDiv>
                    <div className='bigInput'>
                      <input value={data.uc} id='uc' onChange={changeInfo} />
                    </div>
                  </InputDiv>
                </Double>
              </ContentDouble>
            </Content>
            <Content>
              <ContentDouble>
                <Double>
                  <Title>Consumo mês / kWh</Title>
                  <InputDiv>
                    <div className='bigInput'>
                      <input
                        id='consumo_spt_qt'
                        value={data.consumo_spt_qt}
                        onChange={changeInfo}
                      />
                    </div>
                  </InputDiv>
                </Double>
                <Double>
                  <Title>Data de vencimento *</Title>
                  <InputDiv>
                    <div className='bigInput'>
                      <input
                        id='data_vencimento'
                        value={data.data_vencimento}
                        onChange={changeInfoDate}
                      />
                    </div>
                  </InputDiv>
                </Double>
              </ContentDouble>
            </Content>
            <Content>
              <Title>Conta do mês *</Title>
              <InputDiv>
                <div className='bigInput'>
                  <input
                    value={data.mes_referencia}
                    onChange={(e) =>
                      setData({ ...data, mes_referencia: formatDateRef(e.target.value) })
                    }
                  />
                </div>
              </InputDiv>
            </Content>
            <Content>
              <Title>Período de Faturamento *</Title>
              <Text>
                <InputDiv>
                  <div style={{ width: '100%' }}>
                    <label>Emissão*: </label>
                    <input id='data_emissao' value={data.data_emissao} onChange={changeInfoDate} />
                  </div>
                </InputDiv>
                <InputDiv>
                  <div style={{ width: '100%' }}>
                    <label>Leitura Anterior*: </label>
                    <input
                      id='data_leitura_anterior'
                      value={data.data_leitura_anterior}
                      onChange={changeInfoDate}
                    />
                  </div>
                </InputDiv>
                <InputDiv>
                  <div style={{ width: '100%' }}>
                    <label>Leitura Atual*: </label>
                    <input
                      id='data_leitura_atual'
                      value={data.data_leitura_atual}
                      onChange={changeInfoDate}
                    />
                  </div>
                </InputDiv>
                Nª Dias de Faturamento: {data.dias_faturados ?? 0} dias
                <InputDiv>
                  <div style={{ width: '100%' }}>
                    <label>PREV PRÓXIMA LEITURA: </label>
                    <input
                      id='data_proxima_leitura'
                      value={data.data_proxima_leitura}
                      onChange={changeInfoDate}
                    />
                  </div>
                </InputDiv>
              </Text>
            </Content>
          </Double>
        </ContentDouble>
        <Content>
          <Title>Detalhes de Faturamento</Title>
          <Table cellSpacing={0}>
            <thead>
              <tr>
                <th>CC DESCRIÇÃO DO PRODUTO</th>
                <th>QUANTIDADE kWh</th>
                <th>TARIFA APLIC. (R$)</th>
                <th>TARIFA C/ IMPOSTOS</th>
                <th>VALOR TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>TUSD - Energia Ativa Fornecida</td>
                <td>{data.consumo_spt_qt}</td>
                <td>
                  <input
                    style={{ width: '100px' }}
                    value={data.consumo_spt_tusd_tar}
                    id='consumo_spt_tusd_tar'
                    onChange={changeInfo}
                  />
                </td>
                <td>
                  {calculateTar(
                    data.consumo_spt_tusd_tar,
                    data.tot_trib_icms_aliq,
                    data.tot_trib_cofins_aliq,
                    data.tot_trib_pis_aliq,
                  )}
                </td>
                <td>
                  {calculateVal(
                    data.consumo_spt_qt,
                    calculateTar(
                      data.consumo_spt_tusd_tar,
                      data.tot_trib_icms_aliq,
                      data.tot_trib_cofins_aliq,
                      data.tot_trib_pis_aliq,
                    ),
                  )}
                </td>
              </tr>
              <tr>
                <td>TUSD - Energia Ativa Inj. oUC oPT</td>
                <td>
                  <input
                    id='energia_comp_spt_qt_opt'
                    style={{ width: '100px' }}
                    value={data.energia_comp_spt_qt_opt}
                    onChange={changeInfoNegative}
                  />
                </td>
                <td>{data.consumo_spt_tusd_tar}</td>
                <td>
                  <input value={data.ouc_tusd_opt} id='ouc_tusd_opt' onChange={changeInfo} />
                </td>
                <td>{calculateVal(data.energia_comp_spt_qt_opt, data.ouc_tusd_opt)}</td>
              </tr>
              {extra
                ? extra.map((val: any, i: number) => {
                    return (
                      <>
                        <tr>
                          <td>TUSD - Energia Ativa Inj. oUC oPT {i + 2}</td>
                          <td>
                            <input
                              value={val.energia_comp_spt_qt}
                              onChange={(e) =>
                                extraChange(val.id, {
                                  energia_comp_spt_qt: `${
                                    parseFloat(e.target.value.replace('-', '')) > 0 ? '-' : ''
                                  }${e.target.value.replace('-', '')}`,
                                })
                              }
                            />
                          </td>
                          <td>{data.consumo_spt_tusd_tar}</td>
                          <td>
                            <input
                              value={val.tusd}
                              onChange={(e) => extraChange(val.id, { tusd: e.target.value })}
                            />
                          </td>
                          <td>{calculateVal(val.energia_comp_spt_qt, val.tusd)}</td>
                        </tr>
                      </>
                    )
                  })
                : null}
              <tr>
                <td>TUSD - Energia Ativa Inj. oUC mPT</td>
                <td>
                  <input
                    id='energia_comp_spt_qt'
                    style={{ width: '100px' }}
                    value={data.energia_comp_spt_qt}
                    onChange={changeInfoNegative}
                  />
                </td>
                <td>{data.consumo_spt_tusd_tar}</td>
                <td>
                  <input value={data.ouc_tusd} id='ouc_tusd' onChange={changeInfo} />
                </td>
                <td>{calculateVal(data.energia_comp_spt_qt, data.ouc_tusd)}</td>
              </tr>
              <tr>
                <td>TE - Energia Ativa Fornecida</td>
                <td>{data.consumo_spt_qt}</td>
                <td>
                  <input
                    style={{ width: '100px' }}
                    value={data.consumo_spt_te_tar}
                    id='consumo_spt_te_tar'
                    onChange={changeInfo}
                  />
                </td>
                <td>
                  {calculateTar(
                    data.consumo_spt_te_tar,
                    data.tot_trib_icms_aliq,
                    data.tot_trib_cofins_aliq,
                    data.tot_trib_pis_aliq,
                  )}
                </td>
                <td>
                  {calculateVal(
                    data.consumo_spt_qt,
                    calculateTar(
                      data.consumo_spt_te_tar,
                      data.tot_trib_icms_aliq,
                      data.tot_trib_cofins_aliq,
                      data.tot_trib_pis_aliq,
                    ),
                  )}
                </td>
              </tr>
              <tr>
                <td>TE - Energia Ativa Inj. oUC oPT</td>
                <td>{data.energia_comp_spt_qt_opt}</td>
                <td>{data.consumo_spt_te_tar}</td>
                <td>
                  <input value={data.ouc_te_opt} id='ouc_te_opt' onChange={changeInfo} />
                </td>
                <td>{calculateVal(data.energia_comp_spt_qt_opt, data.ouc_te_opt)}</td>
              </tr>
              {extra
                ? extra.map((val: any, i: number) => {
                    return (
                      <>
                        <tr>
                          <td>TE - Energia Ativa Inj. oUC oPT {i + 2}</td>
                          <td>{val.energia_comp_spt_qt}</td>
                          <td>{data.consumo_spt_te_tar}</td>
                          <td>
                            <input
                              value={val.te}
                              onChange={(e) => extraChange(val.id, { te: e.target.value })}
                            />
                          </td>
                          <td>{calculateVal(val.energia_comp_spt_qt, val.te)}</td>
                        </tr>
                      </>
                    )
                  })
                : null}
              <tr>
                <td>TE - Energia Ativa Inj. oUC mPT</td>
                <td>{data.energia_comp_spt_qt}</td>
                <td>{data.consumo_spt_te_tar}</td>
                <td>
                  <input value={data.ouc_te} id='ouc_te' onChange={changeInfo} />
                </td>
                <td>{calculateVal(data.energia_comp_spt_qt, data.ouc_te)}</td>
              </tr>
              <tr>
                <td>
                  <EnergiaInjetada
                    onClick={() => {
                      setExtra([
                        ...extra,
                        {
                          id: v4(),
                          sort: extra.length + 10,
                          energia_comp_spt_qt: '',
                          tusd: '',
                          te: '',
                        },
                      ])
                    }}
                  >
                    + Adicionar Energia Injetada
                  </EnergiaInjetada>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Content>
        <ContentDouble>
          <Double>
            <Title>Impostos</Title>
            <InputDiv>
              <div>
                <label>Aliquota % PIS: </label>
                <input
                  value={data.tot_trib_pis_aliq}
                  id='tot_trib_pis_aliq'
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>Aliquota % COFINS: </label>
                <input
                  value={data.tot_trib_cofins_aliq}
                  id='tot_trib_cofins_aliq'
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>Aliquota % ICMS: </label>
                <input
                  value={data.tot_trib_icms_aliq}
                  id='tot_trib_icms_aliq'
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>Cosip: </label>
                <input id='cosip' value={data.cosip} onChange={changeInfoPrice} />
              </div>
            </InputDiv>
          </Double>
          <Double>
            <Title>Detalhes de Faturamento</Title>
            <InputDiv>
              <div>
                <label>Saldo Atualizado no mês: </label>
                <input
                  id='creditos_estoque_spt'
                  value={data.creditos_estoque_spt}
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>Média geração SPT quantidade: </label>
                <input
                  id='med_ger_spt_quant'
                  value={data.med_ger_spt_quant}
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>Média geração P quantidade: </label>
                <input id='med_ger_p_quant' value={data.med_ger_p_quant} onChange={changeInfo} />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>Média geração FP quantidade: </label>
                <input id='med_ger_fp_quant' value={data.med_ger_fp_quant} onChange={changeInfo} />
              </div>
            </InputDiv>
          </Double>
        </ContentDouble>
      </Content>
    </Container>
  )
}
