import { HTMLAttributes, PropsWithChildren } from 'react'
import { Body } from './styles'

type Props = HTMLAttributes<HTMLDivElement>

export default function ModalBody(props: PropsWithChildren<Props>) {
  const { children, ...rest } = props

  return <Body {...rest}>{children}</Body>
}
