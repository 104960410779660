import styled from 'styled-components'
import WidgetComponent from '../../Widget'

export const Widget = styled(WidgetComponent)`
  text {
    font-family: 'Roboto' !important;
    fill: #808080 !important;
  }

  max-width: 200px;
  height: 337px;
  margin-left: 16px;
  z-index: 10;
`

interface IHoverContainerProps {
  isVisible: boolean
  top: number
  left: number
}

export const HoverContainer = styled.div<IHoverContainerProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};

  flex-direction: column;
  gap: 20px;

  position: absolute;
  transform: translateY(-50%);
  top: ${({ top }) => top}px;
  left: ${({ left }) => left + 20}px;

  width: max-content;
  min-width: 200px;

  padding: 10px;

  background-color: #f9fafd;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};

  border-radius: 6px;

  color: black;
  font-size: 12px;
  word-spacing: 2px;

  z-index: 1000;
`
