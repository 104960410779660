import React from 'react'
import Widget from '../Widget'
import {
  Container,
  ContainerLimits,
  IconContainer,
  InfoContainer,
  InfoTitle,
  InfoValue,
  ValueContainer,
} from './styles'

interface WidgetProps {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  title: string
  value: number | undefined
  isPercentage?: boolean
  iconColor?: string
}

export function WidgetPayments({ Icon, iconColor, title, value, isPercentage }: WidgetProps) {
  const formattedNumber = !isPercentage
    ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(value))
    : `${Number(value && Math.floor(value))}%`

  return (
    <Widget>
      <ContainerLimits>
        <Container>
          <IconContainer iconColor={iconColor}>
            <Icon className='colorPath' />
          </IconContainer>
          <InfoContainer>
            <InfoTitle>{title}</InfoTitle>
            <ValueContainer>
              <InfoValue>{formattedNumber}</InfoValue>
            </ValueContainer>
          </InfoContainer>
        </Container>
      </ContainerLimits>
    </Widget>
  )
}

export default WidgetPayments
