import { useEffect, useLayoutEffect, useRef, useState, useContext } from 'react'
import {
  Wrapper,
  Card,
  RowContainer,
  Divider,
  CartitemContainer,
  Text,
  ContainerItem,
  IContainerItemProps,
  Title,
  Select,
  SelectItem,
  ContainerGridProduction,
  IconContaienr,
  JustifyBetween,
  ContainerMainChartHeader,
  WarningContainer,
  GoalProd,
  DownloadDiv,
} from './styles'
import { ReactComponent as CoalIcon } from '../../assets/coal.svg'
import { ReactComponent as TreeIcon } from '../../assets/tree.svg'
import { ReactComponent as Co2Icon } from '../../assets/co2.svg'
import { MeasurementsChart } from './MeasurementsChart'
import {
  getMeasurementsChartData,
  getAllPlantsByClientId,
  getMeasurementsData,
} from './../../services/requests/user-requests'
import {
  MeasurementsChartArrayType,
  MeasurementsData,
} from '../../services/requests/types'
import DownloadIcon from '../../assets/download-icon-white.svg'
import { monthsList } from '../../utils/selectDates'
import { numberToString } from '../../utils/formatNumberToString'
import Loading from '../../components/Loading'
import { api } from './../../services/api'
import { GlobalContext } from '../../context/global/global'
import { GenericTooltip } from '../../components/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import { DateFilter } from '../../components/Filter/DateFilter'
import { DailyProductionModal, SelectedDayType } from './components/DailyProductionModal'
import { measurementService } from '../../services/requests/measurement-requests'
import moment from 'moment'
import { useToast } from '../../hooks/useToast'
import { downloadFileUtil } from '../../utils/download-file'
import { set } from 'lodash'

interface IValueComponentProps extends IContainerItemProps {
  label: string
  labelDescription?: string
  value?: string
  unitOfMeasurement?: string
  footerText?: string
}

const ValueComponent = ({
  label,
  value,
  unitOfMeasurement,
  labelDescription,
  padding,
  footerText,
  ...props
}: IValueComponentProps) => {
  const { Theme } = useContext(GlobalContext)

  return (
    <ContainerItem padding={padding ?? '0 0 0 20px'} {...props}>
      <ContainerItem gap='0' width='100%'>
        <Text size='20px' width='fit-content'>
          {label}
        </Text>
        {labelDescription && (
          <Text size='15px' width='100%'>
            {labelDescription}
          </Text>
        )}
      </ContainerItem>
      <ContainerItem gap='0'>
        <ContainerItem directionRow width='100%'>
          <Title
            size='24px'
            title={`${value} ${unitOfMeasurement}`}
            width='max-content'
            color={Theme.colors.darkishGreen}
          >
            {value}
          </Title>
          <Text
            size='20px'
            title={`${value} ${unitOfMeasurement}`}
            width='max-content'
            color={Theme.colors.darkishGreen}
          >
            {unitOfMeasurement}
          </Text>
        </ContainerItem>
      </ContainerItem>
      {footerText && (
        <Title size='16px' color={Theme.colors.darkishGreen}>
          {footerText}
        </Title>
      )}
    </ContainerItem>
  )
}

interface IFetchMainChartDataProps {
  plantId: number
  month?: number
  year?: number
}

interface IFetchMonthlyChartDataProps {
  plantId: number
  month?: number
}

export function Measurements() {
  const { Theme, state } = useContext(GlobalContext)
  const { toast } = useToast()
  const userEmail = state.auth?.email

  const [isLoading, setIsloading] = useState<boolean>(true)
  const [isDailyModalOpen, setIsDailyModalOpen] = useState(false)

  const [plantSelectedId, setplantSelectedId] = useState<number | null>(null)
  const [address, setAddress] = useState<string | null>(null)
  const [dailyCapacity, setDailyCapacity] = useState<number>(0)
  const [monthlyCapacity, setMonthlyCapacity] = useState<number>(0)
  const [yearlyCapacity, setYearlyCapacity] = useState<number>(0)
  const [last12Months, setLast12Months] = useState<number>(0)
  const [selectedDailyGraphItem, setSelectedDailyGraphItem] = useState<null | {
    value?: number
    day: string,
    month?: number,
    year?: number
  }>(null)

  const [plants, setPlants] = useState<Array<{
    label: string
    value: number
    installed_power: number
    cep: string
    endereco_cidade?: string
    endereco_uf?: string
  }> | null>(null)

  const plantSelected =
    plants && plantSelectedId
      ? plants.filter((plant) => plant.value === plantSelectedId)[0]
      : {
        label: '',
        value: 0,
        installed_power: 0,
        cep: '',
      }

  const [measurementsData, setMeasurementsData] = useState<MeasurementsData['data'] | null>(null)

  const [mainChartData, setMainChartData] = useState<Array<{
    label: string
    value: number
    hoverTitle: string,
    month: number,
    year?: number
  }> | null>(null)
  const [mainChartDataLoading, setMainChartDataLoading] = useState<boolean>(false)

  const [monthlyChartData, setMonthlyChartData] = useState<Array<{
    label: string
    value: number
    hoverTitle: string
    additionalData: MeasurementsChartArrayType
  }> | null>(null)
  const [monthlyChartDataLoading, setMonthlyChartDataLoading] = useState<boolean>(false)

  const fetchPlants = async () => {
    try {
      const { data } = await getAllPlantsByClientId()

      setPlants(
        data.map((plant) => ({
          label: plant.nome,
          value: plant.id,
          installed_power: plant.potencia_instalada,
          cep: plant.cep,
          endereco_cidade: plant.endereco_cidade,
          endereco_uf: plant.endereco_uf,
        })),
      )
      return data
    } catch (err) {
      console.error(err)
      return null
    }
  }

  function getFirstAndLastDayOfMonth(year: number, month: number) {
    const firstDay = new Date(year, month, 1).toISOString()
    const lastDay = new Date(year, month + 1, 0).toISOString()

    return {
      firstDay,
      lastDay,
    }
  }

  const fetchMeasurementsData = async ({
    plantId,
    month = undefined,
    year,
  }: IFetchMainChartDataProps) => {
    const date = new Date()
    if (year) {
      date.setFullYear(year)
    }

    const fromTo = getFirstAndLastDayOfMonth(
      date.getFullYear(),
      month !== undefined ? month : date.getMonth(),
    )

    try {
      const { data } = await getMeasurementsData({
        from: fromTo.firstDay,
        to: fromTo.lastDay,
        plantId,
      })
      setMeasurementsData(data.data)
      setMonthlyCapacity(data.data.monthCapacity * 100)
      setYearlyCapacity(data.data.yearCapacity * 100)
      setLast12Months(data.data.twelveMonthCapacity * 100)
    } catch (err) {
      console.error(err)
    }
  }

  const fetchMainChartData = async ({
    plantId,
    month = undefined,
    year,
  }: IFetchMainChartDataProps) => {
    const date = new Date()
    if (year) {
      date.setFullYear(year)
    }

    const FromTo = getFirstAndLastDayOfMonth(
      date.getFullYear(),
      month !== undefined ? month : date.getMonth(),
    )

    try {
      const response = await getMeasurementsChartData({
        plantId: plantId,
        from: FromTo.firstDay,
        to: FromTo.lastDay,
        granularity: 'daily',
      })
      const data = response.data.data

      setDailyCapacity(data.length > 0 ? Number(data[data.length - 1].capacidade) * 100 : 0)

      const daysOfEachMonth = [
        31, // Janeiro
        28, // Fevereiro
        31, // Março
        30, // Abril
        31, // Maio
        30, // Junho
        31, // Julho
        31, // Agosto
        30, // Setembro
        31, // Outubro
        30, // Novembro
        31, // Dezembro
      ]

      const fillDaysOfTheMonth = () => {
        const month = new Date(data[0].data).getMonth()
        const daysOfTheMonth = daysOfEachMonth[month]

        if (data.length > daysOfTheMonth) {
          return data.map(({ geracao }, index) => ({
            value: geracao,
            label: `${index + 2}`,
            hoverTitle: `
              ${index + 1 < 10 ? `0${index + 1}` : index + 1}/
              ${monthsList[month].label.slice(0, 3).toUpperCase()}
            `,
            month,
            year
          }))
        }

        const newArray = new Array(daysOfTheMonth).fill(0).map((_, index) => ({
          value: 0,
          label: `${index + 1}`,
          hoverTitle: `-`,
          month,
          year
        }))

        data.forEach(({ data, geracao }) => {
          const day = new Date(data).getUTCDate() - 1
          newArray[day].value = geracao
          newArray[day].hoverTitle = `
            ${day + 1 < 10 ? `0${day + 1}` : day + 1}/
            ${monthsList[month].label.slice(0, 3).toUpperCase()}
          `
        })

        return newArray
      }

      setMainChartData(data.length > 0 ? fillDaysOfTheMonth() : [])
    } catch (err) {
      console.error(err)
    }
  }

  const fetchMonthlyChartData = async ({ plantId, month }: IFetchMonthlyChartDataProps) => {
    const currentMonth = new Date().getMonth()

    const firstMonth = new Date()
    const lastMonth = new Date()

    firstMonth.setMonth((month || currentMonth) - 11)
    lastMonth.setMonth(month || currentMonth)

    try {
      const response = await getMeasurementsChartData({
        plantId: plantId,
        from: firstMonth.toISOString(),
        to: lastMonth.toISOString(),
        granularity: 'monthly',
      })
      const data = response.data.data.sort((a, b) => {
        const getDateInMS = (value: string) => new Date(value)

        if (getDateInMS('01/' + a.data) < getDateInMS('01/' + b.data)) {
          return -1
        }
        if (getDateInMS('01/' + a.data) > getDateInMS('01/' + b.data)) {
          return 1
        }

        return 0
      })

      const months = [
        'JAN',
        'FEV',
        'MAR',
        'ABR',
        'MAI',
        'JUN',
        'JUL',
        'AGO',
        'SET',
        'OUT',
        'NOV',
        'DEZ',
      ]

      const fillEmptyMonths = () => {
        const newArray = data.map((item) => ({
          value: item.geracao,
          label: months[Number(item.data.split('/')[0]) - 1],
          hoverTitle: `
            ${months[Number(item.data.slice(0, 2)) - 1]}
            ${item.data.slice(2)}`,
          additionalData: item,
        }))

        if (newArray.length < 13) {
          const mockArray = new Array(13 - newArray.length).fill({
            value: 0,
            label: '-',
            hoverTitle: `-`,
            additionalData: {},
          })

          return [...mockArray, ...newArray]
        }

        return newArray
      }

      setMonthlyChartData(data.length > 0 ? fillEmptyMonths() : [])
    } catch (err) {
      console.error(err)
    }
  }

  const fetchAddress = async (cep: string, cidade: string, uf: string) => {
    try {
      let response
      if(cep) {
        response = await api.get(`https://viacep.com.br/ws/${cep}/json/`).catch((error) => {
          console.error(error)
          return { data: { erro: true } }
        }).catch((error) => null).then((response) => response)
      } else {
        response = { data: { erro: true } }
      }

      if(response) {
        console.log(response)
        if (!response?.data.erro) {
          setAddress(`${response?.data.localidade} - ${response?.data.uf}`)
          return
        }
      }

      if(!cidade || !uf) {
        toast({
          type: 'error',
          message: 'Não foi possível encontrar o endereço da planta'
        })

        setAddress('Endereço não encontrado')
        return
      }

      setAddress(`${cidade} - ${uf}`)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      const planstIds = await fetchPlants()
      if (planstIds?.[0]?.id) {
        setplantSelectedId(planstIds[0].id)
        await Promise.all([
          fetchAddress(planstIds[0].cep, planstIds[0].endereco_cidade, planstIds[0].endereco_uf),
          fetchMeasurementsData({ plantId: planstIds[0].id }),
          fetchMainChartData({ plantId: planstIds[0].id }),
          fetchMonthlyChartData({ plantId: planstIds[0].id }),
        ])
      } else {
        setIsloading(false)
      }
    })()
  }, [])

  const readyToUpdateOnSelectChange = useRef(false)
  useLayoutEffect(() => {
    if (
      !readyToUpdateOnSelectChange.current &&
      plantSelectedId !== null &&
      mainChartData !== null &&
      monthlyChartData !== null &&
      measurementsData !== null
    ) {
      if (isLoading) {
        setIsloading(false)
      } else {
        readyToUpdateOnSelectChange.current = true
      }
    }
  }, [plantSelectedId, mainChartData, measurementsData, monthlyChartData, isLoading])

  useEffect(() => {
    if (plantSelectedId && readyToUpdateOnSelectChange.current) {
      (async () => {
        setMainChartDataLoading(true)
        setMonthlyChartDataLoading(true)
        const plant = plants!.filter((plant) => plant.value === plantSelectedId)[0]
        await Promise.all([
          fetchAddress(plant.cep, plant.endereco_cidade || '', plant.endereco_uf || ''),
          fetchMeasurementsData({ plantId: plantSelectedId }),
          fetchMainChartData({ plantId: plantSelectedId }),
          fetchMonthlyChartData({ plantId: plantSelectedId }),
        ])

        setMainChartDataLoading(false)
        setMonthlyChartDataLoading(false)
      })()
    }
  }, [plantSelectedId])

  const changeMainChartDate = async (month: number, year: number) => {
    if (plantSelectedId && readyToUpdateOnSelectChange.current) {
      setMainChartDataLoading(true)
      await fetchMainChartData({
        plantId: plantSelectedId,
        month: month - 1,
        year,
      })
      setMainChartDataLoading(false)
    }
  }

  const changeMeasurementsDate = async (month: number, year: number) => {
    if (plantSelectedId && readyToUpdateOnSelectChange.current) {
      await fetchMeasurementsData({
        plantId: plantSelectedId,
        month: month - 1,
        year,
      })
    }
  }

  const handleToggleDailyModal = () => setIsDailyModalOpen(prev => !prev)

  const handleDailyItemClick = (fields: Record<string, any>) => {
    const month = mainChartData?.at(0)?.month ?? moment().get('month')
    const year = mainChartData?.at(0)?.year ?? moment().get('year')

    setSelectedDailyGraphItem({
      value: fields?.value,
      day: fields?.label,
      month,
      year
    })
    handleToggleDailyModal()
  }

  const handleConfirmDownloadDailyModal = (fields?: SelectedDayType | null) => {
    if (!plantSelectedId) {
      toast({
        type: 'info',
        message: 'Selecione uma planta para baixar'
      })

      return
    }

    const now = moment()

    measurementService.downloadDailyConsumption(
      plantSelectedId,
      Number(fields?.day ?? 1),
      fields?.month ?? now.get('month'),
      fields?.year ?? now.get('year')
    )
      .then(value => {
        const date = moment().utc().format('YYYY-MM-DD')

        downloadFileUtil(`consumo-${date}.xlsx`, value)
      })
      .catch(err => {
        const message =
          err?.data?.response?.message ?? "Não foi possível baixar planilha"

        toast({
          type: 'error',
          message
        })
      })
  }

  return isLoading ? (
    <Loading />
  ) : !plants || plants.length === 0 ? (
    <WarningContainer>
      <Title size='32px' width='fit-content' color={Theme.colors.darkishGreen}>
        Nenhuma planta encontrada.
      </Title>
      <Text size='16px' width='fit-content' color={Theme.colors.darkishGreen}>
        Vá até{' '}
        <strong>
          {' '}
          Minhas unidades {'>'} Cadastro {'>'} Inversor{' '}
        </strong>
        e insira as credenciais de acesso.
      </Text>
    </WarningContainer>
  ) : (
    <Wrapper>
      <DailyProductionModal
        isOpen={isDailyModalOpen}
        onClose={handleToggleDailyModal}
        onClick={handleConfirmDownloadDailyModal}
        selectedDay={selectedDailyGraphItem}
      />

      <DateFilter
        onChange={({ month, year }) => {
          changeMeasurementsDate(month, year)
          changeMainChartDate(month, year)
        }}
      />

      <RowContainer>
        <Card width='350px'>
          <CartitemContainer
            maxHeight='130px'
            width='100%'
            gap='10px'
            alignAllCenter
            flexDirection='column'
            height='100%'
            justifyContent='center'
          >
            <Text>Localização:</Text>
            <Text color='black'>{address ? address : '...'}</Text>
          </CartitemContainer>
          <Divider />
          <CartitemContainer
            width='100%'
            height='100%'
            gap='10px'
            alignAllCenter
            flexDirection='column'
            justifyContent='center'
          >
            <Text>Planta:</Text>
            {plants && (
              <Select
                value={plantSelectedId}
                onChange={(e) => setplantSelectedId(e.target.value as number)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      width: 210,
                      marginLeft: 20,
                      borderRadius: 8,
                    },
                  },
                }}
              >
                {plants.map((plant, index) => (
                  <SelectItem
                    key={index}
                    defaultChecked={index === 0}
                    value={plant.value}
                    onMouseDown={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                  >
                    {plant.label}
                  </SelectItem>
                ))}
              </Select>
            )}
          </CartitemContainer>
        </Card>
        <Card height='260px'>
          <ContainerGridProduction
            withGoalProd={
              monthlyChartData &&
                monthlyChartData.length > 0 &&
                monthlyChartData[monthlyChartData.length - 1]?.additionalData?.meta?.valor
                ? true
                : false
            }
          >
            <ValueComponent
              label='Potência instalada'
              value={numberToString(plantSelected.installed_power ?? 0)}
              unitOfMeasurement='kW'
              width='100%'
              gridArea='Capacity'
            />
            <ContainerItem gridArea='Chart' width='100%' padding='0 0 10px 0'>
              <MeasurementsChart<MeasurementsChartArrayType>
                isLoading={monthlyChartDataLoading}
                loadingComponent={<span>Carregando gráfico</span>}
                hoverComponent={(data) => (
                  <ContainerItem width='fit-content' padding='16px 0 0 0'>
                    <Text size='14px' color='black'>
                      Produção mensal:{' '}
                      <strong> {numberToString(Math.round(data.value))} kWh</strong>
                    </Text>
                    {data.additionalData?.meta && (
                      <>
                        <Text size='14px' color='black'>
                          Meta mensal:{' '}
                          <strong>
                            {' '}
                            {numberToString(
                              Math.round(Math.abs(Number(data.additionalData?.meta?.valor || 0))),
                            )}{' '}
                            kWh
                          </strong>
                        </Text>
                        <Text size='14px' color='black'>
                          Variação mensal:{' '}
                          <strong>
                            {' '}
                            {numberToString(
                              Number(
                                (Number(data.additionalData?.meta?.desvio || 0) * 100).toFixed(2),
                              ),
                            )}{' '}
                            %
                          </strong>
                        </Text>
                      </>
                    )}
                  </ContainerItem>
                )}
                data={monthlyChartData}
                height='120px'
              />
            </ContainerItem>
            <ValueComponent
              label='Produção média'
              labelDescription='(12 meses)'
              value={numberToString(Math.round(measurementsData!.avgProduction / 1000))}
              unitOfMeasurement='MWh'
              gridArea='AverageProd'
              width='100%'
              padding='0 0 0 80px'
            />
            <Divider gridArea='Line' />
            <ValueComponent
              label='Produção média diária'
              value={numberToString(Math.round(measurementsData!.dayProduction))}
              unitOfMeasurement='kWh'
              width='100%'
              gridArea='DailyProd'
            />
            <ValueComponent
              label='Produção mensal'
              value={numberToString(Math.round(measurementsData!.monthProduction))}
              unitOfMeasurement='kWh'
              width='100%'
              gridArea='MonthlyProd'
            />
            {/* <ValueComponent
              label='Meta mensal'
              value={'0'}
              // value={numberToString(Math.round(measurementsData!.))}
              unitOfMeasurement='kWh'
              width='100%'
              gridArea='GoalProd'
            /> */}
            {monthlyChartData &&
              monthlyChartData.length > 0 &&
              monthlyChartData[monthlyChartData.length - 1]?.additionalData?.meta?.valor && (
                <GoalProd>
                  <ContainerItem padding={'0 0 0 20px'}>
                    <ContainerItem gap='0' width='100%'>
                      <div
                        style={{
                          backgroundColor: Theme.colors.darkishGreen,
                          padding: '8px',
                          borderRadius: '10px',
                          width: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Text size='20px' width='fit-content' style={{ color: Theme.colors.white }}>
                          Meta mensal
                        </Text>
                        <Text
                          size='18px'
                          width='fit-content'
                          style={{ color: Theme.colors.white, paddingTop: '5px' }}
                        >
                          {numberToString(
                            Math.round(
                              monthlyChartData[monthlyChartData.length - 1].additionalData.meta
                                .valor || 0,
                            ),
                          )}{' '}
                          kWh
                        </Text>
                      </div>
                    </ContainerItem>
                  </ContainerItem>
                </GoalProd>
              )}
            <ValueComponent
              label='Produção anual'
              value={numberToString(Math.round(measurementsData!.yearProduction / 1000))}
              unitOfMeasurement='MWh'
              width='100%'
              gridArea='YearlyProd'
            />
            <ValueComponent
              label='Produção total'
              value={numberToString(Math.round(measurementsData!.totalProduction / 1000))}
              unitOfMeasurement='MWh'
              gridArea='TotalProd'
              width='100%'
              padding='0 0 0 80px'
            />
          </ContainerGridProduction>
        </Card>
      </RowContainer>
      <RowContainer>
        <Card>
          <CartitemContainer width='100%' margin='0 0 20px 0'>
            <ContainerMainChartHeader>
              <Text color={Theme.colors.darkishGreen} width='fit-content'>
                Produção diária (kWh)
              </Text>

              <DownloadDiv
                onClick={() => handleDailyItemClick({
                  label: moment().get('date')
                })}
              >
                <img
                  src={`${DownloadIcon}`}
                  alt='Download todas as faturas'
                />
              </DownloadDiv>
            </ContainerMainChartHeader>
            <MeasurementsChart
              height='230px'
              isLoading={mainChartDataLoading}
              hoverComponent={(data) => (
                <ContainerItem width='fit-content' padding='16px 0 0 0'>
                  <Text size='14px' color='black'>
                    Produção diária: <strong> {numberToString(Math.round(data.value))} kWh</strong>
                  </Text>
                </ContainerItem>
              )}
              onClickItem={handleDailyItemClick}
              loadingComponent={<span>Carregando gráfico</span>}
              data={mainChartData}
            />
          </CartitemContainer>
        </Card>
      </RowContainer>
      <RowContainer>
        <Card>
          <CartitemContainer width='100%' height='100%'>
            <Text width='fit-content' color={Theme.colors.darkishGreen}>
              Acompanhamento de performance
            </Text>
            <JustifyBetween style={{ margin: '20px 0' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Text size='22px' width='fit-content'>
                    Fator de capacidade diário
                  </Text>
                  <GenericTooltip
                    content='Considera os valores de geração consolidados do dia anterior.'
                    direction='right'
                  >
                    <InfoIcon
                      style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }}
                    />
                  </GenericTooltip>
                </div>
                <Title color={Theme.colors.darkishGreen} size='22px'>
                  {numberToString(Number(dailyCapacity.toFixed(2)))} %
                </Title>
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Text size='22px' width='fit-content'>
                    Fator de capacidade mensal
                  </Text>
                  <GenericTooltip
                    content='Considera os valores de geração dos dias consolidados do mês atual.'
                    direction='bottom'
                  >
                    <InfoIcon
                      style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }}
                    />
                  </GenericTooltip>
                </div>
                <Title color={Theme.colors.darkishGreen} size='22px'>
                  {numberToString(Number(monthlyCapacity.toFixed(2)))} %
                </Title>
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Text size='22px' width='fit-content'>
                    Fator de capacidade anual
                  </Text>
                  <GenericTooltip
                    content='Considera os valores de geração dos dias consolidados do ano atual.'
                    direction='left'
                  >
                    <InfoIcon
                      style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }}
                    />
                  </GenericTooltip>
                </div>
                <Title color={Theme.colors.darkishGreen} size='22px'>
                  {numberToString(Number(yearlyCapacity.toFixed(2)))} %
                </Title>
              </div>
              {userEmail && ['cesup.projetos@bb.com.br'].includes(userEmail) && (
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Text size='22px' width='fit-content'>
                      Fator de capacidade 12 meses
                    </Text>
                    <GenericTooltip
                      content='Considera os valores de geração dos últimos 12 meses.'
                      direction='left'
                    >
                      <InfoIcon
                        style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }}
                      />
                    </GenericTooltip>
                  </div>
                  <Title color={Theme.colors.darkishGreen} size='22px'>
                    {numberToString(Number(last12Months.toFixed(2)))} %
                  </Title>
                </div>
              )}
            </JustifyBetween>
          </CartitemContainer>
        </Card>
      </RowContainer>
      <RowContainer>
        <Card>
          <CartitemContainer width='100%' height='100%'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Text size='20px' width='fit-content' color={Theme.colors.darkishGreen}>
                Equivalências de sustentabilidade
              </Text>
              <GenericTooltip
                content='As equivalencias de sustentabilidade consideram os valores históricos desde o inicio da geração da planta.'
                direction='right'
              >
                <InfoIcon
                  style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }}
                />
              </GenericTooltip>
            </div>
            <JustifyBetween>
              <CartitemContainer alignAllCenter>
                <IconContaienr>
                  <CoalIcon />
                </IconContaienr>
                <ContainerItem width='fit-content'>
                  <Text size='22px'>Economia de carvão</Text>
                  <Title color={Theme.colors.darkishGreen} size='22px'>
                    {numberToString(Number(measurementsData!.coalEconomy.toFixed(2)))} t
                  </Title>
                </ContainerItem>
              </CartitemContainer>
              <CartitemContainer alignAllCenter>
                <IconContaienr>
                  <Co2Icon />
                </IconContaienr>
                <ContainerItem width='fit-content'>
                  <Text size='22px'>Emissão de CO2</Text>
                  <Title color={Theme.colors.darkishGreen} size='22px'>
                    - {numberToString(Number(measurementsData!.co2Emission.toFixed(2)))} t
                  </Title>
                </ContainerItem>
              </CartitemContainer>
              <CartitemContainer alignAllCenter>
                <IconContaienr>
                  <TreeIcon />
                </IconContaienr>
                <ContainerItem width='fit-content'>
                  <Text size='22px'>Árvores plantadas</Text>
                  <Title color={Theme.colors.darkishGreen} size='22px'>
                    {numberToString(Number(Math.floor(measurementsData!.treesPlanted)))}
                  </Title>
                </ContainerItem>
              </CartitemContainer>
            </JustifyBetween>
          </CartitemContainer>
        </Card>
      </RowContainer>
    </Wrapper>
  )
}
