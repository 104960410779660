import { darken } from 'polished'
import styled from 'styled-components'

export const StickyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  margin-top: auto;
  padding: 16px;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Stage = styled.div<{ active?: boolean }>`
  display: ${props => !props.active ? 'none' : 'flex'};
  flex-direction: column;
  gap: 16px;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};
  margin-left: auto;

  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`