import { useContext, useState } from 'react'
import { Button } from '../../../../components/Button'
import Modal from '../../../../components/ModalV2'
import { useToast } from '../../../../hooks/useToast'
import {
  AggregatedPayment,
  aggregatedPaymentsService,
} from '../../../../services/aggregated-payments'
import { Stack, Typography } from '@mui/material'
import {
  EmailsListContainer,
  EmailsListWrapper,
} from '../../../../components/Modal/PaymentExcel/styles'
import { GenericTooltip } from '../../../../components/Tooltip'
import { Info } from '@mui/icons-material'
import { GlobalContext } from '../../../../context/global/global'
import { InputV2 } from '../../../../components/InputV2'
import { numberToString } from '../../../../utils/formatNumberToString'
import ClientSelect from '../AggregatedPaymentModal/components/ClientSelect'
import { format, parseISO } from 'date-fns'

interface Props {
  aggregatedPayment: AggregatedPayment
  onSuccess: () => void
  onClose: () => void
}

export default function SendAggregatedPaymentModal(props: Props) {
  const { aggregatedPayment, onSuccess, onClose } = props

  const { toast } = useToast()

  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const sendPayment = async () => {
    try {
      setSubmitting(true)
      await aggregatedPaymentsService.sendAggregatedPayment({
        aggregatedPaymentId: aggregatedPayment.id,
        fechamentosIds: aggregatedPayment.fechamentoIds
      })
      toast({
        message: 'Pagamento enviado com sucesso',
        type: 'success',
      })
      onSuccess()
      onClose()
    } catch (error) {
      toast({
        message: 'Erro ao enviar cobrança',
        type: 'error',
      })
      setSubmitting(false)
    }
  }

  const { Theme } = useContext(GlobalContext)

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        Enviar cobrança
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body
        style={{ display: 'flex', flexDirection: 'column', gap: '16px', overflowY: 'auto' }}
      >
        <Typography color='black'>
          Confirme os dados de cobrança. A cobrança será enviada para o e-mail cadastrado no
          sistema.
        </Typography>

        <ClientSelect readOnly selectedClientId={aggregatedPayment.clientId} formik={false} />

        <InputV2
          label='Valor devido (R$)'
          value={`R$ ${numberToString(aggregatedPayment?.paymentValue, 2)}`}
          readOnly
          formik={false}
        />

        <Stack direction='row' gap={4}>
          <InputV2
            label='Data de Vencimento'
            value={format(parseISO(aggregatedPayment.dueDate), 'dd/MM/yyyy')}
            formik={false}
            readOnly
          />

          <InputV2
            label='Mês de Referencia'
            value={format(parseISO(aggregatedPayment.referenceDate), 'MM/yyyy')}
            readOnly
            formik={false}
          />
        </Stack>

        {aggregatedPayment.paymentEmails.length > 0 && (
          <EmailsListWrapper>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <h3>E-mails</h3>
              <GenericTooltip
                content='A cobrança será enviada para os e-mails listados abaixo.'
                direction='right'
              >
                <Info style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }} />
              </GenericTooltip>
            </div>
            <EmailsListContainer style={{ maxHeight: 'unset', overflowY: 'unset' }}>
              {aggregatedPayment.paymentEmails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </EmailsListContainer>
          </EmailsListWrapper>
        )}
      </Modal.Body>
      <Stack
        direction='row'
        style={{
          display: 'flex',
          width: '100%',
          position: 'sticky',
          padding: '16px',
          bottom: '-16px',
          backgroundColor: 'white',
        }}
      >
        <Button text='Cancelar' mode='white' onClick={onClose} disabled={isSubmitting} />
        <Button
          text='Enviar'
          onClick={sendPayment}
          disabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </Stack>
    </Modal.Provider>
  )
}
