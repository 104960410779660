import { Button, Buttons, Container, Infos, Return, Status } from './styles'
import { useMemo, useState } from 'react'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { TextField } from '@mui/material'
import { invoiceParserV2 } from '../../../services/invoice-parser-v2'
import { useToast } from '../../../hooks/useToast'
import FileUpload from './components/FileUpload'
import InvoiceList from '../../Superuser/InvoiceValidation/components/InvoiceList'
import { InvoiceListProvider } from '../../Superuser/InvoiceValidation/hooks/useInvoiceList'
import ManagerSelect from '../../../components/ManagerSelect'

type UploadedInvoice = { id: string; fileName: string }

export function ReportEnelReader({ setPage }: { setPage: (value: '') => void }) {
  const [invoices, setInvoices] = useState<UploadedInvoice[]>([])

  const [exist, setExist] = useState(false)
  const [hasMuc, setHasMuc] = useState(false)
  const [managerId, setManagerId] = useState<number>()

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const { toast } = useToast()

  async function uploadFiles() {
    if (!managerId) {
      return
    }
    try {
      const uploadedInvoices = await invoiceParserV2.uploadInvoiceFiles({
        distributor: 'ENEL',
        files: uploadedFiles,
        hasMuc,
        managerId,
      })
      setUploadedFiles((previousFiles) => {
        const invoicesUploaded = previousFiles.filter(
          (file) => !uploadedInvoices.invoices.find((invoice) => invoice.fileName === file.name),
        )
        toast({
          message: `Faturas enviadas: ${invoicesUploaded.length}/${previousFiles.length}`,
          type: 'success',
        })
        return invoicesUploaded
      })
      setInvoices((previousInvoices) => [...previousInvoices, ...uploadedInvoices.invoices])
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao enviar arquivos',
        type: 'error',
      })
    }
  }

  const invoicesIds = useMemo(() => invoices.map((invoice) => invoice.id), [invoices])

  return (
    <Container>
      <Infos>
        <Return
          onClick={() => {
            setPage('')
          }}
        >
          <ArrowBack fontSize='small' />
          <p>Voltar</p>
        </Return>
        <Buttons>
          <ManagerSelect
            onChange={(_, val) => setManagerId(val ? val : undefined)}
            renderInput={(params) => <TextField {...params} id='gestor_id' label='Gestor' />}
          />
          <Button className='orange' onClick={() => setHasMuc(!hasMuc)}>
            {hasMuc ? '' : 'Não '}Incluir Muc - Clique para alterar
          </Button>
          {exist ? <Status>Esta fatura já existe</Status> : null}
          <Button onClick={uploadFiles}>Enviar</Button>
        </Buttons>
      </Infos>

      <FileUpload uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />

      {invoicesIds.length > 0 && (
        <InvoiceListProvider invoicesIdsToList={invoicesIds}>
          <InvoiceList showStatus />
        </InvoiceListProvider>
      )}
    </Container>
  )
}
