import ModalDelete from "../../../../../components/Modal/ModalDelete"
import { useToast } from "../../../../../hooks/useToast"
import { invoiceErrorsService } from "../../../../../services/invoice-errors"

interface Props {
  readerErrorId: number
  onSuccess: () => void
  onClose: () => void
}

export default function DeleteInvoiceErrorModal(props: Props) {
  const { readerErrorId, onSuccess, onClose } = props

  const { toast } = useToast()

  const deleteInvoiceError = async () => {
    if (!readerErrorId) {
      return
    }
    try {
      await invoiceErrorsService.delete({
        readerErrorId,
      })
      toast({
        message: 'Erro de leitor deletado com sucesso',
        type: 'success',
      })
      onSuccess()
    } catch (error: any) {
      toast({
        message: error?.message ?? 'Erro ao deletar',
        type: 'error',
      })
    }
  }

  return (
    <ModalDelete
      openModal
      setModalOpen={onClose}
      buttonColor='darkGreen'
      text='Tem certeza que deseja excluir este erro?'
      textStyle={{ fontWeight: 'bold', paddingBottom: 12 }}
      descriptionStyle={{ color: 'red', paddingBottom: 12 }}
      description="Isso também irá excluir as faturas que estão atreladas a este erro"
      action={deleteInvoiceError}
    />
  )
}