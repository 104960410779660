import styled from 'styled-components'
import Modal from '@mui/material/Modal'
import { Button } from '../Button'
import { darken } from 'polished'
import { Theme } from '../../styles/theme'

export const ModalBox = styled.div<{ width?: string }>`
  overflow-y: auto;
  height: 100%;
  max-height: 90%;
  display: block;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  width: ${({ width }) => width || '484px'};
  height: fit-content;
  padding: 24px 32px 24px 32px;
  flex-direction: column;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const AlertText = styled.div`
  color: ${Theme.colors.suportError};
  font-size: 16px;
  margin-top: 15px;
`

export const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ModalTitle = styled.h3`
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.05em;
  padding-bottom: 32px;

  color: ${({ theme }) => theme.colors.darkishGreen};
`

export const SubtitleDiv = styled.div`
  padding-bottom: 32px;
`

export const ButtonsArea = styled.div`
  padding-top: 49px;
  padding-left: 42px;
  padding-right: 42px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const CancelButton = styled(Button)`
  width: 152px;
  height: 40px;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const ContinueButton = styled(CancelButton)`
  background: ${({ theme }) => theme.colors.darkishGreen};

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`
