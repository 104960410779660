import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  ListOrchestratorOverview,
  ListOrchestratorStatus,
  ListUcsScraped,
  orchestratorService,
} from '../../../services/orchestrator'
import { useToast } from '../../../hooks/useToast'
import Loading from '../../../components/Loading'
import StatusPieChart from './components/StatusPieChart'
import OverviewBarChart from './components/OverviewBarChart'
import UcsScrapedTable from './components/UcsScrapedTable'
import { format, parse, startOfMonth } from 'date-fns'
import { TicketsCount } from '../../Tickets/components/TicketsTable/styles'
import SearchInput from '../../Tickets/components/SearchInput'
import MultiStatusFilter from './components/MultiStatusFilter'
import MultiManagerFilter from './components/MultiManagerFilter'
import { DateFilter } from '../../../components/Filter/DateFilter'
import DistributorFilter from '../../AggregatedPayments/components/DistributorFilter'
import { Sort } from '../../../interfaces/pagination'

export default function OrchestratorPage() {
  const [ucsScraped, setUcsScraped] = useState<ListUcsScraped['result']>()
  const [attemptStatusList, setAttemptStatusList] = useState<ListOrchestratorStatus['result']>()
  const [overview, setOverview] = useState<ListOrchestratorOverview['result']>()

  const [isLoading, setLoading] = useState<boolean>(false)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sort, setSort] = useState<Sort>()
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [distributorFilter, setDistributorFilter] = useState<number[]>([])
  const [managerFilter, setManagerFilter] = useState<number[]>([])

  const [selectedReferenceMonth, setSelectedReferenceMonth] = useState<Date>(new Date())

  const [searchFilter, setSearchFilter] = useState<string>('')

  const { toast } = useToast()

  const fetch = async () => {
    try {
      setLoading(true)

      const dates = {
        referenceMonth: format(startOfMonth(selectedReferenceMonth), 'yyyy-MM-dd'),
      }

      const filter = {
        ...(searchFilter ? { search: searchFilter } : {}),
        ...(statusFilter.length > 0 ? { status: statusFilter } : {}),
        ...(distributorFilter.length > 0 ? { distributor: distributorFilter } : {}),
        ...(managerFilter.length > 0 ? { manager: managerFilter } : {}),
      }

      const data = {
        ...dates,
        ...(Object.keys(filter).length > 0 ? { filter } : {}),
      }

      const promises = [
        orchestratorService.listUcsScraped({
          page: currentPage,
          sort,
          ...data,
        }),
        orchestratorService.listStatus({
          ...data,
        }),
        orchestratorService.listOverview({
          ...data,
        }),
      ]
      const [ucsScrapedResponse, attemptStatusResponse, listOverviewResponse] = await Promise.all(
        promises,
      )
      setUcsScraped(ucsScrapedResponse as ListUcsScraped['result'])
      setOverview(listOverviewResponse as ListOrchestratorOverview['result'])
      setAttemptStatusList(attemptStatusResponse as ListOrchestratorStatus['result'])
      setLoading(false)
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao listar dados',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetch()
  }, [
    selectedReferenceMonth,
    searchFilter,
    currentPage,
    statusFilter,
    distributorFilter,
    managerFilter,
    sort,
  ])

  return (
    <>
      {isLoading && <Loading />}

      <div style={isLoading ? { display: 'none' } : {}}>
        <DateFilter
          onChange={(value) =>
            setSelectedReferenceMonth(
              parse(`${value.year}-${value.month}-1`, 'yyyy-MM-dd', new Date()),
            )
          }
          currentValue={{
            month: selectedReferenceMonth.getMonth() + 1,
            year: selectedReferenceMonth.getFullYear(),
          }}
        />

        <Stack direction='row' gap={2}>
          {overview && <OverviewBarChart chartData={overview} />}
          {attemptStatusList && <StatusPieChart chartData={attemptStatusList} />}
        </Stack>

        <Stack direction='row' marginLeft='auto' gap={2} marginBottom={4} marginTop={4}>
          <DistributorFilter
            isFilterActive
            values={distributorFilter}
            onFilter={(values) => {
              setCurrentPage(1)
              setDistributorFilter(
                values
                  .filter((value) => (values.length === 0 ? false : value.checked))
                  .map((value) => Number(value.id)),
              )
            }}
          />
          <MultiStatusFilter
            values={statusFilter}
            onFilter={(values) => {
              setCurrentPage(1)
              setStatusFilter(
                values
                  .filter((value) => (values.length === 0 ? false : value.checked))
                  .map((value) => String(value.id)),
              )
            }}
          />
          <MultiManagerFilter
            values={managerFilter}
            onFilter={(values) => {
              setCurrentPage(1)
              setManagerFilter(
                values
                  .filter((value) => (values.length === 0 ? false : value.checked))
                  .map((value) => Number(value.id)),
              )
            }}
          />

          <TicketsCount>Total de UCs: {ucsScraped?.pageInfo?.totalCount ?? 0}</TicketsCount>
          <SearchInput
            onSearch={(search) => {
              setCurrentPage(1)
              setSearchFilter(search)
            }}
          />
        </Stack>
        {ucsScraped && !isLoading && (
          <UcsScrapedTable
            referenceMonth={selectedReferenceMonth}
            rows={ucsScraped}
            onPageChange={(page) => setCurrentPage(page)}
            sort={sort}
            onSort={setSort}
          />
        )}
      </div>
    </>
  )
}
