import styled from 'styled-components'

export const AlertText = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding-left: 24px;
  padding-right: 47px;

  color: ${({ theme }) => theme.colors.orange};
`

export const AlertBox = styled.div`
  padding-bottom: 24px;
`
export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    cursor: not-allowed;
  }
`
