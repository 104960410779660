import { darken } from "polished";
import styled from "styled-components";

export const OptionList = styled.ul<{ showDropDown?: boolean; emptyPlaceholder?: boolean }>`
  width: 124px;
  max-height: 190px;
`

export const OptionItem = styled.li<{ active?: boolean }>`
  list-style-type: none;
  cursor: pointer;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding: 10px 10px 10px 13px;
  border-radius: ${({ active }) => (active ? '8px' : '0px')};
  background-color: ${({ active, theme }) =>
    active ? darken(0.07, theme.colors.white) : theme.colors.white};
  color: ${({ active, theme }) => (active ? theme.colors.darkishGreen : theme.colors.htmlGrey)};

  &:hover {
    border-radius: 8px;
    background-color: #f1f1f1;
  }
`