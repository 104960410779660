import { SettingsOutlined } from '@mui/icons-material'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import { CheckboxType } from '../../../../interfaces/checkbox'
import { GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'

interface Props {
  tableColumns: GridColDef[]
  onFilter: (checkboxes: CheckboxType[], itemsPerPage: number) => void
}

export default function ColumnFilterSelector(props: Props) {
  const { tableColumns, onFilter } = props

  const [itemsPerPage, setItemsPerPage] = useState<number>(15)

  return (
    <FloatingCheckboxes
      isFilterActive={false}
      title='Configurar Tabela'
      customActionComponent={<ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>}
      submitAction={checkboxes => onFilter(checkboxes, itemsPerPage)}
      pagination={{
        selected: itemsPerPage,
        setSelected: setItemsPerPage,
        options: [15, 20, 30, 50, 100],
      }}
      options={tableColumns.filter(col => col.hideable).map((col) => ({
        label: col.headerName || '',
        checked: !col.hide,
        id: Math.random(),
      }))}
      submitBtnText='Salvar'
    />
  )
}
