import styled from 'styled-components'
import { Theme } from '../../../styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  padding: 0 40px;
  font-family: 'Arial';
  margin-bottom: 80px;
`

export const Checkbox = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  label {
    margin-left: 10px;
    font-size: 18px;
  }

  input {
    height: 15px;
    width: 15px;
  }
`

export const Content = styled.div`
  width: 100%;
`

export const ContentDouble = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Double = styled.div`
  width: 49%;
`

export const Table = styled.table`
  width: 100%;
  tr {
    th {
      text-align: left;
      font-size: 16px;
      padding: 5px 0;

      &:first-child {
        padding-left: 10px;
      }
    }

    td {
      letter-spacing: 0.5px;

      padding: 5px 0;
      font-size: 16px;

      &:first-child {
        padding-left: 10px;
      }
    }
  }
  tr:nth-child(even) {
    background-color: #dddddd;
  }
`

export const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-size: 16px;
    white-space: nowrap;
    margin-right: 10px;
    letter-spacing: 0.5px;
  }

  p {
    margin-left: 5px;
    white-space: nowrap;
  }

  input {
    font-size: 16px;
    letter-spacing: 0.5px;
    width: 100%;
  }

  gap: 10px;
  justify-content: space-between;

  div {
    display: flex;
  }

  .bigInput {
    width: 100%;
    input {
      font-size: 24px;
      text-align: center;
    }
  }
`

export const Title = styled.p`
  margin-top: 10px;
  font-size: 18px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
  background-color: ${Theme.colors.grayDark};
  padding: 2.5px 0;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
`

export const TextValue = styled.p`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: center;
`

export const EnergiaInjetada = styled.p`
  font-weight: 600;
  color: ${Theme.colors.darkGreen};
  cursor: pointer;
`
