import React, { useMemo } from 'react'
import { ContentBlock, FeedItem } from '@knocklabs/client'

import './styles.css'
import { useKnockFeed } from '@knocklabs/react'
import { Locale, formatDistance, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ArchiveButton } from '../ArchiveButton'

export interface NotificationCellProps {
  item: FeedItem
  onItemClick?: (item: FeedItem) => void
}

type BlockByName = {
  [name: string]: ContentBlock
}

export default function NotificationItem(props: NotificationCellProps) {
  const { item, onItemClick } = props
  
  const { feedClient, colorMode } = useKnockFeed()

  const blocksByName: BlockByName = useMemo(() => {
    return item.blocks.reduce((acc, block) => {
      return { ...acc, [block.name]: block }
    }, {})
  }, [item])

  const actionUrl = blocksByName.action_url && (blocksByName.action_url as unknown as Record<string, string | URL>)?.rendered

  const onClick = React.useCallback(() => {
    feedClient.markAsInteracted(item)

    if (onItemClick) return onItemClick(item)

    setTimeout(() => {
      if (actionUrl && actionUrl !== '') {
        window.location.assign(actionUrl)
      }
    }, 200)
  }, [item])

  const onKeyDown = React.useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => {
      switch (ev.key) {
        case 'Enter': {
          ev.stopPropagation()
          onClick()
          break
        }
        default:
          break
      }
    },
    [onClick],
  )

  return (
    <div
      className={`rnf-notification-cell rnf-notification-cell--${colorMode}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      <div className='rnf-notification-cell__inner'>
        {!item.read_at && <div className='rnf-notification-cell__unread-dot' />}

        <div className='rnf-notification-cell__content-outer'>
          {blocksByName.body && (
            <div
              className='rnf-notification-cell__content'
              dangerouslySetInnerHTML={{ __html: (blocksByName.body as unknown as Record<string, string>).rendered }}
            />
          )}

          <span className='rnf-notification-cell__timestamp'>
            {formatTimestamp(item.inserted_at, { locale: ptBR })}
          </span>
        </div>

        <ArchiveButton item={item} />
      </div>
    </div>
  )
}

type FormatTimestampOptions = {
  locale?: Locale
}

export function formatTimestamp(ts: string, options: FormatTimestampOptions = {}) {
  try {
    const parsedTs = parseISO(ts)
    const formatted = formatDistance(parsedTs, new Date(), {
      addSuffix: true,
      locale: options.locale,
    })

    return formatted
  } catch (e) {
    return ts
  }
}
