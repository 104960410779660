import { LocalStorageKeys } from '../../../interfaces/local-storage'
import { ActionTypeGlobalState, GlobalActionType } from '../../../interfaces/states/global-state'
import {
  LoginRequest,
  consumerListGet,
  distributorListGet,
  powerPlantListGet,
} from '../../../services/requests/user-requests'

type LoginActionPayload = {
  password: string
  email: string
}

export async function loginAction(payload: LoginActionPayload): Promise<GlobalActionType> {
  const response = await LoginRequest({
    email: payload.email,
    senha: payload.password,
  })

  const loginData = {
    id: response.data.id,
    email: payload.email,
    token: response.data.token,
    isProvisoryPassword: response.data.senha_provisoria_flag,
    customer: response.data.cliente
      ? {
          id: response.data.cliente.id,
          type: response.data.cliente.tipo,
          canUpdatePayment: response.data.cliente.can_update_payments,
        }
      : undefined,
  }

  localStorage.setItem(LocalStorageKeys.AUTH, JSON.stringify(loginData))

  return {
    type: ActionTypeGlobalState.LOGIN,
    payload: loginData,
  }
}

export function logoutAction(): GlobalActionType {
  localStorage.removeItem(LocalStorageKeys.AUTH)

  return {
    type: ActionTypeGlobalState.LOGOUT,
  }
}

export async function SetPowerPlantListFilterAction(): Promise<GlobalActionType> {
  try {
    const response = await powerPlantListGet()
    return {
      type: ActionTypeGlobalState.SET_POWER_PLANT,
      payload: response.data,
    }
  } catch (err) {
    return {
      type: ActionTypeGlobalState.SET_POWER_PLANT,
      payload: [],
    }
  }
}

export async function setConsumerListFilterActions(): Promise<GlobalActionType> {
  try {
    const response = await consumerListGet()
    return {
      type: ActionTypeGlobalState.SET_CONSUMERS,
      payload: response.data,
    }
  } catch (err) {
    return {
      type: ActionTypeGlobalState.SET_CONSUMERS,
      payload: [],
    }
  }
}

export async function setDistribuitorListFilterActions(): Promise<GlobalActionType> {
  try {
    const response = await distributorListGet()
    return {
      type: ActionTypeGlobalState.SET_DISTRIBUITORS,
      payload: response.data,
    }
  } catch (err) {
    return {
      type: ActionTypeGlobalState.SET_DISTRIBUITORS,
      payload: [],
    }
  }
}
