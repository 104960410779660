import { FormikProps } from 'formik'
import { RadioButtonFormik } from '../../../../../../FormikComponents/RadioButtonInput'
import { Autocomplete, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { consumerListGet } from '../../../../../../../services/requests/user-requests'
import { ConsumerGetListRequestType } from '../../types'
import { useToast } from '../../../../../../../hooks/useToast'
import { InputV2 } from '../../../../../../InputV2'
import { Search } from '@mui/icons-material'

const compensationModalities = [
  {
    label: 'Associação',
    value: 'ASSOCIACAO',
  },
  {
    label: 'Consórcio',
    value: 'CONSORCIO',
  },
  {
    label: 'Cooperativa',
    value: 'COOPERATIVA',
  },
  {
    label: 'Autoconsumo Local',
    value: 'AUTOCONSUMO_LOCAL',
  },
  {
    label: 'Autoconsumo Remoto',
    value: 'AUTOCONSUMO_REMOTO',
  },
  {
    label: 'EMUC',
    value: 'EMUC',
  },
]

interface Props {
  isEdit?: boolean
  formik: FormikProps<any>
}

export default function UCFields(props: Props) {
  const { isEdit, formik } = props

  const { errors, values, setFieldTouched, setFieldValue } = formik

  const { toast } = useToast()

  const [consumers, setConsumers] = useState<ConsumerGetListRequestType>([])

  const [selectedConsumerId, setSelectedConsumerId] = useState<number | null>(
    values.cliente_id_consumidor,
  )
  const [selectedCompensationModality, setSelectedCompensationModality] = useState<string | null>(
    values.modalidade_de_compensacao,
  )

  const fetchConsumers = async () => {
    try {
      const consumersData = await consumerListGet()
      setConsumers(consumersData.data)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar consumidores',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchConsumers()
  }, [])

  useEffect(() => {
    setSelectedCompensationModality(values.modalidade_de_compensacao)
    setSelectedConsumerId(values.cliente_id_consumidor)
  }, [values.cliente_id_consumidor, values.modalidade_de_compensacao])

  return (
    <>
      <InputV2 id='nome' label='Nome da UC' required />
      <InputV2 id='nome_razao_social' label='Razão Social da UC' />
      <Stack direction='row' gap={2}>
        <InputV2
          id='codigo'
          label='Número da UC'
          disabled={isEdit}
          required
          mask={{
            mask: (rawValue) => {
              return Array(rawValue.length).fill(/\S/)
            },
            guide: false,
          }}
        />
        <InputV2 id='documento_numero' label='Documento da UC' required />
      </Stack>

      <Autocomplete
        id='cliente_id_consumidor'
        ListboxProps={{
          style: {
            maxHeight: '250px',
          },
        }}
        renderInput={(params) => (
          <InputV2
            rightIcon={<Search />}
            label='Consumidor da UC'
            error={!!errors[params.id]}
            required
            {...params}
          />
        )}
        options={consumers.map((consumer) => consumer.id)}
        getOptionLabel={(option) => {
          const optionNumber = Number(option)
          return !isNaN(optionNumber)
            ? consumers.find((consumer) => consumer.id === option)?.cliente_nome ?? ''
            : String(option)
        }}
        isOptionEqualToValue={(option) => option === selectedConsumerId}
        value={selectedConsumerId}
        onChange={async (_, value) => {
          setSelectedConsumerId(value)
          const consumerFound = consumers.find((consumer) => consumer.id === value)
          await Promise.all([
            setFieldValue('nome_razao_social', consumerFound?.cliente_nome ?? ''),
            setFieldTouched('documento_numero', true),
            setFieldValue('documento_numero', consumerFound?.documento_numero ?? '', true),
            setFieldValue('cliente_id_consumidor', value, true),
          ])
        }}
      />
      <Stack direction='row' gap={6}>
        <RadioButtonFormik
          name='grupo'
          label='Grupo'
          values={[
            { field: 'A', value: 'A' },
            { field: 'B', value: 'B' },
          ]}
          mode
          required
        />
        <RadioButtonFormik
          name='subgrupo'
          label='SubGrupo'
          values={
            values.group === 'A'
              ? [
                  { field: 'A3', value: 'A3' },
                  { field: 'A3a', value: 'A3a' },
                  { field: 'A4', value: 'A4' },
                ]
              : [
                  { field: 'B', value: 'B' },
                  { field: 'B1', value: 'B1' },
                  { field: 'B2', value: 'B2' },
                  { field: 'B3', value: 'B3' },
                ]
          }
          mode
        />
      </Stack>
      <Autocomplete
        id='modalidade_de_compensacao'
        ListboxProps={{
          style: {
            maxHeight: '250px',
          },
        }}
        renderInput={(params) => (
          <InputV2
            rightIcon={<Search />}
            error={!!errors[params.id]}
            label='Modalidade de Compensação'
            required
            {...params}
          />
        )}
        options={compensationModalities.map((compensationModality) => compensationModality.value)}
        getOptionLabel={(option) => {
          return (
            compensationModalities.find(
              (compensationModalities) => compensationModalities.value === option,
            )?.label ?? option
          )
        }}
        isOptionEqualToValue={(option) => option === selectedCompensationModality}
        value={selectedCompensationModality}
        onChange={(_, value) => {
          setFieldValue('modalidade_de_compensacao', value)
          setSelectedCompensationModality(value)
        }}
      />
      <InputV2 id='consumo_medio' label='Consumo Contratado (kWh)' />
      <Stack direction="row" gap={4}>
        <InputV2
          id='vigencia_prevista_compensacao'
          label="Vigência Prevista de Compensação"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        />
        <InputV2 
          containerStyle={{ display: 'flex', flexDirection: 'column' }}
          id='vigencia_real_compensacao'
          label="Vigência Real de Compensação &nbsp;&nbsp;&nbsp;"
          disabled
        />
      </Stack>
    </>
  )
}
