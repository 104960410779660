import styled from 'styled-components'
import BoxComponent from '@mui/material/Box'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { DataGrid as DataGridComponent } from '@mui/x-data-grid'
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import AutorenewIcon from '@mui/icons-material/Autorenew'

export const Box = styled(BoxComponent)`
  width: 100%;
  position: relative;
`

export const DataGrid = styled(DataGridComponent)<{ changeHeaderColor: string }>`
  & .css-17jjc08-MuiDataGrid-footerContainer {
    border: none;
  }

  & .MuiDataGrid-columnHeaders {
    background-color: ${({ changeHeaderColor, theme }) =>
      changeHeaderColor.length > 0 ? changeHeaderColor : theme.colors.darkishGreen};
    border-radius: 8px 8px 0 0;
    color: #ffffff;
    font-size: 14px;
  }

  & .MuiDataGrid-iconButtonContainer {
    margin-left: 4px;
  }

  & .css-ltf0zy-MuiDataGrid-iconButtonContainer {
    visibility: visible;
  }

  & .MuiDataGrid-columnSeparator--sideRight {
    display: none !important;
  }

  & .MuiDataGrid-columnHeaderCheckbox {
    outline: none !important;

    svg {
      color: #ffffff;
    }
  }

  & .MuiDataGrid-cellCheckbox {
    outline: none !important;

    svg {
      color: #adadad;
    }
  }

  & .RowEven {
    background-color: #ffffff;
  }

  & .RowOdd {
    background-color: #f1f1f1;
  }

  & .MuiDataGrid-root,
  & .MuiDataGrid-columnHeader:focus,
  & .MuiDataGrid-root,
  & .MuiDataGrid-cell:focus {
    outline: none !important;
  }
`

export const Icon = styled(VisibilityIcon)`
  color: ${({ theme }) => theme.colors.grayDark};
`

export const CancelIcon = styled(CancelScheduleSendIcon)`
  color: ${({ theme }) => theme.colors.grayDark};
`

export const StatusIcon = styled(AutorenewIcon)`
  color: ${({ theme }) => theme.colors.grayDark};
`

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: 0px;

  p {
    color: ${(props) => props.theme.colors.grayDark};
    font-size: 0.875rem;
    letter-spacing: 0.15px;
  }
`
