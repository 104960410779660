import styled from 'styled-components'
import CheckboxComponent from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import SelectComponent from '@mui/material/Select'
import { MenuItem } from '@mui/material'
import { darken } from 'polished'

export const Checkbox = styled(CheckboxComponent)`
  padding: 0 !important;
  margin-right: 8px !important;
  position: fixed;

  & .MuiSvgIcon-root {
    font-size: 30px;
  }
`

export const Label = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding-bottom: 16px;
  width: 100%;

  &:last-child {
    padding-bottom: 0;
  }
`

export const ListContainer = styled(FormGroup)`
  width: 300px;
  height: auto;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 16px 16px 16px 28px;
  overflow-y: auto;
  overflow-x: hidden;
  align-content: flex-start;

  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  &::-webkit-scrollbar-button {
    width: 5px;
    height: 5px;
    margin-right: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #adadad;
    border: 8px none #ffffff;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #adadad;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #adadad;
  }
  &::-webkit-scrollbar-track {
    background: #e5e5e5;
    border: 28px none #ffffff;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #e5e5e5;
  }
  &::-webkit-scrollbar-track:active {
    background: #e5e5e5;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
  padding-bottom: 20px;
`

export const Select = styled(SelectComponent)`
  height: 40px;
  font-size: 16px !important;
  background-color: #f1f1f1;

  & > fieldset {
    border: none;
  }
`
export const SelectItem = styled(MenuItem)`
  font-size: 12px !important;
  color: ${({ theme }) => theme.colors.grayDark} !important;
  padding: 8px 2px 8px 15px !important;
`
