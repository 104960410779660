import { useToast } from '../../../../hooks/useToast'
import DownloadIcon from '../../../../assets/download-icon-white.svg'
import { aggregatedPaymentsService } from '../../../../services/aggregated-payments'
import { format } from 'date-fns'
import { Download } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { DownloadDiv } from '../../../Invoices/styles'
import { useTableStateContext } from '../../../../hooks/useTableStateContext'

interface Props {
  variant?: 'primary' | 'secondary'
  aggregatedPaymentId?: number
  referenceMonth: Date
}

export default function DownloadSheetButton(props: Props) {
  const { variant = 'primary', aggregatedPaymentId, referenceMonth } = props

  const { toast } = useToast()

  const { tableState } = useTableStateContext()

  const downloadSheet = async () => {
    try {
      const buffer = await aggregatedPaymentsService.downloadSheet({
        aggregatedPaymentId,
        referenceMonth: format(referenceMonth, 'yyyy-MM-dd'),
        filter: tableState.filters,
        sort: tableState.sort,
      })
      const blob = new Blob([buffer])
      const element = document.createElement('a')
      element.href = URL.createObjectURL(blob)
      element.download = `faturamentos_agrupados_${(referenceMonth.getMonth() + 1) + '_' + referenceMonth.getFullYear()}.xlsx`
      document.body.appendChild(element)
      element.click()
    } catch(error) {
      toast({
        message: 'Erro ao baixar planilha',
        type: 'error'
      })
    }
  }

  if(variant === 'secondary') {
    return (
      <IconButton onClick={downloadSheet}>
         <Download fontSize='medium' />
      </IconButton>
    )
  }

  return (
    <DownloadDiv onClick={downloadSheet}>
      <Download fontSize='medium' />
    </DownloadDiv>
  )
}
