import React, { useEffect } from 'react'
import {
  Container,
  Content,
  ContentDouble,
  DatesDiv,
  Double,
  EnergiaInjetada,
  HeaderDivs,
  InputDiv,
  NumeroInstalacao,
  Table,
  Text,
  Title,
  TotalValueDiv,
} from './styles'
import { calculateTar, calculateVal, formatDateRef, transformDate } from '../utils/format'
import { ITemplate } from '../utils/interface'
import axios from 'axios'
import moment from 'moment'
import { v4 } from 'uuid'

export function CopelTemplate({
  data,
  changeInfo,
  changeInfoPrice,
  changeInfoDate,
  changeInfoNegative,
  setData,
  extra,
  setExtra,
}: {
  data: ITemplate
  setData: (value: ITemplate) => void
  changeInfo: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeInfoPrice: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeInfoDate: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeInfoNegative: (event: React.ChangeEvent<HTMLInputElement>) => void
  extra: any
  setExtra: (value: any) => void
  ucs: string[]
}) {
  useEffect(() => {
    if (data.data_leitura_anterior.length === 10 && data.data_leitura_atual.length === 10) {
      const data1 = moment(transformDate(data.data_leitura_atual))
      const data2 = moment(transformDate(data.data_leitura_anterior))

      setData({
        ...data,
        dias_faturados: String(-data2.diff(data1, 'days')),
      })
    }
  }, [data.data_leitura_atual, data.data_leitura_anterior])

  useEffect(() => {
    ;(async () => {
      if (data.cep && data.cep.length === 9) {
        const cep = (await axios
          .get(`https://viacep.com.br/ws/${data.cep.replace('-', '')}/json`)
          .then((res) => res.data)) as any

        if (cep) {
          setData({
            ...data,
            cidade: cep.localidade,
            uf: cep.uf,
          })
        }
      }
    })()
  }, [data.cep])

  function extraChange(id: any, change: any) {
    const exist = extra.filter((val: any) => val.id !== id)
    const diff = extra.filter((val: any) => val.id === id)[0]

    setExtra([...exist, { ...diff, ...change }].sort((a: any, b: any) => a.sort - b.sort))
  }

  return (
    <Container>
      <Content>
        <ContentDouble>
          <Double>
            <HeaderDivs>
              <div>
                <label>Subgrupo:</label>
                <input id='subgrupo' value={data.subgrupo} onChange={changeInfo} />
              </div>
              <div>
                <label>Classificação:</label>
                <input id='classe' value={data.classe} onChange={changeInfo} />
              </div>
              <div>
                <label>Tipo de fornecimento:</label>
                <input id='fases' value={data.fases} onChange={changeInfo} />
              </div>
            </HeaderDivs>
            <ContentDouble>
              <Double>
                <InputDiv>
                  <label>Nome: </label>
                  <Text title={data.nome}>
                    {data.nome.length > 20 ? `${data.nome.slice(0, 21)}...` : data.nome}
                  </Text>
                </InputDiv>
                <InputDiv>
                  <label>Endereço: </label>
                  <input id='endereco' value={data.endereco} onChange={changeInfo} />
                </InputDiv>
                <InputDiv>
                  <label>CEP: </label>
                  <input id='cep' value={data.cep} onChange={changeInfo} />
                </InputDiv>
                <InputDiv>
                  <label>Cidade: </label>
                  <Text>
                    {data.cidade} - Estado: {data.uf}
                  </Text>
                </InputDiv>
                <InputDiv>
                  <label>CPF: </label>
                  <Text>{data.doc}</Text>
                </InputDiv>
              </Double>
              <Double>
                <NumeroInstalacao>
                  <h2>UNIDADE CONSUMIDORA</h2>
                  <input id='uc' value={data.uc} onChange={changeInfo} />
                  <p>CÓDIGO DEBITO AUTOMÁTICO</p>
                </NumeroInstalacao>
              </Double>
            </ContentDouble>
            <TotalValueDiv>
              <div>
                <p>REF: MÊS/ANO</p>
                <input
                  value={data.mes_referencia}
                  onChange={(e) =>
                    setData({ ...data, mes_referencia: formatDateRef(e.target.value) })
                  }
                />
              </div>
              <div>
                <p>VENCIMENTO</p>
                <input
                  id='data_vencimento'
                  value={data.data_vencimento}
                  onChange={changeInfoDate}
                />
              </div>
              <div>
                <p>TOTAL A PAGAR</p>
                <input
                  id='valor_total_fatura'
                  value={data.valor_total_fatura}
                  onChange={changeInfoPrice}
                />
              </div>
            </TotalValueDiv>
          </Double>
          <Double>
            <DatesDiv>
              <div>
                <p>LEITURA ANTERIOR</p>
                <input
                  id='data_leitura_anterior'
                  value={data.data_leitura_anterior}
                  onChange={changeInfoDate}
                />
              </div>
              <div>
                <p>LEITURA ATUAL</p>
                <input
                  id='data_leitura_atual'
                  value={data.data_leitura_atual}
                  onChange={changeInfoDate}
                />
              </div>
              <div>
                <p>Nº de dias</p>
                <p>{data.dias_faturados}</p>
              </div>
              <div>
                <p>PRÓXIMA LEITURA</p>
                <input
                  id='data_proxima_leitura'
                  value={data.data_proxima_leitura}
                  onChange={changeInfoDate}
                />
              </div>
            </DatesDiv>
            <Title>DATA DE EMISSÃO</Title>
            <InputDiv>
              <div className='bigInput'>
                <input id='data_emissao' value={data.data_emissao} onChange={changeInfoDate} />
              </div>
            </InputDiv>
          </Double>
        </ContentDouble>
      </Content>
      <Content style={{ marginTop: '50px' }}>
        <ContentDouble style={{ gap: '50px' }}>
          <Double style={{ width: '100%' }}>
            <Table cellSpacing={0}>
              <thead>
                <tr>
                  <th>Itens da datura</th>
                  <th>Quant.</th>
                  <th>Preço unit. com tributos</th>
                  <th>Valor (R$)</th>
                  <th>Tarifa unit.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ENERGIA ELET CONSUMO</td>
                  <td>
                    <input
                      id='consumo_spt_qt'
                      style={{ width: '100px' }}
                      value={data.consumo_spt_qt}
                      onChange={changeInfo}
                    />
                  </td>
                  <td>
                    {calculateTar(
                      data.consumo_spt_te_tar,
                      data.tot_trib_icms_aliq,
                      data.tot_trib_cofins_aliq,
                      data.tot_trib_pis_aliq,
                    )}
                  </td>
                  <td>
                    {calculateVal(
                      data.consumo_spt_qt,
                      calculateTar(
                        data.consumo_spt_te_tar,
                        data.tot_trib_icms_aliq,
                        data.tot_trib_cofins_aliq,
                        data.tot_trib_pis_aliq,
                      ),
                    )}
                  </td>
                  <td>
                    <input
                      style={{ width: '100px' }}
                      value={data.consumo_spt_te_tar}
                      id='consumo_spt_te_tar'
                      onChange={changeInfo}
                    />
                  </td>
                </tr>
                <tr>
                  <td>ENERGIA ELET USO SISTEMA</td>
                  <td>{data.consumo_spt_qt}</td>
                  <td>
                    {calculateTar(
                      data.consumo_spt_tusd_tar,
                      data.tot_trib_icms_aliq,
                      data.tot_trib_cofins_aliq,
                      data.tot_trib_pis_aliq,
                    )}
                  </td>
                  <td>
                    {calculateVal(
                      data.consumo_spt_qt,
                      calculateTar(
                        data.consumo_spt_tusd_tar,
                        data.tot_trib_icms_aliq,
                        data.tot_trib_cofins_aliq,
                        data.tot_trib_pis_aliq,
                      ),
                    )}
                  </td>
                  <td>
                    <input
                      style={{ width: '100px' }}
                      value={data.consumo_spt_tusd_tar}
                      id='consumo_spt_tusd_tar'
                      onChange={changeInfo}
                    />
                  </td>
                </tr>
                <tr>
                  <td>ENERGIA INJ. OUC MPT TE 1</td>
                  <td>
                    <input
                      id='energia_comp_spt_qt'
                      style={{ width: '100px' }}
                      value={data.energia_comp_spt_qt}
                      onChange={changeInfoNegative}
                    />
                  </td>
                  <td>
                    <input
                      style={{ width: '100px' }}
                      value={data.ouc_te}
                      id='ouc_te'
                      onChange={changeInfo}
                    />
                  </td>
                  <td>{calculateVal(data.energia_comp_spt_qt, data.ouc_te)}</td>
                  <td>{data.consumo_spt_te_tar}</td>
                </tr>
                <tr>
                  <td>ENERGIA INJ. OUC MPT TUSD 1</td>
                  <td>{data.energia_comp_spt_qt}</td>
                  <td>
                    <input
                      style={{ width: '100px' }}
                      value={data.ouc_tusd}
                      id='ouc_tusd'
                      onChange={changeInfo}
                    />
                  </td>
                  <td>{calculateVal(data.energia_comp_spt_qt, data.ouc_tusd)}</td>
                  <td>{data.consumo_spt_tusd_tar}</td>
                </tr>
                {extra
                  ? extra.map((val: any, i: number) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td>ENERGIA INJ. OUC MPT TE {i + 2}</td>
                            <td>
                              <input
                                style={{ width: '100px' }}
                                value={val.energia_comp_spt_qt}
                                onChange={(e) =>
                                  extraChange(val.id, {
                                    energia_comp_spt_qt: `${
                                      parseFloat(e.target.value.replace('-', '')) > 0 ? '-' : ''
                                    }${e.target.value.replace('-', '')}`,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <input
                                style={{ width: '100px' }}
                                value={val.te}
                                onChange={(e) => extraChange(val.id, { te: e.target.value })}
                              />
                            </td>
                            <td>{calculateVal(val.energia_comp_spt_qt, val.te)}</td>
                            <td>{data.consumo_spt_te_tar}</td>
                          </tr>
                          <tr>
                            <td>ENERGIA INJ. OUC MPT TUSD {i + 2}</td>
                            <td>{val.energia_comp_spt_qt}</td>
                            <td>
                              <input
                                style={{ width: '100px' }}
                                value={val.tusd}
                                onChange={(e) => extraChange(val.id, { tusd: e.target.value })}
                              />
                            </td>
                            <td>{calculateVal(val.energia_comp_spt_qt, val.tusd)}</td>
                            <td>{data.consumo_spt_tusd_tar}</td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                  : null}
                <tr>
                  <td>
                    <EnergiaInjetada
                      onClick={() => {
                        console.log(v4())
                        setExtra([
                          ...extra,
                          {
                            id: v4(),
                            sort: extra.length + 10,
                            energia_comp_spt_qt: '',
                            tusd: '',
                            te: '',
                          },
                        ])
                      }}
                    >
                      + Adicionar Energia Injetada OUC
                    </EnergiaInjetada>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>CONT ILUMIN PUBLICA MUNICIPIO</td>
                  <td>1</td>
                  <td></td>
                  <td>
                    <input value={data.cosip} id='cosip' onChange={changeInfo} />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Double>
          <Double style={{ width: '200px' }}>
            <Title>Impostos</Title>
            <InputDiv>
              <div>
                <label>ICMS: </label>
                <input
                  value={data.tot_trib_icms_aliq}
                  id='tot_trib_icms_aliq'
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>COFINS: </label>
                <input
                  value={data.tot_trib_cofins_aliq}
                  id='tot_trib_cofins_aliq'
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
            <InputDiv>
              <div>
                <label>PIS: </label>
                <input
                  value={data.tot_trib_pis_aliq}
                  id='tot_trib_pis_aliq'
                  onChange={changeInfo}
                />
              </div>
            </InputDiv>
          </Double>
        </ContentDouble>
      </Content>
      <Content style={{ marginTop: '20px' }}>
        <Title>Detalhes de Faturamento</Title>
        <InputDiv>
          <div>
            <label>Saldo Atualizado no mês: </label>
            <input
              id='creditos_estoque_spt'
              value={data.creditos_estoque_spt}
              onChange={changeInfo}
            />
          </div>
        </InputDiv>
        <InputDiv>
          <div>
            <label>Média geração SPT quantidade: </label>
            <input id='med_ger_spt_quant' value={data.med_ger_spt_quant} onChange={changeInfo} />
          </div>
        </InputDiv>
        <InputDiv>
          <div>
            <label>Média geração P quantidade: </label>
            <input id='med_ger_p_quant' value={data.med_ger_p_quant} onChange={changeInfo} />
          </div>
        </InputDiv>
        <InputDiv>
          <div>
            <label>Média geração FP quantidade: </label>
            <input id='med_ger_fp_quant' value={data.med_ger_fp_quant} onChange={changeInfo} />
          </div>
        </InputDiv>
      </Content>
    </Container>
  )
}
