import { useCallback, useState } from 'react'

interface Pagination<T> {
  pageInfo: {
    currentPage: number
    limit: number
    totalCount: number
    totalPages: number
  }
  allData: T[]
  data: T[]
}

interface SortParams {
  field: string
  order: 'asc' | 'desc'
}

interface PaginationParams {
  page?: number
  limit?: number
  filterByIds?: string[]
  searchFilter?: string
  sort?: SortParams
}

interface Props<T> {
  rawData: T[]
  filterDataByIds: (data: T[], ids: string[]) => T[]
  filterBySearch: (data: T[], searchFilter: string) => T[]
  filterBySort: (data: T[], sort: SortParams) => T[]
}

export function usePagination<T>(props: Props<T>) {
  const { rawData, filterDataByIds, filterBySearch, filterBySort } = props

  const [pagination, setPagination] = useState<Pagination<T>>({
    pageInfo: {
      currentPage: 1,
      limit: 15,
      totalCount: 0,
      totalPages: 1,
    },
    allData: rawData,
    data: [],
  })

  const [searchFilter, setSearchFilter] = useState<string>('')
  const [itemsPerPage, setItemsPerPage] = useState<number>(pagination.pageInfo.limit)
  const [currentPage, setCurrentPage] = useState<number>(pagination.pageInfo.currentPage)
  const [sortParams, setSortParams] = useState<SortParams>()
  const [idsFiltered, setIdsFiltered] = useState<string[]>([])

  const getPagination = useCallback(
    (paginationParams: PaginationParams) => {
      const { page = 1, searchFilter, limit = 15, sort, filterByIds } = paginationParams

      if (page) {
        setCurrentPage(page)
      }

      if (limit) {
        setItemsPerPage(limit)
      }

      let filteredData = searchFilter ? filterBySearch(rawData, searchFilter) : rawData

      if (searchFilter) {
        setSearchFilter(searchFilter)
      }

      if (filterByIds) {
        filteredData = filterDataByIds(filteredData, filterByIds)
        setIdsFiltered(filterByIds)
      }

      if (sort) {
        filterBySort(filteredData, sort)
        setSortParams(sort)
      }

      const totalCount = filteredData.length
      const totalPages = Math.ceil(totalCount / limit)

      const startIndex = (page - 1) * limit
      const slicedData = filteredData.slice(startIndex, startIndex + limit)

      setPagination({
        pageInfo: {
          currentPage: page,
          limit,
          totalCount,
          totalPages,
        },
        allData: filteredData,
        data: slicedData,
      })
    },
    [rawData],
  )

  return {
    pagination,
    idsFiltered,
    sortParams,
    currentPage,
    searchFilter,
    itemsPerPage,
    getPagination,
    setSearchFilter,
  }
}
