export const handleMoneyFormat = (value: number, showSymbol?: boolean) => {
  try {
    const formattedValue = Number(value.toFixed(2)).toLocaleString('pt-BR')

    if (formattedValue.split(',')[1] === undefined) {
      return showSymbol ? `R$ ${formattedValue},00` : `${formattedValue},00`
    } else if (formattedValue.split(',')[1].length < 2) {
      return showSymbol ? `R$ ${formattedValue}0` : `${formattedValue}0`
    } else {
      return showSymbol ? `R$ ${formattedValue}` : formattedValue
    }
  } catch (err) {
    return 'R$ 0,00'
  }
}
