import { useState } from 'react'
import { Table } from '../../../../../components/Table'
import { ConferencePagination, ConferenceSortEvent, TableColumn } from '../../../../../interfaces/conference'
import { handleMoneyFormat } from '../../../../../utils/formatMoney'

type SortEvent = (params: ConferenceSortEvent) => void

interface Props {
  tableColumns: TableColumn[]
  pagination: ConferencePagination
  sortEvent?: ConferenceSortEvent
  onPageChange: (page: number) => void
  onSort: SortEvent
}

export default function ConferenceTable(props: Props) {
  const { tableColumns, pagination, sortEvent, onSort, onPageChange } = props

  const [tableStateHeader, setTableStateHeader] = useState(
    tableColumns.map((column) => ({
      field: column.field,
      sort: sortEvent && sortEvent.field === column.field ? sortEvent.sort : undefined,
    })),
  )

  return (
    <Table
      pagination={{
        page: pagination.pageInfo.currentPage,
        total: pagination.pageInfo.totalCount,
        limit: pagination.pageInfo.limit,
        totalRegistersPerPage: pagination.data.length,
        onChangePage: onPageChange,
      }}
      columns={tableColumns}
      rows={pagination.data.map((conference) => {
        const invoicingRemainder =
          conference.previousMonthInvoicing - conference.currentMonthInvoicing

        return {
          ...conference,
          id: conference.entity.id,
          name: conference.entity.name,
          currentMonthInvoicingRemainder:
            invoicingRemainder > 0 ? handleMoneyFormat(invoicingRemainder) : '-',
          currentMonthInvoicing:
            conference.currentMonthInvoicing > 0
              ? handleMoneyFormat(conference.currentMonthInvoicing)
              : '-',
          previousMonthInvoicing:
            conference.previousMonthInvoicing > 0
              ? handleMoneyFormat(conference.previousMonthInvoicing)
              : '-',
        }
      })}
      alignText='center'
      sortEvent={onSort}
      headerModel={tableStateHeader}
      setHeaderModel={setTableStateHeader}
      serverSorting
      messageNoRow='Não há dados.'
    />
  )
}
