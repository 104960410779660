import Widget from '../../Widget'
import Chart, { Props } from 'react-apexcharts'
import {
  ButtonsDiv,
  CalendarDiv,
  CalendarText,
  ChartBody,
  DownloadDiv,
  FiltersDiv,
  OptionItem,
  OptionList,
  TitleDiv,
} from './styles'
import CalendarIcon from '../../../assets/icons/calendar-icon.svg'
import DownloadIcon from '../../../assets/icons/download-icon.svg'
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import './style.css'
import { formatNumberWithDot } from '../../../utils/formatNumber'
import ChavroneDown from '../../../assets/chevron-down.svg'
import { FloatingCheckboxes } from '../../FloatingCheckboxes'

import { convertDateToMonthName } from '../../../utils/dateToName'
import { AreaChartPayload } from '../../../services/requests/types'
import { EnergyTypes } from '../../../pages/Dashboards/DashboardGenerator/type'
import writeXlsxFile from 'write-excel-file'
import { GlobalContext } from '../../../context/global/global'
import { dashboardEnergyDownloadExcel } from '../../../services/requests/user-requests'

interface AreaProps {
  dateSelected: { month: number; year: number }
  defaultCheckedIds: Array<number>
  data: AreaChartPayload[] | { campo: string; mes_referencia: string; valor: number }[]
  ids: { id: number; label: string; checked: boolean }[]
  energyType: { label: string; value: string }
  hideButtons?: boolean
  setEnergyType: Dispatch<SetStateAction<{ label: string; value: string }>>
  setSelectedItems: Dispatch<
    SetStateAction<{ id: number; label: string; checked: boolean }[] | undefined>
  >
  selectedColor?: string
  setSelectedColor?: Dispatch<SetStateAction<string>>
  getSelectedIds?: () => number[] | undefined
  extraFilters?: ReactNode
}

export default function AreaChart({
  ids,
  dateSelected,
  setSelectedItems,
  defaultCheckedIds,
  data,
  energyType,
  setEnergyType,
  hideButtons,
  selectedColor,
  setSelectedColor,
  getSelectedIds,
  extraFilters,
}: AreaProps) {
  const { Theme } = React.useContext(GlobalContext)
  const energyOption = Object.values(EnergyTypes).map((item, index) => ({
    label: item,
    value: Object.keys(EnergyTypes)[index],
  }))

  const [showDropDown, setShowDropDown] = useState(false)
  const [dropDownEnergy, setDropDownEnergy] = useState(false)
  const [selectedPowerPlants, setSelectedPowerPlants] =
    useState<{ usina?: string; mes_referencia: string; valor: number; campo?: string }[][]>()
  useEffect(() => {
    dividePowerPlants()
  }, [data])

  const maxInterval = 13

  const getMonths = data
    .slice(0, maxInterval)
    .map((value) => convertDateToMonthName(value.mes_referencia))

  const dividePowerPlants = () => {
    const res = []

    for (let i = 0; i < data.length; i += maxInterval) {
      const chunk = data.slice(i, i + maxInterval)
      res.push(chunk)
    }

    setSelectedPowerPlants(res)
  }

  const avg = (arr: { name?: string; data: number[] }) => {
    return arr.data.reduce((r: number, c: number) => r + c) / arr.data.length
  }

  const [value, setValue] = useState({
    id: maxInterval,
    name: 'Últimos 12 meses',
  })

  const handleTitle = (value: string) => {
    switch (value) {
      case 'consumida':
        return 'Energia Consumida'
      case 'compensada':
        return 'Energia Compensada'
      case 'acumulado':
        return 'Saldo Acumulado'
      default:
        return ''
    }
  }

  const handleEventClicker = (config: any) => {
    if (hideButtons) {
      return null
    } else {
      if (selectedColor) {
        return null
      } else {
        setSelectedItems(
          ids.map((item) =>
            item.label === config.w.config.series[config.seriesIndex].name
              ? { ...item, checked: true }
              : { ...item, checked: false },
          ),
        )
        setSelectedColor &&
          setSelectedColor((chartOptions!.options!.colors as Array<string>)[config.seriesIndex])
      }
    }
  }

  const shouldFormat = hideButtons
    ? {
        show: false,
      }
    : {
        show: true,
        formatter: function (value: any, { seriesIndex, w }: any) {
          return `${w.config.series[seriesIndex].name}`
        },
        format: 'MMM',
      }

  const chartOptions: Pick<Props, 'options' | 'series'> = {
    options: {
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.8,
          opacityTo: 0.7,
        },
      },
      stroke: {
        width: 2,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: true,
        },
        shared: hideButtons ? true : false,
        intersect: false,
        followCursor: true,
        style: {
          fontFamily: 'Roboto',
          fontSize: '12px',
        },
        x: shouldFormat,
        y: {
          formatter: function (value: number) {
            return `${Math.trunc(value).toLocaleString('pt-BR')} kWh`
          },
          title: hideButtons
            ? {}
            : {
                formatter: function () {
                  return `${energyType.label}: `
                },
              },
        },
        marker: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: selectedColor
        ? [selectedColor]
        : [
            Theme.colors.darkishGreen,
            Theme.colors.DarkSpringGreen,
            Theme.colors.SeaGreen,
            Theme.colors.appleGreen,
            Theme.colors.lightGreen,
          ],
      chart: {
        id: 'area-chart',
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: false,
        },
        events: {
          markerClick: (event: any, chartContext: any, config: any) => {
            handleEventClicker(config)
          },
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        categories: getMonths.slice(maxInterval - value.id, maxInterval),
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        tickAmount: 10,
        labels: {
          formatter: (value: number) => {
            return formatNumberWithDot(value)
          },
        },
        axisTicks: {
          show: true,
          color: '#808080',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
    series:
      selectedPowerPlants &&
      selectedPowerPlants
        .map((item) => ({
          name: hideButtons ? handleTitle(item[0].campo ?? '') : item[0].usina,
          data: item.map((value) => value.valor).slice(maxInterval - value.id, maxInterval),
        }))
        .sort((a, b) => {
          return avg(b) - avg(a)
        }),
  }

  const filterPowerPlants = (values: any) => {
    setSelectedColor && setSelectedColor('')
    setSelectedItems(values)
  }

  const calendarOption = [
    {
      id: maxInterval,
      name: 'Últimos 12 meses',
    },
    {
      id: 6,
      name: 'Últimos 6 meses',
    },
    {
      id: 3,
      name: 'Últimos 3 meses',
    },
  ]

  const handleDownloadButton = async () => {
    if (!getSelectedIds) return

    const selectedIds = getSelectedIds()

    await dashboardEnergyDownloadExcel({
      usinas_id: selectedIds,
      month: dateSelected.month,
      year: dateSelected.year,
      periodo: value.id,
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `resumo_energetico_${dateSelected.month + '_' + dateSelected.year}.xlsx`
        document.body.appendChild(element)
        element.click()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Widget>
      {selectedPowerPlants && (
        <ChartBody>
          <TitleDiv>
            <ButtonsDiv>
              {!hideButtons && (
                <>
                  <FloatingCheckboxes
                    isFilterActive={
                      ids
                        .filter(({ id }) => !defaultCheckedIds.includes(id))
                        .some(({ checked }) => checked == true) ||
                      ids
                        .filter(({ id }) => defaultCheckedIds.includes(id))
                        .some(({ checked }) => checked == false)
                    }
                    label='Usinas'
                    checkbox
                    options={ids.map((item) => item)}
                    key={ids.toString()}
                    selectableAll
                    searchable
                    submitAction={filterPowerPlants}
                  />
                  {extraFilters && extraFilters}
                  <FiltersDiv
                    onClick={() => setDropDownEnergy(!dropDownEnergy)}
                    showDropDown={dropDownEnergy}
                  >
                    <CalendarText style={{fontSize: '15px'}}>{energyType.label}</CalendarText>
                    <img src={`${ChavroneDown}`} style={{ width: 10, height: 5 }} />
                    <OptionList showDropDown={dropDownEnergy} >
                      {energyOption.map((item) => (
                        <OptionItem
                          key={item.value}
                          onClick={() => {
                            if (item.value !== energyType.value) {
                              setEnergyType(item)
                              setDropDownEnergy(false)
                            }
                          }}
                          active={item.value === energyType.value}
                        >
                          {item.label}
                        </OptionItem>
                      ))}
                    </OptionList>
                  </FiltersDiv>
                </>
              )}
            </ButtonsDiv>
            <ButtonsDiv>
              <CalendarDiv
                onClick={() => setShowDropDown(!showDropDown)}
                showDropDown={showDropDown}
              >
                <img src={`${CalendarIcon}`} alt='Calendar Button' />
                <CalendarText>{value.name}</CalendarText>
                <OptionList showDropDown={showDropDown}>
                  {calendarOption.map((item) => (
                    <OptionItem
                      key={item.id}
                      onClick={() => {
                        setValue({ id: item.id, name: item.name })
                        setShowDropDown(false)
                      }}
                      active={value.id === item.id}
                    >
                      {item.name}
                    </OptionItem>
                  ))}
                </OptionList>
              </CalendarDiv>
              <DownloadDiv>
                <img
                  src={`${DownloadIcon}`}
                  alt='Download Button'
                  onClick={() => handleDownloadButton()}
                />
              </DownloadDiv>
            </ButtonsDiv>
          </TitleDiv>
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type='area'
            width='100%'
            height={318}
          />
        </ChartBody>
      )}
    </Widget>
  )
}
