export const initialValues = {
  name: '',
  email: '',
  emails_pagamentos: [{
    id: 0,
    email: '',
  }],
  cpf_cnpj: '',
  fone: '',
  has_aggregate_payments: false,
  aggr_pay_metodo_id: null,
}
