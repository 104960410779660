import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './styles/global'
import { BrowserRouter } from 'react-router-dom'
import { Router } from './Routes'
import { GlobalContext, GlobalContextProvider } from './context/global/global'
import { NotificationProvider } from './hooks/useNotifications'
import { ToastProvider } from './hooks/useToast'

function ThemeProviderWrapper() {
  const { Theme } = React.useContext(GlobalContext)

  return (
    <>
      <GlobalStyle theme={Theme} />
      <ThemeProvider theme={Theme}>
        <Router />
      </ThemeProvider>
    </>
  )
}

function App() {
  return (
    <>
      <BrowserRouter>
        <GlobalContextProvider>
          <NotificationProvider>
            <ToastProvider>
              <ThemeProviderWrapper />
            </ToastProvider>
          </NotificationProvider>
        </GlobalContextProvider>
      </BrowserRouter>
    </>
  )
}

export default App
