import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { InvoiceValidation } from '../../../../../interfaces/invoices'
import { FieldContainer, SectionContainer, SectionGrid, SectionTitle } from '../InvoiceModal/styles'
import { Form } from 'formik'
import { InputV2 } from '../../../../../components/InputV2'

const consumeFieldsMap = [
  'consumo_spt',
  'consumo_p_te',
  'consumo_p_tusd',
  'consumo_fp_te',
  'consumo_fp_tusd',
  'consumo_p',
  'consumo_fp',
  'consumo_total_faturado',
  'energia_comp_spt',
  'energia_comp_spt_te',
  'energia_comp_spt_tusd',
  'energia_comp_fp_te',
  'energia_comp_fp_tusd',
  'energia_comp_p_te',
  'energia_comp_p_tusd',
  'energia_comp_fp',
  'energia_comp_p',
  'energia_comp_total',
  'creditos_estoque',
  'creditos_saldo_ger',
  'tot_trib',
]

interface Props {
  invoiceValidation: InvoiceValidation
  showGeneral?: boolean
}

export default function InvoiceForm(props: PropsWithChildren<Props>) {
  const { invoiceValidation, showGeneral, children } = props

  const formFields = useMemo(() => {
    if (!invoiceValidation.invoiceData) {
      return []
    }
    const entries = Object.entries(invoiceValidation.invoiceData)

    const consumesFieldsFound: Record<string, any> = {}

    for (const [key, value] of entries) {
      const consumeField = consumeFieldsMap.find((consume) => key.startsWith(consume))
      if (consumeField) {
        const itemKey = consumeField

        const itemExists = consumesFieldsFound[itemKey]
        consumesFieldsFound[itemKey] = [...(itemExists ?? []), { originalKey: key, value }]
      } else {
        if (showGeneral) {
          const itemExists = consumesFieldsFound['general']
          if (!itemExists) {
            consumesFieldsFound['general'] = []
          }

          consumesFieldsFound['general'] = [...(itemExists ?? []), { originalKey: key, value }]
        }
      }
    }

    const groups: Record<string, ReactNode[]> = {}

    for (const [key, value] of Object.entries(consumesFieldsFound)) {
      if (!groups[key]) {
        groups[key] = []
      }
      groups[key].push(
        <>
          {value.map((item: any) => {
            const isGeneral = key === 'general'
            const itemLabel = isGeneral
              ? item.originalKey
              : (item.originalKey as string).replace(key, '')
            return (
              <FieldContainer
                style={{ width: 'fit-content' }}
                key={`invoice-field-${item.originalKey}`}
              >
                <InputV2
                  id={item.originalKey}
                  label={isGeneral ? itemLabel : itemLabel.substring(1, itemLabel.length)}
                  required
                />
              </FieldContainer>
            )
          })}
        </>,
      )
    }

    return (
      <>
        {Object.entries(groups)
          .sort(([keyA], [keyB]) => {
            if (keyA === 'general') return -1
            if (keyB === 'general') return 1
            return keyA.localeCompare(keyB)
          })
          .map(([key, value]) => (
            <SectionContainer key={`invoice-group-${key}`}>
              <SectionTitle variant='body1'>{key}</SectionTitle>
              <SectionGrid>{value}</SectionGrid>
            </SectionContainer>
          ))}
      </>
    )
  }, [invoiceValidation, showGeneral])

  return (
    <Form style={{ position: 'relative' }}>
      {formFields}
      {children}
    </Form>
  )
}
