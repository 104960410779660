import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import Modal from '../'
import { invoiceToDemonstrative, sendFees } from '../../../services/requests/user-requests'

import { ButtonsContainer, ContainerList, SendButton } from './styles'

import { EditPaymentModalProps } from './types'

export function FeeModal({
  openModal,
  setModalOpen,
  receiveChargeId,
  receivePaymentData,
  postAction,
}: EditPaymentModalProps) {
  console.log(receivePaymentData)

  const [opacity, setOpacity] = useState(1)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState([])
  const [selectedCharge, setSelectedCharge] = useState<any>()
  const [file, setFile] = useState<File>()

  function getChargeInfo() {
    const charge =
      receivePaymentData &&
      receivePaymentData.find((item: any) => item.fechamento_id === receiveChargeId)
    setSelectedCharge(charge)
    console.log(charge)
  }

  function sendData() {
    ;(async () => {
      if (file) {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('files', file)
        await sendFees(formData)
          .then(() => alert('Arquivo lido com sucesso'))
          .catch(() => alert('Ocorreu um erro'))
          .finally(() => {
            setIsLoading(false)
            setModalOpen(false)
            postAction!()
          })
      }
    })()
  }

  useEffect(() => {
    getChargeInfo()
  }, [receivePaymentData, receiveChargeId])

  return (
    <>
      <Modal
        title='Atualização de tarifas no sistema'
        openModal={openModal}
        setModalOpen={setModalOpen}
        defaultButtons={false}
        opacity={opacity}
        subtitle='Adicione abaixo o arquivo baixado em https://portalrelatorios.aneel.gov.br/luznatarifa/basestarifas'
      >
        <ContainerList>
          <input
            type='file'
            onChange={(event: any) => {
              const { files } = event.target

              if (files) {
                setFile(files[0])
              }
            }}
          />
        </ContainerList>
        <ButtonsContainer>
          <SendButton onClick={sendData} disabled={isLoading && !result.length}>
            {isLoading ? <CircularProgress size={20} /> : `Enviar`}
          </SendButton>
        </ButtonsContainer>
      </Modal>
    </>
  )
}
