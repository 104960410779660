import { useMemo, useState } from 'react'
import { PlantInvoicingPagination, SortParams } from '../../../../interfaces/plants'
import { Table } from '../../../../components/Table'
import { handleMoneyFormat } from '../../../../utils/formatMoney'
import { monthsList } from '../../../../utils/selectDates'
import { GridColDef } from '@mui/x-data-grid'

type SortEvent = (params: SortParams) => void

interface Props {
  tableColumns: GridColDef<any, any, any>[]
  pagination: PlantInvoicingPagination
  sortEvent?: SortParams
  onPageChange: (page: number) => void
  onSort: SortEvent
}

export default function PlantsInvoicingTable(props: Props) {
  const { tableColumns, pagination, sortEvent, onSort, onPageChange } = props

  const [tableStateHeader, setTableStateHeader] = useState(
    tableColumns.map((column) => ({
      field: column.field,
      sort: sortEvent && sortEvent.field === column.field ? sortEvent.order : undefined,
    })),
  )

  const tableData = useMemo(() => {
    const data = pagination.data
      .map((invoicing) =>
        invoicing.months.map((date) => ({
          id: `${invoicing.plant.id}-${date.month}-${date.year}`,
          ucCode: invoicing.plant.code,
          name: invoicing.plant.name,
          date: `${date.month}-${date.year}`,
          finesAndFees: date.finesAndFees,
          invoicing: date.invoicing,
          downloadData: {
            plantId: invoicing.plant.id,
            date: `${date.year}-${date.month}`,
          },
        })),
      )
      .flat()

    if (sortEvent) {
      switch (sortEvent.field) {
        case 'invoicing': {
          data.sort((d1, d2) =>
            sortEvent.order === 'asc' ? d2.invoicing - d1.invoicing : d1.invoicing - d2.invoicing,
          )
          break
        }
        case 'finesAndFees': {
          data.sort((d1, d2) =>
            sortEvent.order === 'asc' ? d2.finesAndFees - d1.finesAndFees : d1.finesAndFees - d2.finesAndFees,
          )
          break
        }
        case 'date': {
          data.sort((d1, d2) => {
            const [d1Month, d1Year] = d1.date.split('-').map((i) => parseInt(i, 10))
            const [d2Month, d2Year] = d2.date.split('-').map((i) => parseInt(i, 10))
            if (sortEvent.order === 'asc') {
              if (d1Year !== d2Year) {
                return d1Year - d2Year
              }
              return d1Month - d2Month
            }
            if (d2Year !== d1Year) {
              return d2Year - d1Year
            }
            return d2Month - d1Month
          })
          break
        }
      }
    }

    return data.map((item) => {
      const itemDate = item.date.split('-')
      return {
        ...item,
        date: `${monthsList[parseInt(itemDate[0], 10) - 1].label}/${itemDate[1]}`,
        invoicing: handleMoneyFormat(item.invoicing),
        finesAndFees: handleMoneyFormat(item.finesAndFees)
      }
    })
  }, [pagination, sortEvent])

  return (
    <Table
      pagination={{
        page: pagination.pageInfo.currentPage,
        total: pagination.pageInfo.totalCount,
        limit: pagination.pageInfo.limit,
        totalRegistersPerPage: pagination.data.length,
        onChangePage: onPageChange,
      }}
      columns={tableColumns}
      rows={tableData}
      alignText='center'
      sortEvent={(event: any) => {
        onSort({
          field: event.field,
          order: event.sort,
        })
      }}
      headerModel={tableStateHeader}
      setHeaderModel={setTableStateHeader}
      serverSorting
      messageNoRow='Não há dados.'
    />
  )
}
