import { useState } from 'react'
import { ticketsService } from '../../../../../../services/tickets'
import { ContinueButton } from './styles'

interface Props {
  ticketId: number
  onClose: () => void
}

export default function CloseTicketButton(props: Props) {
  const { ticketId, onClose } = props

  const [isLoading, setLoading] = useState<boolean>(false)

  const closeTicket = async () => {
    try {
      setLoading(true)
      await ticketsService.closeTicket({
        ticketId,
      })
      onClose()
    } catch (error) {
      console.log(`Error closing ticket: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ContinueButton
      text='Encerrar ticket'
      mode='darkGreen'
      type='submit'
      containerStyle={{ width: 'fit-content', marginLeft: 'auto'  }}
      isLoading={isLoading}
      onClick={closeTicket}
      disabled={isLoading}
    />
  )
}
