import { UseAutocompleteReturnValue } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { InputWrapper, Label, Listbox, Root, StyledTag } from './styles'
import CheckIcon from '@mui/icons-material/Check'

type Props = UseAutocompleteReturnValue<any, true, false, false> & {
  label?: string
  getOptionLabel: (option: any) => string
}

export default function MultiSelect(props: Props) {
  const {
    getOptionLabel,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    label,
    setAnchorEl,
  } = props

  return (
    <Root>
      <div {...getRootProps()}>
        {label && <Label {...getInputLabelProps()}>{label}</Label>}
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index })
            return (
              <StyledTag key={key} {...tagProps}>
                <span>{getOptionLabel(option)}</span>
                <CloseIcon style={{ fontSize: '24px' }} onClick={tagProps.onDelete} />
              </StyledTag>
            )
          })}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              key={`multi-select-item-${option.toString()}`}
            >
              <span>{getOptionLabel(option)}</span>
              <CheckIcon fontSize='small' />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  )
}
