import { PlantInvoicing } from '../interfaces/plants'
import { api } from './api'

interface GetPlantsInvoicing {
  params: {
    date: string
  }
  result: PlantInvoicing[]
}

interface GetPlantsInvoicing {
  params: {
    date: string
  }
  result: PlantInvoicing[]
}

interface GetPlantsInvoicingSheet {
  params: {
    date: string
    fromDate?: string
    plantsIds: string[]
  }
  result: Blob
}

export class PlantsService {
  async getPlantsInvoicing(
    params: GetPlantsInvoicing['params'],
  ): Promise<GetPlantsInvoicing['result']> {
    const response = await api.get('/pagamentos/power-plants', {
      params,
    })
    return response.data
  }
  async getPlantsInvoicingSheet(
    params: GetPlantsInvoicingSheet['params'],
  ): Promise<GetPlantsInvoicingSheet['result']> {
    const { plantsIds, ...rest } = params
    const response = await api.get('/pagamentos/power-plants/csv', {
      params: {
        ...rest,
        ...(plantsIds.length > 0 ? { plantsIds: plantsIds.join(',') } : {}),
      },
      responseType: 'blob',
    })
    const blob = new Blob([response.data])
    return blob
  }
}

export const plantsService = new PlantsService()
