import { ChevronRight } from '@mui/icons-material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import PaperDisabledIcon from '../../../assets/paper-disabled.svg'
import PaperIcon from '../../../assets/paper.svg'
import { handleMoneyFormat } from '../../../utils/formatMoney'
import { ButtonDownloadTable, ObservationMessage, PowerPlantCell, StatusCell } from '../styles'
import { colorClassName, statusStyle } from './selectedObservationFieldColor'
import { getInvoiceDataLocation } from '../../../services/requests/user-requests'
import { useContext } from 'react'
import { GlobalContext } from '../../../context/global/global'

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

function isDateValid(dateString: string) {
  const date = new Date(`${dateString} 12:00:00`)
  return !isNaN(date.getTime())
}

export const tableInvoiceColumns = ({
  setCurrentId,
  setStatus,
  setPage,
  setSearch,
  setSortValues,
}: any): GridColDef[] => [
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status',
      minWidth: 80,
      maxWidth: 120,
      sortable: true,
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        return <StatusCell className={statusStyle[params.value]}>{params.value}</StatusCell>
      },
    },
    {
      field: 'usina_nome',
      headerName: 'Usinas',
      description: 'Usinas',
      minWidth: 380,
      maxWidth: 600,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return (
          <PowerPlantCell
            onClick={() => {
              setPage(1)
              setStatus('')
              setCurrentId({ id: params.row.usina_id, name: params.row.usina_nome })
              setSearch('')
              setSortValues(undefined)
            }}

          >
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '340px'
              }}
              title={params.value}
            >
              {params.value}
            </span>
            <ChevronRight fontSize='medium' />
          </PowerPlantCell>
        )
      },
    },
    {
      field: 'uc_numero',
      headerName: 'Número da UC',
      description: 'Número da UC',
      minWidth: 120,
      maxWidth: 180,
      sortable: true,
      hideable: true,
    },
    {
      field: 'distribuidora_nome',
      headerName: 'Distribuidora',
      description: 'Distribuidora',
      maxWidth: 180,
      minWidth: 120,
      sortable: true,
      hideable: true,
    },
    {
      field: 'valor_total_fatura',
      headerName: 'Valor (R$)',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? handleMoneyFormat(params.value) : '-'
      },
      minWidth: 100,
      maxWidth: 140,
      sortable: true,
      hideable: true,
    },
    {
      field: 'estimativa_emissao',
      headerName: 'Estimativa de emissão',
      description: 'Estimativa de emissão',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value && isDateValid(params.value)
          ? new Date(`${params.value} 12:00:00`).toLocaleString('pt-BR').substring(0, 10)
          : '-'
      },
      minWidth: 100,
      maxWidth: 140,
      sortable: true,
      hideable: true,
    },
    {
      field: 'data_vencimento',
      headerName: 'Vencimento da fatura',
      description: 'Vencimento da fatura',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value
          ? new Date(`${params.value} 12:00:00`).toLocaleString('pt-BR').substring(0, 10)
          : '-'
      },
      minWidth: 120,
      maxWidth: 140,
      sortable: true,
      hideable: true,
    },
    {
      field: 'observacao',
      headerName: 'Observação',
      description: 'Observação',
      renderCell(params: GridRenderCellParams<any, any, any>) {
        return (
          <ObservationMessage className={colorClassName[params.value]}>
            {params.value}
          </ObservationMessage>
        )
      },
      minWidth: 120,
      maxWidth: 180,
      sortable: true,
      hideable: true,
    },
    {
      field: 'fatura_drive_id',
      headerName: 'Fatura',
      description: 'Fatura',
      width: 80,
      sortable: false,
      hideSortIcons: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        return (
          <ButtonDownloadTable
            onClick={() =>
              params.value
                ? openInNewTab(`${process.env.REACT_APP_BASE_URL}/faturas/download/${params.value}`)
                : null
            }
            disabled={params.value === null}
          >
            {params.value ? (
              <img src={PaperIcon.toString()} />
            ) : (
              <img src={PaperDisabledIcon.toString()} />
            )}
          </ButtonDownloadTable>
        )
      },
    },
  ]

export const tableInvoiceColumnsDistributor = [
  {
    field: 'status',
    headerName: 'Status',
    description: 'Status',
    minWidth: 80,
    maxWidth: 120,
    sortable: true,
    hideable: true,
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return <StatusCell className={statusStyle[params.value]}>{params.value}</StatusCell>
    },
  },
  {
    field: 'uc_numero',
    headerName: 'UCs Beneficiárias',
    description: 'UCs Beneficiárias',
    minWidth: 120,
    maxWidth: 160,
    sortable: true,
    hideable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <PowerPlantCell>{params.value}</PowerPlantCell>
    },
  },
  {
    field: 'usina_nome',
    headerName: 'Nome da UC',
    description: 'Nome da UC',
    minWidth: 320,
    sortable: true,
    hideable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <PowerPlantCell>
        <span
          title={params.value}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {params.value}
        </span>
      </PowerPlantCell>
    },
  },
  {
    field: 'distribuidora_nome',
    headerName: 'Distribuidora',
    description: 'Distribuidora',
    minWidth: 160,
    sortable: true,
    hideable: true,
  },
  {
    field: 'valor_total_fatura',
    headerName: 'Valor (R$)',
    description: 'Valor (R$)',
    minWidth: 100,
    sortable: true,
    hideable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? handleMoneyFormat(params.value) : '-'
    },
  },
  {
    field: 'estimativa_emissao',
    headerName: 'Estimativa de emissão',
    description: 'Estimativa de emissão',
    minWidth: 100,
    sortable: true,
    hideable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value && isDateValid(params.value)
        ? new Date(`${params.value} 12:00:00`).toLocaleString('pt-BR').substring(0, 10)
        : '-'
    },
  },
  {
    field: 'data_vencimento',
    headerName: 'Vencimento da fatura',
    description: 'Vencimento da fatura',
    minWidth: 100,
    sortable: true,
    hideable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value
        ? new Date(`${params.value} 12:00:00`).toLocaleString('pt-BR').substring(0, 10)
        : '-'
    },
  },
  {
    field: 'observacao',
    headerName: 'Observação',
    description: 'Observação',
    minWidth: 120,
    maxWidth: 220,
    sortable: true,
    hideable: true,
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return (
        <ObservationMessage className={colorClassName[params.value]}>
          {params.value}
        </ObservationMessage>
      )
    },
  },
  {
    field: 'fatura_drive_id',
    headerName: 'Fatura',
    description: 'Fatura',
    width: 80,
    sortable: false,
    hideSortIcons: true,
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return (
        <ButtonDownloadTable
          onClick={() =>
            params.value
              ? openInNewTab(`${process.env.REACT_APP_BASE_URL}/faturas/download/${params.value}`)
              : null
          }
          disabled={params.value === null}
        >
          {params.value ? (
            <img src={PaperIcon.toString()} />
          ) : (
            <img src={PaperDisabledIcon.toString()} />
          )}
        </ButtonDownloadTable>
      )
    },
  },
]

const tableWidthCol = 52

const monthFields = [
  { field: 'jan', headerName: 'Jan' },
  { field: 'fev', headerName: 'Fev' },
  { field: 'mar', headerName: 'Mar' },
  { field: 'abr', headerName: 'Abr' },
  { field: 'mai', headerName: 'Mai' },
  { field: 'jun', headerName: 'Jun' },
  { field: 'jul', headerName: 'Jul' },
  { field: 'ago', headerName: 'Ago' },
  { field: 'set', headerName: 'Set' },
  { field: 'out', headerName: 'Out' },
  { field: 'nov', headerName: 'Nov' },
  { field: 'dez', headerName: 'Dez' },
].map((month) => ({
  ...month,
  sortable: false,
  width: tableWidthCol,
  hideSortIcons: true,
  renderCell(params: GridRenderCellParams<any, any, any>) {
    return (
      <ButtonDownloadTable
        onClick={() => {
          if (params.value) {
            openInNewTab(`${process.env.REACT_APP_BASE_URL}/faturas/download/${params.value}`)
          }
        }}
        disabled={params.value === null}
      >
        {params.value ? (
          <img src={PaperIcon.toString()} />
        ) : (
          <img src={PaperDisabledIcon.toString()} />
        )}
      </ButtonDownloadTable>
    )
  },
}))

const monthFieldsLocation = [
  { field: 'jan', headerName: 'Jan' },
  { field: 'fev', headerName: 'Fev' },
  { field: 'mar', headerName: 'Mar' },
  { field: 'abr', headerName: 'Abr' },
  { field: 'mai', headerName: 'Mai' },
  { field: 'jun', headerName: 'Jun' },
  { field: 'jul', headerName: 'Jul' },
  { field: 'ago', headerName: 'Ago' },
  { field: 'set', headerName: 'Set' },
  { field: 'out', headerName: 'Out' },
  { field: 'nov', headerName: 'Nov' },
  { field: 'dez', headerName: 'Dez' },
].map((month) => ({
  ...month,
  sortable: false,
  width: tableWidthCol,
  hideSortIcons: true,
  renderCell(params: GridRenderCellParams<any, any, any>) {
    const { setLocationLoading, setLocationError } = useContext(GlobalContext)

    return (
      <ButtonDownloadTable
        onClick={() => {
          ; (async () => {
            if (params.value) {
              setLocationLoading(true)
              await getInvoiceDataLocation({ drive_id: params.value })
                .then((response) => {
                  const file = new Blob([
                    new Uint8Array(response.data.toDownload.content.data).buffer,
                  ])

                  const element = document.createElement('a')
                  element.href = URL.createObjectURL(file)

                  element.download = response.data.toDownload.filename

                  document.body.appendChild(element)
                  element.click()
                })
                .catch((err) => setLocationError('Não foi possível baixar esta fatura!'))
                .finally(() => setLocationLoading(false))
            }
          })()
        }}
        disabled={params.value === null}
      >
        {params.value ? (
          <img src={PaperIcon.toString()} />
        ) : (
          <img src={PaperDisabledIcon.toString()} />
        )}
      </ButtonDownloadTable>
    )
  },
}))

export const tableInvoiceColumnsHistory = [
  {
    field: 'uc_nome',
    headerName: 'Nome da UC',
    minWidth: 320,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <span
          title={params.value}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {params.value}
        </span>
      )
    },
    sortable: true,
    hideable: true,
  },
  {
    field: 'uc_numero',
    headerName: 'Número da UC',
    minWidth: 120,
    maxWidth: 160,
    sortable: true,
    hideable: true,
  },
  {
    field: 'distribuidora_nome',
    headerName: 'Distribuidora',
    minWidth: 160,
    hideable: true,
    sortable: false,
  },
  {
    field: 'consumidor_nome',
    headerName: 'Consumidor',
    sortable: false,
    hideable: true
  },
  ...monthFields,
]

export const tableInvoiceColumnsHistoryLocation: GridColDef[] = [
  {
    field: 'uc_nome',
    headerName: 'Nome da UC',
    description: 'Nome da UC',
    sortable: true,
    hideable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const value = params.value ?? '-'
      return (
        <span
          title={value}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </span>
      )
    },
    minWidth: 280,
  },
  {
    field: 'uc_numero',
    headerName: 'Número da UC',
    description: 'Número da UC',
    sortable: true,
    hideable: true,
    maxWidth: 160,
    minWidth: 120
  },
  {
    field: 'consumidor_nome',
    headerName: 'Consumidor',
    description: 'Consumidor',
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const value = params.value ?? '-'

      return (
        <span
          title={value}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </span>
      )
    },
    minWidth: 240
  },
  ...monthFieldsLocation,
]
