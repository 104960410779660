import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { fonts, fontsType } from '../../../../components/Modal/Credits/ModalAddQuota/types'
import { CancellingAccess, DisabledAccess, ErrorAcess, PositiveAccess, PositiveAcess, StatusIcon } from './style'
import { GetPowerPlantsColumnsParams } from './types'
import { Box, Tooltip } from '@mui/material'
import DeleteIcon from '../../../../assets/delete.svg'
import EditIcon from '../../../../assets/edit.svg'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import { AgreementAccess } from '../ConsumerUnit/style'

const formatDocument = (value: string) => {
  const cnpjCpf = value.replace(/\D/g, '')

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  }

  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

export const getPowerPlantsColumns = ({
  data,
  deleteHandler,
  editHandler,
  statusHandler,
  reportHandler,
  permissions
}: GetPowerPlantsColumnsParams): GridColDef[] => {
  const rows: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      description: 'Nome',
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const value = params.value ?? '-'
        return (
          <span
            title={value}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </span>
        )
      },
      minWidth: 360,
    },
    {
      field: 'codigo',
      headerName: 'Número da UC',
      description: 'Número da UC',
      minWidth: 120,
      maxWidth: 160,
      sortable: true,
      hideable: true,
    },
    {
      field: 'distribuidor_nome',
      headerName: 'Distribuidora',
      description: 'Distribuidora',
      minWidth: 140,
      maxWidth: 180,
      sortable: true,
      hideable: true,
    },
    {
      field: 'potencia_instalada',
      headerName: 'Potência Instalada (kW)',
      description: 'Potência Instalada (kW)',
      sortable: true,
      hideable: true,
      maxWidth: 180,
      minWidth: 80
    },
    {
      field: 'fonte',
      headerName: 'Fonte',
      description: 'Fonte',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const value = params?.value as fontsType
        return fonts[value]
      },
      minWidth: 120,
      maxWidth: 140,
      sortable: true,
      hideable: true,
    },
    {
      field: 'acessivel_flag',
      headerName: 'Acesso',
      description: 'Acesso',
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? <PositiveAcess>Ok</PositiveAcess> : <ErrorAcess>Erro</ErrorAcess>
      },
      maxWidth: 120,
      minWidth: 100,
      sortable: true,
      hideable: true,
    },
    {
      field: 'documento_numero',
      headerName: 'CPF/CNPJ',
      description: 'CPF/CNPJ',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? formatDocument(params.value) : '-'
      },
      minWidth: 160,
      maxWidth: 180,
    },
    {
      field: 'status_ativacao_uc',
      headerName: 'Status de contrato',
      description: 'Status de contrato',
      sortable: true,
      hideable: true,
      renderCell(params: GridRenderCellParams<any, any, any>) {
        if (params.value === 'DISABLED') {
          return <DisabledAccess>Desativada</DisabledAccess>
        }
        if (params.value === 'CANCELLING') {
          return <CancellingAccess>Em cancelamento</CancellingAccess>
        }
        if (params.value === 'AGREEMENT') {
          return <AgreementAccess>Em acordo</AgreementAccess>
        }
        return <PositiveAccess>Ativa</PositiveAccess>
      },
      minWidth: 80,
      maxWidth: 145
    },
    {
      field: 'grupo',
      headerName: 'Grupo',
      description: 'Grupo',
      hide: true,
      sortable: true,
      hideable: true,
      maxWidth: 100,
      minWidth: 80
    },
  ]

  if (permissions && (permissions?.hasEditPermission || permissions?.hasDeletePermission)) {
    const actionsRow: GridColDef = {
      field: 'actions',
      headerName: 'Opções',
      renderHeader: () => '',
      sortable: false,
      hideable: false,
      width: 145,
      renderCell(params: Record<'row', Record<string, unknown>>) {
        const fontSize = 15;

        return (
          <Box display={'flex'} gap={2}>
            {permissions?.hasEditPermission && (
              <Tooltip title={<p style={{ fontSize }}>Editar</p>} placement='bottom'>
                <img
                  src={EditIcon.toString()}
                  onClick={() => editHandler(params.row?.id as number, data)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}

            {permissions?.hasDeletePermission && (
              <Tooltip title={<p style={{ fontSize }}>Deletar</p>} placement='bottom'>
                <img
                  src={DeleteIcon.toString()}
                  onClick={() => deleteHandler(params.row?.id as number)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}

            {permissions?.hasEditPermission && (
              <Tooltip
                title={<p style={{ fontSize: 15 }}>Alterar status de contrato</p>}
                placement='bottom'
              >
                <StatusIcon
                  onClick={() => statusHandler(params.row?.id as number)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}

            {!!reportHandler &&
              <Tooltip
                title={<p style={{ fontSize: 15 }}>Visualizar relatório do investidor</p>}
                placement='bottom'
              >
                <AssessmentOutlinedIcon
                  onClick={() => reportHandler(params.row?.id as number)}
                  fontSize='medium'
                  style={{ cursor: 'pointer', color: '#ababab' }}
                />
              </Tooltip>
            }
          </Box>
        )
      },
    }

    rows.push(actionsRow)
  }

  return rows
}
