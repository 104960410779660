import { PropsWithChildren, createContext, useContext } from 'react'

interface ModalContextData {
  onClose: () => void
}

const ModalContext = createContext({} as ModalContextData)

interface Props {
  onClose: () => void
}

export function ModalProvider(props: PropsWithChildren<Props>) {
  const { onClose, children } = props
  return <ModalContext.Provider value={{ onClose }}>{children}</ModalContext.Provider>
}

export function useModal() {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('Provider not found')
  }
  return context
}
