import { Alert, CircularProgress, Snackbar } from '@mui/material'
import { useState, FormEvent } from 'react'
import Modal from '../..'
import { resetPasswordRequest } from '../../../../services/requests/user-requests'
import { Input } from '../../../Input'
import { SuccessResetPasswordModal } from '../SuccessResetPassword'
import {
  ButtonsContainer,
  CancelButton,
  InvalidInputText,
  SendButton,
  TextResetPasswordModal,
} from './style'

type ResetPasswordModalProps = {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export function ResetPasswordModal({ openModal, setModalOpen }: ResetPasswordModalProps) {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)

  const isMailValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email,
  )

  function setModals(value: boolean) {
    setModalSuccessOpen(value)
    setModalOpen(value)
  }

  async function handlePasswordRecovery(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsLoading(true)
    try {
      await resetPasswordRequest({
        email,
      })
      setIsLoading(false)
      setModalSuccessOpen(true)
    } catch (err: any) {
      setIsLoading(false)
      setErrorMessage(err.message ?? 'Erro ao recuperar senha')
    }
  }

  return (
    <Modal
      title='Informe seu e-mail'
      openModal={openModal}
      setModalOpen={setModalOpen}
      defaultButtons={false}
    >
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
      {modalSuccessOpen && (
        <SuccessResetPasswordModal openModal={modalSuccessOpen} setModalOpen={setModals as any} />
      )}
      <TextResetPasswordModal>
        Enviaremos uma nova senha para o e-mail informado
      </TextResetPasswordModal>
      <form onSubmit={handlePasswordRecovery}>
        <Input
          heightInput={'45px'}
          label='E-mail'
          required
          mode={true}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!isMailValid && email.length > 0 && <InvalidInputText>Email inválido*</InvalidInputText>}
        <ButtonsContainer>
          <CancelButton onClick={() => setModalOpen(false)}>Cancelar</CancelButton>
          <SendButton type='submit' disabled={!isMailValid || email.length === 0}>
            {isLoading ? <CircularProgress size={20} /> : 'Enviar'}
          </SendButton>
        </ButtonsContainer>
      </form>
    </Modal>
  )
}
