import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import {
  FilterContainer,
  InvoicesContainer,
  SearchContainer,
  TableFilters,
  YearButton,
  YearText,
} from './styles'
import { useEffect, useMemo, useState } from 'react'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import { SettingsOutlined } from '@mui/icons-material'
import DownloadInvoiceButton from './components/DownloadInvoiceButton'
import PaperDisabledIcon from '../../../assets/paper-disabled.svg'
import { ListRentalInvoicesFromConsumer, invoiceService } from '../../../services/invoice'
import Loading from '../../../components/Loading'
import { TableV2 } from '../../../components/TableV2'
import { Pagination } from '../../../interfaces/conference'
import { ConsumerUCYearInvoices } from '../../../interfaces/invoice'
import { v4 } from 'uuid'
import SearchInput from '../../Tickets/components/SearchInput'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'
import { Sort } from '../../../interfaces/pagination'

const generateYear = () => {
  const year = []

  for (let i = 2018; i <= new Date().getFullYear(); i++) {
    year.push(i)
  }

  return year
}

const monthFieldsLocation = [
  { field: '01', headerName: 'Jan' },
  { field: '02', headerName: 'Fev' },
  { field: '03', headerName: 'Mar' },
  { field: '04', headerName: 'Abr' },
  { field: '05', headerName: 'Mai' },
  { field: '06', headerName: 'Jun' },
  { field: '07', headerName: 'Jul' },
  { field: '08', headerName: 'Ago' },
  { field: '09', headerName: 'Set' },
  { field: '10', headerName: 'Out' },
  { field: '11', headerName: 'Nov' },
  { field: '12', headerName: 'Dez' },
]

const defaultTableColumns: GridColDef[] = [
  {
    headerName: 'Nome da UC',
    field: 'ucName',
    hideable: true,
    sortable: true,
  },
  {
    headerName: 'Número da UC',
    field: 'ucCode',
    hideable: true,
    sortable: true,
  },
]

export default function InvoicesConsumerPage() {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear())

  const [invoices, setInvoices] = useState<Pagination<ConsumerUCYearInvoices>>({
    pageInfo: {
      currentPage: 1,
      limit: 15,
      totalCount: 1,
      totalPages: 1,
    },
    data: [],
  })
  const [isLoading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(invoices.pageInfo.currentPage)
  const [itemsPerPage, setItemsPerPage] = useState<number>(invoices.pageInfo.limit)
  const [searchValue, setSearchValue] = useState<string>('')
  const [sort, setSort] = useState<Sort>()

  const fetchInvoices = async (params: ListRentalInvoicesFromConsumer['params']) => {
    try {
      setLoading(true)
      const invoices: any = await invoiceService.listRentalInvoicesFromConsumer(params)
      setInvoices(invoices)
      setTableColumns(() => [
        ...defaultTableColumns,
        ...monthFieldsLocation.map((month) => {
          const fieldName = `${month.field}-${selectedYear}`
          return {
            ...month,
            field: fieldName,
            sortable: false,
            hideable: true,
            hide: false,
            hideSortIcons: true,
            width: 52,
            renderCell(params: GridRenderCellParams<any, any, any>) {
              const invoiceExists = invoices.data.find(
                (invoice: any) => invoice.uc.code === params.row.ucCode,
                )
                const fileId = invoiceExists ? `${invoiceExists.uc.code}-${params.field}` : ''
              return invoiceExists && invoiceExists.invoices.includes(month.field) ? (
                <DownloadInvoiceButton fileId={fileId} />
              ) : (
                <img src={PaperDisabledIcon.toString()} />
              )
            },
          }
        }),
      ])
    } catch (error) {
      console.log(`Error fetching consumer invoices: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const fetchParams = useMemo(
    () =>
      ({
        sort,
        itemsPerPage,
        year: selectedYear,
        page: currentPage,
        filter: {
          ...(searchValue ? { search: searchValue } : {}),
        },
      } as ListRentalInvoicesFromConsumer['params']),
    [searchValue, selectedYear, itemsPerPage, currentPage, sort],
  )

  useEffect(() => {
    fetchInvoices(fetchParams)
  }, [fetchParams])

  const [tableColumns, setTableColumns] = useState<GridColDef[]>([])

  return (
    <InvoicesContainer>
      <FilterContainer>
        <TableFilters>
          {generateYear().map((year) => (
            <YearButton
              key={`year-item-${year}`}
              active={year === selectedYear}
              onClick={() => {
                setCurrentPage(1)
                setSelectedYear(year)
              }}
            >
              <YearText>{year}</YearText>
            </YearButton>
          ))}
        </TableFilters>
        <SearchContainer>
          <SearchInput
            onSearch={(searchInput) => {
              setCurrentPage(1)
              setSearchValue(searchInput)
            }}
          />
          <FloatingCheckboxes
            pagination={{
              selected: itemsPerPage,
              setSelected: (perPage) => {
                setCurrentPage(1)
                setItemsPerPage(perPage)
              },
              options: [15, 20, 30],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableColumns
              .filter((col) => col.hideable)
              .map((col) => ({
                label: col.headerName || '',
                checked: !col.hide,
                id: Math.random(),
              }))}
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={(checkboxes) =>
              setTableColumns((previousColumns) =>
                [...previousColumns].map((col) => {
                  const checkbox = checkboxes.find((checkbox) => checkbox.label === col.headerName)
                  return {
                    ...col,
                    hide: checkbox ? !checkbox.checked : false,
                  }
                }),
              )
            }
          />
        </SearchContainer>
      </FilterContainer>

      {isLoading ? (
        <Loading />
      ) : (
        <TableV2
          alignText='center'
          columns={tableColumns}
          rows={invoices.data.map((data) => ({
            id: v4(),
            ucName: data.uc.name,
            ucCode: data.uc.code,
          }))}
          sort={sort}
          onSort={(sort) => setSort(sort)}
        >
          <TablePagination
            pageInfo={invoices.pageInfo}
            currentPageItemCount={invoices.data.length}
            onChange={(page) => setCurrentPage(page)}
          />
        </TableV2>
      )}
    </InvoicesContainer>
  )
}
