import { HTMLAttributes, useState } from 'react'
import { OptionItem, OptionList } from '../../../../../Tickets/styles'
import { FloatingFilterWrapper } from '../../../../../../components/FloatingFilterWrapper'

const roles = [{ label: 'Gestor', value: 'GESTOR' }, { label: 'Consumidor', value: 'CONSUMIDOR' }]

interface Props extends HTMLAttributes<HTMLDivElement> {
  clientTypeFilter?: string
  setClientTypeFilter: (value: string) => void
}

export default function ClientTypeFilter(props: Props) {
  const { clientTypeFilter, setClientTypeFilter } = props

  const [isStatusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive
      label='Tipo'
      styles={props.style}
      modalStyles={{ padding: 4 }}
      hideSubmitButton
    >
      <OptionList showDropDown={isStatusDropdownOpen}>
        {roles.map((item) => (
          <OptionItem
            active={clientTypeFilter === item.value}
            key={item.value}
            onClick={() => {
              setStatusDropdownOpen((previousStatusDropdownOpen) => !previousStatusDropdownOpen)
              const updatedValue = clientTypeFilter === item.value ? '' : item.value;
              setClientTypeFilter(updatedValue)
            }}
          >
            {item.label}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
