import { KnockFeedProvider, KnockProvider } from '@knocklabs/react'
import { PropsWithChildren, ReactElement, useContext } from 'react'
import { GlobalContext } from '../context/global/global'
// import '@knocklabs/react/dist/index.css'

export function NotificationProvider(props: PropsWithChildren): ReactElement {
  const { children } = props

  const { state } = useContext(GlobalContext)

  return (
    <KnockProvider
      apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY as string}
      userId={String(state.auth?.customer?.id)}
    >
      <KnockFeedProvider feedId={String(process.env.REACT_APP_KNOCK_FEED_ID)}>
        {children as any}
      </KnockFeedProvider>
    </KnockProvider>
  )
}
