import { Form, FormikProvider, useFormik } from 'formik'
import Modal from '../../../../../../components/ModalV2'
import { InputV2 } from '../../../../../../components/InputV2'
import * as Yup from 'yup'
import { useToast } from '../../../../../../hooks/useToast'
import { Button } from '../../../../../../components/Button'
import { useState } from 'react'
import {
  CNPJ_REGEX,
  CPF_MASK,
  CPF_REGEX,
  getDocumentMask,
  getDocumentNumber,
  getIndexesOfPipedChars,
} from '../../../../../../utils/mask-utils'
import { clientService } from '../../../../../../services/client'

interface FormData {
  name: string
  documentNumber: string
  email: string
}

const formValidationSchema: Yup.SchemaOf<FormData> = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  documentNumber: Yup.string()
    .required('Campo obrigatório')
    .test(
      'Valid',
      'Formato inválido',
      (value: any) => CPF_REGEX.test(value) || CNPJ_REGEX.test(value),
    ),
  email: Yup.string().email('Formato inválido').required('Campo obrigatório'),
})

interface Props {
  managerId?: number
  onClose: () => void
}

export default function ManagerModal(props: Props) {
  const { managerId, onClose } = props

  const { toast } = useToast()

  const [documentMask, setDocumentMask] = useState<Array<RegExp | string>>(CPF_MASK)
  const [isLoading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (values: FormData) => {
    try {
      setLoading(true)
      await clientService.createClient({
        ...values,
      })
      toast({
        message: 'Gestor criado com sucesso',
        type: 'success',
      })
      onClose()
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao criar gestor',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik<FormData>({
    initialValues: {
      name: '',
      documentNumber: '',
      email: '',
    },
    validationSchema: formValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  })

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {`${managerId ? 'Edição' : 'Cadastro'} de Gestor`} <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <FormikProvider value={formik}>
          <Form style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            <InputV2 id='name' label='Nome' required />
            <InputV2
              id='documentNumber'
              label='Documento'
              required
              mask={{
                mask: documentMask,
                pipe: (value, config) => {
                  const documentNumber = getDocumentNumber(config.rawValue)
                  const mask = getDocumentMask(documentNumber)
                  if (mask !== documentMask) {
                    setDocumentMask(mask)
                    return {
                      value: config.rawValue,
                      indexesOfPipedChars: getIndexesOfPipedChars(documentNumber, mask),
                    }
                  }
                  return value
                },
              }}
            />
            <InputV2 id='email' label='Email' required />
            <Button
              containerStyle={{ margin: '16px 0 0 0' }}
              type='submit'
              text='Cadastrar'
              isLoading={isLoading}
            ></Button>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal.Provider>
  )
}
