import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useToast } from '../../hooks/useToast'

const variants = {
  serradoamolar: '/sign-in/serra-do-amolar',
  default: '/sign-in'
} as const

type Variants = keyof typeof variants
const VIEW_STORAGE_KEY = '@lumi/sign-in/view'

export const Greeter = () => {
  const navigation = useNavigate()
  const { toast } = useToast()

  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    let view: Variants = searchParams.get('view') as Variants
    const reason = searchParams.get('reason')

    const path = variants[view] ?? '/sign-in'
    const lastView = localStorage.getItem(VIEW_STORAGE_KEY) as Variants

    if (window.location?.href?.includes('reason=token-expired'))
      toast({
        type: 'error',
        message: 'Sessão expirada. Faça o login novamente.'
      })

    if (pathname === path) return

    if (!view) {
      if (lastView?.length) {
        view = lastView
      } else {
        view = 'default'
      }
    }

    if (lastView !== view) {
      localStorage.setItem(VIEW_STORAGE_KEY, view)
    }

    navigation({
      pathname: path,
      search: `view=${view}${reason ? `&reason=${reason}` : ''}`,
    })
  }, [searchParams])

  return (
    <Outlet />
  )
}
