import ArrowBack from '@mui/icons-material/ArrowBack'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DefaultTemplate } from '../../../components/Pdfs/Default'
import { SendInvoice } from '../../../components/Pdfs/SendInvoice'
import { calculateDefault, formatNum } from '../../../components/Pdfs/utils/format'
import { ITests } from '../../../components/Pdfs/utils/interface'
import {
  changeManual,
  createManual,
  createManualPdf,
  getClosure,
  getOneInvoice,
  getReportValues,
  listAllUcsCodes,
} from '../../../services/requests/user-requests'
import { Button, Buttons, Container, Infos, Return, Status } from './styles'

export function ReportDefault({ setPage }: { setPage: (value: '') => void }) {
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const updateStatus = queryParams.get('update')

  const [data, setData] = useState<ITests>({
    id: 0,
    consumo_spt_te_tar: 0,
    consumo_spt_tusd_tar: 0,
    tot_trib_icms_aliq: 0,
    tot_trib_cofins_aliq: 0,
    tot_trib_pis_aliq: 0,
    tot_trib_icms_val: 0,
    tot_trib_icms_base: 0,
    tot_trib_pis_val: 0,
    tot_trib_cofins_val: 0,
    tot_trib_pis_base : 0,
    tot_trib_cofins_base: 0,
    med_ger_spt_quant: 0,
    med_ger_tot_quant: 0,
    med_ger_fp_quant: 0,
    med_ger_p_quant: 0,
    dias_faturados: 0,
    fatura_pdf: [],
    mes_referencia: '',
    uc: '',
    data_vencimento: '',
    data_emissao: '',
    data_leitura_anterior: '',
    data_leitura_atual: '',
    data_proxima_leitura: '',
    nome: '',
    endereco: '',
    cep: '',
    bairro: '',
    cidade: '',
    doc: '',
    uf: '',
    numero: '',
    grupo: '',
    subgrupo: '',
    classe: '',
    inscricaoEstadual: '',
    demandaFP: '',
    tensaoMin: '',
    medidor: '',
    fases: '',
    equipamento: '',
    cosip: 0,
    consumo_spt_qt: 0,
    consumo_spt_tar: 0,
    consumo_spt_tar_crua: 0,
    consumo_spt_val: 0,
    consumo_p_qt: 0,
    consumo_p_tar: 0,
    consumo_p_tar_crua: 0,
    consumo_p_val: 0,
    consumo_fp_qt: 0,
    consumo_fp_tar: 0,
    consumo_fp_tar_crua: 0,
    consumo_fp_val: 0,
    energia_comp_spt_qt: 0,
    energia_comp_spt_tar: 0,
    energia_comp_spt_val: 0,
    energia_comp_spt_crua: 0,
    energia_comp_fp_qt: 0,
    energia_comp_fp_tar: 0,
    energia_comp_fp_val: 0,
    energia_comp_p_qt: 0,
    energia_comp_p_tar: 0,
    energia_comp_p_val: 0,
    creditos_recebidos: 0,
    energia_comp_total_qt: 0,
    energia_comp_total_tar: 0,
    energia_comp_total_val: 0,
    consumo_total_faturado_val: 0,
    consumo_total_faturado_tar: 0,
    consumo_total_faturado_qt: 0,
    valor_total_fatura: 0,
    creditos_estoque_p: 0,
    creditos_estoque_fp: 0,
    creditos_estoque_spt: 0,
    creditos_estoque_tot: 0,
    exist: false,
    consumo_spt_te_tar_crua: 0,
    consumo_spt_tusd_tar_crua: 0,
    energia_comp_int_tar: 0,
    energia_comp_int_qt: 0,
    energia_comp_int_val: 0,
    consumo_int_tar: 0,
    consumo_int_qt: 0,
    consumo_int_val: 0,
    tot_band_val: 0,
    tot_dev_band_val: 0,
  })
  const [allUcs, setAllUcs] = useState<string[]>([])
  const [file, setFile] = useState<File>()
  const [status, setStatus] = useState('')

  useEffect(() => {
    ;(async () => {
      if (updateStatus && !isNaN(parseInt(updateStatus))) {
        const fatura = await getOneInvoice(updateStatus)
        const [yearRef, monthRef, codigo, moreCode] = fatura.chave ? fatura.chave.split('-').reverse() : []
        const [yearVenc, monthVenc, dayVenc] = fatura.data_vencimento ? fatura.data_vencimento.split('-') : []
        const [yearEmi, monthEmi, dayEmi] = fatura.data_emissao ? fatura.data_emissao.split('-') : []

        let data_proxima_leitura = ''
        if (fatura.data_proxima_leitura) {
          const [yearNext, monthNext, dayNext] = fatura.data_proxima_leitura.split('-')
          data_proxima_leitura = `${dayNext}/${monthNext}/${yearNext}`
        }

        let data_leitura_anterior = ''
        if (fatura.data_leitura_anterior) {
          const [yearNext, monthNext, dayNext] = fatura.data_leitura_anterior.split('-')
          data_leitura_anterior = `${dayNext}/${monthNext}/${yearNext}`
        }

        let data_leitura_atual = ''
        if (fatura.data_leitura_atual) {
          const [yearNext, monthNext, dayNext] = fatura.data_leitura_atual.split('-')
          data_leitura_atual = `${dayNext}/${monthNext}/${yearNext}`
        }

        setData({
          ...data,
          ...fatura,
          id: fatura.id,
          mes_referencia: `${monthRef}/${yearRef}`,
          uc: moreCode ? `${moreCode}-${codigo}` : codigo,
          data_vencimento: `${dayVenc}/${monthVenc}/${yearVenc}`,
          data_emissao: `${dayEmi}/${monthEmi}/${yearEmi}`,
          data_leitura_anterior,
          data_leitura_atual,
          data_proxima_leitura,
          nome: fatura.nome,
          endereco: fatura.endereco,
          cep: fatura.cep,
          doc: fatura.doc,
          grupo: fatura.grupo,
          subgrupo: fatura.subgrupo,
          med_ger_spt_quant: fatura.med_ger_spt_quant,
          med_ger_fp_quant: fatura.med_ger_fp_quant,
          med_ger_p_quant: fatura.med_ger_p_quant,
          classe: fatura.classe,
          fases: fatura.fases,
          cosip: fatura.cosip,
          consumo_spt_qt: fatura.consumo_spt_qt,
          consumo_fp_qt: fatura.consumo_fp_qt,
          consumo_p_qt: fatura.consumo_p_qt,
          energia_comp_fp_qt: fatura.energia_comp_fp_qt,
          energia_comp_spt_qt: fatura.energia_comp_spt_qt,
          energia_comp_p_qt: fatura.energia_comp_p_qt,
          energia_comp_fp_tar: fatura.energia_comp_fp_tar,
          energia_comp_spt_tar: fatura.energia_comp_spt_tar,
          energia_comp_p_tar: fatura.energia_comp_p_tar,
          valor_total_fatura: fatura.valor_total_fatura,
          creditos_estoque_spt: fatura.creditos_estoque_spt,
          creditos_estoque_p: fatura.creditos_estoque_p,
          creditos_estoque_fp: fatura.creditos_estoque_fp,
          tot_trib_pis_aliq: fatura.tot_trib_pis_aliq,
          tot_trib_cofins_aliq: fatura.tot_trib_cofins_aliq,
          tot_trib_icms_aliq: fatura.tot_trib_icms_aliq,
          dias_faturados: fatura.dias_faturados,
          consumo_spt_te_tar_crua: formatNum(fatura.consumo_spt_te_tar_crua),
          consumo_spt_tusd_tar_crua: formatNum(fatura.consumo_spt_tusd_tar_crua),
          consumo_spt_tar_crua:
            formatNum(fatura.consumo_spt_tar_crua) > 0
              ? formatNum(fatura.consumo_spt_tar_crua)
              : formatNum(fatura.consumo_p_tar_crua) > 0 ||
                formatNum(fatura.consumo_fp_tar_crua) > 0
              ? formatNum(fatura.consumo_p_tar_crua) + formatNum(fatura.consumo_fp_tar_crua)
              : 0,
          exist: false,
        })
      }
    })()
  }, [updateStatus])

  function reset() {
    setData({
      id: 0,
      consumo_spt_te_tar: 0,
      consumo_spt_tusd_tar: 0,
      tot_trib_icms_aliq: 0,
      tot_trib_cofins_aliq: 0,
      tot_trib_icms_val: 0,
      tot_trib_icms_base: 0,
      tot_trib_pis_val: 0,
      tot_trib_cofins_val: 0,
      tot_trib_pis_base : 0,
      tot_trib_cofins_base: 0,
      med_ger_spt_quant: 0,
      med_ger_tot_quant: 0,
      med_ger_fp_quant: 0,
      med_ger_p_quant: 0,
      tot_trib_pis_aliq: 0,
      dias_faturados: 0,
      fatura_pdf: [],
      mes_referencia: '',
      uc: '',
      data_vencimento: '',
      data_emissao: '',
      data_leitura_anterior: '',
      data_leitura_atual: '',
      data_proxima_leitura: '',
      nome: '',
      endereco: '',
      cep: '',
      bairro: '',
      cidade: '',
      doc: '',
      uf: '',
      numero: '',
      grupo: '',
      subgrupo: '',
      classe: '',
      inscricaoEstadual: '',
      demandaFP: '',
      tensaoMin: '',
      medidor: '',
      fases: '',
      equipamento: '',
      cosip: 0,
      consumo_spt_qt: 0,
      consumo_spt_tar: 0,
      consumo_spt_tar_crua: 0,
      consumo_spt_val: 0,
      consumo_p_qt: 0,
      consumo_p_tar: 0,
      consumo_p_tar_crua: 0,
      consumo_p_val: 0,
      consumo_fp_qt: 0,
      consumo_fp_tar: 0,
      consumo_fp_tar_crua: 0,
      consumo_fp_val: 0,
      energia_comp_spt_qt: 0,
      energia_comp_spt_tar: 0,
      energia_comp_spt_val: 0,
      energia_comp_spt_crua: 0,
      energia_comp_fp_qt: 0,
      energia_comp_fp_tar: 0,
      energia_comp_fp_val: 0,
      energia_comp_p_qt: 0,
      energia_comp_p_tar: 0,
      energia_comp_p_val: 0,
      creditos_recebidos: 0,
      energia_comp_total_qt: 0,
      energia_comp_total_tar: 0,
      energia_comp_total_val: 0,
      consumo_total_faturado_val: 0,
      consumo_total_faturado_tar: 0,
      consumo_total_faturado_qt: 0,
      valor_total_fatura: 0,
      creditos_estoque_p: 0,
      creditos_estoque_fp: 0,
      creditos_estoque_spt: 0,
      creditos_estoque_tot: 0,
      exist: false,
      consumo_spt_te_tar_crua: 0,
      consumo_spt_tusd_tar_crua: 0,
      consumo_int_qt: 0,
      consumo_int_tar: 0,
      consumo_int_val: 0,
      energia_comp_int_qt: 0,
      energia_comp_int_tar: 0,
      energia_comp_int_val: 0,
      tot_band_val: 0,
      tot_dev_band_val: 0,
    })
  }

  function sendData() {
    const formattedValue = calculateDefault(data)

    // console.log(file)

    if (
      data.uc &&
      data.mes_referencia &&
      data.data_emissao &&
      data.data_leitura_anterior &&
      data.data_leitura_atual &&
      data.data_vencimento &&
      data.data_proxima_leitura &&
      (updateStatus || (file !== undefined && file !== null))
    ) {
      ;(async () => {
        setStatus('Enviando')

        if (updateStatus) {
          setStatus('Enviando')

          try {
            await changeManual(formattedValue)
              .catch(() => {
                alert(
                  'Ocorreu um erro ao tentar subir a fatura, por favor contate os desenvolvedores!',
                )
              })
              .finally(() => {
                setStatus('')
              })
          } catch {
            setStatus('')

            alert('Ocorreu um erro ao enviar a fatura!')
          }
        } else {
          if (file && formattedValue) {
            const formDatas = new FormData()
            formDatas.append('files', file)

            const result = await createManualPdf(formDatas).catch(() => {
              alert('Ocorreu um erro ao tentar subir o pdf, por favor contate os desenvolvedores!')
            })

            await createManual({ ...formattedValue, drive_id: result.id })
              .then(() => reset())
              .catch(() => {
                alert(
                  'Ocorreu um erro ao tentar subir a fatura, por favor contate os desenvolvedores!',
                )
              })
              .finally(() => setStatus(''))
          } else {
            setStatus('')

            alert('Ocorreu um erro ao enviar os dados!')
          }
        }
      })()
    } else {
      alert(
        'Certifique-se de adicionar o número de instalação, data emissão, conta mês, data de leitura anterior, data de leitura atual, data de vencimento e enviar a fatura',
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      setStatus('Carregando Ucs')
      const data = await listAllUcsCodes().finally(() => setStatus(''))

      setAllUcs(data as unknown as string[])
    })()
  }, [])

  function fechamento() {
    ;(async () => {
      const formattedValue = calculateDefault(data)

      console.log(formattedValue)
      await getClosure(formattedValue)
        .catch(() => {
          alert('Ocorreu um erro ao adquirir o fechamento')
        })
        .then((res: any) => {
          if (res.error) {
            alert('Ocorreu um erro ao adquirir o fechamento')
          } else {
            let text = ''

            for (const r of Object.keys(res)) {
              text += `${r}: ${res[r]}
            `
            }
            alert(text)
          }
        })
    })()
  }

  function autoPreencher() {
    ;(async () => {
      if (allUcs.length && allUcs.includes(data.uc) && data.mes_referencia.length > 5) {
        setStatus('Auto completando')

        const [month, year] = data.mes_referencia.split('/')
        const mes_referencia = `${year}-${month}-01`

        const replacements = (await getReportValues({ codigo: data.uc, mes_referencia }).finally(
          () => setStatus(''),
        )) as any

        setData({
          ...data,
          doc: replacements.documento_numero,
          cep: data.cep || replacements.cep,
          endereco: data.endereco || replacements.endereco,
          subgrupo: data.subgrupo || replacements.subgrupo,
          nome: replacements.nome_razao_social || replacements.nome,
          consumo_spt_te_tar_crua: data.consumo_spt_te_tar_crua || replacements.te,
          consumo_spt_tusd_tar_crua: data.consumo_spt_tusd_tar_crua || replacements.tusd,
          classe: data.classe || replacements.classe,
          fases: data.fases || replacements.fases,
          tot_trib_pis_aliq: data.tot_trib_pis_aliq || replacements.pis,
          tot_trib_cofins_aliq: data.tot_trib_cofins_aliq || replacements.cofins,
          tot_trib_icms_aliq: data.tot_trib_icms_aliq || replacements.icms,
          exist: replacements.existFat,
        })

        if (replacements.existFat) alert('Essa fatura já existe')
      } else {
        alert('Adicione o conta mês e número de inscrição válido para avaliar')
      }
    })()
  }

  return (
    <Container>
      {!status ? (
        <Infos>
          <Return
            onClick={() => {
              if (updateStatus) {
                navigate('/report/edit')
              } else setPage('')
            }}
          >
            <ArrowBack fontSize='small' />
            <p>Voltar</p>
          </Return>
          <Buttons>
            {data.exist ? <Status>Esta fatura já existe</Status> : null}
            {updateStatus ? null : <Button onClick={reset}>Reiniciar</Button>}
            <Button onClick={autoPreencher}>Avaliar</Button>
            <Button onClick={fechamento}>Fechamento</Button>
            <Button onClick={sendData}>{updateStatus ? 'Atualizar' : 'Enviar'}</Button>
          </Buttons>
        </Infos>
      ) : null}
      {!updateStatus ? <SendInvoice setFile={setFile} /> : null}
      {!status ? <DefaultTemplate formData={data} setFormData={setData} /> : status}
    </Container>
  )
}
