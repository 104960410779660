import React, { ReactNode } from 'react'
import Checkmark from '../../../assets/icons/checkmark.svg'
import Modal from '../../../components/Modal'
import { CheckMarkDiv, ConfirmButton, ModalSuccessDiv, SuccessText } from './styles'

interface ModalSuccessProps {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>> | ((open: boolean) => void)
  text: string
  buttonText: string
  buttonColor: 'lightGreen' | 'darkGreen' | 'white'
  children?: ReactNode
  hideBackDrop?: boolean
}

export default function ModalSuccess({
  openModal,
  setModalOpen,
  text,
  buttonText,
  buttonColor,
  children,
  hideBackDrop,
}: ModalSuccessProps) {
  return (
    <Modal
      openModal={openModal}
      setModalOpen={setModalOpen}
      defaultButtons={false}
      hideBackDrop={hideBackDrop}
    >
      <ModalSuccessDiv>
        <CheckMarkDiv>
          <img alt='checkmark' src={`${Checkmark}`} />
        </CheckMarkDiv>
        <SuccessText>{text}</SuccessText>
        <ConfirmButton
          mode={buttonColor}
          text={buttonText}
          onClick={() => setModalOpen(!openModal)}
        />
        {children && children}
      </ModalSuccessDiv>
    </Modal>
  )
}
