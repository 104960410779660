import { useState } from 'react'
import { Label } from '../../../../../../Input/styles'
import { ButtonAdd, LabelWithButton } from '../../styles'
import Add from '../../../../../../../assets/icons/plusIcon.svg'
import Remove from '../../../../../../../assets/icons/minusIcon.svg'
import { FormikProps } from 'formik'
import InverterSelect from '../InverterSelect'
import PowerPlantSelect from '../PowerPlantSelect'

interface Props {
  formik: FormikProps<any>
}

export default function InverterFields(props: Props) {
  const { formik } = props

  const [showInversor, setShowInversor] = useState<boolean>(!!formik.values.inversor_id)

  const toggleShowInversor = async () => {
    if(!showInversor) {
      formik.setFieldValue('inversor_id', undefined)
      formik.setFieldValue('planta_ids', undefined)
    }
    setShowInversor(!showInversor)
  }

  return (
    <>
      <LabelWithButton style={{ paddingBottom: 0 }}>
        <Label mode>Deseja vincular um inversor?</Label>
        <ButtonAdd
          style={{ marginLeft: '8px', width: '32px', height: '32px' }}
          onClick={toggleShowInversor}
        >
          <img src={showInversor ? `${Remove}` : `${Add}`} alt='Add Inversor' />
        </ButtonAdd>
      </LabelWithButton>
      {showInversor && (
        <>
          <InverterSelect formik={formik} />
          <PowerPlantSelect formik={formik} />
        </>
      )}
    </>
  )
}
