export const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]
export const CNPJ_MASK = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const CPF_REGEX = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const CNPJ_REGEX = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

export function getIndexesOfPipedChars(value: string, mask: Array<string | RegExp>) {
  const indexesOfPipedChars = []
  let formattedValue = value
  for (let i = 0, j = 0; i < mask.length; i++) {
    if (typeof mask[i] === 'string') {
      formattedValue = formattedValue.substring(0, j) + mask[i] + formattedValue.substring(j)
      indexesOfPipedChars.push(j)
      j++
    }
    j++
  }
  return indexesOfPipedChars
}

export function getDocumentNumber(document: string): string {
  return document.replace(/\D+/g, '')
}

export function getDocumentMask(document: string): Array<string | RegExp> {
  return getDocumentNumber(document).length <= 11 ? CPF_MASK : CNPJ_MASK
}