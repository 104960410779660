import styled from '@emotion/styled'
import { Paper, Typography } from '@mui/material'
import { Theme } from '../../../../styles/theme'
import ModalTitle from '../../../../components/ModalV2/Title'

export const MessageCard = styled(Paper)<{ isSender: boolean }>(({ isSender }) => ({
  padding: '16px',
  margin: '0 16px 16px 16px',
  border: '1px solid #f1f1f1',
  ...(isSender
    ? { borderRight: `3px solid ${Theme.colors.green}` }
    : { borderLeft: `3px solid ${Theme.colors.green}` }),
}))

export const MessageText = styled(Typography)`
  white-space: pre-line;
  word-wrap: break-word;
`
export const MasterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const MessageCardHeader = styled.div`
  display: flex;
  margin-bottom: 10px;

  h6 {
    font-weight: bold;
    color: ${Theme.colors.darkishGreen};
  }
`

export const MessageDate = styled(Typography)`
  margin-left: auto;
`

export const Footer = styled.div`
  background-color: ${Theme.colors.white};
  width: 100%;
  display: flex;
  padding: 16px;
  bottom: 0;
`

export const ConversationWrapper = styled.div<{ textAreaHeight: number }>`
  display: flex;
  flex-direction: column;
  height: ${({ textAreaHeight }) => `calc(100% - ${textAreaHeight}px)`};
  overflow-y: auto;
`
export const Title = styled(ModalTitle)`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
`