import { PaginationQuery } from '../interfaces/pagination'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

export interface ListRentalInvoicesFromConsumer {
  params: {
    year: number
  } & PaginationQuery
  result: any
}
export interface GetInvoicePreview {
  params: {
    fileId: string
  }
  result: any
}

export class InvoiceService {
  async listRentalInvoicesFromConsumer(
    params: ListRentalInvoicesFromConsumer['params'],
  ): Promise<ListRentalInvoicesFromConsumer['result']> {
    const { year, filter, sort, itemsPerPage, page } = params
    const response = await api.get('/faturas/historico/location', {
      params: {
        year,
        itemsPerPage,
        page,
        filter: filter ? convertObjectToQueryParams(filter) : undefined,
        ...(sort
          ? {
              field: sort.field,
              order: sort.order,
            }
          : {})
      },
    })
    return response.data
  }
  async getInvoicePreview(
    params: GetInvoicePreview['params'],
  ): Promise<GetInvoicePreview['result']> {
    const { fileId } = params
    const response = await api.get('/faturas/location', {
      params: {
        fileId,
      },
    })
    return response.data
  }
}

export const invoiceService = new InvoiceService()
