import styled from 'styled-components'
import { Theme } from '../../../styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  padding: 0 40px;
  font-family: 'Arial';
  margin-bottom: 80px;

  input {
    color: black;
  }
`

export const Content = styled.div`
  width: 100%;
`

export const ContentDouble = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Double = styled.div`
  width: 49%;
`

export const Table = styled.table`
  width: 100%;
  tr {
    th {
      text-align: left;
      font-size: 16px;
      padding: 5px 0;

      &:first-child {
        padding-left: 10px;
      }
    }

    td {
      letter-spacing: 0.5px;

      padding: 5px 0;
      font-size: 16px;

      &:first-child {
        padding-left: 10px;
      }
    }
  }
  tr:nth-child(even) {
    background-color: #dddddd;
  }
`

export const HeaderDivs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  div {
    label {
      font-weight: 600;
      font-size: 16px;
      white-space: nowrap;
      margin-bottom: 5px;
      letter-spacing: 0.5px;
    }
  }
`

export const TotalValueDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    p {
      font-size: 18px;
      padding: 0 10px;
      font-weight: 600;
      text-align: center;
      color: ${Theme.colors.white};
      background-color: ${Theme.colors.htmlGrey};
    }

    input {
      height: 30px;
      font-size: 18px;
      text-align: center;
    }
  }
`

export const DatesDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border: 1px solid ${Theme.colors.htmlGrey};
  padding: 10px;
  border-radius: 8px;

  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 16px;
      padding: 0 10px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 5px;
    }

    input {
      height: 30px;
      width: 120px;
      font-size: 18px;
      text-align: center;
    }
  }
`

export const NumeroInstalacao = styled.div`
  h2 {
    background-color: ${Theme.colors.htmlGrey};
    color: ${Theme.colors.white};
    text-align: center;
    font-size: 16px;
    border-radius: 8px 8px 0 0;
  }

  input {
    font-size: 24px;
    font-weight: 600;
    border: 1px solid ${Theme.colors.htmlGrey};
    text-align: center;
    width: 100%;
    height: 60px;
    background-color: yellow;
    color: black;
  }

  p {
    background-color: ${Theme.colors.htmlGrey};
    color: ${Theme.colors.white};
    text-align: center;
    font-size: 14px;
    border-radius: 0 0 8px 8px;
  }
`

export const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    letter-spacing: 0.5px;
  }

  p {
    margin-left: 5px;
    white-space: nowrap;
  }

  input {
    font-size: 16px;
    letter-spacing: 0.5px;
    width: 100%;
  }

  gap: 5px;

  div {
    gap: 5px;
    display: flex;
  }

  .bigInput {
    width: 100%;
    input {
      font-size: 24px;
      text-align: center;
    }
  }
`

export const EnergiaInjetada = styled.p`
  font-weight: 600;
  color: ${Theme.colors.darkGreen};
  cursor: pointer;
`

export const Title = styled.p`
  margin-top: 10px;
  font-size: 18px;
  width: 100%;
  white-space: wrap;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
  background-color: ${Theme.colors.grayDark};
  padding: 2.5px 0;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
`

export const TextValue = styled.p`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: center;
`
