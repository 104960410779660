import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  background-blend-mode: soft-light, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 32px;
  font-size: 14px;

  & > img {
    width: 18px;
    margin: 8px 10px;
  }
  & > input {
    border: 0;
    color: #000;
    width: 100%;
    margin-right: 10px;
  }
  & > input::placeholder {
    color: ${({ theme }) => theme.colors.grayDark};
  }
`
