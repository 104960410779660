import styled from 'styled-components'
import { Theme } from '../../../styles/theme'

export const Return = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
`

export const Container = styled.div`
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    button {
      &:first-child {
        background-color: ${Theme.colors.htmlGrey};
      }
      margin-top: 50px;
      font-size: 18px;
      color: ${Theme.colors.white};
      background-color: ${Theme.colors.darkGreen};
      padding: 4px 8px;
      border-radius: 4px;
      text-align: center;

      &:disabled {
        cursor: default;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        div {
          display: block;
          width: 250px;

          input {
            color: black;
            border-radius: 4px;
            border: 1px solid ${Theme.colors.darkGreen};
            height: 30px;
            padding-left: 5px;
            &::placeholder {
              color: black;
            }

            &:disabled {
              border: 1px solid ${Theme.colors.lightGreen};
            }
          }
        }
      }
    }
    .success {
      p {
        font-size: 24px;
        text-align: center;
      }

      button {
        margin-top: 10px;
      }
    }
  }
`
