import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContainerBottom = styled.div`
  display: flex;
  padding: 7px 7px 7px 7px;
  align-items: center;
  flex-direction: row;
`

export const InfoContainer = styled.div`
  padding: 10px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InfoTitle = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.05em;

  color: ${({ theme }) => theme.colors.htmlGrey};
`

export const InfoValue = styled.h1`
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.05em;

  color: ${({ theme }) => theme.colors.darkishGreen};
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Divider = styled.div`
  border: 1px solid #d9d9d9;
  width: auto;
`

export const PercentageText = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.05em;
  padding: 0px 4px 0px 4px;

  color: #4b4b4b;
`

export const InRelationToText = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.05em;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors.htmlGrey};
`
