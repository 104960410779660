import emotionStyled from '@emotion/styled'
import { List } from '@mui/material'
import styled from 'styled-components'

export const FieldContainer = styled.li`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
`
export const ArrayContainer = emotionStyled(List)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
`

export const ArrayFields = styled.div`
  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  font-size: 16px;
  padding-right: 0;
  padding-bottom: 8px;
  margin-bottom: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
  }
`

export const StickyFooter = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  padding: 16px 0px;
  bottom: -32px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

export const SectionTitle = styled.h2`
  margin-bottom: 16px;
`

export const SectionGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`