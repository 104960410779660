import { FC, SVGProps } from 'react'
import {
  LinkDropDownItem,
  LinkDropDownList,
  LinkDropDownListButton,
  LinkIconContainer,
  LinkText,
  TextContainer,
} from '../styles'
import { useLocation } from 'react-router-dom'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { match } from '../utils/pathMatch'

interface ILinkButtonProps {
  onClick: () => void
  Icon: FC<SVGProps<SVGSVGElement>>
  isOpen: boolean
  text: string
  isLinkToggled: boolean
  links: Array<{
    path: string
    name: string
  }>
}

export const LinkSelect = ({
  onClick,
  Icon,
  isOpen,
  text,
  isLinkToggled,
  links,
}: ILinkButtonProps) => {
  const { pathname } = useLocation()

  const matchSomeSubPath = links.some(({ path }) => match(pathname, path))

  return (
    <div title={text}>
      <LinkDropDownListButton isactive={matchSomeSubPath} onClick={onClick}>
        <LinkIconContainer isactive={matchSomeSubPath}>
          <Icon />
        </LinkIconContainer>
        <TextContainer>
          <LinkText isOpen={isOpen}>{text}</LinkText>
          <KeyboardArrowDownOutlinedIcon
            fontSize='small'
            style={{
              alignSelf: 'center',
              justifySelf: 'center',
              transform: `rotate(${isLinkToggled ? '180deg' : '0'})`,
              transition: '500ms',
            }}
          />
        </TextContainer>
      </LinkDropDownListButton>
      <LinkDropDownList isOpen={isLinkToggled && isOpen} linksAmount={links!.length}>
        {links!.map(({ name, path }) => (
          <LinkDropDownItem key={path} to={path} isactive={match(pathname, path)} isOpen={isOpen}>
            {name}
          </LinkDropDownItem>
        ))}
      </LinkDropDownList>
    </div>
  )
}
