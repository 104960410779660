import { useTableStateContext } from "../../../../hooks/useTableStateContext"
import { FiltersContainer } from "../../styles"
import DistributorFilter from "../DistributorFilter"
import ExpiringDayFilter from "../ExpiringDayFilter"
import PaymentStatusFilter from "../PaymentStatusFilter"
import PowerPlantFilter from "../PowerPlantFilter"

interface Props {
  referenceDate: Date
}

export default function AggregatedPaymentsTableFilters(props: Props) {
  const { referenceDate } = props

  const { tableState, setFilter, setCurrentPage } = useTableStateContext()
  
  return (
    <FiltersContainer>
    <DistributorFilter
      values={(tableState.filters.distributors as any) ?? []}
      onFilter={(values) => {
        setCurrentPage(1)
        setFilter(
          'distributors',
          values
            .filter((value) => (values.length === 0 ? false : value.checked))
            .map((value) => Number(value.id)),
        )
      }}
    />
    <PowerPlantFilter
      values={(tableState.filters.powerPlants as any) ?? []}
      onFilter={(values) => {
        setCurrentPage(1)
        setFilter(
          'powerPlants',
          values
            .filter((value) => (values.length === 0 ? false : value.checked))
            .map((value) => Number(value.id)),
        )
      }}
    />
    <ExpiringDayFilter
      referenceMonth={referenceDate}
      selectedDay={tableState.filters.expiringDay as any}
      onChange={(day) => {
        setCurrentPage(1)
        setFilter('expiringDay', day)
      }}
    />
    <PaymentStatusFilter
      value={tableState.filters.paymentStatus as any}
      setValue={(status) => {
        setCurrentPage(1)
        setFilter('paymentStatus', status)
      }}
    />
  </FiltersContainer>
  )
}