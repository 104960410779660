import styled, { css } from 'styled-components'

interface IWrapperProps {
  chartHeight: string
}

export const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  height: ${({ chartHeight }) => chartHeight};
`

interface IWrapperBarsProps {
  width: string
  height: string
  paddingLeft: string
}

export const WrapperBars = styled.div<IWrapperBarsProps>`
  z-index: 2;
  position: absolute;
  bottom: 6px;
  padding: ${({ paddingLeft }) => `0 10px 0 calc(${paddingLeft} + 10px)`};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 10px;
  justify-content: space-around;
`

interface IHoverMenuProps {
  side: 'left' | 'right'
}

export const HoverMenu = styled.div<IHoverMenuProps>`
  z-index: 5;
  width: 200px;
  padding: 10px;
  opacity: 0;
  display: none;
  position: absolute;
  top: 10%;
  background-color: #f9fafd;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 10px;
  transition: 500ms;

  :first-child {
    color: black;
    font-size: 16px;
  }

  ${({ side }) => {
    if (side === 'left')
      return css`
        right: calc(100% + 10px);
      `
    if (side === 'right')
      return css`
        left: calc(100% + 10px);
      `
  }}
`

export const ContainerBar = styled.div`
  width: 100%; 
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  transition: 300ms;

  ::before {
    content: '';
    height: 0;
    transform: translateY(-100%);
    width: 1px;
    left: calc(50% - 1px);
    position: absolute;
    top: 100%;
    border-width: 0 1px 0 0;
    border-color: white;
    border-style: dashed;
    margin-bottom: 5px;
    transition: 300ms;
  }
  :hover {
    background-color: #00000049;
    ::before {
      height: 100%;
    }

    ${HoverMenu} {
      opacity: 1;
      display: block;
    }
  }
`

interface IChartBarProps {
  bgColor: string
  height: number
}

export const ChartBar = styled.div<IChartBarProps>`
  width: 70%;
  max-width: 70px;
  height: ${({ height }) => `${height}%`};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 5px;
`

interface IContainerBarLabelProps {
  paddingLeft: string
}

export const ContainerBarLabels = styled.div<IContainerBarLabelProps>`
  padding: ${({ paddingLeft }) => `0 10px 0 calc(${paddingLeft} + 10px)`};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 10px;
`

export const BarLabel = styled.span`
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey};
`

export const WrapperHorizontalLines = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto;
  column-gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`

export const LeftLabel = styled.span`
  text-align: end;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey};
  align-self: flex-end;
`

export const HorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  position: relative;
  bottom: 5px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  align-self: flex-end;
`

export const NoDataWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  bottom: -12px;
  background: #ffffff39;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoDataContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.darkishGreen + '89'};
  color: white;
`
