import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import Modal from '../'
import { invoiceToDemonstrative } from '../../../services/requests/user-requests'

import { ButtonsContainer, ContainerList, SendButton } from './styles'

import { EditPaymentModalProps } from './types'

export function DemonstrativeModal({
  openModal,
  setModalOpen,
  confirmCharge,
  receiveChargeId,
  receivePaymentData,
  sendAll,
  selectedIds,
  selectedDate,
  allData,
}: EditPaymentModalProps) {
  const [opacity, setOpacity] = useState(1)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState([])
  const [selectedCharge, setSelectedCharge] = useState<any>()

  function getChargeInfo() {
    const charge =
      receivePaymentData &&
      receivePaymentData.find((item: any) => item.fechamento_id === receiveChargeId)
    setSelectedCharge(charge)
  }

  function sendData() {
    (async () => {
      setIsLoading(true)

      const selectedData = allData
        ?.filter((val) => selectedIds!.includes(val.id))
        .map((val) => ({
          uc: val.codigo,
          mes_referencia: `${selectedDate.year}-${String(selectedDate.month).padStart(2, '0')}-01`,
        })) as any[]

      const result = await invoiceToDemonstrative({ values: selectedData })
        .then((val) => {
          setModalSuccess(true)
          return val
        })
        .finally(() => {
          setIsLoading(false)
        })

      setResult(result as any)
    })()
  }

  useEffect(() => {
    getChargeInfo()
  }, [receivePaymentData, receiveChargeId])

  return (
    <>
      <Modal
        title='Conversão de demonstrativos para faturas'
        openModal={openModal}
        setModalOpen={setModalOpen}
        defaultButtons={false}
        opacity={opacity}
        subtitle={
          confirmCharge || sendAll
            ? 'Os demonstrativos selecionados serão transformados em faturas'
            : ''
        }
      >
        <ContainerList>
          {result.map((val, i) => (
            <p key={i}>{val}</p>
          ))}
        </ContainerList>
        <ButtonsContainer>
          <SendButton onClick={sendData} disabled={isLoading && !result.length}>
            {isLoading ? <CircularProgress size={20} /> : `Enviar`}
          </SendButton>
        </ButtonsContainer>
      </Modal>
    </>
  )
}
