import { api } from './api'

export interface ReadDemonstrative {
  params: {
    file: File
    distributorName: string
    referenceDate: string
  }
  result: {
    errors: string[]
    success: string[]
  }
}

export class DemonstrativeService {
  async readDemonstrative(
    params: ReadDemonstrative['params'],
  ): Promise<ReadDemonstrative['result']> {
    const { file, distributorName, referenceDate } = params
    const formData = new FormData()
    formData.append('file', file)
    formData.set('distributor', distributorName)
    formData.set('referenceDate', referenceDate)
    const response = await api.post('/demonstrative', formData)
    return response.data
  }
}

export const demonstrativeService = new DemonstrativeService()
