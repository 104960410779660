import { useEffect, useState } from 'react'
import { Sort } from '../interfaces/pagination'
import { isEmpty } from 'lodash'
import { GridColDef } from '@mui/x-data-grid'

export interface TableState {
  currentPage: number
  itemsPerPage: number
  filters: Record<string, unknown>
  sort?: Sort
}

export interface TableStateDataContext {
  tableState: TableState
  tableColumns: GridColDef[]
  setItemsPerPage: (itemsPerPage: number) => void
  setCurrentPage: (page: number) => void
  setTableColumns: (tableColumns: GridColDef[]) => void
  nextPage: () => void
  previousPage: () => void
  setFilter: (key: string, value: unknown) => void
  setSort: (sort?: Sort) => void
}

interface Props {
  tableColumns: GridColDef[]
}

export const useTableState = (props: Props): TableStateDataContext => {
  const { tableColumns: defaultTableColumns } = props

  const [tableState, setTableState] = useState<TableState>({
    currentPage: 1,
    filters: {},
    itemsPerPage: 15,
    sort: undefined,
  })

  const [tableColumns, setTableColumns] = useState<GridColDef[]>(defaultTableColumns)

  useEffect(() => {
    setTableColumns(defaultTableColumns)
  }, [defaultTableColumns])

  const setCurrentPage = (page: number) => {
    setTableState((previousState) => ({
      ...previousState,
      currentPage: page,
    }))
  }

  const setItemsPerPage = (itemsPerPage: number) => {
    setTableState((previousState) => {
      if (previousState.itemsPerPage === itemsPerPage) {
        return previousState
      }
      return {
        ...previousState,
        itemsPerPage,
      }
    })
  }

  const nextPage = () => {
    setTableState((previousState) => ({
      ...previousState,
      currentPage: previousState.currentPage + 1,
    }))
  }

  const previousPage = () => {
    setTableState((previousState) => ({
      ...previousState,
      currentPage: Math.max(1, previousState.currentPage - 1),
    }))
  }

  const setFilter = (key: string, value: unknown) => {
    const isEmptyValue = value === undefined || (Array.isArray(value) && isEmpty(value))
    setTableState((previousState) => ({
      ...previousState,
      filters: isEmptyValue
        ? previousState.filters
        : {
            ...previousState.filters,
            [key]: value,
          },
    }))
  }

  const setSort = (sort?: Sort) => {
    setTableState((previousState) => ({
      ...previousState,
      sort,
    }))
  }

  return {
    tableColumns,
    setTableColumns,
    tableState,
    setItemsPerPage,
    setCurrentPage,
    nextPage,
    previousPage,
    setFilter,
    setSort,
  }
}
