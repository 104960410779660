import styled, { DefaultTheme } from 'styled-components'
import WidgetComponent from '../../../components/Widget'
import { darken } from 'polished'
import { Theme } from '../../../styles/theme'
import { screenSizes } from '../../../styles/media'

export const AllocationContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const Dates = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 18px;
    color: ${Theme.colors.quartzGrey};

    b {
      font-weight: 600;
      color: ${Theme.colors.darkGreen};
    }

    span {
      color: ${Theme.colors.htmlGrey};
      font-size: 14px;
    }
  }
`

export const Cotas = styled.p`
  font-size: 16px;
  margin-right: 30px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: default;
  letter-spacing: 0.001px;

  span {
    font-weight: 600;
  }
`

export const Header = styled.div`
  width: 100%;

  display: flex;
  gap: 1rem;

  @media (max-width: ${screenSizes.desktop}) {
    gap: .725rem;
  }

  @media (max-width: ${screenSizes.large}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 19px 0 16px;

  & > div {
    display: flex;
  }
`

const handleColor = (value: number, theme: DefaultTheme) => {
  if (value > 100) {
    return [theme.colors.orange]
  } else {
    return [theme.colors.darkishGreen]
  }
}

export const PowerPlantContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    padding: 4px 8px;
    font-size: 16px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.darkGreen};
  }
`

export const Widget = styled(WidgetComponent)<{ value: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background: transparent;
  background-color: ${({ value, theme }) => handleColor(value, theme)};
  color: white;

  & > label:first-child {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
  }

  & > label:last-child {
    font-size: 32px;
    font-weight: 600;
  }
`

const Div = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 11px;
  background: ${({ theme }) => theme.colors.darkishGreen};
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 130px;
  height: 32px;
  padding: 7px;
  cursor: pointer;
`

export const DownloadDiv = styled(Div)`
  margin-left: 8px;
  width: 32px;
  height: 32px;

  & > svg {
    fill: red;
  }

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`
