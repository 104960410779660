import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import React from 'react'
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps'
import {
  Header,
  MapContainer,
  MapWrapper,
  MarkerWrapper,
  MapMarkerIcon,
  MarkerTolltip,
} from './styles'
import { DefaultTheme } from 'styled-components'
import { GlobalContext } from '../../../context/global/global'
import GoogleMapReact from 'google-map-react'

type MarkerProps = {
  markerOffset?: number
  name: string
  coordinates: { lat: number; long: number }
  visible?: boolean
  geradoraFlag?: boolean
}
interface Props {
  styles?: React.CSSProperties
  markers: MarkerProps[]
}

interface IMapTooltip extends TooltipProps {
  AppTheme: DefaultTheme
}

const MapTooltip = styled(({ className, ...props }: IMapTooltip) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ AppTheme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: AppTheme.colors.darkishGreen,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: AppTheme.colors.darkishGreen,
    color: '#FFFFFF',
    fontStyle: 'normal',
    fontSize: 14,
    padding: 8,
    maxWidth: 280,
  },
}))

export const MapChart: React.FC<Props> = ({ styles, markers }) => {
  const { Theme } = React.useContext(GlobalContext)

  const mapOptions = {
    disableDefaultUI: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  }

  return (
    <MapContainer style={styles}>
      <Header>
        <h3>Localização das usinas</h3>
      </Header>
      <MapWrapper>
        {!process.env.REACT_APP_GOOGLE_API_KEY ? (
          <p>Caso esteja vendo essa mensagem contate o suporte.</p>
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY as string }}
            defaultCenter={{ lat: -17.32619, lng: -53.823401 }}
            defaultZoom={3}
            options={mapOptions}
          >
            {markers
              .filter(({ coordinates: { lat, long } }) => lat && long)
              .map(({ name, coordinates: { lat, long }, visible = true, geradoraFlag }, index) => {
                const findSameLocations = markers.filter(
                  (marker) => marker.coordinates.lat == lat && marker.coordinates.long == long,
                )
                return visible ? (
                  <MarkerWrapper lat={lat} lng={long} key={index}>
                    <MarkerTolltip>
                      {findSameLocations.length == 1 ? (
                        <span>{name}</span>
                      ) : (
                        findSameLocations.map((location, index) => (
                          <span key={index}>{location.name}</span>
                        ))
                      )}
                    </MarkerTolltip>
                    {geradoraFlag ? (
                      <MapMarkerIcon src={Theme.logos.mapIconSecondary} alt='logo' width={44} height={44} />
                    ) : Theme.logos.mapIconDefault ? (
                      <MapMarkerIcon src={Theme.logos.mapIconDefault} alt='logo' width={44} height={44} />
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 48 48'
                        width='44px'
                        height='44px'
                      >
                        <path
                          fill='#48b564'
                          d='M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z'
                        />
                        <path
                          fill='#fcc60e'
                          d='M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z'
                        />
                        <path
                          fill='#2c85eb'
                          d='M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z'
                        />
                        <path
                          fill='#ed5748'
                          d='M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z'
                        />
                        <path
                          fill='#5695f6'
                          d='M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z'
                        />
                      </svg>
                    )}
                  </MarkerWrapper>
                ) : (
                  <></>
                )
              })}
          </GoogleMapReact>
        )}

        {/* <ComposableMap
          style={{
            position: 'absolute',
            top: -93,
            left: -225,
            height: 480,
          }}
          projection='geoAzimuthalEqualArea'
          projectionConfig={{
            rotate: [58, 20, 0],
            scale: 400,
          }}
        >
          <Geographies geography={require('./br-states.json')}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill='url(#gradient1)'
                  stroke='#E5E5E5'
                />
              ))
            }
          </Geographies>
          <defs>
            <linearGradient
              id='gradient1'
              x1='157'
              y1='24.5'
              x2='157'
              y2='248.5'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor={Theme.colors.appleGreen} />
              <stop offset='0.653542' stopColor={Theme.colors.darkishGreen} />
              <stop offset='1' stopColor={Theme.colors.ShinyShamrock} />
            </linearGradient>
          </defs>
          {markers.map(({ name, coordinates: { lat, long }, visible = true }, index) =>
            visible ? (
              <MapTooltip title={name} arrow AppTheme={Theme}>
                <Marker key={index} coordinates={[long || 1, lat || 1]}>
                  <g
                    transform='translate(-7, -23)'
                    fill='none'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path
                      d='M7 0C3.1332 0 0 3.20619 0 7.17761C0 14.0445 7 23 7 23C7 23 14 14.0431 14 7.17761C14 3.20763 10.8668 0 7 0ZM7 11.149C5.99748 11.149 5.03602 10.7399 4.32714 10.0115C3.61825 9.2832 3.22 8.29537 3.22 7.26535C3.22 6.23534 3.61825 5.24751 4.32714 4.51918C5.03602 3.79085 5.99748 3.38168 7 3.38168C8.00252 3.38168 8.96398 3.79085 9.67286 4.51918C10.3818 5.24751 10.78 6.23534 10.78 7.26535C10.78 8.29537 10.3818 9.2832 9.67286 10.0115C8.96398 10.7399 8.00252 11.149 7 11.149Z'
                      fill='#f1f1f1'
                    />
                  </g>
                </Marker>
              </MapTooltip>
            ) : (
              <></>
            ),
          )}
        </ComposableMap> */}
      </MapWrapper>
    </MapContainer>
  )
}
