import { darken } from 'polished'
import styled from 'styled-components'

export const ChartBody = styled.div`
  padding: 16px 16px 0px 0px;
  width: 100%;
`

export const TitleDiv = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TitleText = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;

  color: ${({ theme }) => theme.colors.htmlGrey};
`

export const CalendarDiv = styled.div<{ showDropDown?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 11px;
  background: ${({ showDropDown }) => (showDropDown ? '#F1F1F1' : '#ffffff')};
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 130px;
  height: 32px;
  padding: 7px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const FiltersDiv = styled(CalendarDiv)`
  align-items: center;
  justify-content: space-between;
`

export const DownloadDiv = styled(CalendarDiv)`
  width: 32px;
  height: 32px;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const CalendarText = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: ${({ theme }) => theme.colors.htmlGrey};
`

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const OptionList = styled.ul<{ showDropDown?: boolean; emptyPlaceholder?: boolean }>`
  position: absolute;
  top: 34px;
  width: 130px;
  left: 0;
  max-height: 190px;
  overflow-y: auto;
  opacity: 1;
  visibility: visible;
  z-index: 10;
  background: #ffffff;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px -0.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  ${({ showDropDown }) =>
    !showDropDown &&
    `
    min-height: 0;
    opacity: 0;
    display: none;
  `}
`

export const OptionItem = styled.li<{ active?: boolean }>`
  list-style-type: none;
  cursor: pointer;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding: 10px 0px 10px 13px;
  margin: 4px 5px 0px 5px;
  color: ${({ active, theme }) => (active ? theme.colors.darkishGreen : theme.colors.htmlGrey)};
  &:first-child {
    padding: 8px 0px 10px 13px;
  }
  &:last-child {
    border-bottom: none;
    padding: 10px 0px 10px 13px;
    margin-bottom: 5px;
  }
  &:hover {
    border-radius: 8px;
    background-color: #f1f1f1;
  }
`
