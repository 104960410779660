import ModalSuccess from '../../ModalSuccess'
import { LinkNotReceiveMail } from './style'

type SuccessResetPasswordModalProps = {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function SuccessResetPasswordModal({
  openModal,
  setModalOpen,
}: SuccessResetPasswordModalProps) {
  return (
    <ModalSuccess
      openModal={openModal}
      setModalOpen={setModalOpen}
      buttonColor='darkGreen'
      text='Enviamos uma senha gerada automaticamente para o e-mail informado'
      buttonText='Ok, obrigado'
      hideBackDrop={true}
    >
      <LinkNotReceiveMail to='#'>Não recebi a senha</LinkNotReceiveMail>
    </ModalSuccess>
  )
}
