import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"
import { IRoute, userRoutes } from "../../components/Sidebar/utils"
import { useGlobalContext } from "./global"
import { usePermissions } from "../../hooks/usePermissions"

export type RoutesContextType = {
  routes: Array<IRoute>
  setRoutes: React.Dispatch<React.SetStateAction<Array<IRoute>>>
}

type RoutesProviderType = {
  children: ReactNode
}

type UserType = keyof typeof userRoutes

export const routesContext = createContext<RoutesContextType>({} as RoutesContextType)

export const RoutesProvider = ({ children }: RoutesProviderType) => {
  const { state } = useGlobalContext()
  const { checkPermission, permissions } = usePermissions()

  const typeUser = useMemo(() => {
    return state.auth?.customer?.type
  },
    [state.auth?.customer?.type]
  )

  const getRoutesPerTypeAndPermissions = (userType: UserType) => {
    const routesPerType = userRoutes[userType]
    const routesFilteredByPermission: IRoute[] = []

    for (const route of routesPerType) {
      const hasExtraLinks = (route.showLinks && !!route.links?.length) ?? false 

      if (!hasExtraLinks) {
        // INFO: True was set as a fallback, in case a specific route doesn't have permissions yet
        const hasPermission = route.permission ? checkPermission(route.permission) : true

        if (hasPermission) routesFilteredByPermission.push(route)
        continue
      }

      const filteredSubRoutes = route?.links?.filter(link => {
        const hasPermission = link.permission ? checkPermission(link.permission) : true
        return hasPermission
      })

      const updatedRoute = {
        ...route,
        links: filteredSubRoutes
      }

      if (updatedRoute.links?.length) routesFilteredByPermission.push(updatedRoute)
    }

    return routesFilteredByPermission
  }

  const [routes, setRoutes] = useState<Array<IRoute>>(
    userRoutes[typeUser as UserType],
  )

  useEffect(() => {
    if (!state.auth) return
    const customerId = state.auth?.customer?.id;

    switch (customerId) {
      case 3594: {
        setRoutes(userRoutes['NO_PAYMENTS'])
        break;
      }

      default: {
        setRoutes(getRoutesPerTypeAndPermissions(typeUser as UserType))
        break;
      }
    }
  }, [permissions, typeUser])

  return (
    <routesContext.Provider
      value={{
        routes,
        setRoutes
      }}
    >
      {children}
    </routesContext.Provider>
  )
}

export const useRoutesContext = () => {
  const context = useContext(routesContext)

  if (!context) {
    throw new Error('Provider not found')
  }

  return context
}
