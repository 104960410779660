import styled from 'styled-components'
import InputMask from 'react-input-mask'
import InputNumber from 'react-input-number'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOutlinedOffIcon from '@mui/icons-material/VisibilityOffOutlined'
import MaskedInput from 'react-text-mask'

export const Label = styled.label<{ mode?: boolean; required?: boolean }>`
  color: ${({ mode, theme }) => (mode ? theme.colors.quartzGrey : theme.colors.white)};
  margin-bottom: 8px;
  padding-left: ${({ mode }) => (mode ? `0px` : `15px`)};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  ${({ required, theme }) =>
    required &&
    `::after {
    content: '*';
    color: ${theme.colors.orange};`}
`

export const InputField = styled(InputMask)<{
  mode?: boolean
  halfSize?: boolean
  type?: string
  sizeForm?: boolean
  $isToggled?: boolean
  isSearchSelectedInput?: boolean
}>`
  @media (min-width: 1440px) {
    ${({ mode, halfSize, sizeForm, $isToggled }) =>
      sizeForm
        ? mode && halfSize
          ? `width: ${$isToggled ? '585px' : '520px'}`
          : `width: 100%;`
        : mode && halfSize
        ? `width: 205px`
        : `width: 420px;`}
  }
  background: ${({ mode }) => (mode ? `#F1F1F1` : `rgba(0, 0, 0, 0.21)`)};
  border-radius: 8px;
  border: none;
  padding-left: ${({ type }) => (type === 'search' ? '36px' : '15px')};
  padding-right: ${({ type }) => (type === 'search' ? '15px' : '36px')};
  padding-top: 8px;
  padding-bottom: ${(props) => (props.isSearchSelectedInput ? '98px' : '8px')};
  color: ${({ mode }) => (mode ? `black` : `white`)};
  height: 45px;

  ${({ mode, halfSize, sizeForm }) =>
    sizeForm
      ? mode && halfSize
        ? `width: 518px`
        : `width: 1052px;`
      : mode && halfSize
      ? `width: 205px`
      : `width: 420px;`}

  ${({ type }) =>
    type === 'search' &&
    `
  ::placeholder, ::-webkit-input-placeholder {
    font-family: var(--roboto);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    color: #adadad;
  }
  padding-left: 42px;
  font-size: 14px;
  `}

::-ms-reveal {
    display: none !important;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const TextMaskInput = styled(MaskedInput)<{
  mode?: boolean
  halfSize?: boolean
  type?: string
  sizeForm?: boolean
  $isToggled?: boolean
  isSearchSelectedInput?: boolean
}>`
  @media (min-width: 1440px) {
    ${({ mode, halfSize, sizeForm, $isToggled }) =>
      sizeForm
        ? mode && halfSize
          ? `width: ${$isToggled ? '600px' : '520px'}`
          : `width: 100%;`
        : mode && halfSize
        ? `width: 205px`
        : `width: 420px;`}
  }
  background: ${({ mode }) => (mode ? `#F1F1F1` : `rgba(0, 0, 0, 0.21)`)};
  border-radius: 8px;
  border: none;
  padding-left: ${({ type }) => (type === 'search' ? '36px' : '15px')};
  padding-right: ${({ type }) => (type === 'search' ? '15px' : '36px')};
  padding-top: 8px;
  padding-bottom: ${(props) => (props.isSearchSelectedInput ? '98px' : '8px')};
  color: ${({ mode }) => (mode ? `black` : `white`)};
  height: 45px;

  ${({ mode, halfSize, sizeForm }) =>
    sizeForm
      ? mode && halfSize
        ? `width: 518px`
        : `width: 1052px;`
      : mode && halfSize
      ? `width: 205px`
      : `width: 420px;`}

  ${({ type }) =>
    type === 'search' &&
    `
  ::placeholder, ::-webkit-input-placeholder {
    font-family: var(--roboto);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
  
    color: #adadad;
  }
  
  `}
`

export const Icon = styled(VisibilityOutlinedIcon)`
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 13px;
`

export const IconHiden = styled(VisibilityOutlinedOffIcon)`
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 13px;
`
export const InputDiv = styled.div<{ mode?: boolean; marginBottom?: string }>`
  position: relative;

  ${({ mode }) => mode && `margin-bottom: 16px;`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
`

export const SearchIcon = styled.img`
  position: absolute;
  left: 16px;
  top: 30%;
  height: 16px;
  width: 16px;
`

export const ErrorText = styled.h5`
  margin-top: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding-left: 4px;
  color: ${(props) => props.theme.colors.suportError};
`

export const FieldErrorMsg = styled.span``

export const InputNumberField = styled(InputNumber)<{
  mode?: boolean
  halfSize?: boolean
  type?: string
  sizeForm?: boolean
  $isToggled?: boolean
  isSearchSelectedInput?: boolean
}>`
  @media (min-width: 1440px) {
    ${({ mode, halfSize, sizeForm, $isToggled }) =>
      sizeForm
        ? mode && halfSize
          ? `width: ${$isToggled ? '585px' : '520px'}`
          : `width: 100%;`
        : mode && halfSize
        ? `width: 205px`
        : `width: 420px;`}
  }
  background: ${({ mode }) => (mode ? `#F1F1F1` : `rgba(0, 0, 0, 0.21)`)};
  border-radius: 8px;
  border: none;
  padding-left: ${({ type }) => (type === 'search' ? '36px' : '15px')};
  padding-right: ${({ type }) => (type === 'search' ? '15px' : '36px')};
  padding-top: 8px;
  padding-bottom: ${(props) => (props.isSearchSelectedInput ? '98px' : '8px')};
  color: ${({ mode }) => (mode ? `black` : `white`)};
  height: 45px;

  ${({ mode, halfSize, sizeForm }) =>
    sizeForm
      ? mode && halfSize
        ? `width: 518px`
        : `width: 1052px;`
      : mode && halfSize
      ? `width: 205px`
      : `width: 420px;`}

  ${({ type }) =>
    type === 'search' &&
    `
  ::placeholder, ::-webkit-input-placeholder {
    font-family: var(--roboto);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    color: #adadad;
  }
  padding-left: 42px;
  font-size: 14px;
  `}


  ::-ms-reveal {
    display: none !important;
  }
`
