import { AxiosResponse } from 'axios'
import { myUnitsPowerPlantsType } from '../../../../../pages/MyUnits/MyUnitsTables/PowerPlant/types'

export interface PowerPlantProps {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editValues?: myUnitsPowerPlantsType
  postAction?: () => void
}

export interface ModalRegisterPowerPlantData {
  nome: string
  fonte: string
  potencia_instalada: number
  distribuidora_id: number
  codigo: string
  senha_dist: string
  cep: string
  modalidade_de_compensacao: string
  cliente_id_consumidor?: number
  planta_ids: number
  grupo: string
  login: string
  inversor_id?: number
  documento_numero: string
  cliente_consumidor_nome?: string
  remuneracao_regra: string
  desconto_fixo_valor: number
  compensado_usina_valor: number
  tarifa_fixa_valor: number
  piso_disponibilidade: number
  valor_arrendamento: number
  valor_fixo: number
  isl_sengi: number
  percentual_sobre_compensado_valor: number
  percentual_sobre_bandeira_valor: number
  percentual_por_bandeira_verde: number
  percentual_sobre_compensado_devolucao?: number
  percentual_por_bandeira_amar: number
  percentual_por_bandeira_verm_p1: number
  percentual_por_bandeira_verm_p2: number
  expectativa_geracao?: number;
  classificacao?: string;
}

export interface ModalEditPowerPlantData extends ModalRegisterPowerPlantData {
  id: number
}

export const consumptionModalityArr = [
  { 'Autoconsumo Remoto': 'AUTOCONSUMO_REMOTO' },
  { 'Autoconsumo Local': 'AUTOCONSUMO_LOCAL' },
  { 'EMUC': 'EMUC' },
  { 'Associação': 'ASSOCIACAO' },
  { 'Consórcio': 'CONSORCIO' },
  { 'Cooperativa': 'COOPERATIVA' },
]

export const fontsArr = [
  { 'Solar': 'SOLAR' },
  { 'Hídrica': 'HIDRICA' },
  { 'Eólica': 'EOLICA' },
  { 'Biogás/Biomassa': 'BIOGAS_BIOMASSA' }
]

export interface GetResponseData {
  responseGet: () => Promise<AxiosResponse<any, any>>
  responseData: React.Dispatch<
    React.SetStateAction<
      {
        value: number
        label: string
      }[]
    >
  >
}
