import styled, { css } from 'styled-components'
import Autocomplete from '@mui/material/Autocomplete'

export const SelectInputFormikContainer = styled.div<{ maxWidth?: string; margin?: string }>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  position: relative;
  margin-bottom: 16px;
  ${({ margin }) => css`
    margin: ${margin};
  `}
`

export const InputSearch = styled(Autocomplete)`
  background-color: rgba(241, 241, 241, 1);
  border-radius: 8px;

  & fieldset {
    border: none !important;
  }

  & input {
    margin-left: 30px;
  }

  & .MuiAutocomplete-popupIndicator {
    display: none;
  }

  & .MuiAutocomplete-listbox {
    background-color: red !important;
    border: 1px solid #000;
  }
`

export const ErrorMessage = styled.span`
  margin: 8px 0 15px 3px;
  display: block;
  font-size: 16px;
  color: ${(props) => props.theme.colors.suportError};
  letter-spacing: -1px;
  font-weight: 500;
`
