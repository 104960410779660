import { FormikProps } from 'formik'
import { useMemo, useState } from 'react'
import { Autocomplete } from '@mui/material'
import { Search } from '@mui/icons-material'
import { fontsArr } from '../../types'
import { InputV2 } from '../../../../../../InputV2'

function getSelectAllConsumptionModality(arr: any) {
  const enumArr = arr.map((consumption: any) => {
    const keyValue = Object.keys(consumption)?.shift()
    const index = keyValue != undefined ? consumption[keyValue as keyof typeof consumption] : ''

    return {
      label: keyValue,
      value: index,
    }
  })

  return enumArr
}

interface Props {
  formik: FormikProps<any>
  required?: boolean
}

export default function ConsumptionModalitySelect(props: Props) {
  const { formik, required = true } = props

  const { errors, values, setFieldValue } = formik

  const remunerationRules = useMemo(() => getSelectAllConsumptionModality(fontsArr) as { label: string; value: string }[], [])

  const [selectedConsumptionModality, setSelectedConsumptionModality] = useState<string | null>(
    values.fonte,
  )

  return (
    <Autocomplete
      id='fonte'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          error={!!errors[params.id]}
          label='Fonte de Geração'
          required={required}
          {...params}
        />
      )}
      options={remunerationRules.map((remunerationRule) => remunerationRule.value)}
      getOptionLabel={(option) =>
        remunerationRules.find((remunerationRule) => remunerationRule.value === option)?.label ??
        option
      }
      isOptionEqualToValue={(option) => option === selectedConsumptionModality}
      value={selectedConsumptionModality}
      onChange={(_, value) => {
        setFieldValue('fonte', value)
        setSelectedConsumptionModality(value)
      }}
    />
  )
}
