import { useRef, useState, CSSProperties } from 'react'
import { SearchInput as SearchInputComponent } from '../../../../components/SearchInput'

interface Props {
  style?: CSSProperties
  defaultValue?: string
  onSearch: (value: string) => void
}

export default function SearchInput(props: Props) {
  const { style, defaultValue, onSearch } = props

  const [searchValue, setSearchValue] = useState<string>(defaultValue ?? '')

  const searchDebounceRef = useRef<NodeJS.Timeout>()

  const searchDebounce = (params: string) => {
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current)
    }
    const delayDebounce = setTimeout(() => {
      onSearch(params)
    }, 1000)
    searchDebounceRef.current = delayDebounce
  }

  return (
    <SearchInputComponent
      value={searchValue}
      setValue={(searchParams) => {
        setSearchValue(searchParams)
        searchDebounce(searchParams)
      }}
      style={{ width: 180, ...style }}
    />
  )
}
