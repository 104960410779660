import { Dispatch, SetStateAction, useState } from 'react'
import { FloatingFilterWrapper } from '../../../../components/FloatingFilterWrapper'
import { OptionItem, OptionList } from '../../styles'

interface Props {
  statusFilter?: string
  setStatusFilter: Dispatch<SetStateAction<string | undefined>>
}

export default function StatusFilter(props: Props) {
  const { statusFilter, setStatusFilter } = props

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive
      label='Status'
      modalStyles={{ padding: 4 }}
      hideSubmitButton
    >
      <OptionList showDropDown={isDropdownOpen}>
        {[
          {
            label: 'Fatura Inexistente',
            value: 'not_charged'
          },
          {
            label: 'Fatura Existente',
            value: 'charged'
          },
        ].map((item) => (
          <OptionItem
            active={statusFilter === item.value}
            key={item.value}
            onClick={() => {
              setDropdownOpen((previousStatusDropdownOpen) => !previousStatusDropdownOpen)
              const updatedValue = statusFilter === item.value ? '' : item.value
              setStatusFilter(updatedValue)
            }}
          >
            {item.label}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
