import { CircularProgress } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import Modal from '../..'
import {
  invoiceClosePut,
  recalculateBilling,
  resendInvoicesPost,
  sendInvoicesPost,
} from '../../../../services/requests/user-requests'
import { FormikInput } from '../../../FormikComponents'
import { GenericTooltip } from '../../../Tooltip'
import {
  ButtonContainer,
  ButtonsContainer,
  CancelButton,
  EmailsListContainer,
  EmailsListWrapper,
  SendButton,
} from './styles'

import InfoIcon from '@mui/icons-material/Info'
import { GlobalContext } from '../../../../context/global/global'
import { ActionTableButton } from '../../../Table/ActionTableButton'
import { EditPaymentModalProps, SubmitData } from './types'
import { useToast } from '../../../../hooks/useToast'
import PaymentStatusModal from '../PaymentStatusModal'
import { useSocket } from '../../../../hooks/useSocket'
import { utcDateToTz } from '../../../../utils/date-utils'
import { format } from 'date-fns'

function getDisabledUpdateDueDateStatus({
  canUpdatePayment,
  status,
}: {
  canUpdatePayment: boolean
  status: string
}) {
  switch (canUpdatePayment) {
    case true: {
      if (status === 'Recebido') {
        return true
      }
      return false
    }
    default:
      if (status === 'Recebido' || status === 'Cobrado') {
        return true
      }
      return false
  }
}

export function EditPaymentModal({
  openModal,
  setModalOpen,
  confirmCharge,
  receiveChargeId,
  receivePaymentData,
  postAction,
  sendAll,
  selectedIds,
  emailsList,
  setErrorMessage,
  selectedDate,
}: EditPaymentModalProps) {
  const { Theme, state } = useContext(GlobalContext)

  const { socket } = useSocket()

  const [opacity, setOpacity] = useState(1)
  const [modalSuccess, setModalSuccess] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false)
  const [isRecalculateLoading, setIsRecalculateLoading] = useState(false)
  const [invoicesBeingSent, setInvoicesBeingSent] = useState<
    {
      invoiceClosingId: number
      uc?: {
        name: string
        code: string
      }
    }[]
  >([])
  const [selectedCharge, setSelectedCharge] = useState<any>()

  const isDisabledUpdateDueDate = getDisabledUpdateDueDateStatus({
    canUpdatePayment: state.auth?.customer?.canUpdatePayment
      ? state.auth?.customer?.canUpdatePayment
      : false,
    status: selectedCharge?.status,
  })

  const { toast } = useToast()

  function handleCloseModal(value: boolean) {
    setModalSuccess(value)
    setOpacity(1)
    setModalOpen(value)
    postAction && postAction()
  }

  function formattedDate(value: string) {
    const date = value
    const day = date.split('/')[0]
    const month = date.split('/')[1]
    const year = date.split('/')[2]

    return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2)
  }

  function recalculateCharge() {
    recalculateBilling({
      fatura_id: selectedCharge.fatura_id,
    })
      .then(() => setIsRecalculateLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsRecalculateLoading(false))
  }

  async function handleSubmit(values: { remuneracao_geracao: string; vencimento: string }) {
    try {
      setIsLoading(true)
      const payload: SubmitData = {
        fechamento_id: receiveChargeId!,
        remuneracao_geracao: Number(
          values.remuneracao_geracao.replace('.', '').replace(',', '.').trimStart(),
        ),
        vencimento: values.vencimento ? formattedDate(values.vencimento) : 'undefined',
      }

      if (confirmCharge) {
        // if (!socket || !socket.connected) {
        //   throw new Error('É necessário estar conectado ao servidor de pagamentos para realizar o envio')
        // }

        if (selectedCharge.email_enviado) {
          const response = await resendInvoicesPost({
            ...payload,
            ...selectedDate,
            fechamentos_id: [payload.fechamento_id!],
          })
          if(response.data.length === 0) {
            throw new Error('Erro ao receber resposta do servidor')
          }
          setInvoicesBeingSent(response.data)
          return
        }

        const response = await sendInvoicesPost({
          ...payload,
          ...selectedDate,
          fechamentos_id: [payload.fechamento_id!],
        })
        if(response.data.length === 0) {
          throw new Error('Erro ao receber resposta do servidor')
        }
        setInvoicesBeingSent(response.data)
      } else {
        await invoiceClosePut({
          ...payload,
          remuneracao_geracao: payload.remuneracao_geracao!,
          id: receiveChargeId!,
        })

        toast({
          message: 'Data de vencimento alterada com sucesso',
          type: 'success',
        })

        setModalOpen(false)
        setOpacity(0)
        setIsLoading(false)
        setModalOpen(false)
        setModalSuccess(false)
        if(postAction) {
          postAction()
        }
      }
    } catch (err: any) {
      console.log(err.message)
      toast({
        message: (err as Error).message,
        type: 'error',
      })
      setOpacity(1)
      setIsLoading(false)
      setModalOpen(false)
    }
  }

  async function handleSendAll() {
    try {
      setIsLoading(true)
      const response = await sendInvoicesPost({
        ...selectedDate,
        fechamentos_id: selectedIds!,
      })
      if(response.data.length === 0) {
        throw new Error('Erro ao receber resposta do servidor')
      }
      setInvoicesBeingSent(response.data)
      // setModalOpen(false)
      // setIsLoading(false)
      // setOpacity(0)
      // setModalOpen(true)
      // setModalSuccess(true)
    } catch (err) {
      console.log((err as Error).message)
      toast({
        message: (err as Error).message,
        type: 'error',
      })
      setOpacity(1)
      setIsLoading(false)
      setModalOpen(false)
    }
  }

  function getChargeInfo() {
    const charge =
      receivePaymentData &&
      receivePaymentData.find((item) => item.fechamento_id === receiveChargeId)
    setSelectedCharge(charge)
  }

  useEffect(() => {
    getChargeInfo()
  }, [receivePaymentData, receiveChargeId])

  return (
    <>
      <PaymentStatusModal
        isOpen={invoicesBeingSent.length > 0}
        invoicesToListen={invoicesBeingSent}
        onClose={() => setModalOpen(false)}
      />

      {invoicesBeingSent.length === 0 && (
        <Modal
          title={`${
            confirmCharge || sendAll ? 'Confirmar dados' : 'Editar informações'
          } de cobrança`}
          openModal={openModal}
          setModalOpen={setModalOpen}
          defaultButtons={false}
          opacity={opacity}
          alert={
            selectedCharge && selectedCharge.versao_leitor === 'demonstrativo'
              ? 'Esta cobrança é feita baseada nos dados do demonstrativo!'
              : ''
          }
          subtitle={
            confirmCharge || sendAll
              ? `Confirme os dados de cobrança. A cobrança será ${
                  selectedCharge && selectedCharge.email_enviado ? 'reenviada' : 'enviada'
                } para o e-mail cadastrado no sistema.`
              : ''
          }
        >
          {!(confirmCharge || sendAll) && selectedCharge && (
            <ButtonContainer>
              {isRecalculateLoading ? (
                <CircularProgress size={20} />
              ) : (
                <ActionTableButton
                  icon
                  onClick={recalculateCharge}
                  disabled={
                    selectedCharge.status === 'Recebido' ||
                    (selectedCharge.status === 'Cobrado' &&
                      selectedCharge.email_status !== 'Erro') ||
                    (selectedCharge.status === 'Vencido' && selectedCharge.email_status !== 'Erro')
                  }
                >
                  Recalcular Cobrança
                </ActionTableButton>
              )}
            </ButtonContainer>
          )}

          {selectedCharge && !sendAll && (
            <Formik
              initialValues={{
                remuneracao_geracao:
                  selectedCharge &&
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(selectedCharge?.remuneracao_geracao)
                    .replace('R$', ''),
                vencimento:
                  selectedCharge &&
                  new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    new Date(selectedCharge?.data_vencimento),
                  ),
              }}
              onSubmit={(values) => handleSubmit(values)}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {() => (
                <Form>
                  <FormikInput
                    name='remuneracao_geracao'
                    label='Valor devido (R$)'
                    mode
                    disabled={
                      selectedCharge.status === 'Recebido' ||
                      (selectedCharge.status === 'Cobrado' &&
                        selectedCharge.email_status !== 'Erro') ||
                      (selectedCharge.status === 'Vencido' &&
                        selectedCharge.email_status !== 'Erro')
                    }
                  />
                  <FormikInput
                    name='vencimento'
                    label='Vencimento (dd/mm/aa)'
                    mode
                    disabled={isDisabledUpdateDueDate}
                    mask='99/99/9999'
                  />
                  {emailsList && emailsList.filter((email) => email && email.length > 0) && (
                    <EmailsListWrapper>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <h3>E-mails</h3>
                        <GenericTooltip
                          content='A cobrança será enviada para os e-mails listados abaixo.'
                          direction='right'
                        >
                          <InfoIcon
                            style={{
                              height: '19px',
                              width: '20px',
                              fill: Theme.colors.darkishGreen,
                            }}
                          />
                        </GenericTooltip>
                      </div>
                      <EmailsListContainer>
                        {emailsList.map((email, index) => (
                          <li key={index}>{email}</li>
                        ))}
                      </EmailsListContainer>
                    </EmailsListWrapper>
                  )}
                  <ButtonsContainer>
                    <CancelButton
                      disabled={isLoading}
                      type='button'
                      onClick={() => {
                        setModalOpen(false)
                      }}
                    >
                      Cancelar
                    </CancelButton>
                    <SendButton type='submit' disabled={isLoading}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        `${
                          confirmCharge
                            ? `${selectedCharge.email_enviado ? 'Reenviar' : 'Enviar'}`
                            : sendAll
                            ? 'Confirmar'
                            : 'Salvar'
                        }`
                      )}
                    </SendButton>
                  </ButtonsContainer>
                </Form>
              )}
            </Formik>
          )}
          {sendAll && (
            <ButtonsContainer>
              <CancelButton
                disabled={isLoading || isRecalculateLoading}
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                Cancelar
              </CancelButton>
              <SendButton onClick={handleSendAll} disabled={isLoading || isRecalculateLoading}>
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  `${confirmCharge || sendAll ? 'Enviar' : 'Salvar'}`
                )}
              </SendButton>
            </ButtonsContainer>
          )}
        </Modal>
      )}
    </>
  )
}
