import { TableV2 } from '../../../../components/TableV2'
import { ListAggregatedPayments } from '../../../../services/aggregated-payments'
import { format, parseISO } from 'date-fns'
import { useTableStateContext } from '../../../../hooks/useTableStateContext'
import { TablePagination } from '../../../../components/TableV2/components/TablePagination'


// Temporary code, remove after refactoring this page
const paymentStatusMap: Record<string, string> = {
  '1': 'Cobrado',
  '2': 'Não cobrado',
  '3': 'Recebido',
  '4': 'Vencido',
}

interface Props {
  payments: ListAggregatedPayments['result']
}

export default function AggregatedPaymentsTable(props: Props) {
  const { payments } = props

  const { tableColumns, tableState, setCurrentPage, setSort } = useTableStateContext()

  return (
    <TableV2
      alignText='center'
      columns={tableColumns}
      rows={(payments?.data ?? []).map((data) => ({
        ...data,
        dueDate: parseISO(data.dueDate),
        dueDateFormatted: format(parseISO(data.dueDate), 'dd/MM/yyyy'),
        status: paymentStatusMap[String(data.paymentStatus)]
          ? paymentStatusMap[String(data.paymentStatus)]
          : paymentStatusMap['2'],
      }))}
      messageNoRow='Não há pagamentos agrupados.'
      sort={tableState.sort}
      onSort={setSort}
    >
      <TablePagination
        onChange={(page) => setCurrentPage(page)}
        currentPageItemCount={payments.data.length}
        pageInfo={{
          totalCount: payments.pageInfo.totalCount,
          currentPage: tableState.currentPage,
          limit: tableState.itemsPerPage,
          totalPages: payments.pageInfo.totalPages,
        }}
      />
    </TableV2>
  )
}
