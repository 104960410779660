import styled from 'styled-components'

export const ChartContainer = styled.div<{ horizontal: boolean; isConsumer: boolean }>`
  padding: ${({ horizontal }) => (horizontal ? '16px' : '33px')} 17px;
  background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  background-blend-mode: soft-light, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  width: 315px;
  height: ${({ isConsumer }) => (isConsumer ? '423px' : '166px')};

  & > h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.htmlGrey};
    font-weight: 400;
    margin-bottom: ${({ horizontal }) => (horizontal ? '13px' : '0px')};
  }

  & .apexcharts-legend-series {
    line-height: 0.9 !important;
  }
`
