import { useState } from 'react'
import { FloatingFilterWrapper } from '../../../../../components/FloatingFilterWrapper'
import { OptionItem, OptionList } from './styles'

interface Props {
  selectedValue?: string
  onSelect: (value: string) => void
}

export default function ConferenceTypeSelect(props: Props) {
  const { selectedValue, onSelect } = props

  const [showDropDown, setShowDropDown] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      styles={{ marginRight: 'auto' }}
      isFilterActive
      label='Agrupar'
      modalStyles={{ padding: 4 }}
      hideSubmitButton
    >
      <OptionList showDropDown={showDropDown}>
        {[
          { value: 'distributor', label: 'Distribuidora' },
          { value: 'client', label: 'Cliente' },
        ].map((item) => (
          <OptionItem
            active={selectedValue === item.value}
            key={`option-item-${item.value}`}
            onClick={() => {
              onSelect(item.value)
              setShowDropDown(false)
            }}
          >
            {item.label}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
