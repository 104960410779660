import {
  ConferencePagination,
  ConferenceSortType,
  ConferenceType,
  PaginationParams,
} from '../interfaces/conference'
import { api } from './api'

export type ConferenceParams = PaginationParams<ConferenceSortType>

class ConferenceService {
  async getConferences(
    type: ConferenceType,
    params?: ConferenceParams,
  ): Promise<ConferencePagination> {
    const response = await api.get(`/super/conference/${type}/invoice-count`, {
      params: {
        ...(params ?? {}),
      },
    })
    return response.data
  }
  async getConfereces() {
    const response = await api.get('/super/conference/invoice-count')
    return response.data
  }
}

export const conferenceService = new ConferenceService()
