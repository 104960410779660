import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../../components/InputV2'
import { Search } from '@mui/icons-material'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useToast } from '../../../../../../hooks/useToast'
import { clientService } from '../../../../../../services/client'

interface Props {
  managerId: number
  value: number | null
  setValue: (value: number | null) => void
}

export default function ClientSelect(props: Props) {
  const { managerId, value, setValue } = props

  const formik = useFormikContext()

  const { toast } = useToast()

  const [clients, setClients] = useState<{ id: number; clientName: string }[]>([])

  const listClients = async () => {
    try {
      const managers = await clientService.listClientsFromManager({
        managerId
      })
      setClients(
        managers.map((manager) => ({
          id: manager.id,
          clientName: manager.clientName,
        })),
      )
    } catch (error) {
      console.error(error)
      toast({
        type: 'error',
        message: 'Erro ao listar clientes',
      })
    }
  }

  useEffect(() => {
    listClients()
  }, [managerId])

  return (
    <Autocomplete
      id='clientId'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          error={!!formik.errors[params.id as keyof typeof formik.errors]}
          label='Cliente'
          required
          {...params}
        />
      )}
      options={clients.map((client) => client.id)}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? clients.find((client) => client.id === option)?.clientName ?? ''
          : String(option)
      }}
      isOptionEqualToValue={(option) => option === value}
      value={value}
      onChange={(_, value) => {
        setValue(value)
      }}
    />
  )
}
