import { Alert, Snackbar } from '@mui/material'
import { Container } from './styles'
import { useEffect, useState } from 'react'
import { ITests } from '../utils/interface'
import axios from 'axios'
import { formatDate, formatDateRef, formatNum } from '../utils/format'

export function DefaultTemplate({
  formData,
  setFormData,
}: {
  formData: ITests
  setFormData: (value: ITests) => void
}) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (formData.cep && formData.cep.length === 9) {
        const cep = (await axios
          .get(`https://viacep.com.br/ws/${formData.cep.replace('-', '')}/json`)
          .then((res) => res.data)) as any

        if (cep) {
          setFormData({
            ...formData,
            cidade: cep.localidade,
            uf: cep.uf,
          })
        }
      }
    })()
  }, [formData.cep])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    const fieldsNegative = [
      'energia_comp_spt_qt',
      'energia_comp_spt_val',
      'energia_comp_int_qt',
      'energia_comp_p_qt',
      'energia_comp_p_val',
      'energia_comp_fp_qt',
      'energia_comp_fp_val',
      'energia_comp_total_qt',
      'energia_comp_total_val',
    ]
    const fieldsToTransform = [
      'valor_total_fatura',
      'cosip',
      'consumo_spt_qt',
      'consumo_spt_tar',
      'consumo_int_qt',
      'consumo_int_tar',
      'consumo_spt_tar_crua',
      'consumo_spt_val',
      'consumo_p_qt',
      'consumo_p_tar',
      'consumo_p_tar_crua',
      'consumo_p_val',
      'consumo_fp_qt',
      'consumo_fp_tar',
      'consumo_fp_tar_crua',
      'consumo_fp_val',
      'consumo_total_faturado_val',
      'consumo_spt_tar_crua',
      'consumo_total_faturado_qt',
      'energia_comp_spt_qt',
      'energia_comp_spt_tar',
      'energia_comp_spt_val',
      'energia_comp_spt_crua',
      'energia_comp_total_qt',
      'energia_comp_total_tar',
      'energia_comp_total_val',
      'energia_comp_fp_qt',
      'energia_comp_fp_tar',
      'energia_comp_fp_val',
      'energia_comp_p_qt',
      'energia_comp_int_qt',
      'energia_comp_int_tar',
      'energia_comp_p_tar',
      'energia_comp_p_val',
      'creditos_recebidos',
      'creditos_estoque_tot',
      'creditos_estoque_p',
      'creditos_estoque_fp',
      'creditos_estoque_spt',
    ]
    if (fieldsToTransform.includes(id)) {
      const valueNum = value.replace(',', '.')
      setFormData({
        ...formData,
        [id]: isNaN(parseFloat(valueNum)) ? 0 : parseFloat(valueNum),
      })
      if (fieldsNegative.includes(id)) {
        setFormData({
          ...formData,
          [id]: isNaN(parseFloat(valueNum))
            ? 0
            : parseFloat(valueNum) > 0
            ? -parseFloat(valueNum)
            : parseFloat(valueNum),
        })
      }
    } else {
      setFormData({
        ...formData,
        [id]: value,
      })
    }
  }

  function handleChangeDate(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setFormData({
      ...formData,
      [id]: formatDate(value),
    })
  }

  function handleChangeDateRef(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target

    setFormData({
      ...formData,
      [id]: formatDateRef(value),
    })
  }

  return (
    <Container>
      <div className='content'>
        {success ? (
          <div className='success'>
            <p>Fatura enviada com Sucesso</p>
            <button onClick={() => setSuccess(false)}>Enviar outra</button>
          </div>
        ) : (
          <form>
            <div>
              <div>
                <label>Mês de Referência *</label>
                <input
                  maxLength={7}
                  type='text'
                  onChange={handleChangeDateRef}
                  id='mes_referencia'
                  value={formData.mes_referencia}
                  placeholder='Ex: 08/2023'
                />
              </div>
              <div>
                <label>Número de instalação *</label>
                <input type='text' value={formData.uc} onChange={handleChange} id='uc' />
              </div>
              <div>
                <label>Data de Vencimento *</label>
                <input
                  type='text'
                  value={formData.data_vencimento}
                  onChange={handleChangeDate}
                  id='data_vencimento'
                  placeholder='Ex: 01/08/2023'
                />
              </div>
              <div>
                <label>Data de Emissão *</label>
                <input
                  type='text'
                  value={formData.data_emissao}
                  onChange={handleChangeDate}
                  id='data_emissao'
                  placeholder='Ex: 01/08/2023'
                />
              </div>
              <div>
                <label>Data de Leitura Anterior *</label>
                <input
                  type='text'
                  value={formData.data_leitura_anterior}
                  onChange={handleChangeDate}
                  id='data_leitura_anterior'
                  placeholder='Ex: 01/08/2023'
                />
              </div>
              <div>
                <label>Data de Leitura Atual *</label>
                <input
                  type='text'
                  value={formData.data_leitura_atual}
                  onChange={handleChangeDate}
                  id='data_leitura_atual'
                  placeholder='Ex: 01/08/2023'
                />
              </div>
              <div>
                <label>Data da Próxima Leitura*</label>
                <input
                  value={formData.data_proxima_leitura}
                  type='text'
                  onChange={handleChangeDate}
                  id='data_proxima_leitura'
                  placeholder='Ex: 01/08/2023'
                />
              </div>
              <div>
                <label>Endereço</label>
                <input
                  type='text'
                  value={formData.endereco}
                  onChange={handleChange}
                  id='endereco'
                />
              </div>
              <div>
                <label>CEP</label>
                <input type='text' value={formData.cep} onChange={handleChange} id='cep' />
              </div>
              <div>
                <label>Cidade</label>
                <input type='text' value={formData.cidade} onChange={handleChange} id='cidade' />
              </div>
              <div>
                <label>UF</label>
                <input type='text' value={formData.uf} onChange={handleChange} id='uf' />
              </div>

              <div>
                <label>Subgrupo</label>
                <input
                  type='text'
                  value={formData.subgrupo}
                  onChange={handleChange}
                  id='subgrupo'
                />
              </div>
              <div>
                <label>Classe</label>
                <input type='text' value={formData.classe} onChange={handleChange} id='classe' />
              </div>

              <div>
                <label>COSIP</label>
                <input value={formData.cosip} type='number' onChange={handleChange} id='cosip' />
              </div>

              <div>
                <label>Valor Total da Fatura</label>
                <input
                  onChange={handleChange}
                  value={formData.valor_total_fatura}
                  type='number'
                  id='valor_total_fatura'
                />
              </div>
              <div style={{ width: '100%' }} />
              <div>
                <label>Tarifa Crua - TE</label>
                <input
                  onChange={handleChange}
                  value={formData.consumo_spt_te_tar_crua}
                  type='number'
                  id='consumo_spt_te_tar_crua'
                />
              </div>
              <div>
                <label>Tarifa Crua - TUSD</label>
                <input
                  onChange={handleChange}
                  value={formData.consumo_spt_tusd_tar_crua}
                  type='number'
                  id='consumo_spt_tusd_tar_crua'
                />
              </div>
              <div>
                <label>Tarifa Crua - Total</label>
                <input
                  onChange={handleChange}
                  value={formData.consumo_spt_tar_crua}
                  type='number'
                  id='consumo_spt_tar_crua'
                />
              </div>
              <div style={{ width: '100%' }} />
              <div>
                <label>ICMS Aliquota</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_icms_aliq}
                  type='number'
                  id='tot_trib_icms_aliq'
                />
              </div>
              <div>
                <label>ICMS Valor</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_icms_val}
                  type='number'
                  id='tot_trib_icms_val'
                />
              </div>
              <div>
                <label>ICMS Base</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_icms_base}
                  type='number'
                  id='tot_trib_icms_base'
                />
              </div>
              <div>
                <label>PIS Aliquota</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_pis_aliq}
                  type='number'
                  id='tot_trib_pis_aliq'
                />
              </div>
              <div>
                <label>PIS Valor</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_pis_val}
                  type='number'
                  id='tot_trib_pis_val'
                />
              </div>
              <div>
                <label>PIS Base</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_pis_base}
                  type='number'
                  id='tot_trib_pis_base'
                />
              </div>
              <div>
                <label>COFINS</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_cofins_aliq}
                  type='number'
                  id='tot_trib_cofins_aliq'
                />
              </div>
              <div>
                <label>COFINS Valor</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_cofins_val}
                  type='number'
                  id='tot_trib_cofins_val'
                />
              </div>
              <div>
                <label>COFINS Base</label>
                <input
                  onChange={handleChange}
                  value={formData.tot_trib_pis_base}
                  type='number'
                  id='tot_trib_cofins_base'
                />
              </div>
              <div style={{ width: '100%' }} />
              <div>
                <label>Consumo SPT Quantidade</label>
                <input
                  value={formData.consumo_spt_qt}
                  type='number'
                  onChange={handleChange}
                  id='consumo_spt_qt'
                />
              </div>
              <div>
                <label>Consumo P Quantidade</label>
                <input
                  value={formData.consumo_p_qt}
                  type='number'
                  onChange={handleChange}
                  id='consumo_p_qt'
                />
              </div>
              <div>
                <label>Consumo FP Quantidade</label>
                <input
                  value={formData.consumo_fp_qt}
                  type='number'
                  onChange={handleChange}
                  id='consumo_fp_qt'
                />
              </div>

              <div style={{ width: '100%' }} />
              <div>
                <label>Consumo P Tarifa</label>
                <input
                  value={formData.consumo_p_tar}
                  type='number'
                  onChange={handleChange}
                  id='consumo_p_tar'
                />
              </div>
              <div>
                <label>Consumo FP Tarifa</label>
                <input
                  value={formData.consumo_fp_tar}
                  type='number'
                  onChange={handleChange}
                  id='consumo_fp_tar'
                />
              </div>
              <div style={{ width: '100%' }} />

              <div>
                <label>Energia Compensada SPT Quantidade</label>
                <input
                  value={formData.energia_comp_spt_qt}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_spt_qt'
                />
              </div>
              <div>
                <label>Energia Compensada SPT Tarifa</label>
                <input
                  value={formData.energia_comp_spt_tar}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_spt_tar'
                />
              </div>

              <div style={{ width: '100%' }} />
              <div>
                <label>Energia Compensada FP Quantidade</label>
                <input
                  value={formData.energia_comp_fp_qt}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_fp_qt'
                />
              </div>
              <div>
                <label>Energia Compensada FP Tarifa</label>
                <input
                  value={formData.energia_comp_fp_tar}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_fp_tar'
                />
              </div>

              <div style={{ width: '100%' }} />
              <div>
                <label>Energia Compensada P Quantidade</label>
                <input
                  value={formData.energia_comp_p_qt}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_p_qt'
                />
              </div>
              <div>
                <label>Energia Compensada P Tarifa</label>
                <input
                  value={formData.energia_comp_p_tar}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_p_tar'
                />
              </div>
              <div style={{ width: '100%' }} />
              <div>
                <label>Consumo intermediário Quantidade</label>
                <input
                  value={formData.consumo_int_qt}
                  type='number'
                  onChange={handleChange}
                  id='consumo_int_qt'
                />
              </div>
              <div>
                <label>Consumo intermediário Tarifa</label>
                <input
                  value={formData.consumo_int_tar}
                  type='number'
                  onChange={handleChange}
                  id='consumo_int_tar'
                />
              </div>
              <div style={{ width: '100%' }} />
              <div>
                <label>Energia Compensada INT Quantidade</label>
                <input
                  value={formData.energia_comp_int_qt}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_int_qt'
                />
              </div>
              <div>
                <label>Energia Compensada INT Tarifa</label>
                <input
                  value={formData.energia_comp_int_tar}
                  type='number'
                  onChange={handleChange}
                  id='energia_comp_int_tar'
                />
              </div>

              <div style={{ width: '100%' }} />
              <div>
                <label>Saldo Acumulado P</label>
                <input
                  value={formData.creditos_estoque_p}
                  type='number'
                  id='creditos_estoque_p'
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Saldo Acumulado FP</label>
                <input
                  value={formData.creditos_estoque_fp}
                  type='number'
                  id='creditos_estoque_fp'
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Saldo Acumulado SPT</label>
                <input
                  value={formData.creditos_estoque_spt}
                  type='number'
                  id='creditos_estoque_spt'
                  onChange={handleChange}
                />
              </div>
              <div style={{ width: '100%' }} />
              <div>
                <label>Medição geração SPT quantidade</label>
                <input
                  value={formData.med_ger_spt_quant}
                  type='number'
                  id='med_ger_spt_quant'
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Medição geração P quantidade</label>
                <input
                  value={formData.med_ger_p_quant}
                  type='number'
                  id='med_ger_p_quant'
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Medição geração FP quantidade</label>
                <input
                  value={formData.med_ger_fp_quant}
                  type='number'
                  id='med_ger_fp_quant'
                  onChange={handleChange}
                />
              </div>
              <div style={{ width: '100%' }} />
              <div>
                <label>Total Bandeira Valor</label>
                <input
                  value={formData.tot_band_val}
                  type='number'
                  id='tot_band_val'
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Total Devolução Bandeira Valor</label>
                <input
                  value={formData.tot_dev_band_val}
                  type='number'
                  id='tot_dev_band_val'
                  onChange={handleChange}
                />
              </div>
            </div>
          </form>
        )}
      </div>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </Container>
  )
}
