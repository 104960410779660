import styled from 'styled-components'

export const LabelWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 13px;
`

export const ButtonAdd = styled.div`
  background: #f1f1f1;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const TwoInputs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 420px;
  gap: 0 16px;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 49px;
  padding-left: 42px;
  padding-right: 42px;
  gap: 16px;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
