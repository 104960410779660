import { CircularProgress } from '@mui/material'
import React, { FormEvent } from 'react'
import logo from '../../../../assets/frame.svg'
import { ResetPasswordModal } from '../../../../components/Modal/Login/ResetPassword'
import { Input } from '../../../../components/UI/Input'
import {
  ButtonEnter,
  ForgotPasswordText,
  ImageContainer,
  InputContainer,
  InvalidInputText,
  LoginInfoContainer,
  LoginPageContainer,
  LoginText,
  LoginTextBox,
  LoginTextStrong,
} from './styles'
import { useLogin } from '../../../../hooks/useLogin'

const validateEmail = (value: string) => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
  value,
)

export const Login = () => {
  const { handleLogin, formState } = useLogin()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [passwordOnblur, setPasswordOnblur] = React.useState(false)
  const [emailOnblur, setEmailOnblur] = React.useState(false)
  const [resetPasswordModal, setResetPasswordModal] = React.useState(false)

  const isMailValid = validateEmail(email)

  async function handleSubmitLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!isMailValid || !password) return

    await handleLogin({
      email,
      password
    })
  }

  return (
    <LoginPageContainer>
      {resetPasswordModal && (
        <ResetPasswordModal openModal={resetPasswordModal} setModalOpen={setResetPasswordModal} />
      )}
      <ImageContainer>
        <LoginTextBox>
          <LoginTextStrong>Impulsionamos</LoginTextStrong>
          <LoginText>os seus negócios, gerenciando a</LoginText>
          <LoginTextStrong>sua energia.</LoginTextStrong>
        </LoginTextBox>
      </ImageContainer>
      <LoginInfoContainer>
        <img src={logo.toString()} width={210} height={92} />
        <form onSubmit={handleSubmitLogin}>
          <InputContainer>
            <Input
              label='E-mail'
              type='email'
              placeholder='emai.email@gmail.com'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              onBlur={() => setEmailOnblur(true)}
            />
            {!isMailValid && emailOnblur && <InvalidInputText>Email inválido*</InvalidInputText>}

            <Input
              label='Senha'
              type='password'
              placeholder='* * * * * * * * *'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              onBlur={() => {
                setPasswordOnblur(true)
              }}
            />
            {!password && passwordOnblur && <InvalidInputText>Senha obrigatória*</InvalidInputText>}
            <ForgotPasswordText onClick={() => setResetPasswordModal(true)}>
              Esqueci minha senha
            </ForgotPasswordText>
          </InputContainer>
          <ButtonEnter disabled={!isMailValid || !password}>
            {formState.isLoading ? <CircularProgress size={30} /> : 'Entrar'}
          </ButtonEnter>
        </form>
      </LoginInfoContainer>
    </LoginPageContainer>
  )
}

export default Login
