const cases = {
  CPF: (value: string) => {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  },
  CNPJ: (value: string) => {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  },
}

interface IcpfCnpjFormaterProps {
  document: string
  type: keyof typeof cases | string
}

export const documentFormater = ({ document, type }: IcpfCnpjFormaterProps) => {
  document.replace(/\D/g, '')
  const selected = cases[type as keyof typeof cases]
  return selected ? selected(document) : document
}

export function formatDocument(doc: string) {
  try {
    const numero = doc.replace(/\D/g, '')

    const isCPF = numero.length === 11
    const isCNPJ = numero.length === 14

    if (isCPF) {
      return numero.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else if (isCNPJ) {
      return numero.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      )
    } else {
      return doc
    }
  } catch (err) {
    return doc
  }
}
