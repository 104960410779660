import { UseAutocompleteProps, useAutocomplete } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { clientService } from '../../../../services/client'
import MultiSelect from '../../../../components/MultiSelect'
import { debounce } from 'lodash'

interface Manager {
  id: number
  cliente_nome: string
}

interface ExtendedAutocompleteProps extends UseAutocompleteProps<Manager, true, false, false> {
  getOptionKey: (option: Manager) => number; 
}

interface Props {
  selectedMangersIds: number[]
  onChange: (managers: Manager[]) => void
}

export default function MultiManagerSelect(props: Props) {
  const { selectedMangersIds, onChange } = props

  const [managers, setManagers] = useState<Manager[]>([])

  const fetchManagers = async () => {
    try {
      const managersResponse = await clientService.getManagers()
      setManagers(managersResponse)
    } catch (error) {
      console.log(`Error fetching managers: ${error}`)
    }
  }

  const debounceOnChange = useMemo(
    () => debounce((selectedManagers: Manager[]) => onChange(selectedManagers), 1000),
    [],
  )

  useEffect(() => {
    fetchManagers()
  }, [])

  const autocomplete = useAutocomplete({
    id: 'clients_ids',
    defaultValue: [],
    multiple: true,
    options: managers,
    onChange: (_, selectedManagers) => {
      if (selectedManagers.length !== selectedMangersIds.length) {
        debounceOnChange(selectedManagers)
      }
    },
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionLabel: (option) => option.cliente_nome,
    getOptionKey: (option) => option.id,
  } as ExtendedAutocompleteProps)

  return (
    <MultiSelect
      label='Clientes'
      getOptionLabel={(option) => option.cliente_nome}
      {...autocomplete}
    />
  )
}
