import { InputV2 } from '../../../../../../InputV2'

export default function AddressFields() {
  return (
    <>
      <InputV2
        id='endereco_cep'
        label='Cep'
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      />
      <InputV2 id='endereco_logradouro' label='Logradouro' />
      <InputV2 id='endereco_num' label='Número do Imóvel' />
      <InputV2 id='endereco_complemento' label='Complemento' />
      <InputV2 id='endereco_bairro' label='Bairro' />
      <InputV2 id='endereco_cidade' label='Cidade' />
      <InputV2
        id='endereco_codigo_cidade'
        label='Código Cidade (IBGE) 7 - digitos'
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      />
    </>
  )
}
