import { AddOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import CreateTicketModal from '../CreateTicketModal'
import { useTickets } from '../../hooks/useTickets'

export default function CreateTicketButton() {
  const [isCreateTicketModalOpen, setCreateTicketModalOpen] = useState<boolean>(false)

  const { fetchTickets } = useTickets()

  return (
    <>
      {isCreateTicketModalOpen && (
        <CreateTicketModal
          onCreate={() => fetchTickets({ page: 1 })}
          onClose={() => setCreateTicketModalOpen(false)}
        />
      )}
      <ActionTableButton onClick={() => setCreateTicketModalOpen(true)} icon={<AddOutlined />}>
        Novo ticket
      </ActionTableButton>
    </>
  )
}
