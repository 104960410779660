import { FormikProps } from 'formik'
import { inversorListGet } from '../../../../../../../services/requests/user-requests'
import { useEffect, useState } from 'react'
import { useToast } from '../../../../../../../hooks/useToast'
import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../../InputV2'
import { Search } from '@mui/icons-material'

interface Props {
  formik: FormikProps<any>
}

export default function InverterSelect(props: Props) {
  const { formik } = props

  const { errors, values, setFieldValue } = formik

  const [inversors, setInversors] = useState<{ id: number; nome: string }[]>([])
  const [selectedInverterId, setSelectedInverterId] = useState<number | null>(
    values.inversor_id,
  )

  const { toast } = useToast()

  const fetchInversors = async () => {
    try {
      const inversorsResponse = await inversorListGet()
      setInversors(inversorsResponse.data)
    } catch(error) {
      toast({
        message: 'Erro ao carregar inversores',
        type: "error"
      })
    }
  }

  useEffect(() => {
    fetchInversors()
  }, [])

  return (
    <Autocomplete
      id='inversor_id'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          label='Marca/Portal do Inversor'
          error={!!errors[params.id]}
          {...params}
        />
      )}
      options={inversors.map((consumer) => consumer.id)}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? inversors.find((consumer) => consumer.id === option)?.nome ?? ''
          : String(option)
      }}
      isOptionEqualToValue={(option) => option === selectedInverterId}
      value={selectedInverterId}
      onChange={async (_, value) => {
        setSelectedInverterId(value)
        setFieldValue('inversor_id', value, true)
      }}
    />
  )
}
