import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined'
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined'
import WidgetTicket from '../../../../components/WidgetTicket'
import { WidgetsContainer } from '../../styles'
import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../../../context/global/global'
import { TicketWidgets } from '../../../../interfaces/tickets'
import { ticketsService } from '../../../../services/tickets'

export default function TicketsWidgets() {
  const { Theme } = useContext(GlobalContext)

  const [widgets, setWidgets] = useState<TicketWidgets>({
    ticketsCompletedCount: 0,
    ticketsInProgressCount: 0,
    ticketsOpenCount: 0,
  })

  const fetchWidgets = async () => {
    try {
      const widgetsResponse = await ticketsService.getWidgets()
      setWidgets(widgetsResponse)
    } catch (error) {
      console.log(`Error fetching widgets: ${error}`)
    }
  }

  useEffect(() => {
    fetchWidgets()
  }, [])

  return (
    <WidgetsContainer>
      <WidgetTicket
        icon={
          <PendingActionsOutlinedIcon
            fontSize='large'
            style={{ color: Theme.colors.darkishGreen }}
          />
        }
        title='Abertos'
        value={widgets.ticketsOpenCount}
      />
      <WidgetTicket
        icon={
          <WorkHistoryOutlinedIcon fontSize='large' style={{ color: Theme.colors.darkishGreen }} />
        }
        title='Em progresso'
        value={widgets.ticketsInProgressCount}
      />
      <WidgetTicket
        icon={
          <BeenhereOutlinedIcon fontSize='large' style={{ color: Theme.colors.darkishGreen }} />
        }
        title='Concluídos'
        value={widgets.ticketsCompletedCount}
      />
    </WidgetsContainer>
  )
}
