import {
  Container,
  Content,
  Infos,
  InputDiv,
  ResponseDiv,
  ResponseTitle,
  Return,
  SendDiv,
} from './styles'
import { useState } from 'react'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { ReadDemonstrative, demonstrativeService } from '../../../services/demonstrative'
import { DateFilter } from '../../../components/Filter/DateFilter'
import { Stack } from '@mui/material'
import { DatePicker } from '../../../interfaces/utils'
import { useToast } from '../../../hooks/useToast'
import { Button } from '../../../components/Button'

interface Props {
  distributorName: string
  setPage: (value: string) => void
}

export function Demonstrative(props: Props) {
  const { distributorName, setPage } = props

  const [status, setStatus] = useState(true)
  const [response, setResponse] = useState<ReadDemonstrative['result']>({ success: [], errors: [] })
  const [files, setFiles] = useState<File[]>([])

  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const [referenceDate, setReferenceDate] = useState<DatePicker>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const readDemonstrative = async () => {
    if (files.length === 0) {
      return
    }

    try {
      setLoading(true)
      const result = await demonstrativeService.readDemonstrative({
        distributorName,
        file: files[0],
        referenceDate: `${referenceDate.month}-${referenceDate.year}`,
      })

      setStatus(true)
      setResponse(result)
    } catch (error) {
      console.log(error)
      toast({
        type: 'error',
        message: 'Erro ao ler demonstrativo',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Infos>
        <Return
          onClick={() => {
            setPage('')
          }}
        >
          <ArrowBack fontSize='small' />
          <p>Voltar</p>
        </Return>
      </Infos>
      {status ? (
        <Content>
          <Stack gap={4}>
            <DateFilter
              style={{ justifyContent: 'start' }}
              onChange={setReferenceDate}
              currentValue={referenceDate}
            />
            <SendDiv>
              <InputDiv>
                <input
                  type='file'
                  onChange={(event: any) => {
                    const { files } = event.target

                    if (files) {
                      setFiles(files)
                    }
                  }}
                />
              </InputDiv>
              <Button
                containerStyle={{ width: '120px' }}
                isLoading={isLoading}
                onClick={readDemonstrative}
                text='Enviar'
              ></Button>
            </SendDiv>
          </Stack>

          <ResponseDiv>
            <div>
              <ResponseTitle>Sucesso</ResponseTitle>
              {response.success.map((val: any, i: number) => (
                <p key={i}>{val}</p>
              ))}
            </div>
            <div>
              <ResponseTitle>Erro</ResponseTitle>
              {response.errors.map((val: any, i: number) => (
                <p key={i}>{val}</p>
              ))}
            </div>
          </ResponseDiv>
        </Content>
      ) : (
        <p>Atualizando</p>
      )}
    </Container>
  )
}
