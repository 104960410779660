import { InvoiceStatusColor } from '../../../interfaces/invoices'
import { Theme } from './../../../styles/theme'

enum SelectedColorEnum {
  'Completa' = 'complete',
  'Aguardando emissão' = `waitEmission`,
  'Não capturado' = 'dontObtain',
  'Não carregada' = 'dontLoading',
}

enum StatusEnum {
  'OK' = 'ok',
  'Atenção' = 'warning',
}

enum StatusPaymentsEnum {
  'Cobrado' = 'charged',
  'Não cobrado' = 'notCharged',
  'Recebido' = 'received',
  'Vencido' = 'expired',
}

export enum StatusEmailEnum {
  'Em verificação' = 'charged',
  'Confirmado' = 'received',
  'Erro' = 'expired',
  'Não enviado' = 'notCharged',
}

export const statusEmailStyle = StatusEmailEnum as unknown as string

export const colorClassName = SelectedColorEnum as unknown as string
export const statusStyle = StatusEnum as unknown as string
export const statusPaymentStyle = StatusPaymentsEnum as unknown as string
export const statusAggregatedPaymentStyle = (status: string | null) => {
  switch(status) {
    case 'PENDING':
      return {
        class: 'charged',
        text: 'Cobrado',
      }
    case 'PAID':
      return {
        class: 'received',
        text: 'Recebido',
      }
    case 'EXPIRED':
      return {
        class: 'expired',
        text: 'Vencido',
      }
    default:
      return {
        class: 'notCharged',
        text: 'Não cobrado',
      }
  }
}
interface IcolorsCaption {
  title: string
  color: keyof typeof Theme.colors
}

export const colorsCaption: Array<IcolorsCaption> = [
  {
    title: 'Faturas carregadas',
    color: InvoiceStatusColor['Completas'] as keyof typeof Theme.colors,
  },
  {
    title: 'Faturas aguardando emissão',
    color: InvoiceStatusColor['Aguardando emissão'] as keyof typeof Theme.colors,
  },
  {
    title: 'Faturas não capturadas',
    color: InvoiceStatusColor['Não capturadas'] as keyof typeof Theme.colors,
  },
  // {
  //   title: 'Faturas não obtidas',
  //   color: InvoiceStatusColor['Não obtidas'] as keyof typeof Theme.colors,
  // },
]
