import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../components/InputV2'
import { Search } from '@mui/icons-material'
import { useState } from 'react'
import { paymentMethodModalities } from '../../../../../interfaces/payment-method'

interface Props {
  onChange: (value?: number) => void
  value?: number
  error?: boolean
}

export default function PaymentModalitySelect(props: Props) {
  const { value, onChange, error } = props

  const [selectedPaymentModality, setSelectedPaymentModality] = useState<number | undefined>(value)

  return (
    <Autocomplete
      id='bb_codigo_modalidade'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2 rightIcon={<Search />} label='Modalidade' required error={error} {...params} />
      )}
      options={Object.keys(paymentMethodModalities).map((key) => Number(key))}
      isOptionEqualToValue={(option) => option === value}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? paymentMethodModalities[optionNumber as keyof typeof paymentMethodModalities] ??
              ''
          : String(option)
      }}
      value={selectedPaymentModality}
      onChange={(_, value) => {
        onChange(value ?? undefined)
        setSelectedPaymentModality(value ?? undefined)
      }}
    />
  )
}
