import styled from 'styled-components'
import { screenSizes } from '../../../styles/media'

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  /* min-height: 173px; */

  /* & > div {
    margin-right: 16px;
    margin-bottom: 5px;
  } */

  /* & > div:last-child {
    min-width: 300px;
    margin-right: 0;
  } */
`
export const Content = styled.div`
  display: flex;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const RedirectButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    cursor: not-allowed;
  }
`

export const RedirectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 15px;
`

export const MapAndBarChartsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: ${screenSizes.large}) {
    flex-direction: column-reverse;
  }
`

export const Charts = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-right: 16px;
  min-width: 500px;

  & > div {
    display: flex;
  }
`

export const AsideCharts = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-direction: column;

  & > div {
    margin-bottom: 16px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 16px;
`
