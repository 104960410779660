import React, { PropsWithChildren, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import './style.css'
import {
  ChartBody,
  ChartContainer,
  HeaderContainer,
  LegendCircle,
  LegendDiv,
  LegendSubDiv,
  LegendText,
  TitleDiv,
  TitleText,
} from './styles'
import { GlobalContext } from '../../../../context/global/global'
import Widget from '../../../../components/Widget'
import { ApexOptions } from 'apexcharts'

interface Props {
  title: string;
  data: {
    name: string
    data: number[]
  }[]
  categories: string[]
}

export default function ConferenceChart(props: PropsWithChildren<Props>) {
  const { title, categories, data } = props

  const { Theme } = React.useContext(GlobalContext)

  const handleSeriesChange = (id: number) => {
    removeFromIds.has(id) ? removeFromIds.delete(id) : removeFromIds.add(id)
    const currentStartingValue = data
    setApexSeries(
      removeFromIds.size >= 3
        ? currentStartingValue
        : currentStartingValue.filter((_, index: number) => !removeFromIds.has(index)),
    )
    setCurrentColor(
      removeFromIds.size >= 3
        ? colors
        : colors.filter((_, index: number) => !removeFromIds.has(index)),
    )
    if (removeFromIds.size >= 3) removeFromIds.clear()
  }

  const [removeFromIds] = useState(new Set())

  const colors = [Theme.colors.lightGreen, Theme.colors.orange, Theme.colors.darkishGreen]

  const [currentColor, setCurrentColor] = useState(colors)
  const [apexSeries, setApexSeries] = useState(data)

  useEffect(() => {
    if (data) {
      setApexSeries(data)
    }
  }, [data])

  const chartOptions = {
    options: {
      fill: {
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: false,
        markers: {
          radius: 100,
        },
        itemMargin: {
          horizontal: 24,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: true,
        style: {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontweight: '400',
          fontSize: '12px',
        },
        x: {
          show: false,
        },
        y: {
          formatter: function (value: number) {
            return String(value)
          },
        },
        marker: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '20',
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: currentColor,
      chart: {
        id: 'conference-chart',
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      xaxis: {
        categories,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 5,
        labels: {
          formatter: (value: number) => {
            return Number(value?.toFixed(2) || 0).toLocaleString('pt-BR')
          },
        },
        forceNiceScale: true,
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#808080',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
    } as ApexOptions,
    series: apexSeries.map((item) => ({
      ...item,
      data: item.data,
    })),
  }

  return (
    <Widget style={{ minWidth: 540 }}>
      <ChartBody id='conference-chart'>
        <HeaderContainer>
          <TitleDiv>
            <TitleText>{title}</TitleText>
          </TitleDiv>
        </HeaderContainer>
        <ChartContainer>
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type='bar'
            width='100%'
            height={400}
          />

          <LegendDiv>
            {[Theme.colors.lightGreen, Theme.colors.orange, Theme.colors.darkishGreen].map(
              (color, index) => (
                <LegendSubDiv active={!removeFromIds.has(index)} key={`legend-color-${color}`}>
                  <LegendCircle color={color} />
                  <LegendText onClick={() => handleSeriesChange(index)}>
                    {data[index].name}
                  </LegendText>
                </LegendSubDiv>
              ),
            )}
          </LegendDiv>
        </ChartContainer>
      </ChartBody>
    </Widget>
  )
}
