import { Field } from 'formik'
import { LabelRadioInput, RadioButtonField, RadioButtonInputContainer } from './style'

type RadioButtonFormik = {
  name: string
  values: {
    field: string
    value: string
  }[]
  label: string
  required?: boolean
  mode?: boolean
  style?: React.CSSProperties
}

export function RadioButtonFormik({ style, name, values, label, required, mode }: RadioButtonFormik) {
  return (
    <RadioButtonInputContainer style={style}>
      <LabelRadioInput mode={mode} required={required}>
        {label}
      </LabelRadioInput>
      <div
        style={{
          display: 'flex',
          gap: '2rem',
          flexDirection: 'row',
        }}
      >
        {values.map((value) => (
          <RadioButtonField key={value.field}>
            <Field name={name} type='radio' value={value.value} />
            <span>{value.field}</span>
          </RadioButtonField>
        ))}
      </div>
    </RadioButtonInputContainer>
  )
}