import styled from 'styled-components'

type WidgetContainerProps = {
  backgroundColor?: string
}

export const WidgetContainer = styled.div<WidgetContainerProps>`
  background: ${(props) =>
    props.backgroundColor ??
    `linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;`};
  background-blend-mode: soft-light, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  height: fit-content;
  display: flex;
  width: 100%;
`
