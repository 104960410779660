import styled from 'styled-components'
import { Theme } from '../../../styles/theme'

export const Container = styled.div``

export const Return = styled.div`
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
`

export const Status = styled.div`
  font-size: 16px;
  color: red;
`

export const Infos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 45px;
`

export const Button = styled.button`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${Theme.colors.darkGreen};
  color: ${Theme.colors.white};
  font-size: 16px;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

export const ShowData = styled.div`
  display: flex;
  gap: 30px;

  div {
    width: 49%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;

    span {
      font-weight: 600;
    }
  }
`
