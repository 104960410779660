import styled from 'styled-components'

export const SelectContainer = styled.div`
  padding-bottom: 16px;
  position: relative;
`

export const SelectInputWrapper = styled.div`
  position: relative;
`

export const SelectInput = styled.input<{ showDropDown?: boolean }>`
  height: 45px;
  cursor: pointer;
  background: #f1f1f1;
  border-radius: 8px;
  border: none;
  padding-left: 15px;
  padding-right: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
`

export const OptionList = styled.ul`
  position: absolute;
  top: 70px;
  min-height: 50px;
  width: 420px;
  opacity: 1;
  visibility: visible;
  overflow-y: auto;
  z-index: 10;
  background: #ffffff;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 0 0 8px 8px;
`

export const OptionItem = styled.li<{ active?: boolean }>`
  list-style-type: none;
  cursor: pointer;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding: 18px 0px 18px 16px;
  color: ${({ active, theme }) => (active ? theme.colors.darkishGreen : theme.colors.quartzGrey)};
  &:first-child {
    padding: 9px 0px 18px 16px;
  }
  &:last-child {
    border-bottom: none;
    padding: 18px 0px 19px 16px;
  }
  &:hover {
    background-color: #d9d9d9;
  }
`
export const TestDiv = styled.div`
  width: 100%;
  cursor: pointer;
`

export const Icons = styled.img`
  position: absolute;
  right: 18px;
  top: 40%;
  cursor: pointer;
`

export const ErrorText = styled.h5`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding-left: 4px;
  color: ${(props) => props.theme.colors.suportError};
  margin-top: 8px;
`
export const ClickableBar = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 100;
`
