import {
  PaymentsContainer,
  FiltersContainer,
  SettingsContainer,
  ConfigButtonsColumn,
  ContainerPreviewInvoice,
} from './styles'

import { ReactComponent as SendIcon } from '../../assets/send.svg'
import { FloatingCheckboxes } from '../../components/FloatingCheckboxes'
import { useEffect, useRef, useState } from 'react'
import { ActionTableButton } from '../../components/Table/ActionTableButton'
import { Table } from '../../components/Table'
import { tableColumns } from './utils'
import { GridColDef } from '@mui/x-data-grid'
import { Alert, Snackbar } from '@mui/material'
import { distributorListGet, getFees } from '../../services/requests/user-requests'
import { PaymentsTypeData } from './types'
import { CheckboxType } from '../../interfaces/checkbox'
import Loading from '../../components/Loading'
import { SendButton } from '../../components/Modal/Payments/EditPaymentModal/styles'

import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { SettingsOutlined } from '@mui/icons-material'
import { FeeModal } from '../../components/Modal/Fee'

interface invoicePreviewData {
  type: 'html' | 'pdf'
  toRender: string | { type: string; data: Buffer }
  toDownload: {
    filename: string
    content: { type: string; data: Buffer }
  }
}

interface IPreviewInvoiceProps {
  invoicePreviewData: invoicePreviewData
  downloadingInvoice: boolean
  setDownloadingInvoice: React.Dispatch<React.SetStateAction<boolean>>
}

const PreviewHtmlInvoice = ({ toRender }: Pick<invoicePreviewData, 'toRender'>) => {
  const ContainerPreviewInvoiceRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ContainerPreviewInvoiceRef.current) {
      ContainerPreviewInvoiceRef.current.innerHTML = toRender as string
    }
  }, [ContainerPreviewInvoiceRef])

  return <div style={{ maxWidth: '780px' }} ref={ContainerPreviewInvoiceRef} />
}

const PreviewInvoice = ({
  invoicePreviewData,
  downloadingInvoice,
  setDownloadingInvoice,
}: IPreviewInvoiceProps) => {
  const downloadPreview = async (pdf: Buffer, fileName: string) => {
    setDownloadingInvoice(true)
    if (invoicePreviewData) {
      // example: https://thelearning.dev/how-to-download-files-on-button-click-reactjs

      // file object
      const file = new Blob([new Uint8Array(pdf).buffer])

      // anchor link
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)

      element.download = fileName

      // simulate link click
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
    }
    setDownloadingInvoice(false)
  }

  const PreviewType = ({ type, toRender }: Omit<invoicePreviewData, 'toDownload'>) => {
    switch (type) {
      case 'html':
        return <PreviewHtmlInvoice toRender={toRender as string} />

      case 'pdf':
        return (() => {
          const myBuffer = (toRender as { data: Buffer }).data
          return (
            <Document
              file={{ data: new Uint8Array(myBuffer) }}
              onSourceError={(error) => console.log('source error', error)}
              loading={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <span>Carregando pré-visualização...</span>
                </div>
              }
              onLoadError={(error) => console.log('load error', error)}
            >
              <Page pageNumber={1} width={720} />
            </Document>
          )
        })()
    }
  }

  return (
    <ContainerPreviewInvoice>
      {invoicePreviewData?.toDownload && (
        <SendButton
          onClick={() =>
            downloadPreview(
              invoicePreviewData.toDownload.content.data,
              invoicePreviewData.toDownload.filename,
            )
          }
          disabled={downloadingInvoice}
        >
          {downloadingInvoice ? 'Carregando..' : 'Baixar PDF'}
        </SendButton>
      )}
      {invoicePreviewData?.type && invoicePreviewData?.toRender && (
        <PreviewType type={invoicePreviewData.type} toRender={invoicePreviewData.toRender} />
      )}
      {!invoicePreviewData && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span>Carregando informações...</span>
        </div>
      )}
    </ContainerPreviewInvoice>
  )
}

export function Fees() {
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [ready, setReady] = useState(false)
  const [total, setTotal] = useState(0)
  const [tableCols, setTableCols] = useState<GridColDef[]>(tableColumns)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [distributors, setDistributors] = useState<
    {
      id: number | string
      label: string
      checked?: boolean
    }[]
  >([])
  const [powerPlants, setPowerPlants] = useState<
    {
      id: number | string
      label: string
      checked?: boolean
    }[]
  >([])
  const [modelPayments, setModelPayments] = useState([
    { field: 'nome', sort: undefined },
    { field: 'tarifa_te', sort: undefined },
    { field: 'tarifa_tusd', sort: undefined },
    { field: 'posto', sort: undefined },
    { field: 'inicio', sort: undefined },
    { field: 'fim', sort: undefined },
    { field: 'subgrupo', sort: undefined },
  ])
  const [payments, setPayments] = useState<PaymentsTypeData[] | null>(null)
  const [status, setStatus] = useState('')
  const [update, setUpdate] = useState(false)
  const [day, setDay] = useState(0)
  const [dayFilterArray, setDayFilterArray] = useState<string[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [loading, setLoading] = useState(true)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [sortValues, setSortValues] = useState<{ field: string; sort: string }>()
  const [showInvoicePreview, setShowInvoicePreview] = useState<boolean>(false)
  const [invoicePreviewData, setInvoicePreviewData] = useState<any>(null)
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false)

  const [isSendAllOpen, setIsSendAllOpen] = useState(false)

  function handleChangePage(page: number) {
    setPage(page)
  }

  async function handleSendAllCharges() {
    setIsSendAllOpen(true)
  }

  async function getData() {
    try {
      const allDistributors = distributors
        .filter((val) => val.checked)
        .map((val) => val.id)
        .join(',')

      const { data } = await getFees({
        page,
        limit: pageItemsAmount,
        sort: sortValues?.field ?? 'inicio',
        order: sortValues?.sort ?? 'DESC',
        distributors: allDistributors,
      })

      setTotal(data.maxPages * pageItemsAmount)
      setPayments(data.data)
    } catch (error) {
      console.log({ error })
      setErrorMessage('Erro ao carregar os dados de demonstrativo.')
    }
  }

  useEffect(() => {
    if (payments != null) {
      const delayDebounce = setTimeout(() => {
        setPage(1)

        setReady(!ready)
      }, 2000)
      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  useEffect(() => {
    ;(async () => {
      await getData()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    const daysInMonth = new Date(dateSelected.year, dateSelected.month, 0).getDate()
    setDayFilterArray([...new Array(daysInMonth)].map((_, index) => `${index + 1}`))
    setDay(0)
  }, [dateSelected])

  useEffect(() => {
    if (!loading) {
      ;(async () => {
        setIsSearchLoading(true)
        await getData()
        setIsSearchLoading(false)
      })()
    }
  }, [
    page,
    distributors,
    powerPlants,
    update,
    status,
    ready,
    sortValues,
    dateSelected,
    pageItemsAmount,
    tableCols,
  ])

  useEffect(() => {
    ;(async () => {
      const distributorsPayload = await distributorListGet()

      console.log(distributorsPayload.data)
      setDistributors(
        distributorsPayload.data.map((val: any) => ({
          ...val,
          label: val.nome,
          checked: true,
        })),
      )
    })()
  }, [])

  function filterDistributors(values: CheckboxType[]) {
    if (!values.length) return
    setDistributors(values)
  }

  useEffect(() => {
    if (!showInvoicePreview) {
      setInvoicePreviewData(null)
    }
  }, [showInvoicePreview])

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  if (loading) {
    return <Loading />
  }

  return (
    <PaymentsContainer>
      <SettingsContainer>
        <FiltersContainer>
          {/* <FloatingCheckboxes
            isFilterActive={distributors.some(({ checked }) => checked === true)}
            label='Distribuidoras'
            options={distributors}
            selectableAll
            searchable
            submitAction={filterDistributors}
          /> */}
        </FiltersContainer>
        <ConfigButtonsColumn>
          <FloatingCheckboxes
            pagination={{
              selected: pageItemsAmount,
              setSelected: setPageItemsAmount,
              options: [15, 20, 30, 50, 100],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableCols.map((col) => ({
              label: col.headerName || '',
              checked: !col.hide,
              id: Math.random(),
            }))}
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={handleSelectTableColumns}
          />

          <ActionTableButton onClick={handleSendAllCharges} icon={<SendIcon />} />
        </ConfigButtonsColumn>
      </SettingsContainer>
      {isSearchLoading ? (
        <Loading />
      ) : (
        <Table
          pagination={{
            page,
            total,
            limit: pageItemsAmount,
            totalRegistersPerPage: payments?.length || 0,
            onChangePage: handleChangePage,
          }}
          columns={tableCols}
          rows={payments?.map((item) => ({ ...item })) || []}
          alignText='center'
          sortEvent={setSortValues}
          serverSorting
          messageNoRow='Não há demonstrativos.'
          setSelectedItems={setSelectedIds}
          headerModel={modelPayments}
          setHeaderModel={setModelPayments}
        />
      )}

      {isSendAllOpen && (
        <FeeModal
          setErrorMessage={setErrorMessage}
          openModal={isSendAllOpen}
          setModalOpen={setIsSendAllOpen}
          sendAll={isSendAllOpen}
          allData={payments!}
          selectedIds={selectedIds}
          postAction={getData}
          selectedDate={dateSelected}
        />
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </PaymentsContainer>
  )
}
