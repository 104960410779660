import axios, { AxiosError } from 'axios'
import { LocalStorageKeys } from '../interfaces/local-storage'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

api.interceptors.request.use((config) => {
  const auth = JSON.parse(localStorage.getItem(LocalStorageKeys.AUTH) ?? '{}')
  if (auth?.token) {
    config.headers = {
      Authorization: `Bearer ${auth.token}`,
      Accept: 'application/json',
    }
  }
  return config
})

api.interceptors.response.use(
  (response: any) => {
    return response
  },
  async (error: any) => {
    if (error.response.status === 401) {
      if (
        window.location.href.includes('token-expired')
      ) return

      localStorage.removeItem(LocalStorageKeys.AUTH)

      if (error?.response?.config?.url === '/login')
        return

      window.location.href = '/?reason=token-expired'
    }

    if (error instanceof AxiosError) {
      if (error.response && error.response.data && error.response.data.status && error.response.data.status === 'erro') {
        return Promise.reject(new Error(error.response.data.message));
      }

      if (error.request) {
        return Promise.reject(new Error(error.request));
      }
    }

    if (error instanceof Error) {
      return Promise.reject(error);
    }

    return Promise.reject(new Error('Erro inesperado'));
  },
)
