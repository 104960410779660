import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Button } from '../../../../../components/Button'
import { useRef, useState } from 'react'
import { ButtonsContainer, UploadFileContainer, UploadInput, UploadedFilesList } from './styles'
import { Close, InsertDriveFile } from '@mui/icons-material'
import { invoiceParserService } from '../../../../../services/invoice-parser'
import Loading from '../../../../../components/Loading'
import { useInvoiceList } from '../../hooks/useInvoiceList'

export default function InvoiceUpload() {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const [isUploading, setUploading] = useState<boolean>(false)

  const { addInvoicesToList } = useInvoiceList();

  const uploadFiles = async () => {
    if (filesToUpload.length === 0) {
      return
    }

    try {
      setUploading(true)
      const invoices = await invoiceParserService.uploadFiles(filesToUpload)
      addInvoicesToList(invoices)
      setFilesToUpload([])
    } catch (error) {
      console.log('Error uploading invoice files: ', error)
    } finally {
      setUploading(false)
    }
  }

  const addFilesToUpload = (fileList: FileList) => {
    if (fileList.length === 0) {
      return
    }
    const files: File[] = []
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i)
      if (file) {
        files.push(file)
      }
    }
    setFilesToUpload((previousFiles) => [...previousFiles, ...files])

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const removeFileFromUpload = (fileName: string) => {
    setFilesToUpload((previousFiles) => [...previousFiles.filter((file) => file.name !== fileName)])
  }

  return (
    <UploadFileContainer>
      <ButtonsContainer>
        <Button text='Upload de Faturas' onClick={() => inputRef.current?.click()} />
        <Button
          text='Processar faturas'
          onClick={uploadFiles}
          disabled={filesToUpload.length === 0 || isUploading}
        />
      </ButtonsContainer>
      <UploadInput
        ref={inputRef}
        type='file'
        multiple
        onChange={(e) => {
          if (e.target.files) {
            addFilesToUpload(e.target.files)
          }
        }}
      />

      {isUploading && <Loading />}

      {filesToUpload.length > 0 && !isUploading && (
        <UploadedFilesList>
          {filesToUpload.map((file) => (
            <ListItem
              key={`file-to-upload-${file.name}`}
              style={{
                borderRadius: '8px',
                margin: '8px 0',
                backgroundColor: '#f0f0f0',
                width: 'fit-content',
              }}
            >
              <ListItemIcon style={{ minWidth: 'unset', marginRight: '16px' }}>
                <InsertDriveFile />
              </ListItemIcon>
              <ListItemText primary={<Typography variant='body1'>{file.name}</Typography>} />
              <IconButton
                style={{ minWidth: 'unset', marginLeft: '16px' }}
                onClick={() => removeFileFromUpload(file.name)}
              >
                <Close />
              </IconButton>
            </ListItem>
          ))}
        </UploadedFilesList>
      )}
    </UploadFileContainer>
  )
}
