import { useRef } from 'react'
import { SearchInput as SearchInputComponent } from '../../../../components/SearchInput'

interface Props {
  value: string
  onChange: (value: string) => void
  onSearch: (value: string) => void
}

export default function SearchInput(props: Props) {
  const { value, onChange, onSearch } = props

  const searchDebounceRef = useRef<NodeJS.Timeout>()

  const searchDebounce = (params: string) => {
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current)
    }
    const delayDebounce = setTimeout(() => {
      onSearch(params)
    }, 1000)
    searchDebounceRef.current = delayDebounce
  }

  return (
    <SearchInputComponent
      value={value}
      setValue={(searchParams) => {
        onChange(searchParams)
        searchDebounce(searchParams)
      }}
      style={{ width: 180 }}
    />
  )
}
