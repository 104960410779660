import styled from 'styled-components'

import windTurbine from '../../../../assets/backgrounds/serra_bg.jpg'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'


export const LoginPageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background: #f9fafd;
`

export const LoginInfoContainer = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 2fr;

  & > section {
    display: grid;
    place-items:center;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.12);

    overflow: hidden;

    #logo {
      width: 80%;
      max-width: 380px;
    }
  }

  & form {
    position: relative;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;

    & footer {
      position: absolute;

      left: 0;
      right: 0;
      bottom: 0;

      width: 100%;
      height: 100px;
      background: #38bdf8;

      display: grid;
      place-items: center;
      gap: 0.25rem;

      > span {
        display: flex;
        justify-items: center;
        align-items: center;
        gap: 0.35rem;

        a {
          color: #082f49;
          font-weight: bold;
          letter-spacing: 0.05rem;
          text-decoration: none;

          margin-bottom: 3px;
        }
      }
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  width: 436px;
  gap: 12px;
  flex-direction: column;
`

export const ForgotPasswordText = styled.h4`
  color: white;
  opacity: 70%;
  font-size: 18px;
  letter-spacing: 0.05rem;
  font-weight: 400;
  align-self: flex-end;
  text-decoration: underline;
  margin-top: 2px;
  cursor: pointer;
  margin-bottom: 97px;
`

export const ButtonEnter = styled.button`
  width: 436px;
  height: 60px;
  left: 827px;
  top: 727px;
  background: #38bdf8;
  color: #082f49;
  border-radius: 8px;
  border: 0;
  font-weight: 900;
  letter-spacing: 0.05rem;
  font-size: 22px;
  line-height: 21px;
  margin-top: -6px;

  &:disabled {
    color: black;
    cursor: not-allowed;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${windTurbine});
  background-size: cover;
  display: flex;
`

export const LoginTextBox = styled.div`
  width: 338px;
  height: 189px;
  display: flex;
  align-items: center;
  text-align: right;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  right: 56px;
  bottom: 56px;
`

export const LoginText = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 47px;
  width: 100%;
  color: #ffffff;
`

export const LoginTextStrong = styled(LoginText)`
  font-weight: bold;
`

export const InvalidInputText = styled.p`
  color: ${(props) => props.theme.colors.suportError};
  font-size: 0.7rem;
  margin-top: -8px;
  margin-left: 2px;
`

export const Label = styled.h4`
  color: white;
  margin-bottom: 8px;
  font-size: 22px;
  letter-spacing: 0.05rem;
`

export const InputField = styled.input`
  background: #f4f4f5;
  border-radius: 8px;
  border: none;
  padding-left: 16px;
  padding-right: 36px;
  padding-top: 18px;
  padding-bottom: 18px;
  color: #121214;
  height: 55px;
  width: 436px;

::-ms-reveal {
    display: none !important;
}
`

export const Icon = styled(VisibilityIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`

export const IconHiden = styled(VisibilityOffIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`
export const InputDiv = styled.div`
  position: relative;
`
