import { Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Button } from '../../../../../components/Button'
import { FormikProvider, useFormik } from 'formik'
import { OnboardingChanges } from '../../../../../interfaces/onboarding'
import { Theme } from '../../../../../styles/theme'
import { InputV2 } from '../../../../../components/InputV2'

type FormData = Record<string, unknown>

interface Props {
  title: string
  changes: OnboardingChanges
  isLoading: boolean
  isOpen?: boolean
  onSubmit: (values: FormData) => void
}

export default function EntityToUpdateForm(props: Props) {
  const { isOpen, changes, onSubmit } = props

  const initialValues = Object.entries(changes).reduce(
    (agg, [field, { after }]) => ({
      ...agg,
      [field]: after,
    }),
    {},
  )

  const formik = useFormik({
    initialValues: initialValues as FormData,
    onSubmit,
  })

  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={6}>
        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit(e)
            }}
          >
            <FormikProvider value={formik}>
              <Table size='small' sx={{ overflow: 'hidden', borderRadius: 0.5 }}>
                <TableHead sx={{ backgroundColor: Theme.colors.darkishGreen }}>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center', color: 'white' }}>Campo</TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white' }}>Valor Atual</TableCell>
                    <TableCell sx={{ textAlign: 'center', color: 'white' }}>Novo Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(changes).map(([field, update], index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell sx={{ textAlign: 'center' }}>{field}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{String(update.before)}</TableCell>
                      <TableCell>
                        <InputV2
                          containerStyle={{ margin: '0 auto', width: 'fit-content' }}
                          size='small'
                          variant='outlined'
                          id={field}
                          {...formik.getFieldProps(field)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Button
                containerStyle={{
                  width: 'fit-content',
                  margin: '8px 0 8px auto',
                }}
                text='Salvar Alterações'
              />
            </FormikProvider>
          </form>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
