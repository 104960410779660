import { InputLabel, MenuItem, Select } from '@mui/material'
import { useState } from 'react'
import { ticketsService } from '../../../../../../services/tickets'
import { Container, Loading } from './styles'
import { TicketStatus } from '../../../../../../interfaces/tickets'

const TICKET_STATUS_TO_LABEL: Partial<Record<TicketStatus, string>> = {
  IN_PROGRESS: 'Em progresso',
  OPEN: 'Aberto',
}

interface Props {
  ticketId: number
  ticketStatus: TicketStatus
}

export default function TicketStatusSelect(props: Props) {
  const { ticketId, ticketStatus: ticketStatusDefaultValue } = props

  const [ticketStatus, setTicketStatus] = useState<TicketStatus>(ticketStatusDefaultValue)
  const [isLoading, setLoading] = useState<boolean>(false)

  const changeTicketStatus = async (ticketStatus: TicketStatus) => {
    try {
      setLoading(true)
      await ticketsService.changeTicketStatus({
        ticketStatus,
        ticketId,
      })
    } catch (error) {
      console.log(`Error changing ticket status: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const ticketStatusLabel = TICKET_STATUS_TO_LABEL[ticketStatus]

  return (
    <Container fullWidth>
      {isLoading && <Loading />}
      <InputLabel id='ticket-assign-label'>{ticketStatusLabel}</InputLabel>
      <Select
        labelId='ticket-assign-label'
        id='ticket-assign'
        value={ticketStatus}
        label={ticketStatusLabel}
        onChange={(e) => {
          const ticketStatus = e.target.value as TicketStatus
          setTicketStatus(ticketStatus)
          changeTicketStatus(ticketStatus)
        }}
      >
        {Object.entries(TICKET_STATUS_TO_LABEL).map(([status, label]) => (
          <MenuItem key={`ticket-status-${status}`} value={status}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Container>
  )
}
