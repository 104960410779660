import { Close, InsertDriveFile } from '@mui/icons-material'
import { IconButton, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useRef } from 'react'

interface Props {
  uploadedFiles: File[]
  setUploadedFiles: Dispatch<SetStateAction<File[]>>
}

export default function FileUpload(props: Props) {
  const { uploadedFiles, setUploadedFiles } = props

  function removeFileFromUpload(fileName: string) {
    setUploadedFiles((previousFiles) => [...previousFiles.filter((file) => file.name !== fileName)])
  }

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        marginBottom: '20px',
        borderRadius: '10px',
      }}
    >
      <Stack direction='row'>
        <p
          style={{
            fontSize: '18px',
            marginRight: '10px',
          }}
        >
          Envie seu pdf aqui:{' '}
        </p>
        <input
          ref={inputRef}
          type='file'
          multiple
          onChange={(event: any) => {
            const { files } = event.target
            setUploadedFiles((previousFiles) => [...previousFiles, ...files])
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.value = ''
              }
            }, 100)
          }}
        />
      </Stack>
      {uploadedFiles.length > 0 && (
        <Stack marginTop={2} gap={1} flexWrap='wrap' direction='row'>
          {uploadedFiles.map((file) => (
            <ListItem
              key={`file-to-upload-${file.name}`}
              style={{
                borderRadius: '8px',
                backgroundColor: '#f0f0f0',
                width: 'fit-content',
              }}
            >
              <ListItemText primary={<Typography variant='body1'>{file.name}</Typography>} />
              <IconButton
                style={{ minWidth: 'unset', marginLeft: '16px' }}
                onClick={() => removeFileFromUpload(file.name)}
              >
                <Close />
              </IconButton>
            </ListItem>
          ))}
        </Stack>
      )}
    </div>
  )
}
