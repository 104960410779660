export function getAbsoluteHeight(el: HTMLElement) {
  const style = window.getComputedStyle(el)
  return ['height', 'padding-top', 'padding-bottom']
    .map((key) => parseInt(style.getPropertyValue(key), 10))
    .reduce((prev, cur) => prev + cur)
}

export function getAbsoluteWidth(el: HTMLDivElement) {
  const style = window.getComputedStyle(el)
  return ['width', 'padding-left', 'padding-right']
    .map((key) => parseInt(style.getPropertyValue(key), 10))
    .reduce((prev, cur) => prev + cur)
}