import { io, Socket } from 'socket.io-client'
import { LocalStorageKeys } from '../interfaces/local-storage'
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'

interface SocketContextData {
  socket?: Socket
}

const SocketContext = createContext({} as SocketContextData)

export function SocketProvider(props: PropsWithChildren) {
  const { children } = props

  const [socket, setSocket] = useState<Socket>()

  const createSocket = () => {
    const auth = JSON.parse(localStorage.getItem(LocalStorageKeys.AUTH) ?? '{}')
    const createdSocket = io(process.env.REACT_APP_BASE_URL as string, {
      autoConnect: false,
      extraHeaders: {
        Authorization: `Bearer ${auth?.token ?? ''}`
      },
    })

    try {
      createdSocket.connect()
      setSocket(createdSocket)
    } catch (error) {
      createdSocket.disconnect()
      console.log(error)
      console.log('Erro ao se conectar no servidor de pagamentos')
    }
    return createdSocket
  }

  useEffect(() => {
    const createdSocket = createSocket()
    return () => {
      if (createdSocket) {
        createdSocket.disconnect()
      }
    }
  }, [])

  const value = useMemo(() => ({ socket }), [socket])

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('Provider not found');
  }
  return context;
};
