import { useEffect, useState } from 'react'
import { invoiceErrorsService, ListWithPagination } from '../../../services/invoice-errors'
import ReloadIcon from '../../../assets/reload.svg'
import { useToast } from '../../../hooks/useToast'
import { PaginationQuery } from '../../../interfaces/pagination'
import Loading from '../../../components/Loading'
import { TableV2 } from '../../../components/TableV2'
import { format, parseISO } from 'date-fns'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { TicketsCount } from '../../Tickets/components/TicketsTable/styles'
import DeleteIcon from '../../../assets/delete.svg'
import PaperIcon from '../../../assets/paper.svg'
import ReaderErrorModal from './components/ReaderErrorModal'
import DeleteInvoiceErrorModal from './components/DeleteInvoiceErrorModal'
import ReanalyzeReaderErrorFilesModal from './components/ReanalyzeReaderErrorFiles'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import { TicketStatusText } from '../../Tickets/styles'
import { TicketStatus } from '../../../interfaces/tickets'
import MultiManagerFilter from '../Orchestrator/components/MultiManagerFilter'

export default function InvoiceErrorsPage() {
  const [pagination, setPagination] = useState<ListWithPagination['result']>()
  const [paginationParams, setPaginationParams] = useState<PaginationQuery>()

  const [selectedInvoiceErrorId, setSelectedInvoiceErrorId] = useState<number>()
  const [deletingInvoiceErrorId, setDeletingInvoiceErrorId] = useState<number>()
  const [reanalyzingInvoiceErrorId, setReanalyzingInvoiceErrorId] = useState<number>()

  const { toast } = useToast()

  const fetchInvoiceErrors = async () => {
    try {
      setPagination(undefined)
      const response = await invoiceErrorsService.listWithPagination({
        ...paginationParams,
      })
      setPagination(response)
    } catch (error: any) {
      toast({
        message: error?.message ?? 'Erro ao listar faturas com erro',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchInvoiceErrors()
  }, [paginationParams])

  if (!pagination) {
    return <Loading />
  }

  return (
    <>
      {selectedInvoiceErrorId && (
        <ReaderErrorModal
          readerErrorId={selectedInvoiceErrorId}
          onClose={() => setSelectedInvoiceErrorId(undefined)}
        />
      )}

      {reanalyzingInvoiceErrorId && (
        <ReanalyzeReaderErrorFilesModal
          readerErrorId={reanalyzingInvoiceErrorId}
          onSuccess={fetchInvoiceErrors}
          onClose={() => setReanalyzingInvoiceErrorId(undefined)}
        />
      )}

      {deletingInvoiceErrorId && (
        <DeleteInvoiceErrorModal
          readerErrorId={deletingInvoiceErrorId}
          onSuccess={fetchInvoiceErrors}
          onClose={() => setDeletingInvoiceErrorId(undefined)}
        />
      )}

      <Stack direction='row' marginLeft='auto' gap={2} marginBottom={4} marginTop={4}>
        <FloatingCheckboxes
          isFilterActive
          label='Status do Erro'
          options={[
            { id: 'pending', label: 'Pendente', checked: true },
            { id: 'finished', label: 'Concluido' },
          ].map((option) => ({
            checked: paginationParams?.filter?.status
              ? (paginationParams.filter.status as string[]).includes(option.id)
              : false,
            ...option,
          }))}
          selectableAll
          searchable
          submitAction={(values) => {
            setPaginationParams((previous) => ({
              ...previous,
              page: 1,
              filter: {
                ...(previous?.filter ?? {}),
                status: values
                  .filter((value) => (values.length === 0 ? false : value.checked))
                  .map((value) => value.id),
              },
            }))
          }}
        />

        <MultiManagerFilter
          values={(paginationParams?.filter?.managers as number[]) ?? []}
          onFilter={(values) => {
            setPaginationParams((previous) => ({
              ...previous,
              page: 1,
              filter: {
                ...(previous?.filter ?? {}),
                managers: values
                  .filter((value) => (values.length === 0 ? false : value.checked))
                  .map((value) => Number(value.id)),
              },
            }))
          }}
        />

        <TicketsCount>Total de Erros: {pagination?.pageInfo?.totalCount ?? 0}</TicketsCount>
      </Stack>

      <TableV2
        alignText='center'
        columns={[
          {
            headerName: 'ID',
            field: 'id',
          },
          {
            headerName: 'Leitor',
            field: 'reader_version',
          },
          {
            headerName: 'Mensagem de Erro',
            field: 'error_message',
          },
          {
            headerName: 'Faturas Impactadas',
            field: 'invoiceCount',
          },
          {
            headerName: 'Última Ocorrência',
            field: 'last_occurance',
            renderCell: (params) => {
              try {
                const date = parseISO(params.value ?? '')
                return format(date, 'dd/MM/yyyy (HH:mm)')
              } catch (error) {
                return '-'
              }
            },
          },
          {
            headerName: 'Primeira Ocorrência',
            field: 'first_occurance',
            renderCell: (params) => {
              try {
                const date = parseISO(params.value ?? '')
                return format(date, 'dd/MM/yyyy (HH:mm)')
              } catch (error) {
                return '-'
              }
            },
          },
          {
            headerName: 'Status',
            field: 'status',
            renderCell: (params) => {
              return (
                <TicketStatusText
                  status={params.row.status === 'pending' ? TicketStatus.OPEN : TicketStatus.CLOSED}
                >
                  {params.row.status === 'pending' ? 'Pendente' : 'Concluido'}
                </TicketStatusText>
              )
            },
          },
          {
            field: 'actions',
            headerName: '',
            sortable: false,
            hideable: false,
            renderCell(params) {
              return (
                <Stack gap={2} direction='row'>
                  {params.row.status === 'pending' && (
                    <Tooltip title={<p style={{ fontSize: 15 }}>Releitura</p>} placement='bottom'>
                      <IconButton onClick={() => setReanalyzingInvoiceErrorId(params.row.id)}>
                        <img
                          src={ReloadIcon.toString()}
                          style={{ cursor: 'pointer', maxWidth: '24px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={<p style={{ fontSize: 15 }}>Visualizar faturas</p>}
                    placement='bottom'
                  >
                    <IconButton onClick={() => setSelectedInvoiceErrorId(params.row.id)}>
                      <img src={PaperIcon.toString()} />
                    </IconButton>
                  </Tooltip>
                  {params.row.status === 'pending' && (
                    <Tooltip title={<p style={{ fontSize: 15 }}>Deletar</p>} placement='bottom'>
                      <IconButton onClick={() => setDeletingInvoiceErrorId(params.row.id)}>
                        <img src={DeleteIcon.toString()} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              )
            },
          },
        ]}
        rows={pagination.data.map((data) => ({
          ...data,
          created_at: format(parseISO(data.created_at), 'dd/MM/yyyy'),
        }))}
      >
        <TablePagination
          currentPageItemCount={pagination.data.length}
          pageInfo={pagination.pageInfo}
          onChange={(page) =>
            setPaginationParams((previous) => ({
              ...previous,
              page,
            }))
          }
        />
      </TableV2>
    </>
  )
}
