import { Pagination, PaginationQuery } from '../interfaces/pagination'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

export interface ListUcsScraped {
  params: {
    referenceMonth: string
  } & PaginationQuery
  result: Pagination<{
    uc: {
      id: number
      code: string
      name: string
    }
    distributor: {
      id: number
      name: string
    }
    manager: {
      id: number
      name: string
    }
    latestAttempt?: {
      id: number
      attemptResult: string
      attemptMessage: string
      createdAt: string
    }
    attempts: number
  }>
}

export interface ListOrchestratorStatus {
  params: {
    referenceMonth: string
    filter?: Record<string, unknown>
  }
  result: {
    attemptResult: string
    totalCount: number
  }[]
}

export interface ListOrchestratorOverview {
  params: {
    referenceMonth: string
    filter?: Record<string, unknown>
  }
  result: {
    date: string
    attempts: {
      total: number
      attemptResult: string
    }[]
  }[]
}
export interface ListOrchestratorResults {
  result: string[]
}

export interface ListOrchestratorUcLogs {
  params: {
    ucId: number
    referenceMonth: string
  }
  result: {
    referenceMonth: string
    attemptResult: string
    attemptMessage?: string
    createdAt: string
  }[]
}

export class OrchestratorService {
  async listUcsScraped(params: ListUcsScraped['params']): Promise<ListUcsScraped['result']> {
    const { referenceMonth, filter, itemsPerPage, page, sort } = params
    const response = await api.get('/orchestrator/ucs', {
      params: {
        referenceMonth,
        itemsPerPage,
        page,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
        ...(sort
          ? {
              field: sort.field,
              order: sort.order,
            }
          : {}),
      },
    })
    return response.data
  }
  async listStatus(
    params: ListOrchestratorStatus['params'],
  ): Promise<ListOrchestratorStatus['result']> {
    const { referenceMonth, filter } = params
    const response = await api.get('/orchestrator/status', {
      params: {
        referenceMonth,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
      },
    })
    return response.data
  }
  async listOverview(
    params: ListOrchestratorOverview['params'],
  ): Promise<ListOrchestratorOverview['result']> {
    const { referenceMonth, filter } = params
    const response = await api.get('/orchestrator/overview', {
      params: {
        referenceMonth,
        ...(filter ? { filter: convertObjectToQueryParams(filter) } : {}),
      },
    })
    return response.data
  }
  async listResults(): Promise<ListOrchestratorResults['result']> {
    const response = await api.get('/orchestrator/results')
    return response.data
  }
  async listUcLogs(
    params: ListOrchestratorUcLogs['params'],
  ): Promise<ListOrchestratorUcLogs['result']> {
    const { ucId, referenceMonth } = params
    const response = await api.get(`/orchestrator/ucs/${ucId}`, {
      params: {
        referenceMonth,
      },
    })
    return response.data
  }
}

export const orchestratorService = new OrchestratorService()
