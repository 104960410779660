import { darken } from 'polished'
import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-left: 42px;
  padding-right: 42px;
  gap: 16px;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`

export const EmailsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.quartzGrey};

  h3 {
    font-family: 'Roboto';
    font-style: normal;
    line-height: 19px;
    font-weight: 400;
    font-size: 16px;
  }
`

export const EmailsListContainer = styled.ol`
  width: 100%;
  max-height: 150px;
  overflow-y: auto;

  font-size: 18px;
  list-style-type: circle;

  li {
    background: #f1f1f1;
    border-radius: 8px;

    line-height: 19px;
    font-weight: 400;

    padding: 8px 15px;
    margin-left: 20px;

    :not(:first-child) {
      margin-top: 5px;
    }
  }
`
