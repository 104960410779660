import React, { useState } from 'react'
import {
  ErrorText,
  Icon,
  IconHiden,
  InputDiv,
  InputField,
  Label,
  SearchIcon,
  TextMaskInput,
} from './styles'
import Search from '../../../assets/icons/searchIcon.svg'
import Tooltip from '../../Tooltip'
import { FieldAttributes, FieldHookConfig, useField } from 'formik'

interface Props extends FieldAttributes<any> {
  label?: string
  type?: 'email' | 'password' | 'search' | 'url' | 'number' | 'text'
  mode?: boolean
  placeholder?: string
  required?: boolean
  halfSize?: boolean
  tooltip?: boolean
  sizeForm?: boolean
  mask?: string | Array<string | RegExp>
  textMask?: (string | RegExp)[]
  onClick?: () => void
  notShowError?: boolean
  disabled?: boolean
  marginBottom?: string
  showTitle?: boolean
  readOnly?: boolean
}

export const FormikInput: React.FC<Props> = ({
  label,
  marginBottom,
  type,
  placeholder,
  mode,
  required,
  halfSize,
  tooltip,
  sizeForm,
  mask,
  onClick,
  notShowError,
  textMask,
  showTitle,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(true)
  const [field, meta] = useField(props as string | FieldHookConfig<any>)

  const handleWheel = (event: any) => {
    event.target.blur()
  }

  return (
    <div onClick={onClick}>
      {!!label && (
        <div style={{ display: 'flex', gap: 8 }}>
          <Label mode={Boolean(mode)} required={required} htmlFor={props.id || props.name}>
            {label}
          </Label>
          {tooltip && <Tooltip title='Random message!' />}
        </div>
      )}
      <InputDiv
        title={showTitle && meta.value}
        marginBottom={marginBottom}
        mode={Boolean(mode)}
        onWheel={handleWheel}
      >
        {textMask ? (
          <TextMaskInput
            onWheel={handleWheel}
            mask={textMask}
            guide={false}
            halfSize={halfSize}
            mode={Boolean(mode)}
            type={isPasswordVisible ? type : ''}
            placeholder={placeholder}
            sizeForm={sizeForm}
            {...field}
            {...props}
          />
        ) : (
          <InputField
            onWheel={handleWheel}
            min={type === 'number' ? 0 : undefined}
            max={type === 'number' ? 1000000 : undefined}
            step='0.01'
            halfSize={halfSize}
            mode={Boolean(mode)}
            type={isPasswordVisible ? type : ''}
            placeholder={placeholder}
            sizeForm={sizeForm}
            mask={mask || ''}
            maskPlaceholder={null}
            {...field}
            {...props}
          />
        )}

        {meta.touched || (meta.error && !notShowError) ? <ErrorText>{meta.error}</ErrorText> : null}
        {type === 'password' && (
          <>
            {isPasswordVisible ? (
              <Icon
                fontSize='small'
                style={{ color: '#808080' }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            ) : (
              <IconHiden
                fontSize='small'
                style={{ color: '#808080' }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            )}
          </>
        )}
        {type === 'search' && <SearchIcon src={`${Search}`} alt='Search Icon' />}
      </InputDiv>
    </div>
  )
}
