import { Close } from '@mui/icons-material'
import { IconButtonProps } from '@mui/material'
import { Button } from './styles'
import { useModal } from '../hooks/useModal'
import { MouseEvent } from 'react'

type Props = IconButtonProps & {
  alignLeft?: boolean
}

export default function ModalCloseButton(props: Props) {
  const { onClick, alignLeft = true, ...rest } = props

  const { onClose } = useModal()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClose()
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <Button alignLeft={alignLeft} onClick={handleClick} {...rest}>
      <Close />
    </Button>
  )
}
