import { useEffect, useState } from 'react'
import { powerPlantListGet } from '../../../../services/requests/user-requests'
import { useToast } from '../../../../hooks/useToast'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import { CheckboxType } from '../../../../interfaces/checkbox'

interface Props {
  values: number[]
  onFilter: (values: CheckboxType[]) => void
}

export default function PowerPlantFilter(props: Props) {
  const { values, onFilter } = props

  const [powerPlants, setPowerPlants] = useState<any[]>([])

  const { toast } = useToast()

  const fetchpowerPlants = async () => {
    try {
      const powerPlantsResponse = await powerPlantListGet()
      setPowerPlants(powerPlantsResponse.data)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar usinas',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchpowerPlants()
  }, [])

  return (
    <FloatingCheckboxes
      isFilterActive={values.length > 0}
      label='Usinas'
      options={powerPlants.map((powerPlant) => ({
        id: powerPlant.id,
        label: powerPlant.nome,
        checked: values.length === 0 ? true : values.includes(powerPlant.id),
      }))}
      selectableAll
      searchable
      submitAction={onFilter}
    />
  )
}
