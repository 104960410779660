import styled from 'styled-components'
import { useContext } from 'react'
import { GlobalContext } from '../../../../../context/global/global'
import { darken } from 'polished'

const getColor = (props: any) => {
  const { Theme } = useContext(GlobalContext)

  if (props.isDragAccept) {
    return Theme.colors.darkishGreen
  }
  if (props.isDragReject) {
    return Theme.colors.darkishGreen
  }
  if (props.isFocused) {
    return Theme.colors.darkishGreen
  }
  return '#FFF'
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`

export const Button = styled.button`
  background-color: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UploadContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 48px 20px;
  border-width: 2px;
  height: 680px;
  width: 100%;
  font-size: 20px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export const FilesList = styled.div`
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`

export const FileContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  flex: 1;
  gap: 4px; 
`

interface IFileStatusMessageProps {
  bgColor: string;
  color: string;
}

export const FileStatusMessage = styled.div<IFileStatusMessageProps>`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
`

const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-left: 42px;
  padding-right: 42px;
  gap: 16px;
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`
