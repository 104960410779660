import { Dispatch, SetStateAction, useRef } from 'react'
import { Images } from '../../styles'
import { Typography } from '@mui/material'

interface Props {
  images: File[]
  setImages: Dispatch<SetStateAction<File[]>>
}

export default function ImageSelector(props: Props) {
  const { images, setImages } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const addFiles = (fileList: FileList | null) => {
    if (!fileList || fileList.length === 0) {
      return
    }
    const files = Array.from(fileList)
    setImages((previousImages) => {
      return [...previousImages, ...files]
    })
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const openFileSelector = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <Images onClick={openFileSelector}>
      <input
        style={{ display: 'none' }}
        type='file'
        onChange={(e) => addFiles(e.target.files)}
        multiple
        ref={inputRef}
      />

      {images.length > 0 ? (
        <p>{images.map(val => val.name).join(',')}</p>
      ) : (
        <p>Clique para inserir arquivos</p>
      )}
    </Images>
  )
}
