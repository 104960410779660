import { ReactComponent as DownloadIcon } from '../../../../../assets/download-icon-white.svg'
import { PaymentExcel } from '../../../../../components/Modal/PaymentExcel'
import { useState } from 'react'
import { DownloadDiv } from '../../../../Invoices/styles'

interface Props {
  dateSelected: {
    year: number
    month: number
  }
  onDownload: (referenceMonths: string[]) => Promise<void>
}

export default function DownloadSheet(props: Props) {
  const { dateSelected, onDownload } = props

  const [isDownloadModalOpen, setDownloadModalOpen] = useState<boolean>(false)

  return (
    <>
      <PaymentExcel
        title="Download de planilha quantitativa"
        subtitle="Selecione os meses em que deseja adicionar a planilha"
        successText="Planilha quantitativa baixada com sucesso"
        selectedDate={dateSelected}
        openModal={isDownloadModalOpen}
        setModalOpen={setDownloadModalOpen}
        handleDownload={onDownload}
      />

      <DownloadDiv style={{ width: '32px', height: '32px' }}>
        <DownloadIcon
          onClick={() => setDownloadModalOpen(true)}
        />
      </DownloadDiv>
    </>
  )
}
