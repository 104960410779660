import { useState, useContext, createContext, useCallback, PropsWithChildren, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { v4 } from 'uuid';

interface ToastParams {
  id: string
  message: string
  type: 'success' | 'error' | 'info' | 'warning'
}

interface ToastContextData {
  toast: (params: Omit<ToastParams, 'id'>) => void
}

const ToastContext = createContext({} as ToastContextData);

export function ToastProvider(props: PropsWithChildren) {
  const { children } = props

  const [toasts, setToasts] = useState<ToastParams[]>([]);

  const toast = useCallback((props: Omit<ToastParams, 'id'>) => {
    const { message, type } = props
    setToasts((prevToasts) => [...prevToasts, { id: v4(), message, type }]);
  }, []);

  const closeToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  useEffect(() => {
    if (toasts.length > 0) {
      const { id } = toasts[0];
      const timer = setTimeout(() => closeToast(id), 5000);
      return () => {
        clearTimeout(timer)
      };
    }
  }, [toasts, closeToast]);

  return (
    <ToastContext.Provider value={{ toast }}>
    {children}
    {toasts.map((toast) => (
      <Snackbar
        key={toast.id}
        open={true}
        autoHideDuration={5000}
        onClose={() => closeToast(toast.id)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
          marginTop: '5px', 
        }}
      >
        <Alert variant="filled" severity={toast.type} onClose={() => closeToast(toast.id)} sx={{ width: 400 }}>
          {toast.message}
        </Alert>
      </Snackbar>
    ))}
  </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('Provider not found');
  }
  return context;
};
