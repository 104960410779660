import { FormikProps } from 'formik'
import { useEffect, useState } from 'react'
import { MetodoDePagamentosListRequestType, RegrasRemuneracaoListRequestType } from '../../types'
import {
  getMetodosDePagamento,
  getRegraRemuneracao,
} from '../../../../../../../services/requests/user-requests'
import { useToast } from '../../../../../../../hooks/useToast'
import { InputV2 } from '../../../../../../InputV2'
import { Autocomplete, Stack } from '@mui/material'
import { Search } from '@mui/icons-material'
import { object } from 'yup'
import { ExpiringRule, expiringRuleMap } from '../../../../../../../interfaces/ucs'

interface Props {
  formik: FormikProps<any>
}

export default function FinancialFields(props: Props) {
  const { formik } = props

  const { errors, values, setFieldValue } = formik

  const { toast } = useToast()

  const [paymentMethods, setPaymentMethods] = useState<MetodoDePagamentosListRequestType>([])
  const [remunerationRules, setRemunerationRules] = useState<RegrasRemuneracaoListRequestType>([])

  const [selectedRemunerationRuleId, setSelectedRemunerationRuleId] = useState<string | null>(
    values.remuneracao_regra,
  )
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<number | null>(
    values.metodo_pagamento_id,
  )

  const [selectedDueDate, setSelectedDueDate] = useState<string | null>(
    values.vencimento_regra,
  )

  const fetchData = async () => {
    try {
      const promises = [getMetodosDePagamento(), getRegraRemuneracao()]

      const [paymentMethodsData, remunerationRuleData] = await Promise.all(promises)

      setPaymentMethods(
        paymentMethodsData.data.map((metodo: { id: number; bb_nome_consorcio: string }) => {
          return {
            label: metodo.bb_nome_consorcio,
            value: metodo.id,
          }
        }),
      )
      setRemunerationRules(remunerationRuleData.data)
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao listar dados',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setSelectedRemunerationRuleId(values.remuneracao_regra)
    setSelectedPaymentMethodId(values.metodo_pagamento_id)
  }, [values.remuneracao_regra, values.metodo_pagamento_id])

  return (
    <>
      <Autocomplete
        id='metodo_pagamento_id'
        ListboxProps={{
          style: {
            maxHeight: '250px',
          },
        }}
        renderInput={(params) => (
          <InputV2
            rightIcon={<Search />}
            error={!!errors[params.id]}
            label='Método de Pagamento'
            {...params}
          />
        )}
        options={paymentMethods.map((paymentMethod) => paymentMethod.value)}
        getOptionLabel={(option) => {
          const optionNumber = Number(option)
          return !isNaN(optionNumber)
            ? paymentMethods.find((paymentMethod) => paymentMethod.value === option)?.label ?? ''
            : String(option)
        }}
        isOptionEqualToValue={(option) => option === selectedPaymentMethodId}
        value={selectedPaymentMethodId}
        onChange={(_, value) => {
          setFieldValue('metodo_pagamento_id', value)
          setSelectedPaymentMethodId(value)
        }}
      />
      <Autocomplete
        id='remuneracao_regra'
        ListboxProps={{
          style: {
            maxHeight: '250px',
          },
        }}
        renderInput={(params) => (
          <InputV2
            rightIcon={<Search />}
            error={!!errors[params.id]}
            label='Regra de Remuneração'
            required
            {...params}
          />
        )}
        options={remunerationRules.map((remunerationRule) => remunerationRule.value)}
        getOptionLabel={(option) =>
          remunerationRules.find((remunerationRule) => remunerationRule.value === option)?.label ??
          option
        }
        isOptionEqualToValue={(option) => option === selectedRemunerationRuleId}
        value={selectedRemunerationRuleId}
        onChange={(_, value) => {
          setFieldValue('remuneracao_regra', value)
          setSelectedRemunerationRuleId(value)
        }}
      />

      <Stack sx={{ paddingLeft: 6 }} gap={2}>
        {values.remuneracao_regra === 'PERCENTUAL_SOBRE_TARIFA_CONSUMO' && (
          <InputV2
            id='percentual_sobre_compensado_valor'
            label='Percentual sobre tarifa de consumo'
            type='number'
          />
        )}
        {(values.remuneracao_regra === 'PERCENTUAL_SOBRE_TARIFA_CRUA' ||
          values.remuneracao_regra === 'PERCENTUAL_SOBRE_COMPENSADO' ||
          values.remunerationRule === 'ENERGIA_AMIGA' ||
          values.remuneracao_regra === 'CV_CHEIA' ||
          values.remuneracao_regra === 'CV_CRUA' ||
          values.remuneracao_regra === 'REGRA_R4' ||
          values.remuneracao_regra === 'REGRA_DEVOLUCAO_R4' ||
          values.remuneracao_regra === 'PERCENTUAL_SOBRE_COMPENSADO_COSIP' ||
          values.remuneracao_regra === 'PERCENTUAL_SOBRE_COMPENSADO_DEVOLUCAO' ||
          values.remuneracao_regra === 'FFZ' ||
          values.remuneracao_regra === 'SIMPLA' ||
          values.remuneracao_regra === 'SIMPLA_FATURA_UNICA' ||
          values.remuneracao_regra === 'DESCONTO_SOBRE_FATURA_GD' ||
          values.remuneracao_regra === 'COMPENSADO_R4' ||
          values.remuneracao_regra === 'APORA' ||
          values.remuneracao_regra === 'PERCENTUAL_TARIFA_CONSUMO_CRUA' ||
          values.remuneracao_regra === 'SOLARES_FATURA_UNICA' ||
          values.remuneracao_regra === 'FATURA_UNICA_COMPENSADA' 
        ) && (
            <InputV2
              id='percentual_sobre_compensado_valor'
              label='Percentual sobre Compensado'
              type='number'
            />
          )}
        {values.remuneracao_regra === 'FFZ' && (
          <>
            <InputV2 id='valor_arrendamento' label='Valor arrendamento' type='number' />
            <InputV2 id='piso_disponibilidade' label='Piso disponibilidade' type='number' />
          </>
        )}
        {(values.remuneracao_regra === 'PERCENTUAL_SOBRE_TARIFA_CRUA' ||
          values.remuneracao_regra === 'PERCENTUAL_SOBRE_COMPENSADO' ||
          values.remunerationRule === 'ENERGIA_AMIGA' ||
          values.remuneracao_regra === 'PERCENTUAL_SOBRE_TARIFA_CONSUMO' ||
          values.remuneracao_regra === 'PERCENTUAL_SOBRE_COMPENSADO_COSIP' ||
          values.remuneracao_regra === 'PERCENTUAL_SOBRE_COMPENSADO_DEVOLUCAO' ||
          values.remuneracao_regra === 'REGRA_R4' ||
          values.remuneracao_regra === 'REGRA_DEVOLUCAO_R4' ||
          values.remuneracao_regra === 'APORA' ||
          values.remuneracao_regra === 'FATURA_UNICA_COMPENSADA' 
        ) && (
            <InputV2
              id='percentual_sobre_bandeira_valor'
              label='Percentual sobre Bandeira'
              type='number'
            />
          )}
        {(values.remuneracao_regra === 'DESCONTO_FIXO' ||
          values.remuneracao_regra === 'COTA_SOBRE_VALOR_FIXO') && (
            <InputV2 id='desconto_fixo_valor' label='Valor do Desconto Fixo' type='number' />
          )}
        {values.remuneracao_regra === 'TARIFA_FIXA' && (
          <InputV2 id='tarifa_fixa_valor' label='Valor da Tarifa Fixa' type='number' />
        )}
        {(values.remuneracao_regra === 'VALOR_FIXO' || values.remuneracao_regra === 'SIMPLA' || values.remuneracao_regra === 'SIMPLA_FATURA_UNICA') && (
          <InputV2 id='valor_fixo' label='Valor Fixo' type='number' />
        )}
        {(values.remuneracao_regra === 'SIMPLA' || values.remuneracao_regra === 'SIMPLA_FATURA_UNICA') && (
          <InputV2
            id='mes_operacao'
            label='Data do início de operação'
            mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          />
        )}
        {values.remuneracao_regra === 'COMPENSADO_USINA' && (
          <InputV2 id='compensado_usina_valor' label='Compensado Usina' type='number' />
        )}
        {values.remuneracao_regra === 'ISL_SENGI' && (
          <InputV2 id='isl_sengi' label='Valor ISL - SENGI' type='number' />
        )}
        {values.remuneracao_regra === 'PERCENTUAL_POR_BANDEIRA' && (
          <>
            <Stack direction='row' gap={2}>
              <InputV2
                id='percentual_por_bandeira_verde'
                label='Percentual sobre Bandeira Verde'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_amar'
                label='Percentual sobre Bandeira Amarela'
                type='number'
              />
            </Stack>
            <Stack direction='row' gap={2}>
              <InputV2
                id='percentual_por_bandeira_verm_p1'
                label='Percentual sobre Bandeira Vermelha P1'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_verm_p2'
                label='Percentual sobre Bandeira Vermelha P2'
                type='number'
              />
            </Stack>
          </>
        )}
      </Stack>
      <Autocomplete
        id='vencimento_regra'
        ListboxProps={{
          style: {
            maxHeight: '250px',
          },
        }}
        renderInput={(params) => (
          <InputV2
            rightIcon={<Search />}
            error={!!errors[params.id]}
            label='Regra de vencimento'
            required
            {...params}
          />
        )}
        options={Object.values(ExpiringRule).map((paymentMethod) => paymentMethod)}
        getOptionLabel={(option) => {
          const result = Object.entries(expiringRuleMap).find(([key, value]) => key === option)
          return result ? result[1] : option
        }}
        isOptionEqualToValue={(option) => option === selectedDueDate}
        value={selectedDueDate}
        onChange={(_, value) => {
          setFieldValue('vencimento_regra', value)
          setSelectedDueDate(value)
        }}
      />
      <InputV2 id='vencimento_valor' label='Dia do vencimento' type="number" />
      <InputV2 id='consultor' label='Consultor' />
      <InputV2
        id='comissao'
        label='Comissão'
        type='number'
      // mask={{
      //   guide: false,
      //   mask: values.comissao.length < 3 ? [/\d/, '%', /\d/] : [/\d/, /\d/, '%']
      // }}
      />

    </>
  )
}
