import {
  ConsumerToUpdateOnboarding,
  QuotasToUpdateOnboarding,
  RecipientUcToUpdateOnboarding,
  UcToUpdateOnboarding,
} from '../interfaces/onboarding'
import { api } from './api'

export interface GetPowerPlantsFromCsv {
  params: {
    managerId: number
    file: File
  }
  result: {
    errors: Record<string, string[]>
    plantsCreatedCount: number
    plantsToUpdate: UcToUpdateOnboarding[]
  }
}
export interface BulkUpdatePowerPlants {
  params: {
    managerId: number
    powerPlants: Array<
      Partial<{
        nome: string
        fonte: string
        potencia_instalada: number
        codigo: string
        grupo: string
        documento_numero: string
        documento_tipo: string
        login: string
        login_tipo: string
        senha_dist: string
        cep: string
        modalidade_de_compensacao: string
        geradora_flag: boolean
        cliente_id_gestor: number
        distribuidora_id: number
      }> & { codigo: string }
    >
  }
  result: void
}
export interface GetConsumersFromCsv {
  params: {
    managerId: number
    file: File
  }
  result: {
    errors: Record<string, string[]>
    consumersCreatedCount: number
    consumersToUpdate: ConsumerToUpdateOnboarding[]
  }
}
export interface BulkUpdateConsumers {
  params: {
    managerId: number
    consumers: Array<
      Partial<{
        cliente_nome: string
        nome_razao_social: string
        tipo: string
        documento_tipo: string
        gestor_id: string
      }> & { documento_numero: string }
    >
  }
  result: void
}
export interface GetRecipientUcsFromCsv {
  params: {
    managerId: number
    file: File
  }
  result: {
    errors: Record<string, string[]>
    ucsCreatedCount: number
    ucsToUpdate: RecipientUcToUpdateOnboarding[]
  }
}
export interface GetQuotasFromCsv {
  params: {
    managerId: number
    file: File
  }
  result: {
    errors: Record<string, string[]>
    quotasCreatedCount: number
    quotasToUpdate: QuotasToUpdateOnboarding[]
  }
}
export interface BulkUpdateQuotas {
  params: {
    managerId: number
    quotas: {
      id: number
      data: Partial<{
        powerPlantId: number
        percentValue: number
      }>
    }[]
  }
  result: void
}
export interface BulkUpdateRecipientUcs {
  params: {
    managerId: number
    recipientUcs: Array<
      Partial<{
        nome: string
        documento_numero: string
        distribuidora_id: number
        login: string
        senha_dist: string
        grupo: string
        cliente_id_consumidor: number
        remuneracao_regra: string
        percentual_sobre_compensado_valor: number
      }> & { codigo: string }
    >
  }
  result: void
}


class OnboardingService {
  async getPowerPlantsFromCsv(
    params: GetPowerPlantsFromCsv['params'],
  ): Promise<GetPowerPlantsFromCsv['result']> {
    const { file, managerId } = params

    const formData = new FormData()
    formData.append('file', file)
    formData.append('managerId', String(managerId))

    const response = await api.post('/super/onboarding/power-plants/csv', formData)
    return response.data
  }
  async bulkUpdatePowerPlants(
    params: BulkUpdatePowerPlants['params'],
  ): Promise<BulkUpdatePowerPlants['result']> {
    const { powerPlants, managerId } = params
    await api.patch('/super/onboarding/power-plants', {
      powerPlants,
      managerId
    })
  }
  async getConsumersFromCsv(
    params: GetConsumersFromCsv['params'],
  ): Promise<GetConsumersFromCsv['result']> {
    const { file, managerId } = params

    const formData = new FormData()
    formData.append('file', file)
    formData.append('managerId', String(managerId))

    const response = await api.post('/super/onboarding/consumers/csv', formData)
    return response.data
  }
  async bulkUpdateConsumers(
    params: BulkUpdateConsumers['params'],
  ): Promise<BulkUpdateConsumers['result']> {
    const { consumers, managerId } = params
    await api.patch('/super/onboarding/consumers', {
      consumers,
      managerId
    })
  }
  async getRecipientUcsFromCsv(
    params: GetRecipientUcsFromCsv['params'],
  ): Promise<GetRecipientUcsFromCsv['result']> {
    const { file, managerId } = params

    const formData = new FormData()
    formData.append('file', file)
    formData.append('managerId', String(managerId))

    const response = await api.post('/super/onboarding/recipient-ucs/csv', formData)
    return response.data
  }
  async bulkUpdateRecipientUcs(
    params: BulkUpdateRecipientUcs['params'],
  ): Promise<BulkUpdateRecipientUcs['result']> {
    const { recipientUcs, managerId } = params
    await api.patch('/super/onboarding/recipient-ucs', {
      recipientUcs,
      managerId
    })
  }
  async getQuotasFromCsv(
    params: GetQuotasFromCsv['params'],
  ): Promise<GetQuotasFromCsv['result']> {
    const { file, managerId } = params

    const formData = new FormData()
    formData.append('file', file)
    formData.append('managerId', String(managerId))

    const response = await api.post('/super/onboarding/quotas/csv', formData)
    return response.data
  }
  async bulkUpdateQuotas(
    params: BulkUpdateQuotas['params'],
  ): Promise<BulkUpdateQuotas['result']> {
    const { quotas, managerId } = params
    await api.patch('/super/onboarding/quotas', {
      quotas,
      managerId
    })
  }
}

export const onboardingService = new OnboardingService()
