import { ActionTableButtonContainer } from './style'

type ActionTableButtonProps = {
  onClick?: () => void
  children?: string
  icon: React.ReactNode
  styles?: React.CSSProperties
  disabled?: boolean
}

export function ActionTableButton({ onClick, icon, children, styles, disabled }: ActionTableButtonProps) {
  return (
    <ActionTableButtonContainer style={styles} onClick={onClick} hasTitle={!!children} disabled={disabled ?? false}>
      {icon}
      {children}
    </ActionTableButtonContainer>
  )
}
