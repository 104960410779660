import { OnboardingChanges } from '../../../../../interfaces/onboarding'
import { Fragment, useEffect, useRef, useState } from 'react'
import { getPageInfo } from '../../../../../utils/pagination'
import SearchInput from '../../../../Tickets/components/SearchInput'
import {
  Alert,
  AlertTitle,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Theme } from '../../../../../styles/theme'
import { TablePagination } from '../../../../../components/TableV2/components/TablePagination'
import EntityToUpdateForm from '../EntityUpdateForm'
import { Error } from '@mui/icons-material'
import { Button } from '../../../../../components/Button'

interface Props<T> {
  entities: T[]
  isLoading: boolean // Remove
  itemsCompleted: number
  itemsToUpdate: number // Remove
  errors?: Record<string, string[]>
  compareSearchFilter: (searchFilter: string, entity: T) => boolean
  getEntityIdentifier: (entity: T) => string | number
  bulkUpdate?: (entities: T[]) => void
  getUpdateFormProps: (entity: T) => {
    title: string
    changes: OnboardingChanges
    onSubmit: (values: Record<string, unknown>) => void
  }
}

export default function EntityItems<T>(props: Props<T>) {
  const {
    entities,
    errors,
    itemsCompleted,
    getEntityIdentifier,
    getUpdateFormProps,
    compareSearchFilter,
    bulkUpdate,
  } = props

  const [currentPage, setCurrentPage] = useState<number>(1)

  const [currentPageInfo, setCurrentPageInfo] = useState(getPageInfo(currentPage, 10, entities))
  const [searchFilter, setSearchFilter] = useState<string>('')

  const containerRef = useRef<HTMLDivElement>(null)

  const [selectedEntitiesIds, setSelectedEntitiesIds] = useState<(string | number)[]>([])

  const selectAllEntities = () => {
    if (selectedEntitiesIds.length === currentPageInfo.data.length) {
      setSelectedEntitiesIds([])
    } else {
      setSelectedEntitiesIds(currentPageInfo.data.map((data) => getEntityIdentifier(data)))
    }
  }

  useEffect(() => {
    const entitiesToPaginate = !searchFilter
      ? entities
      : entities.filter((entity) => compareSearchFilter(searchFilter.toLowerCase(), entity))
    setCurrentPageInfo(getPageInfo(currentPage, 10, entitiesToPaginate))
  }, [entities, currentPage, searchFilter])

  const [open, setOpen] = useState<any>({})

  const handleClick = (itemId: any) => {
    setOpen((prev: any) => ({ ...prev, [itemId]: !prev[itemId] }))
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: '32px', marginTop: '16px' }} ref={containerRef}>
        <Typography variant='h6' marginBottom={2}>
          <strong>Itens criados:</strong> {itemsCompleted}
        </Typography>
        <SearchInput style={{ marginLeft: 'auto' }} onSearch={(value) => setSearchFilter(value)} />
      </div>

      {errors && Object.keys(errors).length > 0 && (
        <>
          <Typography variant='h6' fontWeight='bold' marginBottom={2}>
            Itens com erro:
          </Typography>

          {Object.entries(errors).map(([key, error]) => (
            <Alert
              key={`entity-item-${key}`}
              severity='error'
              iconMapping={{
                error: <Error fontSize='inherit' />,
              }}
              sx={{ mb: 2 }}
            >
              <AlertTitle>ID: {key}</AlertTitle>
              {error.join(', ')}
            </Alert>
          ))}
        </>
      )}

      {currentPageInfo.data.length > 0 && (
        <>
          <Stack direction='row' alignItems='center' marginBottom={2}>
            <Typography variant='h6' fontWeight='bold'>
              Itens para atualizar:
            </Typography>
            {bulkUpdate && (
              <Button
                containerStyle={{ width: '200px', marginLeft: 'auto' }}
                text='Atualizar selecionados'
                onClick={() => {
                  bulkUpdate(
                    currentPageInfo.data.filter((data) =>
                      selectedEntitiesIds.includes(getEntityIdentifier(data)),
                    ),
                  )
                  setSelectedEntitiesIds([])
                }}
              />
            )}
          </Stack>
          <Table sx={{ borderRadius: 4, overflow: 'hidden' }}>
            <TableHead sx={{ backgroundColor: Theme.colors.darkishGreen, borderRadius: 0.5 }}>
              <TableRow>
                <TableCell sx={{ color: 'white', textAlign: 'center', width: '45px' }}>
                  <Checkbox checked={selectedEntitiesIds.length > 0} onClick={selectAllEntities} />
                </TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'center' }}>ID</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'center' }}>Nome</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'center' }}>Alterações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageInfo.data.map((item) => {
                const { title, changes, onSubmit } = getUpdateFormProps(item)
                const itemId = getEntityIdentifier(item)
                return (
                  <Fragment key={`table-row-${itemId}`}>
                    <TableRow sx={{ cursor: 'pointer' }} onClick={() => handleClick(itemId)}>
                      {bulkUpdate && (
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Checkbox
                            id={`checkbox-${itemId}`}
                            onChange={(e) => ({})}
                            checked={selectedEntitiesIds.includes(itemId)}
                          />
                        </TableCell>
                      )}
                      <TableCell sx={{ textAlign: 'center' }}>{itemId}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{title}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {Object.keys(changes).length}
                      </TableCell>
                    </TableRow>

                    <EntityToUpdateForm
                      changes={changes}
                      isLoading={false}
                      isOpen={open[itemId]}
                      onSubmit={onSubmit}
                      title=''
                    />
                  </Fragment>
                )
              })}
            </TableBody>
          </Table>

          {entities.length > 0 && (
            <TablePagination
              pageInfo={{
                currentPage,
                limit: currentPageInfo.itemsPerPage,
                totalCount: currentPageInfo.totalCount,
                totalPages: currentPageInfo.totalPages,
              }}
              currentPageItemCount={currentPageInfo.data.length}
              onChange={(page) => {
                if (containerRef.current) {
                  containerRef.current.scrollIntoView()
                }
                setCurrentPage(page)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
