import styled from 'styled-components'

export const ChartContainer = styled.div`
  width: auto;
  height: 159px;

  display: flex;
  flex-direction: column;

  background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  background-blend-mode: soft-light, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  & > h1 {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.htmlGrey};
    margin: 16px 16px 8px;
  }
`

export const ChartWrapper = styled.div`
  width: 195px;
  height: 85px;
  overflow: hidden;
  margin: 0 auto;

  & .apexcharts-radialbar-hollow {
    display: none !important;
  }
`

export const ChartContext = styled.div`
  width: 300px;
  height: 300px;
  margin-top: -24px;
  margin-left: -16px;
`

export const Indicators = styled.div`
  display: flex;
  position: relative;

  & span {
    position: absolute;
    top: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.htmlGrey};
  }

  & span:first-child {
    top: 7px;
    left: 74px;
  }

  & span:last-child {
    top: 7px;
    right: 67px;
  }
`
