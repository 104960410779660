import { FilterStatus, NotificationFeedPopover, NotificationIconButton } from '@knocklabs/react'
import { Box, Stack, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import NotificationItem from './components/NotificationItem'
import './styles.css'


export default function Notifications() {
  const [isVisible, setVisible] = useState<boolean>(false)

  const notifButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <div>
      <NotificationIconButton ref={notifButtonRef} badgeCountType="unread" onClick={() => setVisible(!isVisible)} />
      <NotificationFeedPopover
        buttonRef={notifButtonRef}
        isVisible={isVisible}
        onClose={() => setVisible(false)}
        EmptyComponent={
          <Stack
            style={{ padding: '16px', textAlign: 'center' }}
            alignItems='center'
            justifyItems='center'
          >
            <Typography variant='body2' fontWeight='bold'>
              Sem notificações
            </Typography>
            <Typography variant='subtitle2' color='rgba(0, 0, 0, 0.87)'>
              No momento, não há notificações. Você será notificado assim que houver atualizações.
            </Typography>
          </Stack>
        }
        renderHeader={(helpers) => (
          <Box style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
            <Typography variant='body1' fontWeight='bold'>
              Notificações
            </Typography>

            <Box style={{ display: 'flex', marginLeft: 'auto' }}>
              <select onChange={(e) => helpers.setFilterStatus(e.target.value as FilterStatus)}>
                <option value='all'>Todas</option>
                <option value='read'>Lida</option>
                <option value='unread'>Não lida</option>
              </select>
            </Box>
          </Box>
        )}
        renderItem={(itemInfo) => <NotificationItem item={itemInfo.item} onItemClick={itemInfo.onItemClick} />}
      />
    </div>
  )
}
