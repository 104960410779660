import { api } from "../api";

export class MeasurementService {
  async downloadDailyConsumption(plantId: number, day: number, month: number, year: number): Promise<Blob> {
    const measurementResponse = await api.get('/report/measurement/daily', {
      params: {
        plantId,
        day,
        month,
        year
      },
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })

    return measurementResponse.data
  }
}

export const measurementService = new MeasurementService()
