import { Autocomplete, AutocompleteProps, ChipTypeMap, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { clientService } from '../../../../../services/client'

interface Manager {
  id: number
  cliente_nome: string
}

type Props = Omit<
  AutocompleteProps<number, false, false, false, ChipTypeMap['defaultComponent']>,
  'getOptionLabel' | 'options' | 'renderInput'
>

export default function ManagerSelect(props: Props) {
  const { ...rest } = props
  const [managers, setManagers] = useState<Manager[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        setLoading(true)
        const managersResponse = await clientService.getManagers()
        setManagers(managersResponse)
      } catch (error) {
        console.log(`Error fetching managers: ${error}`)
      } finally {
        setLoading(false)
      }
    }
    fetchManagers()
  }, [])

  return (
    <Autocomplete
      {...rest}
      disablePortal
      id='manager'
      sx={{ width: '250px' }}
      getOptionLabel={(option) =>
        managers.find((manager) => manager.id === option)?.cliente_nome ?? ''
      }
      options={managers.map((manager) => manager.id)}
      loading={isLoading}
      renderInput={(params) => <TextField {...params} label='Gestor' />}
    />
  )
}
