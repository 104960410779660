import { GridColDef } from '@mui/x-data-grid'
import { useContext, useEffect, useState } from 'react'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import { SearchInput } from '../../../components/SearchInput'
import { ConsumerHistory, PowerPlantsInvoice } from '../../../services/requests/types'
import {
  consumerListGet,
  distributorListGet,
  getConsumerDashboardInvoiceStatus,
  invoicesHistoryLocation,
  invoicesOverviewGet,
  powerPlantListGet,
} from '../../../services/requests/user-requests'

import { Alert, Snackbar, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import Loading from '../../../components/Loading'
import { GlobalContext } from '../../../context/global/global'
import {
  FilterContainer,
  InvoicesContainer,
  SearchContainer,
  TableFilters,
  YearButton,
  YearText,
} from '../styles'
import { tableInvoiceColumnsHistoryLocation } from '../utils/TableData'
import { CheckboxType } from '../../../interfaces/checkbox'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import { SettingsOutlined } from '@mui/icons-material'
import { TableV2 } from '../../../components/TableV2'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'
import { Sort } from '../../../interfaces/conference'

interface ListProps {
  id: number
  label: string
  checked: boolean
}

interface PercentagesProps {
  aguardando_emissao: number
  nao_capturadas: number
  completas: number
  // nao_processadas: number
}

export function InvoicesLocation() {
  const { state, locationLoading, locationError } = useContext(GlobalContext)
  const { type: authType, id: authId } = state.auth?.customer || {}

  const [tableCols, setTableCols] = useState<GridColDef[]>(tableInvoiceColumnsHistoryLocation)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [search, setSearch] = useState('')
  const [powerPlants, setPowerPlants] = useState<ListProps[] | undefined>(
    authType === 'CONSUMIDOR' ? [] : undefined,
  )
  const [consumers, setConsumers] = useState<ListProps[] | undefined>(
    authType === 'CONSUMIDOR' ? [] : undefined,
  )

  const [invoicePercentages, setInvoicePercentages] = useState<PercentagesProps>()
  const [eachPercentage, setEachPercentage] = useState<PercentagesProps>()
  const [consumerInvoices, setConsumerInvoices] = useState<PowerPlantsInvoice[]>()
  const [percentageTotal, setPercentageTotal] = useState(1)
  const [page, setPage] = useState(1)
  const [year, setYear] = useState(new Date().getFullYear())

  const [powerPlantsTotal, setPowerPlantsTotal] = useState(0)
  const [sortValues, setSortValues] = useState<Sort>()
  const [loading, setLoading] = useState(authType === 'GESTOR')
  const [ready, setReady] = useState(false)
  const [currentColor] = useState('')
  const [pageItemsAmount, setPageItemsAmount] = useState(30)

  const [distributors, setDistributors] = useState<ListProps[]>()

  const filterDistributor = (values: any) => {
    setPage(1)
    setDistributors(values)
  }

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: `${currentColor}`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${currentColor}`,
      color: '#FFFFFF',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      letterSpacing: 0,
      fontSize: 14,
      padding: 8,
      maxWidth: 280,
      border: '1px solid white',
    },
  }))

  useEffect(() => {
    setErrorMessage(locationError)
  }, [locationError])

  useEffect(() => {
    Promise.all([powerPlantListGet(), consumerListGet(), distributorListGet()])
      .then(async ([powerPlantData, consumerData, distributorData]) => {
        const powerPlantResponse = powerPlantData
        const consumerResponse = consumerData
        const distribuitorList = distributorData

        setLoading(!powerPlantData.data.length || !consumerData.data.length ? false : true)
        setPowerPlants(
          powerPlantResponse.data.map((item) => ({
            id: item.id,
            label: item.nome,
            checked: false,
          })),
        )

        setConsumers(
          consumerResponse.data.map((item) => ({
            id: item.id,
            label: item.cliente_nome,
            checked: false,
          })),
        )

        setDistributors(
          distribuitorList.data.map((item: { id: number; nome: string }) => ({
            id: item.id,
            label: item.nome,
            checked: true,
          })),
        )
      })
      .catch((error) => {
        setLoading(false)
        setErrorMessage(
          error.message ??
          'Erro ao buscar os dados iniciais de usina e distribuidora.',
        )
      })
  }, [])

  useEffect(() => {
    const fetchInvoices = async () => {
      if (consumers) {
        await invoicesHistoryLocation({
          consumersId: consumers.map((consumer) => consumer.id),
          limit: pageItemsAmount,
        })
      }
    }
    fetchInvoices()
  }, [consumers])

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    const invoicesOverview = ({
      request,
      consumersId,
      distributorsId = [],
    }: {
      request?: any
      consumersId: number[]
      distributorsId?: number[]
    }) => {
      Promise.all(
        authType === 'CONSUMIDOR'
          ? [
            getConsumerDashboardInvoiceStatus({
              year: currentYear,
              month: currentMonth,
            }),
            invoicesHistoryLocation({
              consumersId,
              distributorsId,
              powerPlantsId:
                powerPlants?.filter((item) => item.checked).map((item) => item.id as number) ??
                [],
              page: page,
              filter: search,
              year,
              sort: sortValues?.field === 'uc_nome' ? sortValues?.field.split('_')[1] : 'codigo',
              order: sortValues?.order,
              limit: pageItemsAmount,
            }),
          ]
          : [
            invoicesOverviewGet({
              ...request,
              isPowerPlant: 'false',
              year: currentYear,
              month: currentMonth,
            }),
            invoicesHistoryLocation({
              consumersId,
              distributorsId,
              powerPlantsId:
                powerPlants?.filter((item) => item.checked).map((item) => item.id as number) ??
                [],
              page: page,
              filter: search,
              year,
              sort: sortValues?.field === 'uc_nome' ? sortValues?.field.split('_')[1] : 'codigo',
              order: sortValues?.order,
              limit: pageItemsAmount,
            }),
          ],
      )
        .then(async ([overViewData, historyData]) => {
          const responseOverview = overViewData
          const consumersResponse = historyData

          consumersResponse.data.meta.total &&
            setPowerPlantsTotal(consumersResponse.data.meta.total)

          setConsumerInvoices(
            consumersResponse.data.ucs
              ? consumersResponse.data.ucs.map((item: ConsumerHistory) => ({
                uc_nome: item.uc_nome,
                uc_numero: item.uc_numero,
                consumidor_nome: item.consumidor_nome,
                ...item.faturas,
                id: item.uc_id,
              }))
              : null,
          )

          setPercentageTotal(
            Number(Object.values(responseOverview.data).reduce((r: any, c: any) => r + c)),
          )
          setInvoicePercentages(responseOverview.data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
          setErrorMessage(
            error.message ?? 'Erro ao buscar os dados de fatura das usinas.',
          )
        })
    }

    if (powerPlants?.length && consumers?.length && distributors?.length) {
      setLoading(true)
      let allIds = {
        distributorsId: distributors.filter((item) => item.checked).map((item) => item.id),
        powerPlantsIds: powerPlants.filter((item) => item.checked).map((item) => item.id),
      }
      allIds = { ...allIds }

      const request = {
        ...allIds,
        page,
        year,
      }

      const consumersId = consumers.filter((item) => item.checked).map((item) => item.id)

      const distributorsId = distributors.filter((item) => item.checked).map((item) => item.id)

      invoicesOverview({ request, consumersId, distributorsId })
    } else if (authType === 'CONSUMIDOR') {
      setLoading(true)
      const request = {
        page,
        distributorsId: [],
        powerPlantsIds: [],
        consumersId: authId ? [authId] : [],
      }
      const consumersId = authId ? [authId] : []
      invoicesOverview({ consumersId, request })
    }
  }, [
    powerPlants,
    consumers,
    page,
    ready,
    year,
    sortValues,
    pageItemsAmount,
    tableCols,
    distributors,
  ])

  useEffect(() => {
    if (invoicePercentages) {
      setEachPercentage({
        completas: Math.round((invoicePercentages.completas / percentageTotal) * 100),
        aguardando_emissao: Math.round(
          (invoicePercentages.aguardando_emissao / percentageTotal) * 100,
        ),
        // nao_processadas: Math.round((invoicePercentages.nao_processadas / percentageTotal) * 100),
        nao_capturadas: Math.round((invoicePercentages.nao_capturadas / percentageTotal) * 100),
      })
    }
  }, [invoicePercentages])

  const handlePageChange = (nextPage: number) => {
    if (nextPage !== page) {
      setPage(nextPage)
    }
  }

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  const filterConsumers = (values: any) => {
    setPage(1)
    setConsumers(values)
  }

  const generateYear = () => {
    const year = []

    for (let i = 2018; i <= new Date().getFullYear(); i++) {
      year.push(i)
    }

    return year
  }

  const filterPowerPlants = (values: any) => {
    setPage(1)
    setPowerPlants(values)
  }

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (search.length >= 3 || search.length === 0) {
        setPage(1)
        setReady(!ready)
      }
    }, 1000)

    return () => clearTimeout(delayDebounce)
  }, [search])

  return powerPlants && consumers && !loading && !locationLoading ? (
    <InvoicesContainer>
      <FilterContainer>
        <TableFilters>
          {authType === 'GESTOR' && (
            <>
              <FloatingCheckboxes
                checkbox
                isFilterActive={consumers.every((consumer) => consumer.checked)}
                label='Consumidores'
                options={consumers ?? []}
                selectableAll
                searchable
                submitAction={filterConsumers}
              />
              {distributors && (
                <FloatingCheckboxes
                  isFilterActive={distributors.some((distributor) => distributor.checked)}
                  label='Distribuidoras'
                  options={distributors}
                  selectableAll
                  searchable
                  submitAction={filterDistributor}
                />
              )}
            </>
          )}
          {generateYear().map((item) => (
            <YearButton
              key={item}
              active={item === year}
              onClick={() => {
                setPage(1)
                item !== year ? setYear(item) : null
              }}
            >
              <YearText>{item}</YearText>
            </YearButton>
          ))}
        </TableFilters>
        <SearchContainer>
          <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />
          <FloatingCheckboxes
            pagination={{
              selected: pageItemsAmount,
              setSelected: setPageItemsAmount,
              options: [15, 20, 30, 50, 100],
            }}
            isFilterActive={false}
            title='Configurar Tabela'
            options={tableCols.map((col) => ({
              label: col.headerName || '',
              checked: !col.hide,
              id: Math.random(),
            }))}
            customActionComponent={
              <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
            }
            submitBtnText='Salvar'
            submitAction={handleSelectTableColumns}
          />
        </SearchContainer>
      </FilterContainer>
      <TableV2
        columns={tableCols}
        rows={consumerInvoices ?? []}
        sort={sortValues}
        onSort={(sort) => {
          setSortValues(sort)
          setPage(1)
        }}
        messageNoRow='Não há faturas cadastradas.'
        alignText='center'
        serverSorting
      >
        <TablePagination
          pageInfo={{
            totalCount: powerPlantsTotal,
            currentPage: page,
            totalPages: Math.ceil(powerPlantsTotal / pageItemsAmount),
            limit: pageItemsAmount
          }}
          currentPageItemCount={consumerInvoices?.length ?? 0}
          onChange={handlePageChange}
        />
      </TableV2>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </InvoicesContainer>
  ) : (
    <Loading fatura={locationLoading} />
  )
}
