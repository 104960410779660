import { useContext, useState, MouseEvent as ReactMouseEvent } from 'react'
import { GlobalContext } from '../../../context/global/global'
import { EnergyBarChartPayload } from '../../../services/requests/types'
import { Widget, HoverContainer } from './style'
import { numberToString } from './../../../utils/formatNumberToString'

interface IEnergyChartProps {
  energyBarChartData: EnergyBarChartPayload
}

export function EnergyChart({ energyBarChartData }: IEnergyChartProps) {
  const { Theme } = useContext(GlobalContext)

  const { consumo_efetivado: efetivada, consumo_projetado: projetada } = energyBarChartData

  const [hoverProps, setHoverProps] = useState({
    isVisible: false,
    top: 0,
    left: 0,
  })

  const greaterValue = () => {
    const projetadaCalc = projetada + projetada / 1
    if (projetadaCalc >= efetivada) {
      return projetadaCalc
    } else {
      return efetivada
    }
  }

  const roundGreaterValue = (index = 0) => {
    const getZerosAmount = `${Math.round(greaterValue())}`.length - 1

    const zeros = new Array(getZerosAmount).fill(0).join('')
    const generateNum = Number(`1${zeros}`)
    const roundedValue = Math.ceil(greaterValue() / generateNum) * generateNum

    return isNaN((roundedValue / 4) * index) ? 0 : (roundedValue / 4) * index
  }

  const barHeight = (efetivada * 308) / roundGreaterValue(4)
  // console.log(barHeight)
  const linePosition = (projetada * 308) / roundGreaterValue(4)
  const handleHover = (e: ReactMouseEvent<SVGRectElement, MouseEvent>) => {
    setHoverProps({
      top: e.pageY,
      left: e.pageX,
      isVisible: true,
    })
  }

  const handleMouseOut = () => {
    setHoverProps((prev) => ({ ...prev, isVisible: false }))
  }

  return (
    <>
      <HoverContainer {...hoverProps}>
        <span>
          Consumo Realizado:{' '}
          <strong>{efetivada ? numberToString(Number(efetivada.toFixed(0))) : 0} kWh</strong>
        </span>
        <span>
          Consumo Estimado:{' '}
          <strong>{projetada ? numberToString(Number(projetada.toFixed(0))) : 0} kWh</strong>
        </span>
      </HoverContainer>
      <Widget>
        <svg
          width='255'
          height='auto'
          viewBox='0 0 255 473'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <style></style>
          <g filter='url(#filter0_d_17_15)'>
            <rect x='3' y='5' width='237' height='455' rx='15' />
            <text
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              fontStyle='normal'
              fontWeight='400'
              fontSize='22px'
              letterSpacing='-0.05em'
            >
              <tspan x='23' y='32.5455'>
                Consumo de energia
              </tspan>
            </text>
            <text
              fill='black'
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              fontFamily='Inter'
              fontSize='15'
              letterSpacing='0em'
            >
              <tspan x='50' y='71.4545' textAnchor='end'>
                {numberToString(roundGreaterValue(4))}
              </tspan>
            </text>
            <text
              fill='black'
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              fontFamily='Inter'
              fontSize='15'
              letterSpacing='0em'
            >
              <tspan x='50' y='145' textAnchor='end'>
                {numberToString(roundGreaterValue(3))}
              </tspan>
            </text>
            <text
              fill='black'
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              fontFamily='Inter'
              fontSize='15'
              letterSpacing='0em'
            >
              <tspan x='50' y='223' textAnchor='end'>
                {numberToString(roundGreaterValue(2))}
              </tspan>
            </text>
            <text
              fill='black'
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              fontFamily='Inter'
              fontSize='15'
              letterSpacing='0em'
            >
              <tspan x='50' y='300' textAnchor='end'>
                {numberToString(roundGreaterValue(1))}
              </tspan>
            </text>
            <text
              fill='black'
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              fontFamily='Inter'
              fontSize='15'
              letterSpacing='0em'
            >
              <tspan x='50' y='368.455' textAnchor='end'>
                {numberToString(roundGreaterValue())}
              </tspan>
            </text>
            <line x1='69.5' y1='65' x2='69.5' y2='372' stroke='black' />
            <line x1='69' y1='371.5' x2='220' y2='371.5' stroke='black' />
            <line x1='60' y1='365' x2='69' y2='365' stroke='black' />
            <line x1='60' y1='295' x2='69' y2='295' stroke='black' />
            <line x1='69' y1='295' x2='220' y2='295' stroke='lightgray' />
            <line x1='60' y1='218' x2='69' y2='218' stroke='black' />
            <line x1='69' y1='218' x2='220' y2='218' stroke='lightgray' />
            <line x1='60' y1='140' x2='69' y2='140' stroke='black' />
            <line x1='69' y1='140' x2='220' y2='140' stroke='lightgray' />
            <line x1='60' y1='65' x2='69' y2='65' stroke='black' />
            <line x1='69' y1='65' x2='220' y2='65' stroke='lightgray' />
            <rect
              x='118'
              y='372'
              width='151'
              height='308'
              transform='rotate(-180 169.082 372)'
              style={{ transition: '200ms', opacity: hoverProps.isVisible ? 1 : 0 }}
              fill='#0000001b'
            />
            <rect
              x='169.082'
              y='372'
              width='48.2849'
              height={barHeight}
              rx='5'
              transform='rotate(-180 169.082 372)'
              fill={Theme.colors.darkishGreen}
            />
            <rect
              x='118'
              y='372'
              width='151'
              height='308'
              transform='rotate(-180 169.082 372)'
              onMouseMoveCapture={handleHover}
              onMouseOut={handleMouseOut}
              opacity='0'
              fill='black'
            />
            <line
              x1='0'
              y1={linePosition}
              x2='150.1221'
              y2={linePosition}
              stroke={Theme.colors.orange}
              transform='translate(220, 371.5) rotate(180)'
            />
            <rect x='23' y='393' width='18' height='18' fill={Theme.colors.darkishGreen} />
            <text
              fill='black'
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              letterSpacing='0em'
              fontStyle='normal'
              fontWeight='400'
              fontSize='20'
            >
              <tspan x='47' y='408.273'>
                Consumo realizado
              </tspan>
            </text>
            <rect x='23' y='422' width='18' height='18' fill={Theme.colors.orange} />
            <text
              fill='black'
              xmlSpace='preserve'
              style={{ whiteSpace: 'pre' }}
              letterSpacing='0em'
              fontStyle='normal'
              fontWeight='400'
              fontSize='20'
            >
              <tspan x='47' y='438.273'>
                Consumo estimado
              </tspan>
            </text>
          </g>
          <defs>
            <filter
              id='filter0_d_17_15'
              x='0'
              y='0'
              width='255'
              height='473'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset dx='6' dy='4' />
              <feGaussianBlur stdDeviation='4.5' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0' />
              <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_17_15' />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='effect1_dropShadow_17_15'
                result='shape'
              />
            </filter>
          </defs>
        </svg>
      </Widget>
    </>
  )
}
