import styled from '@emotion/styled'
import { Modal as MUIModal } from '@mui/material'
import { Theme } from '../../../styles/theme'
export const Modal = styled(MUIModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div<{ maxWidth: number }>`
  height: 100%;
  overflow: hidden;
  max-height: 90%;
  background: ${Theme.colors.white};
  border-radius: 16px;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  height: fit-content;
  flex-direction: column;
`
