import { useEffect, useState } from 'react'
import { ListUserPermissions } from '../../../../../services/permissions'
import { List, Typography } from '@mui/material'
import PermissionItem from '../../Users/components/PermissionItem'

export interface TouchedPermission {
  permissionId: number
  hasPermission: boolean
}

interface Props {
  permissions: {
    hasPermission: boolean
    permission: ListUserPermissions['result'][number]['permission']
  }[]
  onChange?: (touchedPermissions: TouchedPermission[]) => void
}

export default function PermissionList(props: Props) {
  const { permissions, onChange } = props

  const [touchedPermissions, setTouchedPermissions] = useState<TouchedPermission[]>([])

  useEffect(() => {
    setTouchedPermissions(
      permissions
        .filter((permissionInfo) => permissionInfo.hasPermission)
        .map((permissionInfo) => ({
          permissionId: permissionInfo.permission.id,
          hasPermission: true,
        })),
    )
  }, [permissions])

  const groupedByResourceType = permissions.reduce((acc, current: any) => {
    const resourceType = current.permission.resourceType

    // Find if the resourceType already exists in the accumulator
    let group = acc.find((item: any) => item.resourceType === resourceType)

    // If it doesn't exist, create a new group
    if (!group) {
      group = { resourceType, permissions: [] }
      acc.push(group)
    }

    // Add the current permission to the group
    group.permissions.push(current)

    return acc
  }, [] as any[])

  const checkPermission = (permissionId: number) => {
    setTouchedPermissions((previousTouchedPermissions) => {
      const updatedTouchedPermissions = [...previousTouchedPermissions]
      const existsIndex = updatedTouchedPermissions.findIndex(
        (permission) => permission.permissionId === permissionId,
      )
      const obj = {
        permissionId: permissionId,
        hasPermission:
          existsIndex !== -1 ? !updatedTouchedPermissions[existsIndex].hasPermission : true,
      }
      if (existsIndex === -1) {
        updatedTouchedPermissions.push(obj)
      } else {
        updatedTouchedPermissions[existsIndex] = obj
      }
      if (onChange) {
        onChange(updatedTouchedPermissions)
      }
      return updatedTouchedPermissions
    })
  }

  return (
    <List sx={{ width: '100%', padding: 0 }}>
      {groupedByResourceType.map((group) => (
        <div style={{ marginBottom: '16px' }} key={`permission-group-${group.resourceType}`}>
          <Typography style={{ marginBottom: '8px' }} variant='body1' fontWeight='bold'>
            {group.resourceType}
          </Typography>
          {group.permissions.map((permissionInfo: ListUserPermissions['result'][number]) => {
            const isDisabled = !!permissionInfo.fromRoles
            const tooltipTitle = isDisabled
              ? `Herdado de: ${(permissionInfo?.fromRoles ?? []).join(', ')}`
              : ''
            return (
              <PermissionItem
                key={`permission-item-${permissionInfo.permission.id}-${group.resourceType}`}
                permission={permissionInfo.permission}
                disabled={isDisabled}
                tooltipTitle={tooltipTitle}
                onClick={() => checkPermission(permissionInfo.permission.id)}
                hasPermission={
                  !!touchedPermissions.find(
                    (permission) =>
                      permission.permissionId === permissionInfo.permission.id &&
                      permission.hasPermission,
                  )
                }
              />
            )
          })}
        </div>
      ))}
    </List>
  )
}
