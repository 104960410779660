import { Conference } from '../../../../interfaces/conference'
import ConferenceChart from '../ConferenceChart'

const getChartData = (conferences: Conference[]) => {
  const data = conferences.sort((r1, r2) => r2.invoicesTotalCount - r1.invoicesTotalCount)

  const categories = data.map((conference) => conference.entity.name)

  const chartData = [
    {
      name: 'Faturas Completas',
      data: data.map((conference) => conference.invoicesCompletedCount),
    },
    {
      name: 'Não Baixadas',
      data: data.map((conference) => conference.invoicesNotDownloadedCount),
    },
    {
      name: 'R$ Faltante (R$/mil)',
      data: data.map((conference) => {
        const remainderInvoicing =
          conference.previousMonthInvoicing - conference.currentMonthInvoicing
        return remainderInvoicing > 0 ? parseFloat((remainderInvoicing / 1000).toFixed(2)) : 0
      }),
    },
  ]

  return {
    categories,
    chartData,
  }
}

interface Props {
  conferences: Conference[]
}

export default function InvoicesOverviewChart(props: Props) {
  const { conferences } = props

  const { categories, chartData } = getChartData(conferences)

  return <ConferenceChart title='Total de Faturas' data={chartData} categories={categories} />
}
