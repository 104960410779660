import styled from "styled-components";

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: ${(props) => props.theme.colors.grayDark};
    font-size: 0.875rem;
    letter-spacing: 0.15px;
  }
`
