import React from 'react'
import { FloatingFilterWrapper } from '../FloatingFilterWrapper'

import Search from '../../assets/icons/searchIcon.svg'
import { InputSearch } from './styles'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  paper: {
    boxShadow:
      '-1.66667px -1.66667px 5px #FFFFFF, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2)',
  },
})

export type InputData = { label: string; id: number }

interface Props {
  label?: string
  isFilterActive: boolean
  data: InputData[]
  value: InputData | null
  submitAction: (value: InputData) => void
  onInputChange?: (value: string) => void
}

export const FloatingSingleSelect: React.FC<Props> = ({
  label,
  data,
  isFilterActive,
  submitAction,
  onInputChange,
  value,
}) => {
  const classes = useStyles()

  return (
    <FloatingFilterWrapper label={label} hideSubmitButton isFilterActive={isFilterActive}>
      <InputSearch
        onChange={(e, val) => {
          submitAction(val as InputData)
        }}
        disablePortal
        value={value || null}
        noOptionsText='Nenhuma opção encontrada'
        classes={{ paper: classes.paper }}
        options={data}
        sx={{ width: 280, height: 30, display: 'flex', alignItems: 'center' }}
        renderInput={(params) => (
          <div style={{ display: 'flex', marginLeft: 10, width: '100%' }}>
            <img src={`${Search}`} />
            <TextField {...params} onChange={({ target }) => {
              if (!onInputChange || !(onInputChange instanceof Function)) return

              onInputChange(target.value ?? '')
            }} />
          </div>
        )}
        renderOption={(props, option: any) => {
          return (
            <li style={{ paddingTop: 8, paddingBottom: 8, fontSize: 16 }} {...props}>
              {option.label}
            </li>
          )
        }}
      />
    </FloatingFilterWrapper>
  )
}
