import { SettingsOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { CheckboxType } from '../../../../../interfaces/checkbox'
import { GridColDef } from '@mui/x-data-grid'
import { FloatingCheckboxes } from '../../../../../components/FloatingCheckboxes'
import { ActionTableButton } from '../../../../../components/Table/ActionTableButton'

interface Props {
  itemsPerPage: number
  setItemsPerPage: (itemsPerPage: number) => void
  tableColumns: GridColDef[]
  setTableColumns: (columns: GridColDef[]) => void
}

export default function TableConfig(props: Props) {
  const { tableColumns, setTableColumns, itemsPerPage, setItemsPerPage } = props

  const [localItemsPerPage, setLocalItemsPerPage] = useState<number>(itemsPerPage)

  const submitAction = (checkboxes: CheckboxType[]) => {
    setItemsPerPage(localItemsPerPage)
    setTableColumns(
      tableColumns.map((col) => ({
        ...col,
        hide: col.hideable
          ? !checkboxes.find((box) => box.label === col.headerName)?.checked
          : false,
      })),
    )
  }

  return (
    <FloatingCheckboxes
      pagination={{
        selected: localItemsPerPage,
        setSelected: setLocalItemsPerPage,
        options: [15, 20, 30, 50, 100],
      }}
      isFilterActive={false}
      title='Configurar Tabela'
      options={tableColumns
        .filter((col) => col.hideable)
        .map((col) => ({
          label: col.headerName || '',
          checked: !col.hide,
          id: Math.random(),
        }))}
      customActionComponent={<ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>}
      submitBtnText='Salvar'
      submitAction={submitAction}
    />
  )
}
