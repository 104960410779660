export enum PaymentMethodPlatform {
  SICOOB = 'SICOOB',
  ASAAS = 'ASAAS',
  BB = 'BB'
}

export enum PaymentMethodModality {
  SIMPLES_COM_REGISTRO = 1,
  VINCULADA = 4
}

export const paymentMethodModalities = {
  1: 'Simples com registro',
  4: 'Vinculada'
}

export const paymentMethodDocumentTypes = {
  1: "Cheque",
  2: "Duplicata mercantil",
  3: "Duplicata mtil por indicacao",
  4: "Duplicata de servico",
  5: "Duplicata de srvc p/indicacao",
  6: "Duplicata rural",
  7: "Letra de cambio",
  8: "Nota de credito comercial",
  9: "Nota de credito a exportacao",
  10: "Nota de credito industrial",
  11: "Nota de credito rural",
  12: "Nota promissoria",
  13: "Nota promissoria rural",
  14: "Triplicata mercantil",
  15: "Triplicata de servico",
  16: "Nota de seguro",
  17: "Recibo",
  18: "Fatura",
  19: "Nota de debito",
  20: "Apolice de seguro",
  21: "Mensalidade escolar",
  22: "Parcela de consorcio",
  23: "Divida ativa da uniao",
  24: "Divida ativa de estado",
  25: "Divida ativa de municipio",
  31: "Cartao de credito",
  32: "Boleto proposta",
  33: "Boleto aporte",
  99: "Outros"
}
