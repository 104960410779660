import React from 'react'
import Widget from '../Widget'

import {
  Container,
  IcontContainer,
  InfoContainer,
  InfoTitle,
  ValueContainer,
  InfoValue,
  ContainerLimits,
  LegendArea,
} from './styles'
import { GlobalContext } from '../../context/global/global'

interface WidgetProps {
  icon: string | React.ReactNode
  title: string
  value: number | string
}

export function WidgetTicket({ icon, title, value }: WidgetProps) {
  const { Theme } = React.useContext(GlobalContext)

  return (
    <Widget style={{ minWidth: 279.5 }}>
      <ContainerLimits>
        <Container>
          <LegendArea>
            <IcontContainer>
              {typeof icon === 'string' ? (
                <img src={`${icon}`} width={24} height={24} color={Theme.colors.darkishGreen} />
              ) : (
                icon
              )}
            </IcontContainer>
            <InfoTitle>{title}</InfoTitle>
          </LegendArea>
          <InfoContainer>
            <ValueContainer>
              <InfoValue>{value}</InfoValue>
            </ValueContainer>
          </InfoContainer>
        </Container>
      </ContainerLimits>
    </Widget>
  )
}

export default WidgetTicket
