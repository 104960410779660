import fullLogo from '../assets/frame.svg'
import icon from '../assets/logo-icon.svg'
import mapIconSecondary from '../assets/google-default-marker.svg'

export const Theme = {
  colors: {
    white: '#FFF',
    darkGreen: '#02231c',
    darkishGreen: '#023b21',
    green: '#457d63',
    DarkSpringGreen: '#196743',
    SeaGreen: '#319065',
    ShinyShamrock: '#589F7E',
    appleGreen: '#6dc19a',
    lightGreen: '#92e3a9',
    brightGreen: '#01fe88',
    orange: '#ff6600',
    lightOrange: '#ff8832',
    cream: '#ffd2b3',
    sideBarText: '#d9d9d9',
    quartzGrey: '#4B4B4B',
    htmlGrey: '#808080',
    grey: '#ababab',
    lightGrey: '#dddddd',
    background: '#f9f9fd',
    grayLight: '#D9D9D9',
    grayDark: '#ADADAD',
    suportError: '#E54545',
  },
  fonts: {
    family: {
      primary: 'Roboto, sans-serif',
      secondary: 'Poppins, sans-serif',
    },
  },
  logos: {
    fullLogo: fullLogo.toString(),
    tabTitle: 'Lumi',
    icon: icon.toString(),
    size: '',
    mapIconDefault: null,
    mapIconSecondary: mapIconSecondary.toString(),
  },
  tabTitle: 'Lumi',
}
