export const initialState = {
  id: 0,
  mes_referencia: '',
  uc: '',
  data_vencimento: '',
  data_emissao: '',
  data_leitura_anterior: '',
  data_leitura_atual: '',
  data_proxima_leitura: '',
  nome: '',
  endereco: '',
  cep: '',
  bairro: '',
  cidade: '',
  doc: '',
  uf: '',
  numero: '',
  grupo: 'B',
  subgrupo: '',
  classe: '',
  inscricaoEstadual: '',
  fases: '',
  modalidade: '',
  bandeira: '',
  cosip: '',
  consumo_spt_qt: '',
  consumo_spt_tar: '',
  consumo_spt_tar_crua: '',
  med_ger_tot_quant: '',
  med_ger_spt_quant: '',
  med_ger_fp_quant: '',
  med_ger_p_quant: '',
  consumo_spt_val: '',
  energia_comp_spt_qt: '',
  energia_comp_spt_qt_opt: '',
  energia_comp_spt_tar: '',
  energia_comp_spt_val: '',
  energia_comp_spt_crua: '',
  energia_comp_total_qt: '',
  energia_comp_total_tar: '',
  energia_comp_total_val: '',
  consumo_total_faturado_val: '',
  consumo_total_faturado_tar: '',
  consumo_total_faturado_qt: '',
  valor_total_fatura: '',
  creditos_estoque_spt: '',
  creditos_estoque_tot: '',
  consumo_spt_te_tar: '',
  consumo_spt_tusd_tar: '',
  tot_trib_pis_aliq: '',
  tot_trib_cofins_aliq: '',
  tot_trib_icms_aliq: '18',
  dias_faturados: '',
  ouc_te: '',
  ouc_tusd: '',
  ouc_te_opt: '',
  ouc_tusd_opt: '',
  exist: false,
  autoconsumo: false,
  ajusteGD_tar: '',
  tot_band_val: 0,
  tot_dev_band_val: 0,
}

export const resetState = {
  id: 0,
  mes_referencia: '',
  uc: '',
  data_vencimento: '',
  data_emissao: '',
  ajusteGD_tar: '',
  data_leitura_anterior: '',
  data_leitura_atual: '',
  data_proxima_leitura: '',
  nome: '',
  endereco: '',
  cep: '',
  bairro: '',
  cidade: '',
  doc: '',
  uf: '',
  med_ger_tot_quant: '',
  med_ger_spt_quant: '',
  med_ger_p_quant: '',
  med_ger_fp_quant: '',
  numero: '',
  grupo: 'B',
  subgrupo: '',
  classe: '',
  inscricaoEstadual: '',
  fases: '',
  modalidade: '',
  bandeira: '',
  consumo_spt_tar_crua: '',
  cosip: '',
  consumo_spt_qt: '',
  consumo_spt_tar: '',
  consumo_spt_val: '',
  energia_comp_spt_qt: '',
  energia_comp_spt_qt_opt: '',
  energia_comp_spt_tar: '',
  energia_comp_spt_val: '',
  energia_comp_spt_crua: '',
  energia_comp_total_qt: '',
  energia_comp_total_tar: '',
  energia_comp_total_val: '',
  consumo_total_faturado_val: '',
  consumo_total_faturado_tar: '',
  consumo_total_faturado_qt: '',
  valor_total_fatura: '',
  creditos_estoque_spt: '',
  creditos_estoque_tot: '',
  consumo_spt_te_tar: '',
  consumo_spt_tusd_tar: '',
  tot_trib_pis_aliq: '',
  tot_trib_cofins_aliq: '',
  tot_trib_icms_aliq: '18',
  dias_faturados: '',
  ouc_te: '',
  ouc_tusd: '',
  ouc_te_opt: '',
  ouc_tusd_opt: '',
  exist: false,
  autoconsumo: false,
  tot_band_val: 0,
  tot_dev_band_val: 0,
}
