import { Button } from '../../../../components/Button'
import Modal from '../../../../components/ModalV2'
import { Text, Title, Wrapper, Container, CancelButton } from './styles'

export type SelectedDayType = {
  value?: number
  day: string
  month?: number
  year?: number
}

interface DailyProductionModalProps {
  selectedDay?: SelectedDayType | null
  isOpen: boolean
  onClose: () => void
  onClick: (value?: SelectedDayType | null) => void
}

export function DailyProductionModal({ selectedDay, isOpen, onClose, onClick }: DailyProductionModalProps) {
  const month = selectedDay?.month && (selectedDay?.month + 1).toString().padStart(2, '0')

  return (
    <Modal.Provider onClose={onClose} isOpen={isOpen}>
      <Container style={{ minWidth: '' }}>
        <Title>
          Relatório
        </Title>

        <Text>Confirme para baixar relatório do {month} de {selectedDay?.year}</Text>

        <Wrapper>
          <CancelButton text="Cancelar" action={onClose} />
          <Button text="Baixar" onClick={() => onClick(selectedDay)} />
        </Wrapper>
      </Container>
    </Modal.Provider>
  )
}
