import styled from 'styled-components'
import MenuItem from '@mui/material/MenuItem';
import SelectComponent from '@mui/material/Select'

export const Select = styled(SelectComponent)`
  width: 220px;
  height: 30px;
  font-size: 12px !important;
  background-color: #F1F1F1;
  margin-bottom: 16px;

  & > fieldset {
    border: none;
  }
`;

export const SelectItem = styled(MenuItem)`
  font-size: 12px !important;
  color: ${({theme}) => theme.colors.grayDark} !important;
  padding: 8px 2px 8px 15px !important;
`;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 210,
      marginLeft: 20,
      borderRadius: 8,
    },
  },
};