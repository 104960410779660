import { UC } from "../interfaces/ucs";
import { api } from "./api";

export class UCsService {
  async findById(ucId: number): Promise<UC> {
    const ucResponse = await api.get(`/ucs/${ucId}`)
    return ucResponse.data
  }
}

export const ucsService = new UCsService()