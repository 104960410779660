export const tableColumns = [
  {
    field: 'cliente_nome',
    headerName: 'Cliente',
  },
  {
    field: 'potencia_instalada',
    headerName: 'Potência Instalada Total',
  },
  {
    field: 'consumidoras',
    headerName: 'Unidades consumidoras',
  },
  {
    field: 'geradoras',
    headerName: 'Usinas',
  },
  {
    field: 'fechamentos',
    headerName: 'Boletos emitidos no mês',
  },
]
