import styled from "styled-components";
import Modal from "../../../../components/ModalV2"
import { Button } from '../../../../components/Button'
import { darken } from "polished";

export const Text = styled.p`
  font-size: 1.25rem;
  padding-inline: .325rem;
`

export const Title = styled(Modal.Title)``

export const Wrapper = styled.div`
  display: flex;
  gap: 0.2825rem;
`

export const Container = styled(Modal.Body)`
  min-width: 26rem;

  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`

export const CancelButton = styled(Button)`
  border: ${(props) => `1px solid ${props.theme.colors.darkGreen}`};
  border-radius: 8px;

  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
  }
`
