import { Button } from '../../../../../components/Button'
import { Form, Formik } from 'formik'
import { FormikInput } from '../../../../../components/FormikComponents'
import { Typography } from '@mui/material'
import { countDecimals } from '../../../../../utils/obj-utils'

interface Props {
  onAddField: (key: string, value: string | number) => void
}

export default function AddInvoiceField(props: Props) {
  const { onAddField } = props

  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography variant='body1' style={{ fontWeight: 'bold', marginBottom: '8px' }}>
        Adicionar campos
      </Typography>
      <Formik
        initialValues={{ key: '', value: '' }}
        onSubmit={(values, helper) => {
          const value = values.value
          const decimals = countDecimals(parseFloat(value))
          const updatedValue = !isNaN(Number(value)) ? parseFloat(Number(value).toFixed(decimals)) : value
          onAddField(values.key, updatedValue)
          helper.resetForm()
        }}
      >
        <Form style={{ display: 'flex', gap: '16px' }}>
          <FormikInput showTitle label={'Chave'} mode required halfSize name={'key'} />
          <FormikInput showTitle label={'Valor'} mode required halfSize name={'value'} />
          <Button
            containerStyle={{ margin: 'auto 0 20px 0', width: 'fit-content' }}
            text='Adicionar campo'
            type="submit"
          ></Button>
        </Form>
      </Formik>
    </div>
  )
}
