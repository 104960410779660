import { useEffect, useMemo, useState } from 'react'
import PlantsInvoicingTable from '../PlantsInvoicingTable'
import SearchInput from '../SearchInput'
import TableConfig from '../TableConfig'
import { Configuration, ConfigurationActions, TotalInvoicing } from './styles'
import { TableColumn } from '../../../../interfaces/conference'
import { PlantInvoicing } from '../../../../interfaces/plants'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import { usePagination } from '../../hooks/usePagination'
import PlantsInvoicingDownloadSheetButton from '../PlantsInvoicingDownloadSheetButton'
import './styles.css'
import { handleMoneyFormat } from '../../../../utils/formatMoney'

export const defaultTableColumns: TableColumn[] = [
  {
    field: 'name',
    headerName: 'Usina',
  },
  {
    field: 'ucCode',
    headerName: 'UC da usina',
  },
  {
    field: 'date',
    headerName: 'Referência',
  },
  {
    field: 'finesAndFees',
    headerName: 'Juros/Multas (R$)',
  },
  {
    field: 'invoicing',
    headerName: 'Valor pago (R$)',
  },
]

interface Props {
  plantsInvoicing: PlantInvoicing[]
  date: string
}

export default function PlantsInvoicing(props: Props) {
  const { plantsInvoicing, date } = props

  const {
    itemsPerPage,
    sortParams,
    searchFilter,
    setSearchFilter,
    pagination,
    idsFiltered,
    getPagination,
  } = usePagination<PlantInvoicing>({
    rawData: plantsInvoicing,
    filterBySearch: (plants, searchFilter) => {
      return plants.filter((info) =>
        info.plant.name.toLowerCase().includes(searchFilter.toLowerCase()),
      )
    },
    filterDataByIds: (plants, ids) => {
      return plants.filter((info) => ids.includes(String(info.plant.id)))
    },
    filterBySort: (plants, sort) => {
      return plants.sort((a, b) => {
        switch (sort.field) {
          case 'name': {
            return sort.order === 'asc'
              ? b.plant.name.localeCompare(a.plant.name)
              : a.plant.name.localeCompare(b.plant.name)
          }
          case 'ucCode': {
            return sort.order === 'asc' ? b.plant.code - a.plant.code : a.plant.code - b.plant.code
          }
        }
        return 1
      })
    },
  })

  const [tableColumns, setTableColumns] = useState<TableColumn[]>(defaultTableColumns)

  useEffect(() => {
    setTableColumns((columns) => {
      const updatedColumns = [...columns]
      const downloadField: TableColumn = {
        field: 'actions',
        headerName: 'Relatório',
        headerClassName: 'download-button-header',
        renderCell: (params) => {
          return (
            <PlantsInvoicingDownloadSheetButton
              styles={{ margin: '0 auto' }}
              date={date}
              plantsIds={[params.row.downloadData.plantId]}
              fromDate={params.row.downloadData.date}
            />
          )
        },
        sortable: false,
      }
      const columnIndex = updatedColumns.findIndex((column) => column.field === downloadField.field)
      if (columnIndex !== -1) {
        updatedColumns[columnIndex] = downloadField
      } else {
        updatedColumns.push(downloadField)
      }
      return updatedColumns
    })
  }, [date])

  const checkboxOptions = useMemo(
    () =>
      plantsInvoicing
        ? plantsInvoicing.map((plantInfo) => ({
            id: plantInfo.plant.id,
            label: plantInfo.plant.name,
            checked: idsFiltered.includes(String(plantInfo.plant.id)),
          }))
        : [],
    [idsFiltered, plantsInvoicing],
  )

  const totalInvoicing = useMemo(
    () =>
      handleMoneyFormat(
        pagination.allData.reduce((agg, plantInfo) => (agg += plantInfo.totalInvoicing), 0),
      ),
    [pagination.allData],
  )

  useEffect(() => {
    getPagination({
      page: 1,
      filterByIds: plantsInvoicing.map((info) => String(info.plant.id)),
    })
  }, [plantsInvoicing])

  return (
    <>
      <Configuration>
        <FloatingCheckboxes
          isFilterActive
          label='Usinas'
          checkbox
          options={checkboxOptions}
          selectableAll
          searchable
          submitAction={(checkboxes) => {
            const checkboxesChecked = checkboxes.filter((checkbox) => checkbox.checked)
            getPagination({
              filterByIds: checkboxesChecked.map((checkbox) => String(checkbox.id)),
            })
          }}
        />

        <ConfigurationActions>
          <TotalInvoicing>
            <span>Arrecadação total:</span> {`R$ ${totalInvoicing}`}
          </TotalInvoicing>

          <SearchInput
            value={searchFilter}
            onSearch={(filter) => {
              getPagination({
                searchFilter: filter,
              })
            }}
            onChange={(filter) => setSearchFilter(filter)}
          />

          <PlantsInvoicingDownloadSheetButton
            plantsIds={idsFiltered.length !== plantsInvoicing.length ? idsFiltered : []}
            date={date}
          />

          <TableConfig
            itemsPerPage={itemsPerPage}
            setItemsPerPage={(itemsPerPage) => {
              getPagination({
                limit: itemsPerPage,
              })
            }}
            setTableColumns={setTableColumns}
            tableColumns={tableColumns}
          />
        </ConfigurationActions>
      </Configuration>
      <PlantsInvoicingTable
        tableColumns={tableColumns}
        pagination={pagination}
        sortEvent={
          sortParams
            ? {
                field: sortParams.field,
                order: sortParams.order,
              }
            : undefined
        }
        onSort={(sort) => {
          getPagination({
            sort,
          })
        }}
        onPageChange={(page) => {
          getPagination({
            page,
          })
        }}
      />
    </>
  )
}
