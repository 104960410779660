import React, { ReactNode, useState } from 'react'
import ModalRegisterConsumerUnit from '../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumerUnit'
import { ModalRegisterConsumer } from '../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumer'
import ModalInverter from '../../../../components/Modal/MyUnits/Registers/ModalInverter'
import {
  IconContainer,
  RegisterButton,
  RegisterCardContainer,
  RegisterCardContent,
  TextRegisterCard,
} from './style'
import ModalRegisterPowerPlant from '../../../../components/Modal/MyUnits/Registers/ModalRegisterPowerPlant'

type ResgisterCardProps = {
  children: ReactNode
  icon: ReactNode
}

export function RegisterCard({ icon, children }: ResgisterCardProps) {
  const [openModal, setModalOpen] = useState(false)

  const handleModal = (title: string) => {
    switch (title) {
      case 'Usina Geradora':
        return <ModalRegisterPowerPlant onClose={() => setModalOpen(false)} />
      case 'Consumidor':
        return <ModalRegisterConsumer openModal={openModal} setModalOpen={setModalOpen} />
      case 'UC Beneficiária':
        return <ModalRegisterConsumerUnit onClose={() => setModalOpen(false)} />
      case 'Inversor':
        return <ModalInverter openModal={openModal} setModalOpen={setModalOpen} />
      default:
        break
    }
  }

  return (
    <RegisterCardContainer>
      <RegisterCardContent>
        <IconContainer>{icon}</IconContainer>
        <TextRegisterCard>{children}</TextRegisterCard>
        <RegisterButton
          onClick={() => {
            setModalOpen(!openModal)
          }}
        >
          Cadastrar
        </RegisterButton>
      </RegisterCardContent>
      {handleModal(children as string)}
    </RegisterCardContainer>
  )
}
