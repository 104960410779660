import React, { ReactNode } from 'react'
import { WidgetContainer } from './styles'

interface WidgetProps extends React.ComponentPropsWithoutRef<'div'> {
  backgroundColor?: string
  children: ReactNode
  style?: React.CSSProperties
}

export function Widget({ children, ...rest }: WidgetProps) {
  return <WidgetContainer {...rest}>{children}</WidgetContainer>
}

export default Widget
