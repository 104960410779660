import styled from 'styled-components'

const tooltipMargin = '10px'
const tooltipArrowSize = '6px'

export const Wrapper = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  display: inline-block;
  position: relative;

  /* Absolute positioning */
  .Tooltip-Tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.darkishGreen};
    font-size: 16px;
    font-family: Roboto;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
    animation: 500ms linear fadeIn;
  }

  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${tooltipArrowSize};
    margin-left: calc(${tooltipArrowSize} * -1);
  }

  /* Absolute positioning */
  .Tooltip-Tip.top {
    top: calc(${tooltipMargin} * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: ${({ theme }) => theme.colors.darkishGreen};
  }

  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + ${tooltipMargin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(${tooltipArrowSize} * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${({ theme }) => theme.colors.darkishGreen};
  }

  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    top: 100%;
  }
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: ${({ theme }) => theme.colors.darkishGreen};
  }

  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + ${tooltipMargin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(${tooltipArrowSize} * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${({ theme }) => theme.colors.darkishGreen};
  }
`
