import styled from "styled-components";

export const TextResetPasswordModal = styled.p`
    font-size: 1.125rem;
    margin-bottom: 2rem;
`

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
`

export const ButtonGeneric = styled.button`
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
    padding: 10px 6px 9px 6px;

    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.318125rem;
    letter-spacing: -5%;
    border: none;
    min-width: 152px;
`

export const CancelButton = styled(ButtonGeneric)`
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.htmlGrey};
    border: 0.5px solid #ccc;
`

export const SendButton = styled(ButtonGeneric)`
    background: ${props => props.theme.colors.darkishGreen};
    color: ${props => props.theme.colors.white};

    &:disabled {
         cursor: not-allowed
    }
`

export const InvalidInputText = styled.p`
    color: ${props => props.theme.colors.suportError};
    font-size: 0.7rem;
    margin-top: -8px;
`