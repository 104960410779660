import { useEffect, useState } from 'react'
import { distributorListGet } from '../../../../services/requests/user-requests'
import { useToast } from '../../../../hooks/useToast'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import { CheckboxType } from '../../../../interfaces/checkbox'

interface Props {
  isFilterActive?: boolean
  values: number[]
  onFilter: (values: CheckboxType[]) => void
}

export default function DistributorFilter(props: Props) {
  const { isFilterActive, values, onFilter } = props

  const [distributors, setDistributors] = useState<any[]>([])

  const { toast } = useToast()

  const fetchDistributors = async () => {
    try {
      const distributorsResponse = await distributorListGet()
      setDistributors(distributorsResponse.data)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar distribuidoras',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  return (
    <FloatingCheckboxes
      isFilterActive={typeof isFilterActive !== 'undefined' ? isFilterActive : values.length > 0}
      label='Distribuidoras'
      options={distributors.map((distributor) => ({
        id: distributor.id,
        label: distributor.nome,
        checked: values.length === 0 ? true : values.includes(distributor.id),
      }))}
      selectableAll
      searchable
      submitAction={onFilter}
    />
  )
}
