import { Alert, Autocomplete, CircularProgress, Snackbar, Typography } from '@mui/material'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { FieldArray, Form, Formik } from 'formik'
import { ChangeEvent, KeyboardEvent, useContext, useState } from 'react'
import * as Yup from 'yup'
import { Modal } from '../../..'
import { GlobalContext } from '../../../../../context/global/global'
import { setConsumerListFilterActions } from '../../../../../context/global/reducers/actions'
import { consumerRegisterPost, editConsumer } from '../../../../../services/requests/user-requests'
import { FormikInput } from '../../../../FormikComponents/FormikInput'
import ModalSuccess from '../../../ModalSuccess'
import { initialValues } from '../ModalRegisterConsumer/utils'
import { ButtonsContainer, CancelButton, SendButton } from './styles'
import {
  cellphoneReg,
  formatNumber,
  HandleRegisterFormData,
  HandleRegisterUpdateFormData,
  phoneReg,
  PowerPlantProps,
} from './types'
import { ButtonAdd } from '../ModalRegisterPowerPlant/styles'
import { FormikCheckbox } from '../../../../FormikComponents/FormikCheckbox'
import { InputV2 } from '../../../../InputV2'
import { Search } from '@mui/icons-material'
import { useToast } from '../../../../../hooks/useToast'

const MASKS = {
  // An extra slot to allow the user to keep typing, it's being revalidated with YUP
  CPF: '999.999.999-999',
  CNPJ: '99.999.999/9999-99'
}

export function ModalRegisterConsumer({
  openModal,
  setModalOpen,
  editValues,
  postAction,
  paymentMethods,
}: PowerPlantProps) {
  const { toast } = useToast()

  const [documentMask, setDocumentMask] = useState(
    editValues
      ? editValues.documento_numero.length > 11
        ? MASKS.CNPJ
        : MASKS.CPF
      : MASKS.CNPJ,
  )

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const [phoneMask, setPhoneMask] = useState(
    editValues ? (editValues.fone?.length > 9 ? cellphoneReg : phoneReg) : cellphoneReg,
  )
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<number | null>(editValues?.aggr_pay_metodo_id || null)

  const { dispatch, state } = useContext(GlobalContext)
  const userEmail = state.auth?.email

  const yupSchema = Yup.object({
    name: Yup.string().required('Nome é obrigatório.'),
    email: Yup.string().email('Formato de e-mail incorreto.'),
    emails_pagamentos: Yup
      .array().of(
        Yup.object().shape({
          email: Yup.string()
            .email('Formato de e-mail incorreto.')
            .required('E-mail inserido é obrigatório.'),
        }),
      ).min(1, "É necessário pelo menos um email de pagamentos")
      .required("É necessário ter um email para pagamentos"),
    cpf_cnpj: Yup.string()
      .required('CPF/CNPJ é obrigatório.')
      .test('Valid', 'CPF/CNPJ válido é obrigatório.', (value: any) => {
        if (!value) return true

        value = value?.replace(/\D/g, '')

        if (value.length === 11) {
          value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
        }

        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')

        return value?.length <= 14 ? cpf.isValid(value) : cnpj.isValid(value)
      }),
    fone: Yup.string().transform(formatNumber),
    has_aggregate_payments: Yup.boolean().notRequired().default(false)
  })

  const handleDynamicMask = (value: any) => {
    if (value.length <= 14) {
      setDocumentMask(MASKS.CPF)
      return
    }

    setDocumentMask(MASKS.CNPJ)
  }

  const isKeyValid = (key: any) => {
    if (key == 8 || (key >= 96 && key <= 105) || (key >= 48 && key <= 57)) {
      return true
    }

    return false
  }

  async function handleCloseModal(value: boolean) {
    dispatch(await setConsumerListFilterActions())
    setModalSuccess(value)
    setOpacity(1)
    setModalOpen(value)
    postAction && postAction()
  }

  function verifyEmailPara(editedValues: HandleRegisterUpdateFormData['emails_pagamentos']) {
    const editedEmailsForSend = !editValues
      ? []
      : editValues?.emails_pagamentos?.map(({ email, id }) => {
        const findEditedValue = editedValues?.find((editedEmail: Record<string, unknown>) => editedEmail?.id === id)

        if (!findEditedValue) {
          return {
            type: 'remove',
            id,
          }
        }

        if (findEditedValue.email === email) {
          return {
            type: '',
            id,
          }
        }

        if (findEditedValue.email !== email) {
          return {
            type: 'update',
            id,
            email: findEditedValue.email,
          }
        }
      })

    const addedEmails = editedValues ?
      editedValues.filter(({ id }) => !editValues?.emails_pagamentos?.map((value) => value.id).includes(id))
        .map(({ id, email }) => ({
          type: 'add',
          id,
          email,
        })) : []

    return [...editedEmailsForSend || [], ...addedEmails]
  }

  async function handleRegisterForm(values: HandleRegisterFormData) {
    try {
      setIsLoading(true)

      const payload = {
        cliente_nome: values.name,
        email: values.email,
        emails_pagamentos: verifyEmailPara(values.emails_pagamentos),
        documento_numero: values.cpf_cnpj.replace(/\D+/g, ''),
        fone: values.fone?.replace(/\D+/g, ''),
        has_aggregate_payments: Boolean(values.has_aggregate_payments ?? false),
        aggr_pay_metodo_id: values.aggr_pay_metodo_id,
      }

      if (editValues) {
        await editConsumer({ ...payload, id: editValues.id })
      } else {
        await consumerRegisterPost(payload)
      }

      setOpacity(0)
      setModalSuccess(true)
    } catch (err: any) {
      const errorMessage = 
        typeof err?.response?.data?.message === 'string' ? 
          err?.response?.data?.message : 
          err?.message ?? 'Erro ao cadastrar consumidor.'
      
      toast({
        type: 'error',
        message: errorMessage
      })
      postAction && postAction()
    } finally {
      setIsLoading(false)
      setModalOpen(false)
    }
  }

  function handlePhoneMask(phone: string | undefined) {
    if (phone && phone.replace(/\D+/g, '').length > 9) {
      setPhoneMask(cellphoneReg)
    } else {
      setPhoneMask(phoneReg)
    }
  }

  return (
    <Modal
      title='Cadastro de Consumidor'
      openModal={openModal}
      setModalOpen={setModalOpen}
      opacity={opacity}
      defaultButtons={false}
    >
      {modalSuccess && (
        <ModalSuccess
          openModal={modalSuccess}
          setModalOpen={handleCloseModal}
          buttonColor='darkGreen'
          buttonText='Ok, obrigado'
          text={`Consumidor ${editValues ? 'atualizado' : 'cadastrado'} com sucesso!`}
        />
      )}
      <Formik
        initialValues={{
          name: editValues?.cliente_nome || initialValues.name,
          email: editValues?.email || initialValues.email,
          cpf_cnpj: editValues?.documento_numero || initialValues.cpf_cnpj,
          fone: editValues?.fone || initialValues.fone,
          emails_pagamentos: editValues?.emails_pagamentos || initialValues.emails_pagamentos,
          has_aggregate_payments: editValues?.has_aggregate_payments || initialValues.has_aggregate_payments,
          aggr_pay_metodo_id: editValues?.aggr_pay_metodo_id || initialValues.aggr_pay_metodo_id,
        }}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          await handleRegisterForm(values)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormikInput label='Nome do consumidor' mode required name='name' />
            <FormikInput
              label='CNPJ/CPF'
              mode
              required
              name='cpf_cnpj'
              mask={documentMask}
              onKeyDown={(e: KeyboardEvent) =>
                isKeyValid(e.keyCode)
                  ? values.cpf_cnpj?.length === 14 || values.cpf_cnpj?.length === 13
                    ? handleDynamicMask(values.cpf_cnpj)
                    : null
                  : null
              }
              onPaste={(e: any) => {
                const clipboardData = e.clipboardData
                const pastedData = clipboardData
                  ?.getData('Text')?.toString()?.replace(/\D/, '')

                handleDynamicMask(pastedData)
              }}
              onChange={(props: ChangeEvent<HTMLInputElement>) => {
                const text = props.target?.value ?? ""

                handleDynamicMask(text)
                setFieldValue("cpf_cnpj", text?.trim(), true)
              }}
            />
            <FormikInput label='E-mail de login' mode name='email' />
            <FormikInput
              label='Número de telefone'
              mode
              name='fone'
              textMask={phoneMask}
              onKeyDown={(e: KeyboardEvent) => {
                isKeyValid(e.keyCode) ? handlePhoneMask(values.fone) : null
              }}
              onPaste={(e: any) => {
                const clipboardData = e.clipboardData
                const pastedData = clipboardData.getData('Text').toString().replace(/\D/, '')
                handlePhoneMask(pastedData)
              }}
            />
            {
              (userEmail === 'equipe-sandbox@alkaenergia.com.br' || userEmail === 'equipe@alkaenergia.com.br' || userEmail === 'sandbox-matriz@tangisainvestimentos.com.br' || userEmail === 'matriz@tangisainvestimentos.com.br') && (
                <FormikCheckbox
                  name='has_aggregate_payments'
                  label='Agrupar faturamentos'
                  defaultChecked={Boolean(values.has_aggregate_payments)}
                  mode
                />
              )
            }
            {
              (values.has_aggregate_payments && (userEmail === 'equipe-sandbox@alkaenergia.com.br' || userEmail === 'equipe@alkaenergia.com.br' || userEmail === 'sandbox-matriz@tangisainvestimentos.com.br' || userEmail === 'matriz@tangisainvestimentos.com.br')) && (
                <Autocomplete
                  id='aggr_pay_metodo_id'
                  ListboxProps={{
                    style: {
                      maxHeight: '250px',
                    },
                  }}
                  renderInput={(params) => (
                    <InputV2
                      rightIcon={<Search />}
                      label='Método de Faturamento'
                      {...params}
                    />
                  )}
                  options={paymentMethods ? paymentMethods.map((paymentMethod) => paymentMethod.value) : []}
                  getOptionLabel={(option) => {
                    const optionNumber = Number(option)
                    return !isNaN(optionNumber)
                      ? paymentMethods?.find((paymentMethod) => paymentMethod.value === option)?.label ?? ''
                      : String(option)
                  }}
                  isOptionEqualToValue={(option) => option === selectedPaymentMethodId}
                  value={selectedPaymentMethodId}
                  onChange={(_, value) => {
                    setFieldValue('aggr_pay_metodo_id', value)
                    setSelectedPaymentMethodId(value)
                  }}
                  style={{
                    marginBottom: '16px',
                  }}
                />
              )
            }
            <FieldArray
              name='emails_pagamentos'
              render={(arrayHelpers) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  {values.emails_pagamentos.map((_, index) => (
                    <div
                      key={index}
                      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                    >
                      <FormikInput
                        marginBottom='0'
                        label={`E-mail para faturamento - ${index + 1}`}
                        required
                        mode
                        name={`emails_pagamentos.${index}.email`}
                      />
                      {
                        index !== 0 && (
                          <ButtonAdd
                            style={{ marginTop: '0' }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Typography>Remover o {index + 1}º e-mail</Typography>
                          </ButtonAdd>
                        )
                      }
                    </div>
                  ))}
                  <ButtonAdd
                    onClick={() =>
                      arrayHelpers.push({
                        id: null,
                        email: '',
                      })
                    }
                  >
                    <Typography>Adicionar e-mail para faturamento</Typography>
                  </ButtonAdd>
                </div>
              )}
            />

            <ButtonsContainer>
              <CancelButton onClick={() => setModalOpen(false)}>Cancelar</CancelButton>
              <SendButton type='submit'>
                {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
              </SendButton>
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
