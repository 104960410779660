import type { FC } from 'react'
import { FieldAttributes, FieldHookConfig, useField } from 'formik'
import { CheckboxLabel, CheckboxText, ErrorText, InputCheckbox } from './styles'

interface Props extends FieldAttributes<any> {
  label?: string
  mode?: boolean
  required?: boolean
  onClick?: () => void
  notShowError?: boolean
  disabled?: boolean
  readOnly?: boolean
}

export const FormikCheckbox: FC<Props> = ({
  label,
  mode,
  required,
  onClick,
  notShowError,
  ...props
}) => {
  const [field, meta] = useField(props as string | FieldHookConfig<any>)

  return (
    <CheckboxLabel 
      onClick={onClick} 
      required={required}
      mode={mode}
    >
      <InputCheckbox
        mode={mode}
        {...field}
        {...props}
      />
      <CheckboxText>
        {label}
      </CheckboxText>

      {meta.touched || (meta.error && !notShowError) ? <ErrorText>{meta.error}</ErrorText> : null}
    </CheckboxLabel>
  )
}
