import { ChangeEvent, useState } from 'react'
import Modal from '../../../../../../components/ModalV2'
import { Stack } from '@mui/material'
import { Button } from '../../../../../../components/Button'
import RolePermissions from './components/Permissions'
import { InputV2 } from '../../../../../../components/InputV2'
import { useToast } from '../../../../../../hooks/useToast'
import { permissionsService } from '../../../../../../services/permissions'

const stages = ['Informações', 'Permissões']

interface Props {
  onCreate: () => void
  onClose: () => void
}

export default function CreateRoleModal(props: Props) {
  const { onCreate, onClose } = props

  const [roleName, setRoleName] = useState<string>('')
  const [selectedPermissionsIds, setSelectedPermissionsIds] = useState<number[]>([])

  const [stage, setStage] = useState<number>(1)
  const [isCreatingRole, setCreatingRole] = useState<boolean>(false)

  const { toast } = useToast()

  const createRole = async () => {
    try {
      setCreatingRole(true)

      await permissionsService.createRole({
        name: roleName,
        permissionsIds: selectedPermissionsIds,
      })

      toast({
        message: `Grupo "${roleName}" criado com sucesso`,
        type: 'success',
      })

      onCreate()
      onClose()
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao criar Grupo',
        type: 'error',
      })
    } finally {
      setCreatingRole(false)
    }
  }

  return (
    <Modal.Provider
      style={{ overflowY: 'auto', width: '100%', ...(stage === 2 ? { height: '100%' } : {}) }}
      isOpen
      maxWidth={650}
      onClose={onClose}
    >
      <Modal.Title>
        {`Grupo - ${stages[stage - 1]}`}
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <Stack display={stage !== 1 ? 'none' : 'flex'}>
          <InputV2
            formik={false}
            label='Nome'
            value={roleName}
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) => setRoleName(e.target.value)}
          />
          <Button
            containerStyle={{ width: '20%', marginTop: '16px', marginLeft: 'auto' }}
            text='Avançar'
            onClick={() => {
              if (roleName) {
                setStage((previousStage) => previousStage + 1)
              }
            }}
          />
        </Stack>

        <Stack display={stage !== 2 ? 'none' : 'flex'}>
          <RolePermissions
            selectedPermissionsIds={selectedPermissionsIds}
            setSelectedPermissionsIds={setSelectedPermissionsIds}
          />

          <div
            style={{
              display: 'flex',
              width: '100%',
              position: 'sticky',
              padding: '16px 0px',
              bottom: '0',
              backgroundColor: 'white',
            }}
          >
            <Button
              containerStyle={{ width: '20%' }}
              text='Voltar'
              onClick={() => setStage((previousStage) => previousStage - 1)}
            />
            <Button
              containerStyle={{ width: '20%', marginLeft: 'auto' }}
              text='Criar Grupo'
              disabled={isCreatingRole}
              isLoading={isCreatingRole}
              onClick={createRole}
            />
          </div>
        </Stack>
      </Modal.Body>
    </Modal.Provider>
  )
}
