import styled from 'styled-components'
import { screenSizes } from '../../../styles/media'

type WidgetPowerPlantProps = {
  isSelectedButton?: boolean
}

export const PowerPlantContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.875rem;
`

export const WidgetsContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`

export const WidgetPowerPlant = styled.div<WidgetPowerPlantProps>`
  width: 100%;
  background: ${(props) =>
    props.isSelectedButton
      ? props.theme.colors.darkishGreen
      : 'linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF'};
  background-blend-mode: soft-light, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  cursor: pointer;
`

export const WidgetPowerPlantHeader = styled.div<WidgetPowerPlantProps>`
  height: 6.8125rem;
  border-bottom: 1px solid
    ${(props) => (props.isSelectedButton ? props.theme.colors.white : props.theme.colors.grayLight)};
  padding: 16px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .425rem;

  .icon-container-widget-plant {
    max-width: 56px;
    min-width: 44px;
    width: 100%;

    max-height: 56px;
    min-height: 44px;
    height: 100%;

    aspect-ratio: 1 / 1;

    background-color: ${(props) =>
      props.isSelectedButton ? props.theme.colors.darkishGreen : props.theme.colors.background};
    border-radius: 50%;
    border: ${(props) =>
      props.isSelectedButton ? `2px solid ${props.theme.colors.white}` : `none`};
    box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
      inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      color: ${(props) =>
        props.isSelectedButton ? props.theme.colors.white : props.theme.colors.darkishGreen};
      max-width: 1.725rem;
      max-height: 2rem;
      width: 100%;
      height: 100%;

      aspect-ratio: 1 / 1;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.171875rem;
    letter-spacing: -5%;

    display: flex;
    gap: .625rem;

    align-items: center;
    justify-content: center;

    color: ${(props) =>
      props.isSelectedButton ? props.theme.colors.white : props.theme.colors.htmlGrey};

    strong {
      font-family: ${(props) => props.theme.fonts.family.secondary};
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 600;
      color: ${(props) =>
        props.isSelectedButton ? props.theme.colors.white : props.theme.colors.darkishGreen};
    }
  }

  @media (max-width: ${screenSizes.desktop}) {
    .icon-container-widget-plant {
      max-width: 44px;
      min-width: 36px;
      width: 100%;

      max-height: 44px;
      min-height: 36px;
      height: 100%;
    }

    p {
      font-size: 1rem;
      line-height: 1rem;

      gap: .425rem;

      strong {
        font-size: 1.625rem;
        line-height: 2.625rem;
      }
    }
  }
`

export const Container = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
`

export const ContainerBottom = styled(Container)<{ isSelectedButton: boolean }>`
  padding: 14px 24px 14px 24px;
  align-items: baseline;
  justify-content: flex-start;
  > svg > path {
    fill: ${(props) => (props.isSelectedButton ? props.theme.colors.white : '#4b4b4b')};
  }
`

export const PercentageText = styled.h4<{ isSelectedButton: boolean }>`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: -0.05em;
  color: ${(props) => (props.isSelectedButton ? '#FFFF' : '#4b4b4b')};
  margin-left: 7px;
  margin-right: 7px;
`

export const InRelationToText = styled.h5<{ isSelectedButton: boolean }>`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => (props.isSelectedButton ? '#FFFF' : '#808080')};
`
