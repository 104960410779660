import { useState } from 'react'
import { ButtonsContainer } from './styles'
import {
  GetConsumersFromCsv,
  GetPowerPlantsFromCsv,
  GetQuotasFromCsv,
  GetRecipientUcsFromCsv,
  onboardingService,
} from '../../../services/onboarding'
import PowerPlantsToUpdate from './components/PowerPlantsToUpdate'
import Loading from '../../../components/Loading'
import FileInputButton from './components/FileInputButton'
import ConsumersToUpdate from './components/ConsumersToUpdate'
import RecipientUcsToUpdate from './components/RecipientUcsToUpdate'
import ManagerSelect from './components/ManagerSelect'
import QuotasToUpdate from './components/QuotasToUpdate'
import { useToast } from '../../../hooks/useToast'

type BulkAction = 'POWER_PLANT' | 'CONSUMER' | 'RECIPIENT' | 'QUOTA'

export default function BulkUpdatePage() {
  const [isLoading, setLoading] = useState<boolean>(false)

  const [selectedManagerId, setSelectedManagerId] = useState<number>()

  const [powerPlantsFromCsv, setPowerPlantsFromCsv] = useState<GetPowerPlantsFromCsv['result']>()
  const [consumersFromCsv, setConsumersFromCsv] = useState<GetConsumersFromCsv['result']>()
  const [recipientUcsFromCsv, setRecipientUcsFromCsv] = useState<GetRecipientUcsFromCsv['result']>()
  const [quotasFromCsv, setQuotasFromCsv] = useState<GetQuotasFromCsv['result']>()

  const { toast } = useToast()

  const sendFile = async (action: BulkAction, file: File): Promise<void> => {
    if (!selectedManagerId) {
      return
    }
    try {
      setLoading(true)
      switch (action) {
        case 'POWER_PLANT': {
          const response = await onboardingService.getPowerPlantsFromCsv({
            file,
            managerId: selectedManagerId,
          })
          setPowerPlantsFromCsv(response)
          break
        }
        case 'CONSUMER': {
          const response = await onboardingService.getConsumersFromCsv({
            file,
            managerId: selectedManagerId,
          })
          setConsumersFromCsv(response)
          break
        }
        case 'RECIPIENT': {
          const response = await onboardingService.getRecipientUcsFromCsv({
            file,
            managerId: selectedManagerId,
          })
          setRecipientUcsFromCsv(response)
          break
        }
        case 'QUOTA': {
          const response = await onboardingService.getQuotasFromCsv({
            file,
            managerId: selectedManagerId,
          })
          setQuotasFromCsv(response)
          break
        }
        default: {
          break
        }
      }
    } catch (error) {
      console.error(error)
      toast({
        type: 'error',
        message: 'Erro ao listar dados do CSV'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div>
        <ButtonsContainer>
          <ManagerSelect
            value={selectedManagerId}
            onChange={(_, val) => setSelectedManagerId(val ?? undefined)}
          />

          <FileInputButton
            disabled={!selectedManagerId}
            text='Upload Usinas'
            onUpload={(file) => sendFile('POWER_PLANT', file)}
          />
          <FileInputButton
            disabled={!selectedManagerId}
            text='Upload Consumidores'
            onUpload={(file) => sendFile('CONSUMER', file)}
          />
          <FileInputButton
            disabled={!selectedManagerId}
            text='Upload Beneficiarias'
            onUpload={(file) => sendFile('RECIPIENT', file)}
          />
          <FileInputButton
            disabled={!selectedManagerId}
            text='Upload Cotas'
            onUpload={(file) => sendFile('QUOTA', file)}
          />
        </ButtonsContainer>

        {isLoading && <Loading />}

        {powerPlantsFromCsv &&
          selectedManagerId && (
            <PowerPlantsToUpdate
              managerId={selectedManagerId}
              powerPlantsFromCsv={powerPlantsFromCsv}
              setPowerPlantsFromCsv={setPowerPlantsFromCsv}
            />
          )}
        {consumersFromCsv && selectedManagerId && (
          <ConsumersToUpdate
            managerId={selectedManagerId}
            consumersFromCsv={consumersFromCsv}
            setConsumersFromCsv={setConsumersFromCsv}
          />
        )}
        {recipientUcsFromCsv && selectedManagerId && (
          <RecipientUcsToUpdate
            managerId={selectedManagerId}
            recipientUcsFromCsv={recipientUcsFromCsv}
            setRecipientUcsFromCsv={setRecipientUcsFromCsv}
          />
        )}
        {quotasFromCsv && selectedManagerId && (
          <QuotasToUpdate
            managerId={selectedManagerId}
            quotasFromCsv={quotasFromCsv}
            setQuotasFromCsv={setQuotasFromCsv}
          />
        )}
      </div>
    </>
  )
}
