import styled from 'styled-components'
import { Button } from '../../../components/Button'

export const FormDiv = styled.div`
  max-width: 1250px;
  @media (min-width: 1441px) {
    width: 94vw;
  }
  width: 1280px;
  padding: 32px;
`

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`

export const SaveButton = styled(Button)`
  width: 213px;
  height: 45px;
  border-radius: 8px;
`
