// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState } from 'react'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FormikInput } from '../FormikComponents/FormikInput'
import Widget from '../Widget'
import { ButtonArea, FormDiv, SaveButton } from './styles'
import { handleSubmitData } from './types'
import { changePasswordRequest } from '../../services/requests/user-requests'

export function PasswordForm() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [successMessage, setSuccessMessage] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)

  const yupPasswordSchema = Yup.object({
    current_password: Yup.string().trim().required('Senha atual obrigatória.'),
    new_password: Yup.string().trim().required('Senha nova obrigatória.'),
    confirm_password: Yup.string()
      .trim()
      .required('Senha de confirmação obrigatória.')
      .oneOf(
        [Yup.ref('new_password'), null],
        'A senha de confirmação deve ser a mesma que a nova senha.',
      ),
  })

  async function handleSubmit(values: handleSubmitData, resetForm: () => void) {
    try {
      setIsRequesting(true)
      await changePasswordRequest({
        senha_anterior: values.current_password,
        nova_senha: values.new_password,
        confirmacao_nova_senha: values.confirm_password,
      })

      resetForm()
      setSuccessMessage(true)
      setIsRequesting(false)
    } catch (error) {
      setErrorMessage(error.message)
      setIsRequesting(false)
    }
  }

  return (
    <>
      <Widget>
        <Formik
          initialValues={{
            current_password: '',
            new_password: '',
            confirm_password: '',
          }}
          validationSchema={yupPasswordSchema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          validateOnChange={false}
        >
          {() => (
            <Form>
              <FormDiv>
                <FormikInput
                  label='Senha atual'
                  type='password'
                  name='current_password'
                  mode
                  sizeForm
                />
                <FormikInput label='Nova senha' type='password' name='new_password' mode sizeForm />
                <FormikInput
                  label='Repetir senha'
                  type='password'
                  name='confirm_password'
                  mode
                  sizeForm
                />
                <ButtonArea>
                  <SaveButton
                    text='Salvar'
                    mode='darkGreen'
                    type='submit'
                    containerStyle={{ width: 213 }}
                    isLoading={isRequesting}
                  />
                </ButtonArea>
              </FormDiv>
            </Form>
          )}
        </Formik>
      </Widget>
      <Snackbar
        open={successMessage}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='success'
          onClose={() => setSuccessMessage(false)}
          sx={{
            width: 400,
          }}
        >
          <AlertTitle>Sucesso</AlertTitle>
          <p>Senha alterada com sucesso!</p>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <AlertTitle>{errorMessage}</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  )
}
