import styled from 'styled-components'
import { InvoiceStatusColor } from '../../interfaces/invoices'
import { darken } from 'polished'

export const InvoicesContainer = styled.div`
  width: 100%;
`
export const InvoiceCard = styled.div`
  margin-bottom: 32px;
`
export const InvoiceHeaderCard = styled.div`
  background-color: ${({ theme }) => theme.colors.darkishGreen};
  padding: 8px 14px 14px 14px;
  border-radius: 8px 8px 0px 0px;
`
export const InvoiceHeaderTitleCard = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.family.primary};
  line-height: 19px;
  letter-spacing: -0.05em;
  font-weight: 400;
`
export const InvoiceCardBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const InvoicePercentageBar = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
`
export const CompletePayment = styled.div<{ percentage: number }>`
  background-color: ${({ theme }) => theme.colors.darkishGreen};
  width: ${({ percentage }) => `${percentage}%`};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 20px;
  border-radius: ${({ percentage }) =>
    percentage === 100 ? `8px 8px 8px 8px` : `8px 0px 0px 8px`};
`
export const WaitIssuance = styled.div<{ percentage: number }>`
  background-color: ${({ theme }) => theme.colors.lightGreen};
  width: ${({ percentage }) => `${percentage}%`};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 20px;
`
export const NotLoading = styled.div<{ percentage: number }>`
  background-color: ${({ theme }) => theme.colors.orange};
  width: ${({ percentage }) => `${percentage}%`};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 20px;
  border-radius: ${({ percentage }) =>
    percentage === 100 ? `8px 8px 8px 8px` : `0px 8px 8px 0px`};
`
export const NotDrawn = styled.div<{ percentage: number }>`
  background-color: ${({ theme }) => theme.colors.cream};
  width: ${({ percentage }) => `${percentage}%`};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 20px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 0px;
`
export const InvoiceCardCaptionContainer = styled.div`
  display: flex;
  padding: 0 24px 19px;
  gap: 0 44px;
`
export const InvoiceCardCaption = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 16.41px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.family.primary};
`
export const ColorCaption = styled.div`
  background-color: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 4px;
`

export const PropTitle = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #808080;
`

export const FilterContainer = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TableFilters = styled.div`
  display: flex;
  gap: 0 24px;
`
export const SearchContainer = styled.div`
  display: flex;
  gap: 0 16px;
`

export const ButtonDownloadTable = styled.button`
  background: transparent;
  border: 0;
`
export const ObservationMessage = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};

  &.complete {
    background-color: ${({ theme }) =>
      theme.colors[InvoiceStatusColor['Completas'] as keyof typeof theme.colors]};
  }
  &.waitEmission {
    background-color: ${({ theme }) =>
      theme.colors[InvoiceStatusColor['Aguardando emissão'] as keyof typeof theme.colors]};
  }
  &.dontLoading {
    background-color: ${({ theme }) =>
      theme.colors[InvoiceStatusColor['Não capturadas'] as keyof typeof theme.colors]};
  }
  &.dontObtain {
    background-color: ${({ theme }) =>
      theme.colors[InvoiceStatusColor['Não obtidas'] as keyof typeof theme.colors]};
  }
`

export const PowerPlantCell = styled.button`
  background-color: transparent;
  border: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.htmlGrey};
`

export const StatusCell = styled.span`
  font-size: 14px;
  font-weight: bold;
  &.ok {
    color: ${({ theme }) => theme.colors.ShinyShamrock};
  }
  &.warning {
    color: ${({ theme }) => theme.colors.lightOrange};
  }
`

export const TableFields = styled.div`
  position: absolute;
  background-color: white;
  width: 140px;
  height: 150px;
  top: 35px;
`

export const OptionList = styled.ul<{ showDropDown?: boolean; emptyPlaceholder?: boolean }>`
  width: 124px;
  max-height: 190px;
`

const Div = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 11px;
  background: ${({ theme }) => theme.colors.darkishGreen};
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 130px;
  height: 32px;
  padding: 7px;
  cursor: pointer;
`

export const DownloadDiv = styled.div`
  padding: 0 8px;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5625rem;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.white};
  font-size: 0.925rem;
  border: none;

  background: ${(props) => props.theme.colors.darkishGreen};

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    background: ${(props) => props.theme.colors.darkishGreen};
  }

  img {
    width: 0.925rem;
    aspect-ratio: 1/1;
  }
`

export const OptionItem = styled.li<{ active?: boolean }>`
  list-style-type: none;
  cursor: pointer;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding: 10px 10px 10px 13px;
  border-radius: ${({ active }) => (active ? '8px' : '0px')};
  background-color: ${({ active, theme }) =>
    active ? darken(0.07, theme.colors.white) : theme.colors.white};
  color: ${({ active, theme }) => (active ? theme.colors.darkishGreen : theme.colors.htmlGrey)};

  &:hover {
    border-radius: 8px;
    background-color: #f1f1f1;
  }
`

export const ReturnView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;
`

export const ReturnButton = styled.div`
  width: 24px;
  height: 24px;
  background-color: #ff6600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const ReturnText = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;

  color: #02231c;
`

export const YearButton = styled.div<{ active: boolean }>`
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ active, theme }) =>
    active ? theme.colors.darkishGreen : theme.colors.grayLight};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? darken(0.1, theme.colors.darkishGreen) : darken(0.1, theme.colors.grayLight)};
    cursor: pointer;
  }
`

export const YearText = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;

  color: #ffffff;
`
