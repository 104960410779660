import styled from 'styled-components'
import { screenSizes } from '../../styles/media'

export const ContainerLimits = styled.div`
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;

  overflow-x: hidden;

  @media (max-width: ${screenSizes.desktop}) {
    padding: 16px;
  }
  
`

export const ContainerBottom = styled(Container)`
  padding: 14px 24px 14px 24px;
  align-items: baseline;
`
export const IconContainer = styled.div<{ iconColor?: string }>`
  background: #f9f9fd;
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 100px;

  max-width: 56px;
  min-width: 44px;
  width: 100%;

  max-height: 56px;
  min-height: 44px;
  height: 100%;

  aspect-ratio: 1 / 1;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${screenSizes.desktop}) {
    margin: auto 0;
  }

  path {
    fill: ${({ iconColor }) => iconColor};
  }
`

export const InfoContainer = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;

  & path {
    fill: ${({ theme }) => theme.colors.brightGreen};
  }
`

export const InfoTitle = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;

  letter-spacing: -0.05em;
  text-wrap: nowrap;

  color: ${({ theme }) => theme.colors.htmlGrey};

  @media (max-width: ${screenSizes.desktop}) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const InfoValue = styled.h1`
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.05em;

  display: flex;
  align-items: center;
  text-align: right;
  
  @media (max-width: ${screenSizes.desktop}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
`

export const MeasurementText = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.05em;
  align-self: flex-end;
  padding-bottom: 10px;
  padding-left: 4px;
  color: #808080;
`

export const Divider = styled.div`
  border: 1px solid #d9d9d9;
  width: 100%;
`

export const PercentageText = styled.h4`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: -0.05em;
  color: #4b4b4b;
  margin-left: 7px;
  margin-right: 7px;
`

export const InRelationToText = styled.h5`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #808080;
`
