import { AddOutlined, SettingsOutlined } from '@mui/icons-material'
import { GridColDef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import boltIcon from '../../../assets/bolt.svg'
import DownloadIcon from '../../../assets/download-icon-white.svg'
import powerPlugIcon from '../../../assets/power-plug.svg'
import greenPowerPlugIcon from '../../../assets/green-power-plug.svg'
import walletIcon from '../../../assets/wallet.svg'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import ModalAddQuota from '../../../components/Modal/Credits/ModalAddQuota'
import ModalReplicateQuota from '../../../components/Modal/Credits/ModalReplicateQuota'
import { SearchInput } from '../../../components/SearchInput'
import { CreditsAllocation, CreditsAllocationTableRes } from '../../../services/requests/types'
import {
  creditsAllocationDownload,
  creditsAllocationInfos,
  creditsAllocationsTable,
  deleteQuota,
  powerPlantListGet,
} from '../../../services/requests/user-requests'

import { DateFilter } from '../../../components/Filter/DateFilter'
import Loading from '../../../components/Loading'
import { ModalAddQuotaProps } from '../../../components/Modal/Credits/ModalAddQuota/types'
import ModalDelete from '../../../components/Modal/ModalDelete'
import ModalSuccess from '../../../components/Modal/ModalSuccess'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import WidgetInfo from '../../../components/WidgetInfo'
import { CheckboxType } from '../../../interfaces/checkbox'
import { trauncateFractionAndFormat } from '../../../utils/trauncateFractionAndFormat'
import { getCreditsAllocationColumns } from '../utils/TableData'
import {
  AllocationContainer,
  Cotas,
  DownloadDiv,
  Header,
  PowerPlantContainer,
  TableHeader,
} from './styles'
import { FloatingSingleSelect } from '../../../components/FloatingSingleSelect'
import { monthsList } from '../../../utils/selectDates'
import moment from 'moment'
import { usePermissions } from '../../../hooks/usePermissions'
import { PermissionType } from '../../../interfaces/permissions'
import { TableV2 } from '../../../components/TableV2'
import { Sort } from '../../../interfaces/conference'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'

type InputData = { label: string; id: number }

export default function CreditsAllocations() {
  const { checkPermission } = usePermissions()

  const [_errorMessage, setErrorMessage] = useState(undefined)
  const [allocationInfos, setAllocationInfos] = useState<CreditsAllocation>({
    geracao: {
      percentual: 0,
      valor: 0,
    },
    compensacao: {
      percentual: 0,
      valor: 0,
    },
    saldo_creditos: {
      percentual: 0,
      valor: 0,
    },
    consumo_total: {
      percentual: 0,
      valor: 0,
    },
    cotas_atribuidas: 0,
  })
  const [allocationsTotal, setAllocationsTotal] = useState(1)
  const [allocationsTable, setAllocationsTable] = useState<CreditsAllocationTableRes['data']>([])
  const [powerPlants, setPowerPlants] = useState<InputData[]>([])
  const [search, setSearch] = useState('')
  const [update] = useState(false)
  const [selectedQuota, setSelectedQuota] = useState<ModalAddQuotaProps['editValues']>()
  const [editOpen, setEditOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedPowerPlant, setSelectedPowerPlant] = useState<InputData | null>(null)
  const [sortValues, setSortValues] = useState<Sort>()
  const [selectedId, setSelectedId] = useState<number>(0)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isSuccessOpen, setIsSuccessOpen] = useState(false)
  const [modalAddQuota, setModalAddQuota] = useState(false)
  const [modalReplicateQuota, setModalReplicateQuota] = useState(false)
  const [loading, setLoading] = useState(true)
  const [ready, setReady] = useState(false)
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [pageItemsAmount, setPageItemsAmount] = useState(30)

  useEffect(() => {
    if (powerPlants && powerPlants.length > 0) {
      setSelectedPowerPlant(powerPlants[0])
    }
  }, [powerPlants])

  useEffect(() => {
    powerPlantListGet()
      .then((res) => {
        setPowerPlants(
          res.data.map((item) => ({
            id: item.id,
            label: item.nome,
          })),
        )
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const initialState = async () => {
    if (!selectedPowerPlant) return
    try {
      if (powerPlants.length) {
        setLoading(true)
      }
      const creditsAllocationRes = await creditsAllocationInfos({
        powerPlantIds: [selectedPowerPlant.id],
        month: dateSelected.month,
        year: dateSelected.year,
      })
      setAllocationInfos(creditsAllocationRes.data)

      const allocationsTableRes = await creditsAllocationsTable({
        sort: sortValues?.field,
        order: sortValues?.order,
        year: dateSelected.year,
        month: dateSelected.month,
        filter: search,
        page,
        powerPlantIds: [selectedPowerPlant.id],
        itemsPerPage: pageItemsAmount,
      })
      setAllocationsTotal(allocationsTableRes.data.meta.total ?? 0)
      setAllocationsTable(
        allocationsTableRes.data.data
          ? allocationsTableRes.data.data.map((item) => ({ ...item, id: item.cota_atual_id }))
          : [],
      )
    } catch (error: any) {
      setErrorMessage(
        error.message ?? 'Erro ao buscar os dados de alocação de créditos.',
      )
    } finally {
      if (powerPlants.length) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    initialState()
  }, [update, ready, page, sortValues, dateSelected, selectedPowerPlant, pageItemsAmount])

  const handlePageChange = (nextPage: number) => {
    if (nextPage !== page) {
      setPage(nextPage)
    }
  }

  useEffect(() => {
    if (search !== undefined) {
      const delayDebounce = setTimeout(() => {
        if (search.length >= 3 || search.length === 0) {
          setPage(1)
          setReady(!ready)
        }
      }, 1000)
      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  const deleteFunction = async () => {
    try {
      const response = await deleteQuota(selectedId)
      console.log('response', response.data)
      setIsSuccessOpen(true)
      initialState()
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = (id: number) => {
    setSelectedId(id)
    setIsDeleteOpen(true)
  }

  const handleEdit = (id: number, allocations: CreditsAllocationTableRes['data']) => {
    const quota = allocations.find((item) => item.cota_atual_id === id)
    if (!quota) return

    setSelectedQuota({
      id: quota.cota_atual_id,
      consumerId: quota.consumer_id,
      usinaId: quota.usina_id,
      month: (new Date(quota.mes_referencia).getUTCMonth() + 1).toString(),
      year: new Date(quota.mes_referencia).getFullYear().toString(),
      powerPlantId: quota.uc_id,
      quota: quota.cota_atual,
      ucName: quota.uc_nome,
      ucNumber: quota.uc_numero,
      ucId: quota.uc_id,
    })
    setEditOpen(true)
  }

  const handleAdd = () => {
    setModalAddQuota(true)
  }

  const handleReplicateQuotas = () => {
    setModalReplicateQuota(true)
  }

  const [tableCols, setTableCols] = useState<GridColDef[]>([])

  useEffect(() => {
    if (!allocationsTable?.length) return

    const hasEditPermission = checkPermission(PermissionType.CREDITS_ALLOCATION_UPDATE)
    const hasDeletePermission = checkPermission(PermissionType.CREDITS_ALLOCATION_DELETE)

    const columns = getCreditsAllocationColumns({
      allocations: allocationsTable,
      deleteHandler: handleDelete,
      editHandler: handleEdit,
      permissions: {
        hasEditPermission,
        hasDeletePermission
      }
    })

    setTableCols((state) => {
      const getFromState = (name?: string) => state.find(item => item.headerName === name)

      const newColumns = columns.reduce((acc, item) => {
        const name = item.headerName
        const oldItem = getFromState(name)

        item.hide = oldItem?.hide ?? item?.hide
        acc.push(item)
        return acc
      }, [] as GridColDef[])

      return newColumns
    })
  }, [allocationsTable])

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  const handleDownloadButton = async () => {
    if (!selectedPowerPlant) return
    await creditsAllocationDownload({
      usina_id: selectedPowerPlant.id,
      mes: dateSelected.month,
      ano: dateSelected.year,
      sort: sortValues?.field,
      order: sortValues?.order,
      filter: search,
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `alocacao_de_creditos_${dateSelected.month + '_' + dateSelected.year
          }.xlsx`
        document.body.appendChild(element)
        element.click()
      })
      .catch((error) => {
        console.log(error)
        setErrorMessage(
          error.message ?? 'Erro ao baixar arquivo de alocação de créditos.',
        )
      })
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const hasCreatePermission = checkPermission(PermissionType.CREDITS_ALLOCATION_CREATE)

  if (loading) return <Loading />

  function getMonth(value: { month: number; year: number }, subtract?: boolean) {
    if (subtract) {
      const currentNewMonth = moment(`${value.year}-${value.month}-01`)
        .add(-2, 'month')
        .format('YYYY-MM')
      const [year, month] = currentNewMonth.split('-')

      return (
        <>
          {monthsList.filter((val) => val.value === String(parseInt(month)))[0].label}{' '}
          <span>{year}</span>
        </>
      )
    } else {
      return (
        <>
          {monthsList.filter((val) => val.value === String(value.month))[0].label}{' '}
          <span>{value.year}</span>
        </>
      )
    }
  }

  return (
    <AllocationContainer>
      {allocationInfos && !loading && (
        <>
          <div>
            <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
          </div>
          <Header>
            <WidgetInfo
              icon={boltIcon.toString()}
              title='Energia Gerada'
              value={Math.trunc(allocationInfos.geracao.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={allocationInfos.geracao.percentual ?? 0}
            />
            <WidgetInfo
              title='Energia Consumida'
              icon={powerPlugIcon.toString()}
              value={Math.trunc(allocationInfos.consumo_total.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={allocationInfos.consumo_total.percentual ?? 0}
            />
            <WidgetInfo
              icon={greenPowerPlugIcon.toString()}
              title='Energia Compensada'
              value={Math.trunc(allocationInfos.compensacao.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={allocationInfos.compensacao.percentual ?? 0}
            />
            <WidgetInfo
              icon={walletIcon.toString()}
              title='Saldo de Créditos'
              value={Math.trunc(allocationInfos.saldo_creditos.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={allocationInfos.saldo_creditos.percentual ?? 0}
            />
            {/* <Widget value={parseFloat(allocationInfos.cotas_atribuidas.toFixed(2))}>
              <label>Cotas atribuídas</label>
              <label>
                {trauncateFractionAndFormat(
                  formatter.formatToParts(allocationInfos.cotas_atribuidas * 100),
                  2,
                )}
                %
              </label>
            </Widget> */}
          </Header>
          <TableHeader>
            <div>
              <FloatingSingleSelect
                isFilterActive={powerPlants.some((powerplant) => powerplant === selectedPowerPlant)}
                value={selectedPowerPlant}
                data={powerPlants}
                label='Usinas'
                submitAction={(option) => setSelectedPowerPlant(option)}
              />
              <PowerPlantContainer>
                <span>Usina: {selectedPowerPlant?.label}</span>
                {/* <GenericTooltip
                  content='A cobrança será enviada para os e-mails listados abaixo.'
                  direction='right'
                >
                  <InfoIcon
                    style={{ height: '19px', width: '20px', fill: Theme.colors.darkishGreen }}
                  />
                </GenericTooltip> */}
              </PowerPlantContainer>
            </div>
            <div style={{ alignItems: 'center' }}>
              <Cotas>
                <span>Créditos alocados:</span>{' '}
                {trauncateFractionAndFormat(
                  formatter.formatToParts(allocationInfos.cotas_atribuidas * 100),
                  2,
                )}
                %
              </Cotas>
              <SearchInput
                value={search}
                setValue={setSearch}
                style={{ width: 180, marginRight: 16 }}
              />
              <DownloadDiv style={{ marginRight: 16 }}>
                <img
                  src={`${DownloadIcon}`}
                  alt='Download Button'
                  onClick={() => handleDownloadButton()}
                />
              </DownloadDiv>
              <FloatingCheckboxes
                pagination={{
                  selected: pageItemsAmount,
                  setSelected: setPageItemsAmount,
                  options: [15, 20, 30, 50, 100],
                }}
                isFilterActive={false}
                title='Configurar Tabela'
                styles={{ marginRight: 16 }}
                options={tableCols.map((col) => ({
                  label: col.headerName || '',
                  checked: !col.hide,
                  id: Math.random(),
                }))}
                customActionComponent={
                  <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
                }
                submitBtnText='Salvar'
                submitAction={handleSelectTableColumns}
              />
              {/* <ActionTableButton onClick={handleReplicateQuotas} icon={<AddOutlined />}>
                Replicar cotas
              </ActionTableButton> */}
              {hasCreatePermission && (
                <ActionTableButton
                  styles={{ marginLeft: 2, fontSize: 15 }}
                  onClick={handleAdd}
                  icon={<AddOutlined />}
                >
                  Adicionar
                </ActionTableButton>
              )}
            </div>
          </TableHeader>
          <TableV2
            columns={tableCols}
            rows={allocationsTable}
            alignText='center'
            sort={sortValues}
            onSort={(sort) => {
              setSortValues(sort)
              setPage(1)
            }}
            serverSorting
            messageNoRow='Nenhum dado'
          >
            <TablePagination
              pageInfo={{
                totalCount: allocationsTotal,
                currentPage: page,
                totalPages: Math.ceil(allocationsTotal / pageItemsAmount),
                limit: pageItemsAmount
              }}
              currentPageItemCount={pageItemsAmount}
              onChange={handlePageChange}
            />

          </TableV2>
        </>
      )}
      {modalAddQuota && (
        <ModalAddQuota
          openModal={modalAddQuota}
          setModalOpen={setModalAddQuota}
          postAction={initialState}
        />
      )}
      {editOpen && (
        <ModalAddQuota
          openModal={editOpen}
          setModalOpen={setEditOpen}
          editValues={selectedQuota}
          postAction={initialState}
        />
      )}

      {modalReplicateQuota && (
        <ModalReplicateQuota
          openModal={modalReplicateQuota}
          setModalOpen={setModalReplicateQuota}
          powerPlants={powerPlants.map((item) => ({ label: item.label, value: item.id }))}
        />
      )}

      {isDeleteOpen && (
        <ModalDelete
          openModal={isDeleteOpen}
          setModalOpen={setIsDeleteOpen}
          buttonColor='darkGreen'
          text='Tem certeza que deseja excluir a cota?'
          action={deleteFunction}
        />
      )}
      {isSuccessOpen && (
        <ModalSuccess
          buttonText='Ok, obrigado'
          buttonColor='darkGreen'
          text='Cota excluída com sucesso!'
          openModal={isSuccessOpen}
          setModalOpen={setIsSuccessOpen}
        />
      )}
    </AllocationContainer>
  )
}
