import React, { Dispatch, SetStateAction, useContext } from 'react'
import { ButtonsDiv } from '../charts/ConsumerMainChart/styles'
import { MenuProps, Select, SelectItem } from '../FloatingSelects/styles'
import { SelectFilter } from '../Select'
import OpenIcon from '../../assets/open.svg'
import { GlobalContext } from '../../context/global/global'
import { monthsList } from '../../utils/selectDates'

interface IFilterProps {
  selects: {
    placeholder?: string
    value: string | undefined
    setValue: Dispatch<SetStateAction<any>>
    datas: {
      label: string
      value: string
    }[]
  }[]
  selectedDate: {
    month: number
    year: number
  }
  submitAction: () => void
}

const Filter = ({ selects, selectedDate, submitAction }: IFilterProps) => {
  const { Theme } = useContext(GlobalContext)
  function months(locales?: string | string[]) {
    const year = new Date().getFullYear()
    const monthList = new Array(12).map((_, i) => i)
    const formatter = (format: 'long' | 'short' = 'long') =>
      new Intl.DateTimeFormat(locales, {
        month: format,
      })

    const getMonthName = (monthIndex: number) => {
      const longFormat = formatter('long').format(new Date(year, monthIndex))
      const long = longFormat[0].charAt(0).toUpperCase() + longFormat.slice(1)

      return {
        long,
        short: formatter('short').format(new Date(year, monthIndex)).replace('.', '').toUpperCase(),
      }
    }

    return monthList.map(getMonthName)
  }

  function years() {
    const currentYear = new Date().getFullYear()
    const yearsUntil2000 = new Array(currentYear - 1999)
      .map((_, i) => i)
      .map((yearIndex) => currentYear - yearIndex)

    return yearsUntil2000
  }

  return (
    <SelectFilter
      isFilterActive={false}
      submitAction={submitAction}
      Customize={() => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '0.5rem',
              gap: '0.5rem',
            }}
          >
            <span
              style={{
                fontWeight: '700',
                fontSize: '26px',
                color: Theme.colors.darkishGreen,
                cursor: 'pointer',
              }}
            >
              {monthsList[selectedDate.month - 1].label}{' '}
              <span
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  color: Theme.colors.grey,
                  cursor: 'pointer',
                }}
              >
                {selectedDate.year}
              </span>
            </span>
          </div>
        )
      }}
      ModalComponent={() => {
        return (
          <>
            {selects.map((select, index) => (
              <Select
                key={index}
                value={select.value}
                onChange={(e) => select.setValue(e.target.value)}
                IconComponent={() => <img style={{ marginRight: 15 }} src={OpenIcon.toString()} />}
                MenuProps={MenuProps}
                sx={{ color: Theme.colors.darkishGreen, width: 200 }}
              >
                {select.datas.map((data, index) => (
                  <SelectItem
                    key={index}
                    value={data.value}
                    onMouseDown={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                  >
                    {data.label}
                  </SelectItem>
                ))}
              </Select>
            ))}
          </>
        )
      }}
    />
  )
}

export default Filter
