import styled from "styled-components";
import { Theme } from "../../../../styles/theme";

export const UsersCount = styled.p`
  font-size: 16px;
  color: ${Theme.colors.white};
  background-color: ${Theme.colors.darkishGreen};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: default;
  letter-spacing: 0.001px;
  display: flex;
  align-items: center;
  font-weight: 600;
`
