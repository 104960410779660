// Valor arrendamento

import { FormikProps } from 'formik'
import { plantListGet } from '../../../../../../../services/requests/user-requests'
import { useEffect, useMemo, useState } from 'react'
import { useToast } from '../../../../../../../hooks/useToast'
import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../../InputV2'
import { Search } from '@mui/icons-material'

interface Props {
  formik: FormikProps<any>
}

export default function PowerPlantSelect(props: Props) {
  const { formik } = props

  const { errors, values, setFieldValue } = formik

  const inverterId = useMemo(() => values.inversor_id, [values.inversor_id])

  const [powerPlants, setPowerPlants] = useState<{ id: number; nome: string }[]>([])
  const [selectedPowerPlantId, setSelectedPowerPlantId] = useState<number | null>(
    values.planta_ids,
  )

  const { toast } = useToast()

  const fetchPowerPlant = async () => {
    if (!inverterId || isNaN(inverterId)) {
      return
    }
    try {
      const response = await plantListGet({
        params: {
          fornecedor_id: inverterId,
        },
      })
      const plantsData = response.data.map((plant: any) => {
        return {
          id: plant.id,
          nome: plant.nome,
        }
      })
      setPowerPlants(plantsData)
    } catch (error) {
      toast({
        message: 'Erro ao carregar usinas geradoras',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchPowerPlant()
  }, [inverterId])

  return (
    <Autocomplete
      id='planta_ids'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          label='Planta'
          error={!!errors[params.id]}
          {...params}
        />
      )}
      options={powerPlants.map((consumer) => consumer.id)}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? powerPlants.find((consumer) => consumer.id === option)?.nome ?? ''
          : String(option)
      }}
      isOptionEqualToValue={(option) => option === selectedPowerPlantId}
      value={selectedPowerPlantId}
      onChange={async (_, value) => {
        setSelectedPowerPlantId(value)
        setFieldValue('planta_ids', value, true)
      }}
    />
  )
}
