import { PaginationQuery } from '../interfaces/pagination'
import { UsersPagination } from '../interfaces/users'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

export interface ListUsers {
  params: PaginationQuery
  result: UsersPagination
}

export interface CreateUser {
  params: {
    name: string
    email: string
    password: string
    clientId: number
  }
  result: void
}

export class UsersService {
  async listUsers(params: ListUsers['params']): Promise<ListUsers['result']> {
    const { filter, itemsPerPage, page, sort } = params

    const queryParams = {
      ...(sort
        ? {
            order: sort.order,
            field: sort.field,
          }
        : {}),
      ...(filter && Object.keys(filter).length > 0
        ? {
            filter: convertObjectToQueryParams(filter),
          }
        : {}),
      ...(itemsPerPage ? { itemsPerPage } : {}),
      ...(page ? { page } : {}),
    }

    const users = await api.get('/users', {
      params: queryParams
    })
    return users.data
  }
  async createUser(params: CreateUser['params']): Promise<CreateUser['result']> {
    await api.post('/super/users', params)
  }
}

export const usersService = new UsersService()
