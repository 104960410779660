import React, { Dispatch, SetStateAction, useContext } from 'react'
import OpenIcon from '../../assets/open.svg'
import { GlobalContext } from '../../context/global/global'
import { FloatingFilterWrapper } from '../FloatingFilterWrapper'
import { MenuProps, Select, SelectItem } from './styles'
interface Props {
  label?: string
  submitBtnText?: string
  isFilterActive: boolean
  selects: {
    placeholder?: string
    value: string | undefined
    setValue: Dispatch<SetStateAction<any>>
    datas: {
      label: string
      value: string
    }[]
  }[]
  submitAction?: () => void
  styles?: React.CSSProperties
}

export const FloatingSelects: React.FC<Props> = ({ selects, isFilterActive, styles, ...rest }) => {
  const { Theme } = useContext(GlobalContext)

  return (
    <FloatingFilterWrapper {...rest} styles={styles} isFilterActive={isFilterActive}>
      {selects.map((select, index) => (
        <Select
          key={index}
          value={select.value}
          onChange={(e) => select.setValue(e.target.value)}
          IconComponent={() => <img style={{ marginRight: 15 }} src={OpenIcon.toString()} />}
          MenuProps={MenuProps}
          sx={{ color: Theme.colors.darkishGreen, width: 200 }}
        >
          {select.datas.map((data, index) => (
            <SelectItem
              key={index}
              value={data.value}
              onMouseDown={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
            >
              {data.label}
            </SelectItem>
          ))}
        </Select>
      ))}
    </FloatingFilterWrapper>
  )
}
