import { useRef } from "react"
import { Button } from "../../../../../components/Button"
import { ButtonInterface } from "../../../../../components/Button/types"

interface Props extends ButtonInterface {
  text: string
  onUpload: (file: File) => void
}

export default function FileInputButton(props: Props) {
  const { text, onUpload, ...rest } = props
  
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <Button
        {...rest}
        containerStyle={{ width: 'fit-content' }}
        text={text}
        onClick={() => inputRef.current?.click()}
      />
      <input
        ref={inputRef}
        type='file'
        style={{ display: 'none' }}
        onChange={(e) => {
          const file = e.target.files?.item(0)
          if (file) {
            onUpload(file)
            if (inputRef.current) {
              inputRef.current.value = ''
            }
          }
        }}
      />
    </>
  )
}
