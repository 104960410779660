import styled from 'styled-components'

export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const WidgetsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 0 16px;

  & path {
    fill: ${({ theme }) => theme.colors.darkGreen};
  }
`

export const SettingsContainer = styled.div`
  margin-top: 19px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 16px;
`

export const FiltersContainer = styled.div`
  display: flex;
  gap: 0 16px;
`

export const ConfigButtonsColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
`

export const StatusText = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;

  &.charged {
    color: ${({ theme }) => theme.colors.quartzGrey};
  }

  &.notCharged {
    color: ${({ theme }) => theme.colors.orange};
  }

  &.received {
    color: ${({ theme }) => theme.colors.appleGreen};
  }

  &.expired {
    color: ${({ theme }) => theme.colors.suportError};
  }
`

export const RowText = styled.span`
  font-size: 14px;
  line-height: 16px;
`

export const DisabledAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.suportError};
`

export const PositiveAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
`
