import styled from 'styled-components'
import ChevronDown from '../../assets/chevron-down.svg'
import ChevronUp from '../../assets/icons/chevron-up.svg'

const chevronDown = ChevronDown as unknown as string
const chevronUp = ChevronUp as unknown as string

export const SelectContainer = styled.div`
  padding-bottom: 16px;
  position: relative;
`

export const SelectInput = styled.div<{ showDropDown?: boolean }>`
  height: 45px;
  background: #f1f1f1;
  border-radius: 8px;
  border: none;
  padding-left: 15px;
  padding-right: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 420px;
  cursor: pointer;
  appearance: none;
  background: ${({ showDropDown }) =>
    showDropDown
      ? `url(${chevronUp}) no-repeat right #f1f1f1`
      : `url(${chevronDown}) no-repeat right #f1f1f1`};
  background-position-x: 98%;
`

export const OptionList = styled.ul<{ showDropDown?: boolean; emptyPlaceholder?: boolean }>`
  position: absolute;
  margin-top: ${({ emptyPlaceholder }) => (emptyPlaceholder ? '8px' : '26px')};
  left: 0px;
  min-height: 50px;
  width: 420px;
  opacity: 1;
  visibility: visible;
  z-index: 10;
  background: #ffffff;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  height: 150px;
  overflow-y: scroll;

  ${({ showDropDown }) =>
    !showDropDown &&
    `
    min-height: 0;
    opacity: 0;
    visibility: hidden;
  `}
`

export const OptionItem = styled.li<{ active?: boolean }>`
  list-style-type: none;
  cursor: pointer;

  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  padding: 18px 0px 18px 16px;

  color: ${({ active, theme }) => (active ? theme.colors.darkishGreen : theme.colors.quartzGrey)};

  &:first-child {
    padding: 9px 0px 18px 16px;
  }

  &:last-child {
    border-bottom: none;
    padding: 18px 0px 19px 16px;
  }
  &:hover {
    background-color: #d9d9d9;
  }
`

export const SelectedItem = styled.div`
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: ${({ theme }) => theme.colors.quartzGrey};
`
