import styled from '@emotion/styled'
import { Theme } from '../../../../styles/theme'

export const Configuration = styled.div`
  display: flex;
  margin: 32px 0;
`

export const ConfigurationActions = styled.div`
  display: flex;
  gap: 0 16px;
  margin-left: auto;
`

export const DownloadButtonHeader = styled.div`
  margin: 0 auto;
`

export const TotalInvoicing = styled.p`
  font-size: 18px;
  color: ${Theme.colors.white};
  background-color: ${Theme.colors.darkishGreen};
  padding: 6px 8px;
  border-radius: 4px;
  margin-right: 30px;
  letter-spacing: 0.001px;
  cursor: default;

  span {
    font-weight: 600;
  }
`