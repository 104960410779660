import { useEffect, useState } from 'react'
import { ListOrchestratorResults, orchestratorService } from '../../../../../services/orchestrator'
import { useToast } from '../../../../../hooks/useToast'
import { FloatingCheckboxes } from '../../../../../components/FloatingCheckboxes'
import { CheckboxType } from '../../../../../interfaces/checkbox'

interface Props {
  values: string[]
  onFilter: (values: CheckboxType[]) => void
}

export default function MultiStatusFilter(props: Props) {
  const { values, onFilter } = props

  const [results, setResults] = useState<ListOrchestratorResults['result']>([])

  const { toast } = useToast()

  const fetchResults = async () => {
    try {
      const resultsResponse = await orchestratorService.listResults()
      setResults(resultsResponse)
    } catch (error) {
      console.log(error)
      toast({
        type: 'error',
        message: 'Erro ao listar status',
      })
    }
  }

  useEffect(() => {
    fetchResults()
  }, [])

  return (
    <FloatingCheckboxes
      isFilterActive
      label='Ultimo Status'
      options={results.map((result) => ({
        id: result,
        label: result,
        checked: values.length === 0 ? true : values.includes(result),
      }))}
      selectableAll
      searchable
      submitAction={onFilter}
    />
  )
}
