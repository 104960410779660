import { useEffect, useState } from 'react'
import { useToast } from '../../../../hooks/useToast'
import { ListRoles, permissionsService } from '../../../../services/permissions'
import { TableV2 } from '../../../../components/TableV2'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { Edit } from '@mui/icons-material'
import RoleModal from './components/RoleModal'
import { Button } from '../../../../components/Button'
import CreateRoleModal from './components/CreateRoleModal'
import Loading from '../../../../components/Loading'

export default function RolesPage() {
  const { toast } = useToast()

  const [roles, setRoles] = useState<ListRoles['result']>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const [selectedRoleId, setSelectedRoleId] = useState<number>()
  const [isCreatingNewRole, setCreatingNewRole] = useState<boolean>()

  const listRoles = async () => {
    try {
      setLoading(true)
      const rolesResponse = await permissionsService.listRoles()
      setRoles(rolesResponse)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar grupos',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listRoles()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {selectedRoleId && (
        <RoleModal roleId={selectedRoleId} onClose={() => setSelectedRoleId(undefined)} />
      )}

      {isCreatingNewRole && (
        <CreateRoleModal onCreate={listRoles} onClose={() => setCreatingNewRole(false)} />
      )}

      <Stack direction='row'>
        <Button
          containerStyle={{ width: 'fit-content' }}
          text='Novo grupo'
          onClick={() => setCreatingNewRole(true)}
        />
      </Stack>

      {isLoading && <Loading />}

      {!isLoading && (
        <TableV2
          alignText='center'
          rows={roles.map((role) => ({
            id: role.id,
            name: role.name,
          }))}
          columns={[
            {
              field: 'name',
              headerName: 'Nome',
            },
            {
              field: 'actions',
              headerName: '',
              sortable: false,
              renderCell: (params: GridRenderCellParams<any, any, any>) => {
                return (
                  <Stack direction='row' gap={2}>
                    <Tooltip title={<p style={{ fontSize: 15 }}>Editar</p>} placement='bottom'>
                      <IconButton onClick={() => setSelectedRoleId(params.row.id)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )
              },
            },
          ]}
        />
      )}
    </>
  )
}
