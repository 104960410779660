import Widget from '../../../../components/Widget'
import Chart from 'react-apexcharts'
import { ChartBody, HeaderContainer, PieChartLegend, TitleDiv, TitleText } from './styles'
import { PlantInvoicing } from '../../../../interfaces/plants'
import { useChartInteraction } from '../../hooks/useChartInteraction'
import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { handleMoneyFormat } from '../../../../utils/formatMoney'
import './style.css'

interface Props {
  chartData: PlantInvoicing[]
}

export default function PlantsInvoicingPieChart(props: Props) {
  const { chartData } = props

  const chartDataFormatted = useMemo(
    () =>
      chartData.map((plantInfo) => ({
        name: plantInfo.plant.name,
        data: parseFloat(
          plantInfo.months.reduce((agg, date) => (agg += date.invoicing), 0).toFixed(2),
        ),
      })),
    [chartData],
  )

  const { apexSeries, colors, handleSeriesChange, selectedSeries } = useChartInteraction({
    chartData: chartDataFormatted,
    colors: [
      "#ffd660",
      "#ff9c00",
      "#ff5a00",
    ]
  })

  return (
    <Widget
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '40%',
        height: 'auto',
        overflowY: 'auto',
        width: 'fit-content',
      }}
    >
      <ChartBody id='plants-invoicing-pie'>
        <HeaderContainer>
          <TitleDiv>
            <TitleText>Visão geral de faturamento</TitleText>
          </TitleDiv>
        </HeaderContainer>

        {chartData.length === 0 ? (
          <Typography style={{ textAlign: 'center', marginTop: '32px' }}>Não há dados</Typography>
        ) : (
          <>
            <Chart
              options={{
                labels: apexSeries.map(serie => serie.name),
                legend: {
                  show: false,
                },
                tooltip: {
                  fillSeriesColor: false,
                  y: {
                    title: {
                      formatter(seriesName) {
                        return `${seriesName}: `
                      },
                    },
                    formatter: (val) => {
                      return `R$ ${handleMoneyFormat(val)}`
                    },
                  },
                },
                dataLabels: {
                  style: {
                    fontSize: '18px'
                  },
                },
                colors,
              }}
              series={apexSeries.map((serie) => serie.data)}
              type='donut'
              height={400}
            />

            <PieChartLegend
              colors={colors}
              legend={chartData.map((plantInfo) => plantInfo.plant.name)}
              selectedLegends={selectedSeries}
              onClick={handleSeriesChange}
            />
          </>
        )}
      </ChartBody>
    </Widget>
  )
}
