import { Checkbox } from '@mui/material';
import styled from 'styled-components'

export const CheckboxLabel = styled.label<{ mode?: boolean; required?: boolean }>`
  color: ${({ mode, theme }) => (mode ? theme.colors.quartzGrey : theme.colors.white)};
  margin-bottom: 8px;
  padding-left: ${({ mode }) => (mode ? `0px` : `15px`)};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: -0.05em;

  ${({ required, theme }) =>
    required &&
    `::after {
    content: '*';
    color: ${theme.colors.orange};`}
`

export const CheckboxText = styled.span``

export const InputCheckbox = styled(Checkbox)<{
  mode?: boolean
  $isToggled?: boolean
}>`
  outline: none !important;
  
  &.MuiCheckbox-root {
    padding: 0px !important;
  }

  svg {
    color: #adadad;
  }

  border-radius: 4px;
  color: ${({ mode }) => (mode ? `black` : `white`)};

  aspect-ration: 1 / 1;
`

export const ErrorText = styled.h5`
  margin-top: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding-left: 4px;
  color: ${(props) => props.theme.colors.suportError};
`
