import { darken } from 'polished'
import styled from 'styled-components'
import { screenSizes } from '../../../styles/media'

export const AllocationContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const Header = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${screenSizes.large}) {
    gap: .725rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

`

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 19px 0 16px;

  & > div {
    display: flex;
  }
`

const Div = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 11px;
  background: ${({ theme }) => theme.colors.darkishGreen};
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 130px;
  height: 32px;
  padding: 7px;
  cursor: pointer;
`

export const DownloadDiv = styled(Div)`
  margin-left: 8px;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`
