import styled from 'styled-components'
import { darken } from 'polished'

export const InvoicesContainer = styled.div`
  width: 100%;
`
export const FilterContainer = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const TableFilters = styled.div`
  display: flex;
  gap: 0 24px;
`
export const SearchContainer = styled.div`
  display: flex;
  gap: 0 16px;
`
export const YearButton = styled.div<{ active: boolean }>`
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ active, theme }) =>
    active ? theme.colors.darkishGreen : theme.colors.grayLight};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? darken(0.1, theme.colors.darkishGreen) : darken(0.1, theme.colors.grayLight)};
    cursor: pointer;
  }
`
export const YearText = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;

  color: #ffffff;
`
