import { CircularProgress, IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Ticket } from '../../../../../../interfaces/tickets'
import { Download, InfoRounded, InsertDriveFile } from '@mui/icons-material'
import { FileName, HeaderContainer, HeaderTitle } from './styles'
import { ticketsService } from '../../../../../../services/tickets'
import { useState } from 'react'
import { Theme } from '../../../../../../styles/theme'

interface Props {
  files: Ticket['files']
}

export default function FileList(props: Props) {
  const { files } = props

  const [downloadingFileId, setDownloadingFileId] = useState<string>('')

  const downloadFile = async (fileId: string) => {
    const fileFound = files.find((file) => file.id === fileId)
    if (!fileFound) {
      return
    }
    try {
      setDownloadingFileId(fileId)
      const blob = await ticketsService.downloadFile({ fileId })
      const url = window.URL.createObjectURL(new Blob([blob]))
      const element = document.createElement('a')
      element.href = url
      element.download = fileFound.originalFileName
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.log(`Erro ao baixar arquivo do ticket: ${error}`)
    } finally {
      setDownloadingFileId('')
    }
  }

  return (
    <div>
      <HeaderContainer>
        <InfoRounded />
        <HeaderTitle variant='subtitle2'>Arquivos anexados:</HeaderTitle>
      </HeaderContainer>
      {files.map((file) => (
        <ListItem
          key={`file-to-upload-${file.id}`}
          style={{
            borderRadius: '8px',
            margin: '8px 0',
            border: '1px solid #f0f0f0',
          }}
        >
          <ListItemIcon style={{ minWidth: 'unset', marginRight: '16px' }}>
            <InsertDriveFile />

          </ListItemIcon>
          <ListItemText primary={<FileName variant='body1'>{file.originalFileName}</FileName>} />
          <IconButton onClick={() => downloadFile(file.id)} disabled={!!downloadingFileId}>
            {downloadingFileId !== file.id ? (
              <Download style={{ width: 24, height: 24 }} />
            ) : (
              <CircularProgress
                style={{ color: Theme.colors.darkishGreen, width: 24, height: 24 }}
              />
            )}
          </IconButton>
        </ListItem>
      ))}
    </div>
  )
}
