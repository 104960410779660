import { Fragment, useLayoutEffect, useRef, useState, ReactNode, useContext } from 'react'
import { GlobalContext } from '../../../context/global/global'
import { numberToString } from '../../../utils/formatNumberToString'
import {
  Wrapper,
  WrapperHorizontalLines,
  HorizontalLine,
  ContainerBar,
  ChartBar,
  BarLabel,
  WrapperBars,
  LeftLabel,
  ContainerBarLabels,
  NoDataWrapper,
  NoDataContainer,
  HoverMenu,
} from './styles'

interface IMeasurementsChartData<T> {
  value: number
  label: string
  hoverTitle?: string
  additionalData?: T
}

interface IHoverComponentProps<T> extends IMeasurementsChartData<T> {
  index: number
}
interface IMeasurementsChartProps<T> {
  data: Array<IMeasurementsChartData<T>> | null
  isLoading: boolean
  loadingComponent: ReactNode
  hoverComponent?: (data: IHoverComponentProps<T>) => ReactNode
  height?: string
  hoverTitle?: (label: string) => ReactNode
  onClickItem?: (fields: Record<"value" | "label", any>) => void
}

const exampleChart = new Array(20).fill(0).map((_, index) => ({
  value: Math.floor(Math.random() * 1000) + 700,
  label: `${index + 1}`,
  hoverTitle: `${index + 1}`,
}))

export function MeasurementsChart<T = any>({
  data,
  height,
  isLoading,
  loadingComponent,
  hoverComponent,
  hoverTitle,
  onClickItem
}: IMeasurementsChartProps<T>) {
  const { Theme } = useContext(GlobalContext)
  const [wrapperBarsProps, setWrapperBarsProps] = useState<{
    width?: string
    paddingLeft?: string
    height?: string
  }>()
  const FirstLeftLabelRef = useRef<HTMLSpanElement>(null)
  const WrapperHorizontalLinesRef = useRef<HTMLDivElement>(null)
  const ContainerBarLabelsRef = useRef<HTMLDivElement>(null)

  const chartData:IMeasurementsChartProps<T>['data'] = Array.isArray(data) && data.length > 0 ? data : exampleChart

  const getChartSize = () => {
    const WrapperHorizontalLinesWidth = WrapperHorizontalLinesRef.current!.offsetWidth

    const WrapperHorizontalLinesHeight = WrapperHorizontalLinesRef.current!.offsetHeight

    const calcWrapperBarsBottomSpacing = WrapperHorizontalLinesHeight / 5 / 2
    const calcWrapperBarsHeight = WrapperHorizontalLinesHeight - calcWrapperBarsBottomSpacing * 2

    setWrapperBarsProps((prev) => ({
      ...prev,
      width: `${WrapperHorizontalLinesWidth}px`,
      height: `${calcWrapperBarsHeight}px`,
    }))
  }

  const getPaddingLeft = () => {
    const LeftLabelWidth = FirstLeftLabelRef.current!.offsetWidth + 10

    setWrapperBarsProps((prev) => ({
      ...prev,
      paddingLeft: `${LeftLabelWidth}px`,
    }))
  }

  useLayoutEffect(() => {
    if (FirstLeftLabelRef.current) {
      getPaddingLeft()
    }
  }, [FirstLeftLabelRef])

  useLayoutEffect(() => {
    if (!isLoading) {
      getChartSize()
    }
  }, [isLoading])

  useLayoutEffect(() => {
    if (WrapperHorizontalLinesRef.current && ContainerBarLabelsRef.current) {
      getChartSize()
      window.addEventListener('resize', getChartSize)

      return () => {
        window.removeEventListener('resize', getChartSize)
      }
    }
  }, [wrapperBarsProps?.paddingLeft, WrapperHorizontalLinesRef, ContainerBarLabelsRef])

  const roundedMaxValue = (index = 0) => {
    const findMaxValue = Math.max(...chartData.map(({ value }) => value))
    const roundMaxValue = Math.ceil(findMaxValue / 100) * 100

    return roundMaxValue - (roundMaxValue / 4) * index
  }

  return (
    <Wrapper chartHeight={height || '200px'}>
      {isLoading ? (
        <NoDataWrapper>
          <NoDataContainer>{loadingComponent}</NoDataContainer>
        </NoDataWrapper>
      ) : (
        Array.isArray(data) &&
        data.length === 0 && (
          <NoDataWrapper>
            <NoDataContainer>
              <span>Nenhum dado encontrado.</span>
            </NoDataContainer>
          </NoDataWrapper>
        )
      )}
      {wrapperBarsProps &&
        wrapperBarsProps.width &&
        wrapperBarsProps.paddingLeft &&
        wrapperBarsProps.height && (
          <WrapperBars
            width={wrapperBarsProps.width}
            paddingLeft={wrapperBarsProps.paddingLeft}
            height={wrapperBarsProps.height}
          >
            {chartData.map(({ value, label, additionalData, hoverTitle: hoverLabel }, index) => {
              const title = hoverTitle ? hoverTitle(hoverLabel || label) : hoverLabel || label;

              return (
                <ContainerBar key={index} onClick={() => onClickItem && onClickItem({ label, value })}>
                  {hoverComponent && (
                    <HoverMenu side={index > Math.floor(chartData.length / 2) ? 'left' : 'right'}>
                      <span>
                        <strong>
                        {title}
                      </strong>
                    </span>
                    {hoverComponent({ value, label, index, additionalData })}
                  </HoverMenu>
                )}
                <ChartBar
                  bgColor={Theme.colors.darkishGreen}
                  height={(value * 100) / roundedMaxValue()}
                />
              </ContainerBar>
              )
            })}
          </WrapperBars>
        )}
      <WrapperHorizontalLines ref={WrapperHorizontalLinesRef}>
        {new Array(5).fill(0).map((_, index) => (
          <Fragment key={index}>
            <LeftLabel {...(index === 0 ? { ref: FirstLeftLabelRef } : {})}>
              {numberToString(roundedMaxValue(index))}
            </LeftLabel>
            <HorizontalLine />
          </Fragment>
        ))}
      </WrapperHorizontalLines>
      {wrapperBarsProps?.paddingLeft && (
        <ContainerBarLabels ref={ContainerBarLabelsRef} paddingLeft={wrapperBarsProps.paddingLeft}>
          {chartData.map(({ label }, index) => (
            <BarLabel key={index}>{label}</BarLabel>
          ))}
        </ContainerBarLabels>
      )}
    </Wrapper>
  )
}
