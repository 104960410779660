import styled from '@emotion/styled'
import { CircularProgress, FormControl } from '@mui/material'
import { Theme } from '../../../../../../styles/theme'

export const Container = styled(FormControl)`
  position: relative;
`

export const Loading = styled(CircularProgress)`
  position: absolute;
  right: 36px;
  top: 16px;
  width: 24px !important;
  height: 24px !important;
  color: ${Theme.colors.darkishGreen};
`
