import styled, { css } from 'styled-components'
import MenuItem from '@mui/material/MenuItem'
import SelectComponent from '@mui/material/Select'
import { darken } from 'polished'

type TypeGridArea =
  | 'Capacity'
  | 'Chart'
  | 'AverageProd'
  | 'Line'
  | 'DailyProd'
  | 'GoalProd'
  | 'MonthlyProd'
  | 'YearlyProd'
  | 'TotalProd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`

export const RowContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`

interface ICardProps {
  width?: string
  height?: string
}

export const Card = styled.div<ICardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ width }) => width ?? '100%'};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};

  background: #fff;
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 16px;
`

interface ICartitemContainerProps {
  height?: string
  width?: string
  maxHeight?: string
  flexDirection?: 'row' | 'column'
  gap?: string
  alignAllCenter?: boolean
  justifyContent?: string
  margin?: string
}

export const CartitemContainer = styled.div<ICartitemContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ?? 'fit-content'};
  height: ${({ height }) => height ?? 'inherit'};
  max-height: ${({ maxHeight }) => maxHeight ?? 'fit-content'};
  overflow: hidden;
  padding: 16px;
  gap: ${({ gap }) => gap ?? '0'};

  ${({ alignAllCenter }) =>
    alignAllCenter &&
    css`
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    `}
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

  ${({ margin }) => css`
    margin: ${margin};
  `}
`

export const ContainerProduction = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export interface IContainerItemProps {
  width?: string
  directionRow?: boolean
  padding?: string
  gridArea?: TypeGridArea
  gap?: string
  justiySelf?: 'start' | 'center' | 'end'
}

export const ContainerItem = styled.div<IContainerItemProps>`
  display: flex;
  gap: ${({ gap }) => gap ?? '10px'};
  width: ${({ width }) => width ?? '150px'};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

  ${({ directionRow }) =>
    directionRow
      ? css`
          flex-direction: row;
          align-items: end;
        `
      : css`
          flex-direction: column;
        `}

  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `}

  ${({ justiySelf }) =>
    justiySelf &&
    css`
      justify-self: ${justiySelf};
    `}
`

export const GoalProd = styled.div`
  grid-area: GoalProd;
  display: flex;
  flex-direction: column;
`

interface IContainerGridProductionProps {
  withGoalProd: boolean
}

export const ContainerGridProduction = styled.div<IContainerGridProductionProps>`
  width: 100%;
  height: fit-content;
  padding: 20px 0;
  align-items: center;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 0.5fr);
  grid-template-rows: repeat(3, fit-content);
  ${({withGoalProd}) => withGoalProd ? 
    css`
      grid-template-areas:
      'Capacity Chart Chart Chart AverageProd'
      'Line Line Line Line Line'
      'DailyProd MonthlyProd GoalProd YearlyProd TotalProd';
    ` : 
    css`
      grid-template-areas:
        'Capacity Chart Chart AverageProd'
        'Line Line Line Line'
        'DailyProd MonthlyProd YearlyProd TotalProd';
    `}
  align-content: space-around;
  justify-content: space-between;
`

interface IDividerProps {
  vertical?: boolean
  gridArea?: TypeGridArea
}

export const Divider = styled.div<IDividerProps>`
  background-color: #d9d9d9;
  ${({ vertical }) => {
    if (vertical) {
      return css`
        width: 2px;
        height: 100%;
      `
    } else {
      return css`
        width: 100%;
        height: 2px;
      `
    }
  }}

  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `}
`

interface ITextProps {
  color?: string
  size?: string
  width?: string
}

export const Title = styled.h3<ITextProps>`
  color: ${({ color, theme }) => color ?? theme.colors.grayDark};
  font-size: ${({ size }) => size ?? '20px'};
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  width: ${({ width }) => width ?? '100%'};
  padding-right: 2px;
`
export const Text = styled.p<ITextProps>`
  color: ${({ color, theme }) => color ?? theme.colors.grayDark};
  font-size: ${({ size }) => size ?? '20px'};
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: flex;
  width: ${({ width }) => width ?? '100%'};
  padding-right: 2px;
`

export const Select = styled(SelectComponent)`
  width: 300px;
  height: 40px;
  font-size: 16px !important;
  background-color: #f1f1f1;

  & > fieldset {
    border: none;
  }
`

export const SelectItem = styled(MenuItem)`
  font-size: 12px !important;
  color: ${({ theme }) => theme.colors.grayDark} !important;
  padding: 8px 2px 8px 15px !important;
`

export const IconContaienr = styled.div`
  margin-right: 20px;
`

export const JustifyBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ContainerMainChartHeader = styled.header`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`

export const WarningContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DownloadDiv = styled.div`
  padding: 0 8px;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5625rem;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.white};
  font-size: 0.925rem;
  border: none;

  background: ${(props) => props.theme.colors.darkishGreen};

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    background: ${(props) => props.theme.colors.darkishGreen};
  }

  img {
    width: 0.925rem;
    aspect-ratio: 1/1;
  }
`
