import { useMemo } from 'react';
import { LegendCircle, LegendDiv, LegendSubDiv, LegendText } from './styles'

interface Props {
  className?: string;
  colors: string[]
  legend: string[]
  selectedLegends: string[]
  onClick: (legend: string) => void 
}

function generateColors(legend: string[], colors: string[]) {
  let index = 0

  const colorsMap = legend.map(() => {
    if(index > colors.length - 1) {
      index = 0;
    }
    const color = colors[index];
    index++;
    return color;
  })
  return colorsMap;
}

export default function ChartLegend(props: Props) {
  const { className, colors, legend, selectedLegends, onClick } = props

  const allColors = useMemo(() => generateColors(legend, colors), [legend, colors]);

  return (
    <LegendDiv id="chart-legend" className={className && className}>
      {legend.map((date, index) => {
        const serieExists = selectedLegends.findIndex((serie) => serie === date)
        const color = allColors[index] ? allColors[index] : allColors[0]
        return (
          <LegendSubDiv active={serieExists !== -1} key={`legend-color-${date}`}>
            <LegendCircle color={color} />
            <LegendText onClick={() => onClick(date)}>{date}</LegendText>
          </LegendSubDiv>
        )
      })}
    </LegendDiv>
  )
}
