import { Form, FormikProvider, useFormik } from 'formik'
import Modal from '../../../../../../components/ModalV2'
import { InputV2 } from '../../../../../../components/InputV2'
import * as Yup from 'yup'
import { useToast } from '../../../../../../hooks/useToast'
import { Button } from '../../../../../../components/Button'
import { useState } from 'react'
import { usersService } from '../../../../../../services/users'
import { SelectV2 } from '../../../../../../components/SelectV2'
import ClientSelect from '../ClientSelect'
import ManagerSelect from '../ManagerSelect'

interface FormData {
  name: string
  email: string
  password: string
  clientId?: number
  managerId?: number
}

function getValidationSchema(consumer?: boolean): Yup.SchemaOf<FormData> {
  const baseSchema = {
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string().email('Formato inválido').required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
    managerId: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
  }
  if (consumer) {
    return Yup.object().shape({
      ...baseSchema,
      clientId: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
    })
  }
  return Yup.object().shape({
    ...baseSchema,
    clientId: Yup.number().optional().typeError('Campo obrigatório'),
  })
}

interface Props {
  managerId?: number
  onClose: () => void
}

export default function UserModal(props: Props) {
  const { managerId, onClose } = props

  const { toast } = useToast()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [selectedUserType, setSelectedUserType] = useState<number | undefined>(1)

  const [selectedClientId, setSelectedClientId] = useState<number | null>(null)
  const [selectedManagerId, setSelectedManagerId] = useState<number | null>(null)

  const handleSubmit = async (values: FormData) => {
    try {
      setLoading(true)
      await usersService.createUser({
        ...values,
        clientId: values.clientId ?? values.managerId ?? 0,
      })
      toast({
        message: 'Usuário criado com sucesso',
        type: 'success',
      })
      onClose()
    } catch (error) {
      console.log(error)
      toast({
        message: `Erro ao criar usuário: ${(error as Error).message}`,
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik<FormData>({
    initialValues: {
      managerId: undefined,
      name: '',
      email: '',
      password: '',
      clientId: undefined,
    },
    validationSchema: getValidationSchema(selectedUserType === 2),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  })

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {`${managerId ? 'Edição' : 'Cadastro'} de Usuário`} <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body style={{ overflowY: 'auto' }}>
        <FormikProvider value={formik}>
          <Form style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            <SelectV2
              formik={false}
              label='Tipo de Acesso'
              required
              value={selectedUserType}
              onChange={(e) => {
                const updatedUserType = e.target.value as number
                if (updatedUserType === 1) {
                  formik.setFieldValue('clientId', undefined)
                }
                setSelectedUserType(updatedUserType)
              }}
              items={[
                { id: 1, displayName: 'Gestor' },
                { id: 2, displayName: 'Consumidor' },
              ]}
            />

            <ManagerSelect
              error={formik.errors['managerId']}
              value={selectedManagerId}
              setValue={(id) => {
                formik.setFieldValue('managerId', id)
                setSelectedManagerId(id)
              }}
            />

            {selectedUserType && selectedUserType === 2 && !!formik.values.managerId && (
              <ClientSelect
                managerId={formik.values.managerId}
                value={selectedClientId}
                setValue={(id) => {
                  formik.setFieldValue('clientId', id)
                  setSelectedClientId(id)
                }}
              />
            )}

            <InputV2 id='name' label='Nome' required />
            <InputV2 id='email' label='Email' required />
            <InputV2 id='password' label='Senha' required />
            <Button
              containerStyle={{ margin: '16px 0 0 0' }}
              type='submit'
              text='Cadastrar'
              isLoading={isLoading}
            ></Button>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal.Provider>
  )
}
