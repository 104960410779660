import { Form, FormikProvider, useFormik } from 'formik'
import { CancelButton, SendButton, Stage, StickyFooter } from './styles'
import { useEffect, useState } from 'react'
import AddressFields from './components/AddressFields'
import Modal from '../../../../ModalV2'
import Tabs from './components/Tabs'
import UCFields from './components/UCFields'
import DistributorFields from './components/DistributorFields'
import FinancialFields from './components/FinancialFields'
import { useToast } from '../../../../../hooks/useToast'
import { editUnitConsumer, unitRegisterPost } from '../../../../../services/requests/user-requests'
import { CircularProgress } from '@mui/material'
import { ExpiringRule, UC } from '../../../../../interfaces/ucs'
import { ucsService } from '../../../../../services/ucs'
import { mergeWith } from 'lodash'
import * as Yup from 'yup'
import Loading from '../../../../Loading'
import { format, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { utcDateToTz } from '../../../../../utils/date-utils'

type FormData = Omit<
  UC,
  | 'id'
  | 'distribuidora_id'
  | 'cliente_id_consumidor'
  | 'modalidade_de_compensacao'
  | 'metodo_pagamento_id'
  | 'cliente_id_gestor'
  | 'documento_tipo'
  | 'login_tipo'
  | 'is_active'
  | 'expectativa_geracao'
> & {
  vigencia_real_compensacao?: string
  distribuidora_id: number | null
  cliente_id_consumidor: number | null
  modalidade_de_compensacao: number | null
  metodo_pagamento_id: number | null
}

type ValidationSchemaData = Omit<
  UC,
  | 'id'
  | 'distribuidora_id'
  | 'cliente_id_consumidor'
  | 'modalidade_de_compensacao'
  | 'metodo_pagamento_id'
  | 'cliente_id_gestor'
  | 'documento_tipo'
  | 'login_tipo'
  | 'is_active'
  | 'expectativa_geracao'
>

const ValidationSchema: Yup.SchemaOf<ValidationSchemaData> = Yup.object().shape({
  documento_numero: Yup.string().required('Campo obrigatório'),
  login: Yup.string(),
  senha_dist: Yup.string(),
  distribuidora_id: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
  modalidade_de_compensacao: Yup.string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  metodo_pagamento_id: Yup.number().nullable(),
  remuneracao_regra: Yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  codigo: Yup.string().required('Campo obrigatório'),
  comissao: Yup.number()
    .min(0, 'Valor inválido')
    .max(100, 'Valor inválido')
    .transform((comissao) => Number(comissao))
    .typeError('Valor inválido'),
  consultor: Yup.string(),
  consumo_medio: Yup.number().typeError('Valor inválido'),
  endereco_bairro: Yup.string(),
  endereco_cep: Yup.string(),
  endereco_cidade: Yup.string(),
  endereco_codigo_cidade: Yup.string(),
  endereco_complemento: Yup.string(),
  endereco_logradouro: Yup.string(),
  endereco_num: Yup.string(),
  grupo: Yup.string().required('Campo obrigatório') as any, // A or B
  subgrupo: Yup.string(),
  nome: Yup.string().required('Campo obrigatório'),
  nome_razao_social: Yup.string(),
  // Remuneration Rule Fields
  isl_sengi: Yup.number().typeError('Valor inválido'),
  percentual_sobre_tarifa_consumo: Yup.number().typeError('Valor inválido'),
  percentual_por_bandeira_amar: Yup.number().typeError('Valor inválido'),
  percentual_por_bandeira_verde: Yup.number().typeError('Valor inválido'),
  percentual_por_bandeira_verm_p1: Yup.number().typeError('Valor inválido'),
  percentual_por_bandeira_verm_p2: Yup.number().typeError('Valor inválido'),
  percentual_sobre_bandeira_valor: Yup.number().typeError('Valor inválido'),
  percentual_sobre_compensado_valor: Yup.number().typeError('Valor inválido'),
  percentual_sobre_compensado_devolucao: Yup.number().typeError('Valor inválido'),
  piso_disponibilidade: Yup.number().typeError('Valor inválido'),
  tarifa_fixa_valor: Yup.number().typeError('Valor inválido'),
  valor_arrendamento: Yup.number().typeError('Valor inválido'),
  valor_fixo: Yup.number().typeError('Valor inválido'),
  desconto_fixo_valor: Yup.number().typeError('Valor inválido'),
  compensado_usina_valor: Yup.number().typeError('Valor inválido'),
  cliente_id_consumidor: Yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  mes_operacao: Yup.string().test(
    'mes_operacao-format',
    'Formato do mês de operação inválido, o correto é: DD-MM-YYYY',
    (value) => {
      return !value || /^(\d{2})-(\d{2})-(\d{4})$/.test(value)
    },
  ),
  vencimento_regra: Yup.mixed<ExpiringRule>()
    .oneOf(Object.values(ExpiringRule))
    .typeError('Valor inválido'),
  vencimento_valor: Yup.number().typeError('Valor inválido'),
  vigencia_prevista_compensacao: Yup.string().test(
    'vigencia_prevista_compensacao-format',
    'Formato inválido, o correto é: MM/YYYY',
    (value) => {
      return !value || /^(\d{2})\/(\d{4})$/.test(value)
    },
  ),
})

const initialValues: FormData = {
  nome: '',
  distribuidora_id: null,
  grupo: 'B',
  subgrupo: 'B',
  codigo: '',
  login: '',
  senha_dist: '',
  cliente_id_consumidor: null,
  consultor: '',
  modalidade_de_compensacao: null,
  metodo_pagamento_id: null,
  consumo_medio: 0,
  comissao: 0,
  endereco_cep: '',
  endereco_logradouro: '',
  endereco_num: '',
  endereco_complemento: '',
  endereco_bairro: '',
  endereco_cidade: '',
  endereco_codigo_cidade: '',
  documento_numero: '',
  nome_razao_social: '',
  remuneracao_regra: '',
  desconto_fixo_valor: 0,
  compensado_usina_valor: 0,
  valor_fixo: 0,
  valor_arrendamento: 0,
  piso_disponibilidade: 0,
  mes_operacao: '',
  isl_sengi: 0,
  tarifa_fixa_valor: 0,
  percentual_sobre_compensado_valor: 0,
  // percentual_sobre_compensado_devolucao: 0,
  percentual_sobre_bandeira_valor: 0,
  percentual_por_bandeira_verde: 0,
  percentual_por_bandeira_amar: 0,
  percentual_por_bandeira_verm_p1: 0,
  percentual_por_bandeira_verm_p2: 0,
  vencimento_regra: undefined,
  vencimento_valor: 0,
  vigencia_prevista_compensacao: '',
  vigencia_real_compensacao: '--/----'
}

interface Props {
  ucId?: number
  onClose: () => void
}

export default function ModalRegisterConsumerUnit(props: Props) {
  const { ucId, onClose } = props

  const [currentStage, setCurrentStage] = useState<number>(0)

  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const isEdit = !!ucId

  const formik = useFormik<FormData>({
    initialTouched: isEdit
      ? Object.keys(initialValues).reduce((agg: Record<string, boolean>, key) => {
          agg[key] = true
          return agg
        }, {})
      : {},
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: true,
  })

  const fetchUc = async () => {
    if (!ucId) {
      return
    }
    try {
      setLoading(true)
      const ucResponse = await ucsService.findById(ucId)
      const filteredUcResponse = Object.fromEntries(
        Object.entries(ucResponse).filter(([key]) =>
          Object.keys(formik.initialValues).includes(key),
        ),
      )
      formik.setValues({
        ...mergeWith(
          {},
          formik.initialValues,
          {
            ...filteredUcResponse,
            ...(filteredUcResponse.mes_operacao
              ? { mes_operacao: format(utcDateToTz(filteredUcResponse.mes_operacao) as Date, 'dd-MM-yyyy') }
              : {}),
            ...(filteredUcResponse.vigencia_prevista_compensacao ? { 
              vigencia_prevista_compensacao: format(new Date(filteredUcResponse.vigencia_prevista_compensacao), 'MM/yyyy') 
            } : {})
          },
          (objValue, srcValue) => (srcValue == null ? objValue : srcValue),
        ),
        desconto_fixo_valor: (ucResponse?.desconto_fixo_valor ?? 0) * 100,
        compensado_usina_valor: (ucResponse?.compensado_usina_valor ?? 0) * 100,
        valor_fixo: ucResponse?.valor_fixo ? ucResponse?.valor_fixo : 0,
        valor_arrendamento: (ucResponse?.valor_arrendamento ?? 0) * 100,
        piso_disponibilidade: (ucResponse?.piso_disponibilidade ?? 0) * 100,
        isl_sengi: (ucResponse?.isl_sengi ?? 0) * 100,
        tarifa_fixa_valor: (ucResponse?.tarifa_fixa_valor ?? 0),
        percentual_sobre_compensado_valor:
          (ucResponse?.percentual_sobre_compensado_valor ?? 0) * 100,
        // percentual_sobre_compensado_devolucao:
        //   (ucResponse?.percentual_sobre_compensado_devolucao ?? 0) * 100,
        percentual_sobre_bandeira_valor: (ucResponse?.percentual_sobre_bandeira_valor ?? 0) * 100,
        percentual_por_bandeira_verde: (ucResponse?.percentual_por_bandeira_verde ?? 0) * 100,
        percentual_por_bandeira_amar: (ucResponse?.percentual_por_bandeira_amar ?? 0) * 100,
        percentual_por_bandeira_verm_p1: (ucResponse?.percentual_por_bandeira_verm_p1 ?? 0) * 100,
        percentual_por_bandeira_verm_p2: (ucResponse?.percentual_por_bandeira_verm_p2 ?? 0) * 100,
      })
      setLoading(false)
    } catch (error) {
      onClose()
      toast({
        message: 'Erro ao carregar dados da UC',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchUc()
  }, [ucId])

  async function handleSubmit(values: FormData) {
    try {
      setSubmitting(true)

      const payload = {
        ...values,
        consumo_medio: Number(values.consumo_medio),
        desconto_fixo_valor:
          typeof values.desconto_fixo_valor === 'string' || values.desconto_fixo_valor === 0
            ? undefined
            : (values.desconto_fixo_valor ?? 0) / 100,
        compensado_usina_valor:
          typeof values.compensado_usina_valor === 'string' || values.compensado_usina_valor === 0
            ? undefined
            : (values.compensado_usina_valor ?? 0) / 100,
        valor_fixo:
          typeof values.valor_fixo === 'string' || values.valor_fixo === 0
            ? undefined
            : values.valor_fixo,
        valor_arrendamento:
          typeof values.valor_arrendamento === 'string' || values.valor_arrendamento === 0
            ? undefined
            : values.valor_arrendamento,
        piso_disponibilidade:
          typeof values.piso_disponibilidade === 'string' || values.piso_disponibilidade === 0
            ? undefined
            : values.piso_disponibilidade,
        isl_sengi:
          typeof values.isl_sengi === 'string' || values.isl_sengi === 0
            ? undefined
            : (values.isl_sengi ?? 0) / 100,
        tarifa_fixa_valor:
          typeof values.tarifa_fixa_valor === 'string' || values.tarifa_fixa_valor === 0
            ? undefined
            : (values.tarifa_fixa_valor ?? 0),
        percentual_sobre_compensado_valor:
          typeof values.percentual_sobre_compensado_valor === 'string' ||
          values.percentual_sobre_compensado_valor === 0
            ? undefined
            : (values.percentual_sobre_compensado_valor ?? 0) / 100,
        percentual_sobre_bandeira_valor:
          typeof values.percentual_sobre_bandeira_valor === 'string' ||
          values.percentual_sobre_bandeira_valor === 0
            ? undefined
            : (values.percentual_sobre_bandeira_valor ?? 0) / 100,
        percentual_por_bandeira_verde:
          typeof values.percentual_por_bandeira_verde === 'string' ||
          values.percentual_por_bandeira_verde === 0
            ? undefined
            : (values.percentual_por_bandeira_verde ?? 0) / 100,
        percentual_por_bandeira_amar:
          typeof values.percentual_por_bandeira_amar === 'string' ||
          values.percentual_por_bandeira_amar === 0
            ? undefined
            : (values.percentual_por_bandeira_amar ?? 0) / 100,
        percentual_por_bandeira_verm_p1:
          typeof values.percentual_por_bandeira_verm_p1 === 'string' ||
          values.percentual_por_bandeira_verm_p1 === 0
            ? undefined
            : (values.percentual_por_bandeira_verm_p1 ?? 0) / 100,
        percentual_por_bandeira_verm_p2:
          typeof values.percentual_por_bandeira_verm_p2 === 'string' ||
          values.percentual_por_bandeira_verm_p2 === 0
            ? undefined
            : (values.percentual_por_bandeira_verm_p2 ?? 0) / 100,
        vencimento_regra: values.vencimento_regra,
        vencimento_valor: Number(values.vencimento_valor),
      }

      if (ucId) {
        await editUnitConsumer({ ...payload, id: ucId })
      } else {
        await unitRegisterPost(payload)
      }

      onClose()
      toast({
        message: `Unidade consumidora ${ucId ? 'atualizada' : 'cadastrada'} com sucesso`,
        type: 'success',
      })
    } catch (error) {
      console.log(error)
      toast({
        message: (error as Error).message,
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal.Provider
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        maxHeight: '750px',
        position: 'relative',
      }}
      maxWidth={500}
      isOpen
      onClose={onClose}
    >
      <Modal.Title>
        {`${ucId ? 'Edição' : 'Cadastro'} de Unidade Consumidora`} <Modal.CloseButton alignLeft />
      </Modal.Title>

      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <Tabs
            value={currentStage}
            onChange={(stage) => setCurrentStage(stage)}
            tabs={['Dados da UC', 'Distribuidora', 'Financeiro', 'Endereços']}
          />
          <FormikProvider value={formik}>
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                height: '100%',
              }}
            >
              <Modal.Body>
                <Stage active={currentStage === 0}>
                  <UCFields isEdit={isEdit} formik={formik} />
                </Stage>
                <Stage active={currentStage === 1}>
                  <DistributorFields formik={formik} />
                </Stage>
                <Stage active={currentStage === 2}>
                  <FinancialFields formik={formik} />
                </Stage>
                <Stage active={currentStage === 3}>
                  <AddressFields />
                </Stage>
              </Modal.Body>
              <StickyFooter>
                {currentStage > 0 && (
                  <CancelButton
                    type='button'
                    onClick={() => setCurrentStage((previousStage) => previousStage - 1)}
                  >
                    Voltar
                  </CancelButton>
                )}
                <SendButton
                  key={`next-stage-button-${currentStage}`}
                  type={currentStage < 3 ? 'button' : 'submit'}
                  disabled={isSubmitting}
                  onClick={() => {
                    if (currentStage < 3) {
                      setCurrentStage((previousStage) => previousStage + 1)
                    }
                  }}
                >
                  {isSubmitting && <CircularProgress size={20} />}
                  {!isSubmitting && (currentStage < 3 ? 'Avançar' : 'Salvar')}
                </SendButton>
              </StickyFooter>
            </Form>
          </FormikProvider>
        </>
      )}
    </Modal.Provider>
  )
}
