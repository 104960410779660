import { ticketCategories } from '../../categories'
import { SelectDiv } from '../../styles'

interface Props {
  onChange: (category: string, subCategory?: string) => void
}

export default function CategorySelect(props: Props) {
  const { onChange } = props

  return (
    <SelectDiv>
      <label>Categoria</label>
      <select
        defaultValue='none'
        onChange={(e: { target: { value: string } }) => {
          const [category, subCategory] = e.target.value.split('|')
          onChange(category, subCategory)
        }}
      >
        <option value="none" selected disabled hidden>Selecionar Categoria</option> 
        {ticketCategories.map((category) =>
          category.options ? (
            <optgroup key={`ticket-category-${category.label}`} label={category.label}>
              {category.options.map((option) => (
                <option key={`ticket-option-${option.label}`} value={`${category.label}|${option.value}`}>
                  {option.label}
                </option>
              ))}
            </optgroup>
          ) : (
            <option key={`ticket-category-${category.label}`} value={category.value}>
              {category.label}
            </option>
          ),
        )}
      </select>
    </SelectDiv>
  )
}
