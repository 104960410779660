import { TableFilters } from './styles'
import ConferenceTypeSelect from './components/ConferenceTypeSelect'
import { useEffect, useState } from 'react'
import Loading from '../../../components/Loading'
import ConferenceTable from './components/ConferenceTable'
import {
  ConferencePagination,
  ConferenceSortEvent,
  ConferenceSortType,
  ConferenceType,
  PaginationParams,
  TableColumn,
} from '../../../interfaces/conference'
import ConferenceSearchInput from './components/ConferenceSearchInput'
import { conferenceService } from '../../../services/conference'
import ConferenceTableConfig from './components/ConferenceTableConfig'

export const defaultColumns: TableColumn[] = [
  {
    field: 'name',
    headerName: 'Distribuidora',
  },
  {
    field: 'invoicesTotalCount',
    headerName: 'Total de faturas',
  },
  {
    field: 'invoicesCompletedCount',
    headerName: 'Faturas Completas',
  },
  {
    field: 'invoicesUnaccessibleCount',
    headerName: 'Sem Acesso',
  },
  {
    field: 'invoicesNotDownloadedCount',
    headerName: 'Não Baixadas',
  },
  {
    field: 'invoicesExpectedCount',
    headerName: 'Faturas Esperadas Até Hoje',
  },
  {
    field: 'invoicesWaitingForIssue',
    headerName: 'Aguardando Emissão',
  },
  {
    field: 'invoicesWarningCount',
    headerName: 'Quantidade de UCs Alertas',
  },
  {
    field: 'previousMonthInvoicing',
    headerName: 'Faturamento Estimado',
  },
  {
    field: 'currentMonthInvoicing',
    headerName: 'Faturamento',
  },
  {
    field: 'currentMonthInvoicingRemainder',
    headerName: 'R$ Faltante',
  },
]

export default function ConferencePage() {
  const [selectedConferenceType, setSelectedConferenceType] =
    useState<ConferenceType>('distributor')

  const [tableColumns, setTableColumns] = useState<TableColumn[]>(defaultColumns)

  const [limit, setLimit] = useState<number>(15)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [sortEvent, setSortEvent] = useState<ConferenceSortEvent>()

  const [conferences, setConferences] = useState<
    Partial<{ [key in ConferenceType]: ConferencePagination & { hasFilter?: boolean } }>
  >({})

  const [isLoading, setLoading] = useState<boolean>(false)

  const fetchConference = async (
    conferenceType: ConferenceType,
    params: Partial<PaginationParams<ConferenceSortType>>,
  ) => {
    try {
      setLoading(true)
      const conferenceResult = await conferenceService.getConferences(conferenceType, {
        filter: searchFilter,
        limit,
        page: currentPagination?.pageInfo?.currentPage,
        ...(sortEvent
          ? {
              order: sortEvent.sort,
              sort: sortEvent.field,
            }
          : {}),
        ...params,
      })
      setConferences((previousState) => ({
        ...previousState,
        [conferenceType]: conferenceResult,
      }))
    } catch (err) {
      console.log(`Erro ao carregar ${conferenceType}`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchConference(selectedConferenceType, { limit: limit })
  }, [])

  const currentPagination = conferences[selectedConferenceType]

  return (
    <>
      <TableFilters>
        <ConferenceTypeSelect
          selectedValue={selectedConferenceType}
          onSelect={(value) => {
            const conferenceType = value as ConferenceType
            setTableColumns((previousColumns) => {
              const updatedColumns = [...previousColumns]
              updatedColumns[0].headerName =
                conferenceType === 'client' ? 'Cliente' : 'Distribuidora'

              return updatedColumns
            })
            setSelectedConferenceType(conferenceType)
            setSearchFilter('')
            setLimit(15)
            setSortEvent(undefined)
            fetchConference(conferenceType, { page: 1, limit: 15 })
          }}
        />

        <ConferenceSearchInput
          value={searchFilter}
          onChange={(value) => setSearchFilter(value)}
          onSearch={(value) => {
            fetchConference(selectedConferenceType, { filter: value, page: 1 })
          }}
        />

        <ConferenceTableConfig
          itemsPerPage={limit}
          setItemsPerPage={(itemsPerPage) => {
            setLimit(itemsPerPage)
            fetchConference(selectedConferenceType, { limit: itemsPerPage, page: 1 })
          }}
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
        />
      </TableFilters>

      {isLoading || !currentPagination ? (
        <Loading />
      ) : (
        <ConferenceTable
          tableColumns={tableColumns}
          pagination={currentPagination}
          sortEvent={sortEvent}
          onSort={(event) => {
            setSortEvent(event)
            fetchConference(selectedConferenceType, {
              sort: event.field,
              order: event.sort,
            })
          }}
          onPageChange={(page) => {
            fetchConference(selectedConferenceType, {
              page,
            })
          }}
        />
      )}
    </>
  )
}
