import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const Title = styled(Typography)`
  padding: 16px 16px 0 16px;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
`
