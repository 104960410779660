import styled from 'styled-components'
import { Theme } from '../../styles/theme'

export const Container = styled.div``

export const Title = styled.p`
  font-size: 24px;
`

export const Content = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`

export const Card = styled.div`
  color: ${Theme.colors.darkGreen};
  border: 2px solid ${Theme.colors.darkGreen};
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 15px 20px;
  font-size: 24px;
  display: flex;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  img {
    height: 50px;
  }

  &:hover {
    opacity: 0.6;
  }
`

export const FloatCard = styled.div`
  display: flex;
  position: absolute;
  opacity: 0.7;
  top: 0;
  background-color: ${Theme.colors.grayDark};
  width: 100%;
  text-align: center;
  justify-content: center;
  color: ${Theme.colors.white};
  font-size: 16px;
`
