import { FormikProps } from 'formik'
import { InputV2 } from '../../../../../../InputV2'
import RemunerationRuleSelect from '../../../../components/RemunerationRuleSelect'
import { Stack } from '@mui/material'

interface Props {
  formik: FormikProps<any>
}

export default function RemunerationRulesFields(props: Props) {
  const { formik } = props

  const remunerationRule = formik.values.remuneracao_regra

  return (
    <>
      <RemunerationRuleSelect required={false} formik={formik} />

      {remunerationRule && (
        <Stack sx={{ paddingLeft: 6 }} gap={2}>
          {remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CONSUMO' && (
            <InputV2
              id='percentual_sobre_compensado_valor'
              label='Percentual sobre tarifa de consumo'
              type='number'
            />
          )}
          {(remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CRUA' ||
            remunerationRule === 'ATINS' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO' ||
            remunerationRule === 'ENERGIA_AMIGA' ||
            remunerationRule === 'CV_CHEIA' ||
            remunerationRule === 'CV_CRUA' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_COSIP' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_DEVOLUCAO') && (
            <InputV2
              id='percentual_sobre_compensado_valor'
              label='Percentual sobre Compensado'
              type='number'
            />
          )}
          {remunerationRule === 'FFZ' && (
            <>
              <InputV2
                id='percentual_sobre_compensado_valor'
                label='Percentual sobre Compensado'
                type='number'
              />
              <InputV2 id='valor_arrendamento' label='Valor arrendamento' type='number' />
              <InputV2 id='piso_disponibilidade' label='Piso disponibilidade' type='number' />
            </>
          )}
          {(remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CRUA' ||
            remunerationRule === 'ATINS' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO' ||
            remunerationRule === 'ENERGIA_AMIGA' ||
            remunerationRule === 'PERCENTUAL_SOBRE_TARIFA_CONSUMO' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_COSIP' ||
            remunerationRule === 'PERCENTUAL_SOBRE_COMPENSADO_DEVOLUCAO') && (
            <InputV2
              id='percentual_sobre_bandeira_valor'
              label='Percentual sobre Bandeira'
              type='number'
            />
          )}
          {(remunerationRule === 'DESCONTO_FIXO' ||
            remunerationRule === 'COTA_SOBRE_VALOR_FIXO') && (
            <InputV2 id='desconto_fixo_valor' label='Valor do Desconto Fixo' type='number' />
          )}
          {remunerationRule === 'TARIFA_FIXA' && (
            <InputV2 id='tarifa_fixa_valor' label='Valor da Tarifa Fixa' type='number' />
          )}
          {remunerationRule === 'VALOR_FIXO' && (
            <InputV2 id='valor_fixo' label='Valor Fixo' type='number' />
          )}
          {remunerationRule === 'SIMPLA' && (
            <>
              <InputV2
                id='percentual_sobre_compensado_valor'
                label='Percentual sobre Compensado'
                type='number'
              />
              <InputV2 id='valor_fixo' label='Valor Fixo' type='number' />
              {/* <InputV2
                    id='mes_operacao'
                    label='Data do início de operação'
                    type='text'
                    onChange={handleChangeDate}
                    value={dateMask}
                  /> */}
            </>
          )}
          {remunerationRule === 'COMPENSADO_USINA' && (
            <InputV2 id='COMPENSADO_USINA' label='Compensado Usina' type='number' />
          )}
          {remunerationRule === 'ISL_SENGI' && (
            <InputV2 id='ISL_SENGI' label='Valor ISL - SENGI' type='number' />
          )}
          {remunerationRule === 'PERCENTUAL_POR_BANDEIRA' && (
            <>
              <InputV2
                id='percentual_por_bandeira_verde'
                label='Percentual sobre Bandeira Verde'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_amar'
                label='Percentual sobre Bandeira Amarela'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_verm_p1'
                label='Percentual sobre Bandeira Vermelha P1'
                type='number'
              />
              <InputV2
                id='percentual_por_bandeira_verm_p2'
                label='Percentual sobre Bandeira Vermelha P2'
                type='number'
              />
            </>
          )}
          {remunerationRule === 'DESCONTO_SOBRE_FATURA_GD' ||
            (remunerationRule === 'COMPENSADO_R4' && (
              <>
                <InputV2
                  id='percentual_sobre_compensado_valor'
                  label='Percentual sobre fatura'
                  type='number'
                />
              </>
            ))}
        </Stack>
      )}
    </>
  )
}
