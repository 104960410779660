import { SettingsOutlined } from '@mui/icons-material'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { TableColumn } from '../../../../interfaces/conference'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'

interface Props {
  tableColumns: TableColumn[]
  setTableColumns: Dispatch<SetStateAction<TableColumn[]>>
  itemsPerPage: number
  setItemsPerPage: (itemsPerPage: number) => void
}

export default function TableConfig(props: Props) {
  const { itemsPerPage, setItemsPerPage, tableColumns, setTableColumns } = props

  const options = useMemo(
    () =>
      tableColumns.map((col) => ({
        label: col.headerName || '',
        checked: !col.hide,
        id: Math.random(),
      })),
    [tableColumns],
  )

  return (
    <FloatingCheckboxes
      pagination={{
        selected: itemsPerPage,
        setSelected: (quantity) => setItemsPerPage(quantity as number),
        options: [15, 20, 30, 50, 100],
      }}
      isFilterActive={false}
      title='Configurar Tabela'
      styles={{ marginRight: 16 }}
      options={options}
      customActionComponent={<ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>}
      submitBtnText='Salvar'
      submitAction={(checkboxes) => {
        setTableColumns((cols) =>
          cols.map((col) => ({
            ...col,
            hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
          })),
        )
      }}
    />
  )
}
