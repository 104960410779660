import { FormikProps } from 'formik'
import { useToast } from '../../../../../hooks/useToast'
import { useEffect, useState } from 'react'
import { getRegraRemuneracao } from '../../../../../services/requests/user-requests'
import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../InputV2'
import { Search } from '@mui/icons-material'
import { RegrasRemuneracaoListRequestType } from '../../Registers/ModalRegisterConsumerUnit/types'

interface Props {
  formik: FormikProps<any>
  required?: boolean
}

export default function RemunerationRuleSelect(props: Props) {
  const { formik, required } = props

  const { errors, values, setFieldValue } = formik

  const { toast } = useToast()

  const [remunerationRules, setRemunerationRules] = useState<RegrasRemuneracaoListRequestType>([])

  const [selectedRemunerationRuleId, setSelectedRemunerationRuleId] = useState<string | null>(
    values.remuneracao_regra,
  )
  const fetchData = async () => {
    try {
      const remunerationRuleData = await getRegraRemuneracao()
      setRemunerationRules(remunerationRuleData.data)
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao listar regras de remuneração',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Autocomplete
      id='remuneracao_regra'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          error={!!errors[params.id]}
          label='Regra de Remuneração'
          required={required}
          {...params}
        />
      )}
      options={remunerationRules.map((remunerationRule) => remunerationRule.value)}
      getOptionLabel={(option) =>
        remunerationRules.find((remunerationRule) => remunerationRule.value === option)?.label ??
        option
      }
      isOptionEqualToValue={(option) => option === selectedRemunerationRuleId}
      value={selectedRemunerationRuleId}
      onChange={(_, value) => {
        setFieldValue('remuneracao_regra', value)
        setSelectedRemunerationRuleId(value)
      }}
    />
  )
}
