import AreaChart from '../../../components/charts/AreaChart'
import React, { useContext, useEffect, useRef, useState } from 'react'
import BarChart from '../../../components/charts/BarChart'
import { HalfCircle } from '../../../components/charts/HalfCircle'
import { InvoicesDonut } from '../../../components/charts/InvoicesDonut'
import { MapChart } from '../../../components/charts/MapChart'
import { InvoiceStatusColor } from '../../../interfaces/invoices'
import {
  AreaChartPayload,
  BarChartPayload,
  DonutChartPayload,
  MapChartPayload,
  PercentageChartPayload,
  WidgetsPayload,
} from '../../../services/requests/types'
import {
  biggestFivePowerPlantsGet,
  dashboardAreaChart,
  dashboardBarChart,
  dashboardMapChart,
  dashboardPercentageChart,
  dashboardWidgetsInfos,
  distributorListGet,
  invoicesOverviewGet,
  powerPlantListGet,
} from '../../../services/requests/user-requests'
import WidgetDetails from '../../../components/WidgetDetails'
import {
  AsideCharts,
  Charts,
  Content,
  DashboardContainer,
  Header,
  MapAndBarChartsContainer,
  RedirectButton,
  RedirectContainer,
  Row,
} from './styles'
import { ReactComponent as BoltIcon } from '../../../assets/bolt.svg'
import { ReactComponent as PowerPlugIcon} from '../../../assets/power-plug.svg'
import { ReactComponent as GreenPowerPlugIcon} from '../../../assets/green-power-plug.svg'
import { ReactComponent as WalletIcon} from '../../../assets/wallet.svg'
import WidgetInfoSmall from '../../../components/WidgetInfoSmall'
import { Alert, Snackbar } from '@mui/material'
import Loading from '../../../components/Loading'
import { handleMoneyFormat } from '../../../utils/formatMoney'
import { IupdateAllChartProps } from '../types'
import { DateFilter } from '../../../components/Filter/DateFilter'
import { useNavigate } from 'react-router-dom'
import { WidgetInfoV2 } from '../../../components/WidgetInfoV2'
import PaymentMethodFilter from '../../Credits/Balance/components/PaymentMethodFilter'

export function DashboardGenerator() {
  const [sideWidth, setSideWidth] = useState(0)
  const [biggestFivePP, setBiggestFivePP] = useState<number[] | null>(null)
  const [uniqueIds, setUniqueIds] = useState<{ id: number; label: string; checked: boolean }[]>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [widgetsData, setWidgetsData] = useState<WidgetsPayload>()
  const [donutChartData, setDonutChartData] = useState<DonutChartPayload>()
  const [percentageChartData, setPercentageChartData] = useState<PercentageChartPayload>()
  const [mapChartData, setMapChartData] = useState<MapChartPayload['usinas']>()
  const [barChartData, setBarChartData] = useState<BarChartPayload[]>()
  const [areaChartData, setAreaChartData] = useState<AreaChartPayload[]>()
  const [selectedColor, setSelectedColor] = useState('')
  const [distributorsId, setDistributorsId] = useState<Array<number>>()
  const [defaultCheckedIds, setDefaultCheckedIds] = useState<Array<number>>([])
  const [isActive, setIsActive] = useState(true)

  const navigate = useNavigate()

  const [loading, setLoading] = useState({
    widgetsData: true,
    donutChartData: true,
    percentageChartData: true,
    mapChartData: true,
    barChartData: true,
    areaChartData: true,
  })

  const [energyType, setEnergyType] = useState({
    label: 'Energia Gerada',
    value: 'gerada',
  })

  const [paymentMethodFilter, setPaymentMethodFilter] = useState<number[]>([])

  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  useEffect(() => {
    Promise.all([biggestFivePowerPlantsGet(), powerPlantListGet(), distributorListGet()])
      .then(async ([bigFiveData, powerPlantData, responseDistributors]) => {
        let bigFiveIds = bigFiveData.data.usinas.map((pp: { id: number; nome: string }) => {
          return pp.id
        })

        if (!bigFiveIds.length) {
          bigFiveIds = powerPlantData.data.map((pp: { id: number; nome: string }) => {
            return pp.id
          })
        }

        const isLoading = !bigFiveData.data.length || !powerPlantData.data.length ? false : true

        setLoading({
          widgetsData: isLoading,
          donutChartData: isLoading,
          percentageChartData: isLoading,
          mapChartData: isLoading,
          barChartData: isLoading,
          areaChartData: isLoading,
        })

        setDefaultCheckedIds(bigFiveIds)

        if (bigFiveIds.length > 0) {
          setUniqueIds(
            powerPlantData.data.map((item) =>
              bigFiveIds.includes(item.id)
                ? {
                    id: item.id,
                    label: item.nome,
                    checked: true,
                  }
                : { id: item.id, label: item.nome, checked: false },
            ),
          )
        } else {
          setIsActive(false)
        }

        setBiggestFivePP(bigFiveIds)

        setDistributorsId(responseDistributors.data.map((item: any) => item.id))
      })
      .catch((err) => {
        setErrorMessage(err.message ?? 'Erro ao buscar maiores usinas.')
      })
  }, [])

  const getSelectedIds = () => {
    if (uniqueIds?.length) {
      let selectedIds

      if (
        uniqueIds?.filter((item) => item.checked).map((item) => item.id).length === 0 &&
        biggestFivePP
      ) {
        selectedIds = biggestFivePP
        setUniqueIds(
          uniqueIds.map((item) =>
            biggestFivePP.includes(item.id)
              ? {
                  ...item,
                  checked: true,
                }
              : { ...item, checked: false },
          ),
        )
      } else {
        selectedIds = uniqueIds?.filter((item) => item.checked).map((item) => item.id)
      }

      return selectedIds
    }
  }

  const updateAllChart = ({ month, year }: IupdateAllChartProps) => {
    const selectedIds = getSelectedIds()

    const filter = {
      ...(paymentMethodFilter && paymentMethodFilter.length > 0 ? { paymentMethod: paymentMethodFilter } : {})
    }

    if (selectedIds?.length) {
      setLoading({
        widgetsData: true,
        donutChartData: true,
        percentageChartData: true,
        mapChartData: true,
        barChartData: true,
        areaChartData: true,
      })

      // widgetsData
      dashboardWidgetsInfos({ usinas_id: selectedIds, month, year, filter })
        .then((res) => setWidgetsData(res.data))
        .catch(() => setErrorMessage('Algo deu errado ao carregar informações gerais.'))
        .finally(() => setLoading((prev) => ({ ...prev, widgetsData: false })))

      // donutChartData
      invoicesOverviewGet({
        distributorsId: distributorsId ?? [],
        page: 1,
        powerPlantsIds: selectedIds,
        isPowerPlant: 'true',
        year,
        month,
        isDashboard: true,
        customFilter: filter,
      })
        .then((res) => setDonutChartData(res.data))
        .catch(() =>
          setErrorMessage('Algo deu errado ao carregar informações do gráfico de donut.'),
        )
        .finally(() => setLoading((prev) => ({ ...prev, donutChartData: false })))

      // percentageChartData
      dashboardPercentageChart({ usinas_id: selectedIds, month, year, filter })
        .then((res) => setPercentageChartData(res.data))
        .catch(() =>
          setErrorMessage('Algo deu errado ao carregar informações do gráfico de porcentagem.'),
        )
        .finally(() => setLoading((prev) => ({ ...prev, percentageChartData: false })))

      // mapChartData
      dashboardMapChart(selectedIds, filter)
        .then((res) => setMapChartData(res.data.usinas))
        .catch(() => setErrorMessage('Algo deu errado ao carregar informações do mapa.'))
        .finally(() => setLoading((prev) => ({ ...prev, mapChartData: false })))

      // barChartData
      dashboardBarChart({ usinas_id: selectedIds, month, year, filter })
        .then((res) => setBarChartData(res.data ? res.data : []))
        .catch(() =>
          setErrorMessage('Algo deu errado ao carregar informações do balanço energético.'),
        )
        .finally(() => setLoading((prev) => ({ ...prev, barChartData: false })))

      // areaChartData
      dashboardAreaChart({
        usinas_id: selectedIds,
        energyType: energyType.value,
        month,
        year,
        filter,
      })
        .then((res) => setAreaChartData(res.data ? res.data : []))
        .catch(() =>
          setErrorMessage('Algo deu errado ao carregar informações do gráfico principal.'),
        )
        .finally(() => setLoading((prev) => ({ ...prev, areaChartData: false })))
    }
  }

  useEffect(() => {
    if (uniqueIds) {
      updateAllChart(dateSelected)
    }
  }, [uniqueIds, dateSelected, paymentMethodFilter])

  useEffect(() => {
    const handleEnergyUpdate = async () => {
      setLoading((prev) => ({ ...prev, areaChartData: true }))
      const selectedIds = getSelectedIds()
      if (selectedIds?.length) {
        try {
          const areaChartResponse = await dashboardAreaChart({
            usinas_id: selectedIds,
            energyType: energyType.value,
            month: dateSelected.month,
            year: dateSelected.year,
          })
          setAreaChartData(areaChartResponse.data)
          setLoading((prev) => ({ ...prev, areaChartData: false }))
        } catch (error: any) {
          setLoading((prev) => ({ ...prev, areaChartData: false }))
          setErrorMessage(error.message ?? 'Erro ao atualizar dados do gráfico de área.')
        }
      }
    }

    handleEnergyUpdate()
  }, [energyType, dateSelected])

  if (!loading && !uniqueIds?.length) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
      >
        <h3>Nenhuma usina cadastrada foi encontrada.</h3>
      </div>
    )
  }

  const handleRedirect = () => {
    navigate('/invoices/distributor')
  }

  return (
    <DashboardContainer>
      {isActive && (
        <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      )}

      {widgetsData && (
        <Header>
          {loading.widgetsData ? (
            <Loading />
          ) : (
            <>
              <WidgetInfoV2
                icon={BoltIcon}
                title='Energia Gerada'
                value={Math.trunc(widgetsData.energia_gerada.valor)}
                inRelationToText='em relação ao mês anterior'
                variation={widgetsData.energia_gerada.percentual ?? 0}
              />
              <WidgetInfoV2
                icon={PowerPlugIcon}
                title='Energia Consumida'
                value={Math.trunc(widgetsData.energia_consumida.valor)}
                inRelationToText='em relação ao mês anterior'
                variation={widgetsData.energia_consumida.percentual ?? 0}
              />
              <WidgetInfoV2
                icon={GreenPowerPlugIcon}
                title='Energia Compensada'
                value={Math.trunc(widgetsData.energia_compensada.valor)}
                inRelationToText='em relação ao mês anterior'
                variation={widgetsData.energia_compensada.percentual ?? 0}
              />
              <WidgetInfoV2
                icon={WalletIcon}
                title='Saldo de Créditos'
                value={Math.trunc(widgetsData.saldo_creditos.valor)}
                inRelationToText='em relação ao mês anterior'
                variation={widgetsData.saldo_creditos.percentual ?? 0}
              />
            </>
          )}
        </Header>
      )}
      <Content>
        {isActive ? (
          <Charts>
            {loading.areaChartData ? (
              <Loading />
            ) : (
              areaChartData &&
              uniqueIds && (
                <div style={{ marginBottom: 17 }}>
                  <AreaChart
                    defaultCheckedIds={defaultCheckedIds}
                    dateSelected={dateSelected}
                    ids={uniqueIds}
                    setSelectedItems={setUniqueIds}
                    data={areaChartData}
                    energyType={energyType}
                    setEnergyType={setEnergyType}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    getSelectedIds={getSelectedIds}
                    // TODO: temporarily removed until we have all the clients with the necessary data to make this query without breaking
                    // extraFilters={(
                      // <PaymentMethodFilter 
                      //   showActiveState
                      //   paymentMethodFilter={paymentMethodFilter}
                      //   setPaymentMethodFilter={setPaymentMethodFilter}
                      // />
                    // )}
                  />
                </div>
              )
            )}

            <MapAndBarChartsContainer>
              {loading.mapChartData ? (
                <Loading />
              ) : (
                mapChartData && (
                  <MapChart
                    key={mapChartData.toString()}
                    markers={mapChartData.map(({ latitude, longitude, nome }) => ({
                      coordinates: { lat: latitude, long: longitude },
                      name: nome,
                    }))}
                  />
                )
              )}
              {barChartData && !loading.mapChartData && (
                <BarChart
                  dateSelected={dateSelected}
                  data={barChartData}
                  getSelectedIds={getSelectedIds}
                />
              )}
            </MapAndBarChartsContainer>
          </Charts>
        ) : (
          <RedirectContainer>
            <h3>Não há dados suficientes</h3>
            <RedirectButton onClick={handleRedirect}>Página de Faturas</RedirectButton>
          </RedirectContainer>
        )}
        <AsideCharts style={{ maxWidth: sideWidth, minWidth: '298px' }}>
          {loading.widgetsData ? (
            <Loading />
          ) : (
            widgetsData && (
              <>
                <WidgetDetails
                  style={{ height: 57 }}
                  title='Tarifa de Aluguel'
                  orientation='row'
                  value={`R$ ${Math.trunc(1000 * widgetsData.preco_medio_venda).toLocaleString(
                    'pt-BR',
                  )}/MWh`}
                />
                <WidgetDetails
                  style={{ height: 57 }}
                  title='Faturamento Estimado'
                  orientation='row'
                  value={`R$ ${
                    Number(
                      widgetsData.faturamento_estimado
                        ? widgetsData.faturamento_estimado.toFixed(2)
                        : '0,00',
                    ).toLocaleString('pt-BR') === 'NaN'
                      ? '0,00'
                      : Number(
                          widgetsData.faturamento_estimado
                            ? widgetsData.faturamento_estimado.toFixed(2)
                            : '0,00',
                        ).toLocaleString('pt-BR')
                  }`}
                />
                <Row>
                  <WidgetInfoSmall
                    title='Quantidade de UCs'
                    value={widgetsData.numero_ucs.valor}
                    variation={widgetsData?.numero_ucs.percentual ?? 0}
                    inRelationToText='no último mês'
                  />
                  <WidgetInfoSmall
                    title='Quantidade de Usinas'
                    value={widgetsData.numero_usinas.valor}
                    variation={widgetsData?.numero_usinas.percentual ?? 0}
                    inRelationToText='no último mês'
                  />
                </Row>
                <Row>
                  <WidgetDetails
                    style={{ width: '48%', height: 104 }}
                    title='Custo das Usinas'
                    orientation='column'
                    value={`${handleMoneyFormat(widgetsData.demanda_usinas, true)}`}
                  />
                  <WidgetDetails
                    style={{ width: '48%' }}
                    title='Economia do Consumidor'
                    orientation='column'
                    value={`${handleMoneyFormat(widgetsData.ganho_consumidor, true)}`}
                  />
                </Row>
              </>
            )
          )}
          {donutChartData && (
            <InvoicesDonut
              styles={{ display: 'flex', marginTop: '14px', flexDirection: 'column' }}
              legendPosition='right'
              isLoading={loading.donutChartData}
              data={[
                {
                  label: 'Completas',
                  value: donutChartData.completas,
                  color: InvoiceStatusColor['Completas'],
                },
                {
                  label: 'Aguardando emissão',
                  value: donutChartData.aguardando_emissao,
                  color: InvoiceStatusColor['Aguardando emissão'],
                },
                {
                  label: 'Não capturadas',
                  value: donutChartData.nao_capturadas,
                  color: InvoiceStatusColor['Não capturadas'],
                },
                // {
                //   label: 'Não obtidas',
                //   value: donutChartData.nao_processadas,
                //   color: InvoiceStatusColor['Não obtidas'],
                // },
              ]}
            />
          )}
          {loading.percentageChartData ? (
            <Loading />
          ) : (
            percentageChartData && (
              <HalfCircle
                value={percentageChartData.compensado_por_consumido}
                styles={{ marginBottom: 20 }}
              />
            )
          )}
        </AsideCharts>
      </Content>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(null)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </DashboardContainer>
  )
}
