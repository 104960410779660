import { Dispatch, SetStateAction, useState } from 'react'
import {
  BulkUpdateConsumers,
  GetConsumersFromCsv,
  onboardingService,
} from '../../../../../services/onboarding'
import EntityItems from '../EntityItems'
import { useToast } from '../../../../../hooks/useToast'

interface Props {
  managerId: number
  consumersFromCsv: GetConsumersFromCsv['result']
  setConsumersFromCsv: Dispatch<SetStateAction<GetConsumersFromCsv['result'] | undefined>>
}

export default function ConsumersToUpdate(props: Props) {
  const { managerId, consumersFromCsv, setConsumersFromCsv } = props

  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const bulkUpdateConsumers = async (
    values: BulkUpdateConsumers['params']['consumers'],
  ): Promise<void> => {
    try {
      setLoading(true)
      await onboardingService.bulkUpdateConsumers({
        managerId,
        consumers: values,
      })
      setConsumersFromCsv((previousPlants) =>
        previousPlants
          ? {
              ...previousPlants,
              consumersToUpdate: [...consumersFromCsv.consumersToUpdate].filter(
                (currentUc) =>
                  !values.find((val) => val.documento_numero === currentUc.documentNumber),
              ),
            }
          : undefined,
      )
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao atualizar consumidor',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <EntityItems
      isLoading={isLoading}
      errors={consumersFromCsv.errors}
      itemsCompleted={consumersFromCsv.consumersCreatedCount}
      itemsToUpdate={consumersFromCsv.consumersToUpdate.length}
      entities={consumersFromCsv.consumersToUpdate}
      compareSearchFilter={(searchFilter, consumer) =>
        consumer.documentNumber.toLowerCase().includes(searchFilter)
      }
      getEntityIdentifier={(consumer) => consumer.documentNumber}
      bulkUpdate={(consumers) => {
        bulkUpdateConsumers(
          consumers.map((consumer) => ({
            ...Object.entries(consumer.changes).reduce(
              (agg, [field, { after }]) => ({
                ...agg,
                [field]: after,
              }),
              {},
            ),
            documento_numero: consumer.documentNumber,
          })),
        )
      }}
      getUpdateFormProps={(consumer) => {
        return {
          title: `${consumer.documentNumber}`,
          changes: consumer.changes,
          onSubmit: (values) =>
            bulkUpdateConsumers([{ ...values, documento_numero: consumer.documentNumber }]),
        }
      }}
    />
  )
}
