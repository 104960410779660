import { HTMLAttributes, useState } from 'react'
import { consumptionModalityArr } from '../../../../../components/Modal/Credits/ModalAddQuota/types'
import { OptionItem, OptionList } from '../../../../Tickets/styles'
import { FloatingFilterWrapper } from '../../../../../components/FloatingFilterWrapper'

function getSelectAllConsumptionModality(arr: any) {
  const enumArr = arr.map((consumption: any) => {
    const keyValue = Object.keys(consumption)?.shift()
    const index = keyValue != undefined ? consumption[keyValue as keyof typeof consumption] : ''

    return {
      label: keyValue,
      value: index,
    }
  })

  return enumArr
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  compensationModalityFilter?: string
  setCompensationModalityFilter: (value: string) => void
}

export default function CompensationModalityFilter(props: Props) {
  const { compensationModalityFilter, setCompensationModalityFilter } = props

  const [isStatusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive
      label='Modalidade de Compensação'
      styles={props.style}
      modalStyles={{ padding: 4 }}
      hideSubmitButton
    >
      <OptionList showDropDown={isStatusDropdownOpen}>
        {getSelectAllConsumptionModality(consumptionModalityArr).map((item: any) => (
          <OptionItem
            active={compensationModalityFilter === item.value}
            key={item.value}
            onClick={() => {
              setStatusDropdownOpen((previousStatusDropdownOpen) => !previousStatusDropdownOpen)
              const updatedValue = compensationModalityFilter === item.value ? '' : item.value;
              setCompensationModalityFilter(updatedValue)
            }}
          >
            {item.label}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
