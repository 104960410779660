import React, { useEffect, useRef, useState } from 'react'
import { FieldAttributes, FieldHookConfig, useField } from 'formik'
import { Label } from '../FormikInput/styles'
import Tooltip from '../../Tooltip'
import {
  ErrorText,
  Icons,
  OptionItem,
  OptionList,
  SelectContainer,
  SelectInput,
  SelectInputWrapper,
} from './styles'
import ChevronDown from '../../../assets/chevron-down.svg'
import ChevronUp from '../../../assets/icons/chevron-up.svg'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

interface SelectProps extends FieldAttributes<any> {
  label: string
  tooltip?: boolean
  tooltipText?: string
  required?: boolean
  responseData?: {
    label: string
    value: number | string
  }[]
  keyName: string
  error?: string
  messageError?: string
  setSelectedValue?: React.Dispatch<React.SetStateAction<number | undefined>>
}

const FormikSelect = ({
  label,
  tooltip,
  tooltipText,
  required,
  responseData,
  keyName,
  error,
  messageError,
  setSelectedValue,
  ...props
}: SelectProps) => {
  const [field] = useField(props as string | FieldHookConfig<any>)
  const [showDropDown, setShowDropDown] = useState(false)
  const [customError, setCustomError] = useState<string | null>(null)
  const [inputLabel, setInputLabel] = useState<string>('')
  const [focus, setFocus] = useState(false)
  const optionRef = useRef(null)

  const modalOptionsRef = useRef<HTMLUListElement>(null)

  const isActionFromFocusRef = useRef<boolean>(false)

  const closeDropdown = () => {
    setFocus(false)
    setShowDropDown(false)
  }

  useOnClickOutside(modalOptionsRef, () => {
    if (!inputLabel?.trim() && messageError) {
      closeDropdown()
      setCustomError(messageError!)
    }
  })

  useEffect(() => {
    if (!optionRef.current || (responseData && responseData?.length === 0)) return

    const getData = responseData?.find((item: any) => item.value === field.value)
    setInputLabel(getData?.label ?? '')
  }, [responseData])

  setSelectedValue &&
    useEffect(() => {
      setSelectedValue(field.value as number)
    }, [field])

  return (
    <SelectContainer>
      <Label mode required={required} htmlFor={props.id || props.name}>
        {label}
        {tooltip && <Tooltip title={tooltipText || ''} />}
      </Label>
      <SelectInputWrapper
        style={{ position: 'relative' }}
        onClick={() => {
          if (isActionFromFocusRef.current) {
            isActionFromFocusRef.current = false
            return
          }
          setCustomError(null)
          setShowDropDown(true)
          setFocus(true)
        }}
      >
        <>
          <SelectInput
            {...field}
            {...props}
            onBlur={(e) => {
              setTimeout(() => {
                field.onBlur(e)
                setCustomError(null)
                closeDropdown()
              }, 200)
            }}
            showDropDown={showDropDown}
            value={inputLabel}
            ref={optionRef}
            readOnly
            required={required}
            onFocus={() => {
              setCustomError(null)
              setShowDropDown(true)
              setFocus(true)
              isActionFromFocusRef.current = true
            }}
          />
          {showDropDown ? <Icons src={`${ChevronUp}`} /> : <Icons src={`${ChevronDown}`} />}
        </>
      </SelectInputWrapper>

      {focus && !customError && (
        <OptionList ref={modalOptionsRef}>
          {responseData?.map((item: any, index) => (
            <OptionItem
              key={index}
              {...field}
              {...props}
              onClick={() => {
                setInputLabel(!item[keyName] ? item?.label : item[keyName])
                props.setFieldValue(`${props.name}`, !item.id ? item.value : item.id)
                closeDropdown()
              }}
            >
              {!item[keyName] ? item?.label : item[keyName]}
            </OptionItem>
          ))}
        </OptionList>
      )}

      {customError || error ? <ErrorText>{customError || error}</ErrorText> : null}
    </SelectContainer>
  )
}

export default FormikSelect
