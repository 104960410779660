import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import Modal from '..'

import { ButtonsContainer, CancelButton, ContainerList, PaymentButton, SendButton } from './styles'

import { EditPaymentModalProps } from './types'
import ModalSuccess from '../ModalSuccess'
import { useToast } from '../../../hooks/useToast'

export function PaymentExcel({
  title,
  subtitle,
  openModal,
  setModalOpen,
  selectedDate,
  successText,
  handleDownload,
}: EditPaymentModalProps) {
  const [modalSuccess, setModalSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selected, setSelected] = useState<string[]>([])
  const [dates, setDates] = useState<string[]>([])

  const { toast } = useToast()

  function getPreviousMonths({ month, year }: { month: number; year: number }): string[] {
    const months: string[] = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ]

    const result: string[] = []

    for (let i = 0; i < 12; i++) {
      if (month - i <= 0) {
        result.unshift(`${months[month - i + 11]} - ${year - 1}`)
      } else {
        result.unshift(`${months[month - i - 1]} - ${year}`)
      }
    }

    return result
  }

  function getMonthIndex(month: string): string {
    const months: string[] = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ]

    return String(months.indexOf(month) + 1).padStart(2, '0')
  }

  function sendData() {
    ;(async () => {
      setIsLoading(true)

      const monthRefs = []

      for (const date of selected) {
        const [month, year] = date.split(' - ')

        monthRefs.push(`${year}-${getMonthIndex(month)}-01`)
      }

      try {
        await handleDownload(monthRefs)
        setModalSuccess(true)
        setModalOpen(false)
        setSelected([])
      } catch (error) {
        toast({
          message: 'Erro ao baixar planilha',
          type: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    })()
  }

  useEffect(() => {
    setSelected([])
    if (selectedDate) {
      setDates(getPreviousMonths(selectedDate))

      setSelected([getPreviousMonths(selectedDate).reverse()[0]])
    }

    setIsLoading(false)
  }, [selectedDate])

  function selectDate(val: string) {
    if (selected.includes(val)) {
      setSelected((old) => old.filter((filtered) => filtered !== val))
    } else {
      setSelected((old: string[]) => [...old, val])
    }
  }

  return (
    <>
      {!modalSuccess ? (
        <Modal
          title={title ?? 'Download de planilha de pagamentos'}
          openModal={openModal}
          setModalOpen={setModalOpen}
          defaultButtons={false}
          subtitle={
            subtitle ?? 'Selecione os meses dos pagamentos em que deseja adicionar a planilha'
          }
        >
          <ContainerList>
            {dates.map((val) => (
              <PaymentButton
                onClick={() => selectDate(val)}
                active={selected.includes(val)}
                key={val}
              >
                {val}
              </PaymentButton>
            ))}
          </ContainerList>
          <ButtonsContainer>
            <CancelButton
              disabled={false}
              type='button'
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Cancelar
            </CancelButton>
            <SendButton onClick={sendData} disabled={isLoading}>
              {isLoading ? <CircularProgress size={20} /> : `Baixar`}
            </SendButton>
          </ButtonsContainer>
        </Modal>
      ) : (
        <ModalSuccess
          openModal={modalSuccess}
          setModalOpen={setModalSuccess}
          buttonColor='darkGreen'
          buttonText='Ok, obrigado'
          text={successText ?? `Planilha de pagamento baixada com sucesso!`}
        />
      )}
    </>
  )
}
