import { darken } from 'polished'
import styled from 'styled-components'

export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const WidgetsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 0 16px;

  & path {
    fill: ${({ theme }) => theme.colors.darkGreen};
  }
`

export const SettingsContainer = styled.div`
  margin-top: 19px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 16px;
`

export const FiltersContainer = styled.div`
  display: flex;
  gap: 0 16px;
`

export const ConfigButtonsColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
`

export const StatusText = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;

  &.charged {
    color: ${({ theme }) => theme.colors.quartzGrey};
  }

  &.notCharged {
    color: ${({ theme }) => theme.colors.orange};
  }

  &.received {
    color: ${({ theme }) => theme.colors.appleGreen};
  }

  &.expired {
    color: ${({ theme }) => theme.colors.suportError};
  }
`

export const RowText = styled.span`
  font-size: 14px;
  line-height: 16px;
`

export const OptionList = styled.ul<{ showDropDown?: boolean; emptyPlaceholder?: boolean }>`
  width: 124px;
  max-height: 190px;
`

export const OptionItem = styled.li<{ active?: boolean }>`
  list-style-type: none;
  cursor: pointer;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  padding: 10px 10px 10px 13px;
  border-radius: ${({ active }) => (active ? '8px' : '0px')};
  background-color: ${({ active, theme }) =>
    active ? darken(0.07, theme.colors.white) : theme.colors.white};
  color: ${({ active, theme }) => (active ? theme.colors.darkishGreen : theme.colors.htmlGrey)};

  &:hover {
    border-radius: 8px;
    background-color: #f1f1f1;
  }
`

export const DisabledAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.suportError};
`

export const PositiveAcess = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkishGreen};
`

export const ContainerPreviewInvoice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const DefaultClientContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const DefaultClientItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
