import { PropsWithChildren, useMemo } from 'react'
import { ListOrchestratorOverview } from '../../../../../services/orchestrator'
import Widget from '../../../../../components/Widget'
import { Theme } from '../../../../../styles/theme'
import { Typography } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface Props {
  chartData: ListOrchestratorOverview['result']
}

export default function OverviewBarChart(props: PropsWithChildren<Props>) {
  const { chartData } = props

  const availableStatus = useMemo(
    () => {
      const statusList = Array.from(
        chartData.reduce((agg, data) => {
          for (const attempt of data.attempts) {
            agg.add(attempt.attemptResult)
          }
          return agg
        }, new Set<string>()),
      )
      statusList.sort((a, b) => {
        if (a === 'SUCCESS' && b !== 'SUCCESS') {
          return -1
        } else if (a !== 'SUCCESS' && b === 'SUCCESS') {
          return 1
        } else {
          return 0
        }
      })
      return statusList
    },
    [chartData],
  )

  return (
    <Widget
      style={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '550px',
        width: '100%',
      }}
    >
      <Typography marginBottom={2} variant='h6' fontWeight='bold'>
        Visualização diária
      </Typography>

      <div style={{ width: '100%', height: '400px' }}>
        <Bar
          options={{
            plugins: {
              legend: {
                position: 'bottom',
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                beginAtZero: true,
                stacked: true,
              },
            },
          }}
          data={{
            labels: chartData.map((data) => data.date),
            datasets: availableStatus.map((status) => ({
              label: status,
              data: chartData.map(
                (data) =>
                  data.attempts.find((attempt) => attempt.attemptResult === status)?.total ?? 0,
              ),
              backgroundColor: status === 'SUCCESS' ? Theme.colors.darkishGreen : '#FF6347',
              categoryPercentage: 0.8,
              barPercentage: 0.7,
              maxBarThickness: 50,
            })),
          }}
        />
      </div>
    </Widget>
  )
}
