import { CircularProgress } from '@mui/material'
import React from 'react'
import { ButtonField, Container } from './styles'
import { ButtonInterface } from './types'

export function Button({
  text,
  containerStyle,
  mode = 'darkGreen',
  action,
  textSize,
  type,
  isLoading = false,
  ...rest
}: ButtonInterface) {
  return (
    <Container style={containerStyle} onClick={action} mode={mode}>
      <ButtonField
        type={type ? type : 'button'}
        {...rest}
        style={{ fontSize: textSize || 16 }}
        mode={mode}
      >
        {isLoading ? <CircularProgress size={20} /> : text}
      </ButtonField>
    </Container>
  )
}
