import { GridRenderCellParams } from '@mui/x-data-grid'
import { DisabledAcess, PositiveAcess, RowText, StatusText } from './styles'
import { statusPaymentStyle } from '../Invoices/utils/selectedObservationFieldColor'

export const tableColumns = [
  {
    field: 'nome',
    headerName: 'Distribuidora',
    sortable: true,
  },
  {
    field: 'subgrupo',
    headerName: 'Subgrupo',
    sortable: true,
  },
  {
    field: 'inicio',
    headerName: 'Início',
    sortable: true,

    renderCell(params: GridRenderCellParams<any, any, any>) {
      const date = new Date(params.value)
      const formattedDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' })
      return <RowText>{formattedDate.format(date)}</RowText>
    },
  },
  {
    field: 'fim',
    headerName: 'Fim',
    sortable: true,

    renderCell(params: GridRenderCellParams<any, any, any>) {
      const date = new Date(params.value)
      const formattedDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' })
      return <RowText>{formattedDate.format(date)}</RowText>
    },
  },
  {
    field: 'posto',
    sortable: true,
    headerName: 'Posto',
  },
  {
    field: 'tarifa_te',
    headerName: 'Tarifa TE',
    sortable: true,
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return (
        <>
          {params.row.unidade === 'kw'
            ? params.value
            : parseFloat(String(parseFloat(params.value) / 10)).toFixed(2)}
        </>
      )
    },
  },
  {
    field: 'tarifa_tusd',
    headerName: 'Tarifa TUSD',
    sortable: true,
    renderCell(params: GridRenderCellParams<any, any, any>) {
      return (
        <>
          {params.row.unidade === 'kw'
            ? params.value
            : parseFloat(String(parseFloat(params.value) / 1000)).toFixed(2)}
        </>
      )
    },
  },
]
