import * as Yup from 'yup'
import { GetResponseData } from './types'

export const yupSchema = Yup.object({
  powerPlantId: Yup.string().required('Selecione uma usina.'),
  year: Yup.number().required('Selecione um ano.'),
  month: Yup.string().required('Selecione um mês.'),
})

export async function getResponseData({ responseGet, responseData }: GetResponseData) {
  try {
    const response = await responseGet()

    const payload = response.data.map((item: {
      id: number,
      nome: string
    }) => {
      return {
        label: item.nome,
        value: item.id
      }
    })

    responseData(payload);
  } catch (error: any) {
    console.log(error.message)
  }
}
