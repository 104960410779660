import { PaginationQuery } from '../interfaces/pagination'
import { PaymentsBalanceSheet } from '../interfaces/payments'
import { convertObjectToQueryParams } from '../utils/pagination'
import { api } from './api'

interface GetPaymentBalanceSheetAllgreen {
  params: PaginationQuery & {
    distributorsId: number[]
    powerPlantsId: number[]
    day?: number
    monthsRefs: string[]
    status?: number
    companies?: number[]
  }
  result: PaymentsBalanceSheet
}

interface GetPaymentBalanceSheet {
  params: PaginationQuery & {
    distributorsId: number[]
    powerPlantsId: number[]
    dueDayReference?: number
    monthReference: string
    status?: number
    companies?: number[]
  }
  result: PaymentsBalanceSheet
}

export class PaymentsService {
  async getBalanceSheetAllgreen(
    params: GetPaymentBalanceSheetAllgreen['params'],
  ): Promise<GetPaymentBalanceSheetAllgreen['result']> {
    const {
      sort,
      distributorsId,
      powerPlantsId,
      day,
      monthsRefs,
      status,
      itemsPerPage,
      page,
      filter,
      companies
    } = params

    const queryParams = {
      distributorsIds: distributorsId.join(','),
      powerPlantsIds: powerPlantsId.join(','),
      companies: companies?.join(','),
      ...(sort
        ? {
            order: sort.order,
            field: sort.field,
          }
        : {
            field: 'cliente_consumidor',
            order: 'asc',
          }),
      ...(filter && Object.keys(filter).length > 0
        ? {
            filter: convertObjectToQueryParams(filter)
          }
        : {}),
      ...(itemsPerPage ? { itemsPerPage } : {}),
      ...(status ? { status } : {}),
      ...(page ? { page } : {}),
      ...(day ? { day } : {}),
      months: monthsRefs.join(','),
    }

    const result = await api.get('/pagamentos/tabelav2', {
      params: queryParams,
    })
    return result.data
  }

  async getBalanceSheet(
    params: GetPaymentBalanceSheet['params'],
  ): Promise<GetPaymentBalanceSheet['result']> {
    const {
      sort,
      distributorsId,
      powerPlantsId,
      dueDayReference,
      monthReference,
      status,
      itemsPerPage,
      page,
      filter,
      companies
    } = params

    const queryParams = {
      distributorsId: distributorsId.join(','),
      powerPlantsId: powerPlantsId.join(','),
      companies: companies?.join(','),
      ...(sort
        ? {
            order: sort.order,
            field: sort.field,
          }
        : {
            field: 'cliente_consumidor',
            order: 'asc',
          }),
      ...(filter && Object.keys(filter).length > 0
        ? {
            filter: convertObjectToQueryParams(filter)
          }
        : {}),
      ...(itemsPerPage ? { itemsPerPage } : {}),
      ...(status ? { status } : {}),
      ...(page ? { page } : {}),
      ...(dueDayReference ? { dueDayReference } : {}),
      monthReference,
    }
    const result = await api.get('/pagamentos/tabelav3', {
      params: queryParams,
    })
    return result.data
  }
}

export const paymentsService = new PaymentsService()
