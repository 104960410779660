import { useState } from 'react'
import { FloatingFilterWrapper } from '../../../../components/FloatingFilterWrapper'
import { OptionItem, OptionList } from '../../styles'
import { ticketCategories } from '../../categories'

interface Props {
  categoryFilter: string
  setCategoryFilter: (value: string) => void
}

export default function CategoryFilter(props: Props) {
  const { categoryFilter, setCategoryFilter } = props

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive
      label='Categoria'
      modalStyles={{ padding: 4, overflowY: 'auto', overflowX: 'unset' }}
      hideSubmitButton
    >
      <OptionList showDropDown={isDropdownOpen}>
        {ticketCategories.map((category) => (
          <OptionItem
            active={categoryFilter === category.label}
            key={`category-filter-${category.label}`}
            onClick={() => {
              setDropdownOpen((previousStatusDropdownOpen) => !previousStatusDropdownOpen)
              const updatedValue = categoryFilter === category.label ? '' : category.label;
              setCategoryFilter(updatedValue)
            }}
          >
            {category.label}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
