import { IconButton, Stack, Tooltip } from '@mui/material'
import { TableV2 } from '../../../components/TableV2'
import { Button } from '../../../components/Button'
import { useEffect, useState } from 'react'
import PaymentMethodModal from './components/PaymentMethodModal'
import { ListPaymentMethodsSuper, paymentMethodsService } from '../../../services/payment-method'
import { useToast } from '../../../hooks/useToast'
import { Edit } from '@mui/icons-material'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'
import { PaginationQuery } from '../../../interfaces/pagination'
import Loading from '../../../components/Loading'

export default function PaymentMethodsPage() {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<number>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [paymentMethods, setPaymentMethods] = useState<ListPaymentMethodsSuper['result']>()
  const [paymentMethodsParams, setPaymentMethodsParams] = useState<PaginationQuery>({
    page: 1,
    itemsPerPage: 15,
  })

  const { toast } = useToast()

  const fetchPaymentMethods = async () => {
    try {
      setLoading(true)
      const paymentMethodsResult = await paymentMethodsService.listPaymentMethodsSuper(
        paymentMethodsParams,
      )
      setPaymentMethods(paymentMethodsResult)
      setLoading(false)
    } catch (error) {
      toast({
        message: 'Erro ao listar métodos de pagamento',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    fetchPaymentMethods()
  }, [paymentMethodsParams])

  return (
    <>
      {typeof selectedPaymentMethodId !== 'undefined' && (
        <PaymentMethodModal
          paymentMethodId={selectedPaymentMethodId ?? undefined}
          onSuccess={fetchPaymentMethods}
          onClose={() => setSelectedPaymentMethodId(undefined)}
        />
      )}

      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <Stack style={{ marginBottom: 8 }}>
            <Button
              containerStyle={{ width: 'fit-content', margin: '0 0 0 auto' }}
              onClick={() => setSelectedPaymentMethodId(0)}
              text='Cadastrar método'
            />
          </Stack>

          {paymentMethods && (
            <TableV2
              alignText='center'
              columns={[
                {
                  field: 'id',
                  headerName: 'ID',
                },
                {
                  field: 'clientName',
                  headerName: 'Cliente',
                },
                {
                  field: 'consortiumName',
                  headerName: 'Consórcio',
                },
                {
                  field: 'platform',
                  headerName: 'Banco',
                },
                {
                  field: 'actions',
                  headerName: '',
                  width: 190,
                  sortable: false,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Tooltip title={<p style={{ fontSize: 15 }}>Editar</p>} placement='bottom'>
                          <IconButton onClick={() => setSelectedPaymentMethodId(params.row.id)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )
                  },
                },
              ]}
              rows={paymentMethods.data.map((paymentMethod) => ({
                id: paymentMethod.id,
                clientName: paymentMethod.manager.name,
                consortiumName: paymentMethod.name,
                platform: paymentMethod.platform,
              }))}
            >
              <TablePagination
                currentPageItemCount={paymentMethods.data.length}
                pageInfo={paymentMethods.pageInfo}
                onChange={(page) =>
                  setPaymentMethodsParams((previousParams) => ({ ...previousParams, page }))
                }
              />
            </TableV2>
          )}
        </>
      )}
    </>
  )
}
