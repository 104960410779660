import * as yup from 'yup'
import { useCallback, useState } from "react"
import { useNavigate } from 'react-router-dom'

import { useGlobalContext, userDashboard } from "../context/global/global"
import { loginAction } from "../context/global/reducers/actions"
import { useToast } from './useToast'

export const useLogin = () => {
  const { dispatch } = useGlobalContext()
  const { toast } = useToast()
  const navigate = useNavigate()

  const [formState, setFormState] = useState<FormState>({
    isLoading: false,
    isError: false,
  })

  const updateState = useCallback(
    <K extends keyof FormState, T extends FormState[K]>(key: K, value: T) =>
      setFormState(prev => ({ ...prev, [key]: value })
      ), [setFormState])

  const handleLogin = async (params: LoginParams) => {
    updateState('isLoading', true)

    try {
      const {
        email,
        password
      } = await loginSchema.validate(params)

      const user = await loginAction({
        email: email.trim(),
        password: password.trim(),
      })

      dispatch(user)
      const { isProvisoryPassword, customer } = user?.payload ?? {}

      if (isProvisoryPassword) {
        navigate('/settings')
        return
      }

      const customerTypeKey = customer.type as keyof typeof userDashboard
      const customerType = userDashboard[customerTypeKey]

      updateState('isLoading', false)
      navigate(customerType)
    } catch (err) {
      toast({
        type: 'error',
        message: 'Credenciais inválidas'
      })

      setFormState(prev => ({ ...prev, isLoading: false, isError: true }))
    }
  }

  return { handleLogin, formState }
}

const loginSchema = yup.object({
  email: yup.string().email().trim().required(),
  password: yup.string().trim().required()
})

type LoginParams = yup.InferType<typeof loginSchema>
type FormState = {
  isLoading: boolean
  isError: boolean
}
