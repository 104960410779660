import { FormikProps } from "formik"
import { useState } from "react"
import { InputV2 } from "../../../../../../InputV2"
import { Autocomplete } from "@mui/material"
import { Search } from "@mui/icons-material"

const classifications = [
  {
    label: 'GD 1',
    value: 'GD1',
  },
  {
    label: 'GD 2',
    value: 'GD2',
  },
  {
    label: 'GD 3',
    value: 'GD3',
  },
]

interface Props {
  formik: FormikProps<any>
}

export default function ClassificationSelect(props: Props) {
  const { formik } = props

  const { errors, values, setFieldValue } = formik

  const [selectedClassification, setSelectedClassification] = useState<string | null>(
    values.classificacao,
  )

  return (
    <Autocomplete
      id='classificacao'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          error={!!errors[params.id]}
          label='Classificação'
          {...params}
        />
      )}
      options={classifications.map((remunerationRule) => remunerationRule.value)}
      getOptionLabel={(option) =>
        classifications.find((remunerationRule) => remunerationRule.value === option)?.label ??
        option
      }
      isOptionEqualToValue={(option) => option === selectedClassification}
      value={selectedClassification}
      onChange={(_, value) => {
        setFieldValue('classificacao', value)
        setSelectedClassification(value)
      }}
    />
  )
}
