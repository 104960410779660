export enum PermissionType {
  PAYMENTS_VIEW = 'Payments:View',
  PAYMENTS_FINANCIAL_VIEW = 'Payments:Financial:View',
  PAYMENTS_AGGREGATED_PAYMENT_VIEW = 'Payments:AggregatedPayment:View',
  PAYMENTS_UPDATE = 'Payments:Update',
  PAYMENTS_SEND_INVOICE = 'Payments:SendInvoice',

  MY_UNITS_POWER_PLANT_VIEW = 'MyUnits:PowerPlant:View',
  MY_UNITS_POWER_PLANT_CREATE = 'MyUnits:PowerPlant:Create',
  MY_UNITS_POWER_PLANT_UPDATE = 'MyUnits:PowerPlant:Update',
  MY_UNITS_POWER_PLANT_DELETE = 'MyUnits:PowerPlant:Delete',

  MY_UNITS_CONSUMER_VIEW = 'MyUnits:Consumer:View',
  MY_UNITS_CONSUMER_CREATE = 'MyUnits:Consumer:Create',
  MY_UNITS_CONSUMER_UPDATE = 'MyUnits:Consumer:Update',
  MY_UNITS_CONSUMER_DELETE = 'MyUnits:Consumer:Delete',

  MY_UNITS_CONSUMER_UNITS_VIEW = 'MyUnits:ConsumerUnits:View',
  MY_UNITS_CONSUMER_UNITS_CREATE = 'MyUnits:ConsumerUnits:Create',
  MY_UNITS_CONSUMER_UNITS_UPDATE = 'MyUnits:ConsumerUnits:Update',

  INVOICES_DISTRIBUTOR_VIEW = 'Invoices:Distributor:View',
  INVOICES_DISTRIBUTOR_UPLOAD = 'Invoices:Distributor:Upload',

  INVOICES_LOCATION_VIEW = 'Invoices:Location:View',

  INVOICES_HISTORY_VIEW = 'Invoices:History:View',

  INVOICES_CONSUMER_HISTORY_VIEW = 'Invoices:Consumer:History:View',

  CREDITS_BALANCE_VIEW = 'Credits:Balance:View',

  CREDITS_ALLOCATION_VIEW = 'Credits:Allocation:View',
  CREDITS_ALLOCATION_CREATE = 'Credits:Allocation:Create',
  CREDITS_ALLOCATION_UPDATE = 'Credits:Allocation:Update',
  CREDITS_ALLOCATION_DELETE = 'Credits:Allocation:Delete',

  DASHBOARD_VIEW = 'Dashboard:View',
  MEASUREMENT_VIEW = 'Measurement:View'
}
