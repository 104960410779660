/**
 * It takes a number and returns a string with a comma separating every three digits.
 * @param [number=0] - The number to be converted to a string.
 * @returns A function that takes a number and returns a string.
 */
export const numberToString = (number = 0, toFixed?: number) => {
  const string = (toFixed ? number.toFixed(toFixed) : number.toString()).split('.')
  string[0] = string[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return string.join(',')
}
