import { useAutocomplete } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import MultiSelect from '../../../../../components/MultiSelect';
import { useToast } from '../../../../../hooks/useToast';
import { GetManagers, clientService } from '../../../../../services/client';
import { CheckboxType } from '../../../../../interfaces/checkbox';
import { FloatingCheckboxes } from '../../../../../components/FloatingCheckboxes';

type Manager = GetManagers['result'][number]

interface Props {
  values: number[]
  onFilter: (values: CheckboxType[]) => void
}

export default function MultiManagerFilter(props: Props) {
  const { values, onFilter } = props

  const [results, setResults] = useState<Manager[]>([])

  const { toast } = useToast()

  const fetchResults = async () => {
    try {
      const managersResponse = await clientService.getManagers()
      setResults(managersResponse)
    } catch (error) {
      console.log(error)
      toast({
        type: 'error',
        message: "Erro ao listar status"
      })
    }
  }

  useEffect(() => {
    fetchResults()
  }, [])

  return (
    <FloatingCheckboxes
      isFilterActive
      label='Gestor'
      options={results.map((result) => ({
        id: result.id,
        label: result.cliente_nome,
        checked: values.length === 0 ? true : values.includes(result.id),
      }))}
      selectableAll
      searchable
      submitAction={onFilter}
    />
  )
}
