import styled from 'styled-components'

export const ChartContainer = styled.div`
  overflow-x: auto;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChartBody = styled.div`
  padding: 16px 16px 20px 0px;
  width: 100%;
`

export const TitleDiv = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TitleText = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;

  color: ${({ theme }) => theme.colors.htmlGrey};
`

export const LegendText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: #808080;
`

export const LegendDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 41px;
  justify-content: center;
`

export const LegendSubDiv = styled(LegendDiv)<{ active: boolean }>`
  gap: 4px;
  align-items: center;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`

export const LegendCircle = styled.div<{ color: string }>`
  border-radius: 10px;
  background: ${({ color }) => `${color}`};
  width: 10px;
  height: 10px;
`
