import styled from "styled-components";
import { Link } from 'react-router-dom';


export const LinkNotReceiveMail = styled(Link)`
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: -5%;
    margin-top: 1rem;
    color: ${props => props.theme.colors.darkishGreen};

    letter-spacing: -0.05em;
    font-weight: 600;
`