import React from 'react'
import Select from './select'
import Option from './option'
import { Label } from '../Input/styles'
import { SelectContainer } from './styles'
import Tooltip from '../Tooltip'
import { ButtonsDiv } from '../charts/ConsumerMainChart/styles'
import { FloatingFilterWrapper } from '../FloatingFilterWrapper'

interface SelectProps {
  data: any[]
  label: string
  mode?: boolean
  required?: boolean
  tooltip?: boolean
  tooltipText?: string
  keyName: string
}

export default function SelectInput({
  data,
  label,
  mode,
  required,
  tooltip,
  tooltipText,
  keyName,
}: SelectProps) {
  return (
    <SelectContainer>
      <div style={{ display: 'flex', gap: 8 }}>
        <Label mode={mode} required={required}>
          {label}
        </Label>
        {tooltip && <Tooltip title={tooltipText || ''} />}
      </div>
      <Select>
        {data.map((item) => (
          <Option value={item[keyName] as string} key={item.id}>
            {item.nome}
          </Option>
        ))}
      </Select>
    </SelectContainer>
  )
}

interface ICallbackProps {
  open: () => void
  close: () => void
  isOpen: boolean
}

interface ISelectProps {
  Customize?: ({ open, close, isOpen }: ICallbackProps) => React.ReactElement
  ModalComponent: ({ close }: Omit<ICallbackProps, 'open' | 'isOpen'>) => React.ReactElement
  submitAction: () => void
  isFilterActive: boolean
}

export function SelectFilter({
  Customize,
  ModalComponent,
  submitAction,
  isFilterActive,
}: ISelectProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return (
    <div>
      <FloatingFilterWrapper
        isFilterActive={isFilterActive}
        submitAction={submitAction}
        customActionComponent={
          Customize ? (
            <Customize open={open} close={close} isOpen={isOpen} />
          ) : (
            <ButtonsDiv
              onClick={() => setIsOpen((prev) => !prev)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFFF',
                borderRadius: '0.5rem',
                padding: '0.5rem',
                gap: '0.5rem',
              }}
            >
              Mês/ano
            </ButtonsDiv>
          )
        }
      >
        <ModalComponent close={close} />
      </FloatingFilterWrapper>
    </div>
  )
}
