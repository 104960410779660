import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import { Alert, Snackbar } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import CloudIcon from '../../../assets/icons/cloudIcon.svg'
import TreeIcon from '../../../assets/icons/treeIcon.svg'
import { DateFilter } from '../../../components/Filter/DateFilter'
import Loading from '../../../components/Loading'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import WidgetInfo from '../../../components/WidgetInfo'
import BarChart from '../../../components/charts/BarChart'
import ConsumerMainChart from '../../../components/charts/ConsumerMainChart'
import { EnergyChart } from '../../../components/charts/EnergyChart'
import { InvoicesDonut } from '../../../components/charts/InvoicesDonut'
import { MapChart } from '../../../components/charts/MapChart'
import { GlobalContext } from '../../../context/global/global'
import { InvoiceStatusColor } from '../../../interfaces/invoices'
import {
  AreaChartPayload,
  BarChartPayload,
  ConsumerMapChartPayload,
  DonutChartConsumerPayload,
  EnergyBarChartPayload,
  WidgetConsumerPayload,
} from '../../../services/requests/types'
import {
  distributorListGet,
  downloadBulletinCelesc,
  downloadBulletinEnergisa,
  getConsumerDashboard,
  getConsumerDashboardArea,
  getConsumerDashboardBars,
  getConsumerDashboardEnergyBars,
  getConsumerDashboardInvoiceStatus,
  getdashboardConsumerMapChart,
  powerPlantListGet,
} from '../../../services/requests/user-requests'
import { getAbsoluteWidth } from '../../../utils/getDimensionsElements'
import { IupdateAllChartProps } from '../types'
import {
  AsideCharts,
  BoltIconColored,
  ButtonsContainer,
  CO2Text,
  CancelButton,
  Charts,
  Content,
  DashboardContainer,
  GreenPowerPlugIconColored,
  Header,
  IconDiv,
  InfoDiv,
  ModalContainer,
  PowerPlugIconColored,
  WalletIconColored,
  WidgetBox,
  WidgetText,
  WidgetTextDiv,
  WidgetValue,
} from './styles'
import Modal from '../../../components/Modal'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import { CheckboxType } from '../../../interfaces/checkbox'
import WidgetInfoV2 from '../../../components/WidgetInfoV2'
import { MapAndBarChartsContainer } from '../DashboardGenerator/styles'

export function DashboardConsumer() {
  const lastWidgetRef = useRef<HTMLDivElement>(null)
  const [sideWidth, setSideWidth] = useState(0)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [powerPlants, setPowerPlants] = useState<CheckboxType[]>([])

  const [widgetsData, setWidgetsData] = useState<WidgetConsumerPayload>()
  const [donutChartData, setDonutChartData] = useState<DonutChartConsumerPayload>()
  const [barChartData, setBarChartData] = useState<BarChartPayload[]>()
  const [energyBarChartData, setEnergyBarChartData] = useState<EnergyBarChartPayload>()
  const [mainChartData, setMainChartData] = useState<AreaChartPayload[]>()
  const [consumerMapChartData, setConsumerMapChartData] = useState<ConsumerMapChartPayload>()
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [mainChartperiod, setMainChartperiod] = useState<string>('13')

  const [openModal, setOpenModal] = useState(false)

  const { state } = useContext(GlobalContext)
  const userEmail = state.auth?.email

  useEffect(() => {
    if (powerPlants.find((val) => val.checked)) {
      updateAllChart(dateSelected)
    }
  }, [dateSelected, mainChartperiod, powerPlants])

  const updateAllChart = ({ month, year }: IupdateAllChartProps) => {
    const idsList = powerPlants
      .filter((val) => val.checked)
      .map((val) => val.id)
      .join(',')

    setLoading(true)
    Promise.all([
      getConsumerDashboard({ month, year, idsList }),
      getConsumerDashboardInvoiceStatus({ month, year, idsList }),
      getConsumerDashboardBars({ month, year, idsList }),
      getConsumerDashboardArea({ month, year, periodo: mainChartperiod, idsList }),
      getdashboardConsumerMapChart({ idsList }),
      getConsumerDashboardEnergyBars({ month, year, idsList }),
    ])
      .then(async ([widgetData, donutData, barData, areaData, mapData, energyData]) => {
        setLoading(true)
        const widgetResponse = widgetData
        setWidgetsData(widgetResponse.data)

        const donutChartResponse = donutData
        setDonutChartData(donutChartResponse.data)

        const barChartResponse = barData
        setBarChartData(barChartResponse.data)

        const mainChartResponse = areaData
        setMainChartData(mainChartResponse.data)

        const mapChartResponse = mapData
        setConsumerMapChartData(mapChartResponse.data)

        const energyBarChartResponse = energyData.data
        setEnergyBarChartData({
          consumo_efetivado: energyBarChartResponse.consumo_efetivado,
          consumo_projetado: energyBarChartResponse.consumo_projetado,
        })
        setLoading(false)
      })
      .catch((error) => {
        setErrorMessage(error.message ?? 'Erro ao atualizar dados dos gráficos.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const filterPowerPlants = (values: CheckboxType[]) => {
    setPowerPlants(values)
  }

  useEffect(() => {
    if (!lastWidgetRef.current) return
    setSideWidth(getAbsoluteWidth(lastWidgetRef.current))
  }, [lastWidgetRef, widgetsData, mainChartData, barChartData, donutChartData, mainChartperiod])

  useEffect(() => {
    if (!powerPlants.length) {
      Promise.all([distributorListGet()])
        .then(async ([distributorListGet]) => {
          setPowerPlants(
            distributorListGet.data.map((item: any) => ({
              id: item.id,
              label: item.nome,
              checked: true,
            })),
          )
        })
        .catch((error) => {
          setErrorMessage(error.message ?? 'Erro ao atualizar dados dos gráficos.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  return !loading ? (
    <DashboardContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10px',
          gap: '0.5rem',
        }}
      >
        {userEmail && ['cesup.projetos@bb.com.br'].includes(userEmail) && (
          <>
            <FloatingCheckboxes
              isFilterActive={powerPlants.some((powerplant) => powerplant.checked)}
              label='Distribuidoras'
              searchable
              selectableAll
              options={powerPlants || []}
              submitAction={filterPowerPlants}
            />
            <ActionTableButton
              onClick={() => {
                // downloadBulletin()
                //   .then((response) => {
                //     const blob = new Blob([response.data])
                //     const element = document.createElement('a')
                //     element.href = URL.createObjectURL(blob)
                //     element.download = `boletim_medicao.xlsx`

                //     document.body.appendChild(element)
                //     element.click()
                //   })
                //   .catch((err) => console.log(err))
                setOpenModal(true)
              }}
              icon={<ArticleRoundedIcon />}
              styles={{ fontSize: '16px' }}
            >
              Boletim de Medição
            </ActionTableButton>
          </>
        )}
      </div>

      {openModal && (
        <Modal
          title='Boletins de medição'
          openModal={openModal}
          setModalOpen={setOpenModal}
          defaultButtons={false}
        >
          <ModalContainer>
            <ActionTableButton
              icon={<ArticleRoundedIcon />}
              styles={{ fontSize: '16px' }}
              onClick={() => {
                downloadBulletinCelesc()
                  .then((response) => {
                    const blob = new Blob([response.data])
                    const element = document.createElement('a')
                    element.href = URL.createObjectURL(blob)
                    element.download = `boletim_medicao_celesc.xlsx`

                    document.body.appendChild(element)
                    element.click()
                  })
                  .catch((err) => console.log(err))
              }}
            >
              Boletim CELESC
            </ActionTableButton>
            <ActionTableButton
              icon={<ArticleRoundedIcon />}
              styles={{ fontSize: '16px' }}
              onClick={() => {
                downloadBulletinEnergisa()
                  .then((response) => {
                    const blob = new Blob([response.data])
                    const element = document.createElement('a')
                    element.href = URL.createObjectURL(blob)
                    element.download = `boletim_medicao_energisa.xlsx`

                    document.body.appendChild(element)
                    element.click()
                  })
                  .catch((err) => console.log(err))
              }}
            >
              Boletim Energisa MS
            </ActionTableButton>
          </ModalContainer>
          <ButtonsContainer>
            <CancelButton
              disabled={false}
              type='button'
              onClick={() => {
                setOpenModal(false)
              }}
            >
              Cancelar
            </CancelButton>
          </ButtonsContainer>
        </Modal>
      )}

      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />

      {widgetsData && (
        <Header>
          <WidgetInfoV2
            icon={BoltIconColored}
            title='Economia no mês'
            value={Number(Number(widgetsData?.economia_mensal.valor).toFixed(2))}
            inRelationToText='em relação ao mês anterior'
            variation={widgetsData?.economia_mensal.porcentagem ?? 0}
            measurement
          />
          <WidgetInfoV2
            icon={PowerPlugIconColored}
            title='Economia acumulada'
            value={Number(Number(widgetsData?.economia_acumulada.valor).toFixed(2))}
            inRelationToText='em relação ao mês anterior'
            variation={widgetsData?.economia_acumulada.porcentagem ?? 0}
            measurement
          />
          <WidgetInfoV2
            icon={GreenPowerPlugIconColored}
            title='Créditos utilizados'
            value={Math.trunc(widgetsData?.creditos_utilizados.valor)}
            inRelationToText='em relação ao mês anterior'
            variation={widgetsData?.creditos_utilizados.porcentagem ?? 0}
          />
            <WidgetInfoV2
              icon={WalletIconColored}
              title='Saldo de Créditos'
              value={Math.trunc(widgetsData?.saldo_creditos.valor)}
              inRelationToText='em relação ao mês anterior'
              variation={widgetsData?.saldo_creditos.porcentagem ?? 0}
            />
        </Header>
      )}
      <Content>
        <Charts>
          {mainChartData && (
            <div style={{ marginBottom: 17 }}>
              {userEmail &&
                ['cesup.projetos@bb.com.br', 'alves-marcelo97@hotmail.com'].includes(userEmail) &&
                energyBarChartData && <EnergyChart energyBarChartData={energyBarChartData} />}
              <ConsumerMainChart
                dateSelected={dateSelected}
                data={mainChartData}
                isConsumer
                userEmail={userEmail}
                onChangePeriod={(period) => setMainChartperiod(period.toString())}
              />
            </div>
          )}
          <MapAndBarChartsContainer>
            {consumerMapChartData && (
              <MapChart
                key={consumerMapChartData.toString()}
                markers={consumerMapChartData.map(
                  ({ latitude, longitude, nome, geradoraFlag }) => ({
                    coordinates: { lat: latitude, long: longitude },
                    name: nome,
                    geradoraFlag,
                  }),
                )}
              />
            )}
            {barChartData && (
              <BarChart dateSelected={dateSelected} data={barChartData} isConsumer />
            )}
          </MapAndBarChartsContainer>
        </Charts>
        <AsideCharts style={{ maxWidth: sideWidth }}>
          {widgetsData && (
            <>
              <WidgetBox>
                <IconDiv>
                  <img src={`${TreeIcon}`} />
                </IconDiv>
                <InfoDiv>
                  <WidgetText>Arvores Plantadas</WidgetText>
                  <WidgetValue>
                    {Math.trunc(widgetsData?.arvores).toLocaleString('pt-BR')}
                  </WidgetValue>
                </InfoDiv>
              </WidgetBox>
              <WidgetBox>
                <IconDiv>
                  <img src={`${CloudIcon}`} />
                </IconDiv>
                <InfoDiv>
                  <WidgetText>Carbono evitado</WidgetText>
                  <WidgetTextDiv>
                    <WidgetValue>
                      {Math.trunc(widgetsData?.carbono).toLocaleString('pt-BR')}
                    </WidgetValue>
                    <CO2Text>tCO2</CO2Text>
                  </WidgetTextDiv>
                </InfoDiv>
              </WidgetBox>
            </>
          )}
          {donutChartData && (
            <InvoicesDonut
              styles={{ width: 278 }}
              isConsumer
              data={[
                {
                  label: 'Completas',
                  value: donutChartData?.completas,
                  color: InvoiceStatusColor['Completas'],
                },
                {
                  label: 'Aguardando emissão',
                  value: donutChartData?.aguardando_emissao,
                  color: InvoiceStatusColor['Aguardando emissão'],
                },
                {
                  label: 'Não capturadas',
                  value: donutChartData?.nao_capturadas,
                  color: InvoiceStatusColor['Não capturadas'],
                },
                // {
                //   label: 'Não obtidas',
                //   value: donutChartData?.nao_processadas,
                //   color: InvoiceStatusColor['Não obtidas'],
                // },
              ]}
            />
          )}
        </AsideCharts>
      </Content>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </DashboardContainer>
  ) : (
    <Loading />
  )
}
