import { useEffect, useMemo, useState } from 'react'
import { UsersPagination } from '../../../../interfaces/users'
import { useToast } from '../../../../hooks/useToast'
import { usersService } from '../../../../services/users'
import { Sort } from '../../../../interfaces/pagination'
import { TableV2 } from '../../../../components/TableV2'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { TablePagination } from '../../../../components/TableV2/components/TablePagination'
import { AddOutlined, Group, ManageAccounts } from '@mui/icons-material'
import { IconButton, Stack, Tooltip } from '@mui/material'
import UserPermissionsModal from './components/UserPermissionsModal'
import UserRolesModal from './components/UserRolesModal'
import ClientTypeFilter from './components/ClientTypeFilter'
import SearchInput from '../../../Tickets/components/SearchInput'
import { UsersCount } from './styles'
import Loading from '../../../../components/Loading'
import { Button } from '../../../../components/Button'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import ManagerModal from './components/ManagerModal'
import UserModal from './components/UserModal'

const primitiveTableColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    sortable: true,
    hideable: true,
  },
  {
    field: 'email',
    headerName: 'email',
    sortable: true,
    hideable: true,
  },
  {
    field: 'clientType',
    headerName: 'Tipo',
    sortable: true,
    hideable: true,
  },
  {
    field: 'managerName',
    headerName: 'Gestor',
    sortable: true,
    hideable: true,
  },
  {
    field: 'createdAt',
    headerName: 'Data de Criação',
    sortable: true,
    hideable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (params.value) {
        return format(params.value, 'dd/MM/yyyy')
      }
      return '-'
    },
  },
]

export default function PermissionsPage() {
  const [userBeingEdited, setUserBeingEdited] = useState<{
    userId: number
    action: 'permissions' | 'roles'
  }>()

  const [itemsPerPage, setItemsPerPage] = useState<number>(15)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sort, setSort] = useState<Sort>()
  const [clientTypeFilter, setClientTypeFilter] = useState<string>()
  const [searchFilter, setSearchFilter] = useState<string>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const [isCreating, setCreating] = useState<boolean>(false)
  const [isCreatingUser, setCreatingUser] = useState<boolean>(false)

  const [usersPagination, setUsersPagination] = useState<UsersPagination>()

  const defaultTableColumns = useMemo(
    () => [
      ...primitiveTableColumns,
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, any, any>) => {
          return (
            <Stack direction='row' gap={2}>
              <Tooltip title={<p style={{ fontSize: 15 }}>Grupos</p>} placement='bottom'>
                <IconButton
                  onClick={() => setUserBeingEdited({ userId: params.row.id, action: 'roles' })}
                >
                  <Group />
                </IconButton>
              </Tooltip>
              <Tooltip title={<p style={{ fontSize: 15 }}>Permissões</p>} placement='bottom'>
                <IconButton
                  onClick={() =>
                    setUserBeingEdited({ userId: params.row.id, action: 'permissions' })
                  }
                >
                  <ManageAccounts />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        },
      },
    ],
    [usersPagination],
  )

  const [tableColumns, setTableColumns] = useState<GridColDef[]>(defaultTableColumns)

  const { toast } = useToast()

  const fetchUsersPagination = async () => {
    try {
      setLoading(true)
      const usersPaginationResponse = await usersService.listUsers({
        filter: {
          ...(clientTypeFilter ? { clientType: clientTypeFilter } : {}),
          ...(searchFilter ? { search: searchFilter } : {}),
        },
        itemsPerPage,
        page: currentPage,
        sort,
      })
      setUsersPagination(usersPaginationResponse)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar usuários',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUsersPagination()
  }, [itemsPerPage, currentPage, sort, clientTypeFilter, searchFilter])

  return (
    <>
      {userBeingEdited && userBeingEdited.action === 'permissions' && (
        <UserPermissionsModal
          userId={userBeingEdited.userId}
          onClose={() => setUserBeingEdited(undefined)}
        />
      )}

      {userBeingEdited && userBeingEdited.action === 'roles' && (
        <UserRolesModal
          userId={userBeingEdited.userId}
          onClose={() => setUserBeingEdited(undefined)}
        />
      )}

      {isCreating && <ManagerModal onClose={() => setCreating(false)} />}
      {isCreatingUser && <UserModal onClose={() => setCreatingUser(false)} managerId={0} />}

      <Stack marginBottom={2} direction='row'>
        <ClientTypeFilter
          clientTypeFilter={clientTypeFilter}
          setClientTypeFilter={(type) => {
            setCurrentPage(1)
            return setClientTypeFilter(type)
          }}
        />

        <Stack marginLeft='auto' direction='row' gap={2}>
          <ActionTableButton icon={<AddOutlined />} onClick={() => setCreating(true)}>
            Novo gestor
          </ActionTableButton>
          <ActionTableButton icon={<AddOutlined />} onClick={() => setCreatingUser(true)}>
            Novo acesso
          </ActionTableButton>

          <UsersCount>Total de usuários: {usersPagination?.pageInfo?.totalCount ?? 0}</UsersCount>

          <SearchInput
            onSearch={(search) => {
              setSearchFilter(search)
              setCurrentPage(1)
            }}
          />
        </Stack>
      </Stack>

      {isLoading && <Loading />}

      {!isLoading && usersPagination && (
        <TableV2
          alignText='center'
          columns={tableColumns}
          sort={sort}
          onSort={(sort) => {
            setSort(sort)
            setCurrentPage(1)
          }}
          rows={usersPagination.data.map((user) => ({
            id: user.id,
            email: user.email,
            managerName: user.manager ? user.manager.name : '-',
            clientType: user.client.type,
            createdAt: user.createdAt,
          }))}
        >
          <TablePagination
            currentPageItemCount={usersPagination.data.length}
            pageInfo={usersPagination.pageInfo}
            onChange={(page) => setCurrentPage(page)}
          />
        </TableV2>
      )}
    </>
  )
}
