import WidgetInfoV2 from '../../../components/WidgetInfoV2'
import { Header } from '../../Dashboards/DashboardGenerator/styles'
import { ReactComponent as BoltIcon } from '../../../assets/bolt.svg'
import { ReactComponent as WalletIcon } from '../../../assets/wallet.svg'
import { PowerOutlined, StorefrontOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { DateFilter } from '../../../components/Filter/DateFilter'
import { TableV2 } from '../../../components/TableV2'
import { Stack } from '@mui/material'
import DistributorFilter from '../../AggregatedPayments/components/DistributorFilter'
import MultiManagerFilter from '../Orchestrator/components/MultiManagerFilter'
import { TicketsCount } from '../../Tickets/components/TicketsTable/styles'
import SearchInput from '../../Tickets/components/SearchInput'
import DownloadSheet from './components/DownloadSheet'
import TableConfig from './components/TableConfig'
import { GridColDef } from '@mui/x-data-grid'
import { PaginationQuery } from '../../../interfaces/pagination'
import { DashboardSuperCards, DashboardSuperList, dashboardSuperService } from '../../../services/dashboard-super'
import { useToast } from '../../../hooks/useToast'
import { TablePagination } from '../../../components/TableV2/components/TablePagination'
import Loading from '../../../components/Loading'

export default function AdminDashboard() {
  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [isLoading, setLoading] = useState<boolean>(false)
  const [cards, setCards] = useState<DashboardSuperCards['result']>()
  const [tablePagination, setTablePagination] = useState<DashboardSuperList['result']>()
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({
    itemsPerPage: 15,
    page: 1,
  })

  const [tableColumns, setTableColumns] = useState<GridColDef[]>([
    { field: 'clientId', headerName: 'ID', sortable: true, hideable: true },
    { field: 'clientName', headerName: 'Cliente', sortable: true, hideable: true },
    { field: 'totalUcsCount', headerName: 'Total de UCs', sortable: true, hideable: true },
    { field: 'totalPower', headerName: 'Potência', sortable: true, hideable: true },
  ])

  const { toast } = useToast()

  const fetchTablePagination = async () => {
    try {
      const response = await dashboardSuperService.list({
        ...paginationQuery,
        referenceDate: `${dateSelected.month}-${dateSelected.year}`,
      })
      setTablePagination(response)
    } catch (error: any) {
      toast({
        message: error.message ? error.message : 'Erro ao listar itens da tabela',
        type: 'error',
      })
    }
  }

  const fetchOverview = async () => {
    try {
      const response = await dashboardSuperService.cards({
        ...paginationQuery,
        referenceDate: `${dateSelected.month}-${dateSelected.year}`,
      })
      setCards(response)
    } catch (error: any) {
      toast({
        message: error.message ? error.message : 'Erro ao carregar dados dos cards',
        type: 'error',
      })
    }
  }

  const downloadSheet = async (referenceMonths: string[]) => {
    try {
      await dashboardSuperService.downloadSheet({
        referenceMonths,
        filter: paginationQuery.filter,
      })
    } catch (error: any) {
      toast({
        message: error.message ? error.message : 'Erro ao baixar planilha',
        type: 'error',
      })
    }
  }

  const fetchData = async () => {
    setLoading(true)
    await Promise.all([fetchOverview(), fetchTablePagination()])
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [dateSelected, paginationQuery])

  if (!tablePagination || !cards || isLoading) {
    return <Loading />
  }

  return (
    <>
      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />
      <Header>
        <WidgetInfoV2
          icon={StorefrontOutlined}
          title='Total de UCs'
          measurementText=""
          value={Math.trunc(cards.totalUcs.count)}
          inRelationToText='em relação ao mês anterior'
          variation={cards.totalUcs.percentage}
        />
        <WidgetInfoV2
          icon={PowerOutlined}
          title='Total de Usinas'
          measurementText=""
          value={Math.trunc(cards.totalPowerPlants.count)}
          inRelationToText='em relação ao mês anterior'
          variation={cards.totalPowerPlants.percentage}
        />
        <WidgetInfoV2
          icon={BoltIcon}
          title='Total de Geração (kW)'
          measurementText="kW"
          value={Math.trunc(cards.totalPower.count)}
          inRelationToText='em relação ao mês anterior'
          variation={cards.totalPower.percentage}
        />
        <WidgetInfoV2
          icon={WalletIcon}
          title='Total de Valores Pagos'
          measurementText=""
          value={`R$ ${
            Number(
              Math.trunc(cards.totalPastValueDue.count)
                ? Math.trunc(cards.totalPastValueDue.count).toFixed(2)
                : '0,00',
            ).toLocaleString('pt-BR') === 'NaN'
              ? '0,00'
              : Number(
                  Math.trunc(cards.totalPastValueDue.count)
                    ? Math.trunc(cards.totalPastValueDue.count).toFixed(2)
                    : '0,00',
                ).toLocaleString('pt-BR')
          }`}
          inRelationToText='em relação ao mês anterior'
          variation={cards.totalPastValueDue.percentage}
        />
      </Header>
      <Stack direction='row' marginLeft='auto' gap={2} marginBottom={4} marginTop={4}>
        <DistributorFilter
          isFilterActive
          values={(paginationQuery?.filter?.distributors as number[]) ?? []}
          onFilter={(values) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page: 1,
              distributors: values
                .filter((value) => (values.length === 0 ? false : value.checked))
                .map((value) => Number(value.id)),
            }))
          }}
        />
        <MultiManagerFilter
          values={(paginationQuery?.filter?.distributor as number[]) ?? []}
          onFilter={(values) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page: 1,
              clients: values
                .filter((value) => (values.length === 0 ? false : value.checked))
                .map((value) => Number(value.id)),
            }))
          }}
        />

        <TicketsCount>Total de Clientes: {tablePagination?.pageInfo?.totalCount ?? 0}</TicketsCount>

        <SearchInput
          defaultValue={paginationQuery.filter?.search as string}
          onSearch={(search) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page: 1,
              filter: {
                ...(previous.filter ?? {}),
                search,
              },
            }))
          }}
        />

        <DownloadSheet dateSelected={dateSelected} onDownload={downloadSheet} />

        <TableConfig
          itemsPerPage={paginationQuery.itemsPerPage ?? 15}
          setItemsPerPage={(itemsPerPage) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page: 1,
              itemsPerPage,
            }))
          }}
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
        />
      </Stack>
      <TableV2
        alignText='center'
        rows={tablePagination.data.map((item) => ({ ...item, id: item.clientId }))}
        columns={tableColumns}
      >
        <TablePagination
          currentPageItemCount={tablePagination.data.length}
          pageInfo={tablePagination.pageInfo}
          onChange={(page) => {
            setPaginationQuery((previous) => ({
              ...previous,
              page,
            }))
          }}
        />
      </TableV2>
    </>
  )
}
