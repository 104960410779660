import styled from 'styled-components'
import { CircularProgress } from '@mui/material'

type ConfigButtonsProps = {
  gap?: string | number
}

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ButtonsConsumerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const ConfigButtonsColumn = styled.div<ConfigButtonsProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  gap: ${(props) => props.gap ?? 'none'};
`

export const Spinner = styled(CircularProgress)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 55vh;
`
