import React from 'react'
import increase from '../../assets/increase.svg'
import decrease from '../../assets/decrease.svg'
import {
  IcontContainer,
  InfoTitle,
  InfoValue,
  MeasurementText,
  PercentageText,
  InRelationToText,
  WidgetContainer,
  TitleContainer,
  WidgetBody,
} from './styles'
import { GlobalContext } from '../../context/global/global'
import { Divider } from '../Divider'
import { Stack } from '@mui/material'

interface WidgetProps {
  icon: React.ElementType
  title: string
  value: number | string
  measurement?: boolean
  measurementText?: string
  variation?: number
  inRelationToText?: string
}

export function WidgetInfoV2({
  icon: Icon,
  title,
  value,
  measurement,
  measurementText,
  variation,
  inRelationToText,
}: WidgetProps) {
  const { Theme } = React.useContext(GlobalContext)

  return (
    <WidgetContainer>
      <WidgetBody>
        <IcontContainer color={Theme.colors.darkishGreen}>
          <Icon />
        </IcontContainer>

        <TitleContainer>
          <InfoTitle>{title}</InfoTitle>
          <Stack marginLeft='auto' alignItems='center' justifyContent='center' direction='row'>
            <InfoValue>
              {typeof value === 'string'
                ? value
                : measurement
                ? new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }).format(value)
                : `${Number(value?.toFixed(2) || 0).toLocaleString('pt-BR')}`}
            </InfoValue>
            {!measurement && <MeasurementText>{measurementText ?? 'kWh'}</MeasurementText>}
          </Stack>
        </TitleContainer>
      </WidgetBody>

      {(variation || inRelationToText) && (
        <>
          <Divider />
          <WidgetBody style={{ alignItems: 'center' }}>
            {variation != undefined && variation != null && (
              <>
                <img src={variation > 0 ? `${increase}` : `${decrease}`} width={28} height={14.5} />
                <PercentageText>
                  {variation > 0 ? `+${variation}%` : `${variation}%`}
                </PercentageText>
              </>
            )}
            {inRelationToText && (
              <InRelationToText title={inRelationToText}>{inRelationToText}</InRelationToText>
            )}
          </WidgetBody>
        </>
      )}
    </WidgetContainer>
  )
}

export default WidgetInfoV2
