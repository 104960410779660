import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'
import { InvoiceValidation } from '../../../../interfaces/invoices'
import { invoiceParserV2 } from '../../../../services/invoice-parser-v2'

type InvoiceValidationFromList = InvoiceValidation & {
  distributor?: {
    name: string
  }
  manager?: {
    name: string
  },
  uc?: {
    name: string
  }
}

interface InvoiceListContextData {
  invoiceList: InvoiceValidationFromList[]
  isLoading: boolean
  fetchInvoiceList: () => Promise<void>
}

const InvoiceListContext = createContext({} as InvoiceListContextData)

interface Props {
  invoicesIdsToList?: string[]
}

export function InvoiceListProvider(props: PropsWithChildren<Props>) {
  const { invoicesIdsToList, children } = props

  const [invoiceList, setInvoiceList] = useState<InvoiceValidationFromList[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const fetchInvoiceList = async () => {
    const invoices = await invoiceParserV2.listInvoicesToValidate(invoicesIdsToList)
    setInvoiceList(invoices)
  }

  const pollingTimeoutRef = useRef<NodeJS.Timeout>()

  const startPolling = () => {
    const timeout = setTimeout(async () => {
      const invoices = await invoiceParserV2.listInvoicesToValidate(invoicesIdsToList)
      setInvoiceList(invoices)
      startPolling()
    }, 15000)
    pollingTimeoutRef.current = timeout
  }

  const stopPolling = () => {
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current)
      pollingTimeoutRef.current = undefined
    }
  }

  useEffect(() => {
    if (invoicesIdsToList && invoicesIdsToList.length > 0) {
      startPolling()
    }
    return () => {
      stopPolling()
    }
  }, [invoicesIdsToList])

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        await fetchInvoiceList()
      } catch (error) {
        console.log(`Erro ao listar faturas em processamento: ${error}`)
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, [invoicesIdsToList])

  return (
    <InvoiceListContext.Provider value={{ invoiceList, fetchInvoiceList, isLoading }}>
      {children}
    </InvoiceListContext.Provider>
  )
}

export function useInvoiceList() {
  const context = useContext(InvoiceListContext)
  if (!context) {
    throw new Error('Provider not found')
  }
  return context
}
