import React, { useContext, useEffect } from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LogoutIcon from '@mui/icons-material/Logout'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../../../context/global/global'
import { logoutAction } from '../../../context/global/reducers/actions'

import { Theme as AppDefaultTheme } from '../../../styles/theme'
import { pathNamesHeader } from '../../../utils/path-names'
import {
  HeaderContainer,
  IconLink,
  IconsContainer,
  InfoRoutePath,
} from './styles'
import Notifications from '../Notifications'

export function Header() {
  const { pathname } = useLocation()
  const [pathTitle, setPathTitle] = React.useState('')
  const [links, setLinks] = React.useState<JSX.Element | null>(null)

  const { dispatch, Theme, setTheme, state } = useContext(GlobalContext)

  useEffect(() => {
    const currentPageProps = pathNamesHeader[pathname as keyof typeof pathNamesHeader]
    setPathTitle(() => currentPageProps.name)
    setLinks(() => currentPageProps.path as JSX.Element)
  }, [pathname])

  return (
    <HeaderContainer>
      <InfoRoutePath>
        <h2>{pathTitle}</h2>
        <p>{links}</p>
      </InfoRoutePath>
      <IconsContainer>
        <IconLink to='/settings'>
          <PermIdentityIcon />
        </IconLink>
        {state.auth?.customer?.type !== 'MASTER' ? (
          <IconLink to='/tickets'>
            <MailOutlineIcon />
          </IconLink>
        ) : null}
        <Notifications />
        <IconLink
          to='#'
          onClick={() => {
            setTheme(AppDefaultTheme)
            // change tab title
            document.title = Theme.tabTitle
            // change Tab icon
            let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
            if (!link) {
              link = document.createElement('link')
              link.rel = 'icon'
              document.getElementsByTagName('head')[0].appendChild(link)
            }
            link.href = AppDefaultTheme.logos.icon.toString()
            dispatch(logoutAction())
          }}
        >
          <LogoutIcon />
        </IconLink>
      </IconsContainer>
    </HeaderContainer>
  )
}
