import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Modal from '../../..'
import { FormikInput } from '../../../../FormikComponents/FormikInput'
import * as Yup from 'yup'
import { Alert, CircularProgress } from '@mui/material'
import AlertIcon from '../../../../../assets/icons/warning-icon.svg'
import { AlertBox, AlertText, ButtonsContainer, CancelButton, SendButton } from './styles'
import FormikSelect from '../../../../FormikComponents/FormikSelect'
import {
  inverterPostRequest,
  inverterSupplierGet,
} from '../../../../../services/requests/user-requests'
import ModalSuccess from '../../../ModalSuccess'

interface PowerPlantProps {
  openModal: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export type InverterSupplierList = {
  value: number
  label: string
}

export default function ModalInverter({ openModal, setModalOpen }: PowerPlantProps) {
  const [alertOpen, setAlertOpen] = useState(false)
  const [successModal, setSuccesModal] = useState(false)
  const [inverterList, setInverterList] = useState<InverterSupplierList[]>()
  const yupSchema = Yup.object({
    login: Yup.string().required('Login é obrigatório.'),
    senha: Yup.string().required('Senha é obrigatório.'),
    fornecedor_id: Yup.string().required('Marca/Portal é obrigatório.'),
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getInverterSupplier = async () => {
      try {
        const response = await inverterSupplierGet()
        const payload = response.data.map((inverter: { id: number; nome: string }) => {
          return {
            label: inverter.nome,
            value: inverter.id,
          }
        })

        setInverterList(payload)
      } catch (error) {
        alert(error)
      }
    }

    getInverterSupplier()
  }, [])

  const handleInverterPost = async (values: any, setSubmitting: any, resetForm: any) => {
    try {
      setIsLoading(true)
      setSubmitting(true)
      await inverterPostRequest({
        ...values,
      })

      setSubmitting(false)
      setIsLoading(false)
      resetForm()
      setSuccesModal(!successModal)
      setModalOpen(!openModal)
    } catch (error: any) {
      setSubmitting(false)
      setIsLoading(false)
      setAlertOpen(!alertOpen)
    }
  }

  return (
    <>
      <ModalSuccess
        text='Inversor cadastrado com sucesso!'
        buttonText='Ok, obrigado'
        buttonColor='darkGreen'
        openModal={successModal}
        setModalOpen={setSuccesModal}
      />
      <Formik
        initialValues={{
          fornecedor_id: inverterList?.length ? inverterList[0].value : '',
          login: '',
          senha: '',
        }}
        validateOnChange={false}
        validateOnBlur
        validationSchema={yupSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleInverterPost(values, setSubmitting, resetForm)
        }}
      >
        {({ setFieldValue, handleSubmit }) => (
          <Modal
            title='Cadastro de Inversor'
            openModal={openModal}
            setModalOpen={setModalOpen}
            defaultButtons={false}
            cancelAction={() => setModalOpen(!openModal)}
            confirmAction={() => handleSubmit()}
          >
            <Form>
              {alertOpen && (
                <AlertBox>
                  <Alert
                    severity='error'
                    color='warning'
                    icon={<img src={`${AlertIcon}`} />}
                    sx={{
                      width: 420,
                      background: 'rgba(255, 102, 0, 0.1)',
                      border: '1px solid #FF6600',
                      borderRadius: '16px',
                      alignItems: 'center',
                    }}
                  >
                    <AlertText>
                      Credenciais inválidas. Confira as informações fornecidas e tente novamente.
                    </AlertText>
                  </Alert>
                </AlertBox>
              )}
              <FormikSelect
                label='Marca/Portal'
                name='fornecedor_id'
                setFieldValue={setFieldValue}
                keyName='nome'
                responseData={inverterList}
              />
              <FormikInput label='Login' mode required name='login' />
              <FormikInput label='Senha' mode required name='senha' type='password' />

              <ButtonsContainer>
                <CancelButton
                  onClick={() => {
                    setModalOpen(false)
                  }}
                >
                  Cancelar
                </CancelButton>
                <SendButton type='submit'>
                  {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
                </SendButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  )
}
