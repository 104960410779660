import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import { plantsService } from '../../../../services/plants'
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-icon-white.svg'
import { CSSProperties } from 'react'

interface Props {
  styles?: CSSProperties
  date: string
  fromDate?: string
  plantsIds: string[]
}

export default function PlantsInvoicingDownloadSheetButton(props: Props) {
  const { date, plantsIds, fromDate, ...rest } = props

  const downloadInvoicingSheet = async () => {
    try {
      const blob = await plantsService.getPlantsInvoicingSheet({ date, plantsIds, fromDate })
      const fileName = `arrecadacao_${date.replace('-', '_')}${
        fromDate ? `(${fromDate})` : ''
      }.xlsx`

      const element = document.createElement('a')
      element.href = URL.createObjectURL(blob)
      element.download = fileName
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      console.log(`Erro ao baixar valores das usinas: ${error}`)
    }
  }

  return <ActionTableButton onClick={downloadInvoicingSheet} icon={<DownloadIcon />} {...rest} />
}
