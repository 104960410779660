import { Autocomplete } from '@mui/material'
import { InputV2 } from '../../../../../../components/InputV2'
import { Search } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useToast } from '../../../../../../hooks/useToast'
import { clientService } from '../../../../../../services/client'

interface Props {
  error?: string
  value: number | null
  setValue: (value: number | null) => void
}

export default function ManagerSelect(props: Props) {
  const { error, value, setValue } = props

  const { toast } = useToast()

  const [clients, setClients] = useState<{ id: number; clientName: string }[]>([])

  const listClients = async () => {
    try {
      const managers = await clientService.getManagers()
      setClients(
        managers.map((manager) => ({
          id: manager.id,
          clientName: manager.cliente_nome,
        })),
      )
    } catch (error) {
      console.error(error)
      toast({
        type: 'error',
        message: 'Erro ao listar gestores',
      })
    }
  }

  useEffect(() => {
    listClients()
  }, [])

  return (
    <Autocomplete
      id='managerId'
      ListboxProps={{
        style: {
          maxHeight: '250px',
        },
      }}
      renderInput={(params) => (
        <InputV2
          rightIcon={<Search />}
          error={!!error}
          label='Gestor'
          required
          {...params}
        />
      )}
      options={clients.map((client) => client.id)}
      getOptionLabel={(option) => {
        const optionNumber = Number(option)
        return !isNaN(optionNumber)
          ? clients.find((client) => client.id === option)?.clientName ?? ''
          : String(option)
      }}
      isOptionEqualToValue={(option) => option === value}
      value={value}
      onChange={(_, value) => {
        setValue(value)
      }}
    />
  )
}
