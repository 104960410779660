import React from 'react'
import { RegisterContainer } from './style'
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import InversorIcon from '../../../assets/icons/inversor-icon.svg'
import { RegisterCard } from './RegisterCard'

export function Registers() {
  return (
    <RegisterContainer>
      <RegisterCard icon={<PowerOutlinedIcon fontSize='large' />}>Usina Geradora</RegisterCard>
      <RegisterCard icon={<PeopleOutlineOutlinedIcon fontSize='large' />}>Consumidor</RegisterCard>
      <RegisterCard icon={<StorefrontOutlinedIcon fontSize='large' />}>
        UC Beneficiária
      </RegisterCard>
      <RegisterCard icon={<img src={InversorIcon.toString()} width={32} height={32} />}>
        Inversor
      </RegisterCard>
    </RegisterContainer>
  )
}
