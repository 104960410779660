import { useState } from 'react'
import PaperDisabledIcon from '../../../../../assets/paper-disabled.svg'
import PaperIcon from '../../../../../assets/paper.svg'
import { invoiceService } from '../../../../../services/invoice'
import { ButtonDownloadTable } from '../../../styles'

interface Props {
  fileId: string
}

export default function DownloadInvoiceButton(props: Props) {
  const { fileId } = props

  const [isLoading, setLoading] = useState<boolean>(false)

  const downloadInvoice = async () => {
    try {
      setLoading(true)
      const invoice = await invoiceService.getInvoicePreview({
        fileId,
      })
      const file = new Blob([new Uint8Array(invoice.toDownload.content.data).buffer])
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)
      element.download = invoice.toDownload.filename
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      console.log(`Error downloading consumer invoice: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const cond = true

  return (
    <ButtonDownloadTable onClick={downloadInvoice} disabled={isLoading}>
      {cond ? <img src={PaperIcon.toString()} /> : <img src={PaperDisabledIcon.toString()} />}
    </ButtonDownloadTable>
  )
}
