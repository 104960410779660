import { Dispatch, SetStateAction, useState } from 'react'
import {
  BulkUpdatePowerPlants,
  GetPowerPlantsFromCsv,
  onboardingService,
} from '../../../../../services/onboarding'
import EntityItems from '../EntityItems'
import { useToast } from '../../../../../hooks/useToast'

interface Props {
  managerId: number
  powerPlantsFromCsv: GetPowerPlantsFromCsv['result']
  setPowerPlantsFromCsv: Dispatch<SetStateAction<GetPowerPlantsFromCsv['result'] | undefined>>
}

export default function PowerPlantsToUpdate(props: Props) {
  const { managerId, powerPlantsFromCsv, setPowerPlantsFromCsv } = props

  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const bulkUpdatePowerPlants = async (
    values: BulkUpdatePowerPlants['params']['powerPlants'],
  ): Promise<void> => {
    try {
      setLoading(true)
      await onboardingService.bulkUpdatePowerPlants({
        managerId,
        powerPlants: values,
      })
      setPowerPlantsFromCsv((previousPlants) =>
        previousPlants
          ? {
              ...previousPlants,
              plantsToUpdate: [...previousPlants.plantsToUpdate].filter(
                (currentUc) => !values.find((val) => val.codigo === currentUc.codigo),
              ),
            }
          : undefined,
      )
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao atualizar consumidor',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <EntityItems
      isLoading={isLoading}
      errors={powerPlantsFromCsv.errors}
      itemsCompleted={powerPlantsFromCsv.plantsCreatedCount}
      itemsToUpdate={powerPlantsFromCsv.plantsToUpdate.length}
      entities={powerPlantsFromCsv.plantsToUpdate}
      compareSearchFilter={(searchFilter, powerPlant) =>
        powerPlant.nome.toLowerCase().includes(searchFilter) ||
        powerPlant.codigo.toLowerCase().includes(searchFilter)
      }
      getEntityIdentifier={(powerPlant) => powerPlant.id}
      bulkUpdate={(powerPlants) => {
        bulkUpdatePowerPlants(
          powerPlants.map((powerPlant) => ({
            ...Object.entries(powerPlant.changes).reduce(
              (agg, [field, { after }]) => ({
                ...agg,
                [field]: after,
              }),
              {},
            ),
            codigo: powerPlant.codigo,
          })),
        )
      }}
      getUpdateFormProps={(powerPlant) => {
        return {
          title: `${powerPlant.nome} (${powerPlant.codigo})`,
          changes: powerPlant.changes,
          onSubmit: (values) => bulkUpdatePowerPlants([{ ...values, codigo: powerPlant.codigo }]),
        }
      }}
    />
  )
}
