import styled from 'styled-components'
import { darken } from 'polished'

import { ReactComponent as boltIcon } from '../../../assets/bolt.svg'
import { ReactComponent as greenPowerPlugIcon } from '../../../assets/green-power-plug.svg'
import { ReactComponent as powerPlugIcon } from '../../../assets/power-plug.svg'
import { ReactComponent as walletIcon } from '../../../assets/wallet.svg'

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > div:last-child {
    min-width: 280px;
    margin-right: 0;
  }
`
export const Content = styled.div`
  display: flex;
`

export const Charts = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  & > div {
    display: flex;
  }
`

export const AsideCharts = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-direction: column;

  & > div {
    margin-bottom: 16px;
  }

  & > div:last-child {
    height: auto;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const WidgetBox = styled.div`
  width: 280px;
  height: 133px;

  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
`

export const IconDiv = styled.div`
  width: 125px;
  height: 133px;
  border-radius: 8px 0px 0px 8px;
  background-color: ${({ theme }) => theme.colors.darkishGreen};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  gap: 16px;
`

export const WidgetText = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #808080;
`

export const WidgetValue = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #023b21;
`

export const WidgetTextDiv = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
`

export const CO2Text = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;
  margin-bottom: 2px;

  color: #808080;
`

export const BoltIconColored = styled(boltIcon)`
  fill: ${({ theme }) => theme.colors.darkishGreen};

  path {
    fill: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const GreenPowerPlugIconColored = styled(greenPowerPlugIcon)`
  fill: ${({ theme }) => theme.colors.darkishGreen};

  path {
    fill: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const PowerPlugIconColored = styled(powerPlugIcon)`
  fill: ${({ theme }) => theme.colors.darkishGreen};

  path {
    fill: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const WalletIconColored = styled(walletIcon)`
  fill: ${({ theme }) => theme.colors.darkishGreen};

  path {
    fill: ${({ theme }) => theme.colors.darkishGreen};
  }
`

export const ModalContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-left: 42px;
  padding-right: 42px;
  gap: 16px;
`

export const ButtonGeneric = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  box-shadow: inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  padding: 10px 6px 9px 6px;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.318125rem;
  letter-spacing: -5%;
  border: none;
  min-width: 152px;
  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.darkishGreen)};
    cursor: pointer;
  }
`

export const CancelButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  border: 0.5px solid #ccc;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
    cursor: pointer;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const SendButton = styled(ButtonGeneric)`
  background: ${(props) => props.theme.colors.darkishGreen};
  color: ${(props) => props.theme.colors.white};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
