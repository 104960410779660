export const downloadFileUtil = (fileName: string, buffer: Buffer | Blob) => {
  if (!window) return

  const itemUrl = window.URL.createObjectURL(new Blob([buffer]))
  const linkElement = document.createElement('a')
  
  linkElement.href = itemUrl
  linkElement.download = fileName

  document.body.appendChild(linkElement)
  linkElement.click()

  // Cleanup
  document.body.removeChild(linkElement)
  window.URL.revokeObjectURL(itemUrl)
}
