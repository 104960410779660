import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ticketsService } from '../../../../services/tickets'
import { Ticket, TicketStatus } from '../../../../interfaces/tickets'
import Loading from '../../../../components/Loading'
import { ConversationWrapper, Footer, Title } from './styles'
import { GlobalContext } from '../../../../context/global/global'
import FileList from './components/FileList'
import Reply from './components/Reply'
import ReplyForm from './components/ReplyForm'
import ModalV2 from '../../../../components/ModalV2'
import { Button } from '../../../../components/Button'
import TicketManageModal from '../TicketManageModal'
import { debounce } from 'lodash'

interface Props {
  ticketId: number
  onTicketClose: () => void
  onClose: () => void
}

export default function TicketModal(props: Props) {
  const { ticketId, onTicketClose, onClose } = props

  const { state } = useContext(GlobalContext)

  const [ticket, setTicket] = useState<Ticket>()
  const [isManagingTicket, setManagingTicket] = useState<boolean>(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [textAreaHeight, setTextAreaHeight] = useState<number>(160)

  const [assignedTo, setAssignedTo] = useState<string>(ticket?.assignedTo ?? '')

  const findTicket = async () => {
    try {
      const ticketResponse = await ticketsService.getTicket({
        ticketId,
      })
      setAssignedTo(ticketResponse?.assignedTo ?? '')
      setTicket(ticketResponse)
    } catch (error) {
      console.log(`Error fetching ticket: ${error}`)
    }
  }

  useEffect(() => {
    findTicket()
  }, [ticketId])

  const debounceInputHeight = useCallback(debounce(() => {
    setTimeout(() => {
      if (textAreaRef.current) {
        const newHeight = textAreaRef.current.clientHeight + 110;
        setTextAreaHeight(newHeight);
      }
    }, 10)
  }, 300), []);

  const isMaster = state.auth?.customer?.type === 'MASTER'

  const canManageTicket =
    ticket && ticket.status !== TicketStatus.CLOSED && isMaster && !isManagingTicket

  const isTicketManageModalOpen = isManagingTicket && !!ticket

  const canReplyTicket = ticket && ticket.status !== TicketStatus.CLOSED && (!isMaster || (isMaster && !!assignedTo))

  return (
    <>
      <TicketManageModal
        ticket={ticket}
        isOpen={isTicketManageModalOpen}
        assignedTo={assignedTo}
        setAssignedTo={setAssignedTo}
        onCancel={() => setManagingTicket(false)}
        onTicketClose={() => {
          onTicketClose()
          onClose()
        }}
      />

      <ModalV2.Provider
        style={{ width: '100%', position: 'relative', height: '100%' }}
        modalStyle={{ ...(isTicketManageModalOpen ? { visibility: 'hidden' } : {}) }}
        hideBackdrop={isTicketManageModalOpen}
        isOpen
        onClose={onClose}
      >
        <Title>
          {ticket &&
            `Ticket #${ticket.id} - ${ticket.title}${
              isMaster && ticket && assignedTo && ticket.status === TicketStatus.CLOSED
                ? ` (${assignedTo})`
                : ''
            }`}
          {canManageTicket && (
            <Button
              containerStyle={{
                marginRight: '16px',
                height: 'fit-content',
                width: 'fit-content',
                marginLeft: 'auto',
              }}
              text='Gerenciar'
              onClick={() => setManagingTicket(true)}
            />
          )}
          <ModalV2.CloseButton alignLeft={!canManageTicket} />
        </Title>

        {!ticket ? (
          <Loading style={{ marginBottom: 32 }} />
        ) : (
          <>
            <ConversationWrapper textAreaHeight={textAreaHeight}>
              {ticket.replies.map((reply, index) => (
                <Reply key={`ticket-reply-${reply.id}`} assignedTo={assignedTo} reply={reply}>
                  {index === 0 && ticket.files.length > 0 && <FileList files={ticket.files} />}
                </Reply>
              ))}
            </ConversationWrapper>
            {canReplyTicket && (
              <Footer>
                <ReplyForm
                  ticketId={ticket.id}
                  onChange={debounceInputHeight}
                  textAreaRef={textAreaRef}
                  onReply={findTicket}
                />
              </Footer>
            )}
          </>
        )}
      </ModalV2.Provider>
    </>
  )
}
