import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ListPermissions, permissionsService } from '../../../../../../../../services/permissions'
import { useToast } from '../../../../../../../../hooks/useToast'
import PermissionList from '../../../../../components/PermissionList'

interface Props {
  selectedPermissionsIds: number[]
  setSelectedPermissionsIds: Dispatch<SetStateAction<number[]>>
}

export default function RolePermissions(props: Props) {
  const { selectedPermissionsIds, setSelectedPermissionsIds } = props

  const [permissions, setPermissions] = useState<ListPermissions['result']>([])

  const { toast } = useToast()

  const listRolePermissions = async () => {
    try {
      const permissionsResponse = await permissionsService.listPermissions()
      setPermissions(permissionsResponse)
    } catch (error) {
      console.error(error)
      toast({
        message: 'Erro ao listar permissões',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    listRolePermissions()
  }, [])

  return (
    <PermissionList
      permissions={permissions.map((permission) => ({
        hasPermission: selectedPermissionsIds.includes(permission.id),
        permission,
      }))}
      onChange={(touchedPermissions) => {
        const permissionsIds = touchedPermissions
          .filter((permission) => permission.hasPermission)
          .map((permission) => permission.permissionId)
        setSelectedPermissionsIds(permissionsIds)
      }}
    />
  )
}
