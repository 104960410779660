import styled from 'styled-components'

export const RegisterCardContainer = styled.div`
  height: 23.375rem;
  width: 100%;
  background: linear-gradient(315deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, #ffffff, #ffffff), ${(props) => props.theme.colors.darkishGreen};
  background-blend-mode: soft-light, normal, normal;
  box-shadow: -1.66667px -1.66667px 5px #ffffff, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  position: relative;
`

export const RegisterCardContent = styled.div`
  margin-top: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

export const IconContainer = styled.div`
  width: 4.5rem;
  height: 4.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors.background};
  box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  border: none;
`

export const TextRegisterCard = styled.h2`
  margin-top: 3.8125rem;
  font-size: 1.375rem;
  text-align: center;
  line-height: 1.625rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.darkishGreen};
`

export const RegisterButton = styled.button`
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  border: 2px solid #808080;
  border-radius: 8px;
  position: absolute;
  bottom: 2rem;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.htmlGrey};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.05em;
  cursor: pointer;
  width: 213px;
`
