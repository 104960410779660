export function SendInvoice({ setFile }: { setFile: (value: File) => void }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        marginBottom: '20px',
        borderRadius: '10px',
      }}
    >
      <p
        style={{
          fontSize: '18px',
          marginRight: '10px',
        }}
      >
        Envie seu pdf aqui:{' '}
      </p>
      <input
        type='file'
        onChange={(event: any) => {
          const { files } = event.target

          if (files) {
            setFile(files[0])
          }
        }}
      />
    </div>
  )
}
