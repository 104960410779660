import { Dispatch, SetStateAction, useState } from 'react'
import { FloatingFilterWrapper } from '../../../../components/FloatingFilterWrapper'
import { OptionItem, OptionList } from '../../styles'
import { ASSIGNEES } from './assignees'

interface Props {
  assignee: string
  setAssigneeFilter: Dispatch<SetStateAction<string>>
}

export default function AssigneeSelect(props: Props) {
  const { assignee, setAssigneeFilter } = props

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive
      label='Responsável'
      modalStyles={{ padding: 4, overflowY: 'auto', overflowX: 'unset' }}
      hideSubmitButton
    >
      <OptionList showDropDown={isDropdownOpen}>
        {ASSIGNEES.map((item) => (
          <OptionItem
            active={assignee === item}
            key={item}
            onClick={() => {
              setDropdownOpen((previousStatusDropdownOpen) => !previousStatusDropdownOpen)
              const updatedValue = assignee === item ? '' : item
              setAssigneeFilter(updatedValue)
            }}
          >
            {item}
          </OptionItem>
        ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
