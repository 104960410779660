import { createGlobalStyle, DefaultTheme } from 'styled-components'

export default createGlobalStyle<{ theme: DefaultTheme }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #F9fafd;
    color: ${({ theme }) => theme.colors.darkishGreen};
    -webkit-font-smoothing: antialiased;
    height: 100vh;
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    letter-spacing: -0.05em;
  }

  input {
    font-family: var(--roboto);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;

    color: ${({ theme }) => theme.colors.quartzGrey}
  }

  .sr-only {
    opacity: 0;
  }

 ::placeholder, ::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.24);
    letter-spacing: -0.05em;
 }

  button {
    cursor: pointer;
    border: 0;
  }

  h1, h2, h3, h4 {
    font-weight: bold;
    letter-spacing: -0.05em;
  }

  h1 {
    font-size: 36px;
    letter-spacing: -0.05em;
  }

  h2 {
    font-size: 24px;
    letter-spacing: -0.05em;
  }

  h3 {
    font-size: 20px;
    letter-spacing: -0.05em;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.05em;
  }

  h5 {
    font-size: 14px;
    letter-spacing: -0.05em;
  }

  h6 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.05em;
  }

  h7 {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: -0.05em;
  }

  h8 {
    font-size: 10px;
    font-weight: 500px;
    letter-spacing: -0.05em;
  }

  :root {
    --darkGreen: ${({ theme }) => theme.colors.darkGreen};
    --darkishGreen: ${({ theme }) => theme.colors.darkishGreen};
    --green: ${({ theme }) => theme.colors.green};
    --appleGreen: ${({ theme }) => theme.colors.appleGreen};
    --lightGreen: ${({ theme }) => theme.colors.lightGreen};
    --brightGreen: ${({ theme }) => theme.colors.brightGreen};
    --orange: ${({ theme }) => theme.colors.orange};
    --lightOrange: ${({ theme }) => theme.colors.lightOrange};
    --cream: ${({ theme }) => theme.colors.cream};
    --quartzGrey: ${({ theme }) => theme.colors.quartzGrey};
    --htmlGrey: ${({ theme }) => theme.colors.htmlGrey};
    --grey: ${({ theme }) => theme.colors.grey};
    --lightGrey: ${({ theme }) => theme.colors.lightGrey};
    --background: ${({ theme }) => theme.colors.background};
    --white: ${({ theme }) => theme.colors.white};
    --grayDark: ${({ theme }) => theme.colors.grayDark};

    --roboto: ${({ theme }) => theme.fonts.family.primary};
    --poppins: ${({ theme }) => theme.fonts.family.secondary};
  }

  .MuiTooltip-tooltip {
    font-size: 14px !important;
    letter-spacing: 0.015rem;
  }
`
