import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import styled, { DefaultTheme } from 'styled-components'
import InformationIcon from '../../assets/icons/informationIcon.svg'
import { useContext, useState, ReactNode, PropsWithChildren, useRef, useEffect } from 'react'
import { GlobalContext } from '../../context/global/global'
import { Wrapper } from './style'

interface TooltipInterface {
  title: string
}

interface IStyledTooltipProps extends TooltipProps {
  AppTheme: DefaultTheme
}

const StyledTooltip = styled(({ className, ...props }: IStyledTooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(
  ({ AppTheme }) =>
    ({
      [`& .${tooltipClasses.arrow}`]: {
        color: AppTheme.colors.darkishGreen,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: AppTheme.colors.darkishGreen,
        color: AppTheme.colors.white,
        fontStyle: 'normal',
        fontSize: 14,
        padding: 8,
        maxWidth: 280,
      },
    } as any),
)

export default function CustomTooltip({ title }: TooltipInterface) {
  const { Theme } = useContext(GlobalContext)
  return (
    <StyledTooltip title={title} arrow placement='bottom-start' AppTheme={Theme}>
      <img alt='tooltip' src={`${InformationIcon}`} style={{ marginBottom: 4, marginLeft: 7 }} />
    </StyledTooltip>
  )
}

interface IGenericTooltipProps {
  delay?: number
  content: ReactNode
  direction?: 'top' | 'right' | 'bottom' | 'left'
}

export const GenericTooltip = ({
  delay,
  content,
  children,
  direction,
}: PropsWithChildren<IGenericTooltipProps>) => {
  let timeout: NodeJS.Timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay || 400)
  }

  const hideTip = () => {
    clearTimeout(timeout)
    setActive(false)
  }

  return (
    <Wrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <div className={`Tooltip-Tip ${direction || 'right'}`}>{content}</div>}
    </Wrapper>
  )
}
