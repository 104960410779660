import { useState } from 'react'
import { FloatingFilterWrapper } from '../../../../components/FloatingFilterWrapper'
import { OptionItem, OptionList } from '../../styles'
import { ticketCategories } from '../../categories'
import { Typography } from '@mui/material'
import { SubCategory } from './styles'

interface Props {
  subCategoryFilter: string
  setSubCategoryFilter: (value: string) => void
}

export default function SubCategoryFilter(props: Props) {
  const { subCategoryFilter, setSubCategoryFilter } = props

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)

  return (
    <FloatingFilterWrapper
      checkbox
      isFilterActive
      label='Sub Categoria'
      modalStyles={{ padding: 16, overflowY: 'auto', overflowX: 'unset' }}
      hideSubmitButton
    >
      <OptionList style={{ width: '200px' }} showDropDown={isDropdownOpen}>
        {ticketCategories
          .filter((category) => category.options && category.options.length > 0)
          .map((category) => (
            <SubCategory key={`ticket-sub-categories-from-${category.label}`}>
              <Typography variant='body2' fontWeight='bold' style={{ marginBottom: '4px' }}>
                {category.label}
              </Typography>

              {category.options?.map((option) => (
                <OptionItem
                  active={subCategoryFilter === option.value}
                  key={`sub-category-filter-${option.label}`}
                  onClick={() => {
                    setDropdownOpen((previousStatusDropdownOpen) => !previousStatusDropdownOpen)
                    const updatedValue = subCategoryFilter === option.value ? '' : option.value
                    setSubCategoryFilter(updatedValue)
                  }}
                >
                  {option.label}
                </OptionItem>
              ))}
            </SubCategory>
          ))}
      </OptionList>
    </FloatingFilterWrapper>
  )
}
