import { styled } from '@mui/material/styles'
import { Tabs as MUITabs, Box, Tab } from '@mui/material'

// Styled components to mimic the tabs in the image
const CustomTabs = styled(MUITabs)({
  '.MuiTabs-indicator': {
    display: 'none', // Hide the default indicator
  },
})

const CustomTab = styled((props: any) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(4),
  '&.Mui-selected': {
    color: 'black',
    borderBottom: '2px solid black',
  },
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(2),
  },
}))

interface Props {
  tabs: string[]
  value: number
  onChange: (value: number) => void
}

export default function Tabs(props: Props) {
  const { tabs, value, onChange } = props

  return (
    <Box sx={{ marginTop: 2, borderBottom: 1, borderTop: 1, borderColor: 'divider' }}>
      <CustomTabs value={value} onChange={(_, value) => onChange(value)}>
        {tabs.map(tab => (
          <CustomTab key={`tabs-${tab}`} label={tab} />
        ))}
      </CustomTabs>
    </Box>
  )
}
